import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useActivityFieldsInfo } from '@bloobirds-it/copilot';
import { Button, Icon, Text, Tooltip } from '@bloobirds-it/flamingo-ui';
import { useLoadingPromise, useTogglerState } from '@bloobirds-it/hooks';
import { useGetI18nSpacetime } from '@bloobirds-it/internationalization';
import { GeneralPrompt } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { mutate } from 'swr';

import { useGeneralPrompts } from '../../hooks/useGeneralPrompts';
import styles from '../../playgroundPages.module.css';
import { parseTextVariables } from '../../utils';
import { ConfirmationDeleteModal } from '../deleteConfirmationModal';
import { NoMainSelection } from '../runs/testRunsNoSetSelect';
import { SaveEditGeneralPromptDropdown } from './saveEditGeneralPromptDropdown';

interface IGeneralPromptsSelectedDetailProps {
  selectedSubType: GeneralPrompt | null;
  defaultPrompt: GeneralPrompt;
}

const HeaderField = ({ icon, label, value }) => (
  <div className={styles.headerField}>
    <Icon name={icon} size={16} color="softPeanut" />
    <Text size="s" weight="bold">
      {label}:
    </Text>
    <Text size="s">{value}</Text>
  </div>
);

type UpdatePromptData = Pick<GeneralPrompt, 'promptType' | 'content'>;

export const GeneralPromptsSelectedDetail = ({
  selectedSubType,
  defaultPrompt,
}: IGeneralPromptsSelectedDetailProps) => {
  const { t } = useTranslation();
  const { meetingTypes } = useActivityFieldsInfo();
  const parsedDate = useGetI18nSpacetime(selectedSubType?.updateDatetime);

  if (!selectedSubType)
    return (
      <NoMainSelection
        title={t('ai.playground.generalPromptsTab.selectSubTypeTitle')}
        subtitle={t('ai.playground.generalPromptsTab.selectSubTypeSubtitle')}
      />
    );

  const [promptType, activityType, activitySubType] = selectedSubType.promptType.split(':');

  return (
    <div className={styles.generalPromptsSelectedDetailContainer}>
      <div className={styles.generalPromptSelectedDetailHeader}>
        <Text weight="bold" className={styles.generalPromptCardTitle}>
          {promptType.replace(/_/g, ' ').toLocaleLowerCase()}
        </Text>
        <div className={styles.generalPromptSelectedDetailHeaderRow}>
          <HeaderField
            icon="gridSquares"
            label={t('ai.playground.header.activityType')}
            value={t(activityType ? `ai.insightActivity.${activityType}` : 'common.all', {
              count: 1,
            })}
          />
          <HeaderField
            icon="bookmark"
            label={t('ai.playground.header.activitySubType')}
            value={t(activitySubType ? meetingTypes.get(activitySubType)?.name : 'common.all')}
          />
        </div>
        <div className={styles.generalPromptSelectedDetailHeaderRow}>
          <HeaderField
            icon="clock"
            label={t('common.lastUpdate')}
            value={parsedDate.format('nice')}
          />
          <HeaderField
            icon="person"
            label={t('common.updatedBy')}
            value={selectedSubType.updatedBy}
          />
        </div>
      </div>
      <GeneralPromptEditor selectedSubType={selectedSubType} defaultPrompt={defaultPrompt} />
    </div>
  );
};

interface IGeneralPromptEditorProps {
  selectedSubType: GeneralPrompt;
  defaultPrompt: GeneralPrompt;
}
/**
 *
 *
 * @param {IGeneralPromptEditorProps} { selectedSubType, defaultPrompt }
 *  defaultPrompt is the "default version " of the subtypes,
 */
const GeneralPromptEditor = ({ selectedSubType, defaultPrompt }: IGeneralPromptEditorProps) => {
  const [editMode, toggle] = useTogglerState(false);
  const [editedPrompt, setEditedPrompt] = useState(selectedSubType?.content);
  const [systemDefaultPrompt, setSystemDefaultPrompt] = useState<GeneralPrompt | null>(null);
  const [openDeleteModal, toggleDeleteModal] = useTogglerState(false);

  const { t } = useTranslation();
  const [clipboardText, setClipboardText] = useState(t('copyText.copyToClipboard'));

  const { reloadAccountPrompts, getDefaultPrompt, accountPrompts } = useGeneralPrompts();
  const { loading, promiseHandler } = useLoadingPromise();
  const {
    loading: backToDefaultLoader,
    promiseHandler: backToDefaultPromiseHandler,
  } = useLoadingPromise();

  const updatePrompt = (data: UpdatePromptData) => {
    //TODO: temporary solution as AI_SUMMARY is an exclusive general prompt available for accounts
    if (
      data.promptType === 'AI_SUMMARY' &&
      accountPrompts.get('AI_SUMMARY')?.id === 'GENERIC_AI_SUMMARY_PROMPT'
    ) {
      return api.post<GeneralPrompt>(`/utils/service/prompts/subtype`, data);
    }
    return api.patch<GeneralPrompt>(`/utils/service/prompts/subtype`, data);
  };

  const handleUpdate = (data: UpdatePromptData, setVisible: (val: boolean) => void) => {
    promiseHandler(() => updatePrompt(data), {
      onSuccess: () => {
        reloadAccountPrompts();
        setVisible(false);
      },
      successToast: 'ai.playground.generalPromptsTab.updatePrompt.success',
      errorToast: 'ai.playground.generalPromptsTab.updatePrompt.error',
    });
  };

  const handleBackToDefault = (promptType: string, setVisible: (val: boolean) => void) => {
    backToDefaultPromiseHandler(
      () => updatePrompt({ content: systemDefaultPrompt?.content, promptType }),
      {
        onSuccess: () => {
          reloadAccountPrompts();
          setVisible(false);
        },

        successToast: 'ai.playground.generalPromptsTab.updatePrompt.defaultSuccess',
        errorToast: 'ai.playground.generalPromptsTab.updatePrompt.defaultError',
      },
    );
  };

  useEffect(() => {
    if (selectedSubType && editMode) {
      toggle();
    }
    if (selectedSubType.content !== editedPrompt) {
      setEditedPrompt(selectedSubType.content);
    }
  }, [selectedSubType]);
  useEffect(() => {
    if (defaultPrompt) {
      getDefaultPrompt(defaultPrompt.promptType)
        .then(response => {
          setSystemDefaultPrompt(response || null);
        })
        .catch(() => {
          setSystemDefaultPrompt(null);
        });
    }
  }, [defaultPrompt]);

  const handleDiscard = () => {
    setEditedPrompt(selectedSubType?.content);
    toggle();
  };
  const isDefaultPrompt = !selectedSubType.promptType.includes(':');

  const isEqualToDefaultPrompt = systemDefaultPrompt?.content === defaultPrompt?.content;

  const deleteGeneralPrompt = (promptType: string) =>
    api.delete<GeneralPrompt>(`/utils/service/prompts/subtype/${promptType}`);

  const handleDelete = () => {
    promiseHandler(() => deleteGeneralPrompt(selectedSubType?.promptType), {
      onSuccess: () => {
        mutate('account-prompts');
        toggleDeleteModal();
      },
      onError: console.error,
      successToast: 'ai.playground.generalPromptsTab.deletePrompt.success',
      errorToast: 'ai.playground.generalPromptsTab.deletePrompt.error',
    });
  };

  return (
    <>
      <div className={styles.generalPromptPromptEditorRow}>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <Text size="s" weight="bold">
            <Icon name="snippet" color="softPeanut" size={24} />
            {t('ai.insightFormModal.prompt.label')}
          </Text>
          {systemDefaultPrompt && isDefaultPrompt && !isEqualToDefaultPrompt && (
            <SaveEditGeneralPromptDropdown
              selectedSubType={selectedSubType}
              editedPrompt={editedPrompt}
              onSave={setVisible => handleBackToDefault(selectedSubType.promptType, setVisible)}
              loading={backToDefaultLoader}
              anchorButtonProps={{
                color: 'bloobirds',
                variant: 'clear',
                disabled: false,
                iconLeft: 'undoRevert',
                text: t('ai.playground.generalPromptsTab.resetPromptToDefault'),
              }}
            />
          )}
          {!isDefaultPrompt && (
            <Button
              size="small"
              uppercase={false}
              color="tomato"
              variant="clear"
              iconLeft="trashFull"
              onClick={toggleDeleteModal}
            >
              {t('ai.playground.promptTab.deletePrompt')}
            </Button>
          )}
        </div>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          {!editMode ? (
            <>
              <Button
                iconLeft="copy"
                uppercase={false}
                size="small"
                variant="clear"
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(selectedSubType?.content);
                    setClipboardText(t('copyText.copied'));
                    setTimeout(() => {
                      setClipboardText(t('copyText.copyToClipboard'));
                    }, 2000);
                  } catch (error) {
                    console.error('failed to copy to clipboard: ', selectedSubType?.content);
                  }
                }}
              >
                {clipboardText}
              </Button>
              <Button
                size="small"
                uppercase={false}
                color="peanut"
                variant="secondary"
                iconLeft="edit"
                onClick={toggle}
              >
                {t('ai.playground.promptTab.editPrompt')}
              </Button>
            </>
          ) : (
            <>
              <Button
                size="small"
                color="bloobirds"
                variant="clear"
                onClick={handleDiscard}
                iconLeft="trashFull"
                uppercase={false}
              >
                {t('ai.playground.promptTab.discard')}
              </Button>
              <SaveEditGeneralPromptDropdown
                selectedSubType={selectedSubType}
                editedPrompt={editedPrompt}
                onSave={setVisible =>
                  handleUpdate(
                    { promptType: selectedSubType.promptType, content: editedPrompt },
                    setVisible,
                  )
                }
                loading={loading}
                anchorButtonProps={{
                  color: selectedSubType?.content === editedPrompt ? 'gray' : 'bloobirds',
                  variant: selectedSubType?.content === editedPrompt ? 'clear' : 'primary',
                  disabled: selectedSubType?.content === editedPrompt,
                  iconLeft: 'save',
                  text: t('ai.playground.promptTab.save'),
                }}
              />
            </>
          )}
        </div>
      </div>

      <div style={{ display: 'flex', flexGrow: 1, overflow: 'hidden', gap: '8px' }}>
        <motion.div
          style={{ overflowY: 'auto', display: 'block' }}
          initial={{ width: '100%' }}
          animate={{ width: editMode ? '50%' : '100%' }}
          transition={{ duration: 0.5 }}
          className={clsx(styles.testRun_prompt_text, styles.generalPromptCurrentContent)}
          dangerouslySetInnerHTML={{ __html: parseTextVariables(selectedSubType?.content) }}
        ></motion.div>
        <motion.div
          style={{ overflowY: 'auto' }}
          initial={{ width: '0%', opacity: 0 }}
          animate={{ width: editMode ? '50%' : '0%', opacity: editMode ? 1 : 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className={styles.testRun_prompt_text_input}>
            <textarea
              value={editedPrompt}
              className={styles.testRun_prompt_textArea}
              onChange={e => setEditedPrompt(e.target.value)}
            />
          </div>
        </motion.div>
      </div>
      {openDeleteModal && (
        <ConfirmationDeleteModal
          title="ai.playground.generalPromptsTab.deletePrompt.title"
          description="ai.playground.generalPromptsTab.deletePrompt.description"
          question="ai.playground.generalPromptsTab.deletePrompt.question"
          onClose={toggleDeleteModal}
          onConfirm={handleDelete}
          loading={loading}
        />
      )}
    </>
  );
};
