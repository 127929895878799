import React, { useEffect, useState } from 'react';

import { TestSet } from '@bloobirds-it/types';

import { TestResults } from '../components/results/testResults';
import { TestRuns } from '../components/runs/testRuns';
import { TestSets } from '../components/testSets/testSets';
import styles from '../playgroundPages.module.css';

const Lab = () => {
  const [selectedTestSet, setSelectedTestSet] = useState<TestSet>();
  const [selectedRun, setSelectedRun] = useState();

  useEffect(() => {
    setSelectedRun(undefined);
  }, [selectedTestSet]);

  return (
    <div className={styles.playground_content}>
      <TestSets setSelectedTestSet={setSelectedTestSet} />
      <TestRuns selectedTestSet={selectedTestSet} handleSelectedRun={setSelectedRun} />
      <TestResults selectedTestSet={selectedTestSet} selectedRun={selectedRun} />
    </div>
  );
};

export default Lab;
