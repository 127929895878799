import React from 'react';
import { useTranslation } from 'react-i18next';

import { Input, Item, Select } from '@bloobirds-it/flamingo-ui';

import { useRequestNumberFormContext } from '../../../context/requestedPhonesFormProvider';
import { useCountriesList } from '../../../hooks/useCountriesList';
import styles from '../../../styles/phoneNumberPage.module.css';
import { useDirtyContext } from '../shared/dirtyContext';
import { InputTag } from './inputTag';

export const FormPurchasePhone = () => {
  const { t } = useTranslation();
  const { countries, formatCountryOption } = useCountriesList();
  const { setIsDirty } = useDirtyContext();

  const { phoneNumber, handleChangePhone } = useRequestNumberFormContext();

  const countriesFormatted = countries.map(country => {
    return {
      value: country.cca2,
      label: formatCountryOption(country),
    };
  });

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        setIsDirty(false);
      }}
    >
      <div className={styles.purchasePhoneForm}>
        <Select
          width="100%"
          className={styles.fullWidth}
          value={phoneNumber.country}
          autocomplete
          removePlaceholder={true}
          onChange={value => {
            handleChangePhone('country', value);
            setIsDirty(true);
          }}
          placeholder={t(
            'accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.form.selectCountry',
          )}
        >
          {countriesFormatted.map(country => {
            return (
              <Item value={country.label} key={country.value} label={country.label}>
                {country.label}
              </Item>
            );
          })}
        </Select>
        <Select
          width="100%"
          removePlaceholder={true}
          value={phoneNumber.typeOfNumber}
          onChange={value => {
            handleChangePhone('typeOfNumber', value);
            setIsDirty(true);
          }}
          className={styles.fullWidth}
          placeholder={t(
            'accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.form.selectNumberType',
          )}
        >
          <Item value="local">
            {t('accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.form.local')}
          </Item>
          <Item value="tollFree">
            {t('accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.form.tollFree')}
          </Item>
          <Item value="mobile">
            {t('accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.form.mobile')}
          </Item>
        </Select>
      </div>
      <div className={styles.purchasePhoneForm}>
        <InputTag />
        <Input
          value={phoneNumber.phonesRequested}
          removePlaceholder={true}
          onChange={value => {
            handleChangePhone('phonesRequested', value);
            setIsDirty(true);
          }}
          width="100%"
          placeholder={t(
            'accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.form.totalPhonesRequested',
          )}
        />
      </div>
    </form>
  );
};
