// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contactEmail-module__emailImage___NqkMK {\n  margin-top: -13px;\n}\n\n.contactEmail-module___call_and_report_tooltip___NzvQX {\n  justify-content: flex-end;\n  width: 80px;\n  margin-left: -51px;\n  display: flex;\n  margin-right: 7px;\n}", "",{"version":3,"sources":["webpack://./src/js/components/discoveryTooltips/contactEmail.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,iBAAiB;AACnB","sourcesContent":[".emailImage {\n  margin-top: -13px;\n}\n\n._call_and_report_tooltip {\n  justify-content: flex-end;\n  width: 80px;\n  margin-left: -51px;\n  display: flex;\n  margin-right: 7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emailImage": "contactEmail-module__emailImage___NqkMK",
	"_call_and_report_tooltip": "contactEmail-module___call_and_report_tooltip___NzvQX"
};
export default ___CSS_LOADER_EXPORT___;
