import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  Icon,
  Input,
  Item,
  ModalContent,
  ModalFooter,
  Select,
  Text,
  Flag,
  countries,
  Spinner,
  Callout,
} from '@bloobirds-it/flamingo-ui';
import { isValidPhone } from '@bloobirds-it/utils';

import { usePhoneConnections } from '../../../../../hooks';
import styles from './addPhoneStep.module.css';

const AddPhoneStep = ({ handleClose, handleNextStep }) => {
  const { addNewConnection, connections } = usePhoneConnections();
  const { t } = useTranslation('translation', {
    keyPrefix: 'userSettingsWebapp.callSettings.addPhoneModal.addPhoneStep',
  });
  const { handleSubmit, control, errors, getValues, watch, formState } = useForm({
    defaultValues: {
      phonePrefix: '34',
      phoneNumber: '',
    },
  });

  const onSubmit = async data => {
    const { phonePrefix, phoneNumber } = data;
    const fullPhoneNumber = `+${phonePrefix} ${phoneNumber.replace(/\s/g, '')}`;
    const connection = await addNewConnection(fullPhoneNumber);
    handleNextStep(connection);
  };

  const phonePrefix = watch('phonePrefix');

  return (
    <>
      <ModalContent>
        <div>
          <div className={styles._header__container}>
            <div className={styles._icon__container}>
              <Icon name="phone" size={48} color="softPeanut" />
            </div>
            <div className={styles._divider} />
          </div>
          <div>
            <div className={styles._section__wrapper}>
              <div className={styles._text__container}>
                <Text color="peanut" size="m" weight="medium">
                  {t('title')}
                </Text>
              </div>
              <div className={styles._phone_number__wrapper}>
                <Controller
                  name="phonePrefix"
                  control={control}
                  error={errors.phonePrefix?.message}
                  rules={{
                    validate: value => {
                      if (!value) {
                        return t('missingPrefix');
                      }

                      if (value.length > 3) {
                        return t('invalidPrefix');
                      }

                      return null;
                    },
                  }}
                  as={
                    <Select placeholder={t('countryPlaceholder')} autocomplete>
                      {countries.map(({ code, name, phone }) => (
                        <Item
                          value={phone}
                          label={`${name} (${code}) ${phone}`}
                          right={
                            <Text size="xs" color="softPeanut">
                              {`+${phone}`}
                            </Text>
                          }
                          adornment={<Flag code={code} />}
                          key={code}
                        >
                          {`${name} (${code})`}
                        </Item>
                      ))}
                    </Select>
                  }
                />
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    validate: value => {
                      if (!value) {
                        return t('phoneEmpty');
                      }

                      const fullPhoneNumber = `+${getValues().phonePrefix} ${value}`;

                      if (!isValidPhone(fullPhoneNumber)) {
                        return t('invalidPhone');
                      }

                      if (
                        connections?.list.some(({ phoneNumber }) => phoneNumber === fullPhoneNumber)
                      ) {
                        return t('phoneUsed');
                      }

                      return null;
                    },
                  }}
                  error={errors.phoneNumber?.message}
                  as={
                    <Input
                      adornment={
                        phonePrefix && <Text color="softPeanut" size="m">{`+${phonePrefix}`}</Text>
                      }
                      width="240"
                      onKeyPress={event => {
                        const keyCode = event.keyCode || event.which;
                        const keyValue = String.fromCharCode(keyCode);

                        if (/\+|-/.test(keyValue)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles._callout_container}>
          <Callout>
            <span role="img" aria-label="hand pointing right">
              👉
            </span>
            <Trans i18nKey="userSettingsWebapp.callSettings.addPhoneModal.addPhoneStep.callout" />
          </Callout>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button variant="clear" onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button onClick={handleSubmit(onSubmit)} disabled={formState.isSubmitting}>
          {formState.isSubmitting ? <Spinner name="loadingCircle" size={12} /> : t('save')}
        </Button>
      </ModalFooter>
    </>
  );
};

export default AddPhoneStep;
