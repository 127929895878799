import { comboboxActions } from '@udecode/plate';
import classNames from 'clsx';
import { useSelected } from 'slate-react';

import { ELEMENT_USER_MENTION } from '../../plugins/userMentionsPlugin/defaults';
import styles from './userMention.module.css';

const UserMention = props => {
  const { attributes, children, element, editor } = props;
  const selected = useSelected();

  const classes = classNames(styles.container, {
    [styles.focused]: selected,
  });

  return (
    <span {...attributes}>
      <span
        contentEditable={false}
        className={classes}
        style={
          element.color ? { color: element.color, backgroundColor: 'var(--veryLightPeanut)' } : {}
        }
        onClick={() => {
          editor.deleteBackward('character');
          comboboxActions.open({
            text: '',
            targetRange: { anchor: editor.selection?.anchor, focus: editor.selection?.focus },
            activeId: ELEMENT_USER_MENTION,
          });
        }}
      >
        {element.name}
      </span>
      {children}
    </span>
  );
};

export default UserMention;
