// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subhomeEmptyContent-module___container___kEQ8\\+ {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 80px;\n}\n\n.subhomeEmptyContent-module___text___pLzuh {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.subhomeEmptyContent-module___text___pLzuh > p:nth-child(2) {\n  margin-top: 10px;\n}\n\n.subhomeEmptyContent-module___icon___-SFlv {\n  height: 45px;\n  width: 45px;\n  margin-bottom: 30px;\n}\n\n.subhomeEmptyContent-module___icon___-SFlv > g,\n.subhomeEmptyContent-module___icon___-SFlv > path {\n  fill: var(--verySoftPeanut);\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/subhomeLayout/subhomeContent/subhomeEmptyContent/subhomeEmptyContent.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;;EAEE,2BAA2B;AAC7B","sourcesContent":["._container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 80px;\n}\n\n._text {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n._text > p:nth-child(2) {\n  margin-top: 10px;\n}\n\n._icon {\n  height: 45px;\n  width: 45px;\n  margin-bottom: 30px;\n}\n\n._icon > g,\n._icon > path {\n  fill: var(--verySoftPeanut);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "subhomeEmptyContent-module___container___kEQ8+",
	"_text": "subhomeEmptyContent-module___text___pLzuh",
	"_icon": "subhomeEmptyContent-module___icon___-SFlv"
};
export default ___CSS_LOADER_EXPORT___;
