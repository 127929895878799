// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".addCompanyModal-module___content__wraper___\\+Wa8p {\n  padding: 32px 60px 0;\n  position: relative;\n  background-color: var(--white);\n}\n\n.addCompanyModal-module___info__wrapper___hNSqj {\n  margin-top: 16px;\n  margin-bottom: 24px;\n}\n\n.addCompanyModal-module___autocomplete__wrapper___khprE {\n  margin-bottom: 30px;\n}\n\n.addCompanyModal-module___confirm__button___iKhpi > button {\n  margin-left: 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/addCompanyModal/addCompanyModal.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["._content__wraper {\n  padding: 32px 60px 0;\n  position: relative;\n  background-color: var(--white);\n}\n\n._info__wrapper {\n  margin-top: 16px;\n  margin-bottom: 24px;\n}\n\n._autocomplete__wrapper {\n  margin-bottom: 30px;\n}\n\n._confirm__button > button {\n  margin-left: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_content__wraper": "addCompanyModal-module___content__wraper___+Wa8p",
	"_info__wrapper": "addCompanyModal-module___info__wrapper___hNSqj",
	"_autocomplete__wrapper": "addCompanyModal-module___autocomplete__wrapper___khprE",
	"_confirm__button": "addCompanyModal-module___confirm__button___iKhpi"
};
export default ___CSS_LOADER_EXPORT___;
