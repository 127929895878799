// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".textMessage-module___text_message___-wufT {\n  white-space: pre-wrap;\n  font-size: 14px;\n  overflow: hidden;\n  color: var(--peanuts);\n  line-height: 24px;\n  text-overflow: ellipsis;\n  display: flex;\n  flex-direction: column;\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/activitySection/activityItem/activityBody/textMessage/textMessage.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,iBAAiB;EACjB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX","sourcesContent":["._text_message {\n  white-space: pre-wrap;\n  font-size: 14px;\n  overflow: hidden;\n  color: var(--peanuts);\n  line-height: 24px;\n  text-overflow: ellipsis;\n  display: flex;\n  flex-direction: column;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_text_message": "textMessage-module___text_message___-wufT"
};
export default ___CSS_LOADER_EXPORT___;
