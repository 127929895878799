import React from 'react';

import { PurchasePhoneNumberGeneralInfo } from '../components/purchaseNumber/generalInfo/purchasePhoneNumberGeneralInfo';
import { InfoComponent } from '../components/purchaseNumber/shared/infoComponent';
import styles from '../styles/phoneNumberPage.module.css';

export const GeneralInfo = () => {
  return (
    <div className={styles.purchaseGeneralContainer}>
      <InfoComponent type="phoneRequest" />
      <PurchasePhoneNumberGeneralInfo />
    </div>
  );
};
