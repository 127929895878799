import {
  TEMPLATE_TYPES,
  QQ_TYPES,
  QQ_TYPES_COPIES,
  QQ_TYPES_MAP,
  TEMPLATE_TYPES_COPIES,
  TEMPLATE_TYPES_ICONS,
  FORM_MODES,
} from '@bloobirds-it/types';

import {
  APP_PLAYBOOK_MESSAGING_EMAIL,
  APP_PLAYBOOK_MESSAGING_LINKEDIN,
  APP_PLAYBOOK_MESSAGING_PITCH,
  APP_PLAYBOOK_MESSAGING_QQ,
  APP_PLAYBOOK_MESSAGING_SNIPPET,
  APP_PLAYBOOK_MESSAGING_WHATSAPP,
} from '../app/_constants/routes';

const typeToUrl = type => {
  if (type === TEMPLATE_TYPES.EMAIL) {
    return APP_PLAYBOOK_MESSAGING_EMAIL;
  }
  if (type === TEMPLATE_TYPES.LINKEDIN) {
    return APP_PLAYBOOK_MESSAGING_LINKEDIN;
  }
  if (type === TEMPLATE_TYPES.PITCH) {
    return APP_PLAYBOOK_MESSAGING_PITCH;
  }
  if (type === TEMPLATE_TYPES.SNIPPET) {
    return APP_PLAYBOOK_MESSAGING_SNIPPET;
  }
  if (type === TEMPLATE_TYPES.QUALIFYING_QUESTION) {
    return APP_PLAYBOOK_MESSAGING_QQ;
  }
  if (type === TEMPLATE_TYPES.WHATSAPP) {
    return APP_PLAYBOOK_MESSAGING_WHATSAPP;
  }
  return '';
};

export {
  typeToUrl,
  TEMPLATE_TYPES,
  QQ_TYPES,
  QQ_TYPES_COPIES,
  QQ_TYPES_MAP,
  TEMPLATE_TYPES_COPIES,
  TEMPLATE_TYPES_ICONS,
  FORM_MODES,
};
