import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconType } from '@bloobirds-it/flamingo-ui';

import { PurchaseNumberHeader } from '../components/headers/purchaseNumberHeader';
import { DirtyProvider } from '../components/purchaseNumber/shared/dirtyContext';
import { useRequestNumberFormContext } from '../context/requestedPhonesFormProvider';
import styles from '../styles/phoneNumberPage.module.css';
import { GeneralInfo } from './generalInfo';
import { OrderReview } from './orderReview';

const CurrentStepComponent = ({ currentStep }: { currentStep: number }) => {
  switch (currentStep) {
    case 0:
      return <GeneralInfo />;
    case 1:
      return <OrderReview />;
    default:
      return <GeneralInfo />;
  }
};

export const PurchaseNewPhone = () => {
  const { t } = useTranslation();
  const { currentStep } = useRequestNumberFormContext();

  const stepList = [
    {
      name: t('accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.title'),
      icon: 'alignJustify' as IconType,
    },
    {
      name: t('accountSettings.phoneNumbers.purchaseNewPhone.orderReview.title'),
      icon: 'phone' as IconType,
    },
  ];

  return (
    <DirtyProvider>
      <div className={styles.purchasePhoneContainer}>
        <PurchaseNumberHeader stepList={stepList} currentStep={currentStep} />
        <CurrentStepComponent currentStep={currentStep} />
      </div>
    </DirtyProvider>
  );
};
