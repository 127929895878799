import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';

import { useUserSettings } from '@bloobirds-it/hooks';
import { CopilotInsight, UserPermission } from '@bloobirds-it/types';

import NoPermissionsPage from '../noPermissionsPage';
import { SectionSelector } from './components/sectionSelector/sectionSelector';
import { useInsights } from './hooks/useInsights';
import styles from './playgroundPages.module.css';
import PlaygroundTabs from './tabs';
import { PlaygroundTab, PLAYGROUND_TABS } from './types';

interface PlaygroundContextInterface {
  selectedTab: PlaygroundTab;
  setSelectedTab: (tab: PlaygroundTab) => void;
  insights: Map<string, CopilotInsight>;
}

export const PlaygroundContext = React.createContext<PlaygroundContextInterface>(null);

export function usePlayground(): PlaygroundContextInterface {
  const context = useContext(PlaygroundContext);
  if (!context) {
    throw new Error(`usePlayground must be used within a PlaygroundProvider`);
  }
  return context;
}

export function PlaygroundProvider({ children }: { children: React.ReactNode }) {
  const { section } = useParams<{ section: PlaygroundTab }>();
  const [selectedTab, setSelectedTab] = useState<PlaygroundTab>(section || PLAYGROUND_TABS.LAB);
  const { insights } = useInsights();

  const value = {
    selectedTab,
    setSelectedTab,
    insights,
  };

  return <PlaygroundContext.Provider value={value}>{children}</PlaygroundContext.Provider>;
}

function PlaygroundPages() {
  const settings = useUserSettings();

  if (!settings?.user?.permissions?.includes(UserPermission.AI_PLAYGROUND))
    return <NoPermissionsPage />;

  return (
    <PlaygroundProvider>
      <div className={styles.playground_background}>
        <div className={styles.playground_container}>
          <SectionSelector />
          <PlaygroundTabs />
        </div>
      </div>
    </PlaygroundProvider>
  );
}

export default PlaygroundPages;
