// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".confirmDeleteModalLayout-module___title__container___wqhF- {\n  display: flex;\n  align-items: center;\n}\n\n.confirmDeleteModalLayout-module___icon___sSHZV {\n  margin-right: 4px;\n}\n\n.confirmDeleteModalLayout-module__content___2t76W {\n  margin: 8px 0 16px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/confirmDeleteModalLayout/confirmDeleteModalLayout.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":["._title__container {\n  display: flex;\n  align-items: center;\n}\n\n._icon {\n  margin-right: 4px;\n}\n\n.content {\n  margin: 8px 0 16px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_title__container": "confirmDeleteModalLayout-module___title__container___wqhF-",
	"_icon": "confirmDeleteModalLayout-module___icon___sSHZV",
	"content": "confirmDeleteModalLayout-module__content___2t76W"
};
export default ___CSS_LOADER_EXPORT___;
