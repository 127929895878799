// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cadencesPage-module__tabGroupWrapper___GfIAH {\n  flex: 1;\n}\n\n.cadencesPage-module__tabGroupWrapper___GfIAH > div {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.cadencesPage-module__tabGroupWrapper___GfIAH > div > div[class*='TabGroup-module_tabContent'],\n.cadencesPage-module__tabGroupWrapper___GfIAH > div > div > div[class*='TabGroup-module_tabPanel'] {\n  height: 100%;\n}\n\n/* Temporary: Hide analyze tab */\n.cadencesPage-module__tabGroupWrapper___GfIAH > div > div > div:last-child > div,\n.cadencesPage-module__tabGroupWrapper___GfIAH > div > div > div:last-child > p {\n  background-image: none !important;\n  color: transparent !important;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/cadencesPage/cadencesPage.module.css"],"names":[],"mappings":"AAAA;EACE,OAAO;AACT;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;;EAEE,YAAY;AACd;;AAEA,gCAAgC;AAChC;;EAEE,iCAAiC;EACjC,6BAA6B;AAC/B","sourcesContent":[".tabGroupWrapper {\n  flex: 1;\n}\n\n.tabGroupWrapper > div {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.tabGroupWrapper > div > div[class*='TabGroup-module_tabContent'],\n.tabGroupWrapper > div > div > div[class*='TabGroup-module_tabPanel'] {\n  height: 100%;\n}\n\n/* Temporary: Hide analyze tab */\n.tabGroupWrapper > div > div > div:last-child > div,\n.tabGroupWrapper > div > div > div:last-child > p {\n  background-image: none !important;\n  color: transparent !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabGroupWrapper": "cadencesPage-module__tabGroupWrapper___GfIAH"
};
export default ___CSS_LOADER_EXPORT___;
