import { useMemo } from 'react';

import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  ActivityBobject,
} from '@bloobirds-it/types';
import { api, getTextFromLogicRole, injectReferencesSearchProcess } from '@bloobirds-it/utils';
import useSWR from 'swr';

export const useEmailThreadActivities = (threadId: string, accountId: string) => {
  const fetchActivities = () => {
    return api.post('/bobjects/' + accountId + '/Activity/search', {
      //TODO: Add columns in query
      query: {
        [ACTIVITY_FIELDS_LOGIC_ROLE.TYPE]: [ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL],
        [ACTIVITY_FIELDS_LOGIC_ROLE.EMAIL_THREAD_ID]: {
          query: threadId,
          searchMode: 'EXACT__SEARCH',
        },
      },
      page: 0,
      formFields: true,
      pageSize: 30,
      injectReferences: true,
      sort: [
        {
          field: ACTIVITY_FIELDS_LOGIC_ROLE.TIME,
          direction: 'DESC',
        },
      ],
      trimBigBodies: true,
    });
  };

  const { data, mutate } = useSWR(
    accountId && threadId && `/emailThreadActivities/${threadId}`,
    fetchActivities,
  );

  const firstAndLast = useMemo(() => {
    const threadActivities: ActivityBobject[] = data?.data?.contents || [];
    if (threadActivities.length === 0) {
      return { firstMessage: null, lastMessage: null };
    }

    const lastMessageField = getTextFromLogicRole(
      threadActivities[0],
      ACTIVITY_FIELDS_LOGIC_ROLE.CREATION_DATETIME,
    );

    const firstMessageField =
      threadActivities.length > 1
        ? getTextFromLogicRole(
            threadActivities[threadActivities.length - 1],
            ACTIVITY_FIELDS_LOGIC_ROLE.CREATION_DATETIME,
          )
        : lastMessageField;

    return {
      firstMessage: firstMessageField || null,
      lastMessage: lastMessageField || null,
    };
  }, [data?.data?.contents]);

  return {
    data,
    activities: data && injectReferencesSearchProcess(data?.data)?.contents,
    total: data?.data?.totalMatching,
    mutate,
    firstAndLast,
  };
};
