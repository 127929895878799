// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".businessAssetBadge-module___container___HLI8T {\n  position: relative;\n}\n.businessAssetBadge-module___counter___qx8R6 {\n  position: absolute;\n  bottom: -6px;\n  right: -6px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bussinesAssetBadge/businessAssetBadge.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;AACb","sourcesContent":["._container {\n  position: relative;\n}\n._counter {\n  position: absolute;\n  bottom: -6px;\n  right: -6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "businessAssetBadge-module___container___HLI8T",
	"_counter": "businessAssetBadge-module___counter___qx8R6"
};
export default ___CSS_LOADER_EXPORT___;
