import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Radio, RadioGroup, Text } from '@bloobirds-it/flamingo-ui';
import { useLanguage, useUserHelpers } from '@bloobirds-it/hooks';
import { UserHelperKeys } from '@bloobirds-it/types';

import styles from './otoQSGSteps.module.css';

export const LanguageSetter = ({ title }: { title?: string }) => {
  const { t, i18n } = useTranslation();
  const { updateLanguage, languages } = useLanguage();

  return (
    <div className={styles.languageSelect}>
      <Text size="m" color="peanut">
        {title ?? t('languages.selectALanguage')}
      </Text>
      <RadioGroup
        value={i18n.language}
        onChange={value => {
          updateLanguage(value);
          i18n.changeLanguage(value);
        }}
        defaultValue={i18n.language}
      >
        {languages.map(language => (
          <Radio key={language} size="small" value={language}>
            <span className={styles.languageOption}>{t(`languages.${language}`)}</span>
          </Radio>
        ))}
      </RadioGroup>
    </div>
  );
};

export const LanguageSetterStep = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'quickStartGuide.oto.blocks.language',
  });
  const { save, has } = useUserHelpers();

  useEffect(() => {
    if (!has(UserHelperKeys.CHOOSE_LANGUAGE)) {
      save(UserHelperKeys.CHOOSE_LANGUAGE);
    }
  }, []);

  return (
    <div className={styles.languageSelect}>
      <LanguageSetter title={t('subtitle')} />
    </div>
  );
};
