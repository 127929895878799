// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".messagingTemplatesBar-module__container___OgV88 {\n  display: flex;\n  padding: 16px 24px 0;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.messagingTemplatesBar-module__select___9ydM1 {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n}\n\n.messagingTemplatesBar-module__label___\\+y7Rh {\n  display: flex;\n  align-items: center;\n  gap: 2px;\n}\n.messagingTemplatesBar-module__select-item___mrlCu{\n  max-width: 256px; /* same width as <Select />*/\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  display: block !important;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/cadencesPage/components/createEditStepModal/automatedEmailModal/messagingTemplatesBar/messagingTemplatesBar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oBAAoB;EACpB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;AACA;EACE,gBAAgB,EAAE,4BAA4B;EAC9C,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,yBAAyB;AAC3B","sourcesContent":[".container {\n  display: flex;\n  padding: 16px 24px 0;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.select {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n}\n\n.label {\n  display: flex;\n  align-items: center;\n  gap: 2px;\n}\n.select-item{\n  max-width: 256px; /* same width as <Select />*/\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  display: block !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "messagingTemplatesBar-module__container___OgV88",
	"select": "messagingTemplatesBar-module__select___9ydM1",
	"label": "messagingTemplatesBar-module__label___+y7Rh",
	"select-item": "messagingTemplatesBar-module__select-item___mrlCu"
};
export default ___CSS_LOADER_EXPORT___;
