import React from 'react';

import { QuickStartGuideBlock } from '../otoConnectionsGuide';
import { SkipSubsection } from './skipSubsection';

type WrapperComponentProps = {
  children: React.ReactNode;
  isCompleted: boolean;
  step: QuickStartGuideBlock;
  setIsOpen: (value: boolean) => void;
};

export const StepWrapper = ({ children, isCompleted, step, setIsOpen }: WrapperComponentProps) => (
  <div>
    {children}
    {!isCompleted && <SkipSubsection step={step} setIsOpen={setIsOpen} />}
  </div>
);
