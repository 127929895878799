import React from 'react';
import { Trans } from 'react-i18next';

import { Icon, IconButton, Text } from '@bloobirds-it/flamingo-ui';
import { motion, AnimatePresence } from 'framer-motion';

import { usePaginatedPhoneEntity } from '../../hooks/usePaginatedPhoneEntity';
import styles from '../../styles/phoneNumberPage.module.css';

export const InfoCallout = () => {
  const { showCallouts } = usePaginatedPhoneEntity();

  return (
    <AnimatePresence>
      {showCallouts.InfoCallout && (
        <motion.div
          className={styles.infoCallDistribution}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          <Icon name="info" size={24} color="bloobirds" />
          <Text size="s" color="peanut" weight="regular">
            <Trans
              i18nKey="accountSettings.phoneNumbers.phoneSettings.tabs.advancedSettings.sectionCallDistribution.info"
              components={{
                strong: (
                  <a target="blank" href="https://support.bloobirds.com/hc/en-us/requests/new"></a>
                ),
              }}
            ></Trans>
          </Text>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const InfoCalloutPurchasePhone = () => {
  const { showCallouts, setShowCallouts } = usePaginatedPhoneEntity();

  const handleClose = () => {
    setShowCallouts({ ...showCallouts, InfoCalloutPurchasePhone: false });
  };

  return (
    <AnimatePresence>
      {showCallouts.InfoCalloutPurchasePhone && (
        <motion.div
          className={styles.infoCallDistribution}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          <Icon name="info" size={24} color="bloobirds" />
          <Text size="xs" color="peanut" weight="regular">
            <Trans i18nKey="accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.infoCallout"></Trans>
          </Text>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const WarningCallout = () => {
  const { showCallouts } = usePaginatedPhoneEntity();

  return (
    <AnimatePresence>
      {showCallouts.ErrorCallout && (
        <motion.div
          className={styles.warningCallout}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          <Icon name="alertTriangle" size={24} color="peanut" />
          <Text size="s" color="peanut" weight="regular">
            <Trans i18nKey="accountSettings.phoneNumbers.phoneSettings.tabs.advancedSettings.sectionCallRecording.warning"></Trans>
          </Text>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const InfoCalloutModal = () => {
  return (
    <AnimatePresence>
      <motion.div
        className={styles.modalCalloutInfo}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
      >
        <Icon name="info" size={24} color="bloobirds" />
        <Text size="s" color="peanut" weight="regular">
          <Trans i18nKey="accountSettings.phoneNumbers.phoneSettings.tabs.teamsandusers.infoCallout"></Trans>
        </Text>
      </motion.div>
    </AnimatePresence>
  );
};
