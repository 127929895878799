// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".section-module___grid___EDRbX > section > div {\n  display: flex;\n  gap: 16px;\n  flex-wrap: wrap;\n  padding: 0 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectForm/section/section.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,eAAe;EACf,eAAe;AACjB","sourcesContent":["._grid > section > div {\n  display: flex;\n  gap: 16px;\n  flex-wrap: wrap;\n  padding: 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_grid": "section-module___grid___EDRbX"
};
export default ___CSS_LOADER_EXPORT___;
