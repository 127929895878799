import React from 'react';

import { UserFilterByTeams } from '@bloobirds-it/filters';
import { Icon } from '@bloobirds-it/flamingo-ui';

import { useEntity } from '../../../../hooks';
import useDashboardFilters from '../../../../hooks/useDashboardFilters';
import styles from './userTeamsFilters.module.css';

export const UserTeamsFilter = ({
  logicRole,
  onChange,
}: {
  logicRole: string;
  onChange: (value: { [key: string]: { searchMode: string; query?: any } }) => void;
}) => {
  const { filters } = useDashboardFilters();
  const bobjectFields = useEntity('bobjectFields');

  const field = bobjectFields?.findByLogicRole(logicRole);
  const value = filters ? filters[field?.id] : undefined;

  const handleChangeUsers = (type: string, values?: string[]) => {
    onChange({
      [field.id]: { searchMode: type, query: values ?? [] },
    });
  };

  let title = '';
  switch (value?.searchMode) {
    case '__MATCH_FULL_ROWS__':
      title = "It's not empty";
      break;
    case '__MATCH_EMPTY_ROWS__':
      title = "It's empty";
      break;
    default:
      title = '';
      break;
  }

  return (
    <UserFilterByTeams
      placeholder="Assigned to"
      title={title}
      value={value?.query}
      onChange={values => handleChangeUsers('EXACT__SEARCH', values)}
      selectProps={{ width: '100%', variant: 'form' }}
    >
      <div className={styles.users}>
        <div
          onClick={() => {
            handleChangeUsers('__MATCH_FULL_ROWS__');
          }}
        >
          It's not empty
          {value?.searchMode === '__MATCH_FULL_ROWS__' && (
            <Icon name="check" color="bloobirds" size={12} />
          )}
        </div>
        <div
          onClick={() => {
            handleChangeUsers('__MATCH_EMPTY_ROWS__');
          }}
        >
          It's empty
          {value?.searchMode === '__MATCH_EMPTY_ROWS__' && (
            <Icon name="check" color="bloobirds" size={12} />
          )}
        </div>
      </div>
    </UserFilterByTeams>
  );
};
