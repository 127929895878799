export const getMentionsFromNote = (noteBody: any) => {
  const mentionIds: Set<string> = new Set();
  if (!noteBody || typeof noteBody === 'string') return [];

  const extractMentions = (children: any[]) => {
    children?.forEach(child => {
      if (child.type === 'user-mention' && child.id) {
        mentionIds.add(child.id);
      }

      if (child.children) {
        extractMentions(child.children);
      }
    });
  };

  noteBody?.forEach((element: any) => {
    if (element?.children && element.children.length > 0) {
      extractMentions(element.children);
    }
  });

  return Array.from(mentionIds);
};
