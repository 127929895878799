import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, SearchInput } from '@bloobirds-it/flamingo-ui';

import { ChooseBobjectTooltip } from '../../../../../components/discoveryTooltips/fieldsTourTooltips/chooseBobjectTooltip';
import {
  AccountSettingsTabHeader,
  AccountSettingsTabHeaderLeft,
  AccountSettingsTabTitle,
  AccountSettingsTabHeaderRight,
} from '../../../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import styles from '../..//styles/phoneNumberPage.module.css';
import {
  usePaginatedPhoneEntity,
  usePhoneNumberFilters,
} from '../../hooks/usePaginatedPhoneEntity';

export const PhoneNumbersHeader = () => {
  const { t } = useTranslation();

  const { updatePhoneNumberFilters } = usePhoneNumberFilters();
  const { setShowPurchasePhoneNumber } = usePaginatedPhoneEntity();

  const handleSearch = (newValue: string) => {
    updatePhoneNumberFilters('search', newValue);
  };

  return (
    <AccountSettingsTabHeader>
      <AccountSettingsTabHeaderLeft>
        <div className={styles.tabTitle__container}>
          <AccountSettingsTabTitle icon="agendaPerson">
            {t('accountSettings.phoneNumbers.title')}
          </AccountSettingsTabTitle>
          <ChooseBobjectTooltip defaultTooltipVisible />
        </div>
      </AccountSettingsTabHeaderLeft>
      <AccountSettingsTabHeaderRight>
        <Button
          iconLeft="plus"
          variant="secondary"
          onClick={() => setShowPurchasePhoneNumber(true)}
        >
          {t('accountSettings.phoneNumbers.purchase')}
        </Button>
        <SearchInput
          width={'400px'}
          onChange={handleSearch}
          placeholder={t('accountSettings.phoneNumbers.searchPlaceholder')}
          color="softBloobirds"
        />
      </AccountSettingsTabHeaderRight>
    </AccountSettingsTabHeader>
  );
};
