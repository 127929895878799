// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".emptyTable-module___container___nu9cD {\n  background-color: var(--lighterGray);\n  width: 100%;\n  margin: 0 -38px;\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n}\n\n.emptyTable-module___description__text___V\\+wae {\n  color: var(--peanut);\n  margin: auto;\n}\n\n.emptyTable-module___description__text_light___ZpsyC {\n  color: var(--softPeanut);\n  margin: auto;\n}\n\n.emptyTable-module___empty_data__container___\\+dElG {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.emptyTable-module___empty_data__icon___2anlh {\n  height: 60px;\n  width: 60px;\n  margin-bottom: 34px;\n}\n\n.emptyTable-module___empty_data__icon___2anlh > g,\n.emptyTable-module___empty_data__icon___2anlh > path {\n  fill: var(--softPeanut);\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectTable/emptyTable/emptyTable.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;AAC7B;;AAEA;EACE,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,wBAAwB;EACxB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;;EAEE,uBAAuB;AACzB","sourcesContent":["._container {\n  background-color: var(--lighterGray);\n  width: 100%;\n  margin: 0 -38px;\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n}\n\n._description__text {\n  color: var(--peanut);\n  margin: auto;\n}\n\n._description__text_light {\n  color: var(--softPeanut);\n  margin: auto;\n}\n\n._empty_data__container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n._empty_data__icon {\n  height: 60px;\n  width: 60px;\n  margin-bottom: 34px;\n}\n\n._empty_data__icon > g,\n._empty_data__icon > path {\n  fill: var(--softPeanut);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "emptyTable-module___container___nu9cD",
	"_description__text": "emptyTable-module___description__text___V+wae",
	"_description__text_light": "emptyTable-module___description__text_light___ZpsyC",
	"_empty_data__container": "emptyTable-module___empty_data__container___+dElG",
	"_empty_data__icon": "emptyTable-module___empty_data__icon___2anlh"
};
export default ___CSS_LOADER_EXPORT___;
