import { useRef, useState } from 'react';

import { createToast } from '@bloobirds-it/flamingo-ui';
import { useDebouncedCallback } from '@bloobirds-it/hooks';
import { getMentionsFromNote } from '@bloobirds-it/notes';
import { deserialize, serialize, useRichTextEditorPlugins } from '@bloobirds-it/rich-text-editor';
import { ACTIVITY_FIELDS_LOGIC_ROLE } from '@bloobirds-it/types';
import { api, getTextFromLogicRole, isHtml } from '@bloobirds-it/utils';
import { useWizardContext } from '@bloobirds-it/wizard-modal-context';

export const useNoteData = ({ t, activity, wizardKey }) => {
  const { getWizardProperties, addMetaToWizardProperties } = useWizardContext();
  const wizardProperties = getWizardProperties(wizardKey);
  const plugins = useRichTextEditorPlugins({
    images: false,
    replaceParagraphs: true,
    userMentions: true,
    elements: true,
  });

  const defaultNoteValue =
    wizardProperties.activityNote ||
    getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.NOTE);
  const serializedNoteValue = isHtml(defaultNoteValue)
    ? deserialize(defaultNoteValue, {
        format: 'HTML',
        plugins,
      })
    : defaultNoteValue;
  const noteRef = useRef(serializedNoteValue);

  const [loading, setLoading] = useState(false);
  const hasSaved = useRef<boolean>();
  const onSubmit = ({ dataToCreate, setHasSaved }) => {
    setLoading(true);
    const activityId = activity?.id;
    api
      .patch(`/bobjects/${activityId?.value}/raw`, {
        contents: dataToCreate,
        params: {},
      })
      .then(() => {
        addMetaToWizardProperties(wizardKey, {
          activityNote: dataToCreate[ACTIVITY_FIELDS_LOGIC_ROLE.NOTE],
        });
        setHasSaved();
        setLoading(false);
      })
      .catch(() => {
        setHasSaved();
        setLoading(false);
        createToast({ message: t('toasts.errorUpdating'), type: 'error' });
      });
  };

  const saveNote = useDebouncedCallback(
    () => {
      if (
        noteRef?.current &&
        noteRef?.current?.[0]?.children?.[0].text &&
        (!serializedNoteValue ||
          JSON.stringify(serializedNoteValue) !== JSON.stringify(noteRef?.current))
      ) {
        const mentions = getMentionsFromNote(noteRef?.current);
        const dataToCreate = noteRef?.current
          ? {
              [ACTIVITY_FIELDS_LOGIC_ROLE.NOTE]: serialize(noteRef?.current, {
                format: 'AST',
                plugins,
              }),
              [ACTIVITY_FIELDS_LOGIC_ROLE.MENTIONS]: mentions,
            }
          : null;
        onSubmit({
          dataToCreate,
          setHasSaved: () => {
            hasSaved.current = true;
          },
        });
      }
    },
    500,
    [onSubmit],
  );

  return {
    note: noteRef?.current,
    setNote: value => {
      noteRef.current = value;
    },
    saveNote,
    loading,
    hasSaved: hasSaved.current,
  };
};
