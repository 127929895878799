import React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckItem, Icon, MultiSelect, Radio, Text } from '@bloobirds-it/flamingo-ui';

import { PHONE_DISTRIUBUTION_TYPES, PhoneNumber } from '../../constants/phoneNumbers.constants';
import styles from '../../styles/phoneNumberPage.module.css';

export const RadioGroupRobin = ({
  phone,
  handleChange,
}: {
  phone: PhoneNumber;
  handleChange: (key: string, value: any) => void;
}) => {
  const { t } = useTranslation();

  const handleRadioChange = (value: string) => {
    handleChange('callDistributionType', value);
    if (value !== PHONE_DISTRIUBUTION_TYPES.ROUND_ROBIN) {
      handleChange('usersRoundRobin', []);
    }
  };

  return (
    <div className={styles.radioLayout}>
      <div className={styles.radioGroupContainer}>
        <Radio
          size="small"
          backgroundColor="veryLightBloobirds"
          value={PHONE_DISTRIUBUTION_TYPES.EVERYONE}
          checked={phone.callDistributionType === PHONE_DISTRIUBUTION_TYPES.EVERYONE}
          onClick={() => handleRadioChange(PHONE_DISTRIUBUTION_TYPES.EVERYONE)}
        >
          {t(
            'accountSettings.phoneNumbers.phoneSettings.tabs.advancedSettings.sectionCallDistribution.ringEveryone',
          )}
        </Radio>
        <Radio
          size="small"
          backgroundColor="veryLightBloobirds"
          value={PHONE_DISTRIUBUTION_TYPES.ROUND_ROBIN}
          checked={phone.callDistributionType === PHONE_DISTRIUBUTION_TYPES.ROUND_ROBIN}
          onClick={() => handleRadioChange(PHONE_DISTRIUBUTION_TYPES.ROUND_ROBIN)}
        >
          {t(
            'accountSettings.phoneNumbers.phoneSettings.tabs.advancedSettings.sectionCallDistribution.ringRoundRobin',
          )}
        </Radio>
        <Radio
          size="small"
          backgroundColor="veryLightBloobirds"
          value={PHONE_DISTRIUBUTION_TYPES.QUEUE}
          checked={phone.callDistributionType === PHONE_DISTRIUBUTION_TYPES.QUEUE}
          onClick={() => handleRadioChange(PHONE_DISTRIUBUTION_TYPES.QUEUE)}
        >
          {t(
            'accountSettings.phoneNumbers.phoneSettings.tabs.advancedSettings.sectionCallDistribution.ringQueue',
          )}
        </Radio>
      </div>
      {phone.callDistributionType === PHONE_DISTRIUBUTION_TYPES.ROUND_ROBIN && (
        <div className={styles.nameAndStatus}>
          <Text size="m" color="softPeanut" weight="regular">
            {t(
              'accountSettings.phoneNumbers.phoneSettings.tabs.advancedSettings.sectionCallDistribution.ringRoundTitle',
            )}
          </Text>
          <Icon name="arrowRight" size={16} color="softPeanut" />
          <MultiSelect
            placeholder="Users"
            selectAllOption
            value={phone.usersRoundRobin.map(user => user.id)}
          >
            {phone.users.map((user, index) => (
              <CheckItem
                key={index}
                onClick={() => {
                  if (phone.usersRoundRobin.some(roundRobinUser => roundRobinUser.id === user.id)) {
                    handleChange(
                      'usersRoundRobin',
                      phone.usersRoundRobin.filter(roundRobinUser => roundRobinUser.id !== user.id),
                    );
                  } else {
                    handleChange('usersRoundRobin', [...phone.usersRoundRobin, user]);
                  }
                }}
                value={user.id}
                checked={phone.usersRoundRobin.some(
                  roundRobinUser => roundRobinUser.id === user.id,
                )}
              >
                {user.name}
              </CheckItem>
            ))}
          </MultiSelect>
        </div>
      )}
    </div>
  );
};
