// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aliasName-module__container___cqNtG {\n  display: flex;\n  align-items: center;\n  height: 24px;\n}\n\n.aliasName-module__container___cqNtG > span {\n  margin-right: auto;\n}\n\n.aliasName-module__container___cqNtG > span {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  height: 24px;\n  gap: 8px\n}\n\n.aliasName-module__container___cqNtG > span >  p {\n  width: 100%;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.aliasName-module__container___cqNtG > * {\n  margin: 0 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/connectionCard/aliasName/aliasName.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,YAAY;EACZ;AACF;;AAEA;EACE,WAAW;EACX,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  height: 24px;\n}\n\n.container > span {\n  margin-right: auto;\n}\n\n.container > span {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  height: 24px;\n  gap: 8px\n}\n\n.container > span >  p {\n  width: 100%;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.container > * {\n  margin: 0 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "aliasName-module__container___cqNtG"
};
export default ___CSS_LOADER_EXPORT___;
