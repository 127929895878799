// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthenticatedScopeApp-module___content___JEfuc {\n  display: flex;\n}\n\n.AuthenticatedScopeApp-module__loader___A1WJM {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n}\n\n.AuthenticatedScopeApp-module__spinner___1vW6n {\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/app/main/AuthenticatedScopeApp.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["._content {\n  display: flex;\n}\n\n.loader {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n}\n\n.spinner {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_content": "AuthenticatedScopeApp-module___content___JEfuc",
	"loader": "AuthenticatedScopeApp-module__loader___A1WJM",
	"spinner": "AuthenticatedScopeApp-module__spinner___1vW6n"
};
export default ___CSS_LOADER_EXPORT___;
