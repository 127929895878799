// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".errorPanel-module__root___OUKFc {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    flex: 1;\n    height: 100%;\n}\n\n.errorPanel-module__text___L1m2q {\n    max-width: 220px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/dashboardPages/v2/panels/errorPanel/errorPanel.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,OAAO;IACP,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".root {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    flex: 1;\n    height: 100%;\n}\n\n.text {\n    max-width: 220px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "errorPanel-module__root___OUKFc",
	"text": "errorPanel-module__text___L1m2q"
};
export default ___CSS_LOADER_EXPORT___;
