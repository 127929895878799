import React from 'react';

import { Dropdown, IconButton, useVisible } from '@bloobirds-it/flamingo-ui';

import { LanguageSetter } from '../quickStartGuideBlocks/components/languageSetter';

const ChangeLanguage = () => {
  const { ref, visible, setVisible } = useVisible(false);

  return (
    <Dropdown
      ref={ref}
      visible={visible}
      anchor={<IconButton name="language" size={36} onClick={() => setVisible(true)} />}
      onClose={() => setVisible(false)}
      position="right-end"
      arrow={false}
    >
      <LanguageSetter />
    </Dropdown>
  );
};

export default ChangeLanguage;
