export const GROUP_COLUMNS = [
  {
    label: 'Name',
    name: 'name',
    sortable: true,
  },
  {
    label: 'Used in',
    name: '',
    sortable: false,
  },
  {
    label: 'Last update',
    name: 'updateDatetime',
    sortable: true,
  },
  {
    label: 'Creation datetime',
    name: 'creationDatetime',
    sortable: true,
  },
  {
    label: 'Status',
    name: '',
    sortable: false,
  },
];

export const BOBJECT_FIELD_GROUPS = ['bobjectFieldGroups'];
