import React from 'react';

import { Spinner, useToasts } from '@bloobirds-it/flamingo-ui';
import { useMessagingTemplate } from '@bloobirds-it/hooks';
import { UserHelperKeys } from '@bloobirds-it/types';

import { useRouter } from '../../../hooks';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { useUserHelpers } from '../../../hooks/useUserHelpers';
import { FORM_MODES, TEMPLATE_TYPES, typeToUrl } from '../../../utils/templates.utils';
import MessagingTemplateForm from './messagingTemplateForm';

const MessagingFormLoader = () => {
  const loadingWrapperStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 500,
  };
  return (
    <div style={loadingWrapperStyle}>
      <Spinner name="loadingCircle" color="purple" />
    </div>
  );
};

const MessagingTemplateFormLayoutContainer = ({ formMode, templateType }) => {
  const { history } = useRouter();
  const templateId = useQueryParam('id');
  const fromUrl = useQueryParam('from');
  const mode = useQueryParam('mode');
  const { createToast } = useToasts();
  const helpers = useUserHelpers();

  const SUCCESS_CODES = new Set([200, 201, 202, 204]);

  const {
    messagingTemplate,
    saveMessagingTemplate,
    deleteMessagingTemplate,
    isLoading,
    error,
  } = useMessagingTemplate(templateId);

  if (formMode === FORM_MODES.EDITION && isLoading) {
    return <MessagingFormLoader />;
  }
  if (formMode === FORM_MODES.CLONE && isLoading) {
    return <MessagingFormLoader />;
  }
  const navigateBack = () => {
    history.push(fromUrl || typeToUrl(templateType));
  };

  return (
    <MessagingTemplateForm
      mode={mode}
      templateType={templateType}
      messagingTemplate={messagingTemplate}
      error={error}
      onSave={async newMessagingTemplate => {
        const res = await saveMessagingTemplate(newMessagingTemplate);
        if (!SUCCESS_CODES.has(res?.status)) {
          createToast({
            type: 'error',
            message: res?.data?.message || 'An error occurred',
          });
        } else {
          createToast({
            type: 'success',
            message:
              newMessagingTemplate?.type === TEMPLATE_TYPES.PITCH
                ? 'Pitch saved successfully'
                : 'Template saved successfully',
          });
          if (newMessagingTemplate?.type === 'EMAIL' && !newMessagingTemplate.id) {
            helpers.save(UserHelperKeys.CREATE_FIRST_EMAIL_TEMPLATE);
          }
          if (newMessagingTemplate?.type === 'PITCH' && !newMessagingTemplate.id) {
            helpers.save(UserHelperKeys.CREATE_YOUR_FIRST_PITCH);
          }
          navigateBack();
        }
      }}
      onDelete={async () => {
        try {
          await deleteMessagingTemplate(templateId);
          createToast({
            type: 'success',
            message: 'Template deleted successfully',
          });
          navigateBack();
        } catch (e) {
          createToast({
            type: 'error',
            message:
              'Could not delete template. It may be caused by the template being used as a suggested template',
          });
        }
      }}
      onCancel={navigateBack}
    />
  );
};

export default MessagingTemplateFormLayoutContainer;
