// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".timelineArea-module__timelineArea___TLV73 {\n  position: relative;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  padding-bottom: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/aiAnalysisPages/leftSideAiAnalysis/mediaAiAnalysis/timelineArea/timelineArea.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,oBAAoB;AACtB","sourcesContent":[".timelineArea {\n  position: relative;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  padding-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timelineArea": "timelineArea-module__timelineArea___TLV73"
};
export default ___CSS_LOADER_EXPORT___;
