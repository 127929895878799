import React from 'react';

import { Icon, Text } from '@bloobirds-it/flamingo-ui';

import { Step } from '../../constants/phoneNumbers.constants';
import styles from '../../styles/phoneNumberPage.module.css';

export const Steps = ({ steps, currentStep }: { steps: Step[]; currentStep: number }) => {
  return (
    <div className={styles.stepsContainer}>
      {steps.map((step, index) => (
        <>
          <div key={index} className={styles.stepInfo}>
            {currentStep === index && <Icon name={step.icon} color="bloobirds" size={18} />}
            <Text
              size="m"
              color={currentStep === index ? 'bloobirds' : 'softPeanut'}
              weight="medium"
            >
              {step.name}
            </Text>
          </div>
          {index === steps.length - 1 ? null : <div className={styles.dividerPhone}></div>}
        </>
      ))}
    </div>
  );
};
