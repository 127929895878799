// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dateText-module___wrapper___mh1RU {\n  margin-left: 32px;\n  margin-right: 16px;\n}\n\n.dateText-module___wrapper___mh1RU > p {\n  display: flex;\n  align-items: flex-start;\n  text-align: right;\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/activitySection/activityItem/dateText/dateText.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":["._wrapper {\n  margin-left: 32px;\n  margin-right: 16px;\n}\n\n._wrapper > p {\n  display: flex;\n  align-items: flex-start;\n  text-align: right;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_wrapper": "dateText-module___wrapper___mh1RU"
};
export default ___CSS_LOADER_EXPORT___;
