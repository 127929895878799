import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Label, SearchInput, Tab, TabGroup, Tooltip } from '@bloobirds-it/flamingo-ui';
import { useActiveAccountId, useManageUserTeamsEnabled } from '@bloobirds-it/hooks';

import { useUserSettings } from '../../../components/userPermissions/hooks';
import { useMediaQuery } from '../../../hooks';
import { useIsAccountAdmin } from '../../../hooks/usePermissions';
import AccountSettingsLayout from '../../../layouts/accountSettingsLayout';
import {
  AccountSettingsTab,
  AccountSettingsTabContent,
  AccountSettingsTabHeader,
  AccountSettingsTabHeaderLeft,
  AccountSettingsTabHeaderRight,
  AccountSettingsTableContainer,
  AccountSettingsTabSubtitle,
  AccountSettingsTabTitle,
} from '../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import NoPermissionsPage from '../../noPermissionsPage';
import GroupsTab from '../fieldsPage/pages/groupsTab/groupsTab';
import { CreateEditUserModal } from './components/createEditUserModal/createEditUserModal';
import { UserCreatedModal } from './components/userCreatedModal/userCreatedModal';
import { UsersList } from './components/usersList/usersList';
import { USER_TYPES } from './constants/users.constants';
import { useCreateEditUserModal } from './hooks/useCreateEditUserModal';
import { useUserCreatedModal } from './hooks/useUserCreatedModal';
import { useUsersList } from './hooks/useUsersList';
import { TeamsPage } from './pages/teams/teamsPage';
import { UsersTabPage } from './pages/users/usersTabPage';
import styles from './styles/usersPage.module.css';

const UsersPage = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(t('accountSettings.salesTeam.usersTab'));
  const isAccountAdmin = useIsAccountAdmin();
  const accountId = useActiveAccountId();
  const { users } = useUsersList({
    filtersBody: {
      userType: USER_TYPES.LICENSE_USER,
    },
  });
  const [searchValue, setSearchValue] = useState();
  const [usersList, setUsersList] = useState(users?.users);
  const manageUserTeams = useManageUserTeamsEnabled(accountId);

  useEffect(() => {
    if (searchValue) {
      const filteredList = users?.users?.filter(user =>
        user.name.toLowerCase().includes(searchValue.toLowerCase()),
      );
      setUsersList(filteredList);
    } else {
      setUsersList(users?.users);
    }
  }, [searchValue, users]);

  if (!isAccountAdmin) {
    return <NoPermissionsPage />;
  }

  return (
    <AccountSettingsLayout
      title={t('accountSettings.salesTeam.title')}
      subtitle={t('accountSettings.salesTeam.subtitle')}
    >
      <TabGroup value={tab} onClick={setTab}>
        <Tab name={t('accountSettings.salesTeam.usersTab')}>
          <UsersTabPage usersList={usersList} setSearchValue={setSearchValue} />
        </Tab>
        {manageUserTeams && (
          <Tab name={t('accountSettings.salesTeam.teamsTab')}>
            <TeamsPage />
          </Tab>
        )}
      </TabGroup>
    </AccountSettingsLayout>
  );
};

export default UsersPage;
