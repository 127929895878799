import { LinkedInMessage } from '@bloobirds-it/types';

import { SALES_NAVIGATOR_PROFILE_URL_MAPPINGS } from '.';
import { normalizeUrl } from '../../../url.utils';
import { transformToNewMessageType } from '../utils';
import extractAuto from './extractAuto';
import { transform } from './transform';

export function getLinkedInUrl(sales_navigator_url: string): Promise<string> {
  return new Promise(resolve => {
    const cache: string = localStorage.getItem(SALES_NAVIGATOR_PROFILE_URL_MAPPINGS);
    const cacheJson: any = cache ? JSON.parse(cache) : {};
    const url = cacheJson[sales_navigator_url];
    if (url) {
      return resolve(normalizeUrl(url));
    }

    resolve(null);
  });
}

export const getSalesNavMessagingSenderMetaObject = async (): Promise<any> => {
  const anchorElement = document.querySelector(
    'a[data-control-name="view_profile"]',
  ) as HTMLAnchorElement;
  if (!anchorElement) {
    return null;
  }
  const profileUrl = anchorElement?.href;

  const salesNavigatorUrl = profileUrl ? normalizeUrl(profileUrl) : '';
  const messageTo = salesNavigatorUrl ? await getLinkedInUrl(salesNavigatorUrl) : null;

  const anchorElementTitle = anchorElement.querySelector('span') as HTMLElement;
  const fullName = anchorElementTitle?.innerText?.trim();
  const anchorElementJobTitle =
    anchorElement !== null
      ? (anchorElement?.querySelector('span[data-anonymize="headline"]') as HTMLSpanElement)
      : null;

  const image = anchorElement.querySelector('img') as HTMLImageElement;

  if (!messageTo && !salesNavigatorUrl) {
    console.log(`Could not find LinkedInUrl for ${fullName}`);
    return;
  }

  return {
    meta: {
      messageTo: messageTo || salesNavigatorUrl,
      salesNavigatorUrl: salesNavigatorUrl,
      nameTo: fullName,
      image: image?.src,
      pathName: window.location.pathname, // Should this be Linkedin's page?
      timestamp: new Date().getTime(),
      jobTitle: anchorElementJobTitle
        ? anchorElementJobTitle.innerText?.replace(/ (at|en) (.*)$/i, '')?.trim()
        : null,
      companyName: anchorElementJobTitle
        ? anchorElementJobTitle.innerText?.replace(/^.*[at |en]/, '')?.trim()
        : null,
    },
  };
};

export default async (
  leadId?: string,
  url?: string,
  currentRef?: any,
): Promise<Array<LinkedInMessage>> => {
  if ((url && url !== normalizeUrl(window.location.href)) || currentRef.current === false) {
    return [];
  }
  const response = await extractAuto();

  if ((url && url !== normalizeUrl(window.location.href)) || currentRef.current === false) {
    return [];
  }

  const object = await getSalesNavMessagingSenderMetaObject();

  if ((url && url !== normalizeUrl(window.location.href)) || currentRef.current === false) {
    return [];
  }

  if (url && new URL(url) && !new URL(url)?.pathname.startsWith(object?.meta?.pathName)) {
    console.log(
      `discarded because different criteria ${object?.meta?.pathName} ${new URL(url)?.pathname}`,
    );
    return [];
  }

  if (!object) {
    return [];
  }

  const oldLinkedInMessages = transform(response, object.meta);
  if ((url && url !== normalizeUrl(window.location.href)) || currentRef.current === false) {
    return [];
  }

  console.log(
    'Found ' + oldLinkedInMessages?.length + ' messages for ' + leadId ||
      object?.meta?.nameTo + ' ' + object?.meta?.messageTo + ', going to sync',
  );

  const messagesToSend = leadId
    ? oldLinkedInMessages?.map(message => ({
        ...message,
        bloobirdsId: leadId,
      }))
    : oldLinkedInMessages;

  return transformToNewMessageType({
    messages: messagesToSend,
    pathName: object.meta.pathName,
    leadName: object.meta.nameTo,
  });
};
