// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".versionOutdated-module___container___x702E {\n  background-color: var(--peanut) !important;\n  color: var(--white) !important;\n  font-weight: bold !important;\n}\n\n.versionOutdated-module___button___nbRqj {\n  font-weight: bold;\n  background-color: inherit;\n  border: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/versionOutdated/versionOutdated.module.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,8BAA8B;EAC9B,4BAA4B;AAC9B;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,SAAS;AACX","sourcesContent":["._container {\n  background-color: var(--peanut) !important;\n  color: var(--white) !important;\n  font-weight: bold !important;\n}\n\n._button {\n  font-weight: bold;\n  background-color: inherit;\n  border: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "versionOutdated-module___container___x702E",
	"_button": "versionOutdated-module___button___nbRqj"
};
export default ___CSS_LOADER_EXPORT___;
