import React from 'react';

import { TestRun } from '@bloobirds-it/types';

import useResults from '../../hooks/useResults';
import styles from '../../playgroundPages.module.css';

const Results = ({ testRun }: { testRun: TestRun }) => {
  const { testSuccessRate, testWarningRate, testErrorRate, testWithoutExpectedRate } = useResults(
    testRun,
  );

  if (!testRun.summarized_results) {
    return null;
  }

  return (
    <span className={styles.testRuns_testRun_results}>
      {testSuccessRate > 0 && (
        <span
          style={{ width: `${testSuccessRate}%` }}
          className={styles.testRuns_testRun_results_success}
        />
      )}
      {testWarningRate > 0 && (
        <span
          style={{ width: `${testWarningRate}%` }}
          className={styles.testRuns_testRun_results_warning}
        />
      )}
      {testErrorRate > 0 && (
        <span
          style={{ width: `${testErrorRate}%` }}
          className={styles.testRuns_testRun_results_error}
        />
      )}
      {testWithoutExpectedRate > 0 && (
        <span
          style={{ width: `${testWithoutExpectedRate}%` }}
          className={styles.testRuns_testRun_results_without_expected}
        />
      )}
    </span>
  );
};

export default Results;
