// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".twilioInstallationPage-module___ellipse___TuSC- {\n  box-sizing: border-box;\n  padding: 20px;\n  background-color: var(--lightBloobirds);\n  width: 72px;\n  height: 72px;\n  border-radius: 50%;\n  margin-bottom: 20px;\n}\n\n.twilioInstallationPage-module___auth_container___QV528 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.twilioInstallationPage-module___auth_header___PI8R0 {\n  margin-bottom: 12px;\n}\n\n.twilioInstallationPage-module___auth_text___Bwtp\\+ {\n  max-width: 510px;\n  margin-bottom: 18px;\n}\n\n.twilioInstallationPage-module___auth_button___2Uhhj {\n  margin: 28px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/dialersPage/twilioIntegration/twilioInstallationPage/twilioInstallationPage.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,uCAAuC;EACvC,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["._ellipse {\n  box-sizing: border-box;\n  padding: 20px;\n  background-color: var(--lightBloobirds);\n  width: 72px;\n  height: 72px;\n  border-radius: 50%;\n  margin-bottom: 20px;\n}\n\n._auth_container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n._auth_header {\n  margin-bottom: 12px;\n}\n\n._auth_text {\n  max-width: 510px;\n  margin-bottom: 18px;\n}\n\n._auth_button {\n  margin: 28px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_ellipse": "twilioInstallationPage-module___ellipse___TuSC-",
	"_auth_container": "twilioInstallationPage-module___auth_container___QV528",
	"_auth_header": "twilioInstallationPage-module___auth_header___PI8R0",
	"_auth_text": "twilioInstallationPage-module___auth_text___Bwtp+",
	"_auth_button": "twilioInstallationPage-module___auth_button___2Uhhj"
};
export default ___CSS_LOADER_EXPORT___;
