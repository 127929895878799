// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".textSelect-module___children_multiselect___sC60G {\n  box-sizing: border-box;\n  padding-top: 24px;\n  padding-bottom: 34px;\n  display: flex;\n  align-items: center;\n  width: fit-content;\n}\n.textSelect-module___children_multiselect___sC60G > * {\n  margin-right: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/integrationLayout/syncSettingsTab/textSelect/textSelect.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,iBAAiB;EACjB,oBAAoB;EACpB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB","sourcesContent":["._children_multiselect {\n  box-sizing: border-box;\n  padding-top: 24px;\n  padding-bottom: 34px;\n  display: flex;\n  align-items: center;\n  width: fit-content;\n}\n._children_multiselect > * {\n  margin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_children_multiselect": "textSelect-module___children_multiselect___sC60G"
};
export default ___CSS_LOADER_EXPORT___;
