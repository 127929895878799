// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".emptyImports-module___container___c7Y3W {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  height: -webkit-fill-available;\n  margin: 15% auto;\n}\n\n.emptyImports-module___paragraph___bX4a4 {\n  font-size: 14px;\n  line-height: 24px;\n  color: var(--softPeanut);\n}\n\n.emptyImports-module___title___rtdJU {\n  font-size: 20px;\n  line-height: 24px;\n  color: var(--peanut);\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/importHistory/emptyImports/emptyImports.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":["._container {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  height: -webkit-fill-available;\n  margin: 15% auto;\n}\n\n._paragraph {\n  font-size: 14px;\n  line-height: 24px;\n  color: var(--softPeanut);\n}\n\n._title {\n  font-size: 20px;\n  line-height: 24px;\n  color: var(--peanut);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "emptyImports-module___container___c7Y3W",
	"_paragraph": "emptyImports-module___paragraph___bX4a4",
	"_title": "emptyImports-module___title___rtdJU"
};
export default ___CSS_LOADER_EXPORT___;
