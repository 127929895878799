export const PROSPECT_PAGES = {
  COMPANY_DELIVERED: 'COMPANY_DELIVERED',
  COMPANY_ON_CADENCE: 'COMPANY_ON_CADENCE',
  COMPANY_SCHEDULED: 'COMPANY_SCHEDULED',
  COMPANY_MEETING_TASKS: 'COMPANY_MEETING_TASKS',
  COMPANY_NURTURING: 'COMPANY_NURTURING',
  COMPANY_INACTIVE: 'COMPANY_INACTIVE',
  COMPANY_ALL_MY_COMPANIES: 'COMPANY_ALL_MY_COMPANIES',
  LEAD_DELIVERED: 'LEAD_DELIVERED',
  LEAD_INACTIVE: 'LEAD_INACTIVE',
  LEAD_ALL_MY_COMPANIES: 'LEAD_ALL_MY_COMPANIES',
};
