import { api } from './api';

// TODO: Add provider as how we were doing it before was not working
export const fetchAndOpenNylasUrl = () => {
  api.get('/utils/nylas/generate-url').then(payload => {
    window.open(payload.data.url);
  });
};

export const fetchAndOpenNylasReconnectUrl = (nylasAccountId: string) => {
  api.post(`/new-email/migrate/reconnect/${nylasAccountId}`).then(payload => {
    window.open(payload.data.url);
  });
};

export const fetchAndOpenUserNylasReconnectUrl = (userId: string) => {
  api.post(`/new-email/migrate/reconnectUser/${userId}`).then(payload => {
    window.open(payload.data.url);
  });
};

export const fetchAndOpenLegacyUrl = () => {
  api.get('/utils/service/gmail/connections/endpoint').then(payload => {
    window.open(payload.data.endpoint);
  });
};
