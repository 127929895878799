import api from "!../../../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../../node_modules/babel-loader/lib/index.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!./deleteCompaniesRelatedModal.css";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};