// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.cadenceFilters-module__typeFilters___j0bQN {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 8px\n}\n\n.cadenceFilters-module__typeFilters___j0bQN span {\n  margin-bottom: 0;\n}\n\n.cadenceFilters-module__authorFilter___WSkuF {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 8px\n}\n\n.cadenceFilters-module__authorFilter___WSkuF > div > div[class^='SmallSelect-module_container'] {\n  height: 26px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/cadencesPage/components/filters/cadenceFilters.module.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB;AACF;;AAEA;EACE,YAAY;AACd","sourcesContent":["\n.typeFilters {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 8px\n}\n\n.typeFilters span {\n  margin-bottom: 0;\n}\n\n.authorFilter {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 8px\n}\n\n.authorFilter > div > div[class^='SmallSelect-module_container'] {\n  height: 26px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"typeFilters": "cadenceFilters-module__typeFilters___j0bQN",
	"authorFilter": "cadenceFilters-module__authorFilter___WSkuF"
};
export default ___CSS_LOADER_EXPORT___;
