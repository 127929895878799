// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".changeSignatureModal-module__footer___ik0Ml {\n  padding-top: 24px;\n}\n\n.changeSignatureModal-module__select___-a4eJ {\n  margin-top: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/connectionCard/changeSignatureModal/changeSignatureModal.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".footer {\n  padding-top: 24px;\n}\n\n.select {\n  margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "changeSignatureModal-module__footer___ik0Ml",
	"select": "changeSignatureModal-module__select___-a4eJ"
};
export default ___CSS_LOADER_EXPORT___;
