// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".messagingTab-module__header___D4zgp {\n  display: flex;\n  align-items: center;\n  margin-top: 24px;\n  margin-bottom: 16px;\n}\n\n.messagingTab-module__title___TFoed {\n  flex-shrink: 0;\n}\n\n.messagingTab-module__actions___z6dCi {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-left: 16px;\n  width: 100%;\n}\n\n.messagingTab-module__emailButton___ztxWr {\n  flex-shrink: 0;\n}\n\n.messagingTab-module__switches___6I-9M {\n  display: flex;\n}\n\n.messagingTab-module__switches___6I-9M > div {\n  margin-right: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/contactPages/contactTabs/messagingTabs/messagingTab/messagingTab.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  margin-top: 24px;\n  margin-bottom: 16px;\n}\n\n.title {\n  flex-shrink: 0;\n}\n\n.actions {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-left: 16px;\n  width: 100%;\n}\n\n.emailButton {\n  flex-shrink: 0;\n}\n\n.switches {\n  display: flex;\n}\n\n.switches > div {\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "messagingTab-module__header___D4zgp",
	"title": "messagingTab-module__title___TFoed",
	"actions": "messagingTab-module__actions___z6dCi",
	"emailButton": "messagingTab-module__emailButton___ztxWr",
	"switches": "messagingTab-module__switches___6I-9M"
};
export default ___CSS_LOADER_EXPORT___;
