import React from 'react';
import { useQueryParams } from '../../../../hooks';
import MessagingTemplateFormLayout from '../../../../layouts/messagingSectionLayout/messagingTemplateFormLayout';
import { TEMPLATE_TYPES } from '../../../../utils/templates.utils';

const SnippetFormContainer = () => {
  const queryParams = useQueryParams();

  return (
    <MessagingTemplateFormLayout
      templateType={TEMPLATE_TYPES.SNIPPET}
      formMode={queryParams.get('mode')}
    />
  );
};

export default SnippetFormContainer;
