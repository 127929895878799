// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".customMappingRow-module___first_cell___1EDtw {\n  display: flex;\n  justify-content: space-between;\n}\n\n.customMappingRow-module___align_row_text___ehrbt > p {\n  padding-top: 4px;\n}\n\n.customMappingRow-module___buttons___K44\\+x {\n  display: flex;\n  padding-top: 4px;\n}\n\n.customMappingRow-module___buttons___K44\\+x > button {\n  margin-left: 6px;\n  height: 24px;\n}\n.customMappingRow-module___buttons___K44\\+x > button + svg {\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/integrationLayout/fieldMappingTab/customMappingRow/customMappingRow.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,SAAS;AACX","sourcesContent":["._first_cell {\n  display: flex;\n  justify-content: space-between;\n}\n\n._align_row_text > p {\n  padding-top: 4px;\n}\n\n._buttons {\n  display: flex;\n  padding-top: 4px;\n}\n\n._buttons > button {\n  margin-left: 6px;\n  height: 24px;\n}\n._buttons > button + svg {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_first_cell": "customMappingRow-module___first_cell___1EDtw",
	"_align_row_text": "customMappingRow-module___align_row_text___ehrbt",
	"_buttons": "customMappingRow-module___buttons___K44+x"
};
export default ___CSS_LOADER_EXPORT___;
