// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inactiveTab-module___info_icon___Qa\\+4h {\n  cursor: pointer;\n}\n\n.inactiveTab-module___clear_button___LT7eN {\n  border: 1px solid white;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/discoveryTooltips/inactiveTab.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["._info_icon {\n  cursor: pointer;\n}\n\n._clear_button {\n  border: 1px solid white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_info_icon": "inactiveTab-module___info_icon___Qa+4h",
	"_clear_button": "inactiveTab-module___clear_button___LT7eN"
};
export default ___CSS_LOADER_EXPORT___;
