// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.reconnectEmailModal-module__content___DpQ\\+q {\n  padding: 16px !important;\n}\n\n.reconnectEmailModal-module__content___DpQ\\+q > div {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.reconnectEmailModal-module__callout___YiqGD {\n  display: flex;\n  gap: 16px;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n}\n\n\n.reconnectEmailModal-module__footer___og511 {\n  display: flex;\n  align-items: center;\n  padding: 16px !important;\n  justify-content: space-between !important;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/connectionCard/reconnectEmailModal/reconnectEmailModal.module.css"],"names":[],"mappings":";AACA;EACE,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,SAAS;EACT,WAAW;EACX,uBAAuB;EACvB,mBAAmB;AACrB;;;AAGA;EACE,aAAa;EACb,mBAAmB;EACnB,wBAAwB;EACxB,yCAAyC;AAC3C","sourcesContent":["\n.content {\n  padding: 16px !important;\n}\n\n.content > div {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.callout {\n  display: flex;\n  gap: 16px;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n}\n\n\n.footer {\n  display: flex;\n  align-items: center;\n  padding: 16px !important;\n  justify-content: space-between !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "reconnectEmailModal-module__content___DpQ+q",
	"callout": "reconnectEmailModal-module__callout___YiqGD",
	"footer": "reconnectEmailModal-module__footer___og511"
};
export default ___CSS_LOADER_EXPORT___;
