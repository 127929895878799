// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qualifyingQuestions-module__qualifyingQuestionList___mbkde {\n  width: 80%;\n  max-width: 480px;\n  margin: 0 auto;\n  padding-bottom: 156px;\n}\n\n.qualifyingQuestions-module__emptyContainer___PHuH3 {\n  margin: 64px auto;\n  padding-bottom: 156px;\n}\n\n.qualifyingQuestions-module__footbar___alNU1 {\n  width: calc(100% - 96px);\n  background: var(--verySoftPurple);\n  position: absolute;\n  bottom: 0;\n  left: 96px;\n  padding: 10px 0;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.qualifyingQuestions-module__footbar___alNU1 button {\n  margin-right: 24px;\n}\n\n.qualifyingQuestions-module__footbar_displaced___0PpLr button:last-child {\n  margin-right: 100px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/qualifyingQuestions/qualifyingQuestions.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;EACxB,iCAAiC;EACjC,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,eAAe;EACf,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".qualifyingQuestionList {\n  width: 80%;\n  max-width: 480px;\n  margin: 0 auto;\n  padding-bottom: 156px;\n}\n\n.emptyContainer {\n  margin: 64px auto;\n  padding-bottom: 156px;\n}\n\n.footbar {\n  width: calc(100% - 96px);\n  background: var(--verySoftPurple);\n  position: absolute;\n  bottom: 0;\n  left: 96px;\n  padding: 10px 0;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.footbar button {\n  margin-right: 24px;\n}\n\n.footbar_displaced button:last-child {\n  margin-right: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qualifyingQuestionList": "qualifyingQuestions-module__qualifyingQuestionList___mbkde",
	"emptyContainer": "qualifyingQuestions-module__emptyContainer___PHuH3",
	"footbar": "qualifyingQuestions-module__footbar___alNU1",
	"footbar_displaced": "qualifyingQuestions-module__footbar_displaced___0PpLr"
};
export default ___CSS_LOADER_EXPORT___;
