// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".labelsDropdown-module___dropdown_wrapper___IFj8y {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n.labelsDropdown-module___label_wrapper___Pl5Zy {\n  margin-right: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/labelsDropdown/labelsDropdown.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["._dropdown_wrapper {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n._label_wrapper {\n  margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_dropdown_wrapper": "labelsDropdown-module___dropdown_wrapper___IFj8y",
	"_label_wrapper": "labelsDropdown-module___label_wrapper___Pl5Zy"
};
export default ___CSS_LOADER_EXPORT___;
