// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subhomeLayout-module___container___1rEaV {\n  width: 100%;\n  background-color: var(--lighterGray);\n  display: flex;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/subhomeLayout/subhomeLayout.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,oCAAoC;EACpC,aAAa;EACb,mBAAmB;AACrB","sourcesContent":["._container {\n  width: 100%;\n  background-color: var(--lighterGray);\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "subhomeLayout-module___container___1rEaV"
};
export default ___CSS_LOADER_EXPORT___;
