import React from 'react';
import { useTranslation } from 'react-i18next';

import { MarkdownReader } from '@bloobirds-it/copilot';
import { Icon, Text } from '@bloobirds-it/flamingo-ui';
import {
  AIResult,
  CopilotInsight,
  InsightType,
  PicklistField,
  TestSetActivity,
  TestRun,
} from '@bloobirds-it/types';
import useSWR from 'swr';

import styles from '../../playgroundPages.module.css';
import ExpectedValue from '../results/activityTab/expectedValue';
import ResultCell from '../results/activityTab/resultCell';
import { generateDecisionChips } from '../results/activityTab/utils';
import { getResult } from '../results/activityTab/utils';

export const Result = ({
  activityResult,
  selectedInsight,
  selectedRun,
}: {
  activityResult: AIResult;
  selectedInsight: CopilotInsight;
  selectedRun: TestRun;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground.activityTab' });
  const isDecision = selectedInsight?.insightType === InsightType.DECISION;
  const isGeneration = selectedInsight?.insightType === InsightType.GENERATION;
  const hasError = activityResult?.error || activityResult?.result_tag === 'FAILURE_PROCESSING';
  const { data: picklistField } = useSWR<PicklistField>(
    selectedInsight?.activityField && `/utils/picklists/${selectedInsight?.activityField}/type`,
    key => api.get<PicklistField>(key).then(res => res.data),
  );

  let results;
  if (selectedInsight?.insightType === InsightType.DECISION) {
    results = generateDecisionChips(activityResult, picklistField);
  } else if (selectedInsight?.insightType === InsightType.GENERATION) {
    results = activityResult?.result;
  }
  if (hasError) {
    results = t('error') + activityResult?.error;
  }

  return (
    <div className={styles.result_tab_container}>
      <Text className={styles.textWithIcon} weight="bold" size="s">
        <Icon name="stars" color="purple" />
        {selectedInsight?.title}
      </Text>
      <div>
        {isDecision && !hasError && (
          <div className={styles.testRun_activities_results}>
            <ExpectedValue
              activityResults={activityResult}
              activityId={activityResult?.activity_id}
              insightId={selectedInsight?.id}
              picklistField={picklistField}
              testSetId={selectedRun?.test_set_id}
            />
          </div>
        )}
        {(selectedRun.status !== 'NOT_STARTED' || isDecision) && (
          <ResultCell
            hasError={hasError}
            result={getResult(activityResult?.result_tag)}
            insightType={selectedInsight?.insightType}
            activityResults={activityResult}
            selectedRunId={selectedRun.pk}
            testSetId={selectedRun.test_set_id}
            withLabel
          />
        )}
      </div>
      <div className={styles.dividerHorizontal} />
      {isGeneration && selectedRun.status === 'COMPLETED' && <MarkdownReader text={results} />}
      <div className={styles.testRun_activities_results}>
        {isDecision &&
          selectedRun.status === 'COMPLETED' &&
          (results || t('ai.playground.activityTab.processing'))}
      </div>
    </div>
  );
};
