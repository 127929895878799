import React, { useState } from 'react';

import { Icon, IconButton, Text, useHover } from '@bloobirds-it/flamingo-ui';
import { CopilotInsightVersion } from '@bloobirds-it/types';
import clsx from 'clsx';
import spacetime from 'spacetime';

import styles from '../../playgroundPages.module.css';
import { DeleteConfirmationModal } from './deleteVersionModal';

export const InsightCardHistory = ({
  version,
  isSelected,
  setSelectedVersion,
  onSetAsActive,
  isLoading,
}: {
  version: CopilotInsightVersion;
  isSelected: boolean;
  setSelectedVersion: (version: CopilotInsightVersion) => void;
  onSetAsActive: (id: string) => void;
  isLoading: boolean;
}) => {
  const [ref, isHovering] = useHover();
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);

  const classes = clsx(styles.version_card, {
    [styles.active]: isSelected,
  });

  return (
    <>
      <div
        ref={ref}
        className={classes}
        onClick={() => {
          setSelectedVersion(version);
        }}
      >
        <div className={styles.version_card_title}>
          <Text size="s" weight="bold" color="peanut">
            Version {version.version}
          </Text>
          <div className={styles.flexContainer} style={{ flexWrap: 'wrap' }}>
            {(version.isActiveVersion || isHovering) && (
              <IconButton
                disabled={isLoading}
                name={version.isActiveVersion ? 'starChecked' : 'starUnchecked'}
                size={16}
                onClick={version.isActiveVersion ? null : () => onSetAsActive(version.id)}
                color="purple"
              />
            )}
            {!version.isActiveVersion && isHovering && !isSelected && (
              <IconButton
                disabled={isLoading}
                name="trashFull"
                size={16}
                onClick={
                  version.isActiveVersion ? null : () => setOpenDeleteConfirmationModal(true)
                }
                color="tomato"
              />
            )}
          </div>
        </div>
        <div className={styles.flexContainer} style={{ gap: '4px' }}>
          <Icon name="clock" color="softPeanut" size={18} />
          <Text size="s">{spacetime(version.createdAt).format('nice')}</Text>
        </div>
      </div>
      {openDeleteConfirmationModal && (
        <DeleteConfirmationModal
          version={version}
          onClose={() => setOpenDeleteConfirmationModal(false)}
        />
      )}
    </>
  );
};
