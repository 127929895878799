import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextArea } from '@bloobirds-it/flamingo-ui';

import { useRequestNumberFormContext } from '../../../context/requestedPhonesFormProvider';
import styles from '../../../styles/phoneNumberPage.module.css';

export const AdditionalInformation = () => {
  const { t } = useTranslation();

  const { phoneNumber, handleChangePhone } = useRequestNumberFormContext();

  return (
    <div className={styles.additionalInfoContainer}>
      <TextArea
        className={styles.textArea}
        value={phoneNumber.additionalInformation}
        removePlaceholder
        width="100%"
        placeholder={t(
          'accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.additionalInformation.placeholder',
        )}
        onChange={value => handleChangePhone('additionalInformation', value)}
        minRows={3}
      />
    </div>
  );
};
