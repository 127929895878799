import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tab, TabGroup } from '@bloobirds-it/flamingo-ui';

import { useIsAccountAdmin } from '../../../hooks/usePermissions';
import AccountSettingsLayout from '../../../layouts/accountSettingsLayout';
import NoPermissionsPage from '../../noPermissionsPage';
import { MainObjectsTable } from './mainObjects/mainObjectsTable';
import { RelatedObjectsTable } from './relatedObjects/relatedObjectsTable';

const RelatedObjects = () => {
  const isAccountAdmin = useIsAccountAdmin();
  const { t } = useTranslation();

  if (!isAccountAdmin) {
    return <NoPermissionsPage />;
  }

  return (
    <AccountSettingsLayout title={t('accountSettings.relatedObjects.title')} subtitle={''}>
      <TabGroup>
        <Tab name={t('accountSettings.tabs.salesforceMainObjects')}>
          <MainObjectsTable />
        </Tab>
        <Tab name={t('accountSettings.tabs.salesforceRelatedObjects')}>
          <RelatedObjectsTable />
        </Tab>
      </TabGroup>
    </AccountSettingsLayout>
  );
};

export default RelatedObjects;
