export enum PRODUCT_FIELDS_LOGIC_ROLE {
  CATEGORY = 'PRODUCT__CATEGORY',
  NAME = 'PRODUCT__NAME',
  PRICE = 'PRODUCT__PRICE',
  VAT = 'PRODUCT__VAT',
  SALESFORCE_PRICEBOOK_ID = 'PRODUCT__SALESFORCE_PRICEBOOK_ID',
  SALESFORCE_PRICEBOOK_ENTRY_ID = 'PRODUCT__SALESFORCE_PRICEBOOK_ENTRY_ID',
  SALESFORCE_PRODUCT_ID = 'PRODUCT__SALESFORCE_PRODUCT_ID',
  HUBSPOT_PRODUCT_ID = 'PRODUCT__HUBSPOT_PRODUCT_ID',
}
