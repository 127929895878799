// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".name-module___container___Tgjjb {\n  text-decoration: none;\n  color: var(--bloobirds);\n  margin-right: 8px;\n  text-align: justify;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  display: block;\n  white-space: nowrap;\n}\n\n.name-module___container___Tgjjb:hover {\n  cursor: pointer;\n}\n\n.name-module___container___Tgjjb > p {\n  text-overflow: ellipsis;\n  overflow-x: hidden;\n}\n\n.name-module___is_complete___fBmLp,\n.name-module___is_complete___fBmLp > * {\n  text-decoration: line-through;\n  text-align: left;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/name/name.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,uBAAuB;EACvB,iBAAiB;EACjB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;;EAEE,6BAA6B;EAC7B,gBAAgB;AAClB","sourcesContent":["._container {\n  text-decoration: none;\n  color: var(--bloobirds);\n  margin-right: 8px;\n  text-align: justify;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  display: block;\n  white-space: nowrap;\n}\n\n._container:hover {\n  cursor: pointer;\n}\n\n._container > p {\n  text-overflow: ellipsis;\n  overflow-x: hidden;\n}\n\n._is_complete,\n._is_complete > * {\n  text-decoration: line-through;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "name-module___container___Tgjjb",
	"_is_complete": "name-module___is_complete___fBmLp"
};
export default ___CSS_LOADER_EXPORT___;
