// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content-module___root___O3s8O {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  margin-bottom: 112px;\n  padding-right: 5%;\n}\n\n.content-module___title___HKOCu {\n  margin: 40px 0 0 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/importForm/content/content.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["._root {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  margin-bottom: 112px;\n  padding-right: 5%;\n}\n\n._title {\n  margin: 40px 0 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_root": "content-module___root___O3s8O",
	"_title": "content-module___title___HKOCu"
};
export default ___CSS_LOADER_EXPORT___;
