// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".settingsTab-module___subtitle_run_once___PANDS {\n  margin-top: 6px;\n}\n\n.settingsTab-module___edit_header___zj7a6 {\n  margin-top: 24px;\n}\n\n.settingsTab-module___bottom_filler___lJmk6 {\n  height: 343px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/playbookPages/automationsPages/workflowEditionPage/settingsTabFolder/settingsTab.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf","sourcesContent":["._subtitle_run_once {\n  margin-top: 6px;\n}\n\n._edit_header {\n  margin-top: 24px;\n}\n\n._bottom_filler {\n  height: 343px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_subtitle_run_once": "settingsTab-module___subtitle_run_once___PANDS",
	"_edit_header": "settingsTab-module___edit_header___zj7a6",
	"_bottom_filler": "settingsTab-module___bottom_filler___lJmk6"
};
export default ___CSS_LOADER_EXPORT___;
