import React from 'react';
import { useTranslation } from 'react-i18next';

import { useActivityFieldsInfo } from '@bloobirds-it/copilot';
import { Icon, Label, Text, Tooltip } from '@bloobirds-it/flamingo-ui';
import { INSIGHT_TYPE_OPTIONS, INSIGHT_STATE_OPTIONS, CopilotInsight } from '@bloobirds-it/types';
import clsx from 'clsx';

import styles from '../../playgroundPages.module.css';

const InsightCard = ({
  insight,
  isSelected,
  handleClick,
}: {
  insight: CopilotInsight;
  isSelected: boolean;
  handleClick: () => void;
}) => {
  const { t: tBase } = useTranslation();
  const { t } = useTranslation('translation', { keyPrefix: 'ai.insightCard' });
  const { meetingTypes } = useActivityFieldsInfo();

  const { id, title, state, insightType, activityType, activitySubtype, category } = insight;

  const classes = clsx(styles.insight_card, {
    [styles.active]: isSelected,
  });

  const stateActive = INSIGHT_STATE_OPTIONS(tBase).find(option => option.id === state);
  const typeActive = INSIGHT_TYPE_OPTIONS(tBase).find(option => option.id === insightType);

  if (!stateActive || !typeActive) {
    return null;
  }

  const subtype = meetingTypes.get(activitySubtype);

  return (
    <div key={id} className={classes} onClick={handleClick}>
      <div className={styles.insight_card_row}>
        <Text size="s" weight="heavy">
          {title}
        </Text>
      </div>
      <div className={styles.insight_card_row}>
        <div>
          <Icon name="stars" size={14} color="softPeanut" />
          <Text size="s" weight="bold">
            {t('type')}:
          </Text>
          <Label
            size="small"
            color={typeActive.color}
            textColor={typeActive.textColor}
            icon={typeActive.icon}
            iconColor={typeActive.iconColor}
            iconSize={14}
            uppercase={false}
          >
            {typeActive.name}
          </Label>
        </div>
        <div>
          <Icon name="activity" size={14} color="softPeanut" />
          <Text size="s" weight="bold">
            {t('status')}:
          </Text>
          {stateActive && (
            <Text size="s" weight="medium" color={stateActive.textColor}>
              {stateActive.name}
            </Text>
          )}
        </div>
      </div>
      <div className={styles.insight_card_row}>
        <div>
          <Icon name="label" size={14} color="softPeanut" />
          <Text size="s" weight="bold">
            {t('category')}:
          </Text>
          <Text size="s" color="peanut">
            {category ? tBase(`ai.insightCategory.${category}`) : 'N/A'}
          </Text>
        </div>
      </div>
      <div className={styles.insight_card_row}>
        <div>
          <Icon name="gridSquares" size={14} color="softPeanut" />
          <Text size="s" weight="bold">
            {t('activityType')}:
          </Text>
          <Tooltip
            title={
              subtype
                ? `${tBase(`ai.insightActivity.${activityType}`, { count: 2 })} - ${subtype?.name}`
                : ''
            }
            position="top"
          >
            <Text size="s" color="peanut">
              {tBase(`ai.insightActivity.${activityType}`, { count: 2 })}{' '}
              {subtype ? `- ${subtype?.name}` : ''}
            </Text>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default InsightCard;
