import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Text } from '@bloobirds-it/flamingo-ui';
import { useTogglerState } from '@bloobirds-it/hooks';
import {
  CopilotInsight,
  DataModelResponse,
  PicklistField,
  TestRun,
  TestSet,
} from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import clsx from 'clsx';
import useSWR from 'swr';

import styles from '../../../playgroundPages.module.css';
import { ActivityCarouselTestSetReviewModal } from '../../activityCarouselTestSetReview/activityCarouselTestSetReviewModal';
import ExpectedValue from './expectedValue';
import ResultCell from './resultCell';
import { generateDecisionChips, getResult, InsightType } from './utils';

interface IActivityRowProps {
  key: string;
  activityId: string;
  selectedRun: TestRun;
  selectedInsight: CopilotInsight;
  selectedTestSet: TestSet;
  dataModel: DataModelResponse;
  idx: number;
}

const ActivityRow = ({
  key,
  activityId,
  selectedRun,
  selectedInsight,
  selectedTestSet,
  dataModel,
  idx,
}: IActivityRowProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground.activityTab' });
  const [expanded, toggle] = useTogglerState(false);

  const activityResults = selectedRun?.results[activityId];
  const hasError = activityResults?.error || activityResults?.result_tag === 'FAILURE_PROCESSING';

  const { data: picklistField } = useSWR<PicklistField>(
    selectedInsight?.activityField && `/utils/picklists/${selectedInsight?.activityField}/type`,
    key => api.get<PicklistField>(key).then(res => res.data),
  );

  let results;
  if (selectedInsight?.insightType === InsightType.DECISION) {
    results = generateDecisionChips(activityResults, picklistField);
  } else if (selectedInsight?.insightType === InsightType.GENERATION) {
    results = activityResults?.result;
  }
  if (hasError) {
    results = t('error') + activityResults?.error;
  }

  const buttonClasses = clsx(styles.testRun_activities_results_icon, {
    [styles.expanded]: expanded,
  });

  const rowClasses = clsx(styles.testRun_activities_row, {
    [styles.error]: hasError,
    [styles.selected]: expanded,
  });

  const isDecision = selectedInsight?.insightType === InsightType.DECISION;
  const isGeneration = selectedInsight?.insightType === InsightType.GENERATION;

  const classes = clsx(styles.testRun_activities_results, {
    [styles.ellipsis]: isGeneration,
  });

  return (
    <Fragment key={key}>
      <tr key={activityId} className={rowClasses} onClick={toggle}>
        <td className={styles.rowNumberCell}>
          <Text size="s" weight="bold">
            {idx + 1}
          </Text>
        </td>
        <td className={classes}>
          {selectedRun.status === 'NOT_STARTED' && <>{t('resultsNotStarted')}</>}
          {selectedRun.status === 'COMPLETED' && (results || t('processing'))}
        </td>
        {selectedRun.status === 'NOT_STARTED' && isGeneration && (
          <td className={styles.testRun_activities_result_right}>
            {t('evaluateResultsNotStarted')}
          </td>
        )}
        {isDecision && !hasError && (
          <td>
            <div className={styles.testRun_activities_results}>
              <ExpectedValue
                activityResults={activityResults}
                activityId={activityId}
                insightId={selectedInsight?.id}
                picklistField={picklistField}
                testSetId={selectedRun?.test_set_id}
              />
            </div>
          </td>
        )}
        {(selectedRun.status !== 'NOT_STARTED' || isDecision) && (
          <td className={styles.testRun_activities_result_status}>
            <ResultCell
              hasError={hasError}
              result={getResult(activityResults?.result_tag)}
              insightType={selectedInsight?.insightType}
              activityResults={activityResults}
              selectedRunId={selectedRun.pk}
              testSetId={selectedRun.test_set_id}
            />
          </td>
        )}
        <td>
          <div className={buttonClasses}>
            <IconButton
              name="chevronOpen"
              size={18}
              color="purple"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                toggle();
              }}
            />
          </div>
        </td>
      </tr>

      {expanded && (
        <ActivityCarouselTestSetReviewModal
          activityResults={selectedRun?.results || {}}
          onClose={toggle}
          activityType={selectedInsight?.activityType}
          selectedTestSet={selectedTestSet}
          selectedTestRun={selectedRun}
          selectedInsight={selectedInsight}
          activityIndex={idx}
        />
      )}
    </Fragment>
  );
};

export default ActivityRow;
