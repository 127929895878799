import React, { useEffect, useState } from 'react';

import { usePausePeriods, useEmailConnections } from '@bloobirds-it/hooks';
import { APP_ACCOUNT_SALES_TEAM, UserRole } from '@bloobirds-it/types';
import { checkTZMatching } from '@bloobirds-it/utils';

import { APP_MANAGEMENT_USER } from '../../app/_constants/routes';
import { useRouter } from '../../hooks';
import SessionManagerFactory from '../../misc/session';
import AlertBanner from '../alertBanner';
import { useUserSettings } from '../userPermissions/hooks';
import AlertMessage from './alertMessage';

const SessionManager = SessionManagerFactory();

const AccountAlertsView = () => {
  const { stoppedConnections } = useEmailConnections();
  const { periods } = usePausePeriods({ userId: SessionManager?.getUser()?.id });
  const settings = useUserSettings();
  const router = useRouter();
  const [alerts, setAlerts] = useState([]);

  const setUniqAlerts = alert => {
    if (alerts?.find(a => a?.key === alert?.key)) return;
    setAlerts(alerts => [...alerts, alert]);
  };

  const onMessageClick = (value, e) => {
    router.push(APP_MANAGEMENT_USER, { event: e });
  };

  const onEmailReconnectClick = (value, e) => {
    router.push(APP_MANAGEMENT_USER + '?tab=EMAIL', { event: e });
  };

  const onAdminEmailReconnectClick = (value, e) => {
    router.push(APP_ACCOUNT_SALES_TEAM, { event: e });
  };

  const checkAlertExistance = type => alerts.find(alert => alert.key === type);

  useEffect(() => {
    const alertType = 'nylasStopped';
    if (stoppedConnections?.length > 0) {
      setAlerts([
        ...alerts,
        {
          key: alertType,
          type: 'negative',
          message: <AlertMessage type={alertType} onMessageClick={onEmailReconnectClick} />,
        },
      ]);
    }
  }, [stoppedConnections]);

  useEffect(() => {
    const alertType = 'differentTimeZone';
    const timeZoneAlert = checkAlertExistance(alertType);
    const isOnMac = window.navigator.platform.indexOf('Mac') !== -1;
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const settingsTimezone = settings?.user?.timeZone?.trim();
    const matchingTimezone = checkTZMatching(userTimezone, settingsTimezone);

    if (
      settings &&
      (isOnMac ? !matchingTimezone : settingsTimezone !== userTimezone) &&
      !timeZoneAlert
    ) {
      setAlerts([
        ...alerts,
        {
          key: alertType,
          type: 'alert',
          message: (
            <AlertMessage
              type={alertType}
              onMessageClick={onMessageClick}
              options={{
                userTimezone,
                settingsTimezone: settings?.user?.timeZone,
              }}
            />
          ),
        },
      ]);
    }

    if (settings && settings?.user?.reconnectEmail) {
      setUniqAlerts({
        key: 'requiresReconnection',
        type: 'alert',
        message: (
          <AlertMessage type="requiresReconnection" onMessageClick={onEmailReconnectClick} />
        ),
      });
    }

    if (
      settings &&
      settings?.user?.reconnectUsersEmail &&
      (settings?.user?.roles?.includes(UserRole.GLOBAL_ADMIN) ||
        settings?.user?.roles?.includes(UserRole.ACCOUNT_ADMIN))
    ) {
      setUniqAlerts({
        key: 'requiresReconnectionAdmin',
        type: 'alert',
        message: (
          <AlertMessage
            type="requiresReconnectionAdmin"
            onMessageClick={onAdminEmailReconnectClick}
          />
        ),
      });
    }
  }, [settings]);

  useEffect(() => {
    const alertType = 'notInChrome';
    if (!window?.chrome) {
      setAlerts([
        ...alerts,
        {
          key: alertType,
          type: 'alert',
          message: <AlertMessage type={alertType} onMessageClick={onMessageClick} />,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    const alertType = 'userPaused';
    const periodAlert = checkAlertExistance(alertType);
    const onMessageClickCadence = (value, e) => {
      router.push(APP_MANAGEMENT_USER + '?tab=CADENCE', { event: e });
    };
    if (
      periods.isUserCurrentlyPaused &&
      !periodAlert &&
      periods.pausedUserId === SessionManager?.getUser()?.id
    ) {
      setAlerts([
        ...alerts,
        {
          key: alertType,
          type: 'alert',
          message: (
            <AlertMessage
              type={alertType}
              onMessageClick={onMessageClickCadence}
              options={{
                date: periods.currentPausedPeriod,
              }}
            />
          ),
        },
      ]);
    }
  }, [periods]);

  return <>{alerts && alerts.map(alert => <AlertBanner key={alert.key} {...alert} />)}</>;
};

export default AccountAlertsView;
