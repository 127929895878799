import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconType, Text } from '@bloobirds-it/flamingo-ui';
import {
  useActiveAccountId,
  useActivityUserMentionsEnabled,
  useUserMentions,
} from '@bloobirds-it/hooks';
import {
  EditorToolbar,
  EditorToolbarControlsSection,
  EditorToolbarFontStylesSection,
  EditorToolbarTextMarksSection,
  EditorToolbarListsSection,
  RichTextEditor,
  useRichTextEditorPlugins,
  deserialize,
  serialize,
  isEmptyText,
} from '@bloobirds-it/rich-text-editor';
import {
  Bobject,
  ACTIVITY_FIELDS_LOGIC_ROLE,
  BobjectTypes,
  MessagesEvents,
} from '@bloobirds-it/types';
import {
  api,
  createParagraph,
  isHtml,
} from '@bloobirds-it/utils';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import debounce from 'lodash/debounce';

import styles from './noteEditor.module.css';
import { getMentionsFromNote } from '../../utils/notes.utils';

interface NoteBoxProps {
  activity: Bobject;
  isSmallBlock?: boolean;
  icon: IconType;
  title: string;
}

interface NoteEditorProps {
  id: string;
  data: string;
  openFloatingNote: boolean;
  expand?: boolean;
  setData: (data: string) => void;
}

interface IContentEditor {
  id: string;
  t: TFunction;
  data: string;
  setData: (data: string) => void;
  setExitModeInline: () => void;
  contentState: [string, (value: string) => void];
  plugins: any;
  users: any;
  expand: boolean;
}

const NoteToolbar = React.memo(() => (
  <div className={styles.toolbar}>
    {/* @ts-ignore */}
    <EditorToolbar backgroundColor="var(--peanut) !important">
      <EditorToolbarControlsSection color="peanut" />
      <EditorToolbarFontStylesSection color="peanut" />
      <EditorToolbarTextMarksSection color="peanut" />
      <EditorToolbarListsSection color="peanut" />
    </EditorToolbar>
  </div>
));

const getDeserialize = (message, plugins) => {
  if (message) {
    return typeof message === 'string'
      ? isHtml(message)
        ? deserialize(message, {
            format: 'HTML',
            plugins: plugins,
          })
        : createParagraph(message)
      : message;
  }

  return null;
};

const getSerialize = (message, plugins) => {
  if (message) {
    return serialize(message, {
      format: 'AST',
      plugins: plugins,
    });
  }
  return null;
};

const NoteContentEditor = ({
  id,
  t,
  data,
  setData,
  setExitModeInline,
  contentState,
  plugins,
  users,
  expand,
}: IContentEditor) => {
  const [content, setContent] = contentState;

  const handleSubmit = content => {
    const dataSerialized = getSerialize(content, plugins);
    const mentions = getMentionsFromNote(content);
    const contentSerialized = {
      [ACTIVITY_FIELDS_LOGIC_ROLE.NOTE]: dataSerialized,
      [ACTIVITY_FIELDS_LOGIC_ROLE.MENTIONS]: mentions,
    };

    if (dataSerialized !== data) {
      const params = { duplicateValidation: true };
      api
        .patch(`/bobjects/${id}/raw`, {
          contents: { ...contentSerialized },
          params,
        })
        .then(() => {
          window.dispatchEvent(
            new CustomEvent(MessagesEvents.ActiveBobjectUpdated, {
              detail: { type: BobjectTypes.Activity },
            }),
          );
          setData(dataSerialized);
        });
    }
  };
  const debouncedSubmit = useCallback(debounce(handleSubmit, 1000), []);

  const contentDeserialize = getDeserialize(content, plugins);

  const editorClasses = clsx(styles.editor, {
    [styles.expandEditor]: expand
  });

  return (
    <div className={styles.contentEditor} onMouseLeave={setExitModeInline}>
      <RichTextEditor
        id={id + '-body'}
        defaultValue={contentDeserialize}
        plugins={plugins}
        users={users?.users}
        placeholder={t('internalNotePlaceholder')}
        onChange={text => {
          setContent(text);
          debouncedSubmit(text);
        }}
        style={{
          color: 'var(--peanut) !important',
          padding: '4px',
        }}
      >
        {editor => (
          <>
            <div className={editorClasses}>{editor}</div>
            <div>
              <NoteToolbar />
            </div>
          </>
        )}
      </RichTextEditor>
    </div>
  );
};

export const NoteEditor = ({ id, data, openFloatingNote, expand = false, setData }: NoteEditorProps) => {
  const [isModeInline, setModeInline] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'extension.noteModal' });

  const contentState = useState(data);
  const users = useUserMentions();
  const accountId = useActiveAccountId();
  const mentionsEnabled = useActivityUserMentionsEnabled(accountId);

  const plugins = useRichTextEditorPlugins({
    images: false,
    replaceParagraphs: true,
    userMentions: mentionsEnabled,
    elements: true,
  });

  useEffect(() => {
    if (openFloatingNote) setModeInline(false);
  }, [openFloatingNote]);

  const classes = clsx(styles.htmlText, {
    [styles.floatingNote]: openFloatingNote,
    [styles.noData]: !data,
    [styles.expand]: expand
  });

  const editorClasses = clsx(styles.htmlText, {
    [styles.expand]: expand 
 });

  if ((!data || isEmptyText(data, plugins)) && !isModeInline) {
    return (
      <div onClick={() => setModeInline(true)} className={classes}>
        {t('internalNotePlaceholder')}
      </div>
    );
  }

  if (isModeInline) {
    return (
      <NoteContentEditor
        id={id}
        t={t}
        data={data}
        setData={setData}
        contentState={contentState}
        users={users}
        plugins={plugins}
        setExitModeInline={() => {
          setModeInline(false);
        }}
        expand={expand}
      />
    );
  }

  return isHtml(data) ? (
    <div
      className={editorClasses}
      dangerouslySetInnerHTML={{ __html: data }}
      onClick={() => setModeInline(true)}
    />
  ) : (
    <div onClick={() => setModeInline(true)}>
      <Text className={editorClasses} size="xs">
        {data ? data : 'None'}
      </Text>
    </div>
  );
};
