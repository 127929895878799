// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".syncSettingsTabEmbedded-module__leadConvertedContainer___Z62Ka {\n  box-sizing: border-box;\n  padding-left: 32px;\n}\n\n.syncSettingsTabEmbedded-module__steps___49QHL {\n  display: flex;\n  flex-direction: row;\n  padding: 0 30px;\n  margin-top: 16px;\n  justify-content: space-between;\n}\n.syncSettingsTabEmbedded-module__leadConvertedSteps___FzcMM {\n  display: flex;\n  flex-direction: row;\n  padding: 0 121px 0 89px;\n  margin-top: 16px;\n  justify-content: space-between;\n}\n\n.syncSettingsTabEmbedded-module__select___GAQIw {\n  margin-top: 40px;\n}\n\n.syncSettingsTabEmbedded-module__column___isJWd {\n  width: 400px;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n\n.syncSettingsTabEmbedded-module__column___isJWd > svg {\n  margin: 10px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/integrationLayout/syncSettingsTab/embedded/syncSettingsTabEmbedded.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".leadConvertedContainer {\n  box-sizing: border-box;\n  padding-left: 32px;\n}\n\n.steps {\n  display: flex;\n  flex-direction: row;\n  padding: 0 30px;\n  margin-top: 16px;\n  justify-content: space-between;\n}\n.leadConvertedSteps {\n  display: flex;\n  flex-direction: row;\n  padding: 0 121px 0 89px;\n  margin-top: 16px;\n  justify-content: space-between;\n}\n\n.select {\n  margin-top: 40px;\n}\n\n.column {\n  width: 400px;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n\n.column > svg {\n  margin: 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leadConvertedContainer": "syncSettingsTabEmbedded-module__leadConvertedContainer___Z62Ka",
	"steps": "syncSettingsTabEmbedded-module__steps___49QHL",
	"leadConvertedSteps": "syncSettingsTabEmbedded-module__leadConvertedSteps___FzcMM",
	"select": "syncSettingsTabEmbedded-module__select___GAQIw",
	"column": "syncSettingsTabEmbedded-module__column___isJWd"
};
export default ___CSS_LOADER_EXPORT___;
