export const GLOBAL_PICKLISTS = Object.freeze({
  CADENCE: 'CADENCE',
  DATA_SOURCE: 'DATA_SOURCE',
  DISCARDED_REASON: 'DISCARDED_REASON',
  EMAIL: 'EMAIL',
  USER: 'USER',
  USE_CASE: 'USE_CASE',
  TIME_ZONE_GMT: 'TIME_ZONE_GMT',
  TASK_TYPE: 'TASK_TYPE',
  TARGET_MARKET: 'TARGET_MARKET',
  PITCH: 'PITCH',
  NURTURING_REASON: 'NURTURING_REASON',
  LINKEDIN_MESSAGE: 'LINKEDIN_MESSAGE',
  IDEAL_CUSTOMER_PROFILE: 'IDEAL_CUSTOMER_PROFILE',
  EMPLOYEE_ROLE: 'EMPLOYEE_ROLE',
  ON_HOLD_REASONS: 'ON_HOLD_REASONS',
});
