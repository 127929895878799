// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".meetingActivityCard-module___check_wrapper___BmXd5 {\n  padding-right: 8px;\n}\n\n.meetingActivityCard-module___callCard_body__text___ifPKM {\n  padding-right: 8px;\n  align-self: auto;\n}\n\n.meetingActivityCard-module___callCard_body__link___5GlSM {\n  padding-right: 5px;\n}\n\n.meetingActivityCard-module___button_reported_container___mhTBM {\n  background-color: var(--verySoftMelon);\n  padding: 0 8px 4px 8px;\n  border-radius: 4px;\n}\n\n.meetingActivityCard-module___separator___zKHpA {\n  display: flex;\n  align-self: center;\n  margin-right: 8px;\n  width: 1px;\n  height: 8px;\n  background-color: var(--softPeanut);\n}\n\n.meetingActivityCard-module__source___nFYE1 {\n  margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/subhomePages/components/subhomeCards/subcomponents/meetingActivityCard.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,sCAAsC;EACtC,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,UAAU;EACV,WAAW;EACX,mCAAmC;AACrC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["._check_wrapper {\n  padding-right: 8px;\n}\n\n._callCard_body__text {\n  padding-right: 8px;\n  align-self: auto;\n}\n\n._callCard_body__link {\n  padding-right: 5px;\n}\n\n._button_reported_container {\n  background-color: var(--verySoftMelon);\n  padding: 0 8px 4px 8px;\n  border-radius: 4px;\n}\n\n._separator {\n  display: flex;\n  align-self: center;\n  margin-right: 8px;\n  width: 1px;\n  height: 8px;\n  background-color: var(--softPeanut);\n}\n\n.source {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_check_wrapper": "meetingActivityCard-module___check_wrapper___BmXd5",
	"_callCard_body__text": "meetingActivityCard-module___callCard_body__text___ifPKM",
	"_callCard_body__link": "meetingActivityCard-module___callCard_body__link___5GlSM",
	"_button_reported_container": "meetingActivityCard-module___button_reported_container___mhTBM",
	"_separator": "meetingActivityCard-module___separator___zKHpA",
	"source": "meetingActivityCard-module__source___nFYE1"
};
export default ___CSS_LOADER_EXPORT___;
