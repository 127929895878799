// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subhomeHeader-module___header___bIlQv {\n  display: flex;\n  width: 100%;\n  align-items: center;\n}\n\n.subhomeHeader-module___subhome_title___tyy8K {\n  display: flex;\n}\n\n.subhomeHeader-module___subhome_title___tyy8K > p {\n  padding-right: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/subhomeLayout/subhomeContent/subhomeHeader/subhomeHeader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["._header {\n  display: flex;\n  width: 100%;\n  align-items: center;\n}\n\n._subhome_title {\n  display: flex;\n}\n\n._subhome_title > p {\n  padding-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_header": "subhomeHeader-module___header___bIlQv",
	"_subhome_title": "subhomeHeader-module___subhome_title___tyy8K"
};
export default ___CSS_LOADER_EXPORT___;
