import { useTranslation } from 'react-i18next';

import { createToast, Dropdown, IconButton } from '@bloobirds-it/flamingo-ui';
import { BobjectTypes, MessagesEvents } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import { mutate } from 'swr';

import { ConfirmDeleteMessage } from '../confirmDeleteMessage/ConfirmDeleteMessage';

export const DeleteActivity = ({
  deleteActivityDropdownVisible,
  setDeleteActivityDropdownVisible,
  activityValue,
  size,
  callbackAction,
  threadId,
}: {
  deleteActivityDropdownVisible: boolean;
  setDeleteActivityDropdownVisible: (deleteActivityDropdownVisible: boolean) => void;
  activityValue: string;
  size: number;
  callbackAction?: () => void;
  threadId?: string;
}) => {
  const { t } = useTranslation();
  const deleteActivity = async () => {
    try {
      if (threadId) {
        await api.delete(`messaging/emails/delete/${threadId}`, {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
          data: {},
        });
      } else {
        await api.delete(`bobjects/${activityValue}`, {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
          data: {},
        });
      }
      mutate(`bobjects/${activityValue}`);
      window.dispatchEvent(
        new CustomEvent(MessagesEvents.ActiveBobjectUpdated, {
          detail: { type: BobjectTypes.Activity },
        }),
      );
      if (callbackAction) {
        callbackAction();
      }
      createToast({
        message: t('activityTimelineItem.item.deleteActivityConfirmToast'),
        type: 'success',
      });
    } catch (error) {
      createToast({
        message: t('common.somethingWentWrong'),
        type: 'error',
      });
      console.error('Error deleting message:', error);
    }
  };
  return (
    <>
      <Dropdown
        position={'bottom'}
        visible={deleteActivityDropdownVisible}
        anchor={
          <IconButton
            name="trashFull"
            size={size}
            onClick={e => {
              e.stopPropagation();
              setDeleteActivityDropdownVisible(true);
            }}
          />
        }
      >
        <ConfirmDeleteMessage
          onClick={() => {
            deleteActivity();
            setDeleteActivityDropdownVisible(false);
          }}
          onCancel={() => setDeleteActivityDropdownVisible(false)}
          message={t('activityTimelineItem.item.deleteMessageActivity')}
        />
      </Dropdown>
    </>
  );
};
