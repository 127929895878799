// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".selectTeamFilter-module__selectButton___S\\+6P\\+{\n    margin-left: 8px;\n}\n\n.selectTeamFilter-module__loadingContent___mJaJx{\n    display: flex;\n    justify-content: center;\n}", "",{"version":3,"sources":["webpack://./src/js/components/selectTeamFilter/selectTeamFilter.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".selectButton{\n    margin-left: 8px;\n}\n\n.loadingContent{\n    display: flex;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectButton": "selectTeamFilter-module__selectButton___S+6P+",
	"loadingContent": "selectTeamFilter-module__loadingContent___mJaJx"
};
export default ___CSS_LOADER_EXPORT___;
