// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".salesPage-module___header_right_content___OvQEy {\n  display: flex;\n  flex: 1;\n  align-items: center;\n}\n\n.salesPage-module___header_right_content__end___\\+3CVw {\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n.salesPage-module___header_right_content_left_align___GSGDz {\n  margin-left: 16px;\n}\n\n.salesPage-module___button_spinner___S1Ocy {\n  height: 16px;\n}\n\n.salesPage-module___startTaskSettings___BqJP6 {\n  margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/subhomePages/salesPage/salesPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,OAAO;EACP,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,OAAO;EACP,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["._header_right_content {\n  display: flex;\n  flex: 1;\n  align-items: center;\n}\n\n._header_right_content__end {\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n._header_right_content_left_align {\n  margin-left: 16px;\n}\n\n._button_spinner {\n  height: 16px;\n}\n\n._startTaskSettings {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_header_right_content": "salesPage-module___header_right_content___OvQEy",
	"_header_right_content__end": "salesPage-module___header_right_content__end___+3CVw",
	"_header_right_content_left_align": "salesPage-module___header_right_content_left_align___GSGDz",
	"_button_spinner": "salesPage-module___button_spinner___S1Ocy",
	"_startTaskSettings": "salesPage-module___startTaskSettings___BqJP6"
};
export default ___CSS_LOADER_EXPORT___;
