import { LinkedInMessage } from '@bloobirds-it/types';

import { getLinedinIdFromUrl, normalizeUrl } from '../../../url.utils';
import { transformToNewMessageType } from '../utils';
import extractAuto from './extractAuto';
import { transform } from './transform';

// create meta object containing info about sender
export const getLinkedInMessagesMetaObject = async (): Promise<any> => {
  const messagesBox = document.querySelector('div.msg-thread');
  if (!messagesBox) {
    console.log('Could not find messages box');
    return;
  }
  const anchorElement = messagesBox?.querySelector('a') as HTMLAnchorElement;
  const anchorElementTitle = anchorElement?.querySelector('h2') as HTMLElement;
  const image = document.querySelector(
    '.msg-conversation-listitem__link.active img.presence-entity__image',
  ) as HTMLImageElement;

  const messageTo = anchorElement !== null ? normalizeUrl(anchorElement.href) : null;
  const titleSelector = '[class*="lockup__subtitle"] div';
  const titlePremiumSelector = '.artdeco-entity-lockup__subtitle';
  const anchorElementJobTitle = (messagesBox.querySelector(titleSelector) ||
    messagesBox.querySelector(titlePremiumSelector)) as HTMLElement;

  return {
    meta: {
      messageTo,
      leadId: messageTo && getLinedinIdFromUrl(normalizeUrl(messageTo)),
      nameTo: anchorElementTitle?.innerText,
      image: image?.src,
      pathName: window.location.pathname,
      timestamp: new Date().getTime(),
      jobTitle: anchorElementJobTitle
        ? anchorElementJobTitle?.innerText?.replace(/ (at|en) (.*)$/i, '')?.trim()
        : null,
      companyName: anchorElementJobTitle
        ? anchorElementJobTitle?.innerText?.replace(/.*(at|en)/, '')?.trim()
        : null,
    },
  };
};

export default async function syncLinkedInMessages(
  leadId?: string,
  url?: string,
  currentRef?: any,
): Promise<Array<LinkedInMessage>> {
  if ((url && url !== normalizeUrl(window.location.href)) || currentRef.current === false) {
    return [];
  }

  const object = await getLinkedInMessagesMetaObject();

  if (!object) {
    return [];
  }

  if (object.meta.pathName?.includes('/new')) {
    return [];
  }

  const response = await extractAuto();
  const oldLinkedInMessages = transform(response, object.meta);

  if (url && new URL(url) && !new URL(url)?.pathname.startsWith(window.location.pathname)) {
    console.log(
      `discarded because different criteria ${window.location.pathname} ${new URL(url)?.pathname}`,
    );
    return [];
  }

  console.log(
    'Found ' + oldLinkedInMessages?.length + ' messages for ' + leadId ||
      object?.meta?.nameTo + ' ' + object?.meta?.messageTo + ', going to sync',
  );

  const messagesToSend = leadId
    ? oldLinkedInMessages?.map(message => ({
        ...message,
        bloobirdsId: leadId,
      }))
    : oldLinkedInMessages;

  return transformToNewMessageType({
    messages: messagesToSend,
    pathName: object.meta.pathName,
    leadName: object.meta.nameTo,
  });
}
