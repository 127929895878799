// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".segmentationSelect-module___select_wrapper___BfS3N {\n  margin-top: 32px;\n  margin-left: 32px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.segmentationSelect-module___select_wrapper___BfS3N > svg {\n  margin-right: 20px;\n}\n\n.segmentationSelect-module___select_text___080Nj {\n  width: 96px;\n}\n\n.segmentationSelect-module___hidden___7Lpil {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/playbookPages/playbookSegmentationPage/segmentationSelect/segmentationSelect.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf","sourcesContent":["._select_wrapper {\n  margin-top: 32px;\n  margin-left: 32px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n._select_wrapper > svg {\n  margin-right: 20px;\n}\n\n._select_text {\n  width: 96px;\n}\n\n._hidden {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_select_wrapper": "segmentationSelect-module___select_wrapper___BfS3N",
	"_select_text": "segmentationSelect-module___select_text___080Nj",
	"_hidden": "segmentationSelect-module___hidden___7Lpil"
};
export default ___CSS_LOADER_EXPORT___;
