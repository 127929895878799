import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Text } from '@bloobirds-it/flamingo-ui';
// @ts-ignore
import { useMeetingLinks } from '@bloobirds-it/hooks';

import { useUserSettings } from '../../../components/userPermissions/hooks';
import { ConfirmDeleteModalLayout } from '../../../layouts/confirmDeleteModalLayout/confirmDeleteModalLayout';
import { LoadingSpinner } from '../../homePage/pages/leftContent/utils/utils';
import { CreateEditMeetingModal } from './createEditMeetingLinkModal/createEditMeetingModal';
import {
  MeetingEntityCard,
  MeetingEntityHeader,
  MeetingSettingsEntityList,
} from './entityList/meetingEntityList';
import styles from './meetingSettings.module.css';
import { useMeetingSettings } from './useMeetingSettings';

export enum ACTION_TYPES {
  ADD = 'ADD',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  STAR = 'STAR',
}

export const MeetingSettings = () => {
  const settings = useUserSettings();
  const {
    modalInfo,
    setModalInfo: setModalProps,
    onCreate,
    onStar,
    onDelete,
    closeModals,
  } = useMeetingSettings();
  const { t } = useTranslation('translation', { keyPrefix: 'userSettingsWebapp.meetingSettings' });

  const { getUserMeetingLinks, mutate, isLoading } = useMeetingLinks();
  const meetingLinks = getUserMeetingLinks(settings?.user?.id);

  const hasMeetingLinks = Array.isArray(meetingLinks) && meetingLinks?.length > 0;

  function handleClose() {
    mutate();
    closeModals();
  }
  return (
    <>
      <MeetingSettingsEntityList>
        <MeetingEntityHeader key="listHeader" title={t('title')}>
          <Button size="small" onClick={onCreate}>
            {t('addLink')}
          </Button>
        </MeetingEntityHeader>
        {isLoading ? (
          <LoadingSpinner />
        ) : hasMeetingLinks ? (
          meetingLinks.map(meeting => (
            <MeetingEntityCard
              key={meeting.id}
              entity={meeting}
              onOpenDelete={() => setModalProps({ openedModal: 'DELETE', entity: meeting })}
              onOpenEdit={() => setModalProps({ openedModal: 'EDIT&CREATE', entity: meeting })}
              onStar={() => onStar(meeting).then(() => mutate())}
            />
          ))
        ) : (
          <div className={styles.noLinks}>
            <Text color="softPeanut" size="m">
              {t('noMeetings')}
            </Text>
            <Text size="m" align="center" className={styles._description__text}>
              {t('noMeetingsSubtitle')}
            </Text>
          </div>
        )}
      </MeetingSettingsEntityList>
      {modalInfo?.openedModal === 'DELETE' && (
        <ConfirmDeleteModalLayout
          assetLabel="Meeting link"
          handleDelete={() => {
            onDelete().then(() => handleClose());
          }}
          handleClose={closeModals}
        >
          {t('deleteMeetingLink')}
        </ConfirmDeleteModalLayout>
      )}
      <CreateEditMeetingModal
        isOpen={modalInfo?.openedModal === 'EDIT&CREATE'}
        closeModals={handleClose}
        modalInfo={modalInfo}
        openDelete={() => setModalProps({ ...modalInfo, openedModal: 'DELETE' })}
      />
    </>
  );
};
