import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Button,
  Text,
  ModalFooter,
  Callout,
  Icon,
} from '@bloobirds-it/flamingo-ui';
import { fetchAndOpenNylasV3Url } from '@bloobirds-it/utils';

import styles from './reconnectEmailModal.module.css';

export const ReconnectEmailModal = ({
  onAccept,
  onClose,
}: {
  onAccept: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userSettingsWebapp.reconnectEmailModal',
  });

  return (
    <Modal open onClose={onClose} width={500}>
      <ModalHeader color="white" size="small">
        <ModalTitle size="small" icon="externalLink" color="bloobirds">
          <Text weight="bold" color="peanut" size="l">
            {t('title')}
          </Text>
        </ModalTitle>
        <ModalCloseIcon size="small" onClick={onClose} />
      </ModalHeader>
      <ModalContent className={styles.content}>
        <Text color="peanut" size="s">
          <Trans
            i18nKey="userSettingsWebapp.reconnectEmailModal.content"
            components={[
              <>
                <br />
                <br />
              </>,
            ]}
          />
        </Text>
        <Callout variant="negative" withoutIcon width="100%">
          <div className={styles.callout}>
            <Icon name="alertTriangle" color="tomato" />
            <Text color="tomato" size="s">
              <Trans i18nKey="userSettingsWebapp.reconnectEmailModal.callout" />
            </Text>
          </div>
        </Callout>
      </ModalContent>
      <ModalFooter className={styles.footer}>
        <Button variant="clear" onClick={onClose} size="small" uppercase={false}>
          {t('back')}
        </Button>
        <Button onClick={onAccept} size="small" uppercase={false}>
          {t('continue')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
