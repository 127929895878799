export const workflowsContextActions = Object.freeze({
  SET_NAME: 'SET_NAME',
  MISSING_INFO: 'MISSING_INFO',
  SUBMITTING: 'SUBMITTING',
  ADD_AND_CONDITION: 'ADD_AND_CONDITON',
  ADD_OR_CONDITION: 'ADD_ON_CONDITION',
  SET_TRIGGER: 'SET_TRIGGER',
  UPDATE_AND_CONDITION_FIELD: 'UPDATE_AND_CONDITION_FIELD',
  UPDATE_AND_CONDITION_VALUE: 'UPDATE_AND_CONDITION_VALUE',
  REMOVE_CONDITIONS_BLOCK: 'REMOVE_CONDITIONS_BLOCK',
  CLONE_CONDITIONS_BLOCK: 'CLONE_CONDITIONS_BLOCK',
  REMOVE_CONDITIONS_VALUE_PAIR: 'REMOVE_CONDITIONS_VALUE_PAIR',
  RESET_CONDITIONS_VALUE_PAIR: 'RESET_CONDITIONS_VALUE_PAIR',
  ADD_ACTIONS_BLOCK: 'ADD_ACTION_BLOCK',
  REMOVE_ACTIONS_BLOCK: 'REMOVE_ACTION_BLOCK',
  ADD_ACTIONS_VALUE_PAIR: 'ADD_ACTIONS_VALUE_PAIR',
  UPDATE_ACTION_TYPE: 'UPDATE_ACTION_TYPE',
  UPDATE_ACTION: 'UPDATE_ACTION',
  UPDATE_RUN_ONCE: 'UPDATE_RUN_ONCE',
  UPDATE_STATUS: 'UPDATE_STATUS',
  UPDATE_ACTION_FIELD_UPDATE_FIELD: 'UPDATE_ACTION_FIELD_UPDATE_FIELD',
  UPDATE_ACTION_VALUE_UPDATE_FIELD: 'UPDATE_ACTION_VALUE_UPDATE_FIELD',
  UPDATE_ACTION_ORIGIN_COPY_FIELD: 'UPDATE_ACTION_SOURCE_COPY_FIELD',
  UPDATE_ACTION_TARGET_COPY_FIELD: 'UPDATE_ACTION_TARGET_COPY_FIELD',
  UPDATE_ACTION_ARRAY_FIELD: 'UPDATE_ACTION_ARRAY_FIELD',
  UPDATE_ACTION_VALUE: 'UPDATE_ACTION_VALUE',
  REMOVE_ACTION_ARRAY_VALUE: 'REMOVE_ACTION_ARRAY_VALUE',
  ADD_ACTIONS_CLEAR_ARRAY: 'ADD_ACTIONS_CLEAR_ARRAY',
  UPDATE_TASK_TITLE: 'UPDATE_TASK_TITLE',
  UPDATE_TASK_ASSIGNED_TO: 'UPDATE_TASK_ASSIGNED_TO',
  UPDATE_NOTIFICATION_TITLE: 'UPDATE_NOTIFICATION_TITLE',
  UPDATE_NOTIFICATION_ASSIGNED_TO: 'UPDATE_NOTIFICATION_ASSIGNED_TO',
  LOAD_WORKFLOW: 'LOAD_WORKFLOW',
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  UPDATE_CAN_OTHERS_EDIT: 'UPDATE_CAN_OTHERS_EDIT',
  UPDATE_IS_LOCKED: 'UPDATE_IS_LOCKED',
  UPDATE_IS_DIRTY: 'UPDATE_IS_DIRTY',
  UPDATE_WORKFLOW_ID: 'UPDATE_WORKFLOW_ID',
  UPDATE_LOGS_FILTERS: 'UPDATE_LOGS_FILTERS',
});
