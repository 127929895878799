import React from 'react';

import { Checkbox } from '@bloobirds-it/flamingo-ui';

export const ActiveConfigurationCard = ({
  capability,
  activeConfiguration,
  handleChange,
}: {
  capability: string;
  activeConfiguration: string[];
  handleChange: (key: string, value: any) => void;
}) => {
  const isActive = activeConfiguration.includes(capability);
  const handleCheckboxClick = () => {
    if (activeConfiguration.includes(capability)) {
      handleChange(
        'activeConfiguration',
        activeConfiguration.filter(cap => cap !== capability),
      );
    } else {
      handleChange('activeConfiguration', [...activeConfiguration, capability]);
    }
  };

  return (
    <Checkbox
      size="small"
      disabled={activeConfiguration.length === 1 && isActive}
      checked={isActive}
      onClick={() => {
        handleCheckboxClick();
      }}
    >
      {capability}
    </Checkbox>
  );
};
