// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".whatsappSettings-module__box___HIAhP {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n\n.whatsappSettings-module__container___S9x7u {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 24px;\n  width: 500px;\n  margin-top: 40px;\n  margin-bottom: 40px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/userSettingsPages/whatsappSettings/whatsappSettings.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,SAAS;EACT,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".box {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 24px;\n  width: 500px;\n  margin-top: 40px;\n  margin-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "whatsappSettings-module__box___HIAhP",
	"container": "whatsappSettings-module__container___S9x7u"
};
export default ___CSS_LOADER_EXPORT___;
