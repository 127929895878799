import useSWRImmutable from 'swr/immutable';

import SessionManagerFactory from '../misc/session';

const SessionManager = SessionManagerFactory();

export const useFeatureFlags = () => {
  const accountId = SessionManager.getAccount()?.id;
  const { data: featureFlags } = useSWRImmutable(
    accountId ? `/featureFlags/feature/account/${accountId}` : null,
  );
  const isFlagEnabled = (flag: string): boolean => {
    const featureFlag = featureFlags?.features?.find(
      (feature: { featureName?: string; featureType?: string }) =>
        (feature.featureName || feature.featureType) === flag,
    );
    return featureFlag ? featureFlag.active : false;
  };

  return { featureFlags, isFlagEnabled };
};

export const useInboundHubspotEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('INBOUND_HUBSPOT');
};

export const useFullSalesEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('SALES');
};

export const useSalesDashboardEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('DASHBOARD_SALES');
};

export const useSendFromMailEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('SEND_FROM_MAIL');
};

export const useDynamicsEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('DYNAMICS');
};

export const useVtigerEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('VTIGER');
};

export const useProductsEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('PRODUCTS');
};

export const useIntegrationApp = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('INTEGRATION_APP');
};

export const useNewMeetingTab = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('MEETING_SALES_TAB');
};

export const useSalesforceOauthEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('SALESFORCE_OAUTH');
};

export const useCreateReceivedMeeting = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('CREATE_RECEIVED_MEETING');
};

export const useManageUserTeamsEnabled = () => {
  const { isFlagEnabled } = useFeatureFlags();
  return isFlagEnabled('MANAGE_USER_TEAMS');
};
