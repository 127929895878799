// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".saveEditConfirmationModal-module__modalContent___3wBjn {\n  height: 100px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  gap: 4px;\n}\n\n.saveEditConfirmationModal-module__modalContent___3wBjn * {\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectTable/actionsPanel/saveEditButton/saveEditConfirmationModal/saveEditConfirmationModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,QAAQ;AACV;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".modalContent {\n  height: 100px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  gap: 4px;\n}\n\n.modalContent * {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": "saveEditConfirmationModal-module__modalContent___3wBjn"
};
export default ___CSS_LOADER_EXPORT___;
