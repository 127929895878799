import React from 'react';
import { useTranslation } from 'react-i18next';

import { usePlayground } from '../playgroundPages';
import { PLAYGROUND_TABS } from '../types';
import GeneralPrompts from './generalPrompts';
import Insights from './insights';
import Lab from './lab';

const PlaygroundTabs = () => {
  const { selectedTab } = usePlayground();
  const { t } = useTranslation();

  switch (selectedTab) {
    case PLAYGROUND_TABS.INSIGHTS:
      return <Insights />;
    case PLAYGROUND_TABS.LAB:
      return <Lab />;
    case PLAYGROUND_TABS.PROMPTS:
      return <GeneralPrompts />;
    default:
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {t('ai.playground.selectATab')}
        </div>
      );
  }
};

export default PlaygroundTabs;
