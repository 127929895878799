// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmptyStatePanel-module__root___w8Mwm {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n  height: 100%;\n}\n\n.EmptyStatePanel-module__text___9Gh5p {\n  max-width: 220px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/dashboardPages/v1/panels/EmptyStatePanel/EmptyStatePanel.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,OAAO;EACP,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n  height: 100%;\n}\n\n.text {\n  max-width: 220px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "EmptyStatePanel-module__root___w8Mwm",
	"text": "EmptyStatePanel-module__text___9Gh5p"
};
export default ___CSS_LOADER_EXPORT___;
