// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".confirmDeleteTwilioModal-module___modal_text___3di9v {\n  margin-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/dialersPage/twilioIntegration/confirmDeleteTwilioModal/confirmDeleteTwilioModal.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB","sourcesContent":["._modal_text {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_modal_text": "confirmDeleteTwilioModal-module___modal_text___3di9v"
};
export default ___CSS_LOADER_EXPORT___;
