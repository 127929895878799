// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".userTabRow-module___cell_text___Z4fJ\\+ {\n  box-sizing: border-box;\n  padding-top: 6px;\n}\n.userTabRow-module___cell_select___1QxNE {\n}\n.userTabRow-module___cell_select___1QxNE > div {\n  padding: 0;\n}\n.userTabRow-module___icon_button___O8d6Y {\n  display: flex;\n}\n.userTabRow-module___icon_button___O8d6Y > p {\n  margin-right: 4px;\n}\n.userTabRow-module___icon_button___O8d6Y > button {\n  margin-top: -6px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/integrationLayout/usersTabTemplate/userTabRow/userTabRow.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,gBAAgB;AAClB;AACA;AACA;AACA;EACE,UAAU;AACZ;AACA;EACE,aAAa;AACf;AACA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB","sourcesContent":["._cell_text {\n  box-sizing: border-box;\n  padding-top: 6px;\n}\n._cell_select {\n}\n._cell_select > div {\n  padding: 0;\n}\n._icon_button {\n  display: flex;\n}\n._icon_button > p {\n  margin-right: 4px;\n}\n._icon_button > button {\n  margin-top: -6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_cell_text": "userTabRow-module___cell_text___Z4fJ+",
	"_cell_select": "userTabRow-module___cell_select___1QxNE",
	"_icon_button": "userTabRow-module___icon_button___O8d6Y"
};
export default ___CSS_LOADER_EXPORT___;
