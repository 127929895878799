// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".currentTimeIndicator-module__indicator___oVpzB {\n  position: absolute;\n  width: 2px;\n  height: 100%;\n  background: #94a5ba;\n  pointer-events: none;\n  top: 0;\n}\n\n.currentTimeIndicator-module__indicatorDot___U892r {\n  position: absolute;\n  top: -5px;\n  left: -4px;\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  background: #94a5ba;\n  pointer-events: none;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/aiAnalysisPages/leftSideAiAnalysis/mediaAiAnalysis/timelineArea/currentTimeIndicator/currentTimeIndicator.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,oBAAoB;EACpB,MAAM;AACR;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".indicator {\n  position: absolute;\n  width: 2px;\n  height: 100%;\n  background: #94a5ba;\n  pointer-events: none;\n  top: 0;\n}\n\n.indicatorDot {\n  position: absolute;\n  top: -5px;\n  left: -4px;\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  background: #94a5ba;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"indicator": "currentTimeIndicator-module__indicator___oVpzB",
	"indicatorDot": "currentTimeIndicator-module__indicatorDot___U892r"
};
export default ___CSS_LOADER_EXPORT___;
