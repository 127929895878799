// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".listTagWrapper-module___tag__container___l8BlI {\n  margin-right: 8px;\n  margin-bottom: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/tagsModal/listTagWrapper/listTagWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":["._tag__container {\n  margin-right: 8px;\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_tag__container": "listTagWrapper-module___tag__container___l8BlI"
};
export default ___CSS_LOADER_EXPORT___;
