import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Text } from '@bloobirds-it/flamingo-ui';
import { ACTIVITY_FIELDS_LOGIC_ROLE, Bobject, LEAD_FIELDS_LOGIC_ROLE } from '@bloobirds-it/types';
import { getValueFromLogicRole } from '@bloobirds-it/utils';

import { InviteeCard } from '../../components/calendar/calendar';
import { SearchLeadsGuests } from '../../components/searchLeadsGuests/searchLeadsGuests';
import { useCalendar } from '../../hooks/useCalendar';
import { Coworker, FormDataInterface, Invitee } from '../../types/calendar';
import { getEmailFromCompany } from '../../utils/calendar.utils';
import { useMeetingsModalContext } from '../context';
import styles from '../meetingModal.module.css';

type Props = {
  bobject: Bobject;
  formData: FormDataInterface;
  users: any;
};
export const MeetingModalInvitees = ({ bobject, formData, users }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meetingModal' });
  const { settings, connections } = useMeetingsModalContext();
  const { invitees, setInvitees } = useCalendar();
  const { watch } = useFormContext();
  const isEditionModal = !!bobject;
  const company = watch('company');
  const lead = watch('lead');
  const user = watch(ACTIVITY_FIELDS_LOGIC_ROLE.USER);
  const activityUser = users?.users?.find(u => ((user as unknown) as string) === u?.id);

  const inviteesNotSynced =
    isEditionModal && !getValueFromLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_INVITEES);
  const removeInvitee = (email: string) => {
    setInvitees(currInvitees => currInvitees?.filter(invitee => invitee?.email !== email));
  };

  useEffect(() => {
    if (formData[ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_INVITEES]) {
      setInvitees(JSON.parse(formData[ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_INVITEES]));
    } else {
      const newInvitees: Invitee[] = [];
      if (!!settings && !!connections) {
        const defaultConnection = connections.defaultConnection || connections.list[0]?.email;
        newInvitees.push({
          type: 'Organizer',
          email: activityUser ? activityUser?.email : defaultConnection,
          name: activityUser
            ? activityUser?.name
            : `${settings?.user?.name} (${t('bloobirdsCalendarSelector.you')})`,
        });
      }

      if (lead) {
        const leadEmail = lead?.email || getValueFromLogicRole(lead, 'LEAD__EMAIL');
        const leadName = lead?.fullName || getValueFromLogicRole(lead, 'LEAD__NAME');
        if (leadEmail) {
          newInvitees.push({ type: 'Lead', email: leadEmail, name: leadName });
        }
      }
      if (company) {
        const companyEmail = getEmailFromCompany(company);
        const companyName = company?.name || getValueFromLogicRole(company, 'COMPANY__NAME');
        if (companyEmail) {
          newInvitees.push({ type: 'Company', email: companyEmail.value, name: companyName });
        }
      }
      if (invitees?.length === 0) {
        setInvitees(newInvitees);
      }
    }
  }, []);

  const handleSelectLeadGuests = (leadSelected: Bobject | Coworker | string) => {
    const isLead =
      typeof leadSelected !== 'string' &&
      typeof leadSelected?.id !== 'string' &&
      leadSelected?.id?.value;
    const isCoworker =
      typeof leadSelected !== 'string' &&
      'type' in leadSelected &&
      leadSelected.type === 'Coworker';
    if (isLead) {
      const leadEmail = getValueFromLogicRole(
        leadSelected as Bobject,
        LEAD_FIELDS_LOGIC_ROLE.EMAIL,
      );
      const leadName = getValueFromLogicRole(
        leadSelected as Bobject,
        LEAD_FIELDS_LOGIC_ROLE.FULL_NAME,
      );
      if (!invitees?.find(invitee => invitee?.email === leadEmail)) {
        setInvitees(curr => [
          ...curr,
          {
            type: 'Lead',
            email: leadEmail,
            name: leadName,
            leadId: typeof leadSelected?.id === 'string' ? null : leadSelected?.id?.value,
          },
        ]);
      }
    } else if (isCoworker) {
      if (!invitees?.find(invitee => invitee?.email === leadSelected.email)) {
        setInvitees(curr => [...curr, leadSelected]);
      }
    } else {
      if (!invitees?.find(invitee => invitee?.email === leadSelected)) {
        setInvitees(curr => [
          ...curr,
          {
            type: null,
            email: leadSelected as string,
            name: null,
            leadId: null,
          },
        ]);
      }
    }
  };

  return (
    <>
      {inviteesNotSynced && (
        <div className={styles.inviteesNotSynced}>
          <Text size="s" color="peanut">
            {t('inviteesNotSynced')}
          </Text>
        </div>
      )}
      {!inviteesNotSynced && (
        <div className={styles.searchLeads}>
          <SearchLeadsGuests
            size={16}
            handleSelect={handleSelectLeadGuests}
            company={company}
            inviteesEmails={invitees?.map(i => i.email)}
          />
        </div>
      )}
      <div className={styles.inviteesList}>
        {invitees?.map(i => (
          <InviteeCard
            invitee={i as Invitee}
            key={i.email}
            handleRemoveInvitee={removeInvitee}
            readOnly={false}
            shouldShowStatus={isEditionModal}
          />
        ))}
      </div>
    </>
  );
};
