// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".showMore___container___4I\\+Ns {\n  font-size: 13px;\n  text-transform: none;\n  cursor: pointer;\n  color: var(--bloobirds);\n  margin-top: 24px;\n}\n\n.showMore___container___4I\\+Ns:hover {\n  background-color: transparent;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/activitySection/activityItem/showMore/showMore.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,oBAAoB;EACpB,eAAe;EACf,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,eAAe;AACjB","sourcesContent":["._container {\n  font-size: 13px;\n  text-transform: none;\n  cursor: pointer;\n  color: var(--bloobirds);\n  margin-top: 24px;\n}\n\n._container:hover {\n  background-color: transparent;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "showMore___container___4I+Ns"
};
export default ___CSS_LOADER_EXPORT___;
