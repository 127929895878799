// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".confirmModal-module__content___BbjzF {\n  margin-bottom: 22px;\n}\n\n.confirmModal-module__content___BbjzF > p:first-child {\n  margin-bottom: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/confirmModal/confirmModal.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".content {\n  margin-bottom: 22px;\n}\n\n.content > p:first-child {\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "confirmModal-module__content___BbjzF"
};
export default ___CSS_LOADER_EXPORT___;
