// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".discardLeadModal-module___content__wraper___FTjV3 {\n  padding: 32px 60px 40px;\n  position: relative;\n  background-color: var(--white);\n}\n\n.discardLeadModal-module___info__wrapper___jvsHf {\n  margin-top: 16px;\n  margin-bottom: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/subhomePages/prospectingPage/discardLeadModal/discardLeadModal.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["._content__wraper {\n  padding: 32px 60px 40px;\n  position: relative;\n  background-color: var(--white);\n}\n\n._info__wrapper {\n  margin-top: 16px;\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_content__wraper": "discardLeadModal-module___content__wraper___FTjV3",
	"_info__wrapper": "discardLeadModal-module___info__wrapper___jvsHf"
};
export default ___CSS_LOADER_EXPORT___;
