import { useAccountId } from '@bloobirds-it/hooks';

export const sortEntities = (entities, sortBy, sortOrder) => {
  if (!entities || entities.length === 0) return entities;

  if (sortBy === 'creationDate') {
    return entities?.sort((a, b) => {
      const dateA = new Date(a.creation_datetime);
      const dateB = new Date(b.creation_datetime);
      return sortOrder === 'ASC' ? dateA - dateB : dateB - dateA;
    });
  }
  if (sortBy === 'lastUpdate') {
    return entities?.sort((a, b) => {
      const dateA = new Date(a.update_datetime);
      const dateB = new Date(b.update_datetime);
      return sortOrder === 'ASC' ? dateA - dateB : dateB - dateA;
    });
  }
  if (sortBy === 'country') {
    return entities?.sort((a, b) => {
      const countryCodeA = a.countryCode?.toLowerCase() || '';
      const countryCodeB = b.countryCode?.toLowerCase() || '';
      return sortOrder === 'ASC'
        ? countryCodeA.localeCompare(countryCodeB)
        : countryCodeB.localeCompare(countryCodeA);
    });
  }
  if (sortBy === 'prefix') {
    return entities?.sort((a, b) => {
      const prefixA = a.prefix?.toLowerCase() || '';
      const prefixB = b.prefix?.toLowerCase() || '';
      return sortOrder === 'ASC' ? prefixA.localeCompare(prefixB) : prefixB.localeCompare(prefixA);
    });
  }
  if (sortBy === 'status') {
    return entities?.sort((a, b) => {
      return sortOrder === 'ASC' ? a.status - b.status : b.status - a.status;
    });
  }
  return entities;
};

export const helperRemoveTag = (tag, tags, handleChange) => {
  handleChange(
    'tags',
    tags.filter(tagNew => tagNew !== tag),
  );
};

const transformUsers = cleanedUsers => {
  return cleanedUsers.map(user => {
    if (!user?.id) {
      return {
        id: user.userId,
        name: user.userName,
        email: user.userEmail,
        role: user.userRole,
        color: user.userColor,
        timezone: user.userTimezone,
        shortname: user.userShortname,
      };
    } else {
      return user;
    }
  });
};

export const handleValueChange = (
  value,
  setUserTeams,
  teams,
  teamlessUsers,
  setSelectedTeamsState,
  selectedTeamsState,
) => {
  let valuesToAdd = [];
  const completeUsers = (teams?.map(team => team.teamUsers).flat() || []).concat(teamlessUsers);
  const cleanedUsers = [
    ...new Set(completeUsers.filter(user => user && value.includes(user.id || user.userId))),
  ];
  const transformedUsers = transformUsers(cleanedUsers);

  value.forEach(val => {
    if (transformedUsers?.some(user => user.id === val)) {
      const user = transformedUsers.find(user => user.id === val);
      valuesToAdd.push(user);
    }

    if (teams?.some(team => team.id === val)) {
      const team = teams?.find(team => team.id === val);
      // remove users from team
      if (selectedTeamsState.length > 0 && value.some(val => selectedTeamsState.includes(val))) {
        setSelectedTeamsState(selectedTeamsState.filter(id => id !== team.id));
        valuesToAdd = valuesToAdd.filter(user =>
          team.teamUsers.some(teamUser => teamUser.id === user.id),
        );
      }
      //add users from team
      else {
        setSelectedTeamsState([...selectedTeamsState, val]);
        if (team) {
          valuesToAdd.push(...team.teamUsers);
        }
      }
    }
  });

  const finalUsers = transformUsers(valuesToAdd);

  setUserTeams(finalUsers);
};

const sanitizeString = str => {
  return str.replace(/\\/g, '\\\\').replace(/"/g, '\\"').replace(/\n/g, '\\n');
};

export const linearIssueHandler = (phoneNumberRequestList, accountId) => async () => {
  const apiKey = 'lin_api_ORm4EMait9XODfCZ67F0m6kfCfBLokxaYISEEUsG';
  const baseUrl = 'https://api.linear.app/graphql';

  try {
    const teamQuery = `
      query {
        teams {
          nodes {
            id
            name
          }
        }
      }
    `;
    const teamResponse = await fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: apiKey,
      },
      body: JSON.stringify({ query: teamQuery }),
    });

    const { data: teamData } = await teamResponse.json();
    const customerSuccessTeam = teamData.teams.nodes.find(team => team.name === 'Customer Success');
    if (!customerSuccessTeam) throw new Error('Customer Success team not found');

    const createIssueMutation = `
  mutation {
    issueCreate(
      input: {
        title: "${phoneNumberRequestList?.length} Phone numbers request for account ${accountId}", 
        description: "${formatDescription(phoneNumberRequestList)}",
        teamId: "${customerSuccessTeam.id}"
      }
    ) {
      success
      issue {
        id
      }
    }
  }
`.replace(/\n/g, '');
    const issueResponse = await fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: apiKey,
      },
      body: JSON.stringify({ query: createIssueMutation }),
    });

    const { data: issueData } = await issueResponse.json();
    if (!issueData.issueCreate.success) throw new Error('Failed to create issue');
  } catch (error) {
    console.error('Error creating linear issue via REST API:', error);
    throw new Error('Failed to create linear issue');
  }
};

const formatDescription = request => {
  let finalString = '';

  request.forEach(req => {
    finalString += `## 📱 Phone Number Request Details
### Request Specifics
- **Phones Requested:** \`${req.phonesRequested}\`
- **Country:**  ${req.country}
- **Prefix:** \`${req.prefix.join(', ')}\`
- **Type of Number:** \`${req.typeOfNumber}\`

### Additional Context
${
  req.additionalInformation
    ? `> ${req.additionalInformation}`
    : '*No additional information provided*'
}

### Capabilities
🔧 **Enabled Capabilities:**
${req.capabilities.map(cap => `- \`${cap}\``).join('\n')}

🤖 **Automated Issue** 
*Created by Bloobirds Platform Workflow*

---

`;
  });
  return sanitizeString(finalString);
};
