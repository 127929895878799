// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cloneCadenceModal-module___title__container___ChMl1 {\n  display: flex;\n  align-items: center;\n}\n\n.cloneCadenceModal-module___title__container___ChMl1 > svg {\n  margin-right: 4px;\n}\n\n.cloneCadenceModal-module___main_info__input___7q\\+eZ {\n  margin: 8px 0 12px 0;\n}\n\n.cloneCadenceModal-module___cadence_callout__container___AE9Qj {\n  border: var(--softBloobirds) 1px solid;\n  border-radius: 4px;\n  margin-top: 20px;\n  margin-bottom: 26px;\n  background-color: var(--veryLightBloobirds);\n}\n\n.cloneCadenceModal-module___cadence_callout__container___AE9Qj > div > svg {\n  color: var(--bloobirds);\n}\n\n.cloneCadenceModal-module___cadence_callout__wrapper___eNIqp {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.cloneCadenceModal-module___cadence_callout__wrapper___eNIqp > div {\n  margin-right: 23px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/cadencesPage/components/cloneCadenceModal/cloneCadenceModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,sCAAsC;EACtC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,2CAA2C;AAC7C;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["._title__container {\n  display: flex;\n  align-items: center;\n}\n\n._title__container > svg {\n  margin-right: 4px;\n}\n\n._main_info__input {\n  margin: 8px 0 12px 0;\n}\n\n._cadence_callout__container {\n  border: var(--softBloobirds) 1px solid;\n  border-radius: 4px;\n  margin-top: 20px;\n  margin-bottom: 26px;\n  background-color: var(--veryLightBloobirds);\n}\n\n._cadence_callout__container > div > svg {\n  color: var(--bloobirds);\n}\n\n._cadence_callout__wrapper {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n._cadence_callout__wrapper > div {\n  margin-right: 23px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_title__container": "cloneCadenceModal-module___title__container___ChMl1",
	"_main_info__input": "cloneCadenceModal-module___main_info__input___7q+eZ",
	"_cadence_callout__container": "cloneCadenceModal-module___cadence_callout__container___AE9Qj",
	"_cadence_callout__wrapper": "cloneCadenceModal-module___cadence_callout__wrapper___eNIqp"
};
export default ___CSS_LOADER_EXPORT___;
