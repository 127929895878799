import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Text, Icon } from '@bloobirds-it/flamingo-ui';

import { RequestedPhone } from '../../../constants/phoneNumbers.constants';
import { useRequestNumberFormContext } from '../../../context/requestedPhonesFormProvider';
import { useFlagEmoji } from '../../../hooks/useFlagEmoji';
import styles from '../../../styles/phoneNumberPage.module.css';
import { CapabilitiesCard } from '../../rows/capabilitiesCard';

export const PhoneCard = ({ phone }: { phone: RequestedPhone }) => {
  const { t } = useTranslation();
  const { flag } = useFlagEmoji(phone.country);

  const { removePhoneNumber } = useRequestNumberFormContext();

  return (
    <div className={styles.phoneCard}>
      <div className={styles.attributesPhone}>
        <div className={styles.itemInformation}>
          <Icon name="flagFilled" size={18} color="softPeanut" />
          <Text size="m" color="peanut" weight="bold">
            {t('accountSettings.phoneNumbers.sorts.country')}:
          </Text>
          <Text size="m" color="peanut">
            {flag}
          </Text>
          <Text size="m" color="peanut" weight="regular">
            {phone.country}
          </Text>
        </div>

        <div className={styles.itemInformation}>
          <Icon name="number" size={18} color="softPeanut" />
          <Text size="m" color="peanut" weight="bold">
            {t('accountSettings.phoneNumbers.purchaseNewPhone.orderReview.phonesRequested')}:
          </Text>
          <Text size="m" color="peanut" weight="regular">
            {phone.phonesRequested}
          </Text>
        </div>

        <div className={styles.itemInformation}>
          <Icon name="gridSquares" size={18} color="softPeanut" />
          <Text size="m" color="peanut" weight="bold">
            {t('accountSettings.phoneNumbers.phoneSettings.tabs.generalSettings.typeOfNumber')}:
          </Text>
          <Text size="m" color="peanut" weight="regular">
            {phone.typeOfNumber}
          </Text>
        </div>

        <div className={styles.itemInformation}>
          <Icon name="agendaPerson" size={18} color="softPeanut" />
          <Text size="m" color="peanut" weight="bold">
            {t('accountSettings.phoneNumbers.purchaseNewPhone.orderReview.prefix')}:
          </Text>
          <Text size="m" color="peanut" weight="regular">
            {phone.prefix}
          </Text>
        </div>

        <div className={styles.itemInformation}>
          <Icon name="gridSquares" size={18} color="softPeanut" />
          <Text size="m" color="peanut" weight="bold">
            {t('accountSettings.phoneNumbers.filters.capabilities')}:
          </Text>
          {phone.capabilities.map((capability, index) => (
            <CapabilitiesCard key={index} capability={capability} />
          ))}
        </div>
      </div>
      <div className={styles.buttonRemoveCard}>
        <Button
          onClick={() => removePhoneNumber(phone.id)}
          variant="clear"
          size="small"
          uppercase={false}
        >
          {t('accountSettings.phoneNumbers.purchaseNewPhone.orderReview.removeOrder')}
        </Button>
      </div>
    </div>
  );
};
