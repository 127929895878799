// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".controlledSwitch-module___switchText__container___iVE7y {\n  display: flex;\n  align-items: center;\n}\n\n.controlledSwitch-module___switchText__text___6jYXo {\n  margin-left: 12px;\n}\n\n.controlledSwitch-module___switchText__icon___f88oL {\n  margin-top: 2px;\n  margin-left: 8px;\n}", "",{"version":3,"sources":["webpack://./src/js/components/controlledSwitch/controlledSwitch.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":["._switchText__container {\n  display: flex;\n  align-items: center;\n}\n\n._switchText__text {\n  margin-left: 12px;\n}\n\n._switchText__icon {\n  margin-top: 2px;\n  margin-left: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_switchText__container": "controlledSwitch-module___switchText__container___iVE7y",
	"_switchText__text": "controlledSwitch-module___switchText__text___6jYXo",
	"_switchText__icon": "controlledSwitch-module___switchText__icon___f88oL"
};
export default ___CSS_LOADER_EXPORT___;
