// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".productSalesforceSection-module___grid___VSM-- > section > div {\n  display: flex;\n  gap: 16px;\n  flex-wrap: wrap;\n  padding: 0 20px;\n}\n\n.productSalesforceSection-module__select___RcDu0 {\n  width: 100%;\n}\n\n.productSalesforceSection-module__select2___D9JNj {\n  width: calc(50% - 8px);\n}\n\n.productSalesforceSection-module__select2___D9JNj > div {\n  width: 100%;\n}\n\n.productSalesforceSection-module__select2___D9JNj > div > div {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/productSalesforceSection/productSalesforceSection.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":["._grid > section > div {\n  display: flex;\n  gap: 16px;\n  flex-wrap: wrap;\n  padding: 0 20px;\n}\n\n.select {\n  width: 100%;\n}\n\n.select2 {\n  width: calc(50% - 8px);\n}\n\n.select2 > div {\n  width: 100%;\n}\n\n.select2 > div > div {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_grid": "productSalesforceSection-module___grid___VSM--",
	"select": "productSalesforceSection-module__select___RcDu0",
	"select2": "productSalesforceSection-module__select2___D9JNj"
};
export default ___CSS_LOADER_EXPORT___;
