import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useVirtual } from 'react-virtual';

import {
  CircularBadge,
  Dropdown,
  Item,
  SearchInput,
  Tooltip,
  Text,
  useVisible,
} from '@bloobirds-it/flamingo-ui';
import { useDebounce } from '@bloobirds-it/hooks';
import clsx from 'clsx';

import styles from './companySelector.module.css';

interface ICompanyItem {
  id: string;
  name: string;
  targetMarket?: string;
  website: string;
}

interface ICompanySelectorProps {
  onCompanyIdChange: (id: string) => void;
  searchCompanies: (query: string) => Promise<ICompanyItem[]>;
  getTargetMarkets?: () => any;
  size?: 'small' | 'medium';
}

export const CompanySelector = ({
  onCompanyIdChange,
  searchCompanies,
  getTargetMarkets,
  size = 'medium',
}: ICompanySelectorProps) => {
  const parentRef = useRef();
  const { ref, visible, setVisible } = useVisible(false);
  const [options, setOptions] = useState<ICompanyItem[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const debounceSearchValue = useDebounce(searchValue, 200);
  const targetMarkets = getTargetMarkets?.();

  useEffect(() => {
    if (debounceSearchValue) {
      searchCompanies(debounceSearchValue).then(newOptions => {
        setOptions(newOptions);
      });
    }
  }, [debounceSearchValue, searchCompanies]);

  useEffect(() => {
    setVisible(options.length > 0 && selectedValue !== searchValue);
  }, [options.length, selectedValue, searchValue]);

  const handleSelect = value => {
    onCompanyIdChange(value);
    const name = options.find(option => option.id === value).name;
    setSearchValue(name);
    setSelectedValue(name);
  };

  const rowVirtualizer = useVirtual({
    size: options?.length,
    parentRef,
    estimateSize: useCallback(() => 56, []),
    overscan: 3,
  });

  return (
    <Dropdown
      zIndex={20001}
      ref={ref}
      width="100%"
      visible={visible}
      arrow={false}
      anchor={
        <div style={{ width: '100%' }}>
          <SearchInput
            width="100%"
            placeholder="Search companies"
            value={searchValue}
            size={size}
            onChange={setSearchValue}
          />
        </div>
      }
    >
      <div
        ref={parentRef}
        className={clsx(styles._modal_container, { [styles._small_container]: size === 'small' })}
      >
        <div
          style={{
            height: `${rowVirtualizer.totalSize}px`,
          }}
          className={styles._relative}
        >
          {rowVirtualizer.virtualItems.map(virtualRow => {
            const option = options[virtualRow.index];
            const targetMarket = targetMarkets?.get(option?.targetMarket);
            return (
              <div
                key={virtualRow.index}
                style={{
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
                className={styles._modal_item}
              >
                <Item className={styles.item} onClick={handleSelect} value={option.id}>
                  <>
                    {targetMarket ? (
                      <Tooltip title={targetMarket?.name} trigger="hover" position="top">
                        <CircularBadge
                          size="medium"
                          style={{
                            backgroundColor: targetMarket?.color || 'var(--verySoftPeanut)',
                            color: 'white',
                          }}
                        >
                          {targetMarket?.shortname || ''}
                        </CircularBadge>
                      </Tooltip>
                    ) : (
                      <CircularBadge
                        size="medium"
                        style={{
                          backgroundColor: 'var(--verySoftPeanut)',
                          color: 'white',
                          fontSize: 20,
                        }}
                      >
                        ?
                      </CircularBadge>
                    )}
                    <div className={styles._lead__info}>
                      <Text color="peanut" size="m" weight="medium" ellipsis={30}>
                        {option.name}
                      </Text>
                      <Text color="softPeanut" size="s" inline className={styles._lead__company}>
                        {option.website}
                      </Text>
                    </div>
                  </>
                </Item>
              </div>
            );
          })}
        </div>
      </div>
    </Dropdown>
  );
};
