// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".opportunitySyncSettings___children_multiselect___ctvp4 {\n  box-sizing: border-box;\n  padding: 0 32px 52px 32px;\n  display: flex;\n  align-items: center;\n  width: fit-content;\n}\n.opportunitySyncSettings___children_multiselect___ctvp4 > * {\n  margin-right: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/integrationLayout/syncSettingsTab/inbound/salesforce/opportunitySyncSetting/opportunitySyncSettings.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB","sourcesContent":["._children_multiselect {\n  box-sizing: border-box;\n  padding: 0 32px 52px 32px;\n  display: flex;\n  align-items: center;\n  width: fit-content;\n}\n._children_multiselect > * {\n  margin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_children_multiselect": "opportunitySyncSettings___children_multiselect___ctvp4"
};
export default ___CSS_LOADER_EXPORT___;
