import { TASK_STATUS_VALUE_LOGIC_ROLE } from '@bloobirds-it/types';

export type TaskTypeLogicRoles =
  | 'PROSPECT_CADENCE'
  | 'NEXT_STEP'
  | 'CONTACT_BEFORE_MEETING'
  | 'OVERDUE'
  | 'COMPLETED'
  | TASK_STATUS_VALUE_LOGIC_ROLE.OVERDUE
  | TASK_STATUS_VALUE_LOGIC_ROLE.COMPLETED;

export const TASK_TYPE_EQUIVALENCE: { [key: string]: TaskTypeLogicRoles[] } = {
  ON_CADENCE: ['PROSPECT_CADENCE'],
  SCHEDULED: ['NEXT_STEP'],
  MEETING_TASKS: ['CONTACT_BEFORE_MEETING'],
  OVERDUE: [TASK_STATUS_VALUE_LOGIC_ROLE.OVERDUE],
  COMPLETED: [TASK_STATUS_VALUE_LOGIC_ROLE.COMPLETED],
  APPOINTMENTS: ['NEXT_STEP'],
  FOLLOW_UPS: ['PROSPECT_CADENCE', 'CONTACT_BEFORE_MEETING'],
  OVERDUE_SALES: [TASK_STATUS_VALUE_LOGIC_ROLE.OVERDUE],
  COMPLETED_SALES: [TASK_STATUS_VALUE_LOGIC_ROLE.COMPLETED],
};
