// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".viewTable-module___emptyContentRoot___SKXpQ {\n  background-color: var(--lighterGray);\n  padding: 0 0 24px 0;\n}\n\n.viewTable-module___emptyContentDescription___JciFO {\n  margin: 0;\n  padding: 20px 0 0 0;\n}\n\n.viewTable-module___emptyContentSubDescription___DyaFf {\n  margin: 10px 0;\n  color: var(--softPeanut);\n}\n\n.viewTable-module___header___nfCQw {\n  border-spacing: 0 8px;\n  max-width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  background-color: var(--lighterGray);\n}\n\n.viewTable-module___title___xbufR {\n  font-size: 20px;\n  line-height: 41px;\n  color: var(--peanut);\n  margin: 0;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  margin-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/viewTable/viewTable.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,wBAAwB;AAC1B;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,oCAAoC;AACtC;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,SAAS;EACT,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["._emptyContentRoot {\n  background-color: var(--lighterGray);\n  padding: 0 0 24px 0;\n}\n\n._emptyContentDescription {\n  margin: 0;\n  padding: 20px 0 0 0;\n}\n\n._emptyContentSubDescription {\n  margin: 10px 0;\n  color: var(--softPeanut);\n}\n\n._header {\n  border-spacing: 0 8px;\n  max-width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  background-color: var(--lighterGray);\n}\n\n._title {\n  font-size: 20px;\n  line-height: 41px;\n  color: var(--peanut);\n  margin: 0;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_emptyContentRoot": "viewTable-module___emptyContentRoot___SKXpQ",
	"_emptyContentDescription": "viewTable-module___emptyContentDescription___JciFO",
	"_emptyContentSubDescription": "viewTable-module___emptyContentSubDescription___DyaFf",
	"_header": "viewTable-module___header___nfCQw",
	"_title": "viewTable-module___title___xbufR"
};
export default ___CSS_LOADER_EXPORT___;
