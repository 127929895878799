import React from 'react';

import { Icon, Item, Select, Text } from '@bloobirds-it/flamingo-ui';
import { Bobject, BobjectTypes, SalesforceStatus } from '@bloobirds-it/types';

import { ChangeStatusStepDataProps } from '../../../../hooks/useChangeStatusStepData';
import { getBobjectName, getIconName } from '../utils/changeStatusSalesforce.utils';
import styles from './salesforceStatusSelector.module.css';
import StatusLabelButton from './statusLabelButton';

export const SalesforceStatusSelector = ({
  statusList,
  bobject,
  changeStatusStepData,
  setChangeStatusStepData,
  onClick,
  onBobjectChange,
}: {
  statusList: SalesforceStatus[];
  bobject: Bobject;
  changeStatusStepData: ChangeStatusStepDataProps;
  setChangeStatusStepData: (data: ChangeStatusStepDataProps) => void;
  onClick?: () => void;
  onBobjectChange?: (value: string) => void;
}) => {
  if (!statusList) return null;
  const sampleBobject = Array.isArray(bobject) ? bobject[0] : bobject;
  const relatedBobjectName = getBobjectName(sampleBobject);
  const bobjectType = sampleBobject?.id?.typeName?.toLowerCase();

  return (
    <div className={styles._status}>
      <div className={styles._name__wrapper}>
        <Icon color="verySoftPeanut" name={getIconName(sampleBobject)} />
        {Array.isArray(bobject) ? (
          <Select
            size="small"
            width="134px"
            defaultValue={bobject[0]?.id?.value}
            onChange={onBobjectChange}
          >
            {bobject.map(b => {
              return (
                <Item key={b.id} value={b.id?.value}>
                  {getBobjectName(b)}
                </Item>
              );
            })}
          </Select>
        ) : (
          <Text dataTest="Text-Modal-StatusUpdate" size="m" color="peanut">
            {relatedBobjectName}
          </Text>
        )}
      </div>
      <div className={styles._list_status}>
        {statusList?.map(status => (
          <StatusLabelButton
            key={`${bobjectType}-status-${status?.name}`}
            status={status}
            selectedStatus={changeStatusStepData[`${bobjectType}Status`]}
            onClick={() => {
              onClick?.();
              setChangeStatusStepData({
                ...changeStatusStepData,
                [`${bobjectType}Status`]: status.name,
              });
            }}
          />
        ))}
      </div>
    </div>
  );
};
