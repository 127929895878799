// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".productHubspotSection-module___grid___xfNDp > section > div {\n  display: flex;\n  gap: 16px;\n  flex-wrap: wrap;\n  padding: 0 20px;\n}\n\n.productHubspotSection-module__select___Dx0GT {\n  width: 100%;\n}\n\n.productHubspotSection-module__select2___ba6vI {\n  width: calc(50% - 8px);\n}\n\n.productHubspotSection-module__select2___ba6vI > div {\n  width: 100%;\n}\n\n.productHubspotSection-module__select2___ba6vI > div > div {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/productHubspotSection/productHubspotSection.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":["._grid > section > div {\n  display: flex;\n  gap: 16px;\n  flex-wrap: wrap;\n  padding: 0 20px;\n}\n\n.select {\n  width: 100%;\n}\n\n.select2 {\n  width: calc(50% - 8px);\n}\n\n.select2 > div {\n  width: 100%;\n}\n\n.select2 > div > div {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_grid": "productHubspotSection-module___grid___xfNDp",
	"select": "productHubspotSection-module__select___Dx0GT",
	"select2": "productHubspotSection-module__select2___ba6vI"
};
export default ___CSS_LOADER_EXPORT___;
