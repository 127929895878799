// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GenerateJustcallTokenModal-module__input_container___nFHVq {\n  display: flex;\n  justify-content: center;\n}\n\n.GenerateJustcallTokenModal-module__input_container___nFHVq > * {\n  margin: 8px;\n}\n\n.GenerateJustcallTokenModal-module__subtitle___pWLRL {\n  margin-bottom: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/dialersPage/justCallIntegration/GenerateJustcallTokenModal/GenerateJustcallTokenModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".input_container {\n  display: flex;\n  justify-content: center;\n}\n\n.input_container > * {\n  margin: 8px;\n}\n\n.subtitle {\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_container": "GenerateJustcallTokenModal-module__input_container___nFHVq",
	"subtitle": "GenerateJustcallTokenModal-module__subtitle___pWLRL"
};
export default ___CSS_LOADER_EXPORT___;
