// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".taskHeader-module__root___JTCtg {\n  display: flex;\n  border-bottom: 1px solid var(--verySoftPeanut);\n  padding-bottom: 16px;\n  margin-bottom: 20px;\n}\n\n.taskHeader-module__left___jI\\+TH {\n  flex-grow: 1;\n}\n\n.taskHeader-module__right___b1Ssc {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.taskHeader-module__title___idvI8 {\n  margin-bottom: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/app/main/board/task/taskBoard/workspace/taskHeader/taskHeader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8CAA8C;EAC9C,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".root {\n  display: flex;\n  border-bottom: 1px solid var(--verySoftPeanut);\n  padding-bottom: 16px;\n  margin-bottom: 20px;\n}\n\n.left {\n  flex-grow: 1;\n}\n\n.right {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.title {\n  margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "taskHeader-module__root___JTCtg",
	"left": "taskHeader-module__left___jI+TH",
	"right": "taskHeader-module__right___b1Ssc",
	"title": "taskHeader-module__title___idvI8"
};
export default ___CSS_LOADER_EXPORT___;
