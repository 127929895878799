// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".searchBar-module___input___4bZPC {\n  height: 40px;\n  width: 200px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/searchBar/searchBar.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;AACd","sourcesContent":["._input {\n  height: 40px;\n  width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_input": "searchBar-module___input___4bZPC"
};
export default ___CSS_LOADER_EXPORT___;
