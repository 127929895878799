import React from 'react';

import { Icon, IconType, Text } from '@bloobirds-it/flamingo-ui';

import styles from '../../styles/phoneNumberPage.module.css';

export const CapabilitiesCard = ({ capability }: { capability: string }) => {
  const switchIcon = (capability: string) => {
    switch (capability) {
      case 'sms':
        return 'chat';
      case 'Voice':
        return 'phone';
      case 'whatsapp':
        return 'whatsapp';
      default:
        return 'phone';
    }
  };

  return (
    <div className={styles.capabilitiesCard}>
      <Icon name={switchIcon(capability) as IconType} size={14} color="peanut" />
      <Text size="xs" weight="medium" color="peanut">
        {capability}
      </Text>
    </div>
  );
};
