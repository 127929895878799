// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".accountSettingsPage-module___container___p3vrU {\n  display: flex;\n  width: 100%;\n  height: calc(100vh - 48px);\n}\n\n.accountSettingsPage-module___content__container___vHOUT {\n  width: 100%;\n  height: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n  background-color: var(--lighterGray);\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/accountSettingsPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,oCAAoC;AACtC","sourcesContent":["._container {\n  display: flex;\n  width: 100%;\n  height: calc(100vh - 48px);\n}\n\n._content__container {\n  width: 100%;\n  height: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n  background-color: var(--lighterGray);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "accountSettingsPage-module___container___p3vrU",
	"_content__container": "accountSettingsPage-module___content__container___vHOUT"
};
export default ___CSS_LOADER_EXPORT___;
