// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".virtualInfiniteScroll-module___row___9-RMt > div {\n  margin-top: 0;\n  margin-bottom: 8px !important; /* For virtual infinite scroll cards only, empty when all infinite scroll has been migrated */\n  margin-left: 0;\n  margin-right: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/virtualInfiniteScroll/virtualInfiniteScroll.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B,EAAE,6FAA6F;EAC5H,cAAc;EACd,eAAe;AACjB","sourcesContent":["._row > div {\n  margin-top: 0;\n  margin-bottom: 8px !important; /* For virtual infinite scroll cards only, empty when all infinite scroll has been migrated */\n  margin-left: 0;\n  margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_row": "virtualInfiniteScroll-module___row___9-RMt"
};
export default ___CSS_LOADER_EXPORT___;
