import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Item, Section, Select, Text } from '@bloobirds-it/flamingo-ui';
import { useActiveUserSettings, useMainRelatedUsageEnabled } from '@bloobirds-it/hooks';
import { BobjectId, BobjectTypes, RelatedObjectClass } from '@bloobirds-it/types';

import { useRelatedAssignment } from '../../hooks/useRelatedAssignment';
import styles from './assingRelatedSelect.module.css';

export const AssignRelatedSelect = ({
  activityId,
  activityRelated,
  referenceBobjectType,
}: {
  activityId: BobjectId<BobjectTypes.Activity>['value'];
  activityRelated?: string | string[];
  referenceBobjectType: BobjectTypes;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'relatedObjects.assignRelatedSelect' });
  const { settings } = useActiveUserSettings();
  const mainRelatedUsageEnabled = useMainRelatedUsageEnabled(settings?.account?.id);
  const { addRelatedToBobject, activityAllRelated } = useRelatedAssignment({
    bobjectId: activityId,
    referenceBobjectType,
  });
  const [selectValue, setSelectValue] = React.useState<string | string[]>(
    typeof activityRelated === 'string' ? activityRelated : activityRelated?.[0],
  );
  const defaultValue = typeof activityRelated === 'string' ? activityRelated : activityRelated?.[0];

  useEffect(() => {
    if (selectValue !== defaultValue) {
      addRelatedToBobject(selectValue);
    }
  }, [selectValue]);

  const handleChange = value => {
    if (!value) setSelectValue([]);
    setSelectValue(value);
  };

  return mainRelatedUsageEnabled && activityAllRelated?.length > 0 ? (
    <div className={styles.wrapper}>
      <Text size="m" color="peanut" weight="medium">
        {t('title')}
      </Text>
      <Select
        size="small"
        width="100%"
        value={selectValue}
        defaultValue={typeof activityRelated === 'string' ? activityRelated : activityRelated?.[0]}
        onChange={handleChange}
        autocomplete
        placeholder={t('placeholder')}
      >
        <Item key="none" value={null} label={t('edition.none')}>
          {t('none')}
        </Item>
        {activityAllRelated?.map((rel, index) => {
          return [
            <Section key={rel?.relatedObjectType + '_' + index}>{rel?.relatedObjectType}</Section>,
            ...rel?.relatedSobjects?.map(rawR => {
              const r = new RelatedObjectClass(rawR);
              return (
                <Item key={r?.compositeRelatedId} value={r?.compositeRelatedId} label={r?.title}>
                  {r.title}
                </Item>
              );
            }),
          ];
        })}
      </Select>
    </div>
  ) : null;
};
