import { useEffect, useRef, useState } from 'react';

import {
  MessagesEvents,
  MessagingResponseV2,
  PaginationFetcherType,
  UseMessagingReturnType,
  UseMessagingTemplatesOptions,
} from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import isEqual from 'lodash/isEqual';
import useSWR from 'swr';

import { useDebouncedCallback } from '../useDebouncedCallback';

export const useDeepEffect = (effect: any, deps?: any) => {
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isSame = prevDeps.current.every((obj, index) => isEqual(obj, deps[index]));

    if (isFirst.current || !isSame) {
      effect();
    }

    isFirst.current = false;
    prevDeps.current = deps;
  }, deps);
};

const searchMessagingTemplates = (
  url: string,
  filters: UseMessagingTemplatesOptions,
  pagination: PaginationFetcherType,
) => {
  const { name, segmentationValues, showCadencesTemplates } = filters || {};

  return api
    .post(`${url}?sort=updateDatetime%2Cdesc`, {
      criteria: {
        ...filters,
        name: name || '',
        segmentationValues: segmentationValues ? JSON.parse(segmentationValues) : {},
        usedInCadences: showCadencesTemplates,
      },
      ...pagination,
    })
    .then(res => {
      return res?.data;
    });
};

export const useMessagingTemplates = (
  filters: UseMessagingTemplatesOptions,
  environment = 'non-set',
): UseMessagingReturnType => {
  const { segmentationValues, type } = filters;
  const [myTemplatesPage, setMyTemplatesPage] = useState(0);
  const [myTemplatesPageSize, setMyTemplatesSize] = useState(3);
  const [teamTemplatesPage, setTeamTemplatesPage] = useState(0);
  const [teamTemplatesPageSize, setTeamTemplatesSize] = useState(5);
  const [isDebouncing, setIsDebouncing] = useState(false);

  const { data, error, mutate, isLoading } = useSWR<MessagingResponseV2>(
    '/messagingTemplates/v2-' + environment,
    () =>
      searchMessagingTemplates(
        '/messaging/messagingTemplates/search/v2',
        {
          ...filters,
          segmentationValues: segmentationValues ? JSON.stringify(segmentationValues) : undefined,
        },
        { myTemplatesPage, myTemplatesPageSize, teamTemplatesPage, teamTemplatesPageSize },
      ),
  );

  useEffect(() => {
    window.addEventListener(MessagesEvents.PlaybookFeed, () => mutate());
    return () => {
      window.removeEventListener(MessagesEvents.PlaybookFeed, () => mutate());
    };
  }, []);

  useEffect(() => {
    setMyTemplatesPage(0);
    setTeamTemplatesPage(0);
  }, [type, filters?.name]);

  const debouncedMutator = useDebouncedCallback(
    () => {
      mutate(async () => {
        return searchMessagingTemplates(
          '/messaging/messagingTemplates/search/v2',
          {
            ...filters,
            segmentationValues: segmentationValues ? JSON.stringify(segmentationValues) : undefined,
          },
          { myTemplatesPage, myTemplatesPageSize, teamTemplatesPage, teamTemplatesPageSize },
        );
      }).then(() => setIsDebouncing(false));
    },
    100,
    [mutate, filters],
  );

  useDeepEffect(() => {
    setIsDebouncing(true);
    // If there is a mutation in progress regardless if we have data or not, we should cancel the mutate and relaunch the debouncedMutator
    debouncedMutator();
  }, [filters, myTemplatesPage, myTemplatesPageSize, teamTemplatesPage, teamTemplatesPageSize]);

  return {
    messagingTemplates: [
      ...(data?.myTemplates?.messagingTemplates || []),
      ...(data?.teamTemplates?.messagingTemplates || []),
    ],
    teamTemplates: {
      ...data?.teamTemplates,
      paginationProps: {
        page: teamTemplatesPage,
        rowsPerPage: teamTemplatesPageSize,
        onChangePage: setTeamTemplatesPage,
        onChangeRowsPerPage: setTeamTemplatesSize,
      },
    },
    myTemplates: {
      ...data?.myTemplates,
      paginationProps: {
        page: myTemplatesPage,
        rowsPerPage: myTemplatesPageSize,
        onChangePage: setMyTemplatesPage,
        onChangeRowsPerPage: setMyTemplatesSize,
      },
    },
    isLoading: isLoading || isDebouncing,
    isError: !!error,
  };
};
