// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".connectNumintecModal-module__input_container___jch9b {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n}\n\n.connectNumintecModal-module__input_container___jch9b > * {\n  margin: 8px;\n}\n\n.connectNumintecModal-module__subtitle___ByIHl {\n  margin-bottom: 16px;\n}\n\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/dialersPage/numintecIntegration/connectNumintecModal/connectNumintecModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".input_container {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n}\n\n.input_container > * {\n  margin: 8px;\n}\n\n.subtitle {\n  margin-bottom: 16px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_container": "connectNumintecModal-module__input_container___jch9b",
	"subtitle": "connectNumintecModal-module__subtitle___ByIHl"
};
export default ___CSS_LOADER_EXPORT___;
