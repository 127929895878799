import React from 'react';

import { Text } from '@bloobirds-it/flamingo-ui';

import styles from '../../styles/phoneNumberPage.module.css';

export const TagsCard = ({ label }: { label: string }) => {
  return (
    <div className={styles.tagsCard}>
      <Text size="xs" color="peanut" weight="medium">
        {label}
      </Text>
    </div>
  );
};
