import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormField } from '@bloobirds-it/bobjects';
import {
  Button,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Spinner,
  Text,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import { useLoadingPromise } from '@bloobirds-it/hooks';
import {
  ACTIVITY_TYPE_OPTIONS,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  GeneralPrompt,
} from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import clsx from 'clsx';

import { useActivityFieldsInfo } from '../hooks/useActivityFieldsInfo';
import styles from './generalPromptFormModal.module.css';

interface IGeneralPromptForm {
  activityType: ACTIVITY_TYPES_VALUES_LOGIC_ROLE | 'All' | null;
  activitySubType: 'All' | string;
}

interface IGeneralPromptFormModal {
  onClose: () => void;
  currentPrompt: GeneralPrompt;
  accountPrompts: Map<string, GeneralPrompt>;
  onSave?: (newValue: GeneralPrompt) => void;
}

export const GeneralPromptFormModal = ({
  onClose,
  currentPrompt,
  onSave,
  accountPrompts,
}: IGeneralPromptFormModal) => {
  const { t } = useTranslation();
  const methods = useForm({ defaultValues: { activityType: 'All', activitySubType: 'All' } });
  const { meetingTypes } = useActivityFieldsInfo();
  const defaultFormValues = {
    control: methods.control,
    hideLabel: true,
    visible: true,
    enabled: true,
    size: 'small' as const,
    withFormGroup: false,
  };
  const { loading, promiseHandler } = useLoadingPromise();
  const { createToast } = useToasts();
  const activityType = methods.watch('activityType');
  const activitySubType = methods.watch('activitySubType');

  if (
    // TODO: FE-806 Remove this retrocompatibility in new beta
    !['Meeting', ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING].includes(activityType) &&
    activitySubType !== 'All'
  ) {
    methods.setValue('activitySubType', 'All');
  }

  // TODO: FE-806 Remove this retrocompatibility in new beta
  const subTypes = ['Meeting', ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING].includes(activityType)
    ? [...meetingTypes.values()]
    : [];

  const onSubmit = (data: IGeneralPromptForm) => {
    let promptType = currentPrompt.promptType;

    if (Object.values(data).every(v => v === 'All')) return;

    if (data.activityType !== 'All') {
      promptType += `:${data.activityType}`;
    }

    if (Object.values(data).every(v => v !== 'All')) {
      promptType += `:${data.activitySubType}`;
    }

    const existingPrompt = accountPrompts.get(promptType);

    if (existingPrompt) {
      createToast({
        type: 'error',
        message: t('ai.playground.generalPromptsTab.createPromptSubTypeError'),
      });
      return;
    }

    promiseHandler(
      () => api.post<GeneralPrompt>(`/utils/service/prompts/subtype`, { promptType }),
      {
        onSuccess: response => {
          onSave?.(response);
          onClose?.();
        },
        successToast: 'ai.generalPromptFormModal.onSuccess',
        errorToast: 'ai.generalPromptFormModal.onError',
      },
    );
  };

  if (!currentPrompt) return null;
  return (
    <FormProvider {...methods}>
      <Modal open onClose={!loading ? onClose : null} width={400}>
        <ModalHeader color="white" size="small">
          <ModalTitle color="purple" icon="plus" size="small">
            <Text size="l" weight="bold">
              {t('ai.generalPromptFormModal.title')}
            </Text>
          </ModalTitle>
          <ModalCloseIcon color="purple" size="small" onClick={onClose} />
        </ModalHeader>
        <ModalContent className={styles.contentContainer}>
          <>
            <div className={styles.formRow}>
              <Text size="s">{t('ai.insightFormModal.activityType.label')}*</Text>
              <FormField
                type="PICKLIST"
                id="activityType"
                formKey="activityType"
                name="activityType"
                placeholder={t('ai.insightFormModal.activityType.placeholder')}
                values={ACTIVITY_TYPE_OPTIONS(t)?.filter(v => v?.enabled)}
                required
                order={4}
                {...defaultFormValues}
              />
            </div>

            <div className={styles.formRow}>
              <Text size="s">{t('ai.insightFormModal.activitySubtype.label')}*</Text>
              <FormField
                type="PICKLIST"
                id="activitySubType"
                formKey="activitySubType"
                name="activitySubType"
                placeholder={t('ai.insightFormModal.activitySubtype.placeholder')}
                values={[ACTIVITY_TYPE_OPTIONS(t)[0], ...subTypes]}
                order={5}
                {...defaultFormValues}
                enabled={activityType !== 'All'}
              />
            </div>
          </>
        </ModalContent>
        <ModalFooter className={styles.footer}>
          <Button
            uppercase={false}
            onClick={methods.handleSubmit(onSubmit)}
            size="small"
            color={activityType === 'All' ? 'veryLightPeanut' : 'purple'}
            className={clsx({ [styles.loadingButton]: loading })}
            disabled={activityType === 'All'}
          >
            {loading ? (
              <Spinner name="dots" size={16} color="white" />
            ) : (
              t('ai.generalPromptFormModal.createButton')
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </FormProvider>
  );
};
