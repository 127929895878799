import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@bloobirds-it/flamingo-ui';

import AddPhoneStep from './addPhoneStep';
import DefaultPhoneStep from './defaultPhoneStep';

const AddPhoneModal = ({ open, handleClose }) => {
  const [step, setStep] = useState(0);
  const [newConnection, setNewConnection] = useState(null);
  const { t } = useTranslation();

  return (
    <Modal
      title={t('userSettingsWebapp.callSettings.addPhoneModal.title')}
      open={open}
      onClose={handleClose}
    >
      {step === 0 && (
        <AddPhoneStep
          handleClose={handleClose}
          handleNextStep={createdConnection => {
            setNewConnection(createdConnection);
            setStep(1);
          }}
        />
      )}
      {step === 1 && <DefaultPhoneStep handleClose={handleClose} newConnection={newConnection} />}
    </Modal>
  );
};

export default AddPhoneModal;
