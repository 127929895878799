// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".meetingTaskBoxCard-module___check_wrapper___4c7ja {\n  padding-right: 8px;\n}\n\n.meetingTaskBoxCard-module___callCard_body__text___xNpOh {\n  padding-right: 8px;\n  align-self: auto;\n}\n\n.meetingTaskBoxCard-module___callCard_body__link___QkyJU {\n  padding-right: 5px;\n}\n\n.meetingTaskBoxCard-module___button_reported_container___TFQSV {\n  background-color: var(--verySoftMelon);\n  padding: 4px 8px;\n  border-radius: 4px;\n}\n\n.meetingTaskBoxCard-module___separator___bcNKo {\n  display: flex;\n  align-self: center;\n  margin-right: 8px;\n  width: 1px;\n  height: 8px;\n  background-color: var(--softPeanut);\n}\n\n.meetingTaskBoxCard-module___card___C6NcP > div {\n  width: 100% !important;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/contactPages/meetingCard/meetingTaskBoxCard.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,sCAAsC;EACtC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,UAAU;EACV,WAAW;EACX,mCAAmC;AACrC;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["._check_wrapper {\n  padding-right: 8px;\n}\n\n._callCard_body__text {\n  padding-right: 8px;\n  align-self: auto;\n}\n\n._callCard_body__link {\n  padding-right: 5px;\n}\n\n._button_reported_container {\n  background-color: var(--verySoftMelon);\n  padding: 4px 8px;\n  border-radius: 4px;\n}\n\n._separator {\n  display: flex;\n  align-self: center;\n  margin-right: 8px;\n  width: 1px;\n  height: 8px;\n  background-color: var(--softPeanut);\n}\n\n._card > div {\n  width: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_check_wrapper": "meetingTaskBoxCard-module___check_wrapper___4c7ja",
	"_callCard_body__text": "meetingTaskBoxCard-module___callCard_body__text___xNpOh",
	"_callCard_body__link": "meetingTaskBoxCard-module___callCard_body__link___QkyJU",
	"_button_reported_container": "meetingTaskBoxCard-module___button_reported_container___TFQSV",
	"_separator": "meetingTaskBoxCard-module___separator___bcNKo",
	"_card": "meetingTaskBoxCard-module___card___C6NcP"
};
export default ___CSS_LOADER_EXPORT___;
