// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.sankeyTooltip-module__nodeValue___sjBgu {\n    padding: 4px;\n    border-radius: 4px;\n}\n.sankeyTooltip-module__tooltipChangeDescription___BKbce {\n    display: flex;\n    justify-content: left;\n    align-items: center;\n    margin-bottom: 2px;\n}\n\n.sankeyTooltip-module__tooltipIcon___VMwjq {\n    margin-right: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/dashboardPages/funnelPanel/sankeyTooltip/sankeyTooltip.module.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,qBAAqB;IACrB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["\n.nodeValue {\n    padding: 4px;\n    border-radius: 4px;\n}\n.tooltipChangeDescription {\n    display: flex;\n    justify-content: left;\n    align-items: center;\n    margin-bottom: 2px;\n}\n\n.tooltipIcon {\n    margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nodeValue": "sankeyTooltip-module__nodeValue___sjBgu",
	"tooltipChangeDescription": "sankeyTooltip-module__tooltipChangeDescription___BKbce",
	"tooltipIcon": "sankeyTooltip-module__tooltipIcon___VMwjq"
};
export default ___CSS_LOADER_EXPORT___;
