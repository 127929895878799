import React from 'react';

import { Text } from '@bloobirds-it/flamingo-ui';
import {
  useActiveAccountId,
  useActivityUserMentionsEnabled,
  useUserMentions,
} from '@bloobirds-it/hooks';
import {
  EditorToolbar,
  EditorToolbarFontStylesSection,
  EditorToolbarListsSection,
  EditorToolbarTextMarksSection,
  RichTextEditor,
  deserialize,
  useRichTextEditorPlugins,
} from '@bloobirds-it/rich-text-editor';
import { createParagraph, isHtml } from '@bloobirds-it/utils';

import styles from './notesForm.module.css';

const getText = (value, notesPlugins) => {
  if (value) {
    return typeof value === 'string'
      ? isHtml(value)
        ? deserialize(value, {
            format: 'HTML',
            plugins: notesPlugins,
          })
        : createParagraph(value)
      : value;
  }

  return null;
};

export const NotesForm = ({
  notesField,
  title,
  placeholder,
  enableMentions = false,
}: {
  notesField: { value: any; onChange: any; ref: any };
  title: string;
  placeholder: string;
  enableMentions?: boolean;
}) => {
  const { value, onChange, ref } = notesField;
  const users = useUserMentions();
  const accountId = useActiveAccountId();
  const mentionsEnabled = useActivityUserMentionsEnabled(accountId);

  const notesPlugins = useRichTextEditorPlugins({
    images: false,
    replaceParagraphs: true,
    userMentions: mentionsEnabled && enableMentions,
    elements: true,
  });

  const text = getText(value, notesPlugins); // create paragraph

  return (
    <div className={styles._notes__container} ref={ref}>
      <Text size="m">{title}</Text>
      <div className={styles._notes__box}>
        <RichTextEditor
          defaultValue={text}
          value={text}
          placeholder={placeholder}
          plugins={notesPlugins}
          onChange={onChange}
          users={users?.users}
          style={{
            fontFamily: 'Proxima Nova Soft',
          }}
        >
          {editor => (
            <>
              <EditorToolbar>
                <div className={styles.toolbarContainer}>
                  <EditorToolbarFontStylesSection />
                  <EditorToolbarTextMarksSection />
                  <EditorToolbarListsSection />
                </div>
              </EditorToolbar>
              <div className={styles._notes_content}>{editor}</div>
            </>
          )}
        </RichTextEditor>
      </div>
    </div>
  );
};
