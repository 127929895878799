// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dayLimitBanner-module___banner_link___csWAX {\n  color: var(--bloobirds);\n  cursor: pointer;\n}\n\n.dayLimitBanner-module___banner_link___csWAX:hover {\n  text-decoration: underline;\n}\n\n.dayLimitBanner-module___banner_icon___gZZS3 {\n  margin-right: 20px;\n}\n\n.dayLimitBanner-module___banner_container___7W\\+Pu {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 5px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/dayLimitBanner/dayLimitBanner.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;AAChB","sourcesContent":["._banner_link {\n  color: var(--bloobirds);\n  cursor: pointer;\n}\n\n._banner_link:hover {\n  text-decoration: underline;\n}\n\n._banner_icon {\n  margin-right: 20px;\n}\n\n._banner_container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 5px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_banner_link": "dayLimitBanner-module___banner_link___csWAX",
	"_banner_icon": "dayLimitBanner-module___banner_icon___gZZS3",
	"_banner_container": "dayLimitBanner-module___banner_container___7W+Pu"
};
export default ___CSS_LOADER_EXPORT___;
