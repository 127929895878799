import { CountryCode, IconType } from '@bloobirds-it/flamingo-ui';
import { name } from 'faker';

export const PHONE_COLUMNS = [
  {
    name: 'numbername',
    sortable: true,
    id: 'numbername',
  },
  {
    name: 'capabilities',
    sortable: true,
    id: 'capabilities',
  },
  {
    name: 'tags',
    sortable: true,
    id: 'tags',
  },
  {
    name: 'activeConfiguration',
    sortable: true,
    id: 'activeConfiguration',
  },
  {
    name: 'users',
    sortable: true,
    id: 'users',
  },
  {
    name: 'status',
    sortable: true,
    id: 'status',
  },
];

export const SORT_VALUES = [
  {
    name: 'creationDate_ASC',
    displayName: 'creationDate',
    id: 'creationDate',
    sortOrder: 'ASC',
  },
  {
    name: 'creationDate_DESC',
    displayName: 'creationDate',
    id: 'creationDate',
    sortOrder: 'DESC',
  },
  {
    name: 'lastUpdate_ASC',
    displayName: 'lastUpdate',
    id: 'lastUpdate',
    sortOrder: 'ASC',
  },
  {
    name: 'lastUpdate_DESC',
    displayName: 'lastUpdate',
    id: 'lastUpdate',
    sortOrder: 'DESC',
  },
  {
    name: 'country',
    displayName: 'country',
    id: 'country',
    sortOrder: 'ASC',
  },
  {
    name: 'prefix_',
    displayName: 'prefix',
    id: 'prefix',
    sortOrder: 'ASC',
  },
  {
    name: 'status',
    displayName: 'status',
    id: 'status',
    sortOrder: 'ASC',
  },
];

export interface PhoneNumber {
  id: string;
  flag: CountryCode;
  phoneNumber: string;
  users: any[];
  usersRoundRobin: any[];
  type: any;
  name: string;
  location: string;
  activeConfiguration: string[];
  capabilities: string[];
  tags: string[];
  status: boolean;
  typeOfNumber: string;
  countryCode: string;
  callRecordingEnabled: boolean;
  prefix: string;
  phonesRequested?: number;
  callDistributionType: string;
  sid: string;
}

export interface PhoneNumbersTableContentProps {
  entities: PhoneNumber[];
  totalElements: number;
  isLoading: boolean;
}

export const PHONE_DISTRIUBUTION_TYPES = {
  EVERYONE: 'everyone',
  ROUND_ROBIN: 'roundRobin',
  QUEUE: 'queue',
};

export interface UserInterface {
  id: string;
  name: string;
  email: string;
  color: string;
}

export interface Step {
  name: string;
  icon: IconType;
}

export interface Country {
  name: {
    common: string;
  };
  cca2: string;
  idd: {
    root: string;
    suffixes: string[];
  };
  flagEmoji?: string;
}

export interface RequestedPhone {
  id: string;
  phonesRequested: string;
  country: string;
  prefix: string[];
  typeOfNumber: string;
  additionalInformation: string;
  capabilities: string[];
}
