// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".typeButton-module___container___qeJRj {\n  display: flex;\n  flex-direction: column;\n  width: 429px;\n  height: 100px;\n  margin-bottom: 20px;\n  padding: 20px 24px;\n  border: 1px solid #1991ff;\n  box-sizing: border-box;\n  border-radius: 4px;\n  cursor: pointer;\n}\n\n.typeButton-module___header___9weUy {\n  display: flex;\n  flex: 1;\n}\n\n.typeButton-module___icon___aYeT2 {\n  flex: 1;\n}\n\n.typeButton-module___title___\\+HbDc {\n  flex: 10;\n}\n\n.typeButton-module___body___MQvng {\n  flex: 1;\n  margin-top: 6px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectTable/createListModal/typeList/typeButton/typeButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,OAAO;AACT;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,OAAO;EACP,eAAe;AACjB","sourcesContent":["._container {\n  display: flex;\n  flex-direction: column;\n  width: 429px;\n  height: 100px;\n  margin-bottom: 20px;\n  padding: 20px 24px;\n  border: 1px solid #1991ff;\n  box-sizing: border-box;\n  border-radius: 4px;\n  cursor: pointer;\n}\n\n._header {\n  display: flex;\n  flex: 1;\n}\n\n._icon {\n  flex: 1;\n}\n\n._title {\n  flex: 10;\n}\n\n._body {\n  flex: 1;\n  margin-top: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "typeButton-module___container___qeJRj",
	"_header": "typeButton-module___header___9weUy",
	"_icon": "typeButton-module___icon___aYeT2",
	"_title": "typeButton-module___title___+HbDc",
	"_body": "typeButton-module___body___MQvng"
};
export default ___CSS_LOADER_EXPORT___;
