import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Button,
  ModalFooter,
  Select,
  Item,
} from '@bloobirds-it/flamingo-ui';
import { useSignatures, SignatureProps } from '@bloobirds-it/hooks';

import styles from './changeSignatureModal.module.css';

export const ChangeSignatureModal = ({
  connectionId,
  onClose,
}: {
  connectionId: string;
  onClose: () => void;
}) => {
  const { data: signatures, signature, changeSignatureConnection } = useSignatures(connectionId);
  const [signatureId, setSignatureId] = useState(signature?.id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation('translation', {
    keyPrefix: 'userSettingsWebapp.changeSignatureModal',
  });

  const handleChangeSignature = async () => {
    if (signatureId) {
      setIsSubmitting(true);
      changeSignatureConnection(signatureId);
      setIsSubmitting(false);
      onClose();
    }
  };

  return (
    <Modal open onClose={onClose} width={500}>
      <ModalHeader size="small">
        <ModalTitle variant="primary" size="small" icon={'signature'}>
          {t('title')}
        </ModalTitle>
        <ModalCloseIcon onClick={onClose} />
      </ModalHeader>
      <ModalContent>
        <Select
          className={styles.select}
          width="100%"
          placeholder={t('placeholder')}
          value={signatureId}
          onChange={setSignatureId}
        >
          {signatures?.map((s: SignatureProps) => (
            <Item key={s.id} value={s.id}>
              {s.name}
            </Item>
          ))}
        </Select>
      </ModalContent>
      <ModalFooter className={styles.footer}>
        <Button variant="clear" color="tomato" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button disabled={!signatureId || isSubmitting} onClick={handleChangeSignature}>
          {t('save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
