// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".newStageCard-module___status_value___x9O\\+L {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.newStageCard-module___color__picker___VneDw > div > div {\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/playbookPages/salesPipelinePage/newStageCard/newStageCard.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,SAAS;AACX","sourcesContent":["._status_value {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n._color__picker > div > div {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_status_value": "newStageCard-module___status_value___x9O+L",
	"_color__picker": "newStageCard-module___color__picker___VneDw"
};
export default ___CSS_LOADER_EXPORT___;
