import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Text } from '@bloobirds-it/flamingo-ui';

import styles from '../deleteMessage/deleteMessage.module.css';

export const ConfirmDeleteMessage = ({
  onClick,
  onCancel,
  message,
}: {
  onClick: () => void;
  onCancel: () => void;
  message: string;
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onCancel();
      }
    }

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <div ref={ref} className={styles.confirmDeleteContainer}>
      <div className={styles.confirmDeleteText}>
        <Text size="s" color="peanut" align="center">
          {message}
        </Text>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '16px' }}>
        <Button
          size="small"
          variant="clear"
          onClick={e => {
            e.stopPropagation();
            onCancel();
          }}
        >
          <Text size="xs" color="bloobirds">
            {t('common.cancel')}
          </Text>
        </Button>
        <Button
          variant="primary"
          size="small"
          onClick={e => {
            e.stopPropagation();
            onClick();
          }}
          color="tomato"
          className={styles.confirmDeleteButton}
        >
          <Text size="xs" color="white">
            {t('common.delete')}
          </Text>
        </Button>
      </div>
    </div>
  );
};
