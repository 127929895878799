import React from 'react';

import { CircularBadge, Text } from '@bloobirds-it/flamingo-ui';
import { Data, NoData } from '@udecode/plate-combobox';
import { getPluginOptions, usePlateEditorRef } from '@udecode/plate-core';
import { MentionPlugin } from '@udecode/plate-mention';
import { Combobox, ComboboxProps } from '@udecode/plate-ui-combobox';

import { ELEMENT_USER_MENTION } from './defaults';
import { getMentionOnSelection } from './getMentionOnSelection';

export interface MentionComboboxProps<TData extends Data = NoData>
  extends Partial<ComboboxProps<TData>> {
  pluginKey?: string;
}

export const CustomMentionCombobox = <TData extends Data = NoData>({
  pluginKey = ELEMENT_USER_MENTION,
  id = pluginKey,
  ...props
}: MentionComboboxProps<TData>) => {
  const editor = usePlateEditorRef();
  const { trigger } = getPluginOptions<MentionPlugin>(editor, pluginKey);

  return (
    <Combobox
      id={id}
      trigger={trigger!}
      controlled
      onSelectItem={getMentionOnSelection({
        key: pluginKey,
      })}
      onRenderItem={({ item }) => <UserItem key={item?.['data-id']} item={item} />}
      {...props}
    />
  );
};

const UserItem = ({ item }: { item: Record<string, any> }) => {
  const { color, shortname } = item?.data[0] || {};
  return (
    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
      <CircularBadge backgroundColor={color || 'lightPeanut'} size="s" color="white">
        {shortname || 'U'}
      </CircularBadge>
      <Text size="s">{item.text}</Text>
    </div>
  );
};
