import React, { useEffect } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { FormField } from '@bloobirds-it/bobjects';
import {
  Button,
  createToast,
  Icon,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Text,
} from '@bloobirds-it/flamingo-ui';
import { BobjectId, SALESFORCE_LOGIC_ROLES, Sobject } from '@bloobirds-it/types';
import { api, getSobjectTypeFromBobject, getTextFromLogicRole } from '@bloobirds-it/utils';

import { CustomObjectForm } from '../../../../../customObject/customObjectForm';
import { useStatusNoteActionContext } from '../../../../hooks/useStatusNoteActions';
import { getBobjectInfo, useUpdatableFields } from '../../utils/salesforceFields.utils';
import styles from './updateCRMFieldsModal.module.css';

const BloobirdsFieldBlock = ({ bobjectId, fields, defaultValues }) => {
  const { control } = useFormContext();
  return (
    <form className={styles.bloobirdsFieldBlock}>
      {fields.map(field => {
        if (!field) return null;
        const fieldId = field.id;
        return (
          <div key={fieldId} className={styles['label-input-container']}>
            <div className={styles.label}>
              <Text size="xs">{field.name}</Text>
            </div>
            <div className={styles['custom-object-wrapper']}>
              <FormField
                hideLabel
                control={control}
                size="small"
                {...field}
                type={field.fieldType}
                formKey={`${bobjectId}.bobject.${fieldId}`}
                defaultValue={defaultValues?.[fieldId]}
                withFormGroup={false}
              />
            </div>
          </div>
        );
      })}
    </form>
  );
};

const SalesforceFieldBlock = ({ bobjectId, fields, defaultValues }) => {
  const { control } = useFormContext();
  return (
    <div>
      <div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
        {fields.map(field => {
          const fieldName = field.name;
          return (
            <div key={field?.id} className={styles['label-input-container']}>
              <div className={styles.label}>
                <Icon name="salesforce" color="softPeanut" size={24} />
                <Text size="xs">{field.label}</Text>
              </div>
              <div className={styles['custom-object-wrapper']}>
                <CustomObjectForm
                  key={fieldName}
                  control={control}
                  {...field}
                  name={fieldName}
                  styleProps={{
                    size: 'small',
                  }}
                  defaultValue={defaultValues?.[fieldName]}
                  formKey={`${bobjectId}.sobject.${fieldName}`}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const BobjectFieldsToUpdate = ({ bobject, parsedFields }) => {
  const bobjectInfo = getBobjectInfo(bobject);
  const bobjectType = bobject?.id.typeName;
  const bobjectId = bobject?.id.value;
  const sobjectType = getSobjectTypeFromBobject(bobject) as Sobject;
  const { watch, setValue } = useFormContext();

  useEffect(() => {
    if (!bobject) return;
    const bobjectFields = parsedFields?.bloobirdsFields?.[bobjectType];
    bobjectFields?.forEach(field => {
      const fieldName = field.id;
      const fieldValue = bobject?.raw?.contents?.[fieldName] || bobject?.rawBobject?.[fieldName];
      setValue(`${bobjectId}.bobject.${field.id}`, fieldValue);
    });
    const sobjectType = getSobjectTypeFromBobject(bobject) as Sobject;
    const salesforceId =
      bobject?.salesforceId ||
      getTextFromLogicRole(bobject, SALESFORCE_LOGIC_ROLES.ACCOUNT_ID_FIELD as any) ||
      getTextFromLogicRole(bobject, SALESFORCE_LOGIC_ROLES.LEAD_ID_FIELD as any) ||
      getTextFromLogicRole(bobject, SALESFORCE_LOGIC_ROLES.CONTACT_ID_FIELD as any);
    const salesforceFields = parsedFields?.salesforceFields?.[sobjectType];
    if (salesforceFields?.length > 0 && salesforceId) {
      setValue(`${bobjectId}.sobjectType`, sobjectType);
      setValue(`${bobjectId}.sobjectId`, salesforceId);
      const sobjectFields = salesforceFields.map(field => field.name);
      api
        .post('/utils/service/salesforce/query', {
          query: `SELECT ${sobjectFields.join(',')} FROM ${sobjectType} WHERE Id='${salesforceId}'`,
        })
        .then(data => {
          if (data?.data) {
            Object.entries(data?.data?.[0] || {})?.forEach(([key, value]) => {
              if (key === 'attributes') return;
              setValue(`${bobjectId}.sobject.${key}`, value);
            });
          }
        });
    }
  }, []);

  return (
    <div>
      <Text size="xs" color="bloobirds">
        <Icon name={bobjectInfo.icon} color="bloobirds" /> {bobjectInfo?.name}
      </Text>
      <div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
        {parsedFields?.bloobirdsFields?.[bobjectType] && (
          <BloobirdsFieldBlock
            fields={parsedFields?.bloobirdsFields?.[bobjectType]}
            bobjectId={bobjectId}
            defaultValues={watch(bobjectId)?.bobject}
          />
        )}
        {parsedFields?.salesforceFields && (
          <SalesforceFieldBlock
            bobjectId={bobjectId}
            fields={parsedFields?.salesforceFields?.[sobjectType]}
            defaultValues={watch(bobjectId)?.sobject}
          />
        )}
      </div>
    </div>
  );
};

export const UpdateCRMFieldsModal = ({ onClose, updatableFields }) => {
  const methods = useForm();
  const { t, activityOpportunity, activityLead, activityCompany } = useStatusNoteActionContext();
  const { getParsedFields } = useUpdatableFields();
  const parsedFields = getParsedFields(updatableFields);
  const onSubmit = data => {
    if (data) {
      Promise.all(
        Object.entries(data).map(
          ([bobjectId, formData]: [BobjectId['value'], Record<string, any>]) => {
            if (!bobjectId) return;
            return api.post('/utils/service/customWizard/updateFields', {
              bobjectId,
              ...formData,
            });
          },
        ),
      )
        .then(() => {
          createToast({
            type: 'success',
            message: t('quickActionColumn.actionCard.updateCRMFields.savedSuccess'),
          });
          onClose();
        })
        .catch(err => {
          createToast({
            type: 'error',
            message: t('quickActionColumn.actionCard.updateCRMFields.savedError', {
              error: err?.response?.data?.message,
            }),
          });
        });
    }
  };

  return (
    <FormProvider {...methods}>
      <Modal open={true} onClose={onClose} width={500}>
        <ModalHeader color="white" size="small">
          <ModalTitle color="bloobirds" icon="refresh" size="small">
            <Text color="peanut" weight="bold" size="m">
              {t('quickActionColumn.actionCard.updateCRMFields.modalTitle')}
            </Text>
          </ModalTitle>
          <ModalCloseIcon color="bloobirds" size="small" onClick={onClose} />
        </ModalHeader>
        <ModalContent className={styles.crmModalWrapper}>
          <div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
            {[activityCompany, activityLead, activityOpportunity].map(bobject => {
              if (!bobject) return null;
              return (
                <BobjectFieldsToUpdate
                  key={bobject.id.value}
                  bobject={bobject}
                  parsedFields={parsedFields}
                />
              );
            })}
          </div>
          <div className={styles['update-crm-fields-ok-button']}>
            <Button onClick={methods.handleSubmit(onSubmit)} size="small" uppercase={false}>
              {t('quickActionColumn.actionCard.updateCRMFields.okAction')}
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
};
