import { IconType } from '@bloobirds-it/flamingo-ui';
import { useDataModel, useSalesforceDataModel } from '@bloobirds-it/hooks';
import {
  BobjectTypes,
  DataModelResponse,
  FieldsEntity,
  LogicRoleType,
  MainBobjectTypes,
  SalesforceDataModelResponse,
  MainBobject,
  Sobject,
} from '@bloobirds-it/types';
import { CustomObjectField } from '@bloobirds-it/wizard-modal-context';

import { getBobjectName } from '../../statusColumn/statusColumnUtils';

export type UpdatableFields = {
  objectType: BobjectTypes | Sobject;
  updatableFields: string[];
  source: 'Bloobirds' | 'Salesforce' | 'Dynamics';
}[];

export function parseSalesforceFields(
  salesforceStatusFieldsRequirements: any,
  salesforceDataModelFields: SalesforceDataModelResponse['types'],
) {
  return Object.entries(salesforceStatusFieldsRequirements).reduce(
    (acc, [sobject, fields]: [Sobject, Array<string>]) => {
      const sobjectFields = salesforceDataModelFields?.[sobject?.toLowerCase()]?.fields;
      acc[sobject] = fields.map(field => {
        const matchingField = sobjectFields?.find(({ name }) => name === field);
        if (!matchingField) return null;
        return {
          ...matchingField,
        };
      });
      return acc;
    },
    {} as Record<Sobject, CustomObjectField[]>,
  );
}

const parseBloobirdsFields = (bloobirdsFields, dataModel: DataModelResponse) => {
  return Object.entries(bloobirdsFields).reduce(
    (acc, [bobjectType, fields]: [MainBobjectTypes, Array<LogicRoleType<MainBobjectTypes>>]) => {
      if (!fields || !bobjectType) return acc;
      return {
        ...acc,
        [bobjectType]: fields.map(field => {
          const matchingField = dataModel.findFieldByLogicRole(field);
          if (!matchingField) return null;
          return matchingField;
        }),
      };
    },
    {} as Record<MainBobjectTypes, FieldsEntity[]>,
  );
};

export function getBobjectInfo(bobject: MainBobject): { name: string; icon: IconType } {
  const bobjectType = bobject?.id.typeName;
  if (!bobjectType) return { name: '', icon: 'questionCircle' };
  const icon =
    bobjectType === 'Company' ? 'company' : bobjectType === 'Lead' ? 'person' : 'fileOpportunity';
  return {
    name: getBobjectName(bobject),
    icon,
  };
}

export const parseFields = (
  updateableFields: UpdatableFields,
  bobjectType: BobjectTypes,
  sobjectType: Sobject,
): Record<'bloobirdsFields' | 'salesforceFields', Array<any>> => {
  if (!updateableFields) return;
  return updateableFields.reduce(
    (acc, curr) => {
      if (curr.source === 'Bloobirds' && curr.objectType === bobjectType) {
        return { ...acc, bloobirdsFields: [...acc.bloobirdsFields, curr] };
      } else if (curr.source === 'Salesforce' && curr.objectType === sobjectType) {
        return { ...acc, salesforceFields: [...acc.salesforceFields, curr] };
      }
    },
    { bloobirdsFields: [], salesforceFields: [] },
  );
};

interface ParsedFields {
  bloobirdsFields: Record<MainBobjectTypes, FieldsEntity[]>;
  salesforceFields: Record<Sobject, CustomObjectField[]>;
}

export const useUpdatableFields = () => {
  const salesforceDataModel = useSalesforceDataModel();
  const dataModel = useDataModel();

  const getParsedFields = (updatableFields: UpdatableFields): ParsedFields => {
    const parsedFieldsBySource = updatableFields.reduce(
      (acc, curr) => {
        if (curr.source === 'Bloobirds') {
          return {
            ...acc,
            bloobirdsFields: { ...acc.bloobirdsFields, [curr.objectType]: curr.updatableFields },
          };
        } else if (curr.source === 'Salesforce') {
          return {
            ...acc,
            salesforceFields: { ...acc.salesforceFields, [curr.objectType]: curr.updatableFields },
          };
        }
      },
      { bloobirdsFields: [], salesforceFields: [] },
    );

    return {
      bloobirdsFields: parseBloobirdsFields(parsedFieldsBySource.bloobirdsFields, dataModel),
      salesforceFields: parseSalesforceFields(
        parsedFieldsBySource.salesforceFields,
        salesforceDataModel?.types,
      ),
    };
  };
  return { getParsedFields };
};
