// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inbound-module__root___QeG6X {\n  height: calc(100vh - 48px);\n  width: 100%;\n}\n\n.inbound-module__header_root___6gMqP {\n  padding: 40px 25px 40px 0;\n  padding-bottom: 0;\n}\n\n.inbound-module__header_titlesContainer___2SLNM {\n  margin: 0 0 24px 25px;\n}\n\n.inbound-module__header_tabsContainer___iwshm > div > div > div {\n  margin-left: 25px;\n}\n\n.inbound-module__header_title___qR2\\+9 {\n  margin: 0 0 8px 0;\n}\n\n.inbound-module__header_subtitle___w-Jfd {\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/app/main/board/task/taskBoard/workspace/inbound/inbound.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,SAAS;AACX","sourcesContent":[".root {\n  height: calc(100vh - 48px);\n  width: 100%;\n}\n\n.header_root {\n  padding: 40px 25px 40px 0;\n  padding-bottom: 0;\n}\n\n.header_titlesContainer {\n  margin: 0 0 24px 25px;\n}\n\n.header_tabsContainer > div > div > div {\n  margin-left: 25px;\n}\n\n.header_title {\n  margin: 0 0 8px 0;\n}\n\n.header_subtitle {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "inbound-module__root___QeG6X",
	"header_root": "inbound-module__header_root___6gMqP",
	"header_titlesContainer": "inbound-module__header_titlesContainer___2SLNM",
	"header_tabsContainer": "inbound-module__header_tabsContainer___iwshm",
	"header_title": "inbound-module__header_title___qR2+9",
	"header_subtitle": "inbound-module__header_subtitle___w-Jfd"
};
export default ___CSS_LOADER_EXPORT___;
