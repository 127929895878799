import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Icon,
  Input,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Text,
  Button,
  ModalFooter,
  useToasts,
  Checkbox,
} from '@bloobirds-it/flamingo-ui';
import { useEmailConnections } from '@bloobirds-it/hooks';
import { EmailConnection } from '@bloobirds-it/types';

import { isEmail } from '../../misc/utils';
import styles from './addAliasModal.module.css';

export const AddAliasModal = ({
  nylasAccount,
  onClose,
}: {
  nylasAccount: EmailConnection;
  onClose: () => void;
}) => {
  const { addNewAlias } = useEmailConnections();
  const [emailAlias, setEmailAlias] = useState<string>();
  const [addAsDefault, setAddAsDefault] = useState<boolean>();
  const [error, setError] = useState<boolean>(false);
  const { createToast } = useToasts();
  const { t } = useTranslation('translation', { keyPrefix: 'userSettingsWebapp.addAliasModal' });

  const handleAddAlias = () => {
    addNewAlias({
      emailAlias: emailAlias,
      nylasAccountId: nylasAccount?.id,
      default: addAsDefault,
    })
      .then(() => {
        onClose();
        createToast({ message: t('toasts.success'), type: 'success' });
      })
      .catch(() => {
        createToast({
          message: t('toasts.error'),
          type: 'error',
        });
      });
  };

  useEffect(() => {
    if (emailAlias?.length > 0 && !isEmail(emailAlias)) {
      setError(true);
    } else {
      setError(false);
    }
  }, [emailAlias]);

  return (
    <Modal open onClose={onClose} width={640}>
      <ModalHeader size="small" color="white">
        <ModalTitle className={styles.modalTitle} size="small">
          <Icon name="mail" size={22} />
          <Text size="l" weight="bold" htmlTag="span">
            {t('title')}
          </Text>
        </ModalTitle>
        <ModalCloseIcon onClick={onClose} size="small" />
      </ModalHeader>
      <ModalContent className={styles.modalContent}>
        <Text size="m" className={styles.text}>
          {t('contentHeader')}
          <Text size="m" weight="bold" htmlTag="span">
            {nylasAccount?.email}
          </Text>
        </Text>
        <Text size="m" className={styles.text}>
          <Trans
            i18nKey="userSettingsWebapp.addAliasModal.content"
            components={[
              <a
                key="0"
                href="https://support.google.com/a/answer/33327?product_name=UnuFlow&hl=en&visit_id=637788702669262869-2616677116&rd=1&src=supportwidget0&hl=en"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              ></a>,
              <a
                key="1"
                href="https://support.microsoft.com/en-us/office/add-or-remove-an-email-alias-in-outlook-com-459b1989-356d-40fa-a689-8f285b13f1f2"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              ></a>,
            ]}
          />
        </Text>
        <Input
          name="alias"
          placeholder="Email alias"
          width="100%"
          error={error && t('validation')}
          onChange={setEmailAlias}
        />
        <Text size="xs" color="softPeanut" className={styles.text}>
          {t('footer')}
        </Text>
      </ModalContent>
      <ModalFooter>
        <Button variant="clear" onClick={onClose} uppercase={false}>
          {t('cancel')}
        </Button>
        <div className={styles.footerRight}>
          <Checkbox onClick={check => setAddAsDefault(check)} checked={addAsDefault} size="small">
            {t('addAsDefault')}
          </Checkbox>
          <Button disabled={error || !emailAlias} onClick={handleAddAlias} uppercase={false}>
            {t('confirm')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
