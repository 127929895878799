// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".twilioConfigModal-module___input__container___g\\+oGZ {\n  margin-top: 10px;\n  margin-bottom: 25px;\n}\n\n.twilioConfigModal-module___link__button___DeHss {\n  cursor: pointer;\n  color: var(--bloobirds);\n}\n\n.twilioConfigModal-module___howTo__text___nFZit {\n  margin-bottom: 20px;\n}\n\n.twilioConfigModal-module___form__container___o3gjE {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/dialersPage/twilioIntegration/twilioConfigModal/twilioConfigModal.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":["._input__container {\n  margin-top: 10px;\n  margin-bottom: 25px;\n}\n\n._link__button {\n  cursor: pointer;\n  color: var(--bloobirds);\n}\n\n._howTo__text {\n  margin-bottom: 20px;\n}\n\n._form__container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_input__container": "twilioConfigModal-module___input__container___g+oGZ",
	"_link__button": "twilioConfigModal-module___link__button___DeHss",
	"_howTo__text": "twilioConfigModal-module___howTo__text___nFZit",
	"_form__container": "twilioConfigModal-module___form__container___o3gjE"
};
export default ___CSS_LOADER_EXPORT___;
