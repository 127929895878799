export enum LEAD_STATUS {
  MEETING = 'MEETING',
  CONTACT = 'CONTACT',
  DISCARDED = 'DISCARDED',
  NURTURING = 'NURTURING',
  ON_HOLD = 'ON_HOLD',
}

export enum LEAD_STATUS_LOGIC_ROLE {
  NEW = 'LEAD__STATUS__NEW',
  BACKLOG = 'LEAD__STATUS__BACKLOG',
  DELIVERED = 'LEAD__STATUS__DELIVERED',
  ON_PROSPECTION = 'LEAD__STATUS__ON_PROSPECTION',
  CONTACTED = 'LEAD__STATUS__CONTACTED',
  ENGAGED = 'LEAD__STATUS__ENGAGED',
  MEETING = 'LEAD__STATUS__MEETING',
  CONTACT = 'LEAD__STATUS__CONTACT',
  NURTURING = 'LEAD__STATUS__NURTURING',
  ON_HOLD = 'LEAD__STATUS__ON_HOLD',
  DISCARDED = 'LEAD__STATUS__DISCARDED',
}

export enum LEAD_SALES_STATUS_VALUES_LOGIC_ROLE {
  ACTIVE = 'LEAD__SALES_STATUS__ACTIVE',
  ON_HOLD = 'LEAD__SALES_STATUS__ON_HOLD',
  NURTURING = 'LEAD__SALES_STATUS__NURTURING',
  DISCARDED = 'LEAD__SALES_STATUS__DISCARDED',
  CLIENT = 'LEAD__SALES_STATUS__CLIENT',
}

export enum LEAD_STAGE_LOGIC_ROLE {
  PROSPECT = 'LEAD__STAGE__PROSPECTING',
  SALES = 'LEAD__STAGE__SALES',
}

export enum LEAD_FIELDS_LOGIC_ROLE {
  ASSIGNED_DATE = 'LEAD__LAST_ASSIGNED_DATE',
  ASSIGNED_TO = 'LEAD__ASSIGNED_TO',
  ATTEMPTS_COUNT = 'LEAD__ATTEMPTS_COUNT',
  ATTEMPTS_LAST_DAY = 'LEAD__ATTEMPTS_LAST_DAY',
  AUTHOR = 'LEAD__AUTHOR',
  BUYING_ROLE = 'LEAD__BUYING_ROLE',
  CADENCE_DATA = 'LEAD__CADENCE_DATA',
  CADENCE_STOPPED = 'LEAD__CADENCE_STOPPED',
  CADENCE = 'LEAD__CADENCE',
  COMPANY = 'LEAD__COMPANY',
  CREATION_DATETIME = 'LEAD__CREATION_DATETIME',
  DISCARDED_REASONS = 'LEAD__DISCARDED_REASONS',
  EMAIL = 'LEAD__EMAIL',
  FULL_NAME = 'LEAD__FULL_NAME',
  //FUNCTION = '',
  HIGH_PRIORITY = 'LEAD__HIGH_PRIORITY',
  ICP = 'LEAD__ICP',
  IS_MQL_OR_SAL_REQUIRED = 'LEAD__IS_MQL_OR_SAL_REQUIRED',
  JOB_TITLE = 'LEAD__LINKEDIN_JOB_TITLE',
  LEAD_OPPORTUNITIES = 'LEAD__OPPORTUNITIES',
  LINKEDIN_JOB_TITLE = 'LEAD__LINKEDIN_JOB_TITLE',
  LINKEDIN_URL = 'LEAD__LINKEDIN_URL',
  LEAD__LINKEDIN_ID = 'LEAD__LINKEDIN_ID',
  SALES_NAV_URL = 'LEAD__SALES_NAVIGATOR_URL',
  MR_RATING = 'LEAD__MR_RATING',
  NAME = 'LEAD__NAME',
  NURTURING_REASONS = 'LEAD__NURTURING_REASONS',
  ON_HOLD_REASONS = 'LEAD__ON_HOLD_REASONS',
  OPPORTUNITY = 'LEAD__OPPORTUNITY',
  OPPORTUNITIES = 'LEAD__OPPORTUNITIES',
  OPT_OUT = 'LEAD__OPT_OUT',
  PHONE = 'LEAD__PHONE',
  POSITION_ROLE = 'LEAD__MANAGEMENT_LEVEL',
  SALES_DISCARDED_REASONS = 'LEAD__SALES_DISCARDED_REASONS',
  SALES_NURTURING_REASONS = 'LEAD__SALES_NURTURING_REASONS',
  SALES_ON_HOLD_REASONS = 'LEAD__SALES_ON_HOLD_REASONS',
  SALES_STATUS = 'LEAD__SALES_STATUS',
  SOURCE = 'LEAD__SOURCE',
  STAGE = 'LEAD__STAGE',
  START_CADENCE = 'LEAD__START_CADENCE',
  STATUS = 'LEAD__STATUS',
  TIME_ZONE = 'LEAD__TIME_ZONE',
  TOUCHES_COUNT = 'LEAD__TOUCHES_COUNT',
  TOUCHES_LAST_DAY = 'LEAD__TOUCHES_LAST_DAY',
  UPDATE_DATETIME = 'LEAD__UPDATE_DATETIME',
  MQL = 'LEAD__MQL',
  SAL = 'LEAD__SAL',
  IS_INACTIVE = 'LEAD__IS_INACTIVE',
  MAIN_NOTE = 'LEAD__MAIN_NOTE',
  SURNAME = 'LEAD__SURNAME',
  CADENCE_ENDED = 'LEAD__CADENCE_ENDED',
  SALESFORCE_CONTACT_ID = 'SALESFORCE_CONTACT_ID',
  SALESFORCE_LEAD_ID = 'SALESFORCE_LEAD_ID',
  SALESFORCE_STATUS = 'SALESFORCE_LEAD_STATUS',
}

export enum LEAD_IS_MQL_OR_SAL_LOGIC_ROLE {
  YES = 'LEAD__IS_MQL_OR_SAL_REQUIRED__YES',
  NO = 'LEAD__IS_MQL_OR_SAL_REQUIRED__NO',
}

export enum LEAD_HIGH_PRIORITY_LOGIC_ROLE {
  YES = 'LEAD__HIGH_PRIORITY_YES',
  NO = 'LEAD__HIGH_PRIORITY_NO',
}

export enum LEAD_OPT_OUT_LOGIC_ROLE {
  YES = 'LEAD__OPT_OUT__YES',
  NO = 'LEAD__OPT_OUT__NO',
}

export enum LEAD_CADENCE_STOPPED_LOGIC_ROLE {
  YES = 'LEAD__CADENCE_STOPPED_YES',
  NO = 'LEAD__CADENCE_STOPPED_NO',
}

export enum LEAD_SOURCE_LOGIC_ROLES {
  INBOUND = 'LEAD__SOURCE__INBOUND',
  OUTBOUND = 'LEAD__SOURCE__OUTBOUND',
}
