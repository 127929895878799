import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
//@ts-ignore eslint-disable-next-line
import RangeSlider from 'react-range-slider-input';

import { Dropdown, Select, useVisible, Text, Icon, useHover } from '@bloobirds-it/flamingo-ui';
import { useDebounce } from '@bloobirds-it/hooks';
import clsx from 'clsx';
import spacetime from 'spacetime';

import './styles.css';
import styles from './timezoneRangeFilter.module.css';

const DEFAULT_VALUE = [0, 1439];

const QuickFilters = [
  {
    i18key: 'allDay',
    value: DEFAULT_VALUE,
  },
  {
    i18key: 'labourHours',
    value: [540, 1080],
  },
  {
    i18key: 'mornings',
    value: [540, 720],
  },
  {
    i18key: 'afternoons',
    value: [720, 1080],
  },
];

type FilterValueType = {
  start: Date;
  end: Date;
};

const transformNumberToDateRange = (value: number[]) => {
  return {
    start: spacetime().goto('UTC').startOf('day').add(value?.[0], 'minutes').toNativeDate(),
    end: spacetime().goto('UTC').startOf('day').add(value?.[1], 'minutes').toNativeDate(),
  };
};

const parseValue = (value: FilterValueType) => {
  if (!value) {
    return DEFAULT_VALUE;
  }
  return [
    value.start?.getMinutes() + value.start?.getHours() * 60,
    value.end?.getMinutes() + value.end?.getHours() * 60,
  ];
};

export const TimezoneRangeFilter = ({
  value,
  onChange,
}: {
  value: FilterValueType;
  onChange: (value: FilterValueType) => void;
}) => {
  const { ref, visible, setVisible } = useVisible();
  const { t } = useTranslation('translation', { keyPrefix: 'leftBar.filters.timezoneRange' });
  const parsedDefaultValue = parseValue(value);
  const [internalValue, setInternalValue] = useState(parsedDefaultValue);
  const debouncedInternalValue = useDebounce(internalValue, 1000);
  const realValues = transformNumberToDateRange(internalValue);
  const toggleDropdownVisibility = () => setVisible(!visible);
  const isAllTime =
    internalValue?.[0] === DEFAULT_VALUE?.[0] && internalValue?.[1] === DEFAULT_VALUE?.[1];

  const parseValueToString = (value: FilterValueType) => {
    if (!value) {
      return undefined;
    }
    return t('displayValue', {
      start: spacetime(value.start)?.goto('UTC').format('{time-24}'),
      end: spacetime(value.end)?.goto('UTC')?.format('{time-24}'),
    });
  };

  useEffect(() => {
    if (!value) setInternalValue(DEFAULT_VALUE);
  }, [value]);

  useEffect(() => {
    onChange(realValues);
  }, [debouncedInternalValue]);

  return (
    <Dropdown
      ref={ref}
      width="100%"
      visible={visible}
      position="bottom-start"
      arrow={false}
      anchor={
        <div className={styles.filter}>
          <Select
            size="small"
            variant="filters"
            width="348px"
            placeholder={value && !isAllTime ? parseValueToString(value) : t('placeholder')}
            onClick={toggleDropdownVisibility}
            removePlaceholder={!!value}
            dropdownProps={{ style: { display: 'none' } }}
            adornment={<Icon name="clock" color="darkBloobirds" size={14} />}
          >
            {''}
          </Select>
        </div>
      }
    >
      <div className={styles.contentWrapper}>
        <div className={styles.header}>
          <Text size="s" color="softPeanut">
            <Trans i18nKey="leftBar.filters.timezoneRange.title" />
          </Text>
        </div>
        <div className={styles.quickFiltersWrapper}>
          {QuickFilters.map(props => (
            <QuickFilterButton
              key={props.i18key}
              setValue={setInternalValue}
              selected={
                internalValue?.[0] === props?.value[0] && internalValue?.[1] === props?.value[1]
              }
              {...props}
            />
          ))}
        </div>
        <RangeSlider
          id="bloobirds-range-slider"
          value={internalValue}
          onInput={setInternalValue}
          min={0}
          max={1440}
          step={60}
        />
        <div className={styles.infoWrapper}>
          <Text size="s" weight="bold" color="darkBloobirds" align="left">
            {t('from', { hour: spacetime(realValues?.start).goto('UTC').format('{time-24}') })}
          </Text>
          <Text size="s" weight="bold" color="darkBloobirds" align="right">
            {t('to', { hour: spacetime(realValues?.end).goto('UTC').format('{time-24}') })}
          </Text>
        </div>
      </div>
    </Dropdown>
  );
};

const QuickFilterButton = ({
  i18key,
  value,
  setValue,
  selected,
}: {
  i18key: string;
  value: number[];
  setValue: (value: number[]) => void;
  selected: boolean;
}) => {
  const [ref, isHover] = useHover();

  const { t } = useTranslation('translation', {
    keyPrefix: 'leftBar.filters.timezoneRange.quickFilters',
  });

  return (
    <div
      className={clsx(styles.quickFilter, { [styles.quickFilterSelected]: selected })}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        setValue(value);
      }}
      ref={ref}
    >
      <Text size="xs" align="center" color={isHover || selected ? 'white' : 'darkBloobirds'}>
        {t(i18key)}
      </Text>
    </div>
  );
};
