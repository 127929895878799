// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".settings-module__form___xgk\\+s {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.settings-module__input___9w9DR {\n  margin: 8px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/genericIntegrationLayout/syncSettings/settings.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".form {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.input {\n  margin: 8px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "settings-module__form___xgk+s",
	"input": "settings-module__input___9w9DR"
};
export default ___CSS_LOADER_EXPORT___;
