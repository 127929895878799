import React, { useEffect } from 'react';

import { Item, Select } from '@bloobirds-it/flamingo-ui';
import { useActiveUserSettings, useDataModel, useMessagingTemplates } from '@bloobirds-it/hooks';
import { BobjectTypes, TEMPLATE_TYPES } from '@bloobirds-it/types';

import { useWorkflow } from '../../../../../context/workflowsContext';
import { BloobirdsAction } from '../../../../../workflows.types';
import styles from '../../modules.module.css';

const SendSMSModule = ({ blockIndex, action }: { blockIndex: number; action: BloobirdsAction }) => {
  const { bobjectType } = action;
  const { settings } = useActiveUserSettings();
  const dataModel = useDataModel(
    settings && `/utils/service/datamodel/workflows/${settings?.user?.id}`,
  );

  const phoneFields =
    dataModel &&
    dataModel.findFieldsByTypeAndBobjectType(bobjectType || BobjectTypes.Lead, 'PHONE');
  const {
    updateAction,
    isMissingInfo: updateMissingInfo,
    state: { isEnabled, isLocked },
  } = useWorkflow();

  const { teamTemplates } = useMessagingTemplates({
    type: TEMPLATE_TYPES.SMS,
    name: null,
    visibility: null,
    onlyMine: false,
    segmentationValues: null,
  });

  const updateValue = (key: string, value: string) => {
    updateMissingInfo(false);
    action[key] = value;
    updateAction(blockIndex, action);
  };

  useEffect(() => {
    if (action?.title) updateMissingInfo(false);
  }, [action]);

  return (
    <>
      <div className={styles._content_wrapper}>
        <div className={styles._radio_modal_select}>
          <Select
            borderless={false}
            width="260px"
            size="small"
            value={action?.smsTemplateId ? action.smsTemplateId : ''}
            placeholder="Choose template"
            disabled={isEnabled || isLocked}
            onChange={value => updateValue('smsTemplateId', value)}
          >
            {teamTemplates?.messagingTemplates?.map(template => (
              <Item key={template?.id} value={template?.id}>
                {template?.name}
              </Item>
            ))}
          </Select>
        </div>
        <div className={styles._radio_modal_select}>
          <Select
            borderless={false}
            width="260px"
            size="small"
            value={action?.phoneFieldId ? action.phoneFieldId : ''}
            placeholder="Choose phone field"
            disabled={isEnabled || isLocked}
            onChange={value => updateValue('phoneFieldId', value)}
          >
            {phoneFields?.map(phone => (
              <Item key={phone?.id} value={phone?.id}>
                {phone?.name}
              </Item>
            ))}
          </Select>
        </div>
      </div>
    </>
  );
};

export default SendSMSModule;
