// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".colorField-module__field___faKgm {\n  height: 18px;\n  display: inline-flex;\n  margin-right: 4px;\n}\n\n.colorField-module__fieldContent___XspjC {\n  display: inline-block;\n  overflow: hidden;\n  font-size: 14px;\n  line-height: 18px;\n  margin-left: 8px;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.colorField-module__fieldCircle___92NsZ {\n  width: 12px;\n  height: 12px;\n  margin: auto;\n  border-radius: 100px;\n  background-color: var(--white);\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/filter/field/colorField.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,oBAAoB;EACpB,8BAA8B;AAChC","sourcesContent":[".field {\n  height: 18px;\n  display: inline-flex;\n  margin-right: 4px;\n}\n\n.fieldContent {\n  display: inline-block;\n  overflow: hidden;\n  font-size: 14px;\n  line-height: 18px;\n  margin-left: 8px;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.fieldCircle {\n  width: 12px;\n  height: 12px;\n  margin: auto;\n  border-radius: 100px;\n  background-color: var(--white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": "colorField-module__field___faKgm",
	"fieldContent": "colorField-module__fieldContent___XspjC",
	"fieldCircle": "colorField-module__fieldCircle___92NsZ"
};
export default ___CSS_LOADER_EXPORT___;
