import {
  BOBJECT_TYPES,
  COMPANY_FIELDS_LOGIC_ROLE,
  COMPANY_STAGE_LOGIC_ROLE,
  LEAD_FIELDS_LOGIC_ROLE,
  LEAD_STAGE_LOGIC_ROLE,
  OPPORTUNITY_FIELDS_LOGIC_ROLE,
  TASK_ACTION,
  TASK_FIELDS_LOGIC_ROLE,
} from '@bloobirds-it/types';

export const OPPORTUNITY_COLUMNS = [
  OPPORTUNITY_FIELDS_LOGIC_ROLE.AMOUNT,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.ASSIGNED_TO,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.CLOSE_DATE,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.COMPANY,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.NAME,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.STATUS__LAST_UPDATE,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.STATUS,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.UPDATE_DATETIME,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.ATTEMPTS_LAST_DAY,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.CREATION_DATETIME,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.EXTRA_INFO_ACTIVITY,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.EXTRA_INFO_ACTIVITY_DATE,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.EXTRA_INFO_TASK_DATE,
];

export const OPPORTUNITY_REFERENCED_COLUMNS = [
  COMPANY_FIELDS_LOGIC_ROLE.NAME,
  COMPANY_FIELDS_LOGIC_ROLE.STATUS,
];

export const TASK_COLUMNS = [
  TASK_FIELDS_LOGIC_ROLE.TITLE,
  TASK_FIELDS_LOGIC_ROLE.STATUS,
  TASK_FIELDS_LOGIC_ROLE.COMPANY,
  TASK_FIELDS_LOGIC_ROLE.LEAD,
  TASK_FIELDS_LOGIC_ROLE.OPPORTUNITY,
  TASK_FIELDS_LOGIC_ROLE.ASSIGNED_TO,
  TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATE,
  TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME,
  TASK_FIELDS_LOGIC_ROLE.TASK_TYPE,
  TASK_FIELDS_LOGIC_ROLE.STEP_ID,
  TASK_FIELDS_LOGIC_ROLE.CUSTOM_TASK,
  TASK_FIELDS_LOGIC_ROLE.SCHEDULED_MODE,
  TASK_FIELDS_LOGIC_ROLE.IS_AUTOMATED,
  TASK_FIELDS_LOGIC_ROLE.CADENCE,
  TASK_ACTION.CALL,
  TASK_ACTION.EMAIL,
  TASK_ACTION.LINKEDIN_MESSAGE,
  TASK_ACTION.AUTOMATED_EMAIL,
  TASK_FIELDS_LOGIC_ROLE.PRIORITY,
];
export const TASK_REFERENCED_COLUMNS = [
  COMPANY_FIELDS_LOGIC_ROLE.COUNTRY,
  COMPANY_FIELDS_LOGIC_ROLE.NAME,
  COMPANY_FIELDS_LOGIC_ROLE.STATUS,
  COMPANY_FIELDS_LOGIC_ROLE.ATTEMPTS_LAST_DAY,
  COMPANY_FIELDS_LOGIC_ROLE.NURTURING_REASONS,
  COMPANY_FIELDS_LOGIC_ROLE.SALES_NURTURING_REASONS,
  COMPANY_FIELDS_LOGIC_ROLE.STAGE,
  COMPANY_FIELDS_LOGIC_ROLE.TIME_ZONE,
  COMPANY_FIELDS_LOGIC_ROLE.CADENCE,
  LEAD_FIELDS_LOGIC_ROLE.FULL_NAME,
  LEAD_FIELDS_LOGIC_ROLE.EMAIL,
  LEAD_FIELDS_LOGIC_ROLE.STATUS,
  LEAD_FIELDS_LOGIC_ROLE.STAGE,
  LEAD_FIELDS_LOGIC_ROLE.ATTEMPTS_LAST_DAY,
  LEAD_FIELDS_LOGIC_ROLE.NURTURING_REASONS,
  LEAD_FIELDS_LOGIC_ROLE.SALES_NURTURING_REASONS,
  LEAD_FIELDS_LOGIC_ROLE.TIME_ZONE,
  LEAD_FIELDS_LOGIC_ROLE.CADENCE,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.NAME,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.STATUS,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.CADENCE,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.COMPANY,
];

export enum SALES_PAGES {
  APPOINTMENTS = 'APPOINTMENTS',
  ALL_MY_OPPORTUNITIES = 'ALL_MY_OPPORTUNITIES',
  INACTIVE = 'INACTIVE',
  COMPANY_INACTIVE = 'Inactive_COMPANY',
  LEAD_INACTIVE = 'Inactive_LEAD',
  OPPORTUNITY_INACTIVE = 'Inactive_OPPORTUNITY',
  COMPANIES_AND_LEADS = 'COMPANIES_AND_LEADS',
  COMPANY_COMPANIES_AND_LEADS = 'COMPANY_COMPANIES_AND_LEADS',
  LEAD_COMPANIES_AND_LEADS = 'LEAD_COMPANIES_AND_LEADS',
  FOLLOW_UP = 'FOLLOW_UP',
}

export const subQueryTypes = {
  [BOBJECT_TYPES.OPPORTUNITY]: {
    [TASK_FIELDS_LOGIC_ROLE.OPPORTUNITY]: ['__MATCH_FULL_ROWS__'],
  },
  [BOBJECT_TYPES.COMPANY]: {
    [TASK_FIELDS_LOGIC_ROLE.COMPANY]: {
      query: {
        [COMPANY_FIELDS_LOGIC_ROLE.STAGE]: [COMPANY_STAGE_LOGIC_ROLE.SALES],
      },
      searchMode: 'SUBQUERY__SEARCH',
    },
    [TASK_FIELDS_LOGIC_ROLE.OPPORTUNITY]: ['__MATCH_EMPTY_ROWS__'],
    [TASK_FIELDS_LOGIC_ROLE.LEAD]: ['__MATCH_EMPTY_ROWS__'],
  },
  [BOBJECT_TYPES.LEAD]: {
    [TASK_FIELDS_LOGIC_ROLE.LEAD]: {
      query: {
        LEAD__STAGE: [LEAD_STAGE_LOGIC_ROLE.SALES],
      },
      searchMode: 'SUBQUERY__SEARCH',
    },
  },
};

export const DEFAULT_SALES_SUBQUERY = [
  {
    [TASK_FIELDS_LOGIC_ROLE.OPPORTUNITY]: ['__MATCH_FULL_ROWS__'],
  },
  {
    [TASK_FIELDS_LOGIC_ROLE.OPPORTUNITY]: ['__MATCH_EMPTY_ROWS__'],
    [TASK_FIELDS_LOGIC_ROLE.COMPANY]: {
      query: {
        [COMPANY_FIELDS_LOGIC_ROLE.STAGE]: [COMPANY_STAGE_LOGIC_ROLE.SALES],
      },
      searchMode: 'SUBQUERY__SEARCH',
    },
    [TASK_FIELDS_LOGIC_ROLE.LEAD]: ['__MATCH_EMPTY_ROWS__'],
  },
  {
    [TASK_FIELDS_LOGIC_ROLE.LEAD]: {
      query: {
        LEAD__STAGE: [LEAD_STAGE_LOGIC_ROLE.SALES],
      },
      searchMode: 'SUBQUERY__SEARCH',
    },
  },
];
