// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pill-module__pill___3AQUv {\n  display: inline-block;\n  padding: 4px 16px;\n  text-align: center;\n  font-size: 13px;\n  border-radius: 4px;\n  margin-right: 4px;\n  border: 2px solid;\n  text-transform: uppercase;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/filter/field/pill.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,yBAAyB;AAC3B","sourcesContent":[".pill {\n  display: inline-block;\n  padding: 4px 16px;\n  text-align: center;\n  font-size: 13px;\n  border-radius: 4px;\n  margin-right: 4px;\n  border: 2px solid;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pill": "pill-module__pill___3AQUv"
};
export default ___CSS_LOADER_EXPORT___;
