import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Text } from '@bloobirds-it/flamingo-ui';

import { useFlagEmoji } from '../../hooks/useFlagEmoji';
import { usePaginatedPhoneEntity } from '../../hooks/usePaginatedPhoneEntity';
import styles from '../../styles/phoneNumberPage.module.css';

export const SettingsHeader = () => {
  const { setShowPhoneSettings, showPhoneSettings } = usePaginatedPhoneEntity();
  const phone = showPhoneSettings.phone;
  const status = phone.status;
  const { t } = useTranslation();
  const { flag } = useFlagEmoji(phone.countryCode);

  const statusConfig = status
    ? {
        dotClass: styles.greenDot,
        text: t('common.enabled'),
      }
    : {
        dotClass: styles.redDot,
        text: t('common.disabled'),
      };

  return (
    <div>
      <Button
        color="bloobirds"
        uppercase={false}
        iconLeft="arrowLeft"
        onClick={() => setShowPhoneSettings({ phone: phone, show: false })}
        variant="clear"
      >
        {t('accountSettings.phoneNumbers.phoneSettings.backButton')}
      </Button>
      <div className={styles.generalInfoSettings}>
        <Text size="xxxl" color="peanut" weight="heavy">
          {flag}
        </Text>
        <div style={{ gap: '8px' }}>
          <div className={styles.nameAndStatus}>
            <Text size="l" color="peanut" weight="heavy">
              {phone.name
                ? phone.name
                : phone.location + ' ' + t('userSettingsWebapp.disconnectModal.phoneNumber')}
            </Text>
            <div className={styles.statusCard}>
              <div className={statusConfig.dotClass}></div>
              <Text size="xs" color="peanut" weight="medium">
                {statusConfig.text}
              </Text>
            </div>
          </div>
          <Text size="m" color="peanut" weight="regular">
            {phone.phoneNumber}
          </Text>
        </div>
      </div>
    </div>
  );
};
