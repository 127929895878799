// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".taskBoard-module__taskWorkspace___0Oz9V {\n  min-height: calc(100vh - 48px);\n  background-color: #f0f4f6;\n  height: calc(100vh - 48px);\n  flex-grow: 1;\n  overflow-y: scroll;\n}\n\n.taskBoard-module__taskWorkspaceAssign___v8Lxf {\n  overflow-y: unset;\n}\n\n.taskBoard-module__taskWorkspaceMargins___2SCA1 {\n  padding: 40px 25px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/app/main/board/task/taskBoard/taskBoard.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,0BAA0B;EAC1B,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".taskWorkspace {\n  min-height: calc(100vh - 48px);\n  background-color: #f0f4f6;\n  height: calc(100vh - 48px);\n  flex-grow: 1;\n  overflow-y: scroll;\n}\n\n.taskWorkspaceAssign {\n  overflow-y: unset;\n}\n\n.taskWorkspaceMargins {\n  padding: 40px 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"taskWorkspace": "taskBoard-module__taskWorkspace___0Oz9V",
	"taskWorkspaceAssign": "taskBoard-module__taskWorkspaceAssign___v8Lxf",
	"taskWorkspaceMargins": "taskBoard-module__taskWorkspaceMargins___2SCA1"
};
export default ___CSS_LOADER_EXPORT___;
