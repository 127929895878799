import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Spinner,
  useToasts,
  Text,
} from '@bloobirds-it/flamingo-ui';
import { CopilotInsightVersion } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import { mutate } from 'swr';

import styles from '../../playgroundPages.module.css';

interface IDeleteConfirmationModalProps {
  version: CopilotInsightVersion;
  onClose: () => void;
}
export const DeleteConfirmationModal = ({ version, onClose }: IDeleteConfirmationModalProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.deleteVersionModal' });
  const { t: baseT } = useTranslation();
  const { createToast } = useToasts();
  const [submitting, setSubmitting] = useState(false);

  const handleDelete = () => {
    try {
      setSubmitting(true);
      api.delete(`/utils/service/copilot-insights/version/${version.id}`).then(() => {
        createToast({
          message: t('successDelete'),
          type: 'success',
        });
        mutate(`/api/insight/version/${version.insightId}`);
        onClose();
      });
    } catch (error) {
      createToast({
        message: t('errorDelete'),
        type: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <Modal open onClose={!submitting ? onClose : null} width={600}>
      <ModalHeader color="veryLightBloobirds" size="medium">
        <ModalTitle color="softBloobirds" icon="trashFull" size="small">
          <Text size="l" weight="bold">
            {t('title')}
          </Text>
        </ModalTitle>
        <ModalCloseIcon color="bloobirds" size="small" onClick={onClose} />
      </ModalHeader>
      <ModalContent className={styles.deleteVersionModalContent}>
        <Text size="m">
          <Trans i18nKey="ai.deleteVersionModal.message.intro" />
        </Text>
        <Text size="m">
          <Trans i18nKey="ai.deleteVersionModal.message.description" />:
        </Text>
        {/* @ts-ignore */}
        {t('message.effects', { returnObjects: true })['map']((effect: string, index: number) => (
          <li key={index}>
            <Trans i18nKey={`ai.deleteVersionModal.message.effects.${index}`}>{effect}</Trans>
          </li>
        ))}
        <Text size="m">
          <Trans i18nKey="ai.deleteVersionModal.message.confirmation" />
        </Text>
      </ModalContent>
      <ModalFooter>
        <Button variant="clear" color="bloobirds" onClick={onClose}>
          {baseT('common.cancel')}
        </Button>
        <Button onClick={handleDelete} color="tomato">
          {!submitting && t('delete')}
          {submitting && <Spinner name={'loadingCircle'} />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
