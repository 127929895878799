export const headerNames = [
  {
    label: 'Name',
    size: 'medium',
  },
  {
    label: 'Role',
    size: 'small',
  },
  {
    label: 'Email',
    size: 'medium',
  },
  {
    label: 'Updates',
    size: 'small',
  },
  {
    label: 'Language',
    size: 'small',
  },
  {
    label: 'Visibility',
    size: 'medium',
  },
  {
    label: 'Permissions',
    size: 'medium',
  },
  {
    label: 'Active',
    size: 'small',
  },
];
