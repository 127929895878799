import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@bloobirds-it/flamingo-ui';

export const ConfirmDeleteTeamModal = ({
  confirmDeleteModal,
  onClose,
}: {
  confirmDeleteModal: { open: boolean; onSave?: () => void };
  onClose: () => void;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.salesTeam.teamUsersPage.teamManagementModal.confirmDeleteTeamModal',
  });

  return confirmDeleteModal?.open ? (
    <Modal open width="416px" onClose={onClose}>
      <ModalHeader size="small">
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Icon name="trashFull" color="bloobirds" size={16} />
          <Text size="s">{t('title')}</Text>
        </div>
        <IconButton name="cross" onClick={onClose} />
      </ModalHeader>
      <ModalContent>
        <div style={{ marginBottom: '16px' }}>
          <Text size="s" align="center">
            {t('message')}
          </Text>
          <Text size="s" align="center" weight="bold">
            {t('submessage')}
          </Text>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button onClick={onClose} variant="clear">
          {t('cancelButton')}
        </Button>
        <Button
          onClick={() => {
            confirmDeleteModal?.onSave?.();
            onClose();
          }}
        >
          {t('deleteButton')}
        </Button>
      </ModalFooter>
    </Modal>
  ) : null;
};
