// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".downloadConfirmationModal-module___content__container___C6CKV {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.downloadConfirmationModal-module___content__container___C6CKV > div {\n  margin-top: 20px;\n}\n\n.downloadConfirmationModal-module___bottom__text___8wwBq {\n  margin-bottom: 30px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/downloadConfirmationModal/downloadConfirmationModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["._content__container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n._content__container > div {\n  margin-top: 20px;\n}\n\n._bottom__text {\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_content__container": "downloadConfirmationModal-module___content__container___C6CKV",
	"_bottom__text": "downloadConfirmationModal-module___bottom__text___8wwBq"
};
export default ___CSS_LOADER_EXPORT___;
