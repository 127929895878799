// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".createInboundMappingsModal-module___radioButtons___T0e0g > div:first-child {\n  margin-bottom: 8px;\n}\n\n.createInboundMappingsModal-module___checkBox___\\+1ugZ {\n  box-sizing: border-box;\n  padding: 12px 0 44px 0;\n}\n.createInboundMappingsModal-module___checkBox___\\+1ugZ > div > p {\n  margin-right: 4px;\n}\n\n.createInboundMappingsModal-module___textArea___JADtT {\n  box-sizing: border-box;\n  padding: 24px 32px;\n  background: var(--lighterGray);\n  margin-bottom: 24px;\n}\n.createInboundMappingsModal-module___textArea___JADtT > ul > li {\n  margin-bottom: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/integrationLayout/syncSettingsTab/outbound/syncAccountSettings/createInboundMappingsModal/createInboundMappingsModal.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,sBAAsB;AACxB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB","sourcesContent":["._radioButtons > div:first-child {\n  margin-bottom: 8px;\n}\n\n._checkBox {\n  box-sizing: border-box;\n  padding: 12px 0 44px 0;\n}\n._checkBox > div > p {\n  margin-right: 4px;\n}\n\n._textArea {\n  box-sizing: border-box;\n  padding: 24px 32px;\n  background: var(--lighterGray);\n  margin-bottom: 24px;\n}\n._textArea > ul > li {\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_radioButtons": "createInboundMappingsModal-module___radioButtons___T0e0g",
	"_checkBox": "createInboundMappingsModal-module___checkBox___+1ugZ",
	"_textArea": "createInboundMappingsModal-module___textArea___JADtT"
};
export default ___CSS_LOADER_EXPORT___;
