import { useMemo } from 'react';

import { useActiveAccountId, useCopilotEnabled, useUserSettings } from '@bloobirds-it/hooks';
import { BobjectType, UserType } from '@bloobirds-it/types';

export const useBulkActionsPanel = (bobjectType: BobjectType) => {
  const accountId = useActiveAccountId();
  const isCopilotEnabled = useCopilotEnabled(accountId);
  const settings = useUserSettings();
  const isSupportUser = settings?.user.type === UserType.SUPPORT_USER;

  const availableActions = useMemo((): BulkActionsPanelTypes[] => {
    switch (bobjectType) {
      case 'Company':
        return ['editProperties', 'assign', 'changeStatus', 'editTargetMarket', 'resync', 'delete'];
      case 'Lead':
        return [
          'editProperties',
          'assign',
          'changeStatus',
          'editBuyerPersonas',
          'resync',
          'delete',
        ];
      case 'Opportunity':
        return ['editProperties', 'assign', 'changeStatus', 'resync', 'delete'];
      case 'Task':
      case 'Activity':
        return [
          'editProperties',
          'resync',
          isCopilotEnabled ? 'sendToPlayground' : undefined,
          isSupportUser && isCopilotEnabled ? 'relaunchInsights' : undefined,
          'delete',
        ];
    }
  }, [bobjectType]);

  return { availableActions };
};
