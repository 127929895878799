import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  ActivityBobject,
} from '@bloobirds-it/types';
import { getFieldByLogicRole } from '@bloobirds-it/utils';

import { ActivityDetails } from '../activityDetails';
import { ActivityFeed } from '../activityFeed/activityFeed';
import { useActivityFeed } from '../activityFeed/useNewActivityFeed';
import { PastActivityFilters } from './filters/pastActivityFilters';
import styles from './pastActivity.module.css';
import { NoDataPage, NoResultsPage } from './pastActivity.utils';
import { usePastActivity } from './usePastActivity';
import { usePastActivityTab } from './usePastActivityTab';

interface ActivityFeedProps {
  setSelectedActivity: (value: ActivityBobject) => void;
  setShowDetail: (value: boolean) => void;
  pastActivitiesInfo: ReturnType<typeof usePastActivity>['pastActivitiesInfo'];
  setExternalSelectedActivity: (value: ActivityBobject) => void;
  activeBobject: ActivityBobject;
  subscribeMutator: () => void;
  activityFeedData: any;
  activitiesLoading: boolean;
  fetchNextPage;
}

const ActivityFeedWrapper = React.forwardRef<HTMLDivElement, ActivityFeedProps>(
  (
    {
      setSelectedActivity,
      setShowDetail,
      pastActivitiesInfo,
      setExternalSelectedActivity,
      activeBobject,
      subscribeMutator,
      activityFeedData,
      activitiesLoading,
      fetchNextPage: newFetchNextPage,
    },
    ref,
  ) => {
    const { activities } = pastActivitiesInfo || {};
    const { t } = useTranslation('translation', { keyPrefix: 'smartEmailModal.pastActivityTab' });

    const handleActivityClick = (activity: ActivityBobject) => {
      const activityTypeLogicRole = getFieldByLogicRole(
        activity,
        ACTIVITY_FIELDS_LOGIC_ROLE.TYPE as any,
      )?.valueLogicRole;

      if (
        ![
          'ACTIVITY__TYPE__STATUS',
          'ACTIVITY__TYPE__CADENCE',
          ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CUSTOM_TASK,
        ].includes(activityTypeLogicRole)
      ) {
        setShowDetail(true);
        setExternalSelectedActivity(undefined);
        setSelectedActivity(activity);
      }
    };

    if (!activeBobject) {
      return <NoDataPage objectName={t('activities').toLowerCase()} />;
    }

    return (
      <div className={styles.past_activity_feed_container} ref={ref}>
        {activities?.length !== 0 && (
          <ActivityFeed
            activityFeedData={activityFeedData}
            activitiesLoading={activitiesLoading}
            fetchNextPage={newFetchNextPage}
            enabledArrowNavigation
            handleOnClick={activity => handleActivityClick(activity)}
            parentRef={ref as RefObject<HTMLDivElement>}
            estimateSize={45}
            // @ts-ignore
            subscribeMutator={subscribeMutator}
          />
        )}
      </div>
    );
  },
);

export const PastActivityTab = React.forwardRef<any, any>(
  (
    {
      accountId,
      dataModel,
      user,
      selectedActivity: externalSelectedActivity,
      data,
      setSelectedActivity: setExternalSelectedActivity,
      subscribeMutator,
    },
    parentRef,
  ) => {
    const userId = user?.id;
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [selectedActivity, setSelectedActivity] = useState<ActivityBobject>(
      externalSelectedActivity,
    );
    const { leadFilterOptions } = usePastActivityTab(data);
    const { pastActivitiesInfo, error, isLoading, mutate, magicFilterHandling } = usePastActivity(
      data,
    );
    const { t } = useTranslation('translation', { keyPrefix: 'smartEmailModal.pastActivityTab' });

    const ref = useRef();

    const {
      setFilters,
      activityFeedData,
      activitiesLoading,
      fetchNextPage,
      mutate: newMutate,
    } = useActivityFeed({
      activeBobject: data.activeBobject,
      subscribeMutator,
    });

    useEffect(() => {
      if (externalSelectedActivity) setShowDetail(true);
    }, [externalSelectedActivity]);

    return (
      <div className={styles.wrapper}>
        {showDetail ? (
          <ActivityDetails
            activity={selectedActivity}
            selectedActivity={externalSelectedActivity}
            userId={userId}
            callback={() => {
              setShowDetail(false);
              newMutate();
              mutate();
              // @ts-ignore
              ref.current = ref.current || parentRef.current;
            }}
            // @ts-ignore
            extended
            dataModel={dataModel}
            visibleFooter={false}
          />
        ) : (
          <div className={styles.feed}>
            <PastActivityFilters
              magicFilterHandling={magicFilterHandling}
              leadFilterOptions={leadFilterOptions}
              filters={data.filters}
              setFilters={value => {
                setFilters(value);
                data.setFilters(value);
              }}
              activeBobject={data.activeBobject}
            />
            {error ? (
              <div className={styles.no_results}>
                <NoDataPage objectName={t('activities').toLowerCase()} />
              </div>
            ) : (
              <>
                {!isLoading && pastActivitiesInfo && pastActivitiesInfo.activities?.length === 0 && (
                  <div className={styles.no_results}>
                    <NoResultsPage />
                  </div>
                )}
                <ActivityFeedWrapper
                  ref={ref}
                  activityFeedData={activityFeedData}
                  activitiesLoading={activitiesLoading}
                  fetchNextPage={fetchNextPage}
                  activeBobject={data.activeBobject}
                  pastActivitiesInfo={pastActivitiesInfo}
                  setSelectedActivity={setSelectedActivity}
                  setShowDetail={setShowDetail}
                  setExternalSelectedActivity={setExternalSelectedActivity}
                  subscribeMutator={subscribeMutator}
                />
              </>
            )}
          </div>
        )}
      </div>
    );
  },
);
