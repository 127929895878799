import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@bloobirds-it/flamingo-ui';

import { RequestedPhone } from '../../../constants/phoneNumbers.constants';
import { useRequestNumberFormContext } from '../../../context/requestedPhonesFormProvider';
import styles from '../../../styles/phoneNumberPage.module.css';
import { NavigationButtons } from '../navigationButtons';
import { NoPhonesRequested } from '../shared/noPhonesRequested';
import { PhoneCard } from './phoneCard';

export const RequestedPhones = () => {
  const { t } = useTranslation();
  const { phoneNumberList } = useRequestNumberFormContext();

  return (
    <div style={{ width: '100%' }}>
      <Text size="m" weight="heavy">
        {t('accountSettings.phoneNumbers.purchaseNewPhone.orderReview.requestedPhones')}
      </Text>
      <div className={styles.collapsableGeneralInfo}>
        {phoneNumberList.length === 0 ? (
          <NoPhonesRequested />
        ) : (
          phoneNumberList.map((phoneNumber, index) => (
            <PhoneCard key={index} phone={phoneNumber as RequestedPhone} />
          ))
        )}
      </div>
      <NavigationButtons />
    </div>
  );
};
