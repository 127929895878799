import React from 'react';
import { useTranslation } from 'react-i18next';

import { TabGroup, Tab } from '@bloobirds-it/flamingo-ui';

import { SettingsHeader } from '../components/headers/settingsHeader';
import { PhoneNumber } from '../constants/phoneNumbers.constants';
import { PhoneNumberFormProvider } from '../context/phoneNumberFormProvider';
import { AdvancedSettings } from './advancedSettings';
import { GeneralSettings } from './generalSettings';
import { TeamsAndUsers } from './teamsAndUsers';

export const PhoneSettings = ({ phone }: { phone: PhoneNumber }) => {
  const { t } = useTranslation();
  return (
    <PhoneNumberFormProvider phone={phone}>
      <SettingsHeader />
      <TabGroup>
        <Tab name={t('accountSettings.phoneNumbers.phoneSettings.tabs.generalSettings.title')}>
          <GeneralSettings />
        </Tab>
        <Tab name={t('accountSettings.phoneNumbers.phoneSettings.tabs.advancedSettings.title')}>
          <AdvancedSettings />
        </Tab>
        <Tab name={t('accountSettings.phoneNumbers.phoneSettings.tabs.teamsandusers.title')}>
          <TeamsAndUsers />
        </Tab>
      </TabGroup>
    </PhoneNumberFormProvider>
  );
};
