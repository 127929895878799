// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".taskIconDisplay-module___icons___gaACK {\n  display: flex;\n  flex-shrink: 0;\n  margin-right: 9px;\n}\n\n.taskIconDisplay-module___icons___gaACK > svg {\n  margin-right: 2px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/subhomePages/components/subhomeCards/subcomponents/taskIconDisplay.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["._icons {\n  display: flex;\n  flex-shrink: 0;\n  margin-right: 9px;\n}\n\n._icons > svg {\n  margin-right: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_icons": "taskIconDisplay-module___icons___gaACK"
};
export default ___CSS_LOADER_EXPORT___;
