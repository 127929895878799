import React from 'react';

import { CompanySelector } from '@bloobirds-it/bobjects';

import { COMPANY_FIELDS_LOGIC_ROLE } from '../../../../../../../constants/company';
import { useEntity } from '../../../../../../../hooks';
import { BobjectApi } from '../../../../../../../misc/api/bobject';
import { getValueFromLogicRole } from '../../../../../../../utils/bobjects.utils';

const AutoCompleteSelect = ({ onCompanyIdChange }) => {
  const searchCompanies = async searchValue => {
    const payload = await BobjectApi.request()
      .Company()
      .search({
        injectReferences: false,
        query: {
          COMPANY__NAME: [searchValue],
        },
        formFields: true,
        pageSize: 50,
      });

    return payload.contents.map(company => ({
      id: company.id.value,
      name: getValueFromLogicRole(company, COMPANY_FIELDS_LOGIC_ROLE.NAME),
      targetMarket: getValueFromLogicRole(company, COMPANY_FIELDS_LOGIC_ROLE.TARGET_MARKET),
      website: getValueFromLogicRole(company, COMPANY_FIELDS_LOGIC_ROLE.WEBSITE),
    }));
  };

  const getTargetMarkets = () => useEntity('targetMarkets');

  return (
    <CompanySelector
      onCompanyIdChange={onCompanyIdChange}
      searchCompanies={searchCompanies}
      getTargetMarkets={getTargetMarkets}
    />
  );
};

export default AutoCompleteSelect;
