import React from 'react';

import { Spinner, CheckItem, MultiSelect } from '@bloobirds-it/flamingo-ui';
import { TeamType } from '@bloobirds-it/types';

import styles from './selectTeamFilter.module.css';

export const SelectTeamFilter = ({
  teams,
  setSelectedTeams,
  selectedTeams,
  isLoadingTeams,
}: {
  teams: Array<TeamType>;
  setSelectedTeams: (teams: Array<TeamType>) => void;
  selectedTeams: Array<TeamType>;
  isLoadingTeams: boolean;
}) => {
  const handleSelectTeam = teamId => {
    const currentSelectedTeams = selectedTeams ?? [];
    const isTeamSelected = currentSelectedTeams.includes(teamId);

    if (isTeamSelected) {
      setSelectedTeams(currentSelectedTeams.filter(id => id !== teamId));
    } else {
      setSelectedTeams([...currentSelectedTeams, teamId]);
    }
  };

  return (
    <div className={styles.selectButton}>
      <MultiSelect
        role="menu"
        dropdownProps={{ zIndex: 20000 }}
        placeholder="All teams"
        size="small"
        value={selectedTeams}
        width={'120px'}
        autocomplete
      >
        {isLoadingTeams ? (
          <Spinner name={'loadingCircle'} size={24} />
        ) : (
          teams?.map(value => (
            <CheckItem
              key={value.id}
              value={value.id}
              label={value.name}
              checked={selectedTeams?.some(team => team.id === value.id)}
              onClick={() => handleSelectTeam(value.id)}
            >
              {value.name}
            </CheckItem>
          ))
        )}
      </MultiSelect>
    </div>
  );
};
