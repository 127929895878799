import { useAllMessagingTemplates } from '@bloobirds-it/hooks';

import { TEMPLATE_TYPES } from '../utils/templates.utils';

function useAutomationEmailTemplates() {
  const { messagingTemplates, isLoading } = useAllMessagingTemplates({
    name: null,
    segmentationValues: {},
    visibility: 'PUBLIC',
    type: TEMPLATE_TYPES.EMAIL,
    stage: 'ALL',
    page: 0,
    size: 200,
  });

  const validTemplates = messagingTemplates
    .filter(template => template.format === 'AST')
    .filter(template => template.visibility === 'PUBLIC');

  return {
    messagingTemplates: validTemplates,
    loading: isLoading,
  };
}

export default useAutomationEmailTemplates;
