export const headerNames = [
  {
    label: 'Stage name',
  },
  {
    label: 'Style',
  },
  {
    label: 'Weighted percentage',
  },
  {
    label: 'Mark as nurturing stage',
  },
  {
    label: 'Status',
  },
];
