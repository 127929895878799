// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".emptyFieldsList-module___container___sRJdc {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  margin-top: 60px;\n}\n\n.emptyFieldsList-module___empty_data__icon___pMYYb {\n  height: 80px;\n  width: 80px;\n  margin-bottom: 32px;\n}\n\n.emptyFieldsList-module___empty_data__icon___pMYYb > path {\n  fill: var(--verySoftPeanut);\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/viewsPage/viewTabTemplate/emptyFieldsList/emptyFieldsList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":["._container {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  margin-top: 60px;\n}\n\n._empty_data__icon {\n  height: 80px;\n  width: 80px;\n  margin-bottom: 32px;\n}\n\n._empty_data__icon > path {\n  fill: var(--verySoftPeanut);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "emptyFieldsList-module___container___sRJdc",
	"_empty_data__icon": "emptyFieldsList-module___empty_data__icon___pMYYb"
};
export default ___CSS_LOADER_EXPORT___;
