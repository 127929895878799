// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".crmStatusCard-module___status_value___KyPX8 {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.crmStatusCard-module___color__picker___-g2u5 > div > div {\n  margin: 0;\n}\n\n.crmStatusCard-module___unassigned_stage_row___rSDE2 {\n  background: var(--lightPeanut);\n}\n\n.crmStatusCard-module___circularBadge___3DYUH {\n  background: var(--verySoftBanana) !important;\n}\n\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/crmStatus/crmStatusCard/crmStatusCard.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,4CAA4C;AAC9C","sourcesContent":["._status_value {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n._color__picker > div > div {\n  margin: 0;\n}\n\n._unassigned_stage_row {\n  background: var(--lightPeanut);\n}\n\n._circularBadge {\n  background: var(--verySoftBanana) !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_status_value": "crmStatusCard-module___status_value___KyPX8",
	"_color__picker": "crmStatusCard-module___color__picker___-g2u5",
	"_unassigned_stage_row": "crmStatusCard-module___unassigned_stage_row___rSDE2",
	"_circularBadge": "crmStatusCard-module___circularBadge___3DYUH"
};
export default ___CSS_LOADER_EXPORT___;
