// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer-module___container___gajh5 {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  bottom: 0;\n  padding: 24px;\n  background: linear-gradient(90deg, transparent 4px, var(--white) 0);\n  z-index: 4;\n}\n\n.footer-module___container___gajh5::before {\n  content: '';\n  position: absolute;\n  width: 100%;\n  bottom: 87px;\n  left: 4px;\n  right: 0;\n  height: 1px;\n  background-color: var(--veryLightBloobirds);\n  z-index: 1;\n}\n\n.footer-module__justifyCenter___aOpa\\+ {\n  justify-content: center !important;\n}\n\n.footer-module__editBtn___d-Z90,\n.footer-module__contactBtn___IZ928 {\n  width: 216px;\n  height: 40px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectDetailsModal/footer/footer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;EACT,aAAa;EACb,mEAAmE;EACnE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,SAAS;EACT,QAAQ;EACR,WAAW;EACX,2CAA2C;EAC3C,UAAU;AACZ;;AAEA;EACE,kCAAkC;AACpC;;AAEA;;EAEE,YAAY;EACZ,YAAY;AACd","sourcesContent":["._container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  bottom: 0;\n  padding: 24px;\n  background: linear-gradient(90deg, transparent 4px, var(--white) 0);\n  z-index: 4;\n}\n\n._container::before {\n  content: '';\n  position: absolute;\n  width: 100%;\n  bottom: 87px;\n  left: 4px;\n  right: 0;\n  height: 1px;\n  background-color: var(--veryLightBloobirds);\n  z-index: 1;\n}\n\n.justifyCenter {\n  justify-content: center !important;\n}\n\n.editBtn,\n.contactBtn {\n  width: 216px;\n  height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "footer-module___container___gajh5",
	"justifyCenter": "footer-module__justifyCenter___aOpa+",
	"editBtn": "footer-module__editBtn___d-Z90",
	"contactBtn": "footer-module__contactBtn___IZ928"
};
export default ___CSS_LOADER_EXPORT___;
