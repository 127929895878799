// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".searchBarButton-module___searchButton___T4P94 {\n  width: 200px;\n  margin-right: 24px;\n  border-width: 1px;\n  border-color: var(--lightPeanut);\n  background: transparent;\n}\n\n.searchBarButton-module___shortVersion___wiwoM {\n  width: 90px;\n  margin-right: 8px;\n}\n\n.searchBarButton-module___searchButtonText___GrE5z {\n  width: 80%;\n  border: none;\n  background: none;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/header/headerActions/searchBarButton/searchBarButton.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,gCAAgC;EAChC,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":["._searchButton {\n  width: 200px;\n  margin-right: 24px;\n  border-width: 1px;\n  border-color: var(--lightPeanut);\n  background: transparent;\n}\n\n._shortVersion {\n  width: 90px;\n  margin-right: 8px;\n}\n\n._searchButtonText {\n  width: 80%;\n  border: none;\n  background: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_searchButton": "searchBarButton-module___searchButton___T4P94",
	"_shortVersion": "searchBarButton-module___shortVersion___wiwoM",
	"_searchButtonText": "searchBarButton-module___searchButtonText___GrE5z"
};
export default ___CSS_LOADER_EXPORT___;
