import { extractText } from './messages/scrapping';
import { LeadProfileScrapper } from './profileScrapper';

export class SalesNavigatorProfileScrapper implements LeadProfileScrapper {
  getRootElement() {
    return (
      document.querySelector('.profile-topcard') || document.querySelector('#profile-card-section')
    );
  }

  getCurrentPositionRootElement() {
    return document.querySelector('main#content-main');
  }

  getCompanyName(): string | null {
    const oldRoot = this.getRootElement();
    const newRoot = this.getCurrentPositionRootElement();
    const oldVersionName = extractText(
      oldRoot?.querySelector('.profile-topcard__summary-position > span > a'),
    );
    if (oldVersionName && oldVersionName !== '') {
      return oldVersionName;
    }
    const experienceElement = document.querySelector('div#experience-section');
    const firstLiExperienceItem = experienceElement?.querySelector('li[id*="_experience-entry_"]');
    const newVersionName = extractText(
      firstLiExperienceItem?.querySelector('h2[data-anonymize*="company-name"]'),
    );

    return newVersionName !== ''
      ? newVersionName
      : extractText(newRoot?.querySelector('*[class^="_current-role-item"] > a'));
  }

  getFullName(): string | null {
    const root = this.getRootElement();
    const oldVersionName = extractText(root?.querySelector('.profile-topcard-person-entity__name'));
    return oldVersionName !== ''
      ? oldVersionName
      : extractText(root?.querySelector('h1[class^="_headingText_"]'));
  }

  getJobTitle(): string | null {
    const element = document.querySelector("[data-anonymize='headline']");
    return extractText(element).replace(/ (at|en) (.*)$/i, '');
  }

  getLinkedinId(): string | null {
    return null;
  }
}
