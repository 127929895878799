import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isTranscriptActivity } from '@bloobirds-it/copilot';
import { Spinner, TabGroup } from '@bloobirds-it/flamingo-ui';
import { useMediaQuery } from '@bloobirds-it/hooks';
import { ACTIVITY_FIELDS_LOGIC_ROLE, Bobject } from '@bloobirds-it/types';
import { getFieldByLogicRole } from '@bloobirds-it/utils';
import clsx from 'clsx';
import Player from 'video.js/dist/types/player';

import styles from './aiAnalysisPage.module.css';
import LeftSideAiAnalysis from './leftSideAiAnalysis';
import { DetailsAiAnalysis } from './leftSideAiAnalysis/detailsAiAnalysis/detailsAiAnalysis';
import InfoAiAnalysis from './leftSideAiAnalysis/infoAiAnalysis';
import MediaAiAnalysis from './leftSideAiAnalysis/mediaAiAnalysis';
import RightSideAiAnalysis from './rightSideAiAnalysis';
import ActivityFeed from './rightSideAiAnalysis/activityFeed/activityFeed';
import Insights from './rightSideAiAnalysis/insights/insights';
import Note from './rightSideAiAnalysis/note/note';
import { Summary } from './rightSideAiAnalysis/summary/summary';
import Tab from './rightSideAiAnalysis/tabAiAnalysis';
import Transcript from './rightSideAiAnalysis/transcript/transcript';
import { SfdcRecord, Source } from './useActivityAnalysis';
import { updateHeightRightSide } from './utiils';

export interface InterventionDuration {
  start: number;
  duration: number;
}

export type InterventionMap = Record<string, InterventionDuration[]>;

const AiAnalysisPage = ({
  activity,
  source,
  sfdcRecord,
  isLoading,
}: {
  activity: Bobject;
  source: Source;
  sfdcRecord: SfdcRecord;
  isLoading: boolean;
}) => {
  const { isSmallDesktop, isMediumDesktop } = useMediaQuery();
  const { t } = useTranslation();
  const [player, setPlayer] = useState<Player | null>(null);
  const activityType = getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE)
    .valueLogicRole;
  const isTranscriptAct = isTranscriptActivity(activityType);

  if (!isLoading && !source) {
    updateHeightRightSide();
  }

  return (
    <div
      className={clsx(styles.container, {
        [styles.container_small_desktop]: isSmallDesktop || isMediumDesktop,
      })}
    >
      <div className={styles.body}>
        <LeftSideAiAnalysis>
          <InfoAiAnalysis activity={activity} isLoading={false} activityType={activityType} />
          <DetailsAiAnalysis
            activity={activity}
            isLoading={isLoading}
            source={source}
            sfdcRecord={sfdcRecord}
            player={player}
            setPlayer={setPlayer}
            activityType={activityType}
          />
        </LeftSideAiAnalysis>
        <RightSideAiAnalysis>
          <TabGroup>
            <Tab active name={t('ai.aiAnalysis.summary')} icon="snippet">
              <Summary />
            </Tab>
            {isTranscriptAct && (
              <Tab name={t('ai.aiAnalysis.transcript')} icon="alignCenter">
                <Transcript
                  onClick={time => player?.currentTime(time)}
                  activityType={activityType}
                />
              </Tab>
            )}
            <Tab name={t('ai.aiAnalysis.insights')} icon="magic">
              <Insights activity={activity} />
            </Tab>
            <Tab name={t('ai.aiAnalysis.activities')} icon="activity">
              <ActivityFeed activity={activity} />
            </Tab>
            {isTranscriptAct && (
              <Tab name={t('ai.aiAnalysis.note')} icon="noteAction">
                <Note activity={activity} />
              </Tab>
            )}
          </TabGroup>
        </RightSideAiAnalysis>
      </div>
    </div>
  );
};

export default AiAnalysisPage;
