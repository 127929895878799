// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".messagingBattlecardsFilterSwitch-module__container___sYR2x {\n  display: flex;\n}\n\n.messagingBattlecardsFilterSwitch-module__text___R5qM9 {\n  margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/messagingTemplates/messagingBattlecardsFilterSwitch/messagingBattlecardsFilterSwitch.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n}\n\n.text {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "messagingBattlecardsFilterSwitch-module__container___sYR2x",
	"text": "messagingBattlecardsFilterSwitch-module__text___R5qM9"
};
export default ___CSS_LOADER_EXPORT___;
