// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".emailIntegrationPage-module___email_buttons__container___3Qmig {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    margin: 16px 0;\n}\n\n.emailIntegrationPage-module___email_buttons__container___3Qmig > div {\n    margin-left: 8px;\n}\n\n.emailIntegrationPage-module___email_buttons__container___3Qmig > span {\n    margin-right: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/emailIntegrationPage/emailIntegrationPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["._email_buttons__container {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    margin: 16px 0;\n}\n\n._email_buttons__container > div {\n    margin-left: 8px;\n}\n\n._email_buttons__container > span {\n    margin-right: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_email_buttons__container": "emailIntegrationPage-module___email_buttons__container___3Qmig"
};
export default ___CSS_LOADER_EXPORT___;
