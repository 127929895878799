export const cleanLocalStorageCache = () => {
  Object.keys(window.localStorage).forEach(key => {
    if (key.startsWith('bb-')) {
      localStorage.removeItem(key);
    }
    if (key === 'recoil-persist') {
      localStorage.removeItem(key);
    }
  });
};

export const cleanChromeStorage = () => {
  return new Promise(resolve => {
    if (chrome && chrome.storage && chrome.storage.sync) {
      chrome?.storage?.sync?.set({ token: '', refreshToken: '', hashedFingerprint: '' }, () => {
        resolve();
      });
    } else {
      resolve(null);
    }
  });
};
