import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Tooltip, Text } from '@bloobirds-it/flamingo-ui';
import { InsightStatus } from '@bloobirds-it/types';
import { motion } from 'framer-motion';

import styles from './insights.module.css';
import {
  INSIGHT_STATUS_BG_COLOR,
  INSIGHT_STATUS_LABEL,
  INSIGHT_STATUS_COLOR,
  INSIGHT_STATUS_ICON,
  INSIGHT_STATUS_LABEL_COLOR,
} from './insights.types';

interface ICollapsableHeaderProps {
  toggle: () => void;
  isOpen: boolean;
}
export const CollapsableHeader = ({
  children,
  toggle,
  isOpen,
}: PropsWithChildren<ICollapsableHeaderProps>) => {
  return (
    <div className={styles.collapseHeader}>
      {children}
      <motion.div
        onClick={toggle}
        initial={{ rotate: 180 }}
        animate={{ rotate: isOpen ? 180 : 0 }}
        transition={{ duration: 0.3 }}
      >
        <Icon name="chevronDown" size={16} color="purple" />
      </motion.div>
    </div>
  );
};
export const CollapsableBody = ({ children, isOpen }: PropsWithChildren<{ isOpen: boolean }>) => {
  return (
    <motion.div
      initial={false}
      animate={{ height: isOpen ? 'auto' : 0, opacity: isOpen ? 1 : 0 }}
      style={{ overflow: 'hidden' }}
      transition={{ duration: 0.3 }}
    >
      {children}
    </motion.div>
  );
};

export const InsightStatusBadge = ({
  status,
  onlyBadge = false,
}: {
  status?: InsightStatus;
  onlyBadge?: boolean;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'activityTimelineItem.item.copilotInsights.insightStatus',
  });
  if (!status || status === 'GENERATED') return null;

  return (
    <div
      className={styles.insightStatusBadgeContainer}
      style={{ backgroundColor: `var(--${INSIGHT_STATUS_BG_COLOR[status]})` }}
    >
      <Tooltip title={onlyBadge ? t(INSIGHT_STATUS_LABEL[status]) : ''} position="top">
        <Icon size={20} color={INSIGHT_STATUS_COLOR[status]} name={INSIGHT_STATUS_ICON[status]} />
      </Tooltip>
      {!onlyBadge && (
        <div style={{ padding: '2px' }}>
          <Text color={INSIGHT_STATUS_LABEL_COLOR[status]} size="xs">
            {t(INSIGHT_STATUS_LABEL[status])}
          </Text>
        </div>
      )}
    </div>
  );
};

export const InsightPlaceHolder = ({ status }: { status: InsightStatus }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'activityTimelineItem.item.copilotInsights.insightStatusMessage',
  });

  switch (status) {
    case 'GENERATED':
      return null;
    case 'GENERATING':
      return (
        <div className={styles.generatingContainer}>
          <div>
            <Icon name="stars" color="purple" />
          </div>
          <Text size="s" weight="heavy" color="purple">
            {t(INSIGHT_STATUS_LABEL[status])}
          </Text>
        </div>
      );
    case 'FAILED':
      return (
        <div className={styles.failedContainer}>
          <Icon name="cancel" color="tomato" />
          <Text size="s" weight="heavy">
            {t(INSIGHT_STATUS_LABEL[status])}
          </Text>
        </div>
      );
    case 'NOT_PROCESSED':
      return (
        <div className={styles.notProcessedContainer}>
          <Icon name="infoFilled" color="bloobirds" />
          <Text size="s" weight="heavy">
            {t(INSIGHT_STATUS_LABEL[status])}
          </Text>
        </div>
      );
    case 'NO_RESULTS':
      return (
        <div className={styles.noResultsContainer}>
          <Icon name="searchNone" color="softPeanut" />
          <Text size="s" weight="heavy" align="center">
            {t(INSIGHT_STATUS_LABEL[status])}
          </Text>
        </div>
      );
    default:
      return null;
  }
};
