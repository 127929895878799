// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subHomeFooter___footer_wrapper___GxCVb {\n  margin-top: 36px;\n}\n\n.subHomeFooter___text_wrapper___jlw3j {\n  margin-bottom: 5px;\n}\n\n.subHomeFooter___text_align_center___Oo9Or {\n  text-align: center;\n}\n\n.subHomeFooter___link___qPt36 {\n  color: var(--bloobirds);\n  font-size: 14px;\n  margin-left: 5px;\n  cursor: pointer;\n}\n\n.subHomeFooter___link___qPt36:hover {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/subhomeLayout/subhomeContent/newSubhomeFilters/subHomeFooter/subHomeFooter.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["._footer_wrapper {\n  margin-top: 36px;\n}\n\n._text_wrapper {\n  margin-bottom: 5px;\n}\n\n._text_align_center {\n  text-align: center;\n}\n\n._link {\n  color: var(--bloobirds);\n  font-size: 14px;\n  margin-left: 5px;\n  cursor: pointer;\n}\n\n._link:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_footer_wrapper": "subHomeFooter___footer_wrapper___GxCVb",
	"_text_wrapper": "subHomeFooter___text_wrapper___jlw3j",
	"_text_align_center": "subHomeFooter___text_align_center___Oo9Or",
	"_link": "subHomeFooter___link___qPt36"
};
export default ___CSS_LOADER_EXPORT___;
