import React, { cloneElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dropdown,
  Icon,
  IconButton,
  Item,
  Label,
  Section,
  Select,
  Spinner,
  Text,
  Tooltip,
  useVisible,
} from '@bloobirds-it/flamingo-ui';
import { useActiveUserSettings, useMainRelatedUsageEnabled } from '@bloobirds-it/hooks';
import {
  Bobject,
  BobjectId,
  BobjectTypes,
  RelatedObject,
  RelatedObjectClass,
  MessagesEvents,
  SALESFORCE_LOGIC_ROLES,
  TaskRelatedBobject,
  MainBobjectTypes,
  ExtensionBobject,
} from '@bloobirds-it/types';
import { api, getValueFromLogicRole } from '@bloobirds-it/utils';
import clsx from 'clsx';

import { useBobjectRelations } from '../../hooks/useBobjectRelations';
import { useRelatedAssignment } from '../../hooks/useRelatedAssignment';
import styles from './assignRelatedDropdown.module.css';

enum DropdownStates {
  Empty,
  NoRelations,
  List,
  Edition,
  Saving,
}

interface AssignRelatedDropdownProps {
  bobjectId:
    | BobjectId<BobjectTypes.Task>['value']
    | BobjectId<BobjectTypes.Activity>['value']
    | Array<BobjectId<BobjectTypes.Activity>['value']>;
  relatedId: string | string[];
  referenceBobject:
    | Bobject
    | ExtensionBobject
    | TaskRelatedBobject
    | {
        id: BobjectId<MainBobjectTypes>['value'];
      };
  openRelatedInSidepeek?: (r: RelatedObject) => void;
}

interface AssignRelatedDropdownContentProps extends AssignRelatedDropdownProps {
  anchorRef?: any;
  anchor?: JSX.Element;
  onClose?: () => void;
}

const DefaultAnchor = ({
  onClick,
}: {
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}) => (
  <Tooltip title="Assign this activity to any related object" position="top">
    <IconButton name="relations" color="bloobirds" onClick={onClick} size={16} />
  </Tooltip>
);
//TODO REFACTOR
export const AssignRelatedDropdown = ({
  anchor,
  onClose,
  ...props
}: AssignRelatedDropdownContentProps) => {
  const { ref, visible, setVisible } = useVisible(false);
  const { settings } = useActiveUserSettings();
  const mainRelatedUsageEnabled = useMainRelatedUsageEnabled(settings?.account?.id);

  const onClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e?.stopPropagation();
    e?.stopPropagation();
    setVisible(!visible);
  };

  return mainRelatedUsageEnabled ? (
    <Dropdown
      ref={ref}
      visible={visible}
      onClose={onClose}
      anchor={
        cloneElement(anchor, {
          onClick: e => {
            onClick(e);
            anchor.props.onClick?.();
          },
        }) ?? <DefaultAnchor onClick={onClick} />
      }
    >
      <AssignRelatedDropdownContent {...props} />
    </Dropdown>
  ) : null;
};

function parsedRelations(relations: ReturnType<typeof useBobjectRelations>['relations']) {
  if (!relations) return null;
  return Object.keys(relations).map(key => {
    return {
      relatedObjectType: key,
      relatedSobjects: relations[key],
    };
  });
}

const AssignRelatedDropdownContent = ({
  bobjectId,
  relatedId,
  referenceBobject,
  openRelatedInSidepeek,
}: AssignRelatedDropdownProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'relatedObjects.assignRelatedDropdown',
  });
  const { settings } = useActiveUserSettings();
  const [state, setState] = React.useState<DropdownStates>(DropdownStates.Empty);
  const [activeRelatedState, setActiveRelatedState] = React.useState(relatedId);
  const [referenceBobjectState, setReferenceBobjectState] = React.useState<Bobject>();
  const [selectValue, setSelectValue] = React.useState<string | string[]>(
    typeof relatedId === 'string' ? relatedId : relatedId?.[0],
  );
  const salesforceInstance = settings?.account?.salesforceInstance;
  const referenceBobjectType = referenceBobjectState?.id?.typeName;
  const referenceBobjectCRMUrl = useMemo(() => {
    let salesforceIdValue;
    switch (referenceBobjectType) {
      case BobjectTypes.Lead:
        salesforceIdValue =
          getValueFromLogicRole(referenceBobjectState, SALESFORCE_LOGIC_ROLES.LEAD_ID_FIELD) ??
          getValueFromLogicRole(referenceBobjectState, SALESFORCE_LOGIC_ROLES.CONTACT_ID_FIELD);
        break;
      case BobjectTypes.Company:
        salesforceIdValue = getValueFromLogicRole(
          referenceBobjectState,
          SALESFORCE_LOGIC_ROLES.ACCOUNT_ID_FIELD,
        );
        break;
      case BobjectTypes.Opportunity:
        salesforceIdValue = getValueFromLogicRole(
          referenceBobjectState,
          SALESFORCE_LOGIC_ROLES.OPPORTUNITY_ID_FIELD,
        );
        break;
      default:
        break;
    }
    if (salesforceIdValue) {
      return `${salesforceInstance}/${salesforceIdValue}`;
    } else {
      return null;
    }
  }, [referenceBobjectState?.id?.value]);

  const {
    addRelatedToBobject,
    activityAllRelated,
    isLoadingAllRelations,
    activityActiveRelated,
    isLoading,
    mutate,
  } = useRelatedAssignment({
    //TODO clean this DISASTER
    bobjectId: bobjectId || 'undefined/Task/undefined',
    activityRelated: activeRelatedState,
    referenceBobjectType,
  });
  const { relations } = useBobjectRelations({ bobject: referenceBobject as any });
  const options = parsedRelations(relations) || activityAllRelated;
  useEffect(() => {
    const referencedBobjectId =
      typeof referenceBobject === 'string'
        ? referenceBobject
        : typeof referenceBobject?.id === 'string'
        ? referenceBobject?.id
        : referenceBobject?.id?.value;
    if (typeof referencedBobjectId === 'string') {
      api
        .get(`/bobjects/${referencedBobjectId}/form`)
        .then(res => setReferenceBobjectState(res?.data));
    } else if (referenceBobject && 'id' && 'fields' in referenceBobject) {
      setReferenceBobjectState(referenceBobject);
    }
  }, [referenceBobject]);

  const handleRelatedClick = (r: RelatedObject) => {
    openRelatedInSidepeek?.(r);
  };

  const handleChange = value => {
    if (!value) setSelectValue([]);
    setSelectValue(value);
  };

  useEffect(() => {
    if (isLoadingAllRelations) {
      setState(DropdownStates.Saving);
    } else {
      if (!options || options?.length === 0) {
        setState(DropdownStates.NoRelations);
      } else {
        if (isLoading) {
          setState(DropdownStates.Saving);
        } else {
          if (Object.values(activityActiveRelated || {})?.length === 0) {
            setState(DropdownStates.Empty);
          } else {
            setState(DropdownStates.List);
          }
        }
      }
    }
  }, [
    isLoading,
    isLoadingAllRelations,
    Object.values(activityActiveRelated || {})?.length,
    Object.values(options || {})?.length,
  ]);

  const renderContent = () => {
    switch (state) {
      case DropdownStates.Empty:
        return (
          <>
            <Icon name="addCircle" color="softPeanut" size={18} />
            <Text size="s" color="softPeanut">
              {t('empty.title')}
            </Text>
          </>
        );
      case DropdownStates.NoRelations:
        return (
          <div className={styles.noRelationsWrapper}>
            <Icon name="searchNone" color="softPeanut" size={18} />
            <Text size="s" color="softPeanut">
              {t('noRelations.title')}
            </Text>
          </div>
        );
      case DropdownStates.Edition:
        return options?.length === 0 ? (
          <div className={styles.sectionHeader}>
            <Text size="s" color="softPeanut" align="center">
              {t('edition.empty')}
            </Text>
          </div>
        ) : (
          <>
            <div className={styles.sectionHeader}>
              <Icon name="edit" color="softPeanut" size={16} />
              <Text size="s" color="softPeanut">
                {t('edition.title')}
              </Text>
            </div>
            <Select
              autocomplete
              size="small"
              width="100%"
              value={selectValue}
              onChange={handleChange}
              defaultValue={typeof relatedId === 'string' ? relatedId : relatedId?.[0]}
              placeholder={t('edition.placeholder')}
            >
              <Item key="none" value={null} label={t('edition.none')}>
                {t('edition.none')}
              </Item>
              {options?.map((rel, index) => {
                return [
                  <Section key={rel?.relatedObjectType + '_' + index}>
                    {rel?.relatedObjectType}
                  </Section>,
                  ...(rel?.relatedSobjects?.map(rawR => {
                    const r = new RelatedObjectClass(rawR);
                    return (
                      <Item
                        key={r?.compositeRelatedId}
                        value={r?.compositeRelatedId}
                        label={r?.title}
                      >
                        {r.title}
                      </Item>
                    );
                  }) ?? []),
                ];
              })}
            </Select>
          </>
        );
      case DropdownStates.List:
        return (
          <>
            {Object.keys(activityActiveRelated || {})?.map(key => (
              <>
                <div className={styles.sectionHeader}>
                  <Icon
                    name={activityActiveRelated?.[key]?.[0]?.icon}
                    color="softPeanut"
                    size={16}
                  />
                  <Text size="s" color="softPeanut">
                    {key}
                  </Text>
                </div>
                {activityActiveRelated[key]?.map(r => (
                  <Label
                    key={r?.compositeRelatedId}
                    onClick={() => handleRelatedClick(r as RelatedObject)}
                    color="transparent"
                    textColor="bloobirds"
                    uppercase={false}
                    align="left"
                    overrideStyle={{
                      border: 'none',
                      padding: 0,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    hoverStyle={{ backgroundColor: 'transparent' }}
                  >
                    {r?.title}
                  </Label>
                ))}
              </>
            ))}
          </>
        );
      case DropdownStates.Saving:
        return <Spinner name="loadingCircle" />;
    }
  };

  const renderFooter = () => {
    switch (state) {
      case DropdownStates.Empty:
        return (
          <Button
            size="small"
            onClick={e => {
              e?.stopPropagation();
              e?.preventDefault();
              setState(DropdownStates.Edition);
            }}
            uppercase={false}
          >
            {t('empty.footer')}
          </Button>
        );
      case DropdownStates.NoRelations:
        return (
          <Button
            size="small"
            iconRight="externalLink"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              window.open(referenceBobjectCRMUrl);
            }}
            uppercase={false}
            disabled={!referenceBobjectCRMUrl}
          >
            {t('noRelations.footer')}
          </Button>
        );
      case DropdownStates.Edition:
        return (
          <>
            <Button
              size="small"
              color="tomato"
              variant="clear"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setState(
                  Object.keys(activityActiveRelated || {})?.length > 0
                    ? DropdownStates.List
                    : DropdownStates.Empty,
                );
              }}
              uppercase={false}
            >
              {t('edition.footer1')}
            </Button>
            <Button
              size="small"
              disabled={options?.length === 0}
              onClick={e => {
                e?.stopPropagation();
                e?.preventDefault();
                setState(DropdownStates.Saving);
                addRelatedToBobject(selectValue, () => {
                  mutate(selectValue);
                  setActiveRelatedState(selectValue);
                  setState(DropdownStates.List);
                  window.dispatchEvent(
                    new CustomEvent(MessagesEvents.ActiveBobjectUpdated, {
                      detail: { type: BobjectTypes.Activity },
                    }),
                  );
                });
              }}
              uppercase={false}
            >
              {t('edition.footer2')}
            </Button>
          </>
        );
      case DropdownStates.List:
        return (
          <Button
            size="small"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setState(DropdownStates.Edition);
            }}
            uppercase={false}
          >
            {t('list.footer')}
          </Button>
        );
      case DropdownStates.Saving:
        return <></>;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={clsx(styles.contentWrapper, {
          [styles.alignCenter]: [DropdownStates.Empty, DropdownStates.Saving].includes(state),
        })}
        onClick={e => {
          e?.stopPropagation();
          e?.stopPropagation();
        }}
      >
        {renderContent()}
      </div>
      <footer>{renderFooter()}</footer>
    </div>
  );
};
