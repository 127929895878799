// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".statusLabel-module___status_wrapper___H-dBT {\n  display: flex;\n  justify-content: center;\n  box-sizing: border-box;\n}\n\n.statusLabel-module___status_text___fB6pI {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}", "",{"version":3,"sources":["webpack://./src/js/components/statusLabel/statusLabel.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":["._status_wrapper {\n  display: flex;\n  justify-content: center;\n  box-sizing: border-box;\n}\n\n._status_text {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_status_wrapper": "statusLabel-module___status_wrapper___H-dBT",
	"_status_text": "statusLabel-module___status_text___fB6pI"
};
export default ___CSS_LOADER_EXPORT___;
