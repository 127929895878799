import { ExtensionLead, RelatedObject } from '@bloobirds-it/types';
import { api, getSobjectTypeFromId } from '@bloobirds-it/utils';
import useSWR from 'swr';

interface RelationsDataProps {
  [key: string]: RelatedObject[];
}

interface RelationsFeedProps {
  relations: RelationsDataProps;
  loading: boolean;
  mutate: () => void;
}

export const useBobjectRelations = ({
  bobject,
  mainRelated,
}: {
  bobject: Pick<ExtensionLead, 'salesforceId' | 'salesforceLeadId'>;
  mainRelated?: boolean;
}): RelationsFeedProps => {
  const sobjectId = bobject?.salesforceId;
  const salesforceLeadId = bobject?.salesforceLeadId;
  const sobjectType = getSobjectTypeFromId(sobjectId);

  const { data: relations, isLoading, mutate } = useSWR<RelationsDataProps>(
    sobjectType && sobjectId
      ? `/utils/service/salesforce/related/${sobjectType}/${sobjectId}?salesforceLeadId=${salesforceLeadId}${
          mainRelated ? '&mainRelatedObject=true' : ''
        }`
      : null,
    (url: string) => api.get(url).then(data => data?.data),
  );

  return {
    relations,
    loading: isLoading,
    mutate,
  };
};
