import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, IconType, Text } from '@bloobirds-it/flamingo-ui';
import { CopilotInsight, CopilotInsightVersion } from '@bloobirds-it/types';

import styles from '../../playgroundPages.module.css';
import InsightHistory from './insightHistory';
import InsightVersions from './insightVersions';

type TabProps = {
  id: string;
  label: string;
  icon: IconType;
};

export const InsightTabs = ({
  history,
  isLoading,
  selectedInsight,
}: {
  history: CopilotInsightVersion[];
  isLoading: boolean;
  selectedInsight: CopilotInsight;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.insightTabs' });
  const TABS = [
    { id: 'history', label: t('history'), icon: 'list1' },
    //{ id: 'versions', label: 'Compare versions', icon: 'sort' },
  ] as TabProps[];

  const [selectedTab, setSelectedTab] = React.useState(TABS[0].id);

  return (
    <>
      <div>
        <div className={styles.insight_tabs}>
          {TABS.map(tab => {
            const disabled = tab.id === 'versions';

            return (
              <div
                key={tab.id}
                className={styles.insight_tab}
                onClick={() => !disabled && setSelectedTab(tab.id)}
                style={{
                  borderBottom:
                    selectedTab === tab.id ? '2px solid var(--peanut)' : '2px solid transparent',
                }}
              >
                <Icon name={tab.icon} size={18} color={'softPeanut'} />
                <Text size="s" color={disabled ? 'softPeanut' : 'peanut'}>
                  {tab.label}
                </Text>
              </div>
            );
          })}
        </div>
        <span className={styles.separator_line} />
      </div>
      <div className={styles.insight_tab_content}>
        {selectedTab === 'history' && (
          <InsightHistory
            history={history}
            isLoading={isLoading}
            selectedInsight={selectedInsight}
          />
        )}
        {selectedTab === 'versions' && <InsightVersions />}
      </div>
    </>
  );
};
