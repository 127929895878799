import { useEffect, useState } from 'react';

import { api } from '@bloobirds-it/utils';

export interface MessagingTemplateResponse {
  id: string;
  name: string;
  subject: string;
  content: string;
  previewSubject: string;
  previewContent: string;
  creationDatetime: string;
  updateDatetime: string;
  createdBy: string;
  updatedBy: string;
  visibility: any;
  isOfficial: boolean;
  isBattlecard: boolean;
  stage: any;
  type: any;
  format: any;
  templateStatistics: any;
  segmentationValues: any;
  mediaFiles: any;
  cadenceUsages: number;
  shortcut: string;
  document: any;
}

export const useTemplate = (templateId: string) => {
  const [template, setTemplate] = useState<MessagingTemplateResponse>();

  useEffect(() => {
    if (templateId) {
      api.get(`/messaging/messagingTemplates/${templateId}`).then(res => {
        const templateResponse: MessagingTemplateResponse = res?.data;
        if (templateResponse) {
          setTemplate(templateResponse);
        }
      });
    }
  }, [templateId]);

  return template;
};
