// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notificationBell-module___wrap___zvJW1 {\n  width: 400px;\n  padding: 8px 0 0 0;\n  min-height: 350px;\n  max-height: calc(100vh - 120px);\n  overflow: auto;\n}\n\n.notificationBell-module___header_wrapper___6pvHv {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 12px;\n  padding: 0 16px;\n}\n\n.notificationBell-module___link___rwvfY {\n  cursor: pointer;\n}\n\n.notificationBell-module___tabs_container_feed___BHbX- {\n  margin-top: -32px;\n}\n\n.notificationBell-module___tabs_container___\\+3MVz > div:first-child > div:first-child > div:first-child {\n  margin-left: 20px;\n}\n\n.notificationBell-module___tabs_container___\\+3MVz > div:first-child > div:first-child > div {\n  min-height: 36px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/header/headerActions/notificationBell/notificationBell.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,+BAA+B;EAC/B,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["._wrap {\n  width: 400px;\n  padding: 8px 0 0 0;\n  min-height: 350px;\n  max-height: calc(100vh - 120px);\n  overflow: auto;\n}\n\n._header_wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 12px;\n  padding: 0 16px;\n}\n\n._link {\n  cursor: pointer;\n}\n\n._tabs_container_feed {\n  margin-top: -32px;\n}\n\n._tabs_container > div:first-child > div:first-child > div:first-child {\n  margin-left: 20px;\n}\n\n._tabs_container > div:first-child > div:first-child > div {\n  min-height: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_wrap": "notificationBell-module___wrap___zvJW1",
	"_header_wrapper": "notificationBell-module___header_wrapper___6pvHv",
	"_link": "notificationBell-module___link___rwvfY",
	"_tabs_container_feed": "notificationBell-module___tabs_container_feed___BHbX-",
	"_tabs_container": "notificationBell-module___tabs_container___+3MVz"
};
export default ___CSS_LOADER_EXPORT___;
