// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".board-module__contentTabs___XUhY9 {\n  width: 100%;\n  height: calc(100vh - 48px);\n  padding: 0;\n  margin: 0;\n  overflow-y: auto;\n}\n\n.board-module__contentTabsAllocateQC___2Um8c {\n  overflow-x: unset !important;\n}\n\n.board-module__tabs___Ch1oW {\n  padding: 0;\n  margin: 0;\n}\n\n.board-module__container___CNxYd {\n  flex-grow: 1;\n  display: flex;\n  min-width: 0;\n}\n\n.board-module__feedWrapper___3fgsv {\n  display: flex;\n  max-height: calc(100vh - 48px);\n  min-height: calc(100vh - 48px);\n}\n", "",{"version":3,"sources":["webpack://./src/js/app/main/board/board.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,0BAA0B;EAC1B,UAAU;EACV,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,UAAU;EACV,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,8BAA8B;AAChC","sourcesContent":[".contentTabs {\n  width: 100%;\n  height: calc(100vh - 48px);\n  padding: 0;\n  margin: 0;\n  overflow-y: auto;\n}\n\n.contentTabsAllocateQC {\n  overflow-x: unset !important;\n}\n\n.tabs {\n  padding: 0;\n  margin: 0;\n}\n\n.container {\n  flex-grow: 1;\n  display: flex;\n  min-width: 0;\n}\n\n.feedWrapper {\n  display: flex;\n  max-height: calc(100vh - 48px);\n  min-height: calc(100vh - 48px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentTabs": "board-module__contentTabs___XUhY9",
	"contentTabsAllocateQC": "board-module__contentTabsAllocateQC___2Um8c",
	"tabs": "board-module__tabs___Ch1oW",
	"container": "board-module__container___CNxYd",
	"feedWrapper": "board-module__feedWrapper___3fgsv"
};
export default ___CSS_LOADER_EXPORT___;
