export const FIELDS_COLUMNS = [
  {
    label: 'Name',
    name: 'name',
    sortable: true,
    id: 'name',
  },
  {
    label: 'Type',
    name: 'fieldType',
    sortable: false,
    id: 'fieldType',
  },
  {
    label: 'Group',
    name: 'bobjectFieldGroup',
    sortable: false,
    id: 'bobjectFieldGroup',
  },
  {
    label: 'Required',
    name: 'required',
    sortable: true,
    id: 'required',
  },
  {
    label: 'Duplicate Validation',
    name: 'duplicateValidation',
    sortable: true,
    id: 'duplicateValidation',
  },
  {
    label: 'Description',
    name: 'description',
    sortable: true,
    id: 'description',
  },
  {
    label: 'Default Value',
    name: '',
    sortable: false,
    id: 'defaultValue',
  },
  {
    label: 'Order',
    name: 'ordering',
    sortable: true,
    id: 'ordering',
  },
  {
    label: 'Values',
    name: '',
    sortable: false,
    id: 'values',
  },
  {
    label: 'Status',
    name: '',
    sortable: false,
    id: 'status',
  },
];

export const BOBJECT_FIELD = ['bobjectFields'];

export const PICKLIST_FIELD_TYPES = [
  'PICKLIST',
  'GLOBAL_PICKLIST',
  'MULTI_PICKLIST',
  'MULTI_GLOBAL_PICKLIST',
];
