import * as Routes from '../../app/_constants/routes';

export const ROUTES_TO_MAP = {
  [Routes.APP_MANAGEMENT_ACCOUNT_CONFIGURATION_INTEGRATION_SALESFORCE]:
    Routes.APP_ACCOUNT_INTEGRATION_SALESFORCE,
  [Routes.APP_MANAGEMENT_ACCOUNT_CONFIGURATION_INTEGRATION_SALESFORCE_TAB]:
    Routes.APP_ACCOUNT_INTEGRATION_SALESFORCE_TAB,
  [Routes.APP_MANAGEMENT_ACCOUNT_CONFIGURATION_INTEGRATION_SALESFORCE_CONNECT]:
    Routes.APP_ACCOUNT_INTEGRATION_SALESFORCE_CONNECT,
  [Routes.APP_MANAGEMENT_ACCOUNT_CONFIGURATION_INTEGRATION_SALESFORCE_SYNC_SETTINGS]:
    Routes.APP_ACCOUNT_INTEGRATION_SALESFORCE_SYNC_SETTINGS,
  [Routes.APP_MANAGEMENT_ACCOUNT_CONFIGURATION_INTEGRATION_SALESFORCE_SYNC_STATUS]:
    Routes.APP_ACCOUNT_INTEGRATION_SALESFORCE_SYNC_STATUS,
  [Routes.APP_MANAGEMENT_ACCOUNT_CONFIGURATION_INTEGRATION_SALESFORCE_USERS]:
    Routes.APP_ACCOUNT_INTEGRATION_SALESFORCE_USERS,
  [Routes.APP_MANAGEMENT_ACCOUNT_CONFIGURATION_INTEGRATION_SALESFORCE_MAPPING]:
    Routes.APP_ACCOUNT_INTEGRATION_SALESFORCE_MAPPING,
  [Routes.APP_MANAGEMENT_ACCOUNT_CONFIGURATION_INTEGRATION_SALESFORCE_OAUTH]:
    Routes.APP_ACCOUNT_INTEGRATION_SALESFORCE_MAPPING,
  [Routes.APP_MANAGEMENT_ACCOUNT_CONFIGURATION_INTEGRATION_HUBSPOT_TAB]:
    Routes.APP_ACCOUNT_INTEGRATION_SALESFORCE_OAUTH,
  [Routes.APP_MANAGEMENT_ACCOUNT_CONFIGURATION_INTEGRATION_HUBSPOT_CONNECT]:
    Routes.APP_ACCOUNT_INTEGRATION_HUBSPOT_CONNECT,
  [Routes.APP_MANAGEMENT_ACCOUNT_CONFIGURATION_INTEGRATION_HUBSPOT_SYNC_SETTINGS]:
    Routes.APP_ACCOUNT_INTEGRATION_HUBSPOT_SYNC_SETTINGS,
  [Routes.APP_MANAGEMENT_ACCOUNT_CONFIGURATION_INTEGRATION_HUBSPOT_SYNC_STATUS]:
    Routes.APP_ACCOUNT_INTEGRATION_HUBSPOT_SYNC_STATUS,
  [Routes.APP_MANAGEMENT_ACCOUNT_CONFIGURATION_INTEGRATION_HUBSPOT_USERS]:
    Routes.APP_ACCOUNT_INTEGRATION_HUBSPOT_USERS,
  [Routes.APP_MANAGEMENT_ACCOUNT_CONFIGURATION_INTEGRATION_HUBSPOT_MAPPING]:
    Routes.APP_ACCOUNT_INTEGRATION_HUBSPOT_MAPPING,
  [Routes.APP_MANAGEMENT_ACCOUNT_CONFIGURATION_INTEGRATION_HUBSPOT_OAUTH]:
    Routes.APP_ACCOUNT_INTEGRATION_HUBSPOT_OAUTH,
  [Routes.APP_MANAGEMENT_ACCOUNT_DIALERS]: Routes.APP_ACCOUNT_DIALERS,
  [Routes.APP_MANAGEMENT_ACCOUNT_DIALERS_TWILIO]: Routes.APP_ACCOUNT_DIALERS_TWILIO,
  [Routes.APP_MANAGEMENT_ACCOUNT_DIALERS_AIRCALL]: Routes.APP_ACCOUNT_DIALERS_AIRCALL,
  [Routes.APP_MANAGEMENT_ACCOUNT_DIALERS_AIRCALL_REDIRECT]:
    Routes.APP_ACCOUNT_DIALERS_AIRCALL_REDIRECT,
  [Routes.APP_MANAGEMENT_ACCOUNT_PREFERENCES_USERS]: Routes.APP_ACCOUNT_SALES_TEAM,
  [Routes.APP_MANAGEMENT_ACCOUNT_PREFERENCES_GENERAL_SETTINGS]: Routes.APP_ACCOUNT_GENERAL_SETTINGS,
  [Routes.APP_MANAGEMENT_ACCOUNT_PREFERENCES_NOTIFICATIONS]: Routes.APP_ACCOUNT_NOTIFICATIONS,
  [Routes.APP_MANAGEMENT_ACCOUNT_PREFERENCES_VIEWS]: Routes.APP_ACCOUNT_VIEWS,
  [Routes.APP_MANAGEMENT_ACCOUNT_DATA_MANAGEMENT_FIELDS]: Routes.APP_ACCOUNT_FIELDS,
  [Routes.APP_MANAGEMENT_ACCOUNT_DATA_MANAGEMENT_GLOBAL_PICKLISTS]:
    Routes.APP_ACCOUNT_GLOBAL_PICKLISTS,
  [Routes.APP_MANAGEMENT_ACCOUNT_DATA_MANAGEMENT_GROUPS]: Routes.APP_ACCOUNT_GROUPS,
  [Routes.APP_MANAGEMENT_ACCOUNT_DATA_MANAGEMENT_DEPENDENCIES]:
    Routes.APP_ACCOUNT_FIELD_DEPENDENCIES,
  [Routes.APP_MANAGEMENT_ACCOUNT_MESSAGING_PITCH]: Routes.APP_PLAYBOOK_MESSAGING_PITCH,
  [Routes.APP_MANAGEMENT_ACCOUNT_MESSAGING_EMAIL]: Routes.APP_PLAYBOOK_MESSAGING_EMAIL,
  [Routes.APP_MANAGEMENT_ACCOUNT_MESSAGING_LINKEDIN]: Routes.APP_PLAYBOOK_MESSAGING_LINKEDIN,
  [Routes.APP_MANAGEMENT_ACCOUNT_MESSAGING_QQ]: Routes.APP_PLAYBOOK_MESSAGING_QQ,
  [Routes.APP_MANAGEMENT_ACCOUNT_MESSAGING_SEGMENTATION]:
    Routes.APP_PLAYBOOK_MESSAGING_SEGMENTATION,
  [Routes.APP_MANAGEMENT_ACCOUNT_BUSINESS_ASSETS_TARGET_MARKET]: Routes.APP_PLAYBOOK_TARGET_MARKET,
  [Routes.APP_MANAGEMENT_ACCOUNT_BUSINESS_ASSETS_BUYER_PERSONAS]:
    Routes.APP_PLAYBOOK_BUYER_PERSONAS,
  [Routes.APP_MANAGEMENT_ACCOUNT_BUSINESS_ASSETS_SCENARIOS]: Routes.APP_PLAYBOOK_SCENARIOS,
  [Routes.APP_MANAGEMENT_ACCOUNT_BUSINESS_ASSETS_CADENCES]: Routes.APP_CADENCES_MANAGE,
};
