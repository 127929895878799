export const colors = {
  verySoftBanana: '#ffe8b3',
  softBanana: '#ffd166',
  banana: '#ffbd19',
  verySoftTangerine: '#ffd1b3',
  softTangerine: '#ffa366',
  tangerine: '#ff8433',
  verySoftMelon: '#d9f0c0',
  softMelon: '#b4de85',
  melon: '#63ba00',
  verySoftGrape: '#a7ffdb',
  softGrape: '#81ecc0',
  grape: '#42da9c',
  lightBloobirds: '#cde2f6',
  softBloobirds: '#43a3fd',
  bloobirds: '#1991ff',
  verySoftPurple: '#E2E5FF',
  softPurple: '#9AA4FF',
  purple: '#1126EA',
  verySoftTomato: '#ffb3c2',
  softTomato: '#ff6685',
  tomato: '#f5245b',
  verySoftPeanut: '#c5d1dd',
  lightPeanut: '#d4e0f1',
  softPeanut: '#94a5ba',
};
