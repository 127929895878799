export const FIELD_VALUE_FILTERS = Object.freeze({
  bobjectTypeId: {
    name: 'bobjectTypes',
    label: 'Bobject Type',
  },
  requiredParentFieldId: {
    name: 'requiredParentFields',
    label: 'Parent Field',
  },
  requiredValueId: {
    name: 'requiredValues',
    label: 'Conditioning Value',
  },
  childFieldId: {
    name: 'childFields',
    label: 'Child Field',
  },
});

export const FIELD_FILTERS = Object.freeze({
  bobjectTypeId: {
    name: 'bobjectTypes',
    label: 'Bobject Type',
  },
  requiredParentFieldId: {
    name: 'requiredParentFields',
    label: 'Parent Field',
  },
  requiredValueId: {
    name: 'requiredValues',
    label: 'Conditioning Value',
  },
});
