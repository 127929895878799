// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".opportunityCardBody-module___opportunity___ra3l4 {\n  display: flex;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/activitySection/activityItem/activityBody/opportunityCardBody/opportunityCardBody.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB","sourcesContent":["._opportunity {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_opportunity": "opportunityCardBody-module___opportunity___ra3l4"
};
export default ___CSS_LOADER_EXPORT___;
