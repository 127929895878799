import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCopilotActivity } from '@bloobirds-it/copilot';
import { Icon, IconButton, Text, Tooltip } from '@bloobirds-it/flamingo-ui';

import NoResultsFound from '../insights/noResultsFound';
import styles from './summary.module.css';

export const Summary = () => {
  const { activityInsights } = useCopilotActivity();

  return <SummaryUI summary={activityInsights?.summary} />;
};

export const SummaryUI = ({ summary }: { summary?: string }) => {
  const { t } = useTranslation();

  const [copyTooltip, setCopyTooltip] = useState(t('copyText.copyToClipboard'));

  const onCopy = () => {
    navigator.clipboard.writeText(activityInsights?.summary).then(() => {
      setCopyTooltip(t('copyText.copied'));
      setTimeout(() => setCopyTooltip(t('copyText.copyToClipboard')), 1000);
    });
  };

  return (
    <div className={styles.summary}>
      <div className={styles.summaryTitle}>
        <div className={styles.titleSection}>
          <Icon name="stars" color="purple" />
          <Text size="s" weight="heavy">
            {t('ai.aiAnalysis.summary')}
          </Text>
        </div>
        {summary && (
          <div className={styles.titleSection}>
            <Tooltip title={copyTooltip} position="top">
              <IconButton name="copy" color="purple" onClick={onCopy} size={16} />
            </Tooltip>
          </div>
        )}
      </div>

      {summary ? (
        <Text size="xs" color="peanut">
          {summary}
        </Text>
      ) : (
        <NoResultsFound />
      )}
    </div>
  );
};
