import React, { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Dropdown,
  Icon,
  IconButton,
  Input,
  Item,
  Section,
  Select,
  Text,
  Tooltip,
  useVisible,
} from '@bloobirds-it/flamingo-ui';
import { useMessagingTemplates } from '@bloobirds-it/hooks';
import { MessagingTemplate, TEMPLATE_TYPES } from '@bloobirds-it/types';

import { useCalendar } from '../../hooks/useCalendar';
import { RemindeBeforeType, ReminderBefore } from '../../types/calendar';
import styles from './reminderForm.module.css';

export const ReminderForm = () => {
  const {
    reminderTemplate,
    setReminderBefore,
    reminderBefore,
    setReminderTemplate,
    showReminder,
    setShowReminder,
  } = useCalendar();
  // @ts-ignore
  const { control, formState } = useFormContext();
  const [templateName, setTemplateName] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const { ref, visible, setVisible } = useVisible();
  const { myTemplates, teamTemplates } = useMessagingTemplates(
    {
      onlyMine: false,
      visibility: null,
      name: searchValue,
      segmentationValues: {},
      type: TEMPLATE_TYPES.EMAIL,
      teams: [],
    },
    'meeting-modal-reminder-form',
  );
  const privateTemplates = myTemplates.messagingTemplates || [];
  const publicTemplates = teamTemplates.messagingTemplates || [];
  const { t } = useTranslation('translation', {
    keyPrefix: 'meetingModal.mainForm.reminderForm',
  });
  const {
    field: { onChange: templateOnChange },
  } = useController({
    control,
    name: 'reminderTemplate',
    rules: {
      required: !!showReminder,
    },
    defaultValue: reminderTemplate,
  });
  const {
    field: { value: reminderBeforeValue, onChange: reminderBeforeOnChange },
  } = useController({
    control,
    name: 'reminderBefore',
    defaultValue: reminderBefore?.value,
    rules: {
      required: true,
    },
  });
  const {
    field: { value: reminderBeforeTypeValue, onChange: reminderBeforeTypeOnChange },
  } = useController({
    control,
    name: 'reminderBeforeType',
    defaultValue: reminderBefore?.type,
    rules: {
      required: true,
    },
  });
  const errorTemplate =
    formState?.errors && formState?.errors['reminderTemplate'] && t('thisFieldIsRequired');

  return (
    <div>
      <div className={styles._reminder_container}>
        <div className={styles._title}>
          <Icon name="bell" />
          <Text size="s">{t('addNotificationEmail')}</Text>
          <Tooltip title={t('tooltipMessage')} position="top">
            <Icon name="infoFilled" size={16} />
          </Tooltip>
        </div>
        <IconButton name="plus" onClick={() => setShowReminder(true)} />
      </div>
      {showReminder && (
        <div className={styles._reminder_form_wrapper}>
          <div className={styles._reminder_form_container}>
            <Tooltip title={templateName?.length > 15 && templateName} position="top">
              <Text color={templateName && templateName.length > 0 ? 'peanut' : 'softPeanut'}>
                {templateName && templateName.length > 0 ? templateName : t('emailTemplate') + '*'}
              </Text>
            </Tooltip>
            <Input
              size="small"
              type="number"
              width="50px"
              value={reminderBeforeValue}
              onChange={(v: string) => {
                setReminderBefore((prevStatus: ReminderBefore) => {
                  return {
                    type: prevStatus.type,
                    value: v,
                  };
                });
                reminderBeforeOnChange(v);
              }}
            />
            <Select
              size="small"
              width="80px"
              borderless={false}
              value={reminderBeforeTypeValue}
              onChange={(type: RemindeBeforeType) => {
                setReminderBefore((prevStatus: ReminderBefore) => {
                  return {
                    type,
                    value: prevStatus.value,
                  };
                });
                reminderBeforeTypeOnChange(type);
              }}
            >
              <Item value={RemindeBeforeType.minutes}>{t('minutes')}</Item>
              <Item value={RemindeBeforeType.hours}>{t('hours')}</Item>
              <Item value={RemindeBeforeType.days}>{t('days')}</Item>
            </Select>
            {templateName && (
              <IconButton
                name="cross"
                onClick={() => {
                  setReminderBefore({
                    type: RemindeBeforeType.minutes,
                    value: 30,
                  });
                  setReminderTemplate(null);
                  setTemplateName(null);
                  setShowReminder(false);
                }}
              />
            )}
          </div>
          <Dropdown
            ref={ref}
            visible={visible}
            anchor={
              <Input
                size="small"
                onClick={() => setVisible(true)}
                onChange={value => setSearchValue(value)}
                value={searchValue}
                width="100%"
                error={errorTemplate}
                placeholder={t('searchTemplate')}
              />
            }
          >
            <div className={styles._dropdown_wrapper}>
              {privateTemplates?.length > 0 && (
                <Section id="my-templates">{t('myTemplates')}</Section>
              )}
              {privateTemplates.map((messagingTemplate: MessagingTemplate) =>
                messagingTemplate.name?.length >= 40 ? (
                  <Item
                    key={messagingTemplate.id}
                    label={messagingTemplate.name}
                    value={messagingTemplate.id}
                    section="my-templates"
                    className={styles.selectItem}
                    onClick={value => {
                      setReminderTemplate(value);
                      templateOnChange(value);
                      setTemplateName(messagingTemplate.name);
                      setVisible(false);
                    }}
                  >
                    <Tooltip
                      className={styles.selectItem}
                      position="top"
                      title={messagingTemplate.name}
                      delay={500}
                    >
                      {messagingTemplate.name}
                    </Tooltip>
                  </Item>
                ) : (
                  <Item
                    key={messagingTemplate.id}
                    section="my-templates"
                    value={messagingTemplate.id}
                    className={styles.selectItem}
                    onClick={value => {
                      setReminderTemplate(value);
                      templateOnChange(value);
                      setTemplateName(messagingTemplate.name);
                      setVisible(false);
                    }}
                  >
                    {messagingTemplate.name}
                  </Item>
                ),
              )}
              {publicTemplates?.length > 0 && (
                <Section id="team-templates">{t('teamTemplates')}</Section>
              )}
              {publicTemplates.map((messagingTemplate: MessagingTemplate) =>
                messagingTemplate.name?.length >= 40 ? (
                  <Item
                    key={messagingTemplate.id}
                    label={messagingTemplate.name}
                    value={messagingTemplate.id}
                    section="team-templates"
                    className={styles.selectItem}
                    onClick={value => {
                      setReminderTemplate(value);
                      templateOnChange(value);
                      setTemplateName(messagingTemplate.name);
                      setVisible(false);
                    }}
                  >
                    <Tooltip
                      className={styles.selectItem}
                      position="top"
                      title={messagingTemplate.name}
                      delay={500}
                    >
                      {messagingTemplate.name}
                    </Tooltip>
                  </Item>
                ) : (
                  <Item
                    key={messagingTemplate.id}
                    section="team-templates"
                    value={messagingTemplate.id}
                    className={styles.selectItem}
                    onClick={value => {
                      setReminderTemplate(value);
                      templateOnChange(value);
                      setTemplateName(messagingTemplate.name);
                      setVisible(false);
                    }}
                  >
                    {messagingTemplate.name}
                  </Item>
                ),
              )}
            </div>
          </Dropdown>
        </div>
      )}
    </div>
  );
};
