// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".quickFilterModal-module___inputs_groups___AxM1t {\n  width: 50%;\n}\n\n.quickFilterModal-module___name_wrapper___B6TR1 {\n  margin: 24px 0 24px 0;\n  display: flex;\n}\n\n.quickFilterModal-module___quick_filter_wrapper___xWjJZ {\n  margin: 9px 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/subhomePages/prospectingPage/quickFilterModal/quickFilterModal.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["._inputs_groups {\n  width: 50%;\n}\n\n._name_wrapper {\n  margin: 24px 0 24px 0;\n  display: flex;\n}\n\n._quick_filter_wrapper {\n  margin: 9px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_inputs_groups": "quickFilterModal-module___inputs_groups___AxM1t",
	"_name_wrapper": "quickFilterModal-module___name_wrapper___B6TR1",
	"_quick_filter_wrapper": "quickFilterModal-module___quick_filter_wrapper___xWjJZ"
};
export default ___CSS_LOADER_EXPORT___;
