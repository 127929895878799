// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".messagingMineSwitch-module__container___sI5Ab {\n  display: flex;\n}\n\n.messagingMineSwitch-module__text___wM9Ij {\n  margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/messagingTemplates/messagingMineSwitch/messagingMineSwitch.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n}\n\n.text {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "messagingMineSwitch-module__container___sI5Ab",
	"text": "messagingMineSwitch-module__text___wM9Ij"
};
export default ___CSS_LOADER_EXPORT___;
