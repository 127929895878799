// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".syncSettingsTab-module___container___zA9n- {\n  margin-top: 28px;\n}\n.syncSettingsTab-module___container___zA9n- > div > div > div > p {\n  color: var(--peanut);\n}\n.syncSettingsTab-module___tabTitle___\\+E\\+-W {\n  display: flex;\n}\n.syncSettingsTab-module___radio_group_container___PTswL {\n  margin-top: 24px;\n  width: 617px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/integrationLayout/syncSettingsTab/syncSettingsTab.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,YAAY;AACd","sourcesContent":["._container {\n  margin-top: 28px;\n}\n._container > div > div > div > p {\n  color: var(--peanut);\n}\n._tabTitle {\n  display: flex;\n}\n._radio_group_container {\n  margin-top: 24px;\n  width: 617px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "syncSettingsTab-module___container___zA9n-",
	"_tabTitle": "syncSettingsTab-module___tabTitle___+E+-W",
	"_radio_group_container": "syncSettingsTab-module___radio_group_container___PTswL"
};
export default ___CSS_LOADER_EXPORT___;
