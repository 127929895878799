import React, { useState, useRef } from 'react';

import { Avatar, ColorType, Text } from '@bloobirds-it/flamingo-ui';
import { motion, AnimatePresence } from 'framer-motion';

import styles from '../../styles/phoneNumberPage.module.css';

interface User {
  id: number;
  name: string;
  color: ColorType;
  email: string;
}

interface UsersGroupCardProps {
  users: User[];
  isLastTwoRows: boolean;
}

const CustomAvatar: React.FC<{ color: string; initials: string }> = ({ color, initials }) => {
  return (
    <div
      className={styles.avatar}
      style={{
        backgroundColor: color || 'var(--peanut)',
      }}
    >
      <Text size="s" color="white" weight="medium">
        {initials}
      </Text>
    </div>
  );
};

function extractInitials(name: string) {
  const [firstName, lastName] = name.split(' ');
  return `${firstName[0]}${lastName ? lastName[0] : ''}`;
}

export const UsersGroupCard: React.FC<UsersGroupCardProps> = ({ users, isLastTwoRows }) => {
  const [isHovered, setIsHovered] = useState(false);
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);

  const maxVisibleUsers = 4;
  const visibleUsers = users?.slice(0, maxVisibleUsers);
  const remainingUsersCount = users?.length - maxVisibleUsers;

  const handleMouseEnter = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    hoverTimeout.current = setTimeout(() => {
      setIsHovered(false);
    }, 100);
  };

  return (
    <div
      className={styles.usersGroupCard}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {visibleUsers?.map(user => (
        <div key={user.id} className={styles.avatarWrapper}>
          <CustomAvatar
            color={user?.color || 'var(--peanut)'}
            initials={extractInitials(user.name)}
          />
        </div>
      ))}
      {remainingUsersCount > 0 && (
        <div className={styles.moreUsers}>
          <Text size="xs" color="peanut">
            +{remainingUsersCount}
          </Text>
        </div>
      )}

      <AnimatePresence>
        {isHovered && (
          <motion.div
            className={styles.usersDropdown}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{
              duration: 0.3,
              ease: 'easeInOut',
            }}
            style={isLastTwoRows && { bottom: '100%', top: 'auto' }}
          >
            {users?.map(user => (
              <div key={user.id} className={styles.userDetail}>
                <CustomAvatar color={user.color} initials={extractInitials(user.name)} />
                <div className={styles.userInfo}>
                  <Text size="s" color="peanut">
                    {user.name}
                  </Text>
                  <Text size="xs" color="softPeanut">
                    {user.email}
                  </Text>
                </div>
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
