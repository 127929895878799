// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".invitationForm-module___invitation_form_wrapper___CU7Yx {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    gap: 12px;\n    margin-top: 12px;\n}\n.invitationForm-module___invitation_form_wrapper___CU7Yx > div {\n    height: 35px;\n}\n\n.invitationForm-module___inputs_wrapper___srfJi {\n    display: flex;\n    align-items: flex-start;\n    gap: 12px;\n}\n\n.invitationForm-module___submit_button___amCv1 {\n    display: flex;\n    align-items: flex-start;\n    justify-content: space-between;\n    height: 35px;\n}\n\n.invitationForm-module___submit_button___amCv1 > div {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}", "",{"version":3,"sources":["webpack://./src/js/pages/homePage/components/invitationForm/invitationForm.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,SAAS;IACT,gBAAgB;AACpB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":["._invitation_form_wrapper {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    gap: 12px;\n    margin-top: 12px;\n}\n._invitation_form_wrapper > div {\n    height: 35px;\n}\n\n._inputs_wrapper {\n    display: flex;\n    align-items: flex-start;\n    gap: 12px;\n}\n\n._submit_button {\n    display: flex;\n    align-items: flex-start;\n    justify-content: space-between;\n    height: 35px;\n}\n\n._submit_button > div {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_invitation_form_wrapper": "invitationForm-module___invitation_form_wrapper___CU7Yx",
	"_inputs_wrapper": "invitationForm-module___inputs_wrapper___srfJi",
	"_submit_button": "invitationForm-module___submit_button___amCv1"
};
export default ___CSS_LOADER_EXPORT___;
