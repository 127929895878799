// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dateTimeShortcut-module__button___7eFzY {\n  padding: 8px;\n  display: flex;\n  justify-content: space-between;\n  align-items: baseline;\n  transition: all 100ms ease-in-out;\n  cursor: pointer;\n  border-radius: 4px;\n}\n\n.dateTimeShortcut-module__button___7eFzY:hover {\n  background-color: var(--lightestBloobirds);\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/dateTimeShortcut/dateTimeShortcut.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;EACrB,iCAAiC;EACjC,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,0CAA0C;AAC5C","sourcesContent":[".button {\n  padding: 8px;\n  display: flex;\n  justify-content: space-between;\n  align-items: baseline;\n  transition: all 100ms ease-in-out;\n  cursor: pointer;\n  border-radius: 4px;\n}\n\n.button:hover {\n  background-color: var(--lightestBloobirds);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "dateTimeShortcut-module__button___7eFzY"
};
export default ___CSS_LOADER_EXPORT___;
