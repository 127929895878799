// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".feedbackStep-module__content___pEpjP {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  margin-bottom: 57px;\n}\n\n.feedbackStep-module__content___pEpjP > svg {\n  margin-bottom: 30px;\n}\n\n.feedbackStep-module__content___pEpjP > p {\n  max-width: 280px;\n  text-align: center;\n}\n\n.feedbackStep-module__actionButtons___7ax1x {\n  display: flex;\n  width: 100%;\n  justify-content: flex-end;\n}\n\n.feedbackStep-module__checkbox___9CwTe {\n  margin-right: 16px;\n  display: flex;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/contactPages/sendToSalesModal/feedbackStep/feedbackStep.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".content {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  margin-bottom: 57px;\n}\n\n.content > svg {\n  margin-bottom: 30px;\n}\n\n.content > p {\n  max-width: 280px;\n  text-align: center;\n}\n\n.actionButtons {\n  display: flex;\n  width: 100%;\n  justify-content: flex-end;\n}\n\n.checkbox {\n  margin-right: 16px;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "feedbackStep-module__content___pEpjP",
	"actionButtons": "feedbackStep-module__actionButtons___7ax1x",
	"checkbox": "feedbackStep-module__checkbox___9CwTe"
};
export default ___CSS_LOADER_EXPORT___;
