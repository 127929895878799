import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTestRuns } from '@bloobirds-it/copilot';
import {
  Button,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Spinner,
  Text,
} from '@bloobirds-it/flamingo-ui';
import { useLoadingPromise } from '@bloobirds-it/hooks';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  APP_CL_ACTIVITIES,
  CopilotActivityType,
  CopilotInsightVersion,
} from '@bloobirds-it/types';
import clsx from 'clsx';

import { useEntity, useRouter } from '../../../../hooks';
import {
  getBobjectTableColumns,
  getBobjectTableFilters,
  getFieldIdByLogicRole,
} from '../../../../utils/bobjects.utils';
import { useInsights } from '../../hooks/useInsights';
import { useSetsData } from '../../hooks/useSetsData';
import styles from '../../playgroundPages.module.css';
import { getColumnsAndFiltersByActivityType } from '../../utils';
import { ActivityTypeIcon } from '../activityTypeIcon/activityTypeIcon';

export const NewRunModal = ({
  selectedVersion,
  onClose,
}: {
  selectedVersion: CopilotInsightVersion;
  onClose: () => void;
}) => {
  const [selectedTestSet, setSelectedTestSet] = useState();
  const { createTestRun } = useTestRuns();
  const { t } = useTranslation('translation', { keyPrefix: 'ai.newRunModal' });
  const { t: baseT } = useTranslation();
  const { testSets, isLoading } = useSetsData();
  const { insights } = useInsights();
  const currentInsight = insights.get(selectedVersion?.insightId);
  const bobjectFields = useEntity('bobjectFields');
  const { push } = useRouter();

  const openActivityTypeReport = (activityType: CopilotActivityType | 'All') => {
    const datetimeFieldId = getFieldIdByLogicRole(
      bobjectFields,
      ACTIVITY_FIELDS_LOGIC_ROLE.CREATION_DATETIME,
    );
    const { columns, filters } = getColumnsAndFiltersByActivityType(activityType);
    const query = `${APP_CL_ACTIVITIES}?query=${getBobjectTableFilters(
      bobjectFields,
      filters,
    )}&columns=${getBobjectTableColumns(
      bobjectFields,
      columns,
    )}&sort=${datetimeFieldId}&direction=DESC`;

    push?.(query);
  };

  const { loading, promiseHandler } = useLoadingPromise();

  async function onCreateTestRun() {
    promiseHandler(
      async () =>
        createTestRun({
          testSetId: selectedTestSet?.pk,
          insightId: selectedVersion.id,
        }),
      {
        errorToast: 'ai.newRunModal.error',
        successToast: 'ai.newRunModal.success',
        onFinally: onClose,
      },
    );
  }

  // Only show test sets that have the same activity type as the insight
  const filteredTestSets = testSets.filter(
    testSet =>
      testSet.activity_type === currentInsight?.activityType ||
      currentInsight?.activityType === 'All',
  );

  return (
    <Modal open onClose={onClose} width={370}>
      <ModalHeader color="white" size="small">
        <ModalTitle size="small" icon="test" color="purple">
          <Text weight="bold">{t('title')}</Text>
        </ModalTitle>
        <ModalCloseIcon color="black" size="small" onClick={onClose} />
      </ModalHeader>
      <ModalContent className={styles.insight_createTestRun}>
        {isLoading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Spinner name="dots" color="purple" size={24} />
          </div>
        ) : filteredTestSets?.length > 0 ? (
          filteredTestSets.map(testSet => {
            const { pk, name, activity_type, activities } = testSet;

            const classNamesTestSet = clsx(styles.testSets_testSet, {
              [styles.active]: selectedTestSet?.pk === pk,
            });

            return (
              <div
                key={pk}
                className={classNamesTestSet}
                onClick={() => setSelectedTestSet(testSet)}
              >
                <Text
                  className={styles.testSets_testSetName}
                  size="s"
                  color="peanut"
                  weight="heavy"
                >
                  {name}
                </Text>
                <div className={styles.testSets_testSetInfo}>
                  <ActivityTypeIcon activityType={activity_type} />
                  <Text size="xs" color="peanut">
                    {Object.keys(activities).length || 0}{' '}
                    {baseT(`ai.insightActivity.${activity_type}`, { count: 2 })}
                  </Text>
                </div>
              </div>
            );
          })
        ) : (
          <div className={styles.testSets_listModal_wrapper}>
            <Text size="s" color="softPeanut" align="center">
              {t('noResults')}
            </Text>
            <Button
              iconRight="externalLink"
              variant="clear"
              color="purple"
              onClick={() => openActivityTypeReport(currentInsight?.activityType)}
              size="small"
              uppercase={false}
            >
              {t('createFromReports')}
            </Button>
          </div>
        )}
      </ModalContent>
      <ModalFooter className={styles.insight_createTestRunFooter}>
        {filteredTestSets?.length > 0 ? (
          <Button
            iconRight="externalLink"
            variant="clear"
            color="purple"
            onClick={() => openActivityTypeReport(currentInsight?.activityType)}
            size="small"
            uppercase={false}
          >
            {t('createNewSet')}
          </Button>
        ) : (
          <div />
        )}
        <Button
          uppercase={false}
          size="small"
          color={!selectedTestSet ? 'veryLightPeanut' : 'purple'}
          onClick={onCreateTestRun}
          disabled={!selectedTestSet}
        >
          {loading ? <Spinner name="loadingCircle" size={16} color="white" /> : t('createButton')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
