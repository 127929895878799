// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttons-module___btnDownload___vZAQE {\n  cursor: pointer;\n  margin-left: 12px;\n}\n\n.buttons-module___btnRevert___Oluh4 {\n  color: var(--tomato);\n}\n\n.buttons-module___btnRevertTextContainer___vcPt8 {\n  display: flex;\n}\n\n.buttons-module___container___59m3c {\n  display: flex;\n  align-items: center;\n}\n\n.buttons-module___textRevert___p6DjS {\n  font-size: 13px;\n  line-height: 16px;\n  margin-left: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/importHistory/table/buttons/buttons.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":["._btnDownload {\n  cursor: pointer;\n  margin-left: 12px;\n}\n\n._btnRevert {\n  color: var(--tomato);\n}\n\n._btnRevertTextContainer {\n  display: flex;\n}\n\n._container {\n  display: flex;\n  align-items: center;\n}\n\n._textRevert {\n  font-size: 13px;\n  line-height: 16px;\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_btnDownload": "buttons-module___btnDownload___vZAQE",
	"_btnRevert": "buttons-module___btnRevert___Oluh4",
	"_btnRevertTextContainer": "buttons-module___btnRevertTextContainer___vcPt8",
	"_container": "buttons-module___container___59m3c",
	"_textRevert": "buttons-module___textRevert___p6DjS"
};
export default ___CSS_LOADER_EXPORT___;
