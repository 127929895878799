import { createParagraph, isHtml } from '@bloobirds-it/utils';
import { createPlateUIEditor, deserializeHtml, serializeHtml, deserializeMd } from '@udecode/plate';

import { checkEmptyText } from './plugins';

export const deserialize = (content, { format = 'AST', plugins = [] } = {}) => {
  if (!content) {
    return undefined;
  }

  if (format === 'Markdown') {
    const editor = createPlateUIEditor({
      plugins,
    });

    const nodes = deserializeMd(editor, content);
    const emptyParagraph = createParagraph('');
    // If the previous node is type p, we add an empty paragraph
    const nodesWithLineBreaks = nodes.reduce((acc, node, index) => {
      if (index > 0 && nodes[index - 1].type === 'p') {
        acc.push(emptyParagraph[0]);
      }
      acc.push(node);
      return acc;
    }, []);

    return nodesWithLineBreaks;
  }

  if (format === 'HTML') {
    const editor = createPlateUIEditor({
      plugins,
    });

    return deserializeHtml(editor, {
      element: content,
    });
  }

  return JSON.parse(content);
};

// This function is only meant for serializing context-free template like when they appear in the card list
export const serialize = (content, { format = 'AST', plugins = [] } = {}) => {
  const editor = createPlateUIEditor({
    plugins,
  });

  if (!content) {
    return '';
  }

  let nodes;
  // For backwards compatibility
  if (format === 'HTML') {
    nodes = deserialize(content, { format, plugins });
  } else {
    nodes = typeof content === 'string' ? JSON.parse(content) : content;
  }

  const html = serializeHtml(editor, { nodes, stripWhitespace: false });
  const raw = html
    .replaceAll(/<div(\s*)?>\s*<\/div>/g, '<br>')
    .replaceAll('&#x27;', "'")
    .replaceAll('&lt;', '<')
    .replaceAll('&gt;', '>')
    .replaceAll('&amp;', '&')
    .replaceAll('&quot;', '"')
    .replaceAll('&apos;', "'");

  return raw;
};

export const isEmptyText = (text: string, plugins = []) => {
  if (isHtml(text)) {
    const textDeserialized = deserialize(text, {
      format: 'HTML',
      plugins: plugins,
    });
    return (
      textDeserialized?.length === 0 ||
      (textDeserialized?.length === 1 && checkEmptyText(textDeserialized[0]))
    );
  }

  return !text || text === 'null' || text?.trim() === '';
};
