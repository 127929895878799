import React, { useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Banner } from '@bloobirds-it/banner';
import {
  Button,
  Icon,
  IconButton,
  IconPicker,
  Input,
  Item,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  Text,
  Tooltip,
} from '@bloobirds-it/flamingo-ui';
import {
  TeamFormType,
  TeamMember,
  TeamModalModes,
  TeamModalProps,
  UserTeamRole,
} from '@bloobirds-it/types';
import { getUserTimeZone } from '@bloobirds-it/utils';
import { v4 as uuid } from 'uuid';

import { useTimeZones } from '../../../../../../../hooks/useTimeZones';
import { useTeamsManagement } from '../../hooks/useTeamsManagement';
import { ModalBlock, ModalBlockBody, ModalBlockHeader, ModalTeamSection } from '../modalBlocks';
import { TeamMemberCard } from '../teamMemberCard';
import { ConfirmDeleteTeamModal } from './components/confirmDeleteTeamModal';
import { ConfirmDeleteUserModal } from './components/confirmDeleteUserModal';
import { UserCreationRow } from './components/userCreationRow';
import styles from './teamManagement.module.css';

const orderTeamUsers = (teamMembers: Array<TeamMember>) => {
  const teamMembersCopy = [...teamMembers];

  return teamMembersCopy?.sort((a, b) => {
    // First, compare by userRole
    if (a.userRole === UserTeamRole.Manager && b.userRole !== UserTeamRole.Manager) {
      return -1;
    }
    if (a.userRole !== UserTeamRole.Manager && b.userRole === UserTeamRole.Manager) {
      return 1;
    }

    // If userRoles are the same, compare by userName
    return a.userName.localeCompare(b.userName);
  });
};

interface ITeamManagementModalProps {
  team: TeamModalProps;
  toggleVisibility: () => void;
  onSave?: () => void;
}

export const TeamManagementModal = ({
  team,
  toggleVisibility,
  onSave,
}: ITeamManagementModalProps) => {
  const [userRowsIds, setUserRowsIds] = useState<string[]>(
    team?.mode === TeamModalModes.Create ? [uuid()] : [],
  );

  const removeUserRow = (rowId: string) => {
    const cleanedRows = userRowsIds?.filter(id => id !== rowId);
    setUserRowsIds(cleanedRows);
  };
  const methods = useForm<TeamFormType>({ defaultValues: team });
  const { control, getValues, errors } = methods;
  const formValues = getValues();
  const isEditing = team.mode === TeamModalModes.Edition;
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.salesTeam.teamUsersPage.teamManagementModal',
  });
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const addUserRow = () => {
    setUserRowsIds(userRowsIds => [...userRowsIds, uuid()]);
    if (scrollRef?.current) {
      // add this setTimeout with no delay to force the scroll to execute in the next execution cycle guaranteing that the DOM
      // has updated otherwise it misses the exact amount of one row
      setTimeout(() => {
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollHeight,
          behavior: 'smooth',
        });
      });
    }
  };
  const timeZones = useTimeZones();
  const selectedTimezone = getUserTimeZone();
  const { handleFormSubmit, handleDeleteTeam } = useTeamsManagement(onSave);
  const [teamWithoutManager, setTeamWithoutManager] = useState(false);
  const [missingInfo, setMissingInfo] = useState(false);
  const [noUsers, setNoUsers] = useState(false);
  const [confirmDeleteUserModal, setConfirmDeleteUserModal] = useState<{
    open: boolean;
    onSave?: () => void;
  }>({ open: false });
  const [confirmDeleteTeamModal, setConfirmDeleteTeamModal] = useState<{
    open: boolean;
    onSave?: () => void;
  }>({ open: false });

  const openConfirmDeleteModal = (onSave: () => void) => {
    setConfirmDeleteUserModal({ open: true, onSave });
  };

  const {
    field: { value: teamUsers },
  } = useController({
    name: 'teamUsers',
    defaultValue: [],
    control,
  });

  const {
    field: { value: newTeamUsersValue, onChange: newTeamUsersOnChange },
  } = useController({
    name: 'newTeamUsers',
    defaultValue: {},
    control,
  });

  useEffect(() => {
    const newUsersArray: TeamMember[] = Object.values(newTeamUsersValue);
    const allUsersInTeam: TeamMember[] = [...teamUsers, ...newUsersArray];

    setNoUsers(userRowsIds?.length === 0 && allUsersInTeam?.length === 0);

    setTeamWithoutManager(
      allUsersInTeam?.length > 0 &&
        !allUsersInTeam?.some(u => u?.userRole === UserTeamRole.Manager),
    );

    setMissingInfo(teamUsers?.length === 0 && newUsersArray?.length < userRowsIds?.length);
  }, [teamUsers, newTeamUsersValue, userRowsIds]);

  return (
    <FormProvider {...methods}>
      <Modal open width="800px" onClose={toggleVisibility} className={styles.teamManagementModal}>
        <ModalHeader size="small">
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Icon name="edit" color="bloobirds" size={16} />
            <Text size="s">{isEditing ? t('editTeam') : t('createTeam')}</Text>
          </div>
          <IconButton name="cross" onClick={toggleVisibility} />
        </ModalHeader>
        <ModalContent className={styles.modalContentWrapper}>
          <div ref={scrollRef} className={styles['scroll-container']}>
            <ModalBlock>
              <ModalBlockHeader title={t('teamInformation')} icon="info" />
              <ModalBlockBody>
                <ModalTeamSection title={t('icon')}>
                  <Controller
                    name="icon"
                    control={control}
                    defaultValue={team?.icon ?? 'people'}
                    render={field => (
                      <div className={styles.iconPicker} id="icon-picker">
                        <IconPicker {...field} defaultValue="bloobirds" size={24} />
                      </div>
                    )}
                  />
                </ModalTeamSection>
                <ModalTeamSection title={t('name')} expand>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true, maxLength: 48 }}
                    render={field => (
                      <Input
                        {...field}
                        placeholder={t('nameInput')}
                        error={errors.name && t('nameRequired')}
                        errorStyle={!!errors.name}
                        width="100%"
                      />
                    )}
                  />
                </ModalTeamSection>
                <ModalTeamSection title={t('timezone')}>
                  <Controller
                    name="timezone"
                    control={control}
                    defaultValue={team?.timezone ?? selectedTimezone}
                    render={field => (
                      <Select {...field} autocomplete width="230px">
                        {timeZones?.map((tz: any) => (
                          <Item
                            label={tz.location}
                            key={tz.location}
                            value={tz.location}
                            onClick={value => field?.onChange(value)}
                          >
                            {tz.location}
                          </Item>
                        ))}
                      </Select>
                    )}
                  />
                </ModalTeamSection>
              </ModalBlockBody>
            </ModalBlock>
            <ModalBlock>
              <ModalBlockHeader title={t('teamMembers')} icon="people">
                <Button iconLeft="plus" variant="secondary" size="small" onClick={addUserRow}>
                  {t('add')}
                </Button>
              </ModalBlockHeader>
              {teamWithoutManager && (
                <div className={styles.banner}>
                  <Banner type="softWarning" icon="alertTriangle">
                    {t('noManagerBanner')}
                  </Banner>
                </div>
              )}
              <ModalBlockBody>
                <div className={styles.membersListWrapper}>
                  <div className={styles.membersListHeader}>
                    <Text size="m" weight="bold">
                      {t('user')}
                    </Text>
                    <Text size="m" weight="bold">
                      {t('role')}
                    </Text>
                  </div>
                  <div className={styles.membersList}>
                    {noUsers && (
                      <Text size="s" color="softTomato" align="center">
                        {t('noUsers')}
                      </Text>
                    )}
                    {isEditing &&
                      orderTeamUsers(formValues?.teamUsers || [])?.map(user => (
                        <TeamMemberCard
                          key={user.userId}
                          user={user}
                          openConfirmDeleteModal={openConfirmDeleteModal}
                        />
                      ))}
                    {userRowsIds?.map(rowId => (
                      <UserCreationRow
                        key={rowId}
                        rowId={rowId}
                        remove={() => removeUserRow(rowId)}
                        newTeamUsersValue={newTeamUsersValue}
                        newTeamUsersOnChange={newTeamUsersOnChange}
                      />
                    ))}
                  </div>
                </div>
              </ModalBlockBody>
              <div className={styles.addTeamMemberButton}>
                <Button variant="clear" iconLeft="plus" uppercase={false} onClick={addUserRow}>
                  {t('addTeamMember')}
                </Button>
              </div>
            </ModalBlock>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button onClick={toggleVisibility} variant="clear">
            {t('cancel')}
          </Button>
          <div className={styles.bottomButtons}>
            {team?.mode === TeamModalModes.Edition && (
              <Button
                variant="clear"
                color="tomato"
                onClick={() =>
                  setConfirmDeleteTeamModal({
                    open: true,
                    onSave: () => {
                      handleDeleteTeam();
                      setConfirmDeleteTeamModal({ open: false });
                    },
                  })
                }
              >
                {t('delete')}
              </Button>
            )}
            <Tooltip title={(teamWithoutManager || missingInfo) && t('saveTooltip')} position="top">
              <Button
                onClick={!noUsers && methods.handleSubmit(handleFormSubmit)}
                disabled={teamWithoutManager || missingInfo || noUsers}
              >
                {t('save')}
              </Button>
            </Tooltip>
          </div>
        </ModalFooter>
      </Modal>
      <ConfirmDeleteUserModal
        confirmDeleteModal={confirmDeleteUserModal}
        onClose={() => setConfirmDeleteUserModal({ open: false })}
      />
      <ConfirmDeleteTeamModal
        confirmDeleteModal={confirmDeleteTeamModal}
        onClose={() => setConfirmDeleteTeamModal({ open: false })}
      />
    </FormProvider>
  );
};
