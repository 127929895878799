// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".transition-module___fade_enter___iq93G {\n  opacity: 0;\n}\n.transition-module___fade_enter_active___iV7A8 {\n  opacity: 1;\n  transition: opacity 300ms;\n}\n\n.transition-module___fade_exit___v4G5d {\n  opacity: 1;\n}\n.transition-module___fade_exit_active___qgoi0 {\n  opacity: 0;\n  transition: opacity 300ms;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/transition/transition.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;AACA;EACE,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,UAAU;AACZ;AACA;EACE,UAAU;EACV,yBAAyB;AAC3B","sourcesContent":["._fade_enter {\n  opacity: 0;\n}\n._fade_enter_active {\n  opacity: 1;\n  transition: opacity 300ms;\n}\n\n._fade_exit {\n  opacity: 1;\n}\n._fade_exit_active {\n  opacity: 0;\n  transition: opacity 300ms;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_fade_enter": "transition-module___fade_enter___iq93G",
	"_fade_enter_active": "transition-module___fade_enter_active___iV7A8",
	"_fade_exit": "transition-module___fade_exit___v4G5d",
	"_fade_exit_active": "transition-module___fade_exit_active___qgoi0"
};
export default ___CSS_LOADER_EXPORT___;
