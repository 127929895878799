// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".noPermissionsPage-module___wrapper___GOaJu {\n  margin: 80px 36px;\n  display: flex;\n  position: relative;\n  flex-grow: 1;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  height: calc(100% - 160px);\n}\n\n.noPermissionsPage-module___image___kk5Rx {\n  margin-bottom: 45px;\n  width: 140px;\n  height: 138px;\n}\n\n.noPermissionsPage-module___text___M0TSB {\n  width: 684px;\n  font-size: 25px;\n  line-height: 1.28;\n  color: var(--peanut);\n  margin-bottom: 28px;\n}\n\n.noPermissionsPage-module___button___TzYZ\\+ {\n  width: 336px;\n  height: 40px;\n  border-radius: 6px;\n  background-color: var(--bloobirds);\n  color: var(--white);\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/noPermissionsPage/noPermissionsPage.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,uBAAuB;EACvB,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kCAAkC;EAClC,mBAAmB;AACrB","sourcesContent":["._wrapper {\n  margin: 80px 36px;\n  display: flex;\n  position: relative;\n  flex-grow: 1;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  height: calc(100% - 160px);\n}\n\n._image {\n  margin-bottom: 45px;\n  width: 140px;\n  height: 138px;\n}\n\n._text {\n  width: 684px;\n  font-size: 25px;\n  line-height: 1.28;\n  color: var(--peanut);\n  margin-bottom: 28px;\n}\n\n._button {\n  width: 336px;\n  height: 40px;\n  border-radius: 6px;\n  background-color: var(--bloobirds);\n  color: var(--white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_wrapper": "noPermissionsPage-module___wrapper___GOaJu",
	"_image": "noPermissionsPage-module___image___kk5Rx",
	"_text": "noPermissionsPage-module___text___M0TSB",
	"_button": "noPermissionsPage-module___button___TzYZ+"
};
export default ___CSS_LOADER_EXPORT___;
