// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".emptyCard-module___card___bclKF {\n  background-color: var(--white);\n  width: 100%;\n  padding: 18px 0;\n  border: 1px solid var(--veryLightBloobirds);\n  box-sizing: border-box;\n  height: 60px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/contactPages/tasksBox/emptyCard/emptyCard.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,WAAW;EACX,eAAe;EACf,2CAA2C;EAC3C,sBAAsB;EACtB,YAAY;AACd","sourcesContent":["._card {\n  background-color: var(--white);\n  width: 100%;\n  padding: 18px 0;\n  border: 1px solid var(--veryLightBloobirds);\n  box-sizing: border-box;\n  height: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_card": "emptyCard-module___card___bclKF"
};
export default ___CSS_LOADER_EXPORT___;
