import React, { useEffect } from 'react';

import { Tab, TabGroup } from '@bloobirds-it/flamingo-ui';

import AddQcToLeadModal from '../../../../../../../components/addQcToLeadModal/addQcToLeadModal';
import { AddQcCompanyTable, LeadTableWithNoQc } from '../../../../../../../components/bobjectTable';
import { TargetMarketTabs } from '../../../../../../../components/entityTabs/entityTabs.view';
import styles from './addQcTask.module.css';
import { useRouter } from "@bloobirds-it/hooks";

const TABS = ['Companies to qualify', 'Leads without QC'];

const AddQcTask = props => {
  const {
    activeTab,
    modalAddQcOpen,
    handleResetView,
    changeTab,
    handleCloseModalAddQc,
    leadId,
  } = props;
  const { history, location } = useRouter();

  useEffect(() => {
    history.replace({ ...location, search: null });
  }, []);

  return (
    <div className={styles._container}>
      <TargetMarketTabs />
      <div className={styles._tab__container}>
        <TabGroup
          value={TABS[activeTab]}
          onClick={value => {
            const tabIndex = TABS.findIndex(tab => tab === value);
            history.replace({ ...location, search: null });
            changeTab(tabIndex);
          }}
        >
          <Tab iconLeft="briefcaseOutline" name="Companies to qualify">
            <AddQcCompanyTable companyStatus="COMPANY__STATUS__NEW" />
          </Tab>
          <Tab
            iconLeft="people"
            name="Leads without QC"
            dataTest="Leads without QC"
            onClick={handleResetView}
          >
            <LeadTableWithNoQc />
            {modalAddQcOpen && (
              <AddQcToLeadModal open handleClose={handleCloseModalAddQc} leadId={leadId} />
            )}
          </Tab>
        </TabGroup>
      </div>
    </div>
  );
};

export default AddQcTask;
