export const headerNames = [
  {
    label: 'Name',
  },
  {
    label: 'Description',
  },
  {
    label: 'Status',
  },
];
