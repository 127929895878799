// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".messagingTemplateSidebar-module__sidebar___cxzr\\+ {\n  box-sizing: border-box;\n  background-color: var(--lightestBloobirds);\n  min-width: 300px;\n  max-width: 386px;\n  padding: 40px 24px;\n  overflow-y: auto;\n}\n\n.messagingTemplateSidebar-module__header___OPdmt {\n  margin-bottom: 16px;\n}\n\n.messagingTemplateSidebar-module__section___WTuCg {\n  margin-bottom: 56px;\n}\n\n.messagingTemplateSidebar-module__description___ePSh4 {\n  margin-top: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/messagingTemplates/messagingTemplateSidebar/messagingTemplateSidebar.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,0CAA0C;EAC1C,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".sidebar {\n  box-sizing: border-box;\n  background-color: var(--lightestBloobirds);\n  min-width: 300px;\n  max-width: 386px;\n  padding: 40px 24px;\n  overflow-y: auto;\n}\n\n.header {\n  margin-bottom: 16px;\n}\n\n.section {\n  margin-bottom: 56px;\n}\n\n.description {\n  margin-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": "messagingTemplateSidebar-module__sidebar___cxzr+",
	"header": "messagingTemplateSidebar-module__header___OPdmt",
	"section": "messagingTemplateSidebar-module__section___WTuCg",
	"description": "messagingTemplateSidebar-module__description___ePSh4"
};
export default ___CSS_LOADER_EXPORT___;
