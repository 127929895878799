// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".taskModalWrapper__container___zX\\+sY {\n  padding: 18px 8px;\n  background-color: var(--white);\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/taskModalWrapper/taskModalWrapper.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,8BAA8B;AAChC","sourcesContent":[".container {\n  padding: 18px 8px;\n  background-color: var(--white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "taskModalWrapper__container___zX+sY"
};
export default ___CSS_LOADER_EXPORT___;
