import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@bloobirds-it/flamingo-ui';

import { useRequestNumberFormContext } from '../../../context/requestedPhonesFormProvider';
import styles from '../../../styles/phoneNumberPage.module.css';
import { TagsCard } from '../../rows/tagsCard';
import { useDirtyContext } from '../shared/dirtyContext';

export const InputTag = () => {
  const { t } = useTranslation();
  const { phoneNumber, handleChangePhone } = useRequestNumberFormContext();
  const [inputValue, setInputValue] = useState('');
  const { setIsDirty } = useDirtyContext();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'Enter' || e.key === ' ') && inputValue.trim() !== '') {
      e.preventDefault();
      if (!phoneNumber.prefix.includes(inputValue.trim())) {
        const phoneNumberPrefixes = [...phoneNumber.prefix, inputValue.trim()];
        handleChangePhone('prefix', phoneNumberPrefixes);
      }
      setInputValue('');
    }
  };

  const removeAllTags = () => {
    handleChangePhone('prefix', []);
  };

  return (
    <div className={styles.inputTagContainer}>
      <div className={styles.inputWithTags}>
        {phoneNumber.prefix.map((tag, index) => (
          <TagsCard key={tag} label={tag} />
        ))}
        <input
          className={styles.inputField}
          type="text"
          value={inputValue}
          onBlur={() => {
            if (inputValue.trim() === '') {
              return;
            }
            handleChangePhone('prefix', [...phoneNumber.prefix, inputValue.trim()]);
            setInputValue('');
            setIsDirty(true);
          }}
          onChange={e => {
            setInputValue(e.target.value);
            setIsDirty(true);
          }}
          onKeyDown={handleKeyDown}
          placeholder={
            phoneNumber.prefix?.length > 0
              ? null
              : t('accountSettings.phoneNumbers.purchaseNewPhone.orderReview.prefix')
          }
        />
      </div>
      {phoneNumber.prefix?.length > 0 && (
        <IconButton
          className={styles.removeTags}
          color="softPeanut"
          name="trashFull"
          onClick={() => removeAllTags()}
        />
      )}
    </div>
  );
};
