// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".field-module__field___F-xOS {\n  margin-right: 4px;\n  display: flex;\n}\n\n.field-module__field___F-xOS svg {\n  height: 16px;\n}\n\n.field-module__field___F-xOS svg > * {\n  fill: var(--softPeanut);\n}\n\n.field-module__fixedHeightField___CedPf {\n  height: 24px;\n}\n\n.field-module__fieldContent___9rEC2 {\n  margin-left: 8px;\n  font-size: 14px;\n  line-height: 18px;\n  display: inline-block;\n}\n\n.field-module__fieldContentUrl___dbCy6 {\n  margin: 0;\n  width: 164px;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.field-module__fieldContentEllipsis___AbYU2 {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/filter/field/field.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,SAAS;EACT,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".field {\n  margin-right: 4px;\n  display: flex;\n}\n\n.field svg {\n  height: 16px;\n}\n\n.field svg > * {\n  fill: var(--softPeanut);\n}\n\n.fixedHeightField {\n  height: 24px;\n}\n\n.fieldContent {\n  margin-left: 8px;\n  font-size: 14px;\n  line-height: 18px;\n  display: inline-block;\n}\n\n.fieldContentUrl {\n  margin: 0;\n  width: 164px;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.fieldContentEllipsis {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": "field-module__field___F-xOS",
	"fixedHeightField": "field-module__fixedHeightField___CedPf",
	"fieldContent": "field-module__fieldContent___9rEC2",
	"fieldContentUrl": "field-module__fieldContentUrl___dbCy6",
	"fieldContentEllipsis": "field-module__fieldContentEllipsis___AbYU2"
};
export default ___CSS_LOADER_EXPORT___;
