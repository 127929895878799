import { PickableIcon } from '../../../../components/iconPicker/iconPicker';

export const pickableIcons: PickableIcon[] = [
  { name: 'customTask', color: 'bloobirds' },
  { name: 'whatsapp', color: 'extraCall' },
  { name: 'linkedin', color: 'darkBloobirds' },
  { name: 'calendar', color: 'tomato' },
  { name: 'noteAction', color: 'banana' },
  { name: 'person', color: 'tangerine' },
  { name: 'chat', color: 'purple' },
  { name: 'postalMail', color: 'softPeanut' },
  { name: 'calendarphone', color: 'grape' },
  { name: 'checkDouble', color: 'extraCall' },
  { name: 'bell', color: 'bloobirds' },
  { name: 'starUnchecked', color: 'banana' },
  { name: 'location', color: 'tomato' },
  { name: 'mail', color: 'tangerine' },
  { name: 'pitch', color: 'lightPurple' },
  { name: 'fileOpportunity', color: 'extraCall' },
];
