/* BOBJECT TABLE */
export const BOBJECT_TABLE_LOADED = 'BOBJECT_TABLE_LOADED';
export const BOBJECT_TABLE_RESET = 'BOBJECT_TABLE_RESET';
export const BOBJECT_TABLE_RESET_CONFIG = 'BOBJECT_TABLE_CONFIG';
export const BOBJECT_TABLE_CLEAN_FILTERS = 'BOBJECT_TABLE_CLEAN_FILTERS';
export const BOBJECT_TABLE_SET_COLUMNS = 'BOBJECT_TABLE_SET_COLUMNS';
export const BOBJECT_TABLE_SET_QUERY = 'BOBJECT_TABLE_SET_QUERY';
export const BOBJECT_TABLE_SET_VIEW = 'BOBJECT_TABLE_SET_VIEW';
export const BOBJECT_TABLE_SET_VIEW_ID = 'BOBJECT_TABLE_SET_VIEW_ID';
export const BOBJECT_TABLE_SET_SORT = 'BOBJECT_TABLE_SET_SORT';
export const BOBJECT_TABLE_SET_VIEWTYPE = 'BOBJECT_TABLE_SET_VIEWTYPE';
export const BOBJECT_TABLE_SET_EDITION_MODE = 'BOBJECT_TABLE_SET_EDITION_MODE';

/* SAVE VIEW */
export const BOBJECT_TABLE_RETRIEVE_VIEW_SUCCESS = 'BOBJECT_TABLE_RETRIEVE_VIEW_SUCCESS';
export const BOBJECT_TABLE_RETRIEVE_VIEWS = 'BOBJECT_TABLE_RETRIEVE_VIEWS';
export const BOBJECT_TABLE_OPEN_SAVE_MODAL_VIEW = 'BOBJECT_TABLE_OPEN_SAVE_MODAL_VIEW';
export const BOBJECT_TABLE_CLOSE_SAVE_MODAL_VIEW = 'BOBJECT_TABLE_CLOSE_SAVE_MODAL_VIEW';
export const BOBJECT_TABLE_CHANGE_NAME_VIEW = 'BOBJECT_TABLE_CHANGE_NAME_VIEW';
export const BOBJECT_TABLE_EDIT_VIEW = 'BOBJECT_TABLE_EDIT_VIEW';
export const BOBJECT_TABLE_RESET_FILTER_COLUMNS = 'BOBJECT_TABLE_RESET_FILTER_COLUMNS';
export const BOBJECT_TABLE_CHANGE_VISIBILITY = 'BOBJECT_TABLE_CHANGE_VISIBILITY';
export const BOBJECT_INBOUND_TABLE_RESET_VIEW = 'BOBJECT_INBOUND_TABLE_RESET_VIEW';
export const BOBJECT_TABLE_UPDATE_PARTIAL_STATE = 'BOBJECT_TABLE_UPDATE_PARTIAL_STATE';

/* ENTITY TABS */
export const ENTITY_TABS_REQUEST_SUCCESS = 'ENTITY_TABS_REQUEST_SUCCESS';
export const ENTITY_TABS_SWITCH_TAB = 'ENTITY_TABS_SWITCH_TAB';
export const ENTITY_TABS_SHOW = 'ENTITY_TABS_SHOW';
export const ENTITY_TABS_HIDE = 'ENTITY_TABS_HIDE';
export const ENTITY_TABS_RESET = 'ENTITY_TABS_RESET';

export const CONTACT_TASK_SET_ACTIVITY_FILTER = 'CONTACT_TASK_SET_ACTIVITY_FILTER';
export const CONTACT_TASK_RESET_ACTIVITY_FILTER = 'CONTACT_TASK_RESET_ACTIVITY_FILTER';
export const WEBSOCKET_MESSAGE_INCOMING = 'WEBSOCKET_MESSAGE_INCOMING';
