// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".seekBar-module__seekBar___CkalP {\n  width: 100%;\n  cursor: pointer;\n  -webkit-appearance: none;\n  appearance: none;\n  cursor: pointer;\n  outline: none;\n  border-radius: 15px;\n  height: 6px;\n  transition: all 0.2s ease;\n}\n\n.seekBar-module__seekBar___CkalP::-webkit-slider-thumb {\n  -webkit-appearance: none;\n  appearance: none;\n  /* creating a custom design */\n  width: 1px;\n  height: 6px;\n}\n\n.seekBar-module__seekBarContainer___43Ye5 {\n  display: flex;\n  flex-direction: column;\n}\n\n.seekBar-module__text___fHV1a {\n  padding-left: 2px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/aiAnalysisPages/leftSideAiAnalysis/mediaAiAnalysis/timelineArea/seekBar/seekBar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,wBAAwB;EACxB,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;EACxB,gBAAgB;EAChB,6BAA6B;EAC7B,UAAU;EACV,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".seekBar {\n  width: 100%;\n  cursor: pointer;\n  -webkit-appearance: none;\n  appearance: none;\n  cursor: pointer;\n  outline: none;\n  border-radius: 15px;\n  height: 6px;\n  transition: all 0.2s ease;\n}\n\n.seekBar::-webkit-slider-thumb {\n  -webkit-appearance: none;\n  appearance: none;\n  /* creating a custom design */\n  width: 1px;\n  height: 6px;\n}\n\n.seekBarContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n.text {\n  padding-left: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"seekBar": "seekBar-module__seekBar___CkalP",
	"seekBarContainer": "seekBar-module__seekBarContainer___43Ye5",
	"text": "seekBar-module__text___fHV1a"
};
export default ___CSS_LOADER_EXPORT___;
