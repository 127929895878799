import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Text } from '@bloobirds-it/flamingo-ui';
import { GeneralPrompt } from '@bloobirds-it/types';

import styles from '../../playgroundPages.module.css';
import { GeneralPromptsColumnCard } from './generalPromptsColumnCard';

export interface IGeneralPromptsColumn {
  setSelectedPrompt: React.Dispatch<React.SetStateAction<GeneralPrompt>>;
  selectedPrompt: GeneralPrompt | null;
  accountPrompts: Map<string, GeneralPrompt>;
}
export const GeneralPromptsColumn = (props: IGeneralPromptsColumn) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className={styles.generalPromptColumnTitle}>
        <Text color="softPeanut" size="s" weight="bold" className={styles.textWithIcon}>
          <Icon name="suggestions" color="softPeanut" size={20} />
          {t('ai.playground.generalPromptsTab.promptsColumnTitle')}
        </Text>
      </div>
      <div className={styles.generalPromptColumnContainer}>
        {[...props.accountPrompts.values()].map(prompt =>
          !prompt.promptType.includes(':') ? (
            <GeneralPromptsColumnCard key={prompt.id} prompt={prompt} {...props} />
          ) : null,
        )}
      </div>
    </div>
  );
};
