import React from 'react';
import { useTranslation } from 'react-i18next';

import { useActivityFieldsInfo } from '@bloobirds-it/copilot';
import { Text, Icon, Tooltip, IconButton } from '@bloobirds-it/flamingo-ui';
import { useLoadingPromise, useTogglerState } from '@bloobirds-it/hooks';
import { GeneralPrompt } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import clsx from 'clsx';
import { mutate } from 'swr';

import styles from '../../playgroundPages.module.css';
import { ConfirmationDeleteModal } from '../deleteConfirmationModal';
import { IGeneralPromptsSubTypesColumn } from './generalPromptsSubtypesColumn';

interface IGeneralPromptsSubTypeColumnCardProps extends IGeneralPromptsSubTypesColumn {
  prompt: GeneralPrompt;
}
export const GeneralPromptsSubTypeColumnCard = ({
  prompt,
  selectedSubType,
  setSelectedSubType,
}: IGeneralPromptsSubTypeColumnCardProps) => {
  const { t } = useTranslation();
  const { meetingTypes } = useActivityFieldsInfo();
  const { loading, promiseHandler } = useLoadingPromise();
  const [openDeleteModal, toggleDeleteModal] = useTogglerState(false);
  if (!prompt) return null;

  const [, activityTypeLogicRole, activitySubtypeId] = prompt.promptType.split(':');

  const deleteGeneralPrompt = (promptType: string) =>
    api.delete<GeneralPrompt>(`/utils/service/prompts/subtype/${promptType}`);

  const handleDelete = () => {
    promiseHandler(() => deleteGeneralPrompt(prompt.promptType), {
      onSuccess: () => {
        mutate('account-prompts');
        toggleDeleteModal();
      },
      successToast: 'ai.playground.generalPromptsTab.deletePrompt.success',
      errorToast: 'ai.playground.generalPromptsTab.deletePrompt.error',
    });
  };
  const subTypeName = meetingTypes.get(activitySubtypeId);

  return (
    <>
      <div
        className={clsx(styles.generalPromptCard, {
          [styles.generalPromptCardActive]: prompt.id === selectedSubType?.id,
        })}
        onClick={() => setSelectedSubType(prompt)}
      >
        <Text color="peanut" size="s" className={styles.textWithIcon}>
          <Icon name="gridSquares" size={16} color="softPeanut" />
          <strong>{t('ai.playground.header.activityType')}: </strong>
          {t(`ai.insightActivity.${activityTypeLogicRole}`, { count: 1 })}
        </Text>
        <Text color="peanut" size="s" className={styles.textWithIcon}>
          <Icon name="bookmark" size={16} color="softPeanut" />
          <strong>{t('common.subType')}: </strong>
          {subTypeName?.name || t('ai.insightFormModal.activityType.options.All')}
        </Text>
        <div className={styles.deleteButtonContainer}>
          <IconButton
            className={styles.testSets_testSetIcon}
            name="trashFull"
            size={16}
            color="softPurple"
            onClick={e => {
              e.stopPropagation();
              toggleDeleteModal();
            }}
          />
        </div>
      </div>
      {openDeleteModal && (
        <ConfirmationDeleteModal
          title="ai.playground.generalPromptsTab.deletePrompt.title"
          description="ai.playground.generalPromptsTab.deletePrompt.description"
          question="ai.playground.generalPromptsTab.deletePrompt.question"
          onClose={toggleDeleteModal}
          onConfirm={handleDelete}
          loading={loading}
        />
      )}
    </>
  );
};

export const DefaultPromptCard = ({
  prompt,
  selectedSubType,
  setSelectedSubType,
}: IGeneralPromptsSubTypeColumnCardProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(styles.generalPromptCard, {
        [styles.generalPromptCardActive]: prompt.id === selectedSubType?.id,
      })}
      style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
      onClick={() => setSelectedSubType(prompt)}
    >
      <div className={styles.defaultPromptCardTitle}>
        <Text size="s" weight="bold">
          {t('common.default')}
        </Text>

        <Tooltip title={t('ai.playground.generalPromptsTab.defaultPromptTooltip')} position="top">
          <Icon color="softPeanut" size={16} name="lock" />
        </Tooltip>
      </div>
      <Text color="softPeanut" size="xxs" className={styles.textWithIcon}>
        <Icon name="infoFilled" size={16} color="softPeanut" />
        {t('ai.playground.generalPromptsTab.defaultPromptInfo')}
      </Text>
    </div>
  );
};
