import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCopilotActivity } from '@bloobirds-it/copilot';
import { Button, Icon, Spinner, Text } from '@bloobirds-it/flamingo-ui';

import styles from './insights.module.css';

function NoResultsFound() {
  const { t } = useTranslation();
  const { activityInsights, generateAnalysis } = useCopilotActivity();
  const isGenerating = activityInsights?.status === 'GENERATING';

  return (
    <div className={styles.noResults}>
      <Icon name="searchNone" size={32} color="softPeanut" />
      <Text size="m">{t('ai.aiAnalysis.noInsights')}</Text>
      <Text size="s" color="softPeanut" align="center">
        {t('ai.aiAnalysis.noInsightsDescription')}
      </Text>
      <Text size="s" color="softPeanut" align="center">
        {t('ai.aiAnalysis.noInsightsDescriptionTry')}
      </Text>
      {isGenerating ? (
        <Spinner name="dots" color="purple" size={24} />
      ) : (
        <Button
          disabled={isGenerating}
          color="purple"
          uppercase={false}
          size="small"
          variant="secondary"
          // @ts-ignore
          iconLeft={isGenerating ? '' : 'undoRevert'}
          onClick={generateAnalysis}
          className={styles.generateButton}
        >
          <>{t('ai.aiAnalysis.noInsightsButton')}</>
        </Button>
      )}
    </div>
  );
}

export default NoResultsFound;
