import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Text } from '@bloobirds-it/flamingo-ui';

import { CallDistribution } from '../components/advancedSettings/callDistribution';
import { CallRecording } from '../components/advancedSettings/callRecording';
import styles from '../styles/phoneNumberPage.module.css';

export const AdvancedSettings = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.generalSettingsContainer}>
      <>
        <Text className={styles.titleGeneral} size="l" color="peanut" weight="medium">
          <Icon name="agendaPerson" size={24} color="bloobirds" />
          {t(
            'accountSettings.phoneNumbers.phoneSettings.tabs.advancedSettings.sectionCallDistribution.title',
          )}
        </Text>
        <CallDistribution />
      </>

      <div className={styles.itemGeneral}>
        <Text className={styles.titleGeneral} size="l" color="peanut" weight="medium">
          <Icon name="voicemail" size={24} color="bloobirds" />
          {t(
            'accountSettings.phoneNumbers.phoneSettings.tabs.advancedSettings.sectionCallRecording.title',
          )}
        </Text>
        <CallRecording />
      </div>
    </div>
  );
};
