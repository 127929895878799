import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  Callout,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  useToasts,
  Text,
  Spinner,
} from '@bloobirds-it/flamingo-ui';
import { useAccountId, useBobject, useTogglerState } from '@bloobirds-it/hooks';
import { LEAD_FIELDS_LOGIC_ROLE, COMPANY_FIELDS_LOGIC_ROLE } from '@bloobirds-it/types';
import { getValueFromLogicRole } from '@bloobirds-it/utils';

import styles from '../contactViewActions.module.css';
import { ActionComponentProps } from '../types';
import { CompanySelector } from './companySelector/companySelector';

interface IReassignCompanyProps extends ActionComponentProps {
  type: 'ASSIGN' | 'REASSIGN';
}

const AssignToCompany = ({
  activeBobject,
  closeAction,
  refreshActiveBobject,
  type,
}: IReassignCompanyProps) => {
  const { t } = useTranslation();
  const accountId = useAccountId();
  const { searchForBobject } = useBobject('Company', accountId);
  const { patchBobject } = useBobject('Lead', accountId);
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, toggle] = useTogglerState(false);
  const { createToast } = useToasts();

  const assignToNewCompany = async () => {
    if (!companyId || !activeBobject) return;
    setLoading(true);
    try {
      const data = {
        [LEAD_FIELDS_LOGIC_ROLE.COMPANY]: companyId,
      };
      await patchBobject(activeBobject.id.objectId, data);
      createToast({ type: 'success', message: t('reassignCompanyModal.successToast') });
      refreshActiveBobject();
      closeAction();
    } catch (e) {
      createToast({ type: 'error', message: t('reassignCompanyModal.errorToast') });
    } finally {
      setLoading(false);
    }
  };

  const searchCompanies = async (searchValue: string) => {
    const payload = await searchForBobject({
      COMPANY__NAME: {
        query: [searchValue],
        searchMode: 'AUTOCOMPLETE__SEARCH',
      },
    });
    return payload.data.contents.map(company => ({
      id: company.id.value,
      name: getValueFromLogicRole(company, COMPANY_FIELDS_LOGIC_ROLE.NAME),
      targetMarket: getValueFromLogicRole(company, COMPANY_FIELDS_LOGIC_ROLE.TARGET_MARKET),
      website: getValueFromLogicRole(company, COMPANY_FIELDS_LOGIC_ROLE.WEBSITE),
    }));
  };

  return (
    <>
      {!open ? (
        <div className={styles['base-action-container']} style={{ gap: '16px' }}>
          <Text size="s" weight="bold" align="center">
            {t(`sidePeek.contactViewActions.${type === 'REASSIGN' ? 're' : ''}assignCompany`)}
          </Text>
          <CompanySelector
            searchCompanies={searchCompanies}
            onCompanyIdChange={setCompanyId}
            size="small"
          />
          <div className={styles['footer-buttons']}>
            <Button
              size="small"
              variant="clear"
              color="tomato"
              uppercase={false}
              onClick={closeAction}
            >
              {t('common.cancel')}
            </Button>
            <Button
              size="small"
              uppercase={false}
              disabled={!companyId}
              onClick={() => {
                type === 'ASSIGN' ? assignToNewCompany() : toggle();
              }}
            >
              {!loading ? (
                t('common.assign')
              ) : (
                <Spinner size={12} color="white" name="loadingCircle" />
              )}
            </Button>
          </div>
        </div>
      ) : (
        // This will exclusively show if its a company reassignation
        <ReassignCompanyConfirmationModal
          okAction={assignToNewCompany}
          koAction={toggle}
          loading={loading}
        />
      )}
    </>
  );
};

interface IReassignCompanyConfirmationModal {
  okAction: () => void;
  koAction: () => void;
  loading: boolean;
}

const ReassignCompanyConfirmationModal = ({
  okAction,
  koAction,
  loading,
}: IReassignCompanyConfirmationModal) => {
  const { t } = useTranslation();
  return (
    <Modal open onClose={koAction}>
      <ModalHeader color="veryLightBloobirds" size="small">
        <ModalTitle color="peanut" icon="deliver" size="small">
          {t(`sidePeek.contactViewActions.reassignCompany`)}
        </ModalTitle>
        <ModalCloseIcon color="bloobirds" size="small" onClick={koAction} />
      </ModalHeader>

      <ModalContent>
        <div className={styles['reassign-modal-content']}>
          <div>
            <Text size="m">{t('reassignCompanyModal.message.intro')}</Text>
            {t('reassignCompanyModal.message.effects', { returnObjects: true })['map'](
              (effect: string, index: number) => (
                <li key={index}>
                  <Trans i18nKey={`reassignCompanyModal.message.effects.${index}`}>{effect}</Trans>
                </li>
              ),
            )}
          </div>
          <div className={styles['callout-wrapper']}>
            <Callout width="100%" variant="alert" icon="alertTriangle">
              <span>{t('reassignCompanyModal.callout.intro')}</span>
              <br />
              {t('reassignCompanyModal.callout.settings', { returnObjects: true })['map'](
                (setting: string, index: number) => (
                  <li key={index}>
                    <Trans i18nKey={`reassignCompanyModal.callout.settings.${index}`}>
                      {setting}
                    </Trans>
                  </li>
                ),
              )}
            </Callout>
          </div>
        </div>
      </ModalContent>

      <ModalFooter>
        <Button onClick={koAction} variant="clear">
          {t('common.goBack')}
        </Button>
        <Button onClick={okAction}>
          {!loading ? t('common.confirm') : <Spinner color="white" name="loadingCircle" />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export const AssignToCompanyAction = (props: ActionComponentProps) => (
  <AssignToCompany {...props} type="ASSIGN" />
);
export const ReassignToCompanyAction = (props: ActionComponentProps) => (
  <AssignToCompany {...props} type="REASSIGN" />
);
