import { Dispatch, SetStateAction, useState } from 'react';

import { TestSet } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import { AxiosResponse } from 'axios';
import useSWR from 'swr';

interface IUseSetsData {
  isLoading: boolean;
  testSetMap: Map<string, TestSet>;
  testSets: TestSet[];
  selectedTestSetId: string;
  setSelectedTestSetId: Dispatch<SetStateAction<string>>;
}
export const useSetsData = (): IUseSetsData => {
  const [selectedTestSetId, setSelectedTestSetId] = useState<string>();

  const { data: testSets, isValidating } = useSWR<AxiosResponse<TestSet[]>>('/api/test-sets', () =>
    api.get('/copilot/playground/test-set'),
  );

  const testSetMap = new Map<string, TestSet>();

  testSets?.data.forEach(testset => {
    testSetMap.set(testset.pk, testset);
  });

  return {
    isLoading: isValidating,
    testSetMap,
    testSets: [...testSetMap.values()].sort(
      (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    ),
    selectedTestSetId,
    setSelectedTestSetId,
  };
};
