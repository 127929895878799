// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer-module___root___lheio {\n  height: 80px;\n  box-sizing: border-box;\n  background-color: var(--lighterGray);\n  width: calc(100% - 96px);\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 16px;\n  position: fixed;\n}\n\n.footer-module___btn_container___D1Jsr {\n  width: 152px;\n  height: 40px;\n}\n\n.footer-module___btn_wrapper___dFtmG {\n  display: flex;\n}\n\n.footer-module___btn_wrapper___dFtmG > * {\n  margin-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/importForm/footer/footer.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,sBAAsB;EACtB,oCAAoC;EACpC,wBAAwB;EACxB,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["._root {\n  height: 80px;\n  box-sizing: border-box;\n  background-color: var(--lighterGray);\n  width: calc(100% - 96px);\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 16px;\n  position: fixed;\n}\n\n._btn_container {\n  width: 152px;\n  height: 40px;\n}\n\n._btn_wrapper {\n  display: flex;\n}\n\n._btn_wrapper > * {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_root": "footer-module___root___lheio",
	"_btn_container": "footer-module___btn_container___D1Jsr",
	"_btn_wrapper": "footer-module___btn_wrapper___dFtmG"
};
export default ___CSS_LOADER_EXPORT___;
