import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { NoResultsFound } from '@bloobirds-it/activity-feed';
import { useCopilotActivity } from '@bloobirds-it/copilot';
import { Icon, SearchInput, Text } from '@bloobirds-it/flamingo-ui';
import { useSearchInTranscript } from '@bloobirds-it/hooks';
import { ACTIVITY_TYPES_VALUES_LOGIC_ROLE } from '@bloobirds-it/types';

import { secondsToTime } from '../../utiils';
import styles from './transcript.module.css';

interface Props {
  onClick: (start: number) => void;
  activityType: ACTIVITY_TYPES_VALUES_LOGIC_ROLE | 'Call';
}

const Transcript = ({ onClick, activityType }: Props) => {
  const { transcript: data } = useCopilotActivity();
  const { t } = useTranslation();
  const {
    highlightText,
    filteredTranscript,
    searchTerm,
    handleSearchChange,
  } = useSearchInTranscript(data?.transcript);

  const parseTime = (time: number) => {
    if (['call', ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL].includes(activityType)) {
      return time / 1000;
    } else {
      return time;
    }
  };

  return (
    <div className={styles.blocks}>
      <div className={styles.titleSection}>
        <Icon name="search" color="purple" />
        <Text size="s" weight="heavy">
          {t('ai.aiAnalysis.searchTranscript')}
        </Text>
      </div>
      <div className={styles.searchSection}>
        <SearchInput
          color="purple"
          size="small"
          width="100%"
          placeholder={t('ai.aiAnalysis.searchTranscriptTooltip')}
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      <div className={styles.titleSection}>
        <Icon name="snippet" color="purple" />
        <Text size="s" weight="heavy">
          {activityType === 'call'
            ? t('ai.aiAnalysis.callTranscript')
            : t('ai.aiAnalysis.meetingTranscript')}
        </Text>
      </div>
      {(filteredTranscript?.length || 0) > 0 ? (
        filteredTranscript?.map((fragment, index) => {
          if (!fragment.text) return null;
          return (
            <div key={index} onClick={() => onClick(parseTime(fragment.start))}>
              <div className={styles.fragmentHeader}>
                <Text color="purple" size="xs" inline>
                  {secondsToTime(parseTime(fragment.start))}
                </Text>
                <Text color="purple" size="xs" weight="bold">
                  <Icon name={fragment.isLead ? 'person' : 'user'} color="purple" size={16} />
                  {fragment.speaker} ({fragment.isLead ? 'lead' : 'user'})
                </Text>
              </div>

              <Text size="xs">{highlightText(fragment.text)}</Text>
            </div>
          );
        })
      ) : (
        <NoResultsFound />
      )}
    </div>
  );
};

export default Transcript;
