import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Text } from '@bloobirds-it/flamingo-ui';

import { useSendToMetaModalVisibility } from '../../sendToMetaModal/useSendToMetaModalVisibility';
import styles from '../segmentationFilter.module.css';

export const Checks: Array<{ key: string; check: boolean }> = [
  {
    key: 'canUpdateSegmentation',
    check: true,
  },
  {
    key: 'canDuplicate',
    check: true,
  },
  {
    key: 'cantChangeContents',
    check: false,
  },
];

export const SendToMetaBlock = ({ size = 'medium' }: { size?: 'small' | 'medium' }) => {
  const isSmall = size === 'small';
  const { openSendToMetaModal } = useSendToMetaModalVisibility();
  const { t } = useTranslation('translation', {
    keyPrefix: 'playbook.segmentationFilter.sendToMetaBlock',
  });

  return (
    <section className={styles.sendToMetaContainer}>
      <Text
        uppercase={!isSmall}
        size="s"
        color={isSmall ? 'peanut' : 'softPeanut'}
        weight={isSmall ? 'bold' : 'regular'}
      >
        {t('title')}
      </Text>
      <Text size={isSmall ? 'xs' : 's'} color="softPeanut">
        {t('description')}
      </Text>
      <div className={styles.sendToMetaCheckWrapper}>
        {Checks.map(({ key, check }) => (
          <div key={key} className={styles.sendToMetaCheck}>
            {check ? (
              <Icon size={18} name="check" color="melon" />
            ) : (
              <Icon size={18} name="cross" color="tomato" />
            )}
            <Text size={isSmall ? 'xs' : 's'} color="softPeanut">
              {t(`checks.${key}`)}
            </Text>
          </div>
        ))}
      </div>
      <Button
        color="softPurple"
        iconLeft="deliver"
        variant="secondary"
        uppercase={false}
        className={styles.sendToMetaButton}
        onClick={openSendToMetaModal}
      >
        {t('buttonText')}
      </Button>
    </section>
  );
};
