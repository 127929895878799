import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, IconType, Text } from '@bloobirds-it/flamingo-ui';

import styles from '../../../styles/phoneNumberPage.module.css';
import { InfoCalloutPurchasePhone } from '../../advancedSettings/customCallouts';

interface InfoComponentProps {
  type: 'orderReview' | 'phoneRequest';
  additionalComponent?: React.ReactNode;
}

export const InfoComponent: React.FC<InfoComponentProps> = ({ type, additionalComponent }) => {
  const { t } = useTranslation();

  const INFO_CONFIGURATIONS = {
    orderReview: {
      iconName: 'fileOpportunity',
      titleKey: 'accountSettings.phoneNumbers.purchaseNewPhone.orderReview.title',
      subtitleKey: 'accountSettings.phoneNumbers.purchaseNewPhone.orderReview.subtitle',
      subtextKey: 'accountSettings.phoneNumbers.purchaseNewPhone.orderReview.subtext',
      subtextStrong: true,
      additionalComponent: <></>,
    },
    phoneRequest: {
      iconName: 'phone',
      titleKey: 'accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.phoneRequest',
      subtitleKey: 'accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.phoneRequestSubtext',
      subtextKey: 'accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.phoneRequestSubtext2',
      subtextStrong: false,
      additionalComponent: <InfoCalloutPurchasePhone />,
    },
  };

  const config = INFO_CONFIGURATIONS[type];

  return (
    <div className={styles.phoneRequestContainer}>
      <div className={styles.textWithIcon}>
        <Icon name={config.iconName as IconType} color="peanut" size={18} />
        <Text size="m" weight="heavy">
          {t(config.titleKey)}
        </Text>
      </div>

      <Text size="xs" color="peanut" weight="regular">
        {t(config.subtitleKey)}
      </Text>

      <Text size="xs" color="peanut" weight="regular">
        {config.subtextStrong ? <strong>{t(config.subtextKey)}</strong> : t(config.subtextKey)}
      </Text>

      {additionalComponent || config.additionalComponent}
    </div>
  );
};
