import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Text } from '@bloobirds-it/flamingo-ui';
import { useRouter } from '@bloobirds-it/hooks';
import { ACTIVITY_FIELDS_LOGIC_ROLE, APP_CL_ACTIVITIES, TestSet } from '@bloobirds-it/types';

import { useEntity } from '../../../../hooks';
import {
  getBobjectTableColumns,
  getBobjectTableFilters,
  getFieldIdByLogicRole,
} from '../../../../utils/bobjects.utils';
import { useSetsData } from '../../hooks/useSetsData';
import styles from '../../playgroundPages.module.css';
import { getColumnsAndFiltersByActivityType } from '../../utils';
import TestSetCard from './testSetCard';
import TestSetSkeleton from './testSetSkeleton';
import TestSetsEmpty from './testSetsEmpty';

export function TestSets({
  setSelectedTestSet,
}: {
  setSelectedTestSet: (testSet: TestSet) => void;
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground.testSet' });
  const { isLoading, testSets, selectedTestSetId, setSelectedTestSetId } = useSetsData();
  const { push } = useRouter();
  const bobjectFields = useEntity('bobjectFields');

  const handleAddTestSet = () => {
    const datetimeFieldId = getFieldIdByLogicRole(
      bobjectFields,
      ACTIVITY_FIELDS_LOGIC_ROLE.CREATION_DATETIME,
    );

    const { columns, filters } = getColumnsAndFiltersByActivityType('All');
    const query = `${APP_CL_ACTIVITIES}?query=${getBobjectTableFilters(
      bobjectFields,
      filters,
    )}&columns=${getBobjectTableColumns(
      bobjectFields,
      columns,
    )}&sort=${datetimeFieldId}&direction=DESC`;

    push?.(query);
  };

  return (
    <div className={styles.testSets_container}>
      <div className={styles.test_header}>
        <div>
          <Icon name="test" size={20} color="softPeanut" />
          <Text size="s" color="softPeanut" weight="bold">
            {t('title')}
          </Text>
        </div>
      </div>
      {isLoading ? (
        <div className={styles.test_list}>
          {[...Array(5)].map((_, index) => (
            <TestSetSkeleton key={index} />
          ))}
        </div>
      ) : testSets && testSets.length > 0 ? (
        <div className={styles.test_list}>
          {testSets.map((testSet: TestSet) => (
            <TestSetCard
              key={testSet.pk}
              testSet={testSet}
              isSelected={testSet.pk === selectedTestSetId}
              handleClick={() => {
                setSelectedTestSetId(testSet.pk);
                setSelectedTestSet(testSet);
              }}
            />
          ))}
          <Button
            variant="clear"
            color="purple"
            onClick={handleAddTestSet}
            uppercase={false}
            iconLeft="add"
          >
            {t('addTestSet')}
          </Button>
        </div>
      ) : (
        <TestSetsEmpty handleAddTestSet={handleAddTestSet} />
      )}
    </div>
  );
}
