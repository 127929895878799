// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".activityList-module___container___HKyL3 {\n  margin-top: 32px;\n  opacity: 0;\n  animation: activityList-module__fade-in___HXz\\+v 250ms ease-in-out forwards;\n  animation-delay: 200ms;\n}\n\n@keyframes activityList-module__fade-in___HXz\\+v {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.activityList-module___header___bToay {\n  margin: 8px 0;\n  font-size: 16px;\n}\n\n.activityList-module___header___bToay :first-child {\n  margin-right: 8px;\n}\n\n.activityList-module___pinned_icon_container___DKAbA {\n  display: inline;\n}\n\n.activityList-module___pinned_icon_container___DKAbA > svg {\n  transform: rotate(30deg);\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/activitySection/activityList/activityList.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,UAAU;EACV,2EAA6C;EAC7C,sBAAsB;AACxB;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":["._container {\n  margin-top: 32px;\n  opacity: 0;\n  animation: fade-in 250ms ease-in-out forwards;\n  animation-delay: 200ms;\n}\n\n@keyframes fade-in {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n._header {\n  margin: 8px 0;\n  font-size: 16px;\n}\n\n._header :first-child {\n  margin-right: 8px;\n}\n\n._pinned_icon_container {\n  display: inline;\n}\n\n._pinned_icon_container > svg {\n  transform: rotate(30deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "activityList-module___container___HKyL3",
	"fade-in": "activityList-module__fade-in___HXz+v",
	"_header": "activityList-module___header___bToay",
	"_pinned_icon_container": "activityList-module___pinned_icon_container___DKAbA"
};
export default ___CSS_LOADER_EXPORT___;
