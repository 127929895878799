import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  IconButton,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Text,
} from '@bloobirds-it/flamingo-ui';
import clsx from 'clsx';

import {
  ActivityCarouselTestSetReview,
  IActivityCarouselTestSetReviewProps,
} from './activityCarouselTestSetReview';
import styles from './activityCarouselTestSetReview.module.css';

interface IActivityCarouselTestSetReviewModalProps
  extends Pick<
    IActivityCarouselTestSetReviewProps,
    'activityResults' | 'activityType' | 'selectedTestSet' | 'selectedTestRun' | 'selectedInsight'
  > {
  onClose: () => void;
  activityIndex: number;
}

export const ActivityCarouselTestSetReviewModal = ({
  activityResults,
  activityType,
  onClose,
  selectedTestSet,
  activityIndex,
  selectedTestRun,
  selectedInsight,
}: IActivityCarouselTestSetReviewModalProps) => {
  const [selectedActivityIndex, setSelectedActivityIndex] = useState(activityIndex);
  const { t } = useTranslation();
  const testRunActivitiesLength = Object.keys(activityResults || {}).length;

  return (
    <Modal open onClose={onClose} width="90vw">
      <ModalHeader color="white" size="small">
        <ModalTitle color="purple" icon="stars" size="small">
          <Text size="l" weight="bold">
            {selectedActivityIndex + 1} {'.- '} {selectedInsight.title}
          </Text>
        </ModalTitle>
        <ModalCloseIcon color="purple" size="small" onClick={onClose} />
      </ModalHeader>

      <ModalContent className={styles.contentContainer}>
        <ActivityCarouselTestSetReview
          activityResults={activityResults}
          activityType={activityType}
          selectedActivityIndex={selectedActivityIndex}
          selectedTestSet={selectedTestSet}
          selectedTestRun={selectedTestRun}
          selectedInsight={selectedInsight}
        />
      </ModalContent>

      <ModalFooter className={styles.footer}>
        <div
          className={clsx(styles.focusViewChevron, {
            [styles.disabled]: selectedActivityIndex + 1 === testRunActivitiesLength,
          })}
        >
          <IconButton
            color="purple"
            onClick={() => {
              setSelectedActivityIndex(prev =>
                prev < testRunActivitiesLength ? prev + 1 : testRunActivitiesLength,
              );
            }}
            disabled={selectedActivityIndex + 1 === testRunActivitiesLength}
            name="chevronDown"
          />
        </div>

        <div
          className={clsx(styles.focusViewChevron, {
            [styles.disabled]: [testRunActivitiesLength, selectedActivityIndex].includes(0),
          })}
        >
          <IconButton
            color="purple"
            disabled={[testRunActivitiesLength, selectedActivityIndex].includes(0)}
            onClick={() => {
              setSelectedActivityIndex(prev => (prev > 0 ? prev - 1 : 0));
            }}
            name="chevronUp"
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};
