import React from 'react';

import { CopilotInsightVersion } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import useSWR from 'swr';

interface IUseInsightVersions {
  versions: Map<string, CopilotInsightVersion>;
  isLoading: boolean;
}
export const useInsightVersions = (insightId: string): IUseInsightVersions => {
  const { data, isLoading } = useSWR(insightId ? `/api/insight/version/${insightId}` : null, () =>
    api
      .get<CopilotInsightVersion[]>('/utils/service/copilot-insights/version/' + insightId)
      .then(payload => (payload?.data || []).map(v => new CopilotInsightVersion(v))),
  );
  const versionsMap = new Map<string, CopilotInsightVersion>();
  data?.forEach(v => versionsMap.set(v.id, v));
  return { versions: versionsMap, isLoading };
};
