// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".leadsCreateOptions-module__container___FztBC {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  margin: 4px 0;\n}\n\n.leadsCreateOptions-module__section___SUBqO {\n  display: flex;\n  align-items: center;\n  margin: 8px 0;\n}\n\n.leadsCreateOptions-module__section___SUBqO > * {\n  margin: 0 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/importForm/components/leadsCreateOptions/leadsCreateOptions.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  margin: 4px 0;\n}\n\n.section {\n  display: flex;\n  align-items: center;\n  margin: 8px 0;\n}\n\n.section > * {\n  margin: 0 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "leadsCreateOptions-module__container___FztBC",
	"section": "leadsCreateOptions-module__section___SUBqO"
};
export default ___CSS_LOADER_EXPORT___;
