import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown, Icon, Tooltip, useVisible, Text, ColorType } from '@bloobirds-it/flamingo-ui';
import { useDataModel } from '@bloobirds-it/hooks';
import { useBobjectRelations } from '@bloobirds-it/related-objects';
import { RelatedObject } from '@bloobirds-it/types';
import { getSalesforceIdField } from '@bloobirds-it/utils';
import clsx from 'clsx';

import styles from '../../../recipientSearchInput.module.css';

const RelatedChip = ({ related, toggleVisible, isOutsider }) => {
  return (
    <div
      onClick={event => {
        event.stopPropagation();
        toggleVisible();
      }}
      style={{
        backgroundColor: isOutsider ? 'var(--softBanana)' : 'var(--lightPeanut)',
        display: 'flex',
        alignItems: 'center',
        padding: '0 4px',
        borderRadius: '4px',
      }}
    >
      <Icon name={related.icon} size={14} color="peanut" />
      {related.title}
    </div>
  );
};

const getAnchorColor = (emptyRelations, isOutsider, visible): ColorType => {
  if (visible) return 'white';
  if (isOutsider) return emptyRelations ? 'banana' : 'tangerine';
  else return emptyRelations ? 'softPeanut' : 'bloobirds';
};

const Anchor = ({ related, emptyRelations, visible, toggleVisible, isOutsider }) => {
  return !related ? (
    <span
      className={clsx(styles.relationer, {
        [styles.relationerOpen]: visible,
        [styles.relationerNoRelations]: emptyRelations,
        [styles.relationerOpenOutsider]: visible && isOutsider,
        [styles.relationerOutsider]: isOutsider,
        [styles.relationerOutsiderNoRelations]: isOutsider && emptyRelations,
      })}
      onClick={e => {
        e.stopPropagation();
        if (!emptyRelations) toggleVisible();
      }}
    >
      <Icon
        name="relations"
        size={12}
        color={getAnchorColor(emptyRelations, isOutsider, visible)}
      />
    </span>
  ) : (
    <RelatedChip related={related} toggleVisible={toggleVisible} isOutsider={isOutsider} />
  );
};

interface RelatedObjectHandlerProps {
  bobject: any;
  activeRelated: string[];
  onAddRelated: (related: string[]) => void;
  isOutsider: boolean;
}

export const RelatedObjectHandler = React.forwardRef<any, RelatedObjectHandlerProps>(
  ({ bobject, activeRelated, onAddRelated, isOutsider }, parentRef) => {
    const { ref, visible, setVisible } = useVisible();
    const { t } = useTranslation('translation', {
      keyPrefix: 'smartEmailModal.components.recipientSearchInput.relatedObjects',
    });
    const dataModel = useDataModel();
    const { relations } = useBobjectRelations({
      bobject: {
        ...bobject,
        salesforceId: getSalesforceIdField(bobject, dataModel),
      },
      mainRelated: true,
    });
    const emptyRelations = !relations || !Object.keys(relations).length;
    return (
      <Dropdown
        ref={ref}
        visible={visible}
        position="bottom"
        anchor={
          <div ref={parentRef}>
            <Tooltip
              title={t(
                emptyRelations
                  ? 'noAssignableObjects'
                  : activeRelated
                  ? 'replaceRelated'
                  : 'addRelated',
              )}
              position={isOutsider ? 'bottom' : 'top'}
            >
              <Anchor
                isOutsider={isOutsider}
                emptyRelations={emptyRelations}
                related={
                  typeof activeRelated?.[0] === 'string'
                    ? relations
                      ? Object.values(relations)
                          .flat()
                          .find(r => r.compositeRelatedId === activeRelated?.[0])
                      : undefined
                    : activeRelated
                }
                visible={visible}
                toggleVisible={() => setVisible(!visible)}
              />
            </Tooltip>
          </div>
        }
      >
        {!emptyRelations && (
          <RelatedDisplay
            relations={relations}
            onAddRelated={onAddRelated}
            closeDropdown={() => setVisible(!visible)}
          />
        )}
      </Dropdown>
    );
  },
);

const RelatedDisplay = ({ relations, onAddRelated, closeDropdown }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '8px',
        gap: '8px',
        maxHeight: '350px',
        overflowY: 'scroll',
      }}
    >
      <div
        onClick={() => {
          closeDropdown();
          onAddRelated(undefined);
        }}
      >
        <Text className={styles.relatedItem} size="s" color="peanut">
          Remove Relation
        </Text>
      </div>
      {Object.entries(relations).map(([key, values]) => (
        <div key={key} style={{ padding: '0 8px' }}>
          <Text size="s" color="softPeanut">
            <Icon name={values?.[0]?.icon} color="softPeanut" /> {key}
          </Text>
          <RelatedList
            relations={values}
            onAddRelated={onAddRelated}
            closeDropdown={closeDropdown}
          />
        </div>
      ))}
    </div>
  );
};

const RelatedList = ({ relations, onAddRelated, closeDropdown }) => {
  const handleClick = (relation: RelatedObject) => {
    onAddRelated(relation);
    closeDropdown();
  };
  return (
    <ul>
      {relations.map(relation => (
        <div onClick={() => handleClick(relation)}>
          <Text key={relation.id} className={styles.relatedItem} size="s" color="peanut">
            {relation.title}
          </Text>
        </div>
      ))}
    </ul>
  );
};
