// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sendToPlaygroundModal-module__content___JGLfX {\n  padding: 32px 24px;\n}\n.sendToPlaygroundModal-module__container___Ph87E {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n.sendToPlaygroundModal-module__counterIcon___WOX1V {\n  align-self: flex-start;\n}\n.sendToPlaygroundModal-module__container___Ph87E strong {\n  text-wrap-mode: nowrap;\n  display: contents;\n}\n.sendToPlaygroundModal-module__activityStats___Qx-jN,\n.sendToPlaygroundModal-module__activityList___JraFj {\n  padding: 0px 16px;\n}\n\n.sendToPlaygroundModal-module__checking_activities___8mwCT {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.sendToPlaygroundModal-module__name_input___tpLbp {\n  padding-top: 32px;\n}\n\n.sendToPlaygroundModal-module__counterTexts___TdFBI {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/sendToPlaygroundModal/sendToPlaygroundModal.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;AACA;EACE,sBAAsB;AACxB;AACA;EACE,sBAAsB;EACtB,iBAAiB;AACnB;AACA;;EAEE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,QAAQ;AACV","sourcesContent":[".content {\n  padding: 32px 24px;\n}\n.container {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n.counterIcon {\n  align-self: flex-start;\n}\n.container strong {\n  text-wrap-mode: nowrap;\n  display: contents;\n}\n.activityStats,\n.activityList {\n  padding: 0px 16px;\n}\n\n.checking_activities {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.name_input {\n  padding-top: 32px;\n}\n\n.counterTexts {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "sendToPlaygroundModal-module__content___JGLfX",
	"container": "sendToPlaygroundModal-module__container___Ph87E",
	"counterIcon": "sendToPlaygroundModal-module__counterIcon___WOX1V",
	"activityStats": "sendToPlaygroundModal-module__activityStats___Qx-jN",
	"activityList": "sendToPlaygroundModal-module__activityList___JraFj",
	"checking_activities": "sendToPlaygroundModal-module__checking_activities___8mwCT",
	"name_input": "sendToPlaygroundModal-module__name_input___tpLbp",
	"counterTexts": "sendToPlaygroundModal-module__counterTexts___TdFBI"
};
export default ___CSS_LOADER_EXPORT___;
