import { useEffect, useState } from 'react';

import {
  Bobject,
  BobjectId,
  BobjectTypes,
  ExtensionBobject,
  PlaybookTab,
  TASK_ACTION,
  TASK_FIELDS_LOGIC_ROLE,
  TASK_STATUS_VALUE_LOGIC_ROLE,
} from '@bloobirds-it/types';
import { api, getValueFromLogicRole, injectReferencesSearchProcess } from '@bloobirds-it/utils';
import { startOfDay } from 'date-fns';

import { BASE_SEARCH_REQUEST } from './useTasksFeed';

const NEEDED_COLUMNS = [
  TASK_FIELDS_LOGIC_ROLE.TITLE,
  TASK_FIELDS_LOGIC_ROLE.STATUS,
  TASK_FIELDS_LOGIC_ROLE.COMPANY,
  TASK_FIELDS_LOGIC_ROLE.LEAD,
  TASK_FIELDS_LOGIC_ROLE.OPPORTUNITY,
  TASK_FIELDS_LOGIC_ROLE.ASSIGNED_TO,
  TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATE,
  TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME,
  TASK_FIELDS_LOGIC_ROLE.TASK_TYPE,
  TASK_FIELDS_LOGIC_ROLE.IS_AUTOMATED,
  TASK_FIELDS_LOGIC_ROLE.IS_AUTOMATED_EMAIL,
  TASK_FIELDS_LOGIC_ROLE.DESCRIPTION,
  TASK_ACTION.CALL,
  TASK_ACTION.EMAIL,
  TASK_ACTION.LINKEDIN_MESSAGE,
  TASK_FIELDS_LOGIC_ROLE.CADENCE,
  TASK_FIELDS_LOGIC_ROLE.MEETING_ACTIVITY,
  TASK_FIELDS_LOGIC_ROLE.CUSTOM_TASK,
  TASK_FIELDS_LOGIC_ROLE.PRIORITY,
  TASK_FIELDS_LOGIC_ROLE.TEMPLATE,
  TASK_FIELDS_LOGIC_ROLE.SUGGESTED_PITCH,
  TASK_FIELDS_LOGIC_ROLE.SUGGESTED_LINKEDIN_TEMPLATE,
  TASK_FIELDS_LOGIC_ROLE.SUGGESTED_WHATSAPP_TEMPLATE,
];

const isTodayOrOverdue = task => {
  const date = getValueFromLogicRole(task, TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME);
  return startOfDay(new Date(date)) <= startOfDay(new Date());
};

const taskTypes = ['PROSPECT_CADENCE', 'CUSTOM_TASK', 'SCHEDULED_EMAIL'];

const getQueryDictionary = (bobjectId: BobjectId['value']) => {
  if (!bobjectId) return;
  const isCompanyId = bobjectId.includes('Company');
  const isOpportunityId = bobjectId.includes('Opportunity');
  return {
    query: {
      [TASK_FIELDS_LOGIC_ROLE.TASK_TYPE]: taskTypes,
      [TASK_FIELDS_LOGIC_ROLE.STATUS]: [
        TASK_STATUS_VALUE_LOGIC_ROLE.OVERDUE,
        TASK_STATUS_VALUE_LOGIC_ROLE.TODO,
      ],
    },
    columns: NEEDED_COLUMNS,
    page: 0,
    pageSize: 100,
    sort: [
      {
        field: TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME,
        direction: 'ASC',
      },
      {
        field: TASK_FIELDS_LOGIC_ROLE.PRIORITY,
        direction: 'ASC',
      },
    ],
    queries: [
      {
        [TASK_FIELDS_LOGIC_ROLE[
          isCompanyId ? 'COMPANY' : isOpportunityId ? 'OPPORTUNITY' : 'LEAD'
        ]]: [bobjectId],
      },
    ],
    ...BASE_SEARCH_REQUEST,
  };
};

enum LogicRoleConverter {
  Emails = TASK_FIELDS_LOGIC_ROLE.TEMPLATE,
  Pitches = TASK_FIELDS_LOGIC_ROLE.SUGGESTED_PITCH,
  LinkedIn = TASK_FIELDS_LOGIC_ROLE.SUGGESTED_LINKEDIN_TEMPLATE,
  WhatsApp = TASK_FIELDS_LOGIC_ROLE.SUGGESTED_WHATSAPP_TEMPLATE,
}

function getTemplateId(task: Bobject<BobjectTypes.Task>, templateType: PlaybookTab) {
  const logicRole = LogicRoleConverter[templateType];
  return getValueFromLogicRole(task, logicRole);
}

export const useSuggestedTemplates = (
  bobject: Bobject | ExtensionBobject,
  templateType: PlaybookTab,
) => {
  const bobjectId = bobject?.id?.value;
  const [suggested, setSuggested] = useState([]);

  useEffect(() => {
    if (bobject && bobject?.id?.accountId) {
      api
        .post('/bobjects/' + bobject?.id?.accountId + '/Task/search', getQueryDictionary(bobjectId))
        .then(async response => {
          if (response?.data) {
            const ids = injectReferencesSearchProcess(response?.data)?.contents;
            const idMap = new Map();

            ids?.forEach(t => {
              const templateId = getTemplateId(t, templateType);
              if (templateId && isTodayOrOverdue(t)) {
                idMap.set(templateId, {
                  id: templateId,
                  taskTitle: getValueFromLogicRole(t, TASK_FIELDS_LOGIC_ROLE.TITLE),
                });
              }
            });

            if (ids?.length === 0 || !ids) {
              return;
            }
            const res = await api.get(
              '/messaging/messagingTemplates/ids?ids=' + Array.from(idMap.keys()).toString(),
            );

            const templatesRetrieved = res?.data ?? [];

            setSuggested(
              templatesRetrieved.map(template => {
                return {
                  ...template,
                  taskTitle: idMap.get(template.id)?.taskTitle || [],
                };
              }),
            );
          }
        });
    }
  }, [bobjectId, templateType]);

  return suggested;
};
