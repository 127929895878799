import { useTranslation } from 'react-i18next';

import { Button, Text } from '@bloobirds-it/flamingo-ui';

import styles from './confirmDeleteMessage.module.css';

export const ConfirmDeleteMessage = ({
  onClick,
  onCancel,
  message,
}: {
  onClick: () => void;
  onCancel: () => void;
  message: string;
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.confirmDeleteContainer}>
      <div className={styles.confirmDeleteText}>
        <Text size="s" color="peanut" align="center">
          {message}
        </Text>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '16px' }}>
        <Button
          size="small"
          variant="clear"
          onClick={e => {
            e.stopPropagation();
            onCancel();
          }}
        >
          <Text size="xs" color="bloobirds">
            {t('common.cancel')}
          </Text>
        </Button>
        <Button
          variant="primary"
          size="small"
          onClick={e => {
            e.stopPropagation();
            onClick();
          }}
          color="tomato"
          className={styles.confirmDeleteButton}
        >
          <Text size="xs" color="white">
            {t('common.delete')}
          </Text>
        </Button>
      </div>
    </div>
  );
};
