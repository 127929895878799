// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".quickFilter-module__wrapper___\\+W8SR {\n  display: flex;\n  align-content: space-between;\n}\n\n.quickFilter-module__dropdownMenu___YmaG2 {\n  width: 23px;\n  height: 23px;\n  border-radius: 16px;\n  margin-left: -23px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/subhomeLayout/subhomeContent/newSubhomeFilters/quickFilters/quickFilter/quickFilter.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,4BAA4B;AAC9B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".wrapper {\n  display: flex;\n  align-content: space-between;\n}\n\n.dropdownMenu {\n  width: 23px;\n  height: 23px;\n  border-radius: 16px;\n  margin-left: -23px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "quickFilter-module__wrapper___+W8SR",
	"dropdownMenu": "quickFilter-module__dropdownMenu___YmaG2"
};
export default ___CSS_LOADER_EXPORT___;
