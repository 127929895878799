import React from 'react';

import { Icon, Text } from '@bloobirds-it/flamingo-ui';
import { useUserSettings } from '@bloobirds-it/hooks';
import { UserType } from '@bloobirds-it/types';

import {
  EntityCard,
  EntityCardItem,
} from '../../../../../components/entityList/entityCard/entityCard';
import { useTagsModal } from '../../../../../hooks/useTagsModal';
import { PhoneNumber } from '../../constants/phoneNumbers.constants';
import { useFlagEmoji } from '../../hooks/useFlagEmoji';
import styles from '../../styles/phoneNumberPage.module.css';
import { CapabilitiesCard } from '../rows/capabilitiesCard';
import { StatusCard } from '../rows/statusCard';
import { TagsCard } from '../rows/tagsCard';
import { UsersGroupCard } from '../rows/usersGroupCard';

export const PhoneRow = ({
  phone,
  isLastTwoRows,
}: {
  phone: PhoneNumber;
  isLastTwoRows: boolean;
}) => {
  const { flag } = useFlagEmoji(phone.countryCode);
  const settings = useUserSettings();

  const { handleOpenTagsModal } = useTagsModal();
  const isSupportUser = settings?.user.type === UserType.SUPPORT_USER;

  return (
    <EntityCard className={styles.row}>
      <EntityCardItem size="small">
        <div className={styles.numberAndName}>
          {isSupportUser && (
            <a
              href={`https://console.twilio.com/us1/develop/phone-numbers/manage/incoming/${phone.sid}/configure/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="externalLink" size={20} color="bloobirds" />
            </a>
          )}
          <div>{flag}</div>
          <div>
            <Text size="s" color="peanut" weight="heavy">
              {phone.phoneNumber}
            </Text>
            <Text size="xs" color="peanut" weight="medium">
              {phone.name}
            </Text>
          </div>
        </div>
      </EntityCardItem>
      <EntityCardItem size="small">
        {phone.capabilities.map((capability, index) => (
          <CapabilitiesCard key={index} capability={capability} />
        ))}
      </EntityCardItem>
      <EntityCardItem size="small">
        {phone.tags.slice(0, 2).map((tag, index) => (
          <TagsCard key={index} label={tag} />
        ))}
        {phone.tags.length > 2 && (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() =>
              handleOpenTagsModal(
                phone.tags?.map(value => ({
                  label: value,
                })),
                false,
                '',
                true,
              )
            }
          >
            <Text size="xs" color="peanut" weight="medium">
              +{phone.tags.length - 2}
            </Text>
          </span>
        )}
      </EntityCardItem>
      <EntityCardItem size="small">
        <div className={styles.activeConfiguration}>
          {phone.activeConfiguration.map((configuration, index) => (
            <Text key={index} size="xs" color="peanut" weight="medium">
              {configuration}
            </Text>
          ))}
        </div>
      </EntityCardItem>
      <EntityCardItem size="small">
        <UsersGroupCard users={phone?.users} isLastTwoRows={isLastTwoRows} />
      </EntityCardItem>
      <EntityCardItem size="small">
        <StatusCard phone={phone} showConfig={true} />
      </EntityCardItem>
    </EntityCard>
  );
};
