// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".allocateQcTask-module__wrapper___gPBW1 {\n  display: flex;\n  flex-direction: row;\n}\n", "",{"version":3,"sources":["webpack://./src/js/app/main/board/task/taskBoard/workspace/allocateQcTask/allocateQcTask.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: row;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "allocateQcTask-module__wrapper___gPBW1"
};
export default ___CSS_LOADER_EXPORT___;
