// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".selectGroupBy-module__select_item___ocOwK {\n  white-space: break-spaces;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/dashboardPages/v1/filters/selectGroupBy.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B","sourcesContent":[".select_item {\n  white-space: break-spaces;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select_item": "selectGroupBy-module__select_item___ocOwK"
};
export default ___CSS_LOADER_EXPORT___;
