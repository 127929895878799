import { useState } from 'react';

import { useToasts } from '@bloobirds-it/flamingo-ui';
import { api } from '@bloobirds-it/utils';
import useSWR from 'swr';

const updatePhoneNumber = async (url, { arg }) => {
  const response = await api.put(url, arg);
  if (response.status !== 200) {
    throw new Error('Failed to update phone number');
  }
  return 200;
};

export const usePhoneNumberForm = initialPhoneNumber => {
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);
  const [initialData, setInitialData] = useState(initialPhoneNumber);
  const { createToast } = useToasts();

  const { data, error, mutate, isLoading } = useSWR(
    phoneNumber?.id ? `/calls/settings/phoneNumbers/${phoneNumber.id}` : null,
    null,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const hasChanges = () => {
    return JSON.stringify(phoneNumber) !== JSON.stringify(initialData);
  };

  const handleChange = (key, value) => {
    setPhoneNumber(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSaveChanges = async updatedPhoneNumber => {
    try {
      setInitialData(updatedPhoneNumber);
      await updatePhoneNumber(`/calls/settings/phoneNumbers/${updatedPhoneNumber.id}`, {
        arg: updatedPhoneNumber,
      });
      createToast({ message: 'Phone number updated successfully', type: 'success' });
      mutate();
    } catch (error) {
      createToast({ message: 'Error updating phone number', type: 'error' });
      console.error('Error updating phone number:', error);
    }
  };

  return {
    phoneNumber,
    handleChange,
    handleSaveChanges,
    error,
    mutate,
    isLoading,
    hasChanges,
  };
};
