import { createContext, useContext } from 'react';

import { SegmentationData, TemplateStage } from '@bloobirds-it/types';

export interface VisibilityFilters {
  onlyMine: boolean;
  onlyOfficial: boolean;
  onlyPrivate: boolean;
  onlyBattlecards: boolean;
  metaApproved: boolean;
}

type ProviderType = {
  activeBobjectSegmentationValues: { [key: string]: string };
  segmentationFields: SegmentationData;
  children: React.ReactElement;
  filterValues: { [key: string]: Array<string> };
  stage: TemplateStage;
  defaultStage: TemplateStage;
  setFiltersContext: (filter: any) => void;
  isSmartEmail: boolean;
  shouldShowBattlecards: boolean;
  shouldShowVisibilityFilters: boolean;
  shouldShowMetaApproved?: boolean;
  visibilityFilters: VisibilityFilters;
  selectedTeams: any;
};

interface SegmentationFilterContextProps {
  stageSelector: [TemplateStage, (TemplateStage) => void];
  selectedSegmentation: { [segmentationId: string]: Array<string> };
  setSelectedSegmentation: (newSegmentationValue: {
    [segmentationId: string]: Array<string>;
  }) => void;
  removeLabelValue: (valueToRemove: string, segmentationField: { id: string }) => void;
  clearBlock: (blockId: string) => void;
  isSmartEmail: boolean;
  shouldShowBattlecards: boolean;
  shouldShowVisibilityFilters: boolean;
  shouldShowMetaApproved?: boolean;
  visibilityFilters: VisibilityFilters;
  setVisibilityFilters: (x: VisibilityFilters) => void;
  setSelectedTeams: (teams: any) => void;
  defaultStage: TemplateStage;
  selectedTeams: any;
}

const SegmentationFilterContext = createContext<SegmentationFilterContextProps | null>(null);

export const SegmentationFilterProvider = ({
  children,
  filterValues,
  shouldShowBattlecards,
  shouldShowVisibilityFilters,
  shouldShowMetaApproved = true,
  stage,
  defaultStage,
  setFiltersContext,
  visibilityFilters,
  isSmartEmail,
  selectedTeams,
}: ProviderType) => {
  function removeLabelValue(valueToRemove, segmentationField) {
    const filteredValues = filterValues[segmentationField.id].filter(
      value => value !== valueToRemove,
    );
    const newSegmentation =
      filteredValues &&
      Object.keys(filterValues).reduce((acc, key) => {
        const isFilteredKeyEmpty = key === segmentationField.id && filteredValues.length === 0;
        return {
          ...acc,
          ...(isFilteredKeyEmpty
            ? {}
            : { [key]: key === segmentationField.id ? filteredValues : filterValues[key] }),
        };
      }, {});

    setFiltersContext({
      segmentationData: newSegmentation,
      stage,
      visibilityFilters,
      shouldShowBattlecards,
      shouldShowVisibilityFilters,
      shouldShowMetaApproved,
      selectedTeams,
    });
  }

  function clearBlock(blockId) {
    const newValue =
      filterValues &&
      Object.keys(filterValues).reduce((acc, key) => {
        if (blockId !== key) {
          acc[key] = filterValues[key];
        }
        return acc;
      }, {});

    setFiltersContext({
      segmentationData: newValue,
      stage,
      visibilityFilters,
      shouldShowBattlecards,
      shouldShowVisibilityFilters,
      shouldShowMetaApproved,
      selectedTeams,
    });
  }

  return (
    <SegmentationFilterContext.Provider
      value={{
        stageSelector: [
          stage,
          (value: TemplateStage) => {
            setFiltersContext({
              segmentationData: filterValues,
              stage: value,
              visibilityFilters,
              shouldShowBattlecards,
              shouldShowVisibilityFilters,
              shouldShowMetaApproved,
              selectedTeams,
            });
          },
        ],
        selectedSegmentation: filterValues,
        setSelectedSegmentation: value => {
          setFiltersContext({
            stage,
            segmentationData: value,
            shouldShowBattlecards,
            visibilityFilters,
            shouldShowVisibilityFilters,
            shouldShowMetaApproved,
            selectedTeams,
          });
        },
        visibilityFilters,
        selectedTeams,
        setVisibilityFilters: value => {
          setFiltersContext({
            stage,
            segmentationData: filterValues,
            shouldShowBattlecards,
            shouldShowVisibilityFilters,
            shouldShowMetaApproved,
            visibilityFilters: { ...visibilityFilters, ...value },
            selectedTeams,
          });
        },
        setSelectedTeams: teams => {
          setFiltersContext({
            stage,
            segmentationData: filterValues,
            shouldShowBattlecards,
            shouldShowVisibilityFilters,
            shouldShowMetaApproved,
            visibilityFilters,
            selectedTeams: teams,
          });
        },
        removeLabelValue,
        clearBlock,
        isSmartEmail,
        shouldShowBattlecards,
        shouldShowVisibilityFilters,
        shouldShowMetaApproved,
        defaultStage,
      }}
    >
      {children}
    </SegmentationFilterContext.Provider>
  );
};

export const useSegmentationFilter = () => {
  const context = useContext(SegmentationFilterContext);

  if (context === undefined || context === null) {
    throw new Error('useSegmentationFilter must be used within the SegmentationFilterProvider');
  }

  return context;
};
