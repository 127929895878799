import { ColorType, IconType } from '@bloobirds-it/flamingo-ui';

import { CadenceType } from './cadenceTable.type';

export const cadenceTasksDisplay: Record<
  CadenceType,
  { label: string; plural: string; tooltip: string; key: string; icon: IconType; color: ColorType }
> = {
  call: {
    label: 'Call',
    plural: 'Calls',
    tooltip: 'call',
    key: 'call',
    icon: 'phone',
    color: 'extraCall',
  },
  email: {
    label: 'Email',
    plural: 'Emails',
    tooltip: 'email',
    key: 'email',
    icon: 'mail',
    color: 'tangerine',
  },
  autoEmail: {
    label: 'Auto-Mail',
    plural: 'Auto Emails',
    key: 'auto-mail',
    tooltip: 'auto email',
    icon: 'autoMail',
    color: 'tangerine',
  },
  linkedIn: {
    label: 'LinkedIn',
    plural: 'LinkedIn',
    key: 'linkedin',
    tooltip: 'linkedin message',
    icon: 'linkedin',
    color: 'darkBloobirds',
  },
  customTask: {
    label: 'Task',
    plural: 'Tasks',
    key: 'task',
    tooltip: 'task',
    icon: 'taskAction',
    color: 'bloobirds',
  },
  meeting: {
    label: 'Meeting',
    plural: 'Meetings',
    key: 'meeting',
    tooltip: 'meeting',
    icon: 'calendar',
    color: 'tomato',
  },
  inbound: {
    label: 'Inbound',
    plural: 'Inbounds',
    tooltip: 'inbound',
    key: 'inbound',
    icon: 'arrowDownRight',
    color: 'banana',
  },
  statusChange: {
    label: 'C. Status',
    plural: 'C. Status',
    key: 'statusChange',
    tooltip: 'status change',
    icon: 'company',
    color: 'peanut',
  },
};
