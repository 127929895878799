// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cadenceFeedbackModal-module__content___Y\\+pEe {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n\n.cadenceFeedbackModal-module__content___Y\\+pEe > svg {\n  margin-bottom: 26px;\n}\n\n.cadenceFeedbackModal-module__content___Y\\+pEe > p {\n  margin-bottom: 16px;\n  text-align: center;\n}\n\n.cadenceFeedbackModal-module__footer___ljnOr {\n  justify-content: flex-end;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/cadenceFeedbackModal/cadenceFeedbackModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".content {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n\n.content > svg {\n  margin-bottom: 26px;\n}\n\n.content > p {\n  margin-bottom: 16px;\n  text-align: center;\n}\n\n.footer {\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "cadenceFeedbackModal-module__content___Y+pEe",
	"footer": "cadenceFeedbackModal-module__footer___ljnOr"
};
export default ___CSS_LOADER_EXPORT___;
