import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Spinner } from '@bloobirds-it/flamingo-ui';
import { useLoadingPromise } from '@bloobirds-it/hooks';
import { ActivityInsights, Bobject } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';


export const RelaunchInsights = ({ bobjects = [] }: { bobjects?: Bobject[] }) => {
  const { loading, promiseHandler } = useLoadingPromise();
  const { t } = useTranslation();
  const handleRelaunch = async () => {
    if (!bobjects?.length) return;

    await promiseHandler(
      async () => {
        for (const bobject of bobjects) {
          await new Promise(resolve => setTimeout(resolve, 1000));
          await api.get<ActivityInsights>(
            `/copilot/transcript/insights/${bobject?.id?.objectId}?regenerate=true`,
          );
        }
      },
      {
        successToast: 'ai.relaunchInsightsBulkAction.success',
        errorToast: 'ai.relaunchInsightsBulkAction.error',
      },
    );
  };

  return (
    <Button
      variant="clear"
      iconLeft={!loading ? 'refresh' : undefined}
      uppercase={false}
      onClick={() => handleRelaunch()}
    >
      <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {loading && <Spinner name="loadingCircle" size={10} />}
        {t(loading ? 'common.loading' : 'ai.relaunchInsightsBulkAction.name')}
      </span>
    </Button>
  );
};;