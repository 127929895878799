// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".startTaskSettings-module___wrap___FS0-o {\n  width: 360px;\n  padding: 20px;\n  min-height: 200px;\n  max-height: calc(100vh - 120px);\n  /*overflow: auto;*/\n}\n\n.startTaskSettings-module___header_wrapper___IB7hz {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 12px;\n  padding: 0 12px;\n}\n\n.startTaskSettings-module___radio_text___F\\+BQJ {\n  letter-spacing: 0.5px;\n  margin: 0;\n}\n\n.startTaskSettings-module___header_separator___m\\+jK\\+ {\n  margin-top: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/startTaskSettings/startTaskSettings.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,+BAA+B;EAC/B,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,SAAS;AACX;;AAEA;EACE,eAAe;AACjB","sourcesContent":["._wrap {\n  width: 360px;\n  padding: 20px;\n  min-height: 200px;\n  max-height: calc(100vh - 120px);\n  /*overflow: auto;*/\n}\n\n._header_wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 12px;\n  padding: 0 12px;\n}\n\n._radio_text {\n  letter-spacing: 0.5px;\n  margin: 0;\n}\n\n._header_separator {\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_wrap": "startTaskSettings-module___wrap___FS0-o",
	"_header_wrapper": "startTaskSettings-module___header_wrapper___IB7hz",
	"_radio_text": "startTaskSettings-module___radio_text___F+BQJ",
	"_header_separator": "startTaskSettings-module___header_separator___m+jK+"
};
export default ___CSS_LOADER_EXPORT___;
