const viewTableActions = Object.freeze({
  VIEW_TABLE_ELEMENTS_LOADED: 'VIEW_TABLE_ELEMENTS_LOADED',
  VIEW_TABLE_UPDATE_PAGE: 'VIEW_TABLE_UPDATE_PAGE',
  VIEW_TABLE_UPDATE_PAGE_SIZE: 'VIEW_TABLE_UPDATE_PAGE_SIZE',
  VIEW_TABLE_UPDATE_TOTAL_ELEMENTS: 'VIEW_TABLE_UPDATE_TOTAL_ELEMENTS',
  VIEW_TABLE_SET_TAGS: 'VIEW_TABLE_SET_TAGS',
  VIEW_TABLE_UPDATE_TEXT_QUERY: 'VIEW_TABLE_UPDATE_TEXT_QUERY',
  VIEW_TABLE_RELOAD_TRUE: 'VIEW_TABLE_RELOAD_TRUE',
  VIEW_TABLE_UPDATE_SEARCH_TEXT: 'VIEW_TABLE_UPDATE_SEARCH_TEXT',
  VIEW_TABLE_CLEAN_FILTERS: 'VIEW_TABLE_CLEAN_FILTERS',
});

export { viewTableActions };
