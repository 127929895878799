// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".activityCarouselTestSetReview-module__container___sVcmB {\n  width: 100%;\n  display: flex;\n  background-color: var(--white);\n  height: 100%;\n  overflow: hidden;\n}\n.activityCarouselTestSetReview-module__contentContainer___Z9TeY {\n  height: 90vh;\n  display: flex;\n  flex-direction: column;\n}\n.activityCarouselTestSetReview-module__contentContainer___Z9TeY > div {\n  flex-grow: 1;\n}\n\n.activityCarouselTestSetReview-module__footer___0IryE {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 8px;\n}\n\n.activityCarouselTestSetReview-module__focusViewChevron___gsN5c {\n  border: 1px solid var(--lightPurple);\n  border-radius: 4px;\n}\n.activityCarouselTestSetReview-module__focusViewChevron___gsN5c.activityCarouselTestSetReview-module__disabled___ymaFc {\n  border-color: var(--softPeanut);\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/playgroundPages/components/activityCarouselTestSetReview/activityCarouselTestSetReview.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;AACpB;AACA;EACE,+BAA+B;AACjC","sourcesContent":[".container {\n  width: 100%;\n  display: flex;\n  background-color: var(--white);\n  height: 100%;\n  overflow: hidden;\n}\n.contentContainer {\n  height: 90vh;\n  display: flex;\n  flex-direction: column;\n}\n.contentContainer > div {\n  flex-grow: 1;\n}\n\n.footer {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 8px;\n}\n\n.focusViewChevron {\n  border: 1px solid var(--lightPurple);\n  border-radius: 4px;\n}\n.focusViewChevron.disabled {\n  border-color: var(--softPeanut);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "activityCarouselTestSetReview-module__container___sVcmB",
	"contentContainer": "activityCarouselTestSetReview-module__contentContainer___Z9TeY",
	"footer": "activityCarouselTestSetReview-module__footer___0IryE",
	"focusViewChevron": "activityCarouselTestSetReview-module__focusViewChevron___gsN5c",
	"disabled": "activityCarouselTestSetReview-module__disabled___ymaFc"
};
export default ___CSS_LOADER_EXPORT___;
