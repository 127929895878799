export const RADIO_ACTION_TYPES = {
  CREATE_TASK: 'create task',
  START_CADENCE: 'start cadence',
  REASSIGN: 'reassign',
};

export const WORKFLOWS_ACTIONS_TYPES = {
  CLEAR_PROPERTY: 'Clear property',
  BLACKLIST: 'Blacklist',
  COPY_PROPERTY: 'Copy property',
  CREATE_NOTIFICATION: 'Create notification',
  CREATE_NOTIFICATION_LEAD: 'Create notification on lead',
  CREATE_NOTIFICATION_COMPANY: 'Create notification on company',
  CREATE_NOTIFICATION_OPPORTUNITY: 'Create notification on opportunity',
  CREATE_TASK: 'Create task',
  CREATE_TASK_LEAD: 'Create task on lead',
  CREATE_TASK_COMPANY: 'Create task on company',
  CREATE_TASK_OPPORTUNITY: 'Create task on opportunity',
  REASSIGN: 'Reassign',
  REASSIGN_LEAD: 'Reassign lead',
  REASSIGN_COMPANY: 'Reassign company',
  REASSIGN_OPPORTUNITY: 'Reassign opportunity',
  START_CADENCE: 'Start cadence',
  START_CADENCE_LEAD: 'Start cadence on lead',
  START_CADENCE_COMPANY: 'Start cadence on company',
  START_CADENCE_OPPORTUNITY: 'Start cadence on opportunity',
  STOP_CADENCE: 'Stop cadence',
  STOP_CADENCE_LEAD: 'Stop cadence on lead',
  STOP_CADENCE_COMPANY: 'Stop cadence on company',
  STOP_CADENCE_OPPORTUNITY: 'Stop cadence on opportunity',
  UPDATE_PROPERTY: 'Update property',
  UPDATE_PROPERTY_FOR_ALL_LEADS: 'Update property on all leads',
  CLEAR_PROPERTY_FOR_ALL_LEADS: 'Clear property on all leads',
  COPY_PROPERTY_FOR_ALL_LEADS: 'Copy property on all leads',
  REASSIGN_FOR_ALL_LEADS: 'Reassign all leads',
  START_CADENCE_FOR_ALL_LEADS: 'Start Cadence on all leads',
  STOP_CADENCE_FOR_ALL_LEADS: 'Stop Cadence on all leads',
  SEND_SMS: 'Send SMS',
  SEND_SMS_LEAD: 'Send SMS to lead',
  SEND_SMS_COMPANY: 'Send SMS to company',
};

export const WORKFLOWS_ACTIONS_TYPES_KEYS = {
  BLACKLIST: 'BLACKLIST',
  CLEAR_PROPERTY: 'CLEAR_PROPERTY',
  COPY_PROPERTY: 'COPY_PROPERTY',
  START_CADENCE: 'START_CADENCE',
  START_CADENCE_LEAD: 'START_CADENCE_LEAD',
  START_CADENCE_COMPANY: 'START_CADENCE_COMPANY',
  START_CADENCE_OPPORTUNITY: 'START_CADENCE_OPPORTUNITY',
  STOP_CADENCE: 'STOP_CADENCE',
  UPDATE_PROPERTY: 'UPDATE_PROPERTY',
  CREATE_NOTIFICATION: 'CREATE_NOTIFICATION',
  CREATE_NOTIFICATION_LEAD: 'CREATE_NOTIFICATION_LEAD',
  UPDATE_PROPERTY_FOR_ALL_LEADS: 'UPDATE_PROPERTY_FOR_ALL_LEADS',
  CLEAR_PROPERTY_FOR_ALL_LEADS: 'CLEAR_PROPERTY_FOR_ALL_LEADS',
  COPY_PROPERTY_FOR_ALL_LEADS: 'COPY_PROPERTY_FOR_ALL_LEADS',
  REASSIGN_ALL_LEADS: 'REASSIGN_ALL_LEADS',
  START_CADENCE_FOR_ALL_LEADS: 'START_CADENCE_FOR_ALL_LEADS',
  STOP_CADENCE_FOR_ALL_LEADS: 'STOP_CADENCE_FOR_ALL_LEADS',
  CREATE_NOTIFICATION_COMPANY: 'CREATE_NOTIFICATION_COMPANY',
  CREATE_NOTIFICATION_OPPORTUNITY: 'CREATE_NOTIFICATION_OPPORTUNITY',
  CREATE_TASK: 'CREATE_TASK',
  CREATE_TASK_LEAD: 'CREATE_TASK_LEAD',
  CREATE_TASK_COMPANY: 'CREATE_TASK_COMPANY',
  CREATE_TASK_OPPORTUNITY: 'CREATE_TASK_OPPORTUNITY',
  REASSIGN: 'REASSIGN',
  REASSIGN_LEAD: 'REASSIGN_LEAD',
  REASSIGN_COMPANY: 'REASSIGN_COMPANY',
  REASSIGN_OPPORTUNITY: 'REASSIGN_OPPORTUNITY',
  SEND_SMS: 'SEND_SMS',
};
