import { CopilotInsightVersion } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import { AxiosResponse } from 'axios';

type CreateTestRunBody = {
  testSetId: string;
  insightId: string;
};

export const useTestRuns = () => {
  const createTestRun: (body: CreateTestRunBody) => Promise<AxiosResponse<any, any>> = (
    body: CreateTestRunBody,
  ) => {
    return api.post('/copilot/playground/test-run', body);
  };
  const getInsightVersions: (
    versionId: string,
  ) => Promise<AxiosResponse<CopilotInsightVersion[], any>> = (versionId: string) =>
    api.get<CopilotInsightVersion[]>('/utils/service/copilot-insights/version/' + versionId);

  return {
    createTestRun,
    getInsightVersions,
  };
};
