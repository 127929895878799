import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@bloobirds-it/flamingo-ui';

export const ConfirmDeleteUserModal = ({
  confirmDeleteModal,
  onClose,
}: {
  confirmDeleteModal: { open: boolean; onSave?: () => void };
  onClose: () => void;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.salesTeam.teamUsersPage.teamManagementModal.confirmDeleteUserModal',
  });
  const i18nPrefix =
    'accountSettings.salesTeam.teamUsersPage.teamManagementModal.confirmDeleteUserModal.';
  return confirmDeleteModal?.open ? (
    <Modal open width="416px" onClose={onClose}>
      <ModalHeader size="small">
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Icon name="trashFull" color="bloobirds" size={16} />
          <Text size="s">{t('title')}</Text>
        </div>
        <IconButton name="cross" onClick={onClose} />
      </ModalHeader>
      <ModalContent>
        <div style={{ marginBottom: '16px' }}>
          <Text size="s">
            <Trans i18nKey={i18nPrefix + 'topMessage'} />
            <ul>
              {['cadenceWarning', 'filtersWarning'].map(key => (
                <li key={key}>
                  <Trans i18nKey={i18nPrefix + key} />
                </li>
              ))}
            </ul>
            <Trans i18nKey={i18nPrefix + 'bottomMessage'} />
          </Text>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button onClick={onClose} variant="clear">
          {t('cancelButton')}
        </Button>
        <Button
          onClick={() => {
            confirmDeleteModal?.onSave?.();
            onClose();
          }}
        >
          {t('deleteButton')}
        </Button>
      </ModalFooter>
    </Modal>
  ) : null;
};
