import React from 'react';
import { useTranslation } from 'react-i18next';

import { useEmailThreadActivities } from '@bloobirds-it/activity-feed';
import { Text } from '@bloobirds-it/flamingo-ui';
import { useDataModel, useActiveAccountId } from '@bloobirds-it/hooks';
import { useGetI18nSpacetime } from '@bloobirds-it/internationalization';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  Bobject,
  COMPANY_FIELDS_LOGIC_ROLE,
  LEAD_FIELDS_LOGIC_ROLE,
} from '@bloobirds-it/types';
import { getFieldByLogicRole, getRelatedBobject, getTextFromLogicRole } from '@bloobirds-it/utils';

import styles from '../infoAiAnalysis.module.css';
import { IconLabelAnalysis, InfoSectionAnalysis } from '../infoAiAnalysis.utils';

export const EmailAnalysis = ({ activity }: { activity: Bobject }) => {
  const { t, i18n } = useTranslation();

  const emailThreadId = getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.EMAIL_THREAD_ID);
  const accountId = useActiveAccountId();
  const { firstAndLast } = useEmailThreadActivities(emailThreadId, accountId);

  const format =
    i18n?.language === 'en'
      ? '{month-short} {date-pad} {year}, {time-24}'
      : '{date-pad} {month-short} {year}, {time-24}';
  const firstMessageDate = useGetI18nSpacetime(firstAndLast?.firstMessage).format(format);

  const lastMessageDate = useGetI18nSpacetime(firstAndLast?.lastMessage).format(format);

  const assignedTo = getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_ASSIGNED_TO);
  const title = getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.TITLE);
  const company = getRelatedBobject(activity, 'Company');
  const companyName = getTextFromLogicRole(company, COMPANY_FIELDS_LOGIC_ROLE.NAME);
  const lead = getRelatedBobject(activity, 'Lead');
  const leadName = getTextFromLogicRole(lead, LEAD_FIELDS_LOGIC_ROLE.FULL_NAME);

  return (
    <div className={styles.container}>
      <Text size="xl" weight="heavy">
        {title}
      </Text>
      {(companyName || leadName) && (
        <div className={styles.infoSubtitles}>
          {companyName && (
            <IconLabelAnalysis
              id="User name"
              iconProps={{ name: 'company', color: 'verySoftBloobirds', size: 20 }}
              labelProps={{
                weight: 'heavy',
                color: 'darkGray',
                size: 'm',
              }}
              text={companyName}
            />
          )}
          {leadName && (
            <IconLabelAnalysis
              id="User name"
              iconProps={{ name: 'person', color: 'verySoftBloobirds', size: 20 }}
              labelProps={{
                weight: 'heavy',
                color: 'darkGray',
                size: 'm',
              }}
              text={leadName}
            />
          )}
        </div>
      )}
      <div className={styles.infoSection}>
        <InfoSectionAnalysis
          icon="deliver"
          title={t('ai.aiAnalysis.firstMessage')}
          info={firstMessageDate || 'N/A'}
        />
        <InfoSectionAnalysis
          icon="calendar"
          title={t('ai.aiAnalysis.lastMessage')}
          info={lastMessageDate || 'N/A'}
        />
        <InfoSectionAnalysis
          icon="person"
          title={t('ai.aiAnalysis.assignedTo')}
          info={assignedTo}
        />
      </div>
    </div>
  );
};
