import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TableRow,
  TableCell,
  Label,
  Text,
  IconButton,
  Action,
  Dropdown,
  Button,
} from '@bloobirds-it/flamingo-ui';
import { useUserTeams } from '@bloobirds-it/hooks';
import { TeamType } from '@bloobirds-it/types';

import { UserInterface } from '../../constants/phoneNumbers.constants';
import { usePhoneNumberFormContext } from '../../context/phoneNumberFormProvider';
import styles from '../../styles/phoneNumberPage.module.css';
import { UserTeamsRow } from './userTeamsRow';

const CustomAvatar: React.FC<{ color: string; initials: string }> = ({ color, initials }) => {
  return (
    <div
      className={styles.avatar}
      style={{
        backgroundColor: color ? color : 'black',
      }}
    >
      <Text size="s" color="white" weight="medium">
        {initials}
      </Text>
    </div>
  );
};

function extractInitials(name: string) {
  if (name) {
    const [firstName, lastName] = name.split(' ');
    return `${firstName[0]}${lastName ? lastName[0] : ''}`;
  }
}

export const AssignedUsersRows = ({ user }: { user: UserInterface }) => {
  const { handleChange, handleSaveChanges, phoneNumber } = usePhoneNumberFormContext();
  const { t } = useTranslation();
  const { getTeamsByUserIdComplete } = useUserTeams();
  const teams = getTeamsByUserIdComplete(user.id);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  return (
    <TableRow>
      <TableCell>
        <div className={styles.userInformation}>
          <CustomAvatar color={user.color} initials={extractInitials(user.name)} />
          <Text size="s" color="peanut" weight="medium">
            {user.name}
          </Text>
        </div>
      </TableCell>
      <TableCell className={styles.tableCellContainer}>
        <Text size="s" className={styles.usersRowGroup} color="peanut" weight="medium">
          {user.email}
        </Text>
      </TableCell>
      <TableCell className={styles.tableCellContainer}>
        <div className={styles.usersRowGroup}>
          {teams ? (
            <UserTeamsRow teams={teams} />
          ) : (
            <Text size="s" color="peanut" weight="medium">
              No teams
            </Text>
          )}
        </div>
      </TableCell>
      <TableCell className={styles.tableCellContainer}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Dropdown
            visible={openDeleteModal}
            anchor={
              <IconButton
                className={styles.deleteIcon}
                onClick={() => setOpenDeleteModal(!openDeleteModal)}
                name="trashFull"
                color="bloobirds"
              />
            }
            onClose={() => setOpenDeleteModal(false)}
          >
            <div style={{ padding: '16px' }}>
              <Text size="s" color="peanut" weight="medium">
                {t('playbook.areYouSure')}
              </Text>
              <div style={{ paddingTop: '24px', display: 'flex', justifyContent: 'center' }}>
                <Button onClick={() => setOpenDeleteModal(false)} variant="clear">
                  {t('common.cancel')}
                </Button>
                <Button
                  color="tomato"
                  onClick={() => {
                    const updatedPhoneNumber = {
                      ...phoneNumber,
                      users: phoneNumber.users.filter((u: UserInterface) => u.id !== user.id),
                    };
                    handleChange(
                      'users',
                      phoneNumber.users.filter((u: UserInterface) => u.id !== user.id),
                    );
                    handleSaveChanges(updatedPhoneNumber);
                    setOpenDeleteModal(false);
                  }}
                >
                  {t('common.delete')}
                </Button>
              </div>
            </div>
          </Dropdown>
        </div>
      </TableCell>
    </TableRow>
  );
};
