import React from 'react';
import { useTranslation } from 'react-i18next';

import { EmailThreadDetail, LinkedInDetail } from '@bloobirds-it/activity-feed';
import { isTranscriptActivity } from '@bloobirds-it/copilot';
import { Spinner, Text } from '@bloobirds-it/flamingo-ui';
import { useUserSettings, useDataModel } from '@bloobirds-it/hooks';
import {
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  ACTIVITY_FIELDS_LOGIC_ROLE,
  Bobject,
} from '@bloobirds-it/types';
import { getTextFromLogicRole } from '@bloobirds-it/utils';
import Player from 'video.js/dist/types/player';

import styles from '../../aiAnalysisPage.module.css';
import { SfdcRecord, Source } from '../../useActivityAnalysis';
import MediaAiAnalysis from '../mediaAiAnalysis';

interface IDetailsAiAnalysisProps {
  isLoading: boolean;
  player: Player;
  setPlayer: (value: Player) => void;
  source: Source;
  sfdcRecord: SfdcRecord;
  activity: Bobject;
  activityType: ACTIVITY_TYPES_VALUES_LOGIC_ROLE;
}
export const DetailsAiAnalysis = ({
  isLoading,
  player,
  setPlayer,
  source,
  sfdcRecord,
  activity,
  activityType,
}: IDetailsAiAnalysisProps) => {
  const { t } = useTranslation();
  const settings = useUserSettings();
  const userId = settings?.user?.id;
  const accountId = settings?.account?.id;

  const dataModel = useDataModel();
  const isTranscriptAct = isTranscriptActivity(activityType);

  if (isLoading) {
    return (
      <div className={styles.noSource}>
        <Spinner size={36} name="dots" />
      </div>
    );
  }

  if (isTranscriptAct) {
    // calls or meetings
    if (source) {
      return (
        <MediaAiAnalysis
          source={source}
          player={player}
          setPlayer={setPlayer}
          sfdcRecord={sfdcRecord}
          activityType={activityType}
        />
      );
    } else {
      return (
        <div id="videoModule" className={styles.noSource}>
          {t('ai.aiAnalysis.noSource')}
        </div>
      );
    }
  }

  if (activityType === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL) {
    const emailThreadId = getTextFromLogicRole(
      activity,
      ACTIVITY_FIELDS_LOGIC_ROLE.EMAIL_THREAD_ID,
    );
    return (
      <div style={{ overflow: 'auto' }}>
        <EmailThreadDetail
          activity={activity}
          accountId={accountId}
          dataModel={dataModel}
          emailThreadId={emailThreadId}
          visibleFooter={false}
          actionsDisabled
          userId={userId}
          readOnly
        />
      </div>
    );
  }
  if (activityType === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.LINKEDIN) {
    return (
      <div style={{ overflow: 'auto', height: '100%' }}>
        <LinkedInDetail
          activity={activity}
          accountId={accountId}
          dataModel={dataModel}
          userId={userId}
          visibleFooter={false}
          hasUserEditAll={false}
          actionsDisabled
          hideHeader
        />
      </div>
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        gap: '1rem',
      }}
    >
      <Text size="m">{t('ai.aiAnalysis.notValidActivityType')}</Text>
    </div>
  );
};
