import React, { createContext, useState, useContext, useEffect } from 'react';

const DirtyContext = createContext();

export const DirtyProvider = ({ children }) => {
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = event => {
      if (isDirty) {
        const message = 'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = message;
        return message;
      }
    };

    window.onbeforeunload = handleBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);

  return <DirtyContext.Provider value={{ isDirty, setIsDirty }}>{children}</DirtyContext.Provider>;
};

export const useDirtyContext = () => useContext(DirtyContext);
