// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".actionsPanel-module___actions_container___GZ3Xq {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-left: 8px;\n  overflow: hidden;\n}\n\n.actionsPanel-module___actions_container___GZ3Xq * {\n  white-space: nowrap;\n}\n\n.actionsPanel-module___actions_button_container___j8ONi {\n  margin-right: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectTable/actionsPanel/actionsPanel.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["._actions_container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-left: 8px;\n  overflow: hidden;\n}\n\n._actions_container * {\n  white-space: nowrap;\n}\n\n._actions_button_container {\n  margin-right: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_actions_container": "actionsPanel-module___actions_container___GZ3Xq",
	"_actions_button_container": "actionsPanel-module___actions_button_container___j8ONi"
};
export default ___CSS_LOADER_EXPORT___;
