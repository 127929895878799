import React from 'react';

import { BobjectField, BobjectTypes, CopilotInsight } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import useSWR from 'swr';

const containsAnyWholeWord = (str, words) => {
  return words.some(word => {
    const regex = new RegExp(`(^|[^a-zA-Z0-9])${word}($|[^a-zA-Z0-9])`);
    return regex.test(str);
  });
};

export const useGetCoreInsights = (
  type: BobjectField<BobjectTypes, BobjectTypes>,
  mainTypeField: BobjectField<BobjectTypes, BobjectTypes>,
) => {
  const { data: coreInsights } = useSWR<CopilotInsight[]>('/utils/service/copilot-insights', key =>
    api.get<CopilotInsight[]>(key).then(res => res.data),
  );

  const filteredInsights = coreInsights?.filter(insight => {
    // If `type` is not provided, include all insights
    if (!type) return true;

    // Check if `insight.activityType` is 'all' or matches `type.text` (case-insensitive)
    const types = ['all', type.text?.toLocaleLowerCase()];
    const activityTypeMatch = containsAnyWholeWord(
      insight.activityType?.toLocaleLowerCase(),
      types,
    );

    // If `insight.activityType` does not match, exclude this insight
    if (!activityTypeMatch) return false;

    // If `insight.activitySubtype` is falsy or 'all', include this insight
    if (!insight.activitySubtype || insight.activitySubtype.toLocaleLowerCase() === 'all') {
      return true;
    }

    // If `mainTypeField.value` is not provided, include this insight
    if (!mainTypeField?.value) {
      return true;
    }

    // Finally, check if `mainTypeField.value` matches `insight.activitySubtype`
    return mainTypeField.value === insight.activitySubtype;
  });

  return filteredInsights;
};
