import { useState } from 'react';

import { useToasts } from '@bloobirds-it/flamingo-ui';
import { ConnectionResponse, Connections } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import useSWR, { SWRResponse } from 'swr';

function findDefaultConnectionEmail(response) {
  if (!response?.nylasTokens || !Array.isArray(response?.nylasTokens)) {
    throw new Error('Invalid response structure');
  }

  for (const connection of response.nylasTokens) {
    if (connection?.default && connection?.email) {
      return connection?.email;
    }

    if (Array.isArray(connection?.nylasAliases)) {
      for (const alias of connection.nylasAliases) {
        if (alias?.default && alias?.emailAlias) {
          return alias?.emailAlias;
        }
      }
    }
  }

  return null;
}

function getConnectionIdByEmail(data, selectedEmail) {
  if (!data?.list || !Array.isArray(data?.list)) {
    throw new Error('Invalid data structure');
  }

  for (const connection of data.list) {
    if (connection?.email === selectedEmail) {
      return connection?.id;
    }

    if (Array.isArray(connection?.nylasAliases)) {
      for (const alias of connection.nylasAliases) {
        if (alias?.emailAlias === selectedEmail) {
          return connection?.id;
        }
      }
    }
  }

  return null;
}

const fetchConnections = async url => {
  const response: ConnectionResponse = await api.get('/utils' + url).then(res => res.data);
  const defaultConnection = findDefaultConnectionEmail(response);

  return {
    list: response.nylasTokens,
    defaultConnection,
    stoppedConnections: response.nylasTokens.filter(
      token => token.syncState === 'stopped' || token.syncState === 'invalid',
    ),
  };
};

const submitDefaultConnection = body => api.patch('/utils/nylas/account/default', body);

const submitEmailAlias = async data => {
  await api.post('/entities/nylasUserAccountAliases', data);
};

const submitNewEmailAlias = async data => {
  await api.post('/utils/nylas/alias', data);
};

const submitUpdateAlias = async (id, data) => {
  await api.patch('/entities/nylasUserAccountAliases/' + id, data);
};

const removeEmailAlias = async id => {
  await api.delete('/entities/nylasUserAccountAliases/' + id);
};

const removeConnection = connectionId => {
  const url = `/utils/nylas/delete/${connectionId}`;
  return api.post(url, {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    data: {},
  });
};

export const useEmailConnections = () => {
  const { data: connections, mutate }: SWRResponse<Connections> = useSWR(
    '/nylas/connections',
    fetchConnections,
    {
      revalidateOnFocus: false,
    },
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { createToast } = useToasts();

  const updateDefaultConnection = newDefaultConnection => {
    setIsSubmitting(true);
    mutate({ ...connections, defaultConnection: newDefaultConnection }, false);
    createToast({ type: 'success', message: 'Your connection has been updated!' });
    submitDefaultConnection({ defaultEmail: newDefaultConnection }).then(() => {
      setIsSubmitting(false);
      mutate();
    });
  };

  const updateDefaultAliasConnection = newDefaultConnection => {
    setIsSubmitting(true);
    mutate({ ...connections, defaultConnection: newDefaultConnection }, false);
    const nylasAccountId = getConnectionIdByEmail(connections, newDefaultConnection);
    createToast({ type: 'success', message: 'Your connection has been updated!' });
    submitDefaultConnection({ defaultEmail: newDefaultConnection, nylasAccountId }).then(() => {
      setIsSubmitting(false);
      mutate();
    });
  };

  const disconnectConnection = (connectionId, isNylas, onError) => {
    setIsSubmitting(true);
    const listName = isNylas ? 'list' : 'legacyList';
    mutate(
      {
        ...connections,
        [listName]: connections[listName].filter(connection => connection.id !== connectionId),
      },
      false,
    );
    createToast({ type: 'success', message: 'Your connection has been removed!' });
    removeConnection(connectionId)
      .then(() => {
        setIsSubmitting(false);
      })
      .catch(() => onError?.());
  };

  const addAlias = async data => {
    await submitEmailAlias(data);
    mutate();
  };

  const addNewAlias = async data => {
    await submitNewEmailAlias(data);
    mutate();
  };

  const updateAlias = async (id, data) => {
    await submitUpdateAlias(id, data);
    mutate();
  };

  const removeAlias = async id => {
    await removeEmailAlias(id);
    mutate();
  };

  return {
    connections,
    mutate,
    disconnectConnection,
    updateDefaultConnection,
    updateDefaultAliasConnection,
    addAlias,
    addNewAlias,
    removeAlias,
    updateAlias,
    isSubmitting,
    stoppedConnections: connections?.stoppedConnections,
  };
};
