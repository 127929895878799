// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".deleteCompaniesRelatedModal___modal_header___L3N5q {\n  box-sizing: border-box;\n  padding: 16px;\n}\n\n.deleteCompaniesRelatedModal___modal_content___pymle {\n  box-sizing: border-box;\n  padding: 32px 58px;\n}\n\n.deleteCompaniesRelatedModal___modal_footer___02ee6 {\n  box-sizing: border-box;\n  padding: 0 32px 40px 32px;\n}\n\n.deleteCompaniesRelatedModal___sections_container___x\\+cOI {\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/contactPages/navegationBar/companiesRelatedDropdown/deleteCompanyRelatedModal/deleteCompaniesRelatedModal.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf","sourcesContent":["._modal_header {\n  box-sizing: border-box;\n  padding: 16px;\n}\n\n._modal_content {\n  box-sizing: border-box;\n  padding: 32px 58px;\n}\n\n._modal_footer {\n  box-sizing: border-box;\n  padding: 0 32px 40px 32px;\n}\n\n._sections_container {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_modal_header": "deleteCompaniesRelatedModal___modal_header___L3N5q",
	"_modal_content": "deleteCompaniesRelatedModal___modal_content___pymle",
	"_modal_footer": "deleteCompaniesRelatedModal___modal_footer___02ee6",
	"_sections_container": "deleteCompaniesRelatedModal___sections_container___x+cOI"
};
export default ___CSS_LOADER_EXPORT___;
