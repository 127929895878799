import React from 'react';
import { useTranslation } from 'react-i18next';

import { ColorType, Icon, IconType, Text } from '@bloobirds-it/flamingo-ui';
import { StepState } from '@bloobirds-it/types';
import { motion } from 'framer-motion';

import styles from './otoQSGSteps.module.css';

type ExpandableSubsectionProps = {
  title: string;
  content: React.ReactNode;
  leftIcon: IconType;
  state: StepState;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

const ExpandableSubsection: React.FC<ExpandableSubsectionProps> = ({
  title,
  content,
  leftIcon,
  state,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();

  const getLabel = () => {
    switch (state) {
      case StepState.BLOCKED:
        return t('quickStartGuide.oto.blocks.blocked');
      case StepState.SKIPPED:
        return t('quickStartGuide.oto.blocks.skipped');
      case StepState.COMPLETED:
        return t('quickStartGuide.oto.blocks.completed');
      default:
        return t('quickStartGuide.oto.blocks.pending');
    }
  };

  const getStyles = () => {
    switch (state) {
      case StepState.BLOCKED:
        return {
          backgroundColor: '#D4E0F1',
          dividerColor: 'var(--lightPeanut)',
          color: '#464F57',
          textColor: 'softPeanut',
        };
      case StepState.COMPLETED:
        return {
          backgroundColor: '#EAF4E0',
          dividerColor: 'var(--melon)',
          color: '#63BA00',
          textColor: 'softPeanut',
        };
      case StepState.SKIPPED:
        return {
          backgroundColor: '#EAF4E0',
          dividerColor: 'var(--melon)',
          color: '#63BA00',
          textColor: 'softPeanut',
        };
      default:
        return {
          backgroundColor: '#D4E0F1',
          dividerColor: 'var(--lightPeanut)',
          color: '#464F57',
          textColor: isOpen ? 'bloobirds' : 'peanut',
        };
    }
  };

  const handleToggle = () => {
    if (state !== StepState.BLOCKED) {
      setIsOpen(!isOpen);
    }
  };

  const label = getLabel();
  const { backgroundColor, dividerColor, color, textColor } = getStyles();

  return (
    <div className={styles.expandableSubsection}>
      <div className={`${styles.header} ${isOpen ? styles.active : ''}`} onClick={handleToggle}>
        <div className={styles.leftSide}>
          <Icon name={leftIcon} size={24} />
          <Text color={textColor as ColorType} size="m" weight="bold" className={styles.title}>
            {title}
          </Text>
        </div>
        <div className={styles.rightSide}>
          <div
            className={styles.label}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              justifyContent: 'center',
              backgroundColor,
              color,
            }}
          >
            {state === StepState.BLOCKED && <Icon name="lock" size={14} color="peanut" />}
            <span>{label}</span>
          </div>

          <Icon
            name="chevronDown"
            size={24}
            className={`${styles.icon} ${isOpen ? styles.rotated : ''}`}
          />
        </div>
      </div>
      <div
        style={{
          zIndex: 100,
          position: 'relative',
          height: '3px',
          backgroundColor: dividerColor,
          width: 'calc(100% - 1px)',
          borderRadius: '0px 0px 4px 4px',
          translate: '0 -4px',
        }}
      ></div>

      <motion.div
        className={`${styles.content} ${isOpen ? styles.active : ''}`}
        initial={{ height: 0 }}
        animate={{ height: isOpen ? 'auto' : 0 }}
        transition={{ duration: 0.3 }}
        style={{ overflow: 'hidden', visibility: isOpen ? 'visible' : 'hidden' }}
      >
        {content}
      </motion.div>
    </div>
  );
};

export default ExpandableSubsection;
