import React, { useEffect, useState } from 'react';

import { GeneralPrompt } from '@bloobirds-it/types';
import isEqual from 'lodash/isEqual';

import { GeneralPromptsColumn } from '../components/generalPromptsColumn/generalPromptsColumn';
import { GeneralPromptsSelectedDetail } from '../components/generalPromptsSelectedDetail/generalPromptsSelectedDetail';
import { GeneralPromptsSubTypesColumn } from '../components/generalPromptsSubtypesColumn/generalPromptsSubtypesColumn';
import { useGeneralPrompts } from '../hooks/useGeneralPrompts';
import styles from '../playgroundPages.module.css';

const GeneralPrompts = () => {
  const { accountPrompts, accountPromptsArr } = useGeneralPrompts();

  const [selectedPrompt, setSelectedPrompt] = useState<GeneralPrompt | null>(null);
  const [selectedSubType, setSelectedSubType] = useState<GeneralPrompt | null>(null);

  useEffect(() => {
    if (selectedSubType && !selectedSubType?.promptType.includes(selectedPrompt?.promptType)) {
      setSelectedSubType(null);
    }
  }, [selectedPrompt]);

  useEffect(() => {
    if (!accountPrompts) return;

    if (selectedSubType) {
      const updatedSubType = accountPrompts.get(selectedSubType.promptType);
      setSelectedSubType(updatedSubType || null);
    }
    if (selectedPrompt) {
      const updatedPrompt = accountPrompts.get(selectedPrompt.promptType);
      if (!isEqual(selectedPrompt, updatedPrompt)) {
        setSelectedPrompt(updatedPrompt || null);
      }
    }
  }, [accountPromptsArr]);

  return (
    <div className={styles.playground_content}>
      <GeneralPromptsColumn
        accountPrompts={accountPrompts}
        selectedPrompt={selectedPrompt}
        setSelectedPrompt={setSelectedPrompt}
      />
      <div className={styles.divider} />
      <GeneralPromptsSubTypesColumn
        selectedPrompt={selectedPrompt}
        selectedSubType={selectedSubType}
        setSelectedSubType={setSelectedSubType}
      />
      <div className={styles.divider} />
      <GeneralPromptsSelectedDetail
        defaultPrompt={selectedPrompt}
        selectedSubType={selectedSubType}
      />
    </div>
  );
};

export default GeneralPrompts;
