import { useController } from 'react-hook-form';

import { FormGroup, FormLabel } from '../../formGroup/formGroup';
import { FormFieldProps, Input } from '../baseInput/baseInput';

export const DecimalField = ({
  required,
  control,
  name,
  id,
  size = 'small',
  requiredMessage,
  hideLabel,
  formKey,
  withFormGroup = true,
}: FormFieldProps) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name: formKey ?? `fields.${id}`,
    rules: {
      required: {
        value: required,
        message: requiredMessage,
      },
    },
  });

  const simpleDecimalField = (
    <>
      {size === 'small' && !hideLabel && <FormLabel required={required}>{name}</FormLabel>}
      <Input
        size={size}
        value={value}
        placeholder={size === 'small' ? undefined : `${name}${required ? ' *' : ''}`}
        onChange={onChange}
        onBlur={onBlur}
        error={error?.message}
        type="number"
      />
    </>
  );

  return withFormGroup ? (
    <FormGroup size={hideLabel ? undefined : size}>{simpleDecimalField}</FormGroup>
  ) : (
    simpleDecimalField
  );
};
