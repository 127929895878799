import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@bloobirds-it/flamingo-ui';
import { TestRun } from '@bloobirds-it/types';

import styles from '../../../playgroundPages.module.css';
import { getProgressPercentage } from '../../../utils';

export const LoadingResults = ({ testRun }: { testRun: TestRun }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground' });
  return (
    <div className={styles.loadingResultsContainer}>
      <div className={styles.loadingResultsProgressContainer}>
        <Text weight="bold" color="purple">
          {t('activityTab.processing')}
        </Text>
        <div className={styles.progressBackground}>
          <div
            style={{ width: getProgressPercentage(testRun) }}
            className={styles.progressForeground}
          ></div>
          <p className={styles.progress}>{getProgressPercentage(testRun)}</p>
        </div>
      </div>
    </div>
  );
};
