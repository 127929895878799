import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  ActivityBobject,
  Bobject,
} from '@bloobirds-it/types';
import { api, getFieldByLogicRole, getTextFromLogicRole } from '@bloobirds-it/utils';
import { atom, useRecoilState, useResetRecoilState } from 'recoil';

import { CheckTestSet, CheckTestSetResponse } from '../typings/playground';

interface ICheckedActivities {
  activitiesWithTranscript: number;
  activitiesWithoutTranscript: number;
  invalidActivities: number;
}
interface SendToPlaygroundModalState {
  isOpen: boolean;
  bobjects: null | Bobject[];
  typeMismatch: boolean;
  checkingActivities: boolean;
  checkedActivities: null | ICheckedActivities;
  type: ACTIVITY_TYPES_VALUES_LOGIC_ROLE;
  validActivitiesCount: number;
  checkedActivitiesIds: CheckTestSetResponse | null;
}

const sendToPlaygroundModalAtom = atom<SendToPlaygroundModalState>({
  key: 'sendToPlaygroundModal',
  default: {
    isOpen: false,
    bobjects: null,
    typeMismatch: false,
    checkingActivities: false,
    checkedActivities: null,
    type: null,
    validActivitiesCount: 0,
    checkedActivitiesIds: null,
  },
});

const allHaveSameType = (bobjects: Bobject[]) => {
  const targetType = getTextFromLogicRole(bobjects[0], ACTIVITY_FIELDS_LOGIC_ROLE.TYPE);
  return bobjects.every(bobject => {
    const type = getTextFromLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE);
    return targetType === type;
  });
};

const useSendToPlaygroundModal = () => {
  const [state, setState] = useRecoilState(sendToPlaygroundModalAtom);
  const resetState = useResetRecoilState(sendToPlaygroundModalAtom);

  const closeSendToPlaygroundModal = () => {
    resetState();
  };

  const checkActivities = (data: CheckTestSet) => {
    return api
      .post<CheckTestSetResponse>('/copilot/playground/test-set/check', data)
      .then(res => res.data);
  };

  const filterBySingleActivityThread = (bobjects: Bobject[]) => {
    if (!bobjects) return [];
    const type = getFieldByLogicRole(bobjects[0], ACTIVITY_FIELDS_LOGIC_ROLE.TYPE);

    if (type?.valueLogicRole === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL) {
      const threads: Record<string, boolean> = {};

      return bobjects.filter(bob => {
        const threadId = getTextFromLogicRole(bob, ACTIVITY_FIELDS_LOGIC_ROLE.EMAIL_THREAD_ID);

        if (!threadId || !threads[threadId]) {
          threads[threadId] = true;
          return true;
        } else return false;
      });
    }
    if (type?.valueLogicRole === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.LINKEDIN) {
      const leadsMap: Record<string, boolean> = {};
      const filteredActivities = bobjects.filter(bob => {
        const LeadId = getTextFromLogicRole(bob, ACTIVITY_FIELDS_LOGIC_ROLE.LEAD);
        const note = getTextFromLogicRole(bob, ACTIVITY_FIELDS_LOGIC_ROLE.NOTE);
        const messageBody = getTextFromLogicRole(bob, ACTIVITY_FIELDS_LOGIC_ROLE.MESSAGE_BODY);

        if ((note || messageBody) && (!LeadId || !leadsMap[LeadId])) {
          leadsMap[LeadId] = true;
          return true;
        } else return false;
      });
      return filteredActivities;
    }
    return bobjects;
  };

  const threadCounter = (bobjects: Bobject[], type: ACTIVITY_TYPES_VALUES_LOGIC_ROLE) => {
    if (!bobjects || bobjects.length === 0 || !state.checkedActivitiesIds) return 0;
    const validActivities = new Set([
      ...(state?.checkedActivitiesIds?.activitiesWithTranscript || []),
      ...(state?.checkedActivitiesIds?.activitiesWithoutTranscript || []),
    ]);
    const threadMap = new Map();
    bobjects.forEach(bob => {
      let groupingId;
      if (type === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL) {
        groupingId = getTextFromLogicRole(bob, ACTIVITY_FIELDS_LOGIC_ROLE.EMAIL_THREAD_ID);
      }
      if (type === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.LINKEDIN) {
        groupingId = getTextFromLogicRole(bob, ACTIVITY_FIELDS_LOGIC_ROLE.LEAD);
      }
      if (!threadMap.get(groupingId) && validActivities.has(bob.id.objectId)) {
        threadMap.set(groupingId, true);
      }
    });
    return threadMap.size;
  };
  const openSendToPlaygroundModal = (bobjects: ActivityBobject[]) => {
    const allMatchType = allHaveSameType(bobjects);
    const type = getFieldByLogicRole(bobjects[0], ACTIVITY_FIELDS_LOGIC_ROLE.TYPE);
    const state: SendToPlaygroundModalState = {
      isOpen: true,
      bobjects,
      typeMismatch: !allMatchType,
      checkingActivities: true,
      checkedActivities: null,
      type: type?.valueLogicRole as ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
      validActivitiesCount: 0,
      checkedActivitiesIds: null,
    };

    checkActivities({
      activities: filterBySingleActivityThread(bobjects).map(b => b.id.objectId),
    }).then(checkRes =>
      setState({
        ...state,
        checkedActivitiesIds: checkRes,
        checkedActivities: {
          activitiesWithTranscript: checkRes.activitiesWithTranscript.length,
          activitiesWithoutTranscript: checkRes.activitiesWithoutTranscript.length,
          invalidActivities:
            checkRes.activitiesChecked.length -
            checkRes.activitiesWithTranscript.length -
            checkRes.activitiesWithoutTranscript.length,
        },
        checkingActivities: false,
        validActivitiesCount:
          checkRes.activitiesWithTranscript.length + checkRes.activitiesWithoutTranscript.length,
      }),
    );

    setState(state);
  };

  return {
    ...state,
    threadCounter,
    openSendToPlaygroundModal,
    closeSendToPlaygroundModal,
    filterBySingleActivityThread,
  };
};

export default useSendToPlaygroundModal;
