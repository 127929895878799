// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".name-module___container___iZZcl {\n  text-decoration: none;\n  color: var(--bloobirds);\n  margin-right: 8px;\n  max-width: 120px;\n  text-align: justify;\n  text-overflow: ellipsis;\n  font-size: 14px;\n  display: inline-flex;\n}\n\n.name-module___container___iZZcl:hover {\n  cursor: pointer;\n}\n\n.name-module___is_complete___FnEP2,\n.name-module___is_complete___FnEP2 > * {\n  text-decoration: line-through;\n  text-align: left;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/subhomePages/salesPage/name/name.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,uBAAuB;EACvB,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,eAAe;AACjB;;AAEA;;EAEE,6BAA6B;EAC7B,gBAAgB;AAClB","sourcesContent":["._container {\n  text-decoration: none;\n  color: var(--bloobirds);\n  margin-right: 8px;\n  max-width: 120px;\n  text-align: justify;\n  text-overflow: ellipsis;\n  font-size: 14px;\n  display: inline-flex;\n}\n\n._container:hover {\n  cursor: pointer;\n}\n\n._is_complete,\n._is_complete > * {\n  text-decoration: line-through;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "name-module___container___iZZcl",
	"_is_complete": "name-module___is_complete___FnEP2"
};
export default ___CSS_LOADER_EXPORT___;
