// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".syncStatusTab-module___card_container___ueiDq {\n  background-color: white;\n  box-sizing: border-box;\n  padding: 32px 60px 60px 60px;\n  border: 1px solid var(--verySoftCondition);\n  border-radius: 4px;\n}\n\n.syncStatusTab-module___api_call_title___OXJVl {\n  display: flex;\n}\n.syncStatusTab-module___api_call_title___OXJVl > p {\n  padding-left: 12px;\n}\n.syncStatusTab-module___api_call_text___qVkO8 {\n  display: flex;\n  justify-content: space-between;\n  box-sizing: border-box;\n  padding-top: 40px;\n}\n.syncStatusTab-module___api_call_text___qVkO8 > p {\n  padding-bottom: 8px;\n}\n\n.syncStatusTab-module___sync_logs_table_header___lBgy9 {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/integrationLayout/syncStatusTabTemplate/syncStatusTab.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,sBAAsB;EACtB,4BAA4B;EAC5B,0CAA0C;EAC1C,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,sBAAsB;EACtB,iBAAiB;AACnB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB","sourcesContent":["._card_container {\n  background-color: white;\n  box-sizing: border-box;\n  padding: 32px 60px 60px 60px;\n  border: 1px solid var(--verySoftCondition);\n  border-radius: 4px;\n}\n\n._api_call_title {\n  display: flex;\n}\n._api_call_title > p {\n  padding-left: 12px;\n}\n._api_call_text {\n  display: flex;\n  justify-content: space-between;\n  box-sizing: border-box;\n  padding-top: 40px;\n}\n._api_call_text > p {\n  padding-bottom: 8px;\n}\n\n._sync_logs_table_header {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_card_container": "syncStatusTab-module___card_container___ueiDq",
	"_api_call_title": "syncStatusTab-module___api_call_title___OXJVl",
	"_api_call_text": "syncStatusTab-module___api_call_text___qVkO8",
	"_sync_logs_table_header": "syncStatusTab-module___sync_logs_table_header___lBgy9"
};
export default ___CSS_LOADER_EXPORT___;
