import { getTokenWebapp, manageRefreshToken } from '@bloobirds-it/utils';
import { stringify } from 'query-string';

import SessionManagerFactory from '../session';

const SessionManager = SessionManagerFactory();

const buildOptions = (method, body, includeAuth, xlsxDownload, xlsxUpload) => {
  let headers = {
    'Access-Control-Allow-Headers': 'Authorization',
    'x-include-references-to-many': true,
  };
  if (!xlsxUpload) {
    headers = {
      ...headers,
      'content-type': 'application/json',
    };
  }
  if (!xlsxDownload && !xlsxUpload) {
    headers = {
      ...headers,
      Accept: 'application/json',
    };
  }

  const ops = {
    headers,
    method,
    mode: 'cors',
  };

  if (!xlsxDownload && includeAuth) {
    const token = getTokenWebapp();
    ops.headers.Authorization = `Bearer ${token}`;
  }
  const fingerprint = localStorage.getItem('bb-hashed-fingerprint');
  if (fingerprint) {
    ops.headers['X-Fingerprint'] = fingerprint;
  }
  const refreshToken = localStorage.getItem('bb-refresh-token');
  if (refreshToken) {
    ops.headers['X-Refresh-Token'] = refreshToken;
  }
  if (xlsxUpload) {
    ops.body = body;
  } else if (body !== undefined) {
    ops.body = JSON.stringify(body);
  }
  return ops;
};

export const request = ({
  host,
  url,
  method,
  body,
  requestParams,
  failureAction,
  includeAuth = true,
  xlsxDownload,
  xlsxUpload,
}) => {
  let params = '';
  if (requestParams !== undefined && Object.keys(requestParams).length > 0) {
    params = `?${stringify(requestParams)}`;
  }
  const endpoint = `${host}${url}${params}`;
  const options = buildOptions(method, body, includeAuth, xlsxDownload, xlsxUpload);
  return fetch(endpoint, options).then(async response => {
    if (response.status === 200 || response.status === 201) {
      const newToken = response.headers.get('X-Refreshed-Auth-Token');
      if (newToken) {
        SessionManager.setToken(newToken);
        localStorage.setItem('bb-token', newToken);
      }
      return xlsxDownload ? response : response.json();
    }
    if (response.status === 202 || response.status === 204) {
      return response;
    }

    if (response.status === 401 || response.status === 403) {
      if (response.status === 401) {
        const data = await response?.json();
        response.data = data;
      }

      const error = {
        message: 'Unauthorized',
        response,
        request: {
          url: response.url,
          method,
        },
      };

      return await manageRefreshToken(
        error,
        true,
        async () =>
          await request({
            host,
            url,
            method,
            body,
            requestParams,
            failureAction,
            includeAuth,
            xlsxDownload,
            xlsxUpload,
          }),
      );
    }

    console.log('Failed to fetch:', endpoint, response);
    return Promise.reject({ type: failureAction, response });
  });
};
