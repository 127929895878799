import { Skeleton } from '@bloobirds-it/flamingo-ui';

import style from './relationsSkeleton.module.css';

export const RelatedObjectCardSkeleton = () => (
  <div className={style.cardContainer}>
    <Skeleton variant="rect" width="100%" height={20} />
    <div className={style.cardFieldsContainer}>
      <Skeleton variant="rect" width="45%" height={14} />
      <Skeleton variant="rect" width="45%" height={14} />
      <Skeleton variant="rect" width="45%" height={14} />
      <Skeleton variant="rect" width="45%" height={14} />
    </div>
  </div>
);

export const RelationsFeedSkeleton = () => (
  <div className={style.feedContainer}>
    <Skeleton variant="text" height={20} width="30%" />
    <Skeleton variant="rect" width="100%" height={120} />
    <Skeleton variant="text" height={20} width="30%" />
    <Skeleton variant="rect" width="100%" height={120} />
  </div>
);

export const RelatedCardsListSkeleton = ({ length = 3 }: { length?: number }) => (
  <div className={style.listContainer}>
    <Skeleton variant="rect" width={108} height={16} />
    {Array.from({ length }).map((_, i) => (
      <RelatedObjectCardSkeleton key={i} />
    ))}
  </div>
);
