// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".duplicateValidationModal-module___body___hykXH {\n  width: 692px;\n  margin: 24px auto 0;\n}\n\n.duplicateValidationModal-module___card_list___3hGos {\n  margin-top: 36px;\n  display: flex;\n  justify-content: center;\n  gap: 32px;\n}\n\n.duplicateValidationModal-module___body_footer___HlKlQ {\n  margin-top: 32px;\n  margin-bottom: 48px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/duplicateValidationModal/duplicateValidationModal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["._body {\n  width: 692px;\n  margin: 24px auto 0;\n}\n\n._card_list {\n  margin-top: 36px;\n  display: flex;\n  justify-content: center;\n  gap: 32px;\n}\n\n._body_footer {\n  margin-top: 32px;\n  margin-bottom: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_body": "duplicateValidationModal-module___body___hykXH",
	"_card_list": "duplicateValidationModal-module___card_list___3hGos",
	"_body_footer": "duplicateValidationModal-module___body_footer___HlKlQ"
};
export default ___CSS_LOADER_EXPORT___;
