// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qualifiyingQuestionsPlaceholder__list___tKCRa {\n  margin-top: 32px;\n}\n\n.qualifiyingQuestionsPlaceholder__question___s1OJa {\n  margin-bottom: 32px;\n}\n\n.qualifiyingQuestionsPlaceholder__questionTitle___jT4xt {\n  margin-bottom: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/qualifyingQuestions/qualifyingQuestionsPlaceholder/qualifiyingQuestionsPlaceholder.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".list {\n  margin-top: 32px;\n}\n\n.question {\n  margin-bottom: 32px;\n}\n\n.questionTitle {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "qualifiyingQuestionsPlaceholder__list___tKCRa",
	"question": "qualifiyingQuestionsPlaceholder__question___s1OJa",
	"questionTitle": "qualifiyingQuestionsPlaceholder__questionTitle___jT4xt"
};
export default ___CSS_LOADER_EXPORT___;
