import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input, Switch, Text } from '@bloobirds-it/flamingo-ui';
import { CadenceTagType } from '@bloobirds-it/types';
import { id } from 'date-fns/locale';

import AutoSizeTag from '../../../../cadencesPage/cadenceEditionPage/cadenceEditionHeader/cadenceTags/autoSizeTag';
import { CadenceTag } from '../../../../cadencesPage/cadenceEditionPage/cadenceEditionHeader/cadenceTags/cadenceTagsBlock';
import { PhoneNumber } from '../../constants/phoneNumbers.constants';
import { usePhoneNumberFormContext } from '../../context/phoneNumberFormProvider';
import { usePaginatedPhoneEntity } from '../../hooks/usePaginatedPhoneEntity';
import { helperRemoveTag } from '../../utils/phoneHelper';
import { StatusCard } from '../rows/statusCard';
import styles from './../../styles/phoneNumberPage.module.css';
import { ActiveConfigurationCard } from './activeConfigurationCard';

export const PhoneNumberConfiguration = ({ phone }: { phone: PhoneNumber }) => {
  const { t } = useTranslation();
  const [addTagVisible, setAddTagVisible] = useState(false);
  const {
    phoneNumber,
    handleChange,
    handleSaveChanges,
    hasChanges,
    isLoading,
  } = usePhoneNumberFormContext();
  const { allTags, setShowPhoneSettings } = usePaginatedPhoneEntity();

  const onAddCallback = async (tag: string | CadenceTagType) => {
    setAddTagVisible(false);
    const tagName = typeof tag === 'string' ? tag : tag.name;
    if (phoneNumber.tags.includes(tagName) || tagName === '') {
      return;
    }
    const tagsToSet = [...phoneNumber.tags, tagName];
    handleChange('tags', tagsToSet);
  };

  const unSelectedCadenceTags = useMemo(
    () =>
      allTags.filter(
        (tag: CadenceTagType) => !phoneNumber.tags.map((t: any) => t?.name).includes(tag?.name),
      ),
    [phoneNumber.tags, allTags],
  );

  return (
    <div className={styles.phoneConfiguration}>
      <>
        <Text size="m" color="peanut" weight="bold">
          {t('accountSettings.phoneNumbers.phoneSettings.tabs.generalSettings.numberStatus')}
        </Text>
        <div className={styles.itemInformation} style={{ marginTop: '12px' }}>
          <div className={styles.blueSwitch}>
            <Switch
              checked={phoneNumber.status}
              onChange={checked => handleChange('status', checked)}
            />
            <Text size="s" color="peanut" weight="regular">
              {t(
                'accountSettings.phoneNumbers.phoneSettings.tabs.generalSettings.numberStatusText',
              )}
            </Text>
          </div>
          <StatusCard phone={phoneNumber} showConfig={false} />
        </div>
      </>

      <div className={styles.phoneNameContainer}>
        <Text size="m" color="peanut" weight="bold">
          {t('accountSettings.phoneNumbers.phoneSettings.tabs.generalSettings.phoneName')}
        </Text>
        <div className={styles.itemInformation}>
          <Text size="xs" color="softPeanut" weight="regular">
            {t('accountSettings.phoneNumbers.phoneSettings.tabs.generalSettings.phoneNameSubtext')}
          </Text>
        </div>
        <Input
          height="40px"
          width="310px"
          size="medium"
          value={phoneNumber.name}
          onChange={e => handleChange('name', e)}
        />
      </div>

      <div className={styles.phoneNameContainer}>
        <Text size="m" color="peanut" weight="bold">
          {t('accountSettings.phoneNumbers.phoneSettings.tabs.generalSettings.activeConfiguration')}
        </Text>
        <div className={styles.itemInformation}>
          <Text size="xs" color="softPeanut" weight="regular">
            {t(
              'accountSettings.phoneNumbers.phoneSettings.tabs.generalSettings.activeConfigurationSubtext',
            )}
          </Text>
        </div>
        <div className={styles.capabilitiesContainer}>
          {phoneNumber.capabilities.map((capability: string, index: number) => (
            <ActiveConfigurationCard
              key={index}
              capability={capability}
              activeConfiguration={phoneNumber.activeConfiguration}
              handleChange={handleChange}
            />
          ))}
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'between', alignItems: 'end' }}>
        <div className={styles.phoneNameContainer}>
          <Text size="m" color="peanut" weight="bold">
            {t('accountSettings.phoneNumbers.phoneSettings.tabs.generalSettings.tags')}
          </Text>
          <div className={styles.itemInformation}>
            <Text size="xs" color="softPeanut" weight="regular">
              {t('accountSettings.phoneNumbers.phoneSettings.tabs.generalSettings.tagsSubtext')}
            </Text>
          </div>
          <div className={styles.itemInformation}>
            {phoneNumber.tags.map((tag: string, index: number) => (
              <CadenceTag
                key={index}
                tag={{ id: index.toString(), name: tag }}
                handleRemoveTag={() => {
                  helperRemoveTag(tag, phoneNumber.tags, handleChange);
                }}
              />
            ))}
            {addTagVisible ? (
              <AutoSizeTag cadenceTags={unSelectedCadenceTags} onAddCallback={onAddCallback} />
            ) : (
              <Button
                variant="clear"
                iconLeft="plus"
                size="small"
                onClick={() => {
                  setAddTagVisible(true);
                }}
                uppercase={false}
              >
                Add tag
              </Button>
            )}
          </div>
        </div>

        <Button
          disabled={!hasChanges() || isLoading}
          type="button"
          onClick={() => {
            handleSaveChanges(phoneNumber);
            setShowPhoneSettings({ phone: phoneNumber, show: true });
          }}
          className={styles.saveButton}
        >
          {t('sidePeek.contactRelatedCompanies.saveChanges')}
        </Button>
      </div>
    </div>
  );
};
