import { useUserSettings } from '@bloobirds-it/hooks';
import { api } from '@bloobirds-it/utils';
import useSWR from 'swr';

const fetcher = url => api.get(url).then(res => res.data);

export const usePhoneFilters = () => {
  const settings = useUserSettings();

  const { data, error } = useSWR(
    `/calls/settings/phoneNumbers/filters/${settings.account.id}`,
    fetcher,
  );

  return {
    filtersSelection: data,
    isLoading: !error && !data,
    isError: error,
  };
};
