// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".alertMessage-module___accounts_alert_link___A7-UA {\n  color: #1991ff;\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.alertMessage-module___pause_icon___jA5dF {\n  margin-right: 20px;\n}\n\n.alertMessage-module___pause_text_container___SPtuB {\n  display: flex;\n  align-items: center;\n}\n\n.alertMessage-module___edit_workflow_text_container___kbiid {\n  padding-left: 110px;\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n}\n\n.alertMessage-module___edit_workflow_text_container___kbiid > p {\n  padding-top: 4px;\n}\n\n.alertMessage-module___lead_cadence_link___dWtQs {\n  font-weight: bold;\n  cursor: pointer;\n  color: var(--bloobirds);\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/accountAlerts/alertMessage/alertMessage.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,uBAAuB;AACzB","sourcesContent":["._accounts_alert_link {\n  color: #1991ff;\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n._pause_icon {\n  margin-right: 20px;\n}\n\n._pause_text_container {\n  display: flex;\n  align-items: center;\n}\n\n._edit_workflow_text_container {\n  padding-left: 110px;\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n}\n\n._edit_workflow_text_container > p {\n  padding-top: 4px;\n}\n\n._lead_cadence_link {\n  font-weight: bold;\n  cursor: pointer;\n  color: var(--bloobirds);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_accounts_alert_link": "alertMessage-module___accounts_alert_link___A7-UA",
	"_pause_icon": "alertMessage-module___pause_icon___jA5dF",
	"_pause_text_container": "alertMessage-module___pause_text_container___SPtuB",
	"_edit_workflow_text_container": "alertMessage-module___edit_workflow_text_container___kbiid",
	"_lead_cadence_link": "alertMessage-module___lead_cadence_link___dWtQs"
};
export default ___CSS_LOADER_EXPORT___;
