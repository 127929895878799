import React from 'react';

const useResults = testRun => {
  const testSuccessRate = (
    (testRun.summarized_results?.success / testRun.summarized_results?.total) * 100 || 0
  ).toFixed(1);
  const testWarningRate = (
    (testRun.summarized_results?.partial_success / testRun.summarized_results?.total) * 100 || 0
  ).toFixed(1);
  const testErrorRate = (
    ((testRun.summarized_results?.failure + testRun.summarized_results?.processing_error) /
      testRun.summarized_results?.total) *
      100 || 0
  ).toFixed(1);
  const testWithoutExpectedRate = (
    100 -
      ((testRun.summarized_results?.success / testRun.summarized_results?.total) * 100 +
        (testRun.summarized_results?.partial_success / testRun.summarized_results?.total) * 100 +
        ((testRun.summarized_results?.failure + testRun.summarized_results?.processing_error) /
          testRun.summarized_results?.total) *
          100) || 0
  ).toFixed(1);

  return {
    testSuccessRate: Number(testSuccessRate),
    testWarningRate: Number(testWarningRate),
    testErrorRate: Number(testErrorRate),
    testWithoutExpectedRate: Number(testWithoutExpectedRate),
  };
};

export default useResults;
