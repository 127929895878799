import { useEffect, useState } from 'react';

import { BobjectTypes, GlobalSearchResponse } from '@bloobirds-it/types';
import { api, keepPreviousResponse } from '@bloobirds-it/utils';
import useSWR from 'swr';

import { useDebounce } from './useDebounce';

type SearchBobjectsHook = {
  searchValue: string;
  accountId: string;
  callback?: () => void;
  numberOfResults?: number;
  bobjectTypes?: string[];
};

export const useSearchBobjects = ({
  searchValue,
  accountId,
  callback,
  numberOfResults = 20,
  bobjectTypes = Object.values(BobjectTypes),
}: SearchBobjectsHook) => {
  const { data: response } = useSWR(
    searchValue && searchValue !== '' ? ['bobjectSelector', searchValue] : null,
    () => {
      return api
        .post(`/bobjects/${accountId}/global-search`, {
          query: searchValue,
          bobjectTypes,
          numberOfResults,
        })
        .then(response => {
          callback?.();
          return response;
        });
    },
    { use: [keepPreviousResponse] },
  );

  const results: GlobalSearchResponse[] = response?.data?.results;
  const totalMatching: number = response?.data?.totalMatching;

  return { results, totalMatching, response };
};

export const useSearchBobjectsRaw = ({
  searchValue,
  accountId,
  callback,
  numberOfResults = 20,
  bobjectTypes = Object.values(BobjectTypes),
}) => {
  const [data, setData] = useState({ response: null, results: [], totalMatching: 0 });

  const debouncedSearchValue = useDebounce(searchValue, 400);

  const fetchData = async () => {
    try {
      const response = await api.post(`/bobjects/${accountId}/global-search`, {
        query: debouncedSearchValue,
        bobjectTypes,
        numberOfResults,
      });

      setData({
        response,
        results: response.data?.results || [],
        totalMatching: response.data?.totalMatching || 0,
      });

      // Call the callback after successful fetch
      callback?.();
    } catch (error) {
      console.error('Error fetching data:', error);
      setData({
        response: null,
        results: [],
        totalMatching: 0,
      });
    }
  };

  useEffect(() => {
    if (debouncedSearchValue) {
      fetchData();
    }
  }, [debouncedSearchValue]);

  return data;
};
