// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cardIcon-module___icon___tEPBX {\n  position: relative;\n}\n\n.cardIcon-module___icon_direction___YOF90 {\n  position: absolute;\n  top: -40%;\n  right: -40%;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/cardIcon/cardIcon.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb","sourcesContent":["._icon {\n  position: relative;\n}\n\n._icon_direction {\n  position: absolute;\n  top: -40%;\n  right: -40%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_icon": "cardIcon-module___icon___tEPBX",
	"_icon_direction": "cardIcon-module___icon_direction___YOF90"
};
export default ___CSS_LOADER_EXPORT___;
