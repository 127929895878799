import React, { useState } from 'react';

import { CopilotInsight } from '@bloobirds-it/types';

import InsightInfo from '../components/insightInfo/insightInfo';
import InsightList from '../components/insightList/insightList';
import styles from '../playgroundPages.module.css';

const Insights = () => {
  const [selectedInsight, setSelectedInsight] = useState<CopilotInsight>();

  return (
    <div className={styles.playground_content}>
      <InsightList setSelectedInsight={setSelectedInsight} selectedInsight={selectedInsight} />
      <InsightInfo setSelectedInsight={setSelectedInsight} selectedInsight={selectedInsight} />
    </div>
  );
};

export default Insights;
