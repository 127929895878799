export enum OPPORTUNITY_PRODUCT_FIELDS_LOGIC_ROLE {
  AUTHOR = 'OPPORTUNITY_PRODUCT__AUTHOR',
  DISCOUNT = 'OPPORTUNITY_PRODUCT__DISCOUNT',
  EXTRA_FEE = 'OPPORTUNITY_PRODUCT__EXTRA_FEE',
  OPPORTUNITY = 'OPPORTUNITY_PRODUCT__OPPORTUNITY',
  PRICE = 'OPPORTUNITY_PRODUCT__PRICE',
  PRODUCT = 'OPPORTUNITY_PRODUCT__PRODUCT',
  TOTAL_PRICE = 'OPPORTUNITY_PRODUCT__TOTAL_PRICE',
  UNITS = 'OPPORTUNITY_PRODUCT__UNITS',
  VAT = 'OPPORTUNITY_PRODUCT__VAT',
}
