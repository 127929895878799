import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Dropdown, Text, useVisible } from '@bloobirds-it/flamingo-ui';

import styles from '../segmentationFilter.module.css';

export const DuplicateMetaTemplate = ({
  size = 'medium',
  templateName,
  duplicateAction,
}: {
  size?: 'small' | 'medium';
  templateName: string;
  duplicateAction: () => void;
}) => {
  const isSmall = size === 'small';
  const { t } = useTranslation('translation', {
    keyPrefix: 'playbook.segmentationFilter.duplicateMetaTemplate',
  });
  const { visible, setVisible, ref: dropdownRef } = useVisible();
  const closeDropdown = () => setVisible(false);

  return (
    <section className={styles.sendToMetaContainer}>
      <Text
        uppercase={!isSmall}
        size="s"
        color={isSmall ? 'peanut' : 'softPeanut'}
        weight={isSmall ? 'bold' : 'regular'}
      >
        {t('title')}
      </Text>
      <Text size={isSmall ? 'xs' : 's'} color="softPeanut">
        <Trans
          i18nKey="playbook.segmentationFilter.duplicateMetaTemplate.description"
          components={{ 1: <br /> }}
        />
      </Text>
      <Dropdown
        ref={dropdownRef}
        visible={visible}
        anchor={
          <Button
            color="softPurple"
            iconLeft="deliver"
            variant="secondary"
            uppercase={false}
            className={styles.sendToMetaButton}
            onClick={() => setVisible(visible => !visible)}
          >
            {t('buttonText')}
          </Button>
        }
      >
        <DropdownContent
          templateName={templateName}
          duplicateAction={() => {
            duplicateAction();
            closeDropdown();
          }}
          closeDropdown={closeDropdown}
        />
      </Dropdown>
    </section>
  );
};

const DropdownContent = ({
  templateName,
  duplicateAction,
  closeDropdown,
}: {
  templateName: string;
  duplicateAction: () => void;
  closeDropdown: () => void;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'playbook.segmentationFilter.duplicateMetaTemplate.dropdownContent',
  });

  return (
    <div className={styles.duplicateDropdownContent}>
      <Text size="s">
        <Trans
          i18nKey="playbook.segmentationFilter.duplicateMetaTemplate.dropdownContent.content"
          components={{
            1: (
              <>
                <br />
                <br />
              </>
            ),
          }}
          values={{ templateName }}
        />
      </Text>
      <footer className={styles.duplicateDropdownFooter}>
        <Button
          uppercase={false}
          size="small"
          color="purple"
          variant="clear"
          onClick={closeDropdown}
        >
          {t('back')}
        </Button>
        <Button uppercase={false} size="small" color="purple" onClick={duplicateAction}>
          {t('duplicate')}
        </Button>
      </footer>
    </div>
  );
};
