// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paginationCustom-module__buttonsPagination___g9XuM {\n  flex-shrink: 0;\n  color: var(--peanut);\n  margin-left: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/paginationCustom/paginationCustom.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,iBAAiB;AACnB","sourcesContent":[".buttonsPagination {\n  flex-shrink: 0;\n  color: var(--peanut);\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsPagination": "paginationCustom-module__buttonsPagination___g9XuM"
};
export default ___CSS_LOADER_EXPORT___;
