import { useFormContext, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Item, Select, Text } from '@bloobirds-it/flamingo-ui';
import { useFullSalesEnabled, useIsOTOAccount } from '@bloobirds-it/hooks';
import { ACTIVITY_FIELDS_LOGIC_ROLE } from '@bloobirds-it/types';

import { useCalendar } from '../../hooks/useCalendar';
import { useMeetingsModalContext } from '../context';
import styles from '../meetingModal.module.css';

type Props = { users: any };
export const MeetingModalGuests = ({ users }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meetingModal' });
  const { accountId, dataModel } = useMeetingsModalContext();
  const { invitees, setInvitees } = useCalendar();
  const { control, formState } = useFormContext();
  const isOTOAccount = useIsOTOAccount();
  const isFullSalesEnabled = useFullSalesEnabled(accountId);
  const activityAssignedToField = dataModel?.findFieldByLogicRole(
    ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_ASSIGNED_TO,
  );
  const activityAssignedToValues = activityAssignedToField?.values;
  const activityAccountExecutiveField = dataModel?.findFieldByLogicRole(
    ACTIVITY_FIELDS_LOGIC_ROLE.ACCOUNT_EXECUTIVE,
  );
  const accountExecutivePicklistValues = activityAccountExecutiveField?.values;

  // region Form related logic
  const {
    field: { value: assignedToValue, onChange: activityAssignedToOnChange },
  } = useController({
    control,
    name: ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_ASSIGNED_TO,
    defaultValue: '',
    rules: { required: activityAssignedToField?.required && isFullSalesEnabled },
  });

  const {
    field: { value: accountExecutive, onChange: accountExecutiveOnChange },
  } = useController({
    control,
    name: ACTIVITY_FIELDS_LOGIC_ROLE.ACCOUNT_EXECUTIVE,
    rules: { required: activityAccountExecutiveField?.required && !isFullSalesEnabled },
  });

  const errorAssignedTo =
    formState?.errors &&
    formState?.errors[ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_ASSIGNED_TO] &&
    t('thisFieldIsRequired');

  const errorAe =
    formState?.errors &&
    formState?.errors[ACTIVITY_FIELDS_LOGIC_ROLE.ACCOUNT_EXECUTIVE] &&
    t('thisFieldIsRequired');
  // endregion

  return (
    <div className={styles['guests-container']}>
      <Text size="m">{t('guests')}</Text>
      <div>
        {!isOTOAccount &&
          (isFullSalesEnabled ? (
            <Select
              value={assignedToValue}
              placeholder={`${activityAssignedToField?.name || t('meetingAssignedTo')} ${
                activityAssignedToField?.required ? '*' : ''
              }`}
              width="100%"
              size="labeled"
              // @ts-ignore
              portal={false}
              borderless={false}
              onChange={(v: string) => {
                activityAssignedToOnChange(v);
              }}
              error={errorAssignedTo}
              autocomplete={
                activityAssignedToValues?.filter((ae: any) => ae?.isEnabled)?.length > 7
              }
            >
              {activityAssignedToValues
                ?.filter((ae: any) => ae?.isEnabled)
                .map((ae: any) => (
                  <Item
                    key={ae.id}
                    value={ae?.id}
                    label={ae.name}
                    onClick={(v: string) => {
                      const user = users?.users?.find((user: any) => user?.id === v);
                      if (!invitees?.find(invitee => invitee?.email === user?.email)) {
                        setInvitees(curr => [
                          ...curr,
                          {
                            type: 'AE',
                            email: user?.email,
                          },
                        ]);
                      }
                    }}
                  >
                    {ae.name}
                  </Item>
                ))}
            </Select>
          ) : (
            <Select
              width="100%"
              value={accountExecutive}
              placeholder={`${t('accountExecutive')} ${
                activityAccountExecutiveField?.required ? '*' : ''
              }`}
              size="labeled"
              borderless={false}
              // @ts-ignore
              portal={false}
              onChange={(v: string) => {
                accountExecutiveOnChange(v);
              }}
              error={errorAe}
              autocomplete={
                accountExecutivePicklistValues?.filter((ae: any) => ae?.isEnabled)?.length > 7
              }
            >
              {accountExecutivePicklistValues
                ?.filter((ae: any) => ae?.isEnabled)
                .map((ae: any) => (
                  <Item
                    key={ae.id}
                    value={ae?.id}
                    label={ae?.name}
                    onClick={(v: string) => {
                      const ae = accountExecutivePicklistValues?.find((ae: any) => ae?.id === v);
                      if (!invitees?.find(invitee => invitee?.email === ae?.name)) {
                        setInvitees(curr => [
                          ...curr,
                          {
                            type: 'AE',
                            email: ae?.name,
                          },
                        ]);
                      }
                    }}
                  >
                    {ae?.name}
                  </Item>
                ))}
            </Select>
          ))}
      </div>
    </div>
  );
};
