import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Text } from '@bloobirds-it/flamingo-ui';

import styles from '../../playgroundPages.module.css';

const TestRunsEmpty = ({ handleAddRun }: { handleAddRun: () => void }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground.runs' });

  return (
    <div className={styles.test_empty}>
      <span>🏃‍♀</span>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8, padding: '0 8px' }}>
        <Text size="s" color="peanut" weight="heavy" align="center">
          {t('noResults')}
        </Text>
        <Text size="xs" color="peanut" align="center">
          {t('noResultsDescription')}
        </Text>
      </div>
      <Button
        iconLeft="add"
        uppercase={false}
        size="small"
        color={'lightPurple'}
        onClick={handleAddRun}
      >
        {t('noResultsButton')}
      </Button>
    </div>
  );
};

export default TestRunsEmpty;
