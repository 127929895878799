import { ACTIVITY_FIELDS_LOGIC_ROLE } from '../../../../constants/activity';
import { COMPANY_FIELDS_LOGIC_ROLE } from '../../../../constants/company';

export const meetingsFiltersFields = [
  ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_ASSIGNED_TO,
  ACTIVITY_FIELDS_LOGIC_ROLE.REPORTED,
  ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_MAIN_TYPE,
  ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_RESULT,
  COMPANY_FIELDS_LOGIC_ROLE.TARGET_MARKET,
  COMPANY_FIELDS_LOGIC_ROLE.MR_RATING,
];
