export const bobjectTableActions = Object.freeze({
  BOBJECT_TABLE_CHANGE_NAME_VIEW: 'BOBJECT_TABLE_CHANGE_NAME_VIEW',
  BOBJECT_TABLE_CHANGE_VISIBILITY: 'BOBJECT_TABLE_CHANGE_VISIBILITY',
  BOBJECT_TABLE_CLEAN_FILTERS: 'BOBJECT_TABLE_CLEAN_FILTERS',
  BOBJECT_TABLE_STATE_FROM_URL: 'BOBJECT_TABLE_STATE_FROM_URL',
  BOBJECT_TABLE_CLOSE_SAVE_MODAL_VIEW: 'BOBJECT_TABLE_CLOSE_SAVE_MODAL_VIEW',
  BOBJECT_TABLE_RESET_CONFIG: 'BOBJECT_TABLE_RESET_CONFIG',
  BOBJECT_TABLE_EDIT_VIEW: 'BOBJECT_TABLE_EDIT_VIEW',
  BOBJECT_TABLE_SET_QUERY: 'BOBJECT_TABLE_SET_QUERY',
  BOBJECT_TABLE_LOADED: 'BOBJECT_TABLE_LOADED',
  BOBJECT_TABLE_OPEN_SAVE_MODAL_VIEW: 'BOBJECT_TABLE_OPEN_SAVE_MODAL_VIEW',
  BOBJECT_TABLE_RESET: 'BOBJECT_TABLE_RESET',
  BOBJECT_TABLE_RESET_FILTER_COLUMNS: 'BOBJECT_TABLE_RESET_FILTER_COLUMNS',
  BOBJECT_TABLE_RETRIEVE_VIEWS: 'BOBJECT_TABLE_RETRIEVE_VIEWS',
  BOBJECT_TABLE_RETRIEVE_VIEW_SUCCESS: 'BOBJECT_TABLE_RETRIEVE_VIEW_SUCCESS',
  BOBJECT_TABLE_SET_VIEWTYPE: 'BOBJECT_TABLE_SET_VIEWTYPE',
  BOBJECT_TABLE_UPDATE_PARTIAL_STATE: 'BOBJECT_TABLE_UPDATE_PARTIAL_STATE',
  RESET_TASK_STATE: 'RESET_TASK_STATE',
  BOBJECT_TABLE_SET_COLUMNS: 'BOBJECT_TABLE_SET_COLUMNS',
  BOBJECT_TABLE_SET_SORT: 'BOBJECT_TABLE_SET_SORT',
  BOBJECT_TABLE_SET_VIEW_ID: 'BOBJECT_TABLE_SET_VIEW_ID',
  BOBJECT_TABLE_SET_VIEW: 'BOBJECT_TABLE_SET_VIEW',
  BOBJECT_TABLE_SET_EDITION_MODE: 'BOBJECT_TABLE_SET_EDITION_MODE',
  BOBJECT_TABLE_REMOVE_PROVISIONAL_ROW: 'BOBJECT_TABLE_REMOVE_PROVISIONAL_ROW',
});
