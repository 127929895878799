// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".activityBody-module___note_message___CjSMB {\n  margin-top: 24px;\n  margin-bottom: 0;\n}\n\n.activityBody-module___body___eYHOe {\n  white-space: break-spaces;\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  padding-left: 12px;\n  justify-content: flex-start;\n  margin-top: 24px;\n}\n\n.activityBody-module___body___eYHOe:empty {\n  display: none;\n}\n\n.activityBody-module___body___eYHOe:last-child {\n  padding-right: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/activitySection/activityItem/activityBody/activityBody.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["._note_message {\n  margin-top: 24px;\n  margin-bottom: 0;\n}\n\n._body {\n  white-space: break-spaces;\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  padding-left: 12px;\n  justify-content: flex-start;\n  margin-top: 24px;\n}\n\n._body:empty {\n  display: none;\n}\n\n._body:last-child {\n  padding-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_note_message": "activityBody-module___note_message___CjSMB",
	"_body": "activityBody-module___body___eYHOe"
};
export default ___CSS_LOADER_EXPORT___;
