// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bulkActionsFeedbackModal-module___title__container___29UDl {\n  display: flex;\n  align-items: center;\n}\n\n.bulkActionsFeedbackModal-module___icon___hqirN {\n  margin-right: 4px;\n}\n\n.bulkActionsFeedbackModal-module___modal_message_title___lYzJk {\n  margin-bottom: 16px;\n}\n\n.bulkActionsFeedbackModal-module___modal_message_subtitle___TWpdw {\n  margin-bottom: 76px;\n}\n\n.bulkActionsFeedbackModal-module___spinner_wrapper___52PCe {\n  display: flex;\n  justify-content: center;\n  padding: 50px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectTable/bulkActionsPanel/modals/feedbackModal/bulkActionsFeedbackModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":["._title__container {\n  display: flex;\n  align-items: center;\n}\n\n._icon {\n  margin-right: 4px;\n}\n\n._modal_message_title {\n  margin-bottom: 16px;\n}\n\n._modal_message_subtitle {\n  margin-bottom: 76px;\n}\n\n._spinner_wrapper {\n  display: flex;\n  justify-content: center;\n  padding: 50px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_title__container": "bulkActionsFeedbackModal-module___title__container___29UDl",
	"_icon": "bulkActionsFeedbackModal-module___icon___hqirN",
	"_modal_message_title": "bulkActionsFeedbackModal-module___modal_message_title___lYzJk",
	"_modal_message_subtitle": "bulkActionsFeedbackModal-module___modal_message_subtitle___TWpdw",
	"_spinner_wrapper": "bulkActionsFeedbackModal-module___spinner_wrapper___52PCe"
};
export default ___CSS_LOADER_EXPORT___;
