// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".addButton-module__buttonWrapper___KQiwi {\n  display: flex;\n  background: var(--lighterGray);\n  mix-blend-mode: normal;\n  border-radius: 4px;\n  padding: 4px;\n  margin-left: 8px;\n  align-items: center;\n  height: 16px;\n}\n\n.addButton-module__buttonWrapper___KQiwi:hover {\n  cursor: pointer;\n}\n\n.addButton-module__buttonWrapper___KQiwi > span {\n  margin-left: 4px;\n  margin-right: 8px;\n}\n\n.addButton-module__buttonDisabled___BcyNl {\n  background-color: var(--lightestBloobirds);\n}\n\n.addButton-module__buttonDisabled___BcyNl:hover {\n  cursor: default;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/contactPages/navegationBar/navigationTab/addButton/addButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".buttonWrapper {\n  display: flex;\n  background: var(--lighterGray);\n  mix-blend-mode: normal;\n  border-radius: 4px;\n  padding: 4px;\n  margin-left: 8px;\n  align-items: center;\n  height: 16px;\n}\n\n.buttonWrapper:hover {\n  cursor: pointer;\n}\n\n.buttonWrapper > span {\n  margin-left: 4px;\n  margin-right: 8px;\n}\n\n.buttonDisabled {\n  background-color: var(--lightestBloobirds);\n}\n\n.buttonDisabled:hover {\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrapper": "addButton-module__buttonWrapper___KQiwi",
	"buttonDisabled": "addButton-module__buttonDisabled___BcyNl"
};
export default ___CSS_LOADER_EXPORT___;
