// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".saveQuickFilterModal-module__content___V1qlX {\n  padding-top: 8px;\n}\n\n.saveQuickFilterModal-module__nameWrapper___o\\+rUc {\n  margin: 24px 0 36px 0;\n  display: flex;\n}\n\n.saveQuickFilterModal-module__quickFilterWrapper___X99KT {\n  margin: 9px 0 9px 36px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/subhomeLayout/subhomeContent/newSubhomeFilters/quickFilters/saveQuickFilterModal/saveQuickFilterModal.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".content {\n  padding-top: 8px;\n}\n\n.nameWrapper {\n  margin: 24px 0 36px 0;\n  display: flex;\n}\n\n.quickFilterWrapper {\n  margin: 9px 0 9px 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "saveQuickFilterModal-module__content___V1qlX",
	"nameWrapper": "saveQuickFilterModal-module__nameWrapper___o+rUc",
	"quickFilterWrapper": "saveQuickFilterModal-module__quickFilterWrapper___X99KT"
};
export default ___CSS_LOADER_EXPORT___;
