// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buyerPersonasButton-module___buyer_personas_message___Xo\\+D9 {\n  display: flex;\n  background: var(--verySoftPurple);\n  mix-blend-mode: normal;\n  border-radius: 4px;\n  padding: 8px 26px;\n  align-items: center;\n  margin-left: 8px;\n}\n\n.buyerPersonasButton-module___buyer_personas_message___Xo\\+D9:hover {\n  cursor: pointer;\n}\n\n.buyerPersonasButton-module___buyer_personas_message___Xo\\+D9 > span {\n  margin-left: 3px;\n}\n\n.buyerPersonasButton-module___buyer_personas_message_small___yIDwt {\n  padding: 4px 11px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/buyerPersonasButton/buyerPersonasButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iCAAiC;EACjC,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["._buyer_personas_message {\n  display: flex;\n  background: var(--verySoftPurple);\n  mix-blend-mode: normal;\n  border-radius: 4px;\n  padding: 8px 26px;\n  align-items: center;\n  margin-left: 8px;\n}\n\n._buyer_personas_message:hover {\n  cursor: pointer;\n}\n\n._buyer_personas_message > span {\n  margin-left: 3px;\n}\n\n._buyer_personas_message_small {\n  padding: 4px 11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_buyer_personas_message": "buyerPersonasButton-module___buyer_personas_message___Xo+D9",
	"_buyer_personas_message_small": "buyerPersonasButton-module___buyer_personas_message_small___yIDwt"
};
export default ___CSS_LOADER_EXPORT___;
