// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".doubleInput-module___container___46AiX {\n  display: flex;\n  align-items: center;\n}\n\n.doubleInput-module___input_wrapper___YelwJ {\n  margin-right: 10px;\n  width: 40%;\n}\n\n.doubleInput-module___text_wrapper___K99x3 {\n  margin-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/subhomePages/salesPage/doubleInput/doubleInput.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["._container {\n  display: flex;\n  align-items: center;\n}\n\n._input_wrapper {\n  margin-right: 10px;\n  width: 40%;\n}\n\n._text_wrapper {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "doubleInput-module___container___46AiX",
	"_input_wrapper": "doubleInput-module___input_wrapper___YelwJ",
	"_text_wrapper": "doubleInput-module___text_wrapper___K99x3"
};
export default ___CSS_LOADER_EXPORT___;
