export const QUOTES = [
  {
    text: 'Success is not final, failure is not fatal: it is the courage to continue that counts',
    author: 'Winston Churchill',
    type: 'Motivational',
  },
  {
    text: 'Success is a science; if you have the conditions, you get the result.',
    author: 'Oscar Wilde',
    type: 'Motivational',
  },
  {
    text: 'Success consists of going from failure to failure without loss of enthusiasm.',
    author: 'Winston Churchill',
    type: 'Motivational',
  },
  {
    text: "Just remember, you can't climb the ladder of success with your hands in your pockets.",
    author: 'Arnold Schwarzenegger',
    type: 'Motivational',
  },
  {
    text: 'That some achieve great success, is proof to all that others can achieve it as well.',
    author: 'Abraham Lincoln',
    type: 'Motivational',
  },
];
