// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rightClickContextMenu-module___container___vZyux {\n  background: #ffffff;\n  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);\n  border-radius: 4px;\n  margin: 4px;\n}\n\n.rightClickContextMenu-module___container__wrapper___\\+FXjx {\n  position: fixed;\n  height: 32px;\n  width: 140px;\n  z-index: 1;\n}\n\n.rightClickContextMenu-module___link___xbo5t {\n  text-decoration: none;\n  font-size: 14px;\n  line-height: 24px;\n  color: var(--peanut);\n  margin: 14px 10px;\n  letter-spacing: initial;\n  white-space: nowrap;\n}\n\n.rightClickContextMenu-module___link___xbo5t:hover {\n  color: var(--bloobirds);\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/rightClickContextMenu/rightClickContextMenu.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,4CAA4C;EAC5C,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["._container {\n  background: #ffffff;\n  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);\n  border-radius: 4px;\n  margin: 4px;\n}\n\n._container__wrapper {\n  position: fixed;\n  height: 32px;\n  width: 140px;\n  z-index: 1;\n}\n\n._link {\n  text-decoration: none;\n  font-size: 14px;\n  line-height: 24px;\n  color: var(--peanut);\n  margin: 14px 10px;\n  letter-spacing: initial;\n  white-space: nowrap;\n}\n\n._link:hover {\n  color: var(--bloobirds);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "rightClickContextMenu-module___container___vZyux",
	"_container__wrapper": "rightClickContextMenu-module___container__wrapper___+FXjx",
	"_link": "rightClickContextMenu-module___link___xbo5t"
};
export default ___CSS_LOADER_EXPORT___;
