import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, Item, Text, MultiSelect } from '@bloobirds-it/flamingo-ui';

import { SORT_VALUES } from '../../constants/phoneNumbers.constants';
import {
  usePaginatedPhoneEntity,
  usePhoneNumberFilters,
} from '../../hooks/usePaginatedPhoneEntity';
import { usePhoneFilters } from '../../hooks/usePhoneFilters';
import styles from '../../styles/phoneNumberPage.module.css';

export const PhoneFilters = () => {
  const { t } = useTranslation();
  const { filtersSelection } = usePhoneFilters();

  const { updatePhoneNumberFilters, filters } = usePhoneNumberFilters();
  const { handleRefresh } = usePaginatedPhoneEntity();

  const handleSortChange = (value: any) => {
    // @ts-ignore
    updatePhoneNumberFilters('sort', { [value.displayName]: value?.sortOrder || 'ASC' });
    handleRefresh();
  };

  return (
    <div className={styles.filters_container}>
      <Text htmlTag="span" size="s" color="black" className={styles.filters_title__container}>
        {t('accountSettings.phoneNumbers.sorts.label')}:{' '}
      </Text>
      <div className={styles._filter__input}>
        <Select
          width={'150px'}
          size="small"
          placeholder={t('accountSettings.phoneNumbers.sorts.label')}
          value={SORT_VALUES.find(
            sort =>
              sort.displayName === Object.keys(filters.sort)[0] &&
              sort.sortOrder ===
                filters.sort[Object.keys(filters.sort)[0] as keyof typeof filters.sort],
          )}
          onChange={handleSortChange}
        >
          {SORT_VALUES?.map((sortValue: any) => (
            <Item key={sortValue.name} value={sortValue}>
              {t(`accountSettings.phoneNumbers.sorts.${sortValue.displayName}`)}
              {sortValue.sortOrder && (
                <span style={{ marginLeft: 'auto' }}>
                  {sortValue.sortOrder === 'ASC' ? '↑' : '↓'}
                </span>
              )}
            </Item>
          ))}
        </Select>
      </div>
      <div className={styles.divider}></div>
      <Text htmlTag="span" size="s" color="black" className={styles.filters_title__container}>
        {t('accountSettings.phoneNumbers.filters.label')}:{' '}
      </Text>
      <div className={styles._filter__input}>
        <Select
          width={'150px'}
          size="small"
          placeholder={t('accountSettings.phoneNumbers.filters.country')}
          value={filters.location || null}
          onChange={v => {
            updatePhoneNumberFilters('location', v);
            handleRefresh();
          }}
        >
          <Item key={null} value={null}>
            {t('accountSettings.phoneNumbers.filters.placeholderAllCountries')}
          </Item>
          {filtersSelection?.countries?.map((country: string) => (
            <Item key={country} value={country}>
              {country}
            </Item>
          ))}
        </Select>
      </div>

      <div className={styles._filter__input}>
        <Select
          width={'150px'}
          size="small"
          placeholder={t('accountSettings.phoneNumbers.filters.prefix')}
          value={filters.prefixes || null}
          onChange={v => {
            updatePhoneNumberFilters('prefix', v);
            handleRefresh();
          }}
        >
          <Item key={null} value={null}>
            {t('accountSettings.phoneNumbers.filters.placeholderAllPrefixes')}
          </Item>
          {filtersSelection?.prefixes?.map((prefix: string) => (
            <Item key={prefix} value={prefix}>
              {prefix}
            </Item>
          ))}
        </Select>
      </div>

      <div className={styles._filter__input}>
        <Select
          width={'150px'}
          size="small"
          placeholder={t('accountSettings.phoneNumbers.filters.status')}
          value={filters?.status !== undefined ? String(filters.status) : null}
          onChange={v => {
            updatePhoneNumberFilters('status', v === 'true' ? true : v === 'false' ? false : null);
            handleRefresh();
          }}
        >
          <Item key={null} value={null}>
            {t('accountSettings.phoneNumbers.filters.placeholderAllStatus')}
          </Item>
          <Item key={'true'} value={'true'}>
            {t('common.enabled')}
          </Item>
          <Item key={'false'} value={'false'}>
            {t('common.disabled')}
          </Item>
        </Select>
      </div>

      <div className={styles._filter__input}>
        <Select
          width={'150px'}
          size="small"
          placeholder={t('accountSettings.phoneNumbers.filters.capabilities')}
          value={filters.capabilities || null}
          onChange={v => {
            updatePhoneNumberFilters('capabilities', v);
            handleRefresh();
          }}
        >
          <Item key={null} value={null}>
            {t('accountSettings.phoneNumbers.filters.placeholderAllCapabilities')}
          </Item>
          {filtersSelection?.capabilities?.map((capabilities: string) => (
            <Item key={capabilities} value={capabilities}>
              {capabilities}
            </Item>
          ))}
        </Select>
      </div>

      <div className={styles._filter__input}>
        <Select
          width={'150px'}
          size="small"
          variant="form"
          autocomplete
          placeholder={t('accountSettings.phoneNumbers.filters.tags')}
          value={filters.tags || null}
          onChange={v => {
            updatePhoneNumberFilters('tags', v);
            handleRefresh();
          }}
        >
          <Item key={null} value={null}>
            {t('accountSettings.phoneNumbers.filters.placeholderAllTags')}
          </Item>
          {filtersSelection?.tags?.map((tag: string) => (
            <Item key={tag} value={tag} label={tag}>
              {tag}
            </Item>
          ))}
        </Select>
      </div>
    </div>
  );
};
