import { ColorType, IconType } from '@bloobirds-it/flamingo-ui';

export interface RelatedPickableIconProps {
  name: IconType;
  color: ColorType;
}

export const relatedPickableIcons: RelatedPickableIconProps[] = [
  { name: 'salesforce', color: 'bloobirds' },
  { name: 'briefcase', color: 'banana' },
  { name: 'file', color: 'extraCall' },
  { name: 'assignBoard', color: 'darkBloobirds' },
  { name: 'whatsapp', color: 'extraCall' },
  { name: 'customTask', color: 'bloobirds' },
  { name: 'linkedin', color: 'darkBloobirds' },
  { name: 'calendar', color: 'tomato' },
  { name: 'noteAction', color: 'banana' },
  { name: 'person', color: 'tangerine' },
  { name: 'chat', color: 'purple' },
  { name: 'postalMail', color: 'bloobirds' },
  { name: 'calendarphone', color: 'grape' },
  { name: 'checkDouble', color: 'extraCall' },
  { name: 'bell', color: 'bloobirds' },
  { name: 'starUnchecked', color: 'banana' },
  { name: 'location', color: 'tomato' },
  { name: 'mail', color: 'tangerine' },
  { name: 'pitch', color: 'lightPurple' },
  { name: 'fileOpportunity', color: 'extraCall' },
];
