// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".taskFeedConfigurationPage-module__formField___Ovd6Z {\n  margin: 10px 0;\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}\n\n.taskFeedConfigurationPage-module__formField___Ovd6Z input {\n  padding: 5px;\n  border-radius: 3px;\n  border: 1px solid #ccc;\n}\n\n.taskFeedConfigurationPage-module__button___CQ4pH {\n  padding: 5px 10px;\n  margin-top: 10px;\n  border-radius: 3px;\n  border: none;\n  background-color: #007bff;\n  color: white;\n  cursor: pointer;\n}\n\n.taskFeedConfigurationPage-module__button___CQ4pH:not(:last-of-type) {\n  margin-right: 10px;\n}\n\n.taskFeedConfigurationPage-module__rule___F0lxP {\n  border: 1px solid #ccc;\n  padding: 10px;\n  margin: 10px 0;\n  border-radius: 5px;\n}\n\n.taskFeedConfigurationPage-module__ruleTitle___-4e9T {\n  font-weight: bold;\n  margin-bottom: 10px;\n}\n\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/taskFeedConfigurationPage/styles/taskFeedConfigurationPage.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".formField {\n  margin: 10px 0;\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}\n\n.formField input {\n  padding: 5px;\n  border-radius: 3px;\n  border: 1px solid #ccc;\n}\n\n.button {\n  padding: 5px 10px;\n  margin-top: 10px;\n  border-radius: 3px;\n  border: none;\n  background-color: #007bff;\n  color: white;\n  cursor: pointer;\n}\n\n.button:not(:last-of-type) {\n  margin-right: 10px;\n}\n\n.rule {\n  border: 1px solid #ccc;\n  padding: 10px;\n  margin: 10px 0;\n  border-radius: 5px;\n}\n\n.ruleTitle {\n  font-weight: bold;\n  margin-bottom: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formField": "taskFeedConfigurationPage-module__formField___Ovd6Z",
	"button": "taskFeedConfigurationPage-module__button___CQ4pH",
	"rule": "taskFeedConfigurationPage-module__rule___F0lxP",
	"ruleTitle": "taskFeedConfigurationPage-module__ruleTitle___-4e9T"
};
export default ___CSS_LOADER_EXPORT___;
