import { ACTIVITY_TYPES_VALUES_LOGIC_ROLE } from '@bloobirds-it/types';

export const getValidActivityTypeFromLogicRole = (
  activityType: ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
) => {
  switch (activityType) {
    case ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING:
      return 'meeting';
    case ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL:
      return 'call';
    case ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL:
      return 'email';
    case ACTIVITY_TYPES_VALUES_LOGIC_ROLE.LINKEDIN:
      return 'linkedin';

    default:
      return null;
  }
};

export const getLogicRoleFromParsedActivityType = (
  activityType: string,
): ACTIVITY_TYPES_VALUES_LOGIC_ROLE => {
  switch (activityType) {
    case 'meeting':
      return ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING;
    case 'call':
      return ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL;
    case 'email':
      return ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL;
    case 'linkedin':
      return ACTIVITY_TYPES_VALUES_LOGIC_ROLE.LINKEDIN;
    default:
      return null;
  }
};
