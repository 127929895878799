export const GLOBAL_PICKLISTS_COLUMNS = [
  {
    label: 'Name',
    name: 'name',
    sortable: true,
  },
  {
    label: 'Used in',
    name: '',
    sortable: false,
  },
  {
    label: 'Values',
    name: '',
    sortable: false,
  },
  {
    label: 'Last update',
    name: 'updateDatetime',
    sortable: true,
  },
  {
    label: 'Creation datetime',
    name: 'creationDatetime',
    sortable: true,
  },
  {
    label: 'Status',
    name: '',
    sortable: false,
  },
];

export const RESTRICTED_GLOBAL_PICKLISTS = [
  'TARGET_MARKET',
  'IDEAL_CUSTOMER_PROFILE',
  'USE_CASE',
  'USER',
  'PITCH',
  'EMAIL',
  'LINKEDIN_MESSAGE',
  'CADENCE',
  'DATA_SOURCE',
];

export const MY_PLAYBOOK_GLOBAL_PICKLISTS = [
  'CADENCE',
  'IDEAL_CUSTOMER_PROFILE',
  'LINKEDIN_MESSAGE',
  'PITCH',
  'TARGET_MARKET',
  'USE_CASE',
  'EMAIL',
];
