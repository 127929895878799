import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, IconButton, Text } from '@bloobirds-it/flamingo-ui';
import { api } from '@bloobirds-it/utils';
import { mutate } from 'swr';

import styles from '../../../playgroundPages.module.css';
import { getResult } from './utils';

const ResultCell = ({
  hasError,
  result,
  insightType,
  activityResults,
  selectedRunId,
  testSetId,
  withLabel,
}: {
  hasError: boolean;
  result: any;
  insightType: string;
  activityResults: any;
  selectedRunId: string;
  testSetId: string;
  withLabel?: boolean;
}) => {
  const [resultTag, setResultTag] = useState();
  const { t } = useTranslation();
  if (resultTag) {
    return (
      <div className={styles.textWithIcon}>
        {withLabel && <Text weight="bold">{t('ai.playground.activityTab.result')}:</Text>}
        {getResult(resultTag)}
      </div>
    );
  }
  if (hasError) {
    return !withLabel ? (
      <div className={styles.testRun_activities_resultFeedback}>
        <Icon name="cross" color="tomato" />
      </div>
    ) : (
      <div className={styles.textWithIcon}>
        {withLabel && <Text weight="bold">{t('ai.playground.activityTab.result')}:</Text>}
        <Icon name="cross" color="tomato" />
      </div>
    );
  }
  if (insightType === 'DECISION') {
    return !withLabel ? (
      result
    ) : (
      <div className={styles.textWithIcon}>
        {withLabel && <Text weight="bold">{t('ai.playground.activityTab.result')}:</Text>}
        {result}
      </div>
    );
  }
  if (activityResults?.result_tag && activityResults?.result_tag !== 'WITHOUT_EXPECTED_RESULT') {
    return (
      <div className={styles.textWithIcon}>
        {withLabel && <Text weight="bold">{t('ai.playground.activityTab.result')}:</Text>}
        {result}
      </div>
    );
  }
  function handleFeedback(feedback) {
    api
      .patch(
        `/copilot/playground/test-run/${selectedRunId}/${activityResults?.activity_id}/feedback`,
        {
          feedback,
        },
      )
      .then(response => {
        mutate(`/api/test-runs/${testSetId}`);
        if (response?.data?.results) {
          setResultTag(response?.data?.results[activityResults?.activity_id]?.result_tag);
        }
      });
  }

  const choices = (
    <div className={styles.testRun_activities_resultFeedback}>
      <IconButton
        className={styles.thumbIcon}
        name="thumbsDown"
        size={22}
        color="peanut"
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          handleFeedback('FAILURE');
        }}
      />
      <IconButton
        className={styles.thumbIcon}
        name="thumbsUp"
        size={22}
        color="peanut"
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          handleFeedback('SUCCESS');
        }}
      />
    </div>
  );

  return !withLabel ? (
    choices
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text color="softPeanut" size="s">
        {t('ai.playground.activityTab.selectOption')}
      </Text>
      {choices}
    </div>
  );
};

export default ResultCell;
