// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".timeTimeSchedulerPage-module__loader___K8Ra5 {\n  display: flex;\n  flex-direction: column;\n  width: 100vw;\n  height: 100vh;\n  align-items: center;\n  justify-content: center;\n  background-color: var(--white);\n}\n\n.timeTimeSchedulerPage-module__spinner___QG1Xo {\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/timeTimeSchedulerPage/timeTimeSchedulerPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".loader {\n  display: flex;\n  flex-direction: column;\n  width: 100vw;\n  height: 100vh;\n  align-items: center;\n  justify-content: center;\n  background-color: var(--white);\n}\n\n.spinner {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "timeTimeSchedulerPage-module__loader___K8Ra5",
	"spinner": "timeTimeSchedulerPage-module__spinner___QG1Xo"
};
export default ___CSS_LOADER_EXPORT___;
