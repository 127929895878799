// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".timeline-module__attendees___o5Nf7 {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.timeline-module__timelineContainer___UP058 {\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n}\n\n.timeline-module__actor___m9Th9 {\n  margin-left: 2px;\n  display: flex;\n  gap: 4px;\n}\n\n.timeline-module__timeline___xNQ0n {\n  margin-left: 1px;\n  width: 100%;\n  bottom: 0;\n  height: 6px;\n  border-radius: 15px;\n  background: #f2f2f2;\n  position: relative;\n}\n\n.timeline-module__blob___aG60f {\n  position: absolute;\n  height: 6px;\n  border-radius: 4px;\n}\n\n.timeline-module__blob___aG60f:hover {\n  opacity: 0.6;\n  cursor: grab;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/aiAnalysisPages/leftSideAiAnalysis/mediaAiAnalysis/timelineArea/timeline/timeline.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,SAAS;EACT,WAAW;EACX,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd","sourcesContent":[".attendees {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.timelineContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n}\n\n.actor {\n  margin-left: 2px;\n  display: flex;\n  gap: 4px;\n}\n\n.timeline {\n  margin-left: 1px;\n  width: 100%;\n  bottom: 0;\n  height: 6px;\n  border-radius: 15px;\n  background: #f2f2f2;\n  position: relative;\n}\n\n.blob {\n  position: absolute;\n  height: 6px;\n  border-radius: 4px;\n}\n\n.blob:hover {\n  opacity: 0.6;\n  cursor: grab;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attendees": "timeline-module__attendees___o5Nf7",
	"timelineContainer": "timeline-module__timelineContainer___UP058",
	"actor": "timeline-module__actor___m9Th9",
	"timeline": "timeline-module__timeline___xNQ0n",
	"blob": "timeline-module__blob___aG60f"
};
export default ___CSS_LOADER_EXPORT___;
