// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".crmStatusList-module___tbody___-I4SK {\n  display: table-row-group;\n  vertical-align: middle;\n  border: 1px solid black;\n}\n\n.crmStatusList-module___no_results__contents___2nf1N {\n  box-sizing: border-box;\n  padding: 150px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: white;\n}\n\n.crmStatusList-module___no_results__img___xqNCp {\n  margin-bottom: 20px;\n}\n\n.crmStatusList-module___card__dragging___jyawI {\n  box-shadow: 0 4px 6px rgba(92, 92, 255, 0.3), 0 -4px 6px rgba(92, 92, 255, 0.15) !important;\n}\n\n.crmStatusList-module___card__dragging___jyawI > *:nth-of-type(1n + 3) {\n  display: none;\n}\n\n.crmStatusList-module___select_field__button___dQarR {\n  margin-top: 15px !important;\n}\n\n.crmStatusList-module___no_field_subtitle___JdKQy {\n  margin-top: 10px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/crmStatus/crmStatusList/crmStatusList.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,2FAA2F;AAC7F;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":["._tbody {\n  display: table-row-group;\n  vertical-align: middle;\n  border: 1px solid black;\n}\n\n._no_results__contents {\n  box-sizing: border-box;\n  padding: 150px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: white;\n}\n\n._no_results__img {\n  margin-bottom: 20px;\n}\n\n._card__dragging {\n  box-shadow: 0 4px 6px rgba(92, 92, 255, 0.3), 0 -4px 6px rgba(92, 92, 255, 0.15) !important;\n}\n\n._card__dragging > *:nth-of-type(1n + 3) {\n  display: none;\n}\n\n._select_field__button {\n  margin-top: 15px !important;\n}\n\n._no_field_subtitle {\n  margin-top: 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_tbody": "crmStatusList-module___tbody___-I4SK",
	"_no_results__contents": "crmStatusList-module___no_results__contents___2nf1N",
	"_no_results__img": "crmStatusList-module___no_results__img___xqNCp",
	"_card__dragging": "crmStatusList-module___card__dragging___jyawI",
	"_select_field__button": "crmStatusList-module___select_field__button___dQarR",
	"_no_field_subtitle": "crmStatusList-module___no_field_subtitle___JdKQy"
};
export default ___CSS_LOADER_EXPORT___;
