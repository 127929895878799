import React from 'react';

import { Icon, IconType, Text } from '@bloobirds-it/flamingo-ui';

import styles from './teamManagementModal/teamManagement.module.css';

export const ModalTeamSection = ({
  title,
  expand,
  children,
}: {
  title: string;
  expand?: boolean;
  children: any;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        ...(expand && { flexGrow: 1 }),
      }}
    >
      <Text size="s" weight="bold">
        {title}
      </Text>
      {children}
    </div>
  );
};

export const ModalBlockBody = ({ children }: { children: any }) => {
  return <div className={styles.modalBlockBody}>{children}</div>;
};
export const ModalBlock = ({ children }: { children: any }) => {
  return <div className={styles.modalBlock}>{children}</div>;
};

export const ModalBlockHeader = ({
  title,
  icon,
  children,
}: {
  title: string;
  icon: IconType;
  children?: any;
}) => {
  return (
    <div className={styles.modalBlockWrapper}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <Icon name={icon} size={22} color="softPeanut" />
        <Text size="s" color="softPeanut">
          {title}
        </Text>
      </div>
      {children}
    </div>
  );
};
