import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Icon,
  IconType,
  Item,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Select,
  Skeleton,
  Text,
} from '@bloobirds-it/flamingo-ui';
import { RelatedObjectsTableProps } from '@bloobirds-it/types';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { useGetRelatedObjects } from '../../hooks/useGetRelatedObjects';
import { crmObjects } from '../mainObjects.constants';
import styles from '../mainObjects.module.css';

const CrmObjectCard = ({
  logo,
  name,
  onChange,
  checked,
}: {
  logo: IconType;
  name: string;
  onChange: () => void;
  checked: boolean;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.relatedObjects.fieldsModal',
  });

  return (
    <div
      className={clsx(styles.cardContainer, {
        [styles.cardContainerChecked]: checked,
      })}
      onClick={() => onChange()}
    >
      <Icon name={logo} size={28} color="softBloobirds" />
      <Text size="s" align="center">
        {t('referenceObject', { name })}
      </Text>
    </div>
  );
};

export const SelectReferenceObjectView = ({
  onClose,
  setCrmObject,
}: {
  onClose: () => void;
  setCrmObject?: (crmObject: RelatedObjectsTableProps) => void;
}) => {
  const [selectedCrmObject, setSelectedCrmObject] = useState<string>();
  const [selectedRelatedObject, setSelectedRelatedObject] = useState<RelatedObjectsTableProps>();
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.relatedObjects.fieldsModal',
  });

  const { data, isLoading } = useGetRelatedObjects(selectedCrmObject, true);

  useEffect(() => {
    if (selectedCrmObject) {
      setSelectedRelatedObject(undefined);
    }
  }, [selectedCrmObject]);

  return (
    <>
      <ModalHeader variant="primary" size="small">
        <ModalTitle variant="primary">
          <div>
            <Icon size={24} name="assignBoard" color="bloobirds" />
            <Text size="m" inline color="peanut">
              {t('referenceObjectTitle')}
            </Text>
          </div>
        </ModalTitle>
        <ModalCloseIcon variant="primary" size="small" color="bloobirds" onClick={onClose} />
      </ModalHeader>
      <ModalContent>
        <Text size="l" color="peanut">
          {t('referenceObjectQuestion')}
        </Text>
        <Text size="s" color="softPeanut" className={styles.subtitle}>
          {t('referenceObjectDescription')}
        </Text>
        <div className={styles.selectorContainer}>
          {crmObjects.map(({ icon, label, crmObject }) => (
            <CrmObjectCard
              key={crmObject}
              logo={icon as IconType}
              name={label}
              onChange={() => setSelectedCrmObject(crmObject)}
              checked={crmObject === selectedCrmObject}
            />
          ))}
        </div>
        {!selectedCrmObject && (
          <div className={styles.info}>
            <Icon name="search" size={36} color="softPeanut" />
            <Text size="s" align="center" color="softPeanut">
              {t('selectReferenceObject')}
            </Text>
          </div>
        )}
        {selectedCrmObject && isLoading && (
          <div className={styles.loadingInfo}>
            <div className={styles.loaderModal} />
            <div>
              <Text size="s" align="center" color="peanut">
                {t('loadingValues')}
              </Text>
              <Text size="s" align="center" color="peanut" weight="bold">
                {t('loadingInfo')}
              </Text>
            </div>
            <Skeleton variant="rect" height="24px" width="240px" />
          </div>
        )}
        <AnimatePresence>
          <motion.div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
            exit={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1, justifyContent: 'space-around' }}
          >
            {selectedCrmObject && !isLoading && data && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.25 }}
                className={styles.info}
              >
                <Text size="s" align="center" color="peanut">
                  {t('chooseMainObject')}
                </Text>
                <Select
                  value={selectedRelatedObject}
                  onChange={value => {
                    const hasOnlyOneOption = value?.availableObjectTypeFieldRelated?.length === 1;
                    setSelectedRelatedObject({
                      ...value,
                      ...(hasOnlyOneOption
                        ? { objectTypeFieldRelated: value?.availableObjectTypeFieldRelated[0] }
                        : {}),
                    });
                  }}
                  placeholder={!selectedRelatedObject ? t('selectObject') : undefined}
                  disabled={data.length === 0}
                  renderDisplayValue={value => value?.objectApiName?.apiName}
                >
                  {data?.map(relatedObject => (
                    <Item key={relatedObject.id} value={relatedObject}>
                      {relatedObject.objectApiName.label}
                    </Item>
                  ))}
                </Select>
              </motion.div>
            )}
            {selectedRelatedObject && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.5 }}
                style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
              >
                <Text size="s" align="center" color="peanut">
                  {t('selectRelationshipField')}
                </Text>
                <Select
                  value={selectedRelatedObject?.objectTypeFieldRelated}
                  onChange={value =>
                    setSelectedRelatedObject(prevState => ({
                      ...prevState,
                      objectTypeFieldRelated: value,
                    }))
                  }
                  placeholder={
                    !selectedRelatedObject ? t('relationshipsTitlePlaceholder') : undefined
                  }
                  disabled={data.length === 0}
                >
                  {selectedRelatedObject?.availableObjectTypeFieldRelated.map((field: string) => (
                    <Item key={field} value={field}>
                      {field}
                    </Item>
                  ))}
                </Select>
              </motion.div>
            )}
          </motion.div>
        </AnimatePresence>
      </ModalContent>
      <ModalFooter>
        <Button variant="clear" onClick={onClose} color="tomato">
          {t('cancel')}
        </Button>
        <Button
          onClick={() => setCrmObject(selectedRelatedObject)}
          color="bloobirds"
          disabled={!selectedCrmObject}
          className={!selectedCrmObject && styles.buttonDisabled}
        >
          {t('continue')}
        </Button>
      </ModalFooter>
    </>
  );
};
