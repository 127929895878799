import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Text } from '@bloobirds-it/flamingo-ui';

import { PhoneNumber } from '../../constants/phoneNumbers.constants';
import { useFlagEmoji } from '../../hooks/useFlagEmoji';
import styles from '../../styles/phoneNumberPage.module.css';
import { CapabilitiesCard } from '../rows/capabilitiesCard';

export const PhoneNumberInformation = ({ phone }: { phone: PhoneNumber }) => {
  const { t } = useTranslation();
  const { flag } = useFlagEmoji(phone.countryCode);
  return (
    <div className={styles.phoneInformation}>
      <div className={styles.itemInformation}>
        <Icon name="flagFilled" size={18} color="softPeanut" />
        <Text size="m" color="peanut" weight="bold">
          {t('accountSettings.phoneNumbers.sorts.country')}:
        </Text>
        <Text size="m" color="peanut">
          {flag}
        </Text>
        <Text size="m" color="peanut" weight="regular">
          {phone.location}
        </Text>
      </div>

      <div className={styles.itemInformation}>
        <Icon name="agendaPerson" size={18} color="softPeanut" />
        <Text size="m" color="peanut" weight="bold">
          {t('accountSettings.phoneNumbers.phoneSettings.tabs.generalSettings.typeOfNumber')}:
        </Text>
        <Text size="m" color="peanut" weight="regular">
          {phone.typeOfNumber}
        </Text>
      </div>

      <div className={styles.itemInformation}>
        <Icon name="gridSquares" size={18} color="softPeanut" />
        <Text size="m" color="peanut" weight="bold">
          {t('accountSettings.phoneNumbers.filters.capabilities')}:
        </Text>
        {phone.capabilities.map((capability, index) => (
          <CapabilitiesCard key={index} capability={capability} />
        ))}
      </div>
    </div>
  );
};
