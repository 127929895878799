import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Radio, RadioGroup, Text, useToasts } from '@bloobirds-it/flamingo-ui';
import { useActiveAccountId, useLanguage } from '@bloobirds-it/hooks';

import {
  useUserSettings,
  useUserSettingsReload,
} from '../../../../../components/userPermissions/hooks';
import {
  AccountSettingsSectionContent,
  AccountSettingsSectionSubtitle,
} from '../../../../../layouts/accountSettingsLayout/accountSettingsSection/accountSettingsSection';
import {
  AccountSettingsTab,
  AccountSettingsTabContent,
  AccountSettingsTabTitle,
} from '../../../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import { api } from '../../../../../utils/api';
import styles from '../../styles/generalSettingsPage.module.css';

export const LanguageCard = ({ defaultValue }: { defaultValue: 'es' | 'en' }) => {
  const reloadUserSettings = useUserSettingsReload();
  const accountId = useActiveAccountId();
  const [hasChanges, setHasChanges] = useState(false);
  const methods = useForm({ defaultValues: { language: defaultValue } });
  const { createToast } = useToasts();

  const { t } = useTranslation();
  const { languages } = useLanguage();

  const handleSave = ({ language }: { language: 'es' | 'en' }) => {
    setHasChanges(false);
    api.patch(`utils/service/accounts/${accountId}/language/${language}`, {}).then(() => {
      reloadUserSettings();
      createToast({
        message: t('accountSettings.generalSettings.language.successMessage'),
        type: 'success',
      });
    });
  };

  return (
    <FormProvider {...methods}>
      <AccountSettingsTab>
        <AccountSettingsTabTitle icon="language">
          <Text>{t('accountSettings.generalSettings.language.title')}</Text>
        </AccountSettingsTabTitle>
        <AccountSettingsSectionSubtitle>
          {t('accountSettings.generalSettings.language.subtitle')}
        </AccountSettingsSectionSubtitle>
        <AccountSettingsTabContent>
          <AccountSettingsSectionContent>
            <div className={styles._radio_language_wrapper}>
              <Controller
                name="language"
                render={({ value, onChange }) => (
                  <RadioGroup
                    value={value}
                    onChange={value => {
                      onChange(value);
                      setHasChanges(true);
                    }}
                    defaultValue={defaultValue}
                  >
                    {languages.map(language => (
                      <Radio key={language} size="small" value={language}>
                        <span>{t(`languages.${language}`)}</span>
                      </Radio>
                    ))}
                  </RadioGroup>
                )}
              />
            </div>
          </AccountSettingsSectionContent>
        </AccountSettingsTabContent>
        <AccountSettingsTabContent>
          <Button disabled={!hasChanges} onClick={methods.handleSubmit(handleSave)}>
            {t('common.save').toUpperCase()}
          </Button>
        </AccountSettingsTabContent>
      </AccountSettingsTab>
    </FormProvider>
  );
};
