// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".quickStartGuide-module__container___PPcLP {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  margin: 8px 24px;\n}\n\n.quickStartGuide-module__hint_container___wzl3f {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin: 8px 70px;\n}\n\n.quickStartGuide-module__emoji___tIGRo {\n  display: flex;\n  justify-content: center;\n}\n\n.quickStartGuide-module__academy_link___e8IcN {\n  cursor: pointer;\n  margin-top: 16px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.quickStartGuide-module__academy_link___e8IcN > * {\n  margin-right: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/homePage/components/quickStartGuide/quickStartGuide.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  margin: 8px 24px;\n}\n\n.hint_container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin: 8px 70px;\n}\n\n.emoji {\n  display: flex;\n  justify-content: center;\n}\n\n.academy_link {\n  cursor: pointer;\n  margin-top: 16px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.academy_link > * {\n  margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "quickStartGuide-module__container___PPcLP",
	"hint_container": "quickStartGuide-module__hint_container___wzl3f",
	"emoji": "quickStartGuide-module__emoji___tIGRo",
	"academy_link": "quickStartGuide-module__academy_link___e8IcN"
};
export default ___CSS_LOADER_EXPORT___;
