import React, { useState } from 'react';

import { useAccountId, useUserSettings } from '@bloobirds-it/hooks';
import { GeneralPrompt, Settings } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import { AxiosResponse } from 'axios';
import useSWR, { KeyedMutator } from 'swr';

const getGenericAiSummaryPrompt = (
  defaultAiSummaryPrompt: GeneralPrompt,
  settings: Settings,
): GeneralPrompt => ({
  ...defaultAiSummaryPrompt,
  id: 'GENERIC_AI_SUMMARY_PROMPT',
  createdBy: settings?.user?.name,
  updatedBy: settings?.user?.name,
  accountId: settings?.account.id,
});
interface IUseGeneralPromptsData {
  accountPrompts: Map<string, GeneralPrompt>;
  reloadAccountPrompts: KeyedMutator<AxiosResponse<GeneralPrompt[], any>>;
  accountPromptsArr: GeneralPrompt[];
  getDefaultPrompt: (promptType: string) => Promise<GeneralPrompt>;
}
export const useGeneralPrompts = (): IUseGeneralPromptsData => {
  const accountId = useAccountId();
  const settings = useUserSettings();
  const { data, mutate } = useSWR(accountId && 'account-prompts', () =>
    api.get<GeneralPrompt[]>(`/utils/service/prompts/account/${accountId}`),
  );

  const { data: defaultAiSummaryPrompt } = useSWR(accountId && 'default-ai-summary', () =>
    api
      .get<GeneralPrompt>('/utils/service/prompts/AI_SUMMARY?default=true')
      .then(payload => payload.data),
  );

  const getDefaultPrompt = (promptType: string) =>
    api
      .get<GeneralPrompt>(`/utils/service/prompts/${promptType}?default=true`)
      .then(payload => payload?.data);

  const accountPrompts: Map<string, GeneralPrompt> = new Map();

  if (data?.data) {
    data.data.forEach(prompt => {
      accountPrompts.set(prompt.promptType, prompt);
    });
  }

  if (!accountPrompts.get('AI_SUMMARY') && defaultAiSummaryPrompt) {
    accountPrompts.set('AI_SUMMARY', getGenericAiSummaryPrompt(defaultAiSummaryPrompt, settings));
  }

  return {
    accountPrompts,
    accountPromptsArr: data?.data || [],
    reloadAccountPrompts: mutate,
    getDefaultPrompt,
  };
};
