// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".accountSettingsSection-module__subtitle___TlKHf {\n  margin-top: 2px;\n}\n\n.accountSettingsSection-module__content___Fp4Ba {\n  margin-top: 16px;\n}\n\n.accountSettingsSection-module__footer___c0xFZ {\n  margin-top: 32px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/accountSettingsLayout/accountSettingsSection/accountSettingsSection.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".subtitle {\n  margin-top: 2px;\n}\n\n.content {\n  margin-top: 16px;\n}\n\n.footer {\n  margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": "accountSettingsSection-module__subtitle___TlKHf",
	"content": "accountSettingsSection-module__content___Fp4Ba",
	"footer": "accountSettingsSection-module__footer___c0xFZ"
};
export default ___CSS_LOADER_EXPORT___;
