import React, { createContext, useContext, ReactNode } from 'react';

import { useRequestPhoneForm } from '../hooks/useRequestPhoneForm';

type RequestedPhonesFormProviderContextType = ReturnType<typeof useRequestPhoneForm>;

const RequestPhoneNumberFormContext = createContext<
  RequestedPhonesFormProviderContextType | undefined
>(undefined);

export const RequestedPhonesFormProvider = ({ children }: { children: ReactNode }) => {
  const requestPhoneNumberForm = useRequestPhoneForm();
  return (
    <RequestPhoneNumberFormContext.Provider value={requestPhoneNumberForm}>
      {children}
    </RequestPhoneNumberFormContext.Provider>
  );
};

export const useRequestNumberFormContext = () => {
  const context = useContext(RequestPhoneNumberFormContext);
  if (!context) {
    throw new Error('usePhoneNumberFormContext must be used within a PhoneNumberFormProvider');
  }
  return context;
};
