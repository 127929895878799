// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".salesforceIntegrationPage-module___container___RUBCI {\n  box-sizing: border-box;\n  padding: 52px 40px;\n  width: 100%;\n}\n\n.salesforceIntegrationPage-module___tooltips_container___9r4U3 {\n  height: 0;\n}\n\n.salesforceIntegrationPage-module___tab_group___R-aA\\+ {\n  box-sizing: border-box;\n  padding-top: 20px;\n}\n\n.salesforceIntegrationPage-module__loader___x76zu {\n  margin-top: 35vh;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n}\n\n.salesforceIntegrationPage-module__spinner___VRJX8 {\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/salesforceIntegrationPage/salesforceIntegrationPage.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["._container {\n  box-sizing: border-box;\n  padding: 52px 40px;\n  width: 100%;\n}\n\n._tooltips_container {\n  height: 0;\n}\n\n._tab_group {\n  box-sizing: border-box;\n  padding-top: 20px;\n}\n\n.loader {\n  margin-top: 35vh;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n}\n\n.spinner {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "salesforceIntegrationPage-module___container___RUBCI",
	"_tooltips_container": "salesforceIntegrationPage-module___tooltips_container___9r4U3",
	"_tab_group": "salesforceIntegrationPage-module___tab_group___R-aA+",
	"loader": "salesforceIntegrationPage-module__loader___x76zu",
	"spinner": "salesforceIntegrationPage-module__spinner___VRJX8"
};
export default ___CSS_LOADER_EXPORT___;
