// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".disableCustomTaskModal-module__header___W7gxS {\n  padding: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.disableCustomTaskModal-module__title___rWABW {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.disableCustomTaskModal-module__content___p6uwU {\n  padding-bottom: 36px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/playbookPages/businessAssetsPage/customTaskTab/disableCustomTaskModal/disableCustomTaskModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".header {\n  padding: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.title {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.content {\n  padding-bottom: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "disableCustomTaskModal-module__header___W7gxS",
	"title": "disableCustomTaskModal-module__title___rWABW",
	"content": "disableCustomTaskModal-module__content___p6uwU"
};
export default ___CSS_LOADER_EXPORT___;
