// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".components___update_icon___XvYrp {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n.components___update_icon_icon___O\\+a1j {\n  margin: 4px;\n}\n.components___update_icon_title___YGwtl {\n  margin: 4px;\n}\n\n.components___add_property___zIy1r {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectTable/bulkActionsPanel/modals/updatePropertyModal/components/components.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":["._update_icon {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n._update_icon_icon {\n  margin: 4px;\n}\n._update_icon_title {\n  margin: 4px;\n}\n\n._add_property {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_update_icon": "components___update_icon___XvYrp",
	"_update_icon_icon": "components___update_icon_icon___O+a1j",
	"_update_icon_title": "components___update_icon_title___YGwtl",
	"_add_property": "components___add_property___zIy1r"
};
export default ___CSS_LOADER_EXPORT___;
