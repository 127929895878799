// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".activity-module__container___dGOro {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 85%;\n}\n\n.activity-module__row___Pb8nc {\n  display: flex;\n  flex-direction: column;\n  margin: 24px 0;\n  width: 100%;\n}\n\n.activity-module__title___v2rBD {\n  margin-bottom: 4px;\n}\n\n.activity-module__metrics_container___a5aBm {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n\n.activity-module__names_container___9TQqR {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.activity-module__names_container___9TQqR > p {\n  min-width: 55px;\n  display: flex;\n  justify-content: center;\n}\n\n.activity-module__skeleton___in13e {\n  display: flex;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/homePage/components/activity/activity.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 85%;\n}\n\n.row {\n  display: flex;\n  flex-direction: column;\n  margin: 24px 0;\n  width: 100%;\n}\n\n.title {\n  margin-bottom: 4px;\n}\n\n.metrics_container {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n\n.names_container {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.names_container > p {\n  min-width: 55px;\n  display: flex;\n  justify-content: center;\n}\n\n.skeleton {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "activity-module__container___dGOro",
	"row": "activity-module__row___Pb8nc",
	"title": "activity-module__title___v2rBD",
	"metrics_container": "activity-module__metrics_container___a5aBm",
	"names_container": "activity-module__names_container___9TQqR",
	"skeleton": "activity-module__skeleton___in13e"
};
export default ___CSS_LOADER_EXPORT___;
