// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inboundFieldGrid-module___field_wrapper___VUbVa {\n  display: flex;\n  flex-direction: column;\n}\n\n.inboundFieldGrid-module___field_grid___-K22Q {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  gap: 16px;\n}\n\n.inboundFieldGrid-module___field_grid_item___JAYVL {\n  display: flex;\n  gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/activitySection/activityItem/activityBody/inboundFieldGrid/inboundFieldGrid.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,kCAAkC;EAClC,SAAS;AACX;;AAEA;EACE,aAAa;EACb,QAAQ;AACV","sourcesContent":["._field_wrapper {\n  display: flex;\n  flex-direction: column;\n}\n\n._field_grid {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  gap: 16px;\n}\n\n._field_grid_item {\n  display: flex;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_field_wrapper": "inboundFieldGrid-module___field_wrapper___VUbVa",
	"_field_grid": "inboundFieldGrid-module___field_grid___-K22Q",
	"_field_grid_item": "inboundFieldGrid-module___field_grid_item___JAYVL"
};
export default ___CSS_LOADER_EXPORT___;
