// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".updateQuickFilterModal-module__content___9NYDC {\n  margin-bottom: 48px;\n}\n\n.updateQuickFilterModal-module__buttons___9fgmk > button:first-child {\n  margin-right: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/subhomeLayout/subhomeContent/newSubhomeFilters/quickFilters/updateQuickFilterModal/updateQuickFilterModal.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".content {\n  margin-bottom: 48px;\n}\n\n.buttons > button:first-child {\n  margin-right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "updateQuickFilterModal-module__content___9NYDC",
	"buttons": "updateQuickFilterModal-module__buttons___9fgmk"
};
export default ___CSS_LOADER_EXPORT___;
