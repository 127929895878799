import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Text } from '@bloobirds-it/flamingo-ui';
import { useUserHelpers } from '@bloobirds-it/hooks';
import { SignaturesKeys, UserHelperKeys } from '@bloobirds-it/types';

import { QuickStartGuideBlock, useStepContext } from '../otoConnectionsGuide';
import styles from './otoQSGSteps.module.css';

type SkipSubsectionProps = {
  step: QuickStartGuideBlock;
  setIsOpen: (value: boolean) => void;
};

export const SkipSubsection = ({ step, setIsOpen }: SkipSubsectionProps) => {
  const { t } = useTranslation();
  const { save } = useUserHelpers();
  const { setStateSignatures } = useStepContext();

  return (
    <div className={styles.containerSkip}>
      <Icon name="info" size={24} />
      <Text size="s">{t('quickStartGuide.oto.blocks.skipStep')}</Text>
      <Button
        onClick={() => {
          save(step.key);
          setIsOpen(false);
          if (step.key === UserHelperKeys.SET_YOUR_EMAIL_SIGNATURE) {
            setStateSignatures?.(SignaturesKeys.SKIPPED);
          }
        }}
        variant="secondary"
        size="small"
      >
        {t('quickStartGuide.oto.blocks.skip')}
      </Button>
    </div>
  );
};
