import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Label, SearchInput, Tooltip } from '@bloobirds-it/flamingo-ui';
import { User } from '@bloobirds-it/types';

import { useUserSettings } from '../../../../../components/userPermissions/hooks';
import { useMediaQuery } from '../../../../../hooks';
import {
  AccountSettingsTab,
  AccountSettingsTabContent,
  AccountSettingsTabHeader,
  AccountSettingsTabHeaderLeft,
  AccountSettingsTabHeaderRight,
  AccountSettingsTableContainer,
  AccountSettingsTabSubtitle,
  AccountSettingsTabTitle,
} from '../../../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import { CreateEditUserModal } from '../../components/createEditUserModal/createEditUserModal';
import { UserCreatedModal } from '../../components/userCreatedModal/userCreatedModal';
import { UsersList } from '../../components/usersList/usersList';
import { USER_TYPES } from '../../constants/users.constants';
import { useCreateEditUserModal } from '../../hooks/useCreateEditUserModal';
import { useUserCreatedModal } from '../../hooks/useUserCreatedModal';
import { useUsersList } from '../../hooks/useUsersList';
import styles from '../../styles/usersPage.module.css';

export const UsersTabPage = ({
  usersList,
  setSearchValue,
}: {
  usersList: User[];
  setSearchValue: (searchValue: string) => void;
}) => {
  const settings = useUserSettings();
  const { isSmallDesktop } = useMediaQuery();
  const { modalOpen, handleOpenCreateEditUserModal } = useCreateEditUserModal();
  const { modalOpen: userCreatedOpen, handleCloseUserCreatedModal } = useUserCreatedModal();
  const { t } = useTranslation();
  const { users } = useUsersList({
    filtersBody: {
      userType: USER_TYPES.LICENSE_USER,
    },
  });

  return (
    <>
      <AccountSettingsTab>
        <AccountSettingsTabHeader>
          <AccountSettingsTabHeaderLeft>
            <AccountSettingsTabTitle icon="person">
              <div className={styles._title_container}>
                {' '}
                {t('common.user_other')}{' '}
                <Label>
                  {users?.users?.length}{' '}
                  {settings?.account?.maxUsersAllowed
                    ? t('accountSettings.salesTeam.maxUsersCounter', {
                        maxUsersAllowed: settings?.account?.maxUsersAllowed,
                      })
                    : ''}
                </Label>{' '}
              </div>
            </AccountSettingsTabTitle>
            <AccountSettingsTabSubtitle>
              {t('accountSettings.salesTeam.usersSubtitle')}
            </AccountSettingsTabSubtitle>
          </AccountSettingsTabHeaderLeft>
          <AccountSettingsTabHeaderRight>
            <SearchInput
              width="200px"
              placeholder={t('common.search')}
              onChange={value => setSearchValue(value)}
              color="softBloobirds"
            />
            {users?.users?.length >= settings?.account?.maxUsersAllowed ? (
              <Tooltip title={t('accountSettings.salesTeam.maxUsersTooltip')} position="top">
                <Button disabled iconLeft="plus">
                  {!isSmallDesktop && t('accountSettings.salesTeam.createNewUser')}
                </Button>
              </Tooltip>
            ) : (
              <Button iconLeft="plus" onClick={() => handleOpenCreateEditUserModal({})}>
                {!isSmallDesktop && t('accountSettings.salesTeam.createNewUser')}
              </Button>
            )}
          </AccountSettingsTabHeaderRight>
        </AccountSettingsTabHeader>
        <AccountSettingsTabContent>
          <AccountSettingsTableContainer>
            <UsersList users={users} usersList={usersList} />
          </AccountSettingsTableContainer>
        </AccountSettingsTabContent>
      </AccountSettingsTab>
      {modalOpen && <CreateEditUserModal />}
      {userCreatedOpen && <UserCreatedModal onClose={handleCloseUserCreatedModal} />}
    </>
  );
};
