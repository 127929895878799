import React from 'react';
import { useTranslation } from 'react-i18next';

import { Switch, Text } from '@bloobirds-it/flamingo-ui';

import { usePhoneNumberFormContext } from '../../context/phoneNumberFormProvider';
import styles from '../../styles/phoneNumberPage.module.css';
import { WarningCallout } from './customCallouts';

export const CallRecording = () => {
  const { t } = useTranslation();
  const { handleChange, handleSaveChanges, phoneNumber } = usePhoneNumberFormContext();

  const handleToggleRecording = () => {
    const updatedPhoneNumber = {
      ...phoneNumber,
      callRecordingEnabled: !phoneNumber.callRecordingEnabled,
    };
    handleChange('callRecordingEnabled', updatedPhoneNumber.callRecordingEnabled);
    handleSaveChanges(updatedPhoneNumber);
  };

  return (
    <div className={styles.callRecordingContainer}>
      <Text size="s" color="softPeanut" weight="regular">
        {t(
          'accountSettings.phoneNumbers.phoneSettings.tabs.advancedSettings.sectionCallRecording.subtitle',
        )}
      </Text>
      <div className={styles.blueSwitch} style={{ maxWidth: '300px' }}>
        <Switch checked={phoneNumber.callRecordingEnabled} onChange={handleToggleRecording} />
        <Text size="s" color="peanut" weight="regular">
          {t(
            'accountSettings.phoneNumbers.phoneSettings.tabs.advancedSettings.sectionCallRecording.enableCallRecording',
          )}
        </Text>
      </div>
      <WarningCallout />
    </div>
  );
};
