import React from 'react';

import { Spinner } from '@bloobirds-it/flamingo-ui';
import { useUserSettings } from '@bloobirds-it/hooks';
import useSWR from 'swr';

import { api } from '../../../utils/api';
import styles from './timeTimeSchedulerPage.module.css';

interface TimeTimeSchedulerPageProps {
  redirectUrl?: string;
}

const TimeTimeSchedulerPage = ({ redirectUrl }: TimeTimeSchedulerPageProps) => {
  const settings = useUserSettings();

  const { data: token, error, isLoading } = useSWR(
    '/messaging/timetime-scheduler/token',
    async url => {
      const response = await api.get(url);
      return response?.data;
    },
  );

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Spinner name="loadingCircle" />
      </div>
    );
  }

  if (error) {
    return <div>Error loading scheduler profile</div>;
  }

  let externalUrl = `https://bookings.bloobirds.com/login-with-token?tt_hide_top_bar=true&tt_hide_sidebar=true&tt_app_name=${settings?.account?.name}`;
  if (redirectUrl) {
    externalUrl += `&redirect=${redirectUrl}`;
  }

  return isLoading ? (
    <div className={styles.loader}>
      <Spinner name="loadingCircle" />
    </div>
  ) : (
    <iframe
      name="timetime-scheduler"
      id="timetime-scheduler"
      allow="clipboard-write"
      src={`${externalUrl}&token=${token}`}
      frameBorder="0"
      width="100%"
      height="100%"
    ></iframe>
  );
};
export default TimeTimeSchedulerPage;
