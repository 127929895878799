// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".playbackRateButton-module__velocityDropdown___IjfPq {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  padding: 4px 12px;\n  gap: 8px;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/aiAnalysisPages/leftSideAiAnalysis/mediaAiAnalysis/controlBar/playbackRateButton/playbackRateButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,iBAAiB;EACjB,QAAQ;EACR,eAAe;AACjB","sourcesContent":[".velocityDropdown {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  padding: 4px 12px;\n  gap: 8px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"velocityDropdown": "playbackRateButton-module__velocityDropdown___IjfPq"
};
export default ___CSS_LOADER_EXPORT___;
