// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".statsPanel-module___stats_content___GZpSI {\n  display: flex;\n  justify-content: center;\n  overflow: hidden;\n  margin-bottom: 16px;\n}\n\n@media screen and (max-width: 1120px) {\n  .statsPanel-module___stats_content___GZpSI {\n    justify-content: flex-start;\n    margin-bottom: 0;\n  }\n}\n\n.statsPanel-module___stats_skeleton_content___5FcF4 {\n  width: 100%;\n  margin: 12px 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/dashboardPages/v2/statsPanel/statsPanel.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE;IACE,2BAA2B;IAC3B,gBAAgB;EAClB;AACF;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB","sourcesContent":["._stats_content {\n  display: flex;\n  justify-content: center;\n  overflow: hidden;\n  margin-bottom: 16px;\n}\n\n@media screen and (max-width: 1120px) {\n  ._stats_content {\n    justify-content: flex-start;\n    margin-bottom: 0;\n  }\n}\n\n._stats_skeleton_content {\n  width: 100%;\n  margin: 12px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_stats_content": "statsPanel-module___stats_content___GZpSI",
	"_stats_skeleton_content": "statsPanel-module___stats_skeleton_content___5FcF4"
};
export default ___CSS_LOADER_EXPORT___;
