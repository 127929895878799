import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { TDateISO, TDateISODate } from '@bloobirds-it/types';
import { isEqual, throttle } from 'lodash';
import { atom, useRecoilState } from 'recoil';
import spacetime from 'spacetime';

import { ICalendarEvent } from '../types/calendar';

const isMouseDownAtom = atom({
  key: 'eventPlaceholderIsMouseDownAtom',
  default: false,
});

export const useMouseEvents = () => {
  const [isMouseDown, setIsMouseDown] = useRecoilState<boolean>(isMouseDownAtom);

  return {
    isMouseDown,
    setIsMouseDown,
  };
};

export const useEventPlaceholder = (setMeetingDuration: (duration: number) => void) => {
  const [eventPlaceholder, setEventPlaceholder] = useState<Partial<ICalendarEvent> | null>(null);
  const methods = useFormContext();
  const dateTime = methods?.watch('dateTime');
  const duration = methods?.watch('duration');
  const title = methods?.watch('title');

  const generatePlaceHolder = useCallback(
    throttle((date: TDateISO, duration: number) => {
      const newPlaceholder: Partial<ICalendarEvent> = {
        duration: duration,
        type: 'placeholder',
        startTime: date,
        endTime: null,
        id: 'event-placeholder',
        title: title || 'Untitled Event',
        collisions: 0,
        collisionNumber: 0,
        day: spacetime(date).format('iso-short') as TDateISODate,
        participants: [],
        calendarId: 'eventPlaceholder',
      };

      setEventPlaceholder(prevPlaceholder => {
        if (!isEqual(prevPlaceholder, newPlaceholder)) {
          return newPlaceholder;
        }
        return prevPlaceholder;
      });
    }, 250),
    [title],
  );

  useEffect(() => {
    if (dateTime && duration) {
      generatePlaceHolder(dateTime as TDateISO, duration || 15);
    }
  }, [dateTime, duration, generatePlaceHolder]);

  const handlePlaceholderCreation = useCallback(
    throttle((date: TDateISO, duration: number) => {
      const newPlaceholder: ICalendarEvent = {
        duration: duration,
        type: 'placeholder',
        startTime: date,
        endTime: date,
        startTimeTimestamp: null,
        endTimeTimestamp: null,
        id: 'event-placeholder',
        title: title || 'Untitled Event',
        collisions: 0,
        collisionNumber: 0,
        day: spacetime(date).format('iso-short') as TDateISODate,
        participants: [],
        calendarId: 'eventPlaceholder',
      };

      setEventPlaceholder(prevPlaceholder => {
        if (!isEqual(prevPlaceholder, newPlaceholder)) {
          return newPlaceholder;
        }
        return prevPlaceholder;
      });
    }, 250),
    [title],
  );

  useEffect(() => {
    if (
      eventPlaceholder?.startTime &&
      spacetime(eventPlaceholder?.startTime).format('iso-utc') !==
        spacetime(dateTime).format('iso-utc')
    ) {
      methods?.setValue('dateTime', spacetime(eventPlaceholder?.startTime).toNativeDate());
    }
    if (eventPlaceholder?.duration && eventPlaceholder?.duration !== parseInt(duration)) {
      setMeetingDuration(eventPlaceholder?.duration);
      methods?.setValue('duration', eventPlaceholder?.duration);
    }
  }, [eventPlaceholder]);

  return {
    eventPlaceholder,
    onCalendarPlaceholder: handlePlaceholderCreation,
  };
};
