import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Text } from '@bloobirds-it/flamingo-ui';

import { LogoSvg } from '../../../../assets/svg';
import styles from './chromeAppSettings.module.css';

const ChromeAppSettings = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userSettingsWebapp.chromeAppSettings',
  });

  return (
    <div className={styles._container} data-intercom="user-settings-page-call">
      <div className={styles._content__box}>
        <div className={styles._logo__container}>
          <LogoSvg />
        </div>
        <div className={styles._text__box} />
        <div className={styles._heading__container}>
          <Text size="m" align="center">
            <Trans
              i18nKey="userSettingsWebapp.chromeAppSettings.title"
              components={[
                <Text key="0" size="m" weight="bold" inline>
                  {''}
                </Text>,
              ]}
            />
          </Text>
          {Array.from(Array(4).keys()).map((_, i) => {
            return (
              <Text key={i} size="m" className={styles._bullet_point}>
                <Trans
                  i18nKey={'userSettingsWebapp.chromeAppSettings.bulletPoints.' + i}
                  components={[
                    <Text key="0" size="m" weight="bold" inline>
                      {''}
                    </Text>,
                  ]}
                />
              </Text>
            );
          })}
        </div>
        <Button
          variant="secondary"
          className={styles._download_button}
          iconLeft="externalLink"
          onClick={() => {
            window.open(
              'https://chrome.google.com/webstore/detail/bloobirds/bfnmjliageccndnbpoadbigbnhicogbh',
            );
          }}
        >
          {t('link')}
        </Button>
        <Text color="peanut" size="xs" align="center">
          <Trans
            i18nKey="userSettingsWebapp.chromeAppSettings.knowledge"
            components={[
              <span
                key="0"
                className={styles._link}
                onClick={() => {
                  window.open(
                    'https://support.bloobirds.com/hc/en-us/articles/360019542260',
                    '_blank',
                  );
                }}
              ></span>,
            ]}
          />
        </Text>
      </div>
    </div>
  );
};

export default ChromeAppSettings;
