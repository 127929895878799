// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".deleteQuickFilterModal-module__content___twzf- {\n  padding-top: 8px;\n  padding-bottom: 36px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/subhomeLayout/subhomeContent/newSubhomeFilters/quickFilters/deleteQuickFilterModal/deleteQuickFilterModal.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,oBAAoB;AACtB","sourcesContent":[".content {\n  padding-top: 8px;\n  padding-bottom: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "deleteQuickFilterModal-module__content___twzf-"
};
export default ___CSS_LOADER_EXPORT___;
