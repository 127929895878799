// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".apiKeyCard-module__cardContainer___ctB37 {\n  display: flex;\n  justify-content: space-between;\n  padding: 20px 32px;\n  margin: 0 60px;\n  border: 1px solid #e5e4ed;\n  border-radius: 2px;\n}\n\n.apiKeyCard-module__cardContent___tXIug {\n  display: flex;\n}\n\n.apiKeyCard-module__cardContent___tXIug > div {\n  margin: 8px;\n}\n\n.apiKeyCard-module__cardDelete___fhcX- {\n  display: flex;\n}\n\n.apiKeyCard-module__confirmation_info___wYv9S {\n  margin-bottom: 8px;\n  line-height: 24px;\n}\n\n.apiKeyCard-module__confirmation_body___HSLvk {\n  margin-bottom: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/apiKeyPage/components/apiKeyCard/apiKeyCard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;EAClB,cAAc;EACd,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".cardContainer {\n  display: flex;\n  justify-content: space-between;\n  padding: 20px 32px;\n  margin: 0 60px;\n  border: 1px solid #e5e4ed;\n  border-radius: 2px;\n}\n\n.cardContent {\n  display: flex;\n}\n\n.cardContent > div {\n  margin: 8px;\n}\n\n.cardDelete {\n  display: flex;\n}\n\n.confirmation_info {\n  margin-bottom: 8px;\n  line-height: 24px;\n}\n\n.confirmation_body {\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": "apiKeyCard-module__cardContainer___ctB37",
	"cardContent": "apiKeyCard-module__cardContent___tXIug",
	"cardDelete": "apiKeyCard-module__cardDelete___fhcX-",
	"confirmation_info": "apiKeyCard-module__confirmation_info___wYv9S",
	"confirmation_body": "apiKeyCard-module__confirmation_body___HSLvk"
};
export default ___CSS_LOADER_EXPORT___;
