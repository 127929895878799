import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Text } from '@bloobirds-it/flamingo-ui';

import { PhoneNumber } from '../../constants/phoneNumbers.constants';
import { usePaginatedPhoneEntity } from '../../hooks/usePaginatedPhoneEntity';
import styles from '../../styles/phoneNumberPage.module.css';

export const StatusCard = ({ phone, showConfig }: { phone: PhoneNumber; showConfig: boolean }) => {
  const status = phone.status;
  const { t } = useTranslation();
  const { setShowPhoneSettings } = usePaginatedPhoneEntity();

  const handleOnClick = () => {
    setShowPhoneSettings({
      phone: phone,
      show: true,
    });
  };

  const statusConfig = status
    ? {
        dotClass: styles.greenDot,
        text: t('common.enabled'),
      }
    : {
        dotClass: styles.redDot,
        text: t('common.disabled'),
      };

  return (
    <>
      <div className={styles.statusCard}>
        <div className={statusConfig.dotClass}></div>
        <Text size="xs" color="peanut" weight="medium">
          {statusConfig.text}
        </Text>
      </div>
      {showConfig && (
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          <IconButton onClick={() => handleOnClick()} name="settings" size={20} color="bloobirds" />
        </div>
      )}
    </>
  );
};
