// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-module___header__container___oVnNJ {\n  width: 100%;\n  position: fixed;\n  height: 64px;\n  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.123);\n  display: flex;\n  align-items: center;\n  padding: 0 24px;\n  background-color: var(--white);\n  z-index: 1;\n}\n\n.header-module___back__button___L2Y9b {\n  margin-left: 32px;\n  margin-right: 64px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  letter-spacing: 0.8px;\n}\n\n.header-module___back__button___L2Y9b > svg {\n  margin-right: 10px;\n}\n\n.header-module___back__button___L2Y9b:hover {\n  cursor: pointer;\n}\n\n.header-module___title__container___wqzNY {\n  flex-grow: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/userSettingsPages/header/header.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,YAAY;EACZ,4CAA4C;EAC5C,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,8BAA8B;EAC9B,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd","sourcesContent":["._header__container {\n  width: 100%;\n  position: fixed;\n  height: 64px;\n  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.123);\n  display: flex;\n  align-items: center;\n  padding: 0 24px;\n  background-color: var(--white);\n  z-index: 1;\n}\n\n._back__button {\n  margin-left: 32px;\n  margin-right: 64px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  letter-spacing: 0.8px;\n}\n\n._back__button > svg {\n  margin-right: 10px;\n}\n\n._back__button:hover {\n  cursor: pointer;\n}\n\n._title__container {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_header__container": "header-module___header__container___oVnNJ",
	"_back__button": "header-module___back__button___L2Y9b",
	"_title__container": "header-module___title__container___wqzNY"
};
export default ___CSS_LOADER_EXPORT___;
