import React, { useEffect, useState } from 'react';

import { CopilotSummary } from '@bloobirds-it/copilot';
import { Icon, Text } from '@bloobirds-it/flamingo-ui';
import {
  useActiveAccountId,
  useActivityUserMentionsEnabled,
  useCopilotEnabled,
  useTogglerState,
  useUserMentions,
  useUserSettings,
} from '@bloobirds-it/hooks';
import {
  deserialize,
  EditorToolbar,
  EditorToolbarFontStylesSection,
  EditorToolbarListsSection,
  EditorToolbarTextMarksSection,
  MyEditor,
  RichTextEditor,
  useRichTextEditorPlugins,
} from '@bloobirds-it/rich-text-editor';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  ActivityInsights,
} from '@bloobirds-it/types';
import {
  api,
  createParagraph,
  getFieldByLogicRole,
  getTextFromLogicRole,
  isHtml,
} from '@bloobirds-it/utils';
import { resetEditorChildren, insertElements } from '@udecode/plate';
import debounce from 'lodash/debounce';
import useSWR from 'swr';

import { useStatusNoteActionContext } from '../../hooks/useStatusNoteActions';
import { ColumnHeader } from '../common/columnHeader';
import styles from './noteColumn.module.css';
import clsx from 'clsx';

export const NoteColumn = () => {
  const { note, setNote, loading, saveNote, hasSaved, activity, t } = useStatusNoteActionContext();
  const [editor, setEditor] = useState<MyEditor | null>(null);
  const settings = useUserSettings();
  const isCopilotEnabled = useCopilotEnabled(settings?.account?.id);
  const copilotAnalysis = getTextFromLogicRole(
    activity,
    ACTIVITY_FIELDS_LOGIC_ROLE.COPILOT_ANALYSIS,
  );
  const hasAi = !!copilotAnalysis;

  const { data: activityInsights } = useSWR(
    hasAi && `/copilot/transcript/insights/${activity?.id?.objectId}`,
    key => api.get<ActivityInsights>(key).then(res => res.data),
  );

  const accountId = useActiveAccountId();
  const mentionsEnabled = useActivityUserMentionsEnabled(accountId);
  const plugins = useRichTextEditorPlugins({
    images: false,
    replaceParagraphs: true,
    userMentions: mentionsEnabled,
    elements: true,
  });

  const activityType = getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE)
    ?.valueLogicRole;
  const isCallActivity = activityType === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL;
  const activityTypeString = isCallActivity ? t('noteColumn.call') : t('noteColumn.meeting');
  const debouncedSaveNote = React.useCallback(
    debounce(() => saveNote(), 1000),
    [],
  );
  const users = useUserMentions();

  const getDeserialize = (message, plugins) => {
    if (message) {
      return typeof message === 'string'
        ? isHtml(message)
          ? deserialize(message, {
              format: 'HTML',
              plugins: plugins,
            })
          : createParagraph(message)
        : message;
    }

    return null;
  };

  const deserializedNote = getDeserialize(note, plugins);

  const hasAISummary = activityInsights?.summary;
  const classes = clsx(styles.noteColumn, {
    [styles.withCopilot]: hasAISummary,
    [styles.withoutCopilot]: !hasAISummary,
  });

  return (
    <div className={classes}>
      <ColumnHeader
        icon="noteAction"
        text={t('noteColumn.header', { activityType: activityTypeString })}
        loading={loading}
      >
        {hasSaved && (
          <div style={{ display: 'flex' }}>
            <Icon name="check" color="softPeanut" size={16} />
            <Text size="s" color="softPeanut">
              {t('header.saved')}
            </Text>
          </div>
        )}
      </ColumnHeader>
      {isCopilotEnabled && (
        <CopilotSummary
          activityInsights={activityInsights}
          onAddToNote={() => {
            const summaryAsNote = getDeserialize(activityInsights?.summary, plugins);

            insertElements(editor, summaryAsNote);
            saveNote();
          }}
        />
      )}
      <RichTextEditor
        id={'note-detail-body'}
        users={users?.users}
        defaultValue={deserializedNote}
        plugins={plugins}
        value={deserializedNote}
        placeholder={t('noteColumn.placeholder')}
        setEditor={(value: MyEditor) => {
          setEditor(value);
        }}
        onChange={value => {
          setNote(value);
          debouncedSaveNote();
        }}
        style={{
          padding: '0',
          height: '100%',
        }}
      >
        {editor => (
          <>
            <div className={styles.body_wrapper}>{editor}</div>
            <div className={styles.toolbar}>
              {/* @ts-ignore */}
              <EditorToolbar backgroundColor="var(--peanut) !important">
                <EditorToolbarTextMarksSection color="peanut" />
                <EditorToolbarListsSection color="peanut" />
                <EditorToolbarFontStylesSection color="peanut" />
              </EditorToolbar>
            </div>
          </>
        )}
      </RichTextEditor>
    </div>
  );
};
