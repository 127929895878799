import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { isNonTranscriptActivity } from '@bloobirds-it/copilot';
import {
  Button,
  Input,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Spinner,
  Callout,
  Icon,
  Text,
} from '@bloobirds-it/flamingo-ui';
import { useLoadingPromise } from '@bloobirds-it/hooks';
import { api } from '@bloobirds-it/utils';

import useSendToPlaygroundModal from '../../hooks/useSendToPlaygroundModal';
import styles from './sendToPlaygroundModal.module.css';

const ACTIVITY_CONFIG = {
  ACTIVITY__TYPE__EMAIL: {
    icon: 'mail',
    color: 'tangerine',
  },
  ACTIVITY__TYPE__LINKEDIN_MESSAGE: {
    icon: 'linkedin',
    color: 'bloobirds',
  },
};

const ActivityCounter = ({ icon, color, children }) => (
  <Text className={styles.counterTexts} size="m">
    <Icon name={icon} color={color} size={24} className={styles.counterIcon} />
    {children}
  </Text>
);

const LoadingSpinner = () => (
  <div className={styles.checking_activities}>
    <Spinner name="loadingCircle" size={48} />
  </div>
);

const NoValidActivities = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'ai.sendToPlaygroundModal.noValidActivities',
  });

  return (
    <div className={styles.container}>
      {(t('messages', { returnObjects: true }) as string[]).map((message, index) => (
        <Text align="center" size="m" key={index}>
          <Trans i18nKey={`ai.sendToPlaygroundModal.noValidActivities.messages.${index}`}>
            {message}
          </Trans>
        </Text>
      ))}
    </div>
  );
};

const TypeMismatch = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'ai.sendToPlaygroundModal.typeMismatch',
  });

  return (
    <div className={styles.container}>
      <Text size="m">
        <Trans i18nKey="ai.sendToPlaygroundModal.typeMismatch.activityTypes" />
      </Text>
      <Text size="m">{t('instruction')}:</Text>
      <div className={styles.activityList}>
        <Text size="m">{t('calls')}</Text>
        <Text size="m">{t('meetings')}</Text>
        <Text size="m">{t('emails')}</Text>
        <Text size="m">{t('linkedin')}</Text>
      </div>
    </div>
  );
};

const ActivitySetContent = ({
  type,
  totalCount,
  threadCount,
  validActivitiesCount,
  invalidActivities,
}) => {
  const isNonTranscript = isNonTranscriptActivity(type);
  const isPartialSet = invalidActivities > 0;

  if (isNonTranscript) {
    return isPartialSet ? (
      <NonTranscriptPartialSet
        type={type}
        totalCount={totalCount}
        invalidActivities={invalidActivities}
        threadCount={threadCount}
      />
    ) : (
      <NonTranscriptCompleteSet type={type} totalCount={totalCount} threadCount={threadCount} />
    );
  }

  return isPartialSet ? (
    <WithTranscriptPartialSet
      type={type}
      validActivitiesCount={validActivitiesCount}
      invalidActivities={invalidActivities}
    />
  ) : (
    <TranscriptCompleteSet type={type} validActivitiesCount={validActivitiesCount} />
  );
};

const NonTranscriptPartialSet = ({ type, totalCount, invalidActivities, threadCount }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'ai.sendToPlaygroundModal.partialSet',
  });
  const { t: baseT } = useTranslation();
  const activityConfig = ACTIVITY_CONFIG[type];

  return (
    <div className={styles.container}>
      <Text size="m">
        <Trans i18nKey="ai.sendToPlaygroundModal.partialSet.title" />
      </Text>
      <Callout width="100%" withoutIcon>
        <Trans i18nKey={`ai.sendToPlaygroundModal.partialSet.threadWarning.${type}`} />
      </Callout>
      <div className={styles.activityStats}>
        <ActivityCounter icon={activityConfig.icon} color={activityConfig.color}>
          <Trans
            i18nKey={`ai.sendToPlaygroundModal.partialSet.validNonTranscriptActivitiesTotal.${type}`}
            values={{ count: totalCount }}
          />
        </ActivityCounter>
        <ActivityCounter icon="gridSquares" color="bloobirds">
          <Trans
            i18nKey="ai.sendToPlaygroundModal.partialSet.validNonTranscriptActivitiesThreadCount"
            values={{
              count: threadCount,
              type: baseT(`ai.insightActivity.${type}`, { count: threadCount }),
            }}
          />
        </ActivityCounter>
        <ActivityCounter icon="cross" color="tomato">
          <Trans
            i18nKey="ai.sendToPlaygroundModal.partialSet.invalidActivities"
            values={{
              count: invalidActivities,
            }}
          />
        </ActivityCounter>
      </div>
      <Text size="m">{t('disclaimer')}</Text>
      <Text size="m">{t('doYouWishToContinue')}</Text>
    </div>
  );
};

const NonTranscriptCompleteSet = ({ type, totalCount, threadCount }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'ai.sendToPlaygroundModal.correctSet',
  });
  const { t: baseT } = useTranslation();
  const activityConfig = ACTIVITY_CONFIG[type];

  return (
    <div className={styles.container}>
      <Text size="m">{t('title')}</Text>
      <Callout width="100%" withoutIcon>
        <Trans i18nKey={`ai.sendToPlaygroundModal.partialSet.threadWarning.${type}`} />
      </Callout>
      <div className={styles.activityStats}>
        <ActivityCounter icon={activityConfig.icon} color={activityConfig.color}>
          <Trans
            i18nKey={`ai.sendToPlaygroundModal.partialSet.validNonTranscriptActivitiesTotal.${type}`}
            values={{ count: totalCount }}
          />
        </ActivityCounter>
        <ActivityCounter icon="gridSquares" color="bloobirds">
          <Trans
            i18nKey="ai.sendToPlaygroundModal.partialSet.validNonTranscriptActivitiesThreadCount"
            values={{
              count: threadCount,
              type: baseT(`ai.insightActivity.${type}`, { count: threadCount }),
            }}
          />
        </ActivityCounter>
      </div>
      <Text size="m">{t('doYouWishToContinue')}</Text>
    </div>
  );
};

const WithTranscriptPartialSet = ({ type, validActivitiesCount, invalidActivities }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'ai.sendToPlaygroundModal.partialSet',
  });
  const { t: baseT } = useTranslation();

  return (
    <div className={styles.container}>
      <Text size="m">
        <Trans i18nKey="ai.sendToPlaygroundModal.partialSet.title" />
      </Text>
      <div className={styles.activityStats}>
        <ActivityCounter icon="check" color="melon">
          <Trans
            i18nKey="ai.sendToPlaygroundModal.partialSet.validActivities"
            values={{
              count: validActivitiesCount,
              type: baseT(`ai.insightActivity.${type}`, { count: validActivitiesCount }),
            }}
          />
        </ActivityCounter>
        <ActivityCounter icon="cross" color="tomato">
          <Trans
            i18nKey="ai.sendToPlaygroundModal.partialSet.invalidActivities"
            values={{ count: invalidActivities }}
          />
        </ActivityCounter>
      </div>
      <Text size="m">{t('disclaimer')}</Text>
      <Text size="m">{t('doYouWishToContinue')}</Text>
    </div>
  );
};

const TranscriptCompleteSet = ({ type, validActivitiesCount }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'ai.sendToPlaygroundModal.correctSet',
  });
  const { t: baseT } = useTranslation();

  return (
    <div className={styles.container}>
      <Text size="m">{t('title')}</Text>
      <div className={styles.activityStats}>
        <ActivityCounter icon="check" color="melon">
          <Trans
            i18nKey="ai.sendToPlaygroundModal.correctSet.validActivities"
            values={{
              count: validActivitiesCount,
              type: baseT(`ai.insightActivity.${type}`, { count: validActivitiesCount }),
            }}
          />
        </ActivityCounter>
      </div>
      <Text size="m">{t('doYouWishToContinue')}</Text>
    </div>
  );
};

// Main Modal Component
const SendToPlaygroundModal = () => {
  const {
    isOpen,
    closeSendToPlaygroundModal,
    bobjects,
    typeMismatch,
    checkingActivities,
    checkedActivities,
    validActivitiesCount,
    type,
    filterBySingleActivityThread,
    threadCounter,
  } = useSendToPlaygroundModal();

  const [testSetName, setTestSetName] = useState();
  const { t: baseT } = useTranslation();
  const { loading, promiseHandler } = useLoadingPromise();

  const canSend = !checkingActivities && !typeMismatch && validActivitiesCount > 0;

  const handleClose = () => {
    closeSendToPlaygroundModal();
    setTestSetName(undefined);
  };

  const handleConfirm = () => {
    promiseHandler(
      () =>
        api.post('/copilot/playground/test-set', {
          activities: filterBySingleActivityThread(bobjects).map(b => b.id.objectId),
          activityType: type,
          name: testSetName,
        }),
      {
        onSuccess: handleClose,
        successToast: 'ai.sendToPlaygroundModal.successToast',
        errorToast: 'ai.sendToPlaygroundModal.errorToast',
      },
    );
  };

  return (
    <Modal width={500} open={isOpen} onClose={handleClose}>
      <ModalHeader size="small">
        <ModalTitle icon="playground" size="small">
          {baseT('ai.sendToPlaygroundModal.title')}
        </ModalTitle>
        <ModalCloseIcon size="small" onClick={handleClose} />
      </ModalHeader>

      <ModalContent className={styles.content}>
        {checkingActivities && <LoadingSpinner />}
        {!checkingActivities && typeMismatch && <TypeMismatch />}
        {!checkingActivities && !typeMismatch && checkedActivities && (
          <>
            {validActivitiesCount === 0 ? (
              <NoValidActivities />
            ) : (
              <>
                <ActivitySetContent
                  type={type}
                  totalCount={bobjects?.length}
                  threadCount={threadCounter(bobjects, type)}
                  validActivitiesCount={validActivitiesCount}
                  invalidActivities={checkedActivities.invalidActivities}
                />
                <Input
                  className={styles.name_input}
                  placeholder="Test set name"
                  value={testSetName}
                  onChange={setTestSetName}
                  width="100%"
                />
              </>
            )}
          </>
        )}
      </ModalContent>

      <ModalFooter>
        <Button variant="clear" onClick={handleClose}>
          {baseT(canSend ? 'common.cancel' : 'common.close')}
        </Button>
        {canSend && (
          <Button disabled={!testSetName || loading} onClick={handleConfirm}>
            {loading ? <Spinner name="loadingCircle" /> : baseT('common.confirm')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default SendToPlaygroundModal;
