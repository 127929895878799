// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contactTabs-module___container___2hZ5c {\n  margin-top: 40px;\n}\n\n.contactTabs-module___container___2hZ5c > div > div:first-child {\n  max-width: 1280px;\n  margin: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/contactPages/contactTabs/contactTabs.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,YAAY;AACd","sourcesContent":["._container {\n  margin-top: 40px;\n}\n\n._container > div > div:first-child {\n  max-width: 1280px;\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "contactTabs-module___container___2hZ5c"
};
export default ___CSS_LOADER_EXPORT___;
