// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".userTeamsFilters-module__users___TO5xU {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  color: var(--darkGray);\n  font-size: 14px;\n  font-weight: var(--fontMedium);\n  line-height: 16px;\n  padding: 16px 8px 8px;\n  margin-bottom: -24px;\n  cursor: pointer;\n}\n\n.userTeamsFilters-module__users___TO5xU > div {\n  display: flex;\n  justify-content: space-between;\n}\n\n.userTeamsFilters-module__users___TO5xU > div:hover {\n  color: var(--bloobirds);\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/dashboardPages/v2/filters/userTeamsFilters.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,sBAAsB;EACtB,eAAe;EACf,8BAA8B;EAC9B,iBAAiB;EACjB,qBAAqB;EACrB,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".users {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  color: var(--darkGray);\n  font-size: 14px;\n  font-weight: var(--fontMedium);\n  line-height: 16px;\n  padding: 16px 8px 8px;\n  margin-bottom: -24px;\n  cursor: pointer;\n}\n\n.users > div {\n  display: flex;\n  justify-content: space-between;\n}\n\n.users > div:hover {\n  color: var(--bloobirds);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"users": "userTeamsFilters-module__users___TO5xU"
};
export default ___CSS_LOADER_EXPORT___;
