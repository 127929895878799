import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CircularBadge, Icon, IconButton, Item, Select, Text } from '@bloobirds-it/flamingo-ui';
import { TeamMember, UserTeamRole } from '@bloobirds-it/types';

import styles from './teamManagementModal/teamManagement.module.css';

export function TeamMemberCard({
  user,
  openConfirmDeleteModal,
}: {
  user: TeamMember;
  openConfirmDeleteModal: (onSave: () => void) => void;
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.salesTeam.teamUsersPage.teamManagementModal.teamMemberCard',
  });
  const { getValues, setValue } = useFormContext();
  const formValues = getValues();
  const teamUsersValue = formValues?.teamUsers;

  const removeUser = () => {
    const filteredTeamUser = teamUsersValue?.filter((u: TeamMember) => u?.userId !== user?.userId);
    setValue('teamUsers', filteredTeamUser);
  };

  return (
    <div className={styles.memberInfo}>
      <div className={styles.modalUserInfo}>
        <CircularBadge
          size="m"
          style={{ flexShrink: 0 }}
          backgroundColor={user.userColor}
          color="white"
        >
          {/* @ts-ignore */}
          {user?.userShortName ?? <Icon name="person" size={16} color="white" />}
        </CircularBadge>
        <section>
          <Text size="s" weight="bold">
            {user.userName}
          </Text>
          <Text size="xs" color="softPeanut">
            {user.userEmail}
          </Text>
        </section>
      </div>
      <div className={styles.userSelect}>
        <Select
          value={user.userRole}
          onChange={value => {
            setValue(
              'teamUsers',
              teamUsersValue?.map((u: TeamMember) => {
                if (u?.userId === user?.userId) {
                  return { ...u, userRole: value };
                }
                return u;
              }),
            );
          }}
          width="164px"
        >
          <Item value={UserTeamRole.Manager}>{t('manager')}</Item>
          <Item value={UserTeamRole.User}>{t('user')}</Item>
        </Select>
        <IconButton
          name="trashFull"
          size={18}
          color="softPeanut"
          onClick={() => openConfirmDeleteModal(removeUser)}
        />
      </div>
    </div>
  );
}
