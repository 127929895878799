// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".somethingWentWrongPage-module__wrapper___rbsE5 {\n  padding: 40px 120px;\n  margin-bottom: 40px;\n  width: 100%;\n  height: 100vh;\n  overflow-y: auto;\n  overflow-x: hidden;\n  background: var(--lighterGray);\n  display: flex;\n  flex-direction: column;\n}\n\n.somethingWentWrongPage-module__container___\\+zKHo {\n  width: 100%;\n  height: calc(100% - 140px);\n  display: flex;\n  background-color: var(--white);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 24px;\n  border: 1px solid var(--veryLightPeanut);\n  border-radius: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/somethingWentWrongPage/somethingWentWrongPage.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,8BAA8B;EAC9B,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,0BAA0B;EAC1B,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,wCAAwC;EACxC,kBAAkB;AACpB","sourcesContent":[".wrapper {\n  padding: 40px 120px;\n  margin-bottom: 40px;\n  width: 100%;\n  height: 100vh;\n  overflow-y: auto;\n  overflow-x: hidden;\n  background: var(--lighterGray);\n  display: flex;\n  flex-direction: column;\n}\n\n.container {\n  width: 100%;\n  height: calc(100% - 140px);\n  display: flex;\n  background-color: var(--white);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 24px;\n  border: 1px solid var(--veryLightPeanut);\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "somethingWentWrongPage-module__wrapper___rbsE5",
	"container": "somethingWentWrongPage-module__container___+zKHo"
};
export default ___CSS_LOADER_EXPORT___;
