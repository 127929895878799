// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".disconnectModal-module___container___FIwbH {\n  padding-bottom: 10px;\n  padding-bottom: 30px;\n}\n\n.disconnectModal-module___emoji__container___MKd4d {\n  margin-right: 5px;\n}\n\n.disconnectModal-module___checkbox_wrapper___qrJ\\+f {\n  margin-top: 20px;\n  margin-bottom: 40px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/connectionCard/disconnectModal/disconnectModal.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["._container {\n  padding-bottom: 10px;\n  padding-bottom: 30px;\n}\n\n._emoji__container {\n  margin-right: 5px;\n}\n\n._checkbox_wrapper {\n  margin-top: 20px;\n  margin-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "disconnectModal-module___container___FIwbH",
	"_emoji__container": "disconnectModal-module___emoji__container___MKd4d",
	"_checkbox_wrapper": "disconnectModal-module___checkbox_wrapper___qrJ+f"
};
export default ___CSS_LOADER_EXPORT___;
