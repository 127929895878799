import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, IconType } from '@bloobirds-it/flamingo-ui';
import { ButtonVariant } from '@bloobirds-it/flamingo-ui/dist/components/Button/Button';

import { useRequestNumberFormContext } from '../../context/requestedPhonesFormProvider';
import styles from '../../styles/phoneNumberPage.module.css';
import { useDirtyContext } from './shared/dirtyContext';

export const NavigationButtons = () => {
  const { t } = useTranslation();
  const {
    currentStep,
    handleStepChange,
    addNewPhoneNumber,
    createLinearIssue,
  } = useRequestNumberFormContext();
  const { setIsDirty } = useDirtyContext();
  const buttons = [
    {
      label: 'accountSettings.phoneNumbers.purchaseNewPhone.goToOrderReview',
      icon: 'deliver',
      variant: 'clear',
      onClick: () => handleStepChange(1),
      visible: currentStep === 0,
    },
    {
      label: 'accountSettings.phoneNumbers.purchaseNewPhone.addAnotherNumber',
      icon: 'plus',
      variant: 'primary',
      onClick: () => {
        addNewPhoneNumber();
        setIsDirty(false);
      },
      visible: currentStep === 0,
    },
    {
      label: 'accountSettings.phoneNumbers.purchaseNewPhone.addMoreNumbers',
      icon: 'plus',
      variant: 'clear',
      onClick: () => handleStepChange(0),
      visible: currentStep === 1,
    },
    {
      label: 'accountSettings.phoneNumbers.purchaseNewPhone.requestNumbers',
      icon: 'deliver',
      variant: 'primary',
      onClick: createLinearIssue,
      visible: currentStep === 1,
    },
  ];

  return (
    <div className={styles.navigationButtons}>
      {buttons
        .filter(button => button.visible)
        .map((button, index) => (
          <Button
            key={button.label}
            className={index === 0 ? styles.buttonOrder : undefined}
            color="bloobirds"
            iconLeft={button.icon as IconType}
            onClick={button.onClick || undefined}
            uppercase={false}
            variant={button.variant as ButtonVariant}
          >
            {t(button.label)}
          </Button>
        ))}
    </div>
  );
};
