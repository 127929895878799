// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".moreFilter-module__moreFilterInput___0HPVP {\n  background: var(--lightestBloobirds);\n  color: var(--peanut);\n  box-sizing: border-box;\n  border-radius: 4px;\n  max-width: 180px;\n  padding: 4px 6px;\n  font-size: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  cursor: pointer;\n}\n\n.moreFilter-module__moreFilterInput___0HPVP > svg {\n  margin-left: 8px;\n}\n\n.moreFilter-module__moreFiltersInputSelected___GggTq {\n  background: var(--softPeanut);\n  color: var(--white);\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/subhomeLayout/subhomeContent/newSubhomeFilters/moreFilter/moreFilter.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,oBAAoB;EACpB,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,mBAAmB;AACrB","sourcesContent":[".moreFilterInput {\n  background: var(--lightestBloobirds);\n  color: var(--peanut);\n  box-sizing: border-box;\n  border-radius: 4px;\n  max-width: 180px;\n  padding: 4px 6px;\n  font-size: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  cursor: pointer;\n}\n\n.moreFilterInput > svg {\n  margin-left: 8px;\n}\n\n.moreFiltersInputSelected {\n  background: var(--softPeanut);\n  color: var(--white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"moreFilterInput": "moreFilter-module__moreFilterInput___0HPVP",
	"moreFiltersInputSelected": "moreFilter-module__moreFiltersInputSelected___GggTq"
};
export default ___CSS_LOADER_EXPORT___;
