// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".importStepper-module__stepper__root___--E0p {\n  width: 100%;\n  padding-top: 12px;\n}\n\n.importStepper-module__stepConnector___LP6wP .importStepper-module__alternativeLabel___5-nN4 {\n  top: 10px;\n  left: calc(-50% + 16px);\n  right: calc(50% + 16px);\n}\n\n.importStepper-module__stepConnector___LP6wP .importStepper-module__line___Rhi77 {\n  border-color: #eaeaf0;\n  border-top-width: 3px;\n  border-radius: 1px;\n}\n\n.importStepper-module__stepIcons__root___jU2iO {\n  color: #eaeaf0;\n  display: flex;\n  height: 22px;\n  align-items: center;\n}\n\n.importStepper-module__stepIcons__active___wzEIs {\n  color: natural;\n}\n\n.importStepper-module__stepIcons__circle___1Cs8p {\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n  background-color: var(--bloobirds);\n}\n\n.importStepper-module__stepIcons__completed___V0oMi {\n  z-index: 1;\n  font-size: 18px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/importForm/stepper/importStepper.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,kCAAkC;AACpC;;AAEA;EACE,UAAU;EACV,eAAe;AACjB","sourcesContent":[".stepper__root {\n  width: 100%;\n  padding-top: 12px;\n}\n\n.stepConnector .alternativeLabel {\n  top: 10px;\n  left: calc(-50% + 16px);\n  right: calc(50% + 16px);\n}\n\n.stepConnector .line {\n  border-color: #eaeaf0;\n  border-top-width: 3px;\n  border-radius: 1px;\n}\n\n.stepIcons__root {\n  color: #eaeaf0;\n  display: flex;\n  height: 22px;\n  align-items: center;\n}\n\n.stepIcons__active {\n  color: natural;\n}\n\n.stepIcons__circle {\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n  background-color: var(--bloobirds);\n}\n\n.stepIcons__completed {\n  z-index: 1;\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepper__root": "importStepper-module__stepper__root___--E0p",
	"stepConnector": "importStepper-module__stepConnector___LP6wP",
	"alternativeLabel": "importStepper-module__alternativeLabel___5-nN4",
	"line": "importStepper-module__line___Rhi77",
	"stepIcons__root": "importStepper-module__stepIcons__root___jU2iO",
	"stepIcons__active": "importStepper-module__stepIcons__active___wzEIs",
	"stepIcons__circle": "importStepper-module__stepIcons__circle___1Cs8p",
	"stepIcons__completed": "importStepper-module__stepIcons__completed___V0oMi"
};
export default ___CSS_LOADER_EXPORT___;
