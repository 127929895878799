// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".searchCompanyItem___search_item_container___VsoIW {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n\n.searchCompanyItem___search_item_text___IVluX {\n  margin-left: 8px;\n  margin-top: 4px;\n}\n\n.searchCompanyItem___circular_badge_wrapper___nCShG {\n  flex-shrink: 0;\n}\n\n.searchCompanyItem___search_item_card___JFq-9 {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/contactPages/navegationBar/companiesRelatedDropdown/searchCompanyItem/searchCompanyItem.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,8BAA8B;AAChC","sourcesContent":["._search_item_container {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n\n._search_item_text {\n  margin-left: 8px;\n  margin-top: 4px;\n}\n\n._circular_badge_wrapper {\n  flex-shrink: 0;\n}\n\n._search_item_card {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_search_item_container": "searchCompanyItem___search_item_container___VsoIW",
	"_search_item_text": "searchCompanyItem___search_item_text___IVluX",
	"_circular_badge_wrapper": "searchCompanyItem___circular_badge_wrapper___nCShG",
	"_search_item_card": "searchCompanyItem___search_item_card___JFq-9"
};
export default ___CSS_LOADER_EXPORT___;
