// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".salesLabel-module___label_div___aC4L- {\n  content: '';\n  position: absolute;\n  top: -2px;\n  left: 0;\n  padding: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/salesLabel/salesLabel.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,UAAU;AACZ","sourcesContent":["._label_div {\n  content: '';\n  position: absolute;\n  top: -2px;\n  left: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_label_div": "salesLabel-module___label_div___aC4L-"
};
export default ___CSS_LOADER_EXPORT___;
