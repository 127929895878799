// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".accountSettingsLayout-module___container___zfqY9 {\n  padding: 48px 40px;\n}\n\n.accountSettingsLayout-module___title___4-ZdG {\n  display: flex;\n  align-items: baseline;\n  margin-bottom: 10px;\n}\n\n.accountSettingsLayout-module___title___4-ZdG > * {\n  margin-right: 10px;\n}\n\n.accountSettingsLayout-module___subtitle___cg1p9 {\n  margin: 10px 0 20px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/accountSettingsLayout/accountSettingsLayout.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["._container {\n  padding: 48px 40px;\n}\n\n._title {\n  display: flex;\n  align-items: baseline;\n  margin-bottom: 10px;\n}\n\n._title > * {\n  margin-right: 10px;\n}\n\n._subtitle {\n  margin: 10px 0 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "accountSettingsLayout-module___container___zfqY9",
	"_title": "accountSettingsLayout-module___title___4-ZdG",
	"_subtitle": "accountSettingsLayout-module___subtitle___cg1p9"
};
export default ___CSS_LOADER_EXPORT___;
