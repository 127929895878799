// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subhomeCard-module___container___dVi0u {\n  position: relative;\n  margin: 8px 0;\n  cursor: pointer;\n}\n\n.subhomeCard-module__card_description___XMgiU{\n  display: flex;\n  gap: 4px;\n  padding-top: 12px;\n}\n\n.subhomeCard-module___container___dVi0u .subhomeCard-module___link_wrapper_ml___CufCG {\n  margin-left: 0;\n}\n\n.subhomeCard-module___dashed_line___onIDg {\n  position: absolute;\n  width: 0;\n  height: 8px;\n  left: 28px;\n  border-left: 1px dashed #c5d1dd;\n}\n\n.subhomeCard-module___container___dVi0u:last-child {\n  margin-bottom: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/subhomeLayout/subhomeContent/subhomeCard/subhomeCard.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,UAAU;EACV,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["._container {\n  position: relative;\n  margin: 8px 0;\n  cursor: pointer;\n}\n\n.card_description{\n  display: flex;\n  gap: 4px;\n  padding-top: 12px;\n}\n\n._container ._link_wrapper_ml {\n  margin-left: 0;\n}\n\n._dashed_line {\n  position: absolute;\n  width: 0;\n  height: 8px;\n  left: 28px;\n  border-left: 1px dashed #c5d1dd;\n}\n\n._container:last-child {\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "subhomeCard-module___container___dVi0u",
	"card_description": "subhomeCard-module__card_description___XMgiU",
	"_link_wrapper_ml": "subhomeCard-module___link_wrapper_ml___CufCG",
	"_dashed_line": "subhomeCard-module___dashed_line___onIDg"
};
export default ___CSS_LOADER_EXPORT___;
