export const ALLOCATE_QC_TASK_LOAD_ASSIGNEES_SUCCESS = 'ALLOCATE_QC_TASK_LOAD_ASSIGNEES_SUCCESS';
export const COMPANIES_TO_ALLOCATE_LOAD_SUCCESS = 'COMPANIES_TO_ALLOCATE_LOAD_SUCCESS';
export const ALLOCATE_QC_TASK_ALLOCATE_QC_TRANSACTION_END =
  'ALLOCATE_QC_TASK_ALLOCATE_QC_TRANSACTION_END';
export const COMPANIES_TO_ALLOCATE_SET_DRAGGED_COMPANY =
  'COMPANIES_TO_ALLOCATE_SET_DRAGGED_COMPANY';
export const COMPANIES_TO_ALLOCATE_SET_DRAGGED_XY = 'COMPANIES_TO_ALLOCATE_SET_DRAGGED_XY';
export const COMPANIES_TO_ALLOCATE_SET_DROPPABLE_ELEMENT =
  'COMPANIES_TO_ALLOCATE_SET_DROPPABLE_ELEMENT';
export const COMPANIES_TO_ALLOCATE_RESTORE_DRAGGED_PROPERTIES =
  'COMPANIES_TO_ALLOCATE_RESTORE_DRAGGED_PROPERTIES';
export const ALLOCATE_QC_TASK_FILTERS_CLEAN = 'ALLOCATE_QC_TASK_FILTERS_CLEAN';
export const ALLOCATE_QC_TASK_PAGE_PER_ROW = 'ALOCATE_QC_TASK_PAGE_PER_ROW';
export const ALLOCATE_QC_TASK_CHANGE_PAGE = 'ALLOCATE_QC_TASK_CHANGE_PAGE';
export const ALLOCATE_QC_TASK_ASSIGNEE_EXPANDED = 'ALLOCATE_QC_TASK_ASSIGNEE_EXPANDED';
export const ALLOCATE_QC_TASK_LOAD_ASSIGNEES_AGG_MR_SUCCESS =
  'ALLOCATE_QC_TASK_LOAD_ASSIGNEES_AGG_MR_SUCCESS';
export const ALLOCATE_QC_TASK_LOAD_ASSIGNEES_AGG_STATUS_SUCCESS =
  'ALLOCATE_QC_TASK_LOAD_ASSIGNEES_AGG_STATUS_SUCCESS';
export const ALLOCATE_QC_TASK_ASSIGNEE_CLEAR = 'ALLOCATE_QC_TASK_ASSIGNEE_CLEAR';
export const ALLOCATE_QC_TASK_SELECT_QC = 'ALLOCATE_QC_TASK_SELECT_QC';
export const ALLOCATE_QC_TASK_SELECT_QC_RESET = 'ALLOCATE_QC_TASK_SELECT_QC_RESET';
export const ALLOCATE_QC_TASK_SELECT_RANGE_QC = 'ALLOCATE_QC_TASK_SELECT_RANGE_QC';
export const COMPANIES_TO_ALLOCATE_CALCULATE_CENTER = 'COMPANIES_TO_ALLOCATE_CALCULATE_CENTER';
export const ALLOCATE_QC_TASK_SELECT_COMPANIES_TOGGLE = 'ALLOCATE_QC_TASK_SELECT_COMPANIES_TOGGLE';
export const ALLOCATE_QC_TASK_ALLOCATE_QC_TRANSACTION_ON_LOAD =
  'ALLOCATE_QC_TASK_ALLOCATE_QC_TRANSACTION_ON_LOAD';
export const ALLOCATE_QC_TASK_CLEAR_SESSION = 'ALLOCATE_QC_TASK_CLEAR_SESSION';
export const ALLOCATE_QC_TASK_RESET_COMPANIES = 'ALLOCATE_QC_TASK_RESET_COMPANIES';
export const ALLOCATE_QC_TASK_APPLY_FILTERS = 'ALLOCATE_QC_TASK_APPLY_FILTERS';
