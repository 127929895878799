import { useTranslation } from 'react-i18next';

import { useToasts } from '@bloobirds-it/flamingo-ui';
import { TeamFormType, TeamModalModes } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';

import { useTeamManagementModalVisibility } from '../components/teamManagementModal/hooks/useTeamManagementModalVisibility';

export const useTeamsManagement = (onSave?: () => void) => {
  const { createToast } = useToasts();
  const { closeModal, viewManagementModal } = useTeamManagementModalVisibility();
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.salesTeam.teamUsersPage.teamManagementModal.toasts',
  });
  const isEdition = viewManagementModal?.mode === TeamModalModes.Edition;

  const handleFormSubmit = (formValues: TeamFormType) => {
    if (isEdition) {
      api
        .patch('utils/teamUser/' + viewManagementModal?.id, {
          ...formValues,
          ...{
            ['teamUsers']: [...formValues.teamUsers, ...Object.values(formValues?.newTeamUsers)],
          },
        })
        .then(() => {
          createToast({ type: 'success', message: t('edit.success') });
          onSave?.();
          closeModal();
        })
        .catch(() => {
          createToast({ type: 'error', message: t('edit.error') });
        });
    } else {
      api
        .post('utils/teamUser', {
          ...formValues,
          ...{
            ['teamUsers']: [...formValues.teamUsers, ...Object.values(formValues?.newTeamUsers)],
          },
        })
        .then(() => {
          createToast({ type: 'success', message: t('create.success') });
          onSave?.();
          closeModal();
        })
        .catch(() => {
          createToast({ type: 'error', message: t('create.error') });
        });
    }
  };

  const handleDeleteTeam = () => {
    api
      .delete('utils/teamUser/delete/' + viewManagementModal?.id)
      .then(() => {
        createToast({ type: 'success', message: t('delete.success') });
        onSave?.();
        closeModal();
      })
      .catch(() => {
        createToast({ type: 'error', message: t('delete.error') });
      });
  };

  return { handleFormSubmit, handleDeleteTeam };
};
