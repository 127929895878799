import { IconType } from '@bloobirds-it/flamingo-ui';

export enum TeamModalModes {
  Edition,
  Create,
  Idle,
}

export enum UserTeamRole {
  User = 'USER',
  Manager = 'MANAGER',
}

export interface TeamMember {
  userId: string;
  userRole: UserTeamRole;
  userName: string;
  userEmail: string;
  userColor: string;
  userShortName: string;
}

export interface TeamType {
  id: string;
  icon: IconType;
  name: string;
  timezone: string;
  teamUsers: TeamMember[];
}

export interface TeamFormType extends TeamType {
  newTeamUsers: TeamMember[];
}

export interface TeamModalProps extends TeamType {
  mode: TeamModalModes;
}
