// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".searchFilter-module___container___H4Hdv {\n  width: 160px;\n  margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectTable/rightPanel/searchFilter/searchFilter.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":["._container {\n  width: 160px;\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "searchFilter-module___container___H4Hdv"
};
export default ___CSS_LOADER_EXPORT___;
