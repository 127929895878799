// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dashboardPage-module__root___TxMES {\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: center;\n  flex-direction: row;\n  z-index: 0;\n}\n\n.dashboardPage-module__root_withErrors___lyCkd {\n  flex: 1;\n  align-items: center;\n  justify-content: center;\n}\n\n.dashboardPage-module__content___nIqej {\n  flex: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/dashboardPages/v1/dashboardPage/dashboardPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,OAAO;EACP,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,OAAO;EACP,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,OAAO;AACT","sourcesContent":[".root {\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: center;\n  flex-direction: row;\n  z-index: 0;\n}\n\n.root_withErrors {\n  flex: 1;\n  align-items: center;\n  justify-content: center;\n}\n\n.content {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "dashboardPage-module__root___TxMES",
	"root_withErrors": "dashboardPage-module__root_withErrors___lyCkd",
	"content": "dashboardPage-module__content___nIqej"
};
export default ___CSS_LOADER_EXPORT___;
