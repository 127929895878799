// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".assigneeList-module__assigneeList___zPIoy {\n  width: 212px;\n  margin-right: 20px;\n  overflow-y: scroll;\n  height: calc(100vh - 267px);\n}\n\n.assigneeList-module__subTitle___eVfqK {\n  font-size: 13px;\n  line-height: 1.23;\n  letter-spacing: 0.8px;\n  color: var(--softPeanut);\n}\n", "",{"version":3,"sources":["webpack://./src/js/app/main/board/task/taskBoard/workspace/allocateQcTask/assigneeList.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,qBAAqB;EACrB,wBAAwB;AAC1B","sourcesContent":[".assigneeList {\n  width: 212px;\n  margin-right: 20px;\n  overflow-y: scroll;\n  height: calc(100vh - 267px);\n}\n\n.subTitle {\n  font-size: 13px;\n  line-height: 1.23;\n  letter-spacing: 0.8px;\n  color: var(--softPeanut);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assigneeList": "assigneeList-module__assigneeList___zPIoy",
	"subTitle": "assigneeList-module__subTitle___eVfqK"
};
export default ___CSS_LOADER_EXPORT___;
