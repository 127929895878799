// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rightPanel-module___container___DPAA0 {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  width: 100%;\n  min-width: max-content;\n}\n\n.rightPanel-module___import_button_container___HeWbN {\n  margin-right: 10px;\n  margin-left: 10px;\n  display: inline-flex;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectTable/rightPanel/rightPanel.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":["._container {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  width: 100%;\n  min-width: max-content;\n}\n\n._import_button_container {\n  margin-right: 10px;\n  margin-left: 10px;\n  display: inline-flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "rightPanel-module___container___DPAA0",
	"_import_button_container": "rightPanel-module___import_button_container___HeWbN"
};
export default ___CSS_LOADER_EXPORT___;
