import { ActionComponents, ContactViewActionTypes } from '../types';
import { AssignToCompanyAction, ReassignToCompanyAction } from './assignToCompanyAction';
import { RemoveCompanyConfirmationModal } from './removeCompanyAction';

export * from './companySelector/companySelector';
export const ACTION_COMPONENTS: ActionComponents = {
  [ContactViewActionTypes.ASSIGN_COMPANY]: AssignToCompanyAction,
  [ContactViewActionTypes.REASSIGN_COMPANY]: ReassignToCompanyAction,
  [ContactViewActionTypes.REMOVE_COMPANY]: RemoveCompanyConfirmationModal,
};
