import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Spinner,
  useToasts,
  Text,
} from '@bloobirds-it/flamingo-ui';
import { useAccountId, useBobject } from '@bloobirds-it/hooks';
import { LEAD_FIELDS_LOGIC_ROLE } from '@bloobirds-it/types';

import styles from '../contactViewActions.module.css';
import { ActionComponentProps } from '../types';

export const RemoveCompanyConfirmationModal = ({
  activeBobject,
  closeAction,
  refreshActiveBobject,
}: ActionComponentProps) => {
  const { t } = useTranslation();
  const accountId = useAccountId();
  const { patchBobject } = useBobject('Lead', accountId);
  const [loading, setLoading] = useState<boolean>(false);

  const { createToast } = useToasts();

  const removeCompany = async () => {
    if (!activeBobject) return;
    setLoading(true);

    try {
      const data = {
        [LEAD_FIELDS_LOGIC_ROLE.COMPANY]: null,
      };

      await patchBobject(activeBobject.id.objectId, data);
      createToast({ type: 'success', message: t('removeCompanyModal.successToast') });
      refreshActiveBobject();
      closeAction();
    } catch (e) {
      createToast({ type: 'error', message: t('removeCompanyModal.errorToast') });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open onClose={closeAction} className={styles['reassign-modal']}>
      <ModalHeader color="veryLightBloobirds" size="small">
        <ModalTitle color="peanut" icon="deliver" size="small">
          {t('sidePeek.contactViewActions.removeCompany')}
        </ModalTitle>
        <ModalCloseIcon color="bloobirds" size="small" onClick={closeAction} />
      </ModalHeader>

      <ModalContent>
        <div>
          <Text size="m">{t('removeCompanyModal.message.intro')}</Text>
          {t('removeCompanyModal.message.effects', { returnObjects: true })['map'](
            (effect: string, index: number) => (
              <li key={index}>
                <Trans i18nKey={`removeCompanyModal.message.effects.${index}`}>{effect}</Trans>
              </li>
            ),
          )}
        </div>
      </ModalContent>

      <ModalFooter>
        <Button onClick={closeAction} variant="clear">
          {t('common.cancel')}
        </Button>
        <Button color="tomato" onClick={removeCompany}>
          {!loading ? t('common.unassign') : <Spinner color="white" name="loadingCircle" />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
