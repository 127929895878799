import React, { useState } from 'react';
import { UseFormGetValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Icon, IconButton, Spinner, Text } from '@bloobirds-it/flamingo-ui';
import { useGetI18nSpacetime } from '@bloobirds-it/internationalization';
import { ChangeTimezoneModal } from '@bloobirds-it/misc-modals';
import { Bobject } from '@bloobirds-it/types';
import { getUserTimeZone } from '@bloobirds-it/utils';
import spacetime from 'spacetime';

import { BloobirdsCalendarsSelector } from '../../components/bloobirdsCalendarsSelector/bloobirdsCalendarsSelector';
import { CalendarsSelector } from '../../components/calendarsSelector/calendarsSelector';
import { useCalendar } from '../../hooks/useCalendar';
import { useMeetingsModalContext } from '../context';
import styles from '../meetingModal.module.css';

type Props = {
  minimize: (data: Record<string, any>) => void;
  handleClose: () => void;
  bobject: Bobject;
};
export const MeetingModalHeader = ({ minimize, bobject, handleClose }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meetingModal' });
  const [changeTimezoneModalVisible, setChangeTimezoneModalVisible] = useState<boolean>(false);
  const { connections } = useMeetingsModalContext();
  const { getValues } = useFormContext();

  const {
    selectedTimezone,
    setEventTypesSelected,
    setDate,
    setSelectedTimezone,
    loading,
    date,
    eventsTypeSelected,
  } = useCalendar();

  return (
    <div className={styles._header__container}>
      <div className={styles._header__date_picker}>
        <Text size="l" weight="regular">
          {useGetI18nSpacetime(date, getUserTimeZone()).format('{month} {year}')}
        </Text>
        <IconButton
          name="chevronLeft"
          onClick={() => setDate(date => spacetime(date).subtract(1, 'week').toNativeDate())}
          size={16}
        />
        <IconButton
          name="chevronRight"
          onClick={() => setDate(date => spacetime(date).add(1, 'week').toNativeDate())}
          size={16}
        />
        <Button variant="secondary" size="small" onClick={() => setDate(new Date())}>
          {t('today')}
        </Button>
      </div>
      <div className={styles._header__spacer} />
      <div className={styles._header_right_actions}>
        {loading && <Spinner name="loadingCircle" size={16} />}
        <Text size="xs" color="softPeanut">
          {t('timezone')}: {selectedTimezone}{' '}
          <span
            className={styles._timezone_selector}
            onClick={() => setChangeTimezoneModalVisible(true)}
          >
            {t('change')}
          </span>
        </Text>
        {changeTimezoneModalVisible && (
          <ChangeTimezoneModal
            onChange={value => {
              setSelectedTimezone(value);
              setChangeTimezoneModalVisible(false);
            }}
            onClose={() => setChangeTimezoneModalVisible(false)}
            defaultTimezone={selectedTimezone}
          />
        )}
        <div className={styles._event_type_selector}>
          <div
            className={styles._event_type}
            style={{
              backgroundColor:
                eventsTypeSelected === 'bloobirds' ? 'var(--bloobirds)' : 'var(--white)',
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
            }}
            onClick={() => setEventTypesSelected('bloobirds')}
          >
            <Text size="xs" color={eventsTypeSelected === 'bloobirds' ? 'white' : 'bloobirds'}>
              Bloobirds
            </Text>
          </div>
          <div
            className={styles._event_type}
            style={{
              backgroundColor: eventsTypeSelected === 'nylas' ? 'var(--bloobirds)' : 'var(--white)',
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px',
            }}
            onClick={() => setEventTypesSelected('nylas')}
          >
            <Icon
              name="calendar"
              size={12}
              color={eventsTypeSelected === 'nylas' ? 'white' : 'bloobirds'}
            />
            <Text size="xs" color={eventsTypeSelected === 'nylas' ? 'white' : 'bloobirds'}>
              {t('calendarName')}
            </Text>
          </div>
        </div>
        <div className={styles._calendar_select}>
          {eventsTypeSelected === 'nylas' ? (
            <CalendarsSelector
              connections={connections}
              disabled={eventsTypeSelected === 'nylas' && connections?.list?.length === 0}
            />
          ) : (
            <BloobirdsCalendarsSelector />
          )}
        </div>
        <IconButton
          name="minus"
          size={24}
          onClick={() =>
            minimize({
              data: getValues(),
              title: getValues()?.title || t('untitledEvent'),
              bobject,
            })
          }
        />
        <IconButton name="cross" size={24} onClick={handleClose} />
      </div>
    </div>
  );
};
