import { useState } from 'react';

import { CopilotInsight, InsightCategory } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import useSWR from 'swr';

import { CopilotInsightSettings, InsightType, State } from '../types';

export type FilterType = {
  state: State | '';
  insightType: InsightType | '';
  activityType: string;
  activitySubtype: string;
  insightCategory: InsightCategory | '';
};

export const useInsights = () => {
  const [filter, setFilter] = useState<FilterType>({
    state: '',
    insightType: '',
    activityType: '',
    activitySubtype: '',
    insightCategory: '',
  });

  const { data, mutate, isLoading } = useSWR('api/insights', async () => {
    return await api.get<CopilotInsight[]>(`/utils/service/copilot-insights`).then(payload => {
      return (payload?.data || []).map(ins => new CopilotInsight(ins));
    });
  });
  const { data: settings } = useSWR<CopilotInsightSettings>('api/insights/settings', async () => {
    return await api
      .get<CopilotInsightSettings>(`/utils/service/copilot-insights/settings`)
      .then(({ data }) => data || ({} as CopilotInsightSettings));
  });

  const filterInsights = (data: CopilotInsight[], filter: FilterType) => {
    if (!data) {
      return [];
    }
    if (filter.state) {
      data = data.filter(insight => insight.state === filter.state);
    }

    if (filter.insightType) {
      data = data.filter(insight => insight.insightType === filter.insightType);
    }

    if (filter.activityType) {
      data = data.filter(insight => insight.activityType === filter.activityType);
    }

    if (filter.activitySubtype) {
      data = data.filter(insight => insight.activitySubtype === filter.activitySubtype);
    }

    if (filter.insightCategory) {
      data = data.filter(insight => insight.category === filter.insightCategory);
    }

    // order by createdAt desc
    data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    return data;
  };

  const insightsMap = new Map<string, CopilotInsight>();
  const filteredInsights = filterInsights(data, filter) || [];

  filteredInsights.forEach(insight => {
    insightsMap.set(insight.id, insight);
  });

  return {
    isLoading,
    insights: insightsMap,
    filter,
    setFilter,
    mutate,
    nActive: settings?.insightsActive || 0,
    nCreated: settings?.insightsCreated || 0,
    nActiveTotal: settings?.maxInsightsActive || 15, //15 is default value
    nCreatedTotal: settings?.maxInsightsCreated,
  };
};
