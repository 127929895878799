// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hoverTimeIndicator-module__indicator___xXNol {\n  position: absolute;\n  width: 2px;\n  height: 100%;\n  background: #464f57;\n  pointer-events: none;\n  opacity: 0;\n  transition: opacity 0.2s;\n  top: 0;\n}\n\n.hoverTimeIndicator-module__indicatorInfo___oFGCk {\n  position: absolute;\n  top: -5px;\n  left: -16px;\n  background: #464f57;\n  pointer-events: none;\n  padding: 2px 4px;\n  border-radius: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/aiAnalysisPages/leftSideAiAnalysis/mediaAiAnalysis/timelineArea/hoverTimeIndicator/hoverTimeIndicator.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,oBAAoB;EACpB,UAAU;EACV,wBAAwB;EACxB,MAAM;AACR;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,mBAAmB;EACnB,oBAAoB;EACpB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".indicator {\n  position: absolute;\n  width: 2px;\n  height: 100%;\n  background: #464f57;\n  pointer-events: none;\n  opacity: 0;\n  transition: opacity 0.2s;\n  top: 0;\n}\n\n.indicatorInfo {\n  position: absolute;\n  top: -5px;\n  left: -16px;\n  background: #464f57;\n  pointer-events: none;\n  padding: 2px 4px;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"indicator": "hoverTimeIndicator-module__indicator___xXNol",
	"indicatorInfo": "hoverTimeIndicator-module__indicatorInfo___oFGCk"
};
export default ___CSS_LOADER_EXPORT___;
