// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loginPage-module___container___QtWxn {\n  height: 100%;\n  width: 100%;\n  background-color: var(--white);\n  display: flex;\n  flex-wrap: wrap;\n  box-sizing: border-box;\n}\n\n.loginPage-module___form_wrapper___7GAIx {\n  align-items: center;\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  margin: 0;\n  box-sizing: border-box;\n  flex-grow: 0;\n  max-width: 50%;\n  flex-basis: 50%;\n}\n\n.loginPage-module___aside_wrapper___6-PKi {\n  flex-grow: 0;\n  max-width: 50%;\n  flex-basis: 50%;\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/loginPage/loginPage.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,8BAA8B;EAC9B,aAAa;EACb,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,eAAe;EACf,SAAS;EACT,sBAAsB;EACtB,YAAY;EACZ,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,eAAe;EACf,SAAS;AACX","sourcesContent":["._container {\n  height: 100%;\n  width: 100%;\n  background-color: var(--white);\n  display: flex;\n  flex-wrap: wrap;\n  box-sizing: border-box;\n}\n\n._form_wrapper {\n  align-items: center;\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  margin: 0;\n  box-sizing: border-box;\n  flex-grow: 0;\n  max-width: 50%;\n  flex-basis: 50%;\n}\n\n._aside_wrapper {\n  flex-grow: 0;\n  max-width: 50%;\n  flex-basis: 50%;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "loginPage-module___container___QtWxn",
	"_form_wrapper": "loginPage-module___form_wrapper___7GAIx",
	"_aside_wrapper": "loginPage-module___aside_wrapper___6-PKi"
};
export default ___CSS_LOADER_EXPORT___;
