import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@bloobirds-it/flamingo-ui';

import { Step } from '../../constants/phoneNumbers.constants';
import { usePaginatedPhoneEntity } from '../../hooks/usePaginatedPhoneEntity';
import styles from '../../styles/phoneNumberPage.module.css';
import { Steps } from '../purchaseNumber/steps';

export const PurchaseNumberHeader = ({
  stepList,
  currentStep,
}: {
  stepList: Step[];
  currentStep: number;
}) => {
  const { setShowPurchasePhoneNumber } = usePaginatedPhoneEntity();
  const { t } = useTranslation();

  return (
    <div className={styles.purchaseNumberHeader}>
      <Button
        color="bloobirds"
        uppercase={false}
        iconLeft="arrowLeft"
        onClick={() => setShowPurchasePhoneNumber(false)}
        variant="clear"
      >
        {t('accountSettings.phoneNumbers.phoneSettings.backButton')}
      </Button>
      <Steps steps={stepList} currentStep={currentStep} />
    </div>
  );
};
