import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { createToast } from '@bloobirds-it/flamingo-ui';
import {
  ACTIVITY_DIRECTION,
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_TYPES,
  Bobject,
  BobjectId,
  BobjectTypes,
  LEAD_FIELDS_LOGIC_ROLE,
  SessionStorageKeys,
  TASK_FIELDS_LOGIC_ROLE,
} from '@bloobirds-it/types';
import { api, getValueFromLogicRole, parseEmailPixels } from '@bloobirds-it/utils';

import { useSessionStorage } from './useSessionStorage';
import { useUserPhoneNumbers } from './useUserPhoneNumbers';
import { useUserSettings } from './useUserSettings';

export const useActivities = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'activityTimelineItem.toasts.created' });
  const settings = useUserSettings();
  const hasSettingActive = settings?.account?.createActivitiesWhenCompletingCallTasks;
  const { get } = useSessionStorage();
  const lastUsedPhone = get(SessionStorageKeys.LastPhoneUsed);
  const { userPhones } = useUserPhoneNumbers();

  const getMatchingPrefixPhone = (leadPhone: string) => {
    if (!leadPhone) return;
    return userPhones?.find(
      ({ phoneNumber }) => phoneNumber?.substring(0, 3) === leadPhone?.substring(0, 3),
    );
  };

  const logActivityFromTask = ({
    taskId,
    callback,
  }: {
    taskId: BobjectId<BobjectTypes.Task>['value'];
    callback?: (activity: Bobject, mainBobject: Bobject) => void;
  }) => {
    if (!hasSettingActive) return;

    api.get('/bobjects/' + taskId + '/form?injectReferences=true').then(response => {
      const task = response.data;
      const accountId = task?.id?.accountId;
      const opportunityId = getValueFromLogicRole(task, TASK_FIELDS_LOGIC_ROLE.OPPORTUNITY);
      const leadId = getValueFromLogicRole(task, TASK_FIELDS_LOGIC_ROLE.LEAD);
      const companyId = getValueFromLogicRole(task, TASK_FIELDS_LOGIC_ROLE.COMPANY);
      const opportunity = task?.referencedBobjects?.[opportunityId];
      const lead = task?.referencedBobjects?.[leadId];
      const company = task?.referencedBobjects?.[companyId];
      const leadPhoneNumber = getValueFromLogicRole(lead, LEAD_FIELDS_LOGIC_ROLE.PHONE);
      const userPhone =
        lastUsedPhone ??
        getMatchingPrefixPhone(leadPhoneNumber)?.phoneNumber ??
        userPhones[0]?.phoneNumber ??
        null;

      api
        .post(`/bobjects/${accountId}/Activity`, {
          contents: {
            [ACTIVITY_FIELDS_LOGIC_ROLE.TYPE]: ACTIVITY_TYPES.CALL,
            [ACTIVITY_FIELDS_LOGIC_ROLE.DIRECTION]: ACTIVITY_DIRECTION.OUTGOING,
            [ACTIVITY_FIELDS_LOGIC_ROLE.DATETIME]: new Date(),
            [ACTIVITY_FIELDS_LOGIC_ROLE.USER]: getValueFromLogicRole(
              task,
              TASK_FIELDS_LOGIC_ROLE.ASSIGNED_TO,
            ),
            [ACTIVITY_FIELDS_LOGIC_ROLE.OPPORTUNITY]: getValueFromLogicRole(
              task,
              TASK_FIELDS_LOGIC_ROLE.OPPORTUNITY,
            ),
            [ACTIVITY_FIELDS_LOGIC_ROLE.LEAD]: getValueFromLogicRole(
              task,
              TASK_FIELDS_LOGIC_ROLE.LEAD,
            ),
            [ACTIVITY_FIELDS_LOGIC_ROLE.COMPANY]: getValueFromLogicRole(
              task,
              TASK_FIELDS_LOGIC_ROLE.COMPANY,
            ),
            [ACTIVITY_FIELDS_LOGIC_ROLE.CALL_LEAD_PHONE_NUMBER]: leadPhoneNumber,
            [ACTIVITY_FIELDS_LOGIC_ROLE.CALL_USER_PHONE_NUMBER]: userPhone,
          },
          params: {},
        })
        .then(({ data }) => {
          api
            .get('/bobjects/' + data?.value + '/form?injectReferences=true')
            .then(({ data: activity }) => {
              callback?.(activity, opportunity ?? lead ?? company);
            });
          createToast({ type: 'success', message: t('success') });
        })
        .catch(() => {
          createToast({ type: 'error', message: t('error') });
        });
    });
  };

  return { logActivityFromTask };
};

export const useEmailActivities = () => {
  const fetchBody = useCallback(async activityId => {
    const response = await api.get(`/utils/service/activity/${activityId}/body`);
    return response.data;
  }, []);

  const fillEmailActivityWithBody = async (activity: Bobject) => {
    const body = await fetchBody(activity?.id?.objectId);
    // Create a copy of the fields array
    const fieldsCopy = [...activity.fields];
    // Remove the tracking pixels from the email body
    const bodyWithoutPixels = parseEmailPixels(body);

    // Find the field to be updated in the copied fields array
    const fieldIndex = fieldsCopy.findIndex(
      fieldItem => fieldItem.logicRole === ACTIVITY_FIELDS_LOGIC_ROLE.MESSAGE_BODY,
    );

    if (fieldIndex !== -1) {
      // Create a new field object with the updated properties
      const updatedField = {
        ...fieldsCopy[fieldIndex],
        text: bodyWithoutPixels,
        value: bodyWithoutPixels,
      };

      // Replace the old field object with the new one in the copied fields array
      fieldsCopy[fieldIndex] = updatedField;
    }

    // Create a new bobject with the updated fields array
    const activityCopy = { ...activity, fields: fieldsCopy };

    return activityCopy;
  };

  return { fetchBody, fillEmailActivityWithBody };
};
