import React from 'react';

import { ACTIVITY_TYPES_VALUES_LOGIC_ROLE, Bobject } from '@bloobirds-it/types';

import CallAnalysis from './callAiAnalysis';
import MeetingAnalysisSkeleton from './infoAiSkeleton';
import MeetingAnalysis from './meetingAiAnalysis';
import { EmailAnalysis } from './nonTranscript/emailAiAnalysis';
import { LinkedinAiAnalysis } from './nonTranscript/linkedinAiAnalysis';

interface InfoAnalysisProps {
  activity: Bobject;
  isLoading: boolean;
  activityType: ACTIVITY_TYPES_VALUES_LOGIC_ROLE;
}

const InfoAiAnalysis = ({ activity, isLoading, activityType }: InfoAnalysisProps) => {
  if (isLoading || !activity) {
    return <MeetingAnalysisSkeleton />;
  }

  switch (activityType) {
    case ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING:
      return <MeetingAnalysis activity={activity} />;
    case ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL:
      return <CallAnalysis activity={activity} />;
    case ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL:
      return <EmailAnalysis activity={activity} />;
    case ACTIVITY_TYPES_VALUES_LOGIC_ROLE.LINKEDIN:
      return <LinkedinAiAnalysis activity={activity} />;
    default:
      return null;
  }
};

export default InfoAiAnalysis;
