import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useToasts } from '@bloobirds-it/flamingo-ui';
import { UserHelperKeys } from '@bloobirds-it/types';
import { api, fetchLanguages } from '@bloobirds-it/utils';

import { useActiveUserId } from './useActiveUser';
import { useUserHelpers } from './useUserHelpers';

export const useLanguage = () => {
  const userId = useActiveUserId();
  const { t } = useTranslation();
  const { createToast } = useToasts();
  const [languages, setLanguages] = useState<string[]>([]);
  const { has, save } = useUserHelpers();

  useEffect(() => {
    fetchLanguages().then(languages => {
      setLanguages(languages);
    });
  }, []);

  const updateLanguage = language => {
    api
      .patch(`/utils/service/users/${userId}/language/${language}`, {})
      .then(() => {
        if (!has(UserHelperKeys.CHOOSE_LANGUAGE)) {
          save(UserHelperKeys.CHOOSE_LANGUAGE);
        }
        createToast({
          message: t('leftBar.successChangeLng', {
            language: t(`languages.${language}`),
          }),
          type: 'success',
        });
      })
      .catch(() => {
        createToast({
          message: t('leftBar.errorChangeLng', {
            language: t(`languages.${language}`),
          }),
          type: 'error',
        });
      });
  };

  return {
    languages,
    updateLanguage,
  };
};
