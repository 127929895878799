// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inputFeedback-module__item___UG1E1 {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-top: 16px;\n}\n\n.inputFeedback-module__list___PTSfl {\n  margin: 16px auto 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/inputFeedback/inputFeedback.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".item {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-top: 16px;\n}\n\n.list {\n  margin: 16px auto 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "inputFeedback-module__item___UG1E1",
	"list": "inputFeedback-module__list___PTSfl"
};
export default ___CSS_LOADER_EXPORT___;
