// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".selectType-module___text___YgJMJ {\n  color: var(--softPeanut);\n}\n\n.selectType-module___title___g8F5z {\n  color: var(--peanut);\n}\n\n.selectType-module___root___ZLb1r {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n}\n\n.selectType-module___wrapper___bmsVy {\n  max-width: 33.333333%;\n}\n\n.selectType-module___inputs___1-Rzt {\n  width: 400px;\n}\n\n.selectType-module___textContainer___DGe4z {\n  margin-top: 20px;\n  margin-bottom: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/importForm/components/selectType/selectType.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["._text {\n  color: var(--softPeanut);\n}\n\n._title {\n  color: var(--peanut);\n}\n\n._root {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n}\n\n._wrapper {\n  max-width: 33.333333%;\n}\n\n._inputs {\n  width: 400px;\n}\n\n._textContainer {\n  margin-top: 20px;\n  margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_text": "selectType-module___text___YgJMJ",
	"_title": "selectType-module___title___g8F5z",
	"_root": "selectType-module___root___ZLb1r",
	"_wrapper": "selectType-module___wrapper___bmsVy",
	"_inputs": "selectType-module___inputs___1-Rzt",
	"_textContainer": "selectType-module___textContainer___DGe4z"
};
export default ___CSS_LOADER_EXPORT___;
