// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfirmResyncModal-module___content___fiyrx {\n  margin-bottom: 32px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.ConfirmResyncModal-module___content___fiyrx > p:first-child {\n  margin-bottom: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/confirmResyncModal/ConfirmResyncModal.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["._content {\n  margin-bottom: 32px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n._content > p:first-child {\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_content": "ConfirmResyncModal-module___content___fiyrx"
};
export default ___CSS_LOADER_EXPORT___;
