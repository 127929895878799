import { useEffect, useState } from 'react';

import { Icon } from '@bloobirds-it/flamingo-ui';
import clsx from 'clsx';
import { useDebounce } from 'use-debounce';

import styles from '../whatsappDetail.module.css';

export function WhatsappFileDropzone({ onFilesAdded }) {
  const [dragging, setDragging] = useState(false);

  const [delayedDragging] = useDebounce(dragging, 200);

  const handleDrop = e => {
    e.preventDefault();
    setDragging(false);
    onFilesAdded(e.dataTransfer.files);
  };

  useEffect(() => {
    const handleDrag = e => {
      e.preventDefault();
      setDragging(true);
    };

    const handleDragLeave = e => {
      e.preventDefault();
      setDragging(false);
    };

    window.addEventListener('dragover', handleDrag, false);
    window.addEventListener('dragleave', handleDragLeave, false);
    window.addEventListener('drop', handleDrop, false);

    return () => {
      window.removeEventListener('dragover', handleDrag);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('drop', handleDrop);
    };
  }, [onFilesAdded]);

  if (!delayedDragging) {
    return null;
  }

  return (
    <div
      className={clsx(styles.dropzone, { [styles.dropzoneActive]: delayedDragging })}
      onDrop={handleDrop}
    >
      <Icon name="upload" size={24} color="melon" />
      Drop your files here
    </div>
  );
}
