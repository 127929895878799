import React, { useEffect, useState } from 'react';

import { MultiSelect, CheckItem } from '@bloobirds-it/flamingo-ui';
import { api } from '@bloobirds-it/utils';
import { mutate } from 'swr';

const ExpectedValue = ({ activityResults, activityId, insightId, picklistField, testSetId }) => {
  const [values, setValues] = useState(() => {
    if (activityResults?.expected_result?.result) {
      return activityResults?.expected_result?.result;
    }
    return [];
  });

  useEffect(() => {
    if (activityResults?.expected_result?.result) {
      setValues(activityResults?.expected_result?.result);
    } else {
      setValues([]);
    }
  }, [activityResults]);
  const handleValueChange = value => {
    const currentValue = values;
    api
      .post(`copilot/playground/test-set/${testSetId}/expected-result`, {
        activityId: activityId,
        result: value,
        insightId: insightId,
      })
      .then(() => {
        setValues(value);
        mutate(`/api/test-runs/${testSetId}`);
      })
      .catch(() => {
        setValues(currentValue);
      });
  };

  return (
    <MultiSelect size="small" value={values} onChange={handleValueChange}>
      {picklistField?.values.map(value => (
        <CheckItem key={value.id} value={value.id}>
          {value.value}
        </CheckItem>
      ))}
    </MultiSelect>
  );
};

export default ExpectedValue;
