import { useController } from 'react-hook-form';

import { FormGroup, FormLabel } from '../../formGroup/formGroup';
import { FormFieldProps, Input } from '../baseInput/baseInput';

export const TextField = ({
  control,
  required,
  name,
  id,
  size = 'small',
  requiredMessage,
  hideLabel,
  formKey,
  withFormGroup = true,
  placeholder,
}: FormFieldProps) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name: formKey ?? `fields.${id}`,
    rules: {
      required: {
        value: required,
        message: requiredMessage,
      },
    },
  });

  const simpleInput = (
    <>
      {size === 'small' && !hideLabel && <FormLabel required={required}>{name}</FormLabel>}
      <Input
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error?.message}
        size={size}
        placeholder={
          placeholder || (size === 'small' ? undefined : `${name}${required ? ' *' : ''}`)
        }
      />
    </>
  );

  return withFormGroup ? <FormGroup size={size}>{simpleInput}</FormGroup> : simpleInput;
};
