import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  Callout,
  Checkbox,
  Modal,
  ModalContent,
  ModalFooter,
  Text,
} from '@bloobirds-it/flamingo-ui';

import styles from './disconnectModal.module.css';

const DisconnectModal = ({ connection, handleClose, handleConfirm, open, type }) => {
  const [isAccept, setIsAccept] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'userSettingsWebapp.disconnectModal' });
  const isEmail = type === 'email';
  const nameType = isEmail ? t('email') : t('phoneNumber');
  return (
    <Modal title={t('title', { nameType })} open={open} onClose={handleClose}>
      <ModalContent>
        <div className={styles._container}>
          <Text color="peanut" size="m" weight="medium">
            {t('content', { connection })}
          </Text>
          <div className={styles._checkbox_wrapper}>
            <Checkbox expand defaultChecked={isAccept} onClick={setIsAccept}>
              {t('checkbox', { connection, nameType })}
            </Checkbox>
          </div>

          {type === 'phone' && (
            <Callout variant="alert" width="100%">
              <span role="img" aria-label="hand right" className={styles._emoji__container}>
                👉
              </span>
              <Trans i18nKey="userSettingsWebapp.disconnectModal.callout" />
            </Callout>
          )}
        </div>
      </ModalContent>
      <ModalFooter>
        <Button variant="clear" onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button onClick={handleConfirm} disabled={!isAccept}>
          {t('confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DisconnectModal;
