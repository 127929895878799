import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@bloobirds-it/flamingo-ui';
import { useUserHelpers } from '@bloobirds-it/hooks';
import { Connections, UserHelperKeys } from '@bloobirds-it/types';
import clsx from 'clsx';

import { fetchAndOpenNylasUrl } from '../../../../../pages/userSettingsPages/emailSettings/emailSettings.services';
import { GoogleSignIn, MicrosoftSignIn } from '../../../../BrandedButtons';
import ConnectionCard from '../../../../connectionCard';
import { useUserSettings } from '../../../../userPermissions/hooks';
import styles from '../../slides/slides.module.css';

export const EmailDisplay = ({ connections }: { connections: Connections }) => {
  const { settings } = useUserSettings();
  const { save } = useUserHelpers();
  const { t } = useTranslation();

  useEffect(() => {
    if (connections?.list?.length > 0) {
      save(UserHelperKeys.CONNECT_EMAIL);
    }
  }, [connections?.list?.length]);

  const hasConnections = connections?.list && connections.list.length > 0;
  return hasConnections ? (
    <div className={styles.connectionsContainer}>
      <div
        className={clsx(styles._email_buttons__wrapper, {
          [styles._email_buttons__disconnected]: !hasConnections,
        })}
      >
        <div style={{ paddingTop: '20px' }} className={styles.row}>
          {settings?.gmailConnectButtonEnabled && (
            <GoogleSignIn
              onClick={() =>
                fetchAndOpenNylasUrl({
                  bbPage: window.location.pathname + '?welcomeScreen=',
                  target: '_self',
                })
              }
            />
          )}
          {settings?.microsoftConnectButtonEnabled && (
            <MicrosoftSignIn
              onClick={() =>
                fetchAndOpenNylasUrl({
                  bbPage: window.location.pathname + '?welcomeScreen=',
                  target: '_self',
                })
              }
            />
          )}
        </div>
        <div style={{ paddingTop: '24px' }}>
          {hasConnections ? (
            <Text size="s" weight="medium" color="softPeanut" htmlTag="span">
              {t('userSetings.email.connections.title')}
            </Text>
          ) : (
            <Text size="s" weight="bold" color="softPeanut" htmlTag="span">
              {t('userSetings.email.connections.empty')}
            </Text>
          )}
        </div>
      </div>
      {connections.list?.map(connection => (
        <ConnectionCard
          data={connection}
          isNylas
          key={connection.email}
          isDefault={connection.email === connections.defaultConnection}
          isQSG
        />
      ))}
    </div>
  ) : (
    <div className={styles.row}>
      {settings?.gmailConnectButtonEnabled && (
        <GoogleSignIn
          onClick={() =>
            fetchAndOpenNylasUrl({
              bbPage: window.location.pathname + '?welcomeScreen=',
              target: '_self',
              provider: 'gmail',
            })
          }
        />
      )}
      {settings?.microsoftConnectButtonEnabled && (
        <MicrosoftSignIn
          onClick={() =>
            fetchAndOpenNylasUrl({
              bbPage: window.location.pathname + '?welcomeScreen=',
              target: '_self',
              provider: 'outlook',
            })
          }
        />
      )}
    </div>
  );
};
