import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCopilotActivity } from '@bloobirds-it/copilot';
import { Button, Icon, IconButton, Label, Spinner, Text, Tooltip } from '@bloobirds-it/flamingo-ui';
import {
  useAiAnalysisEnabled,
  useCopilotEnabled,
  useTogglerState,
  useUserSettings,
} from '@bloobirds-it/hooks';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  MIXPANEL_EVENTS,
} from '@bloobirds-it/types';
import { baseUrls, getFieldByLogicRole, getTextFromLogicRole } from '@bloobirds-it/utils';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import mixpanel from 'mixpanel-browser';

import { ShowTranscriptButton } from '../transcript/transcript';
import styles from './copilotSuggestionsCallout.module.css';

interface CalloutButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  generating?: boolean;
  error?: boolean;
  isBubble?: boolean;
}

const ShowInsightsButton = ({
  onClick,
  disabled,
  generating,
  error,

  isBubble,
}: CalloutButtonProps) => {
  const { t } = useTranslation();
  return (
    <div
      style={{ cursor: !error && !generating ? 'pointer' : 'not-allowed' }}
      className={clsx(styles.calloutButton, {
        [styles.disabled]: disabled,
        [styles.error]: error,
        [styles.smallButton]: isBubble,
      })}
      onClick={() => !disabled && !generating && !error && onClick()}
    >
      {!error && generating && <Spinner name="loadingCircle" color="purple" size={24} />}
      {!error && !generating && (
        <IconButton name="insights" size={24} color={disabled ? 'verySoftPeanut' : 'purple'} />
      )}
      {error ? (
        <>
          <Icon name="cross" size={24} color="verySoftTomato" />
          <Text size="xxs" color="extraMeeting">
            {t('common.failed')}
          </Text>
        </>
      ) : (
        <Text
          className={styles.calloutButtonText}
          size={isBubble ? 'xxs' : 'xs'}
          color={disabled ? 'verySoftPeanut' : 'peanut'}
          weight="medium"
        >
          {t('activityTimelineItem.item.copilotCallout.insights')}
        </Text>
      )}
    </div>
  );
};

export const CopilotSuggestionsCallout = ({
  isBubble,
  regenerateAvailable = true,
}: {
  isBubble: boolean;
  regenerateAvailable: boolean;
}) => {
  const settings = useUserSettings();
  const {
    setOverlay,
    activityInsights,
    generateAnalysis,
    activity,
    isTranscriptActivity,
  } = useCopilotActivity();
  const { t } = useTranslation();
  const [isHovering, setIsHovering] = useState(false);
  const accountId = settings?.account?.id;
  const aiAnalysisEnabled = useAiAnalysisEnabled(accountId);
  const isCopilotEnabled = useCopilotEnabled(accountId);
  const [expandSummary, toggleExpandSummary] = useTogglerState(!!activityInsights?.summary);
  const [showAllSummary, toggleShowAllSummary] = useTogglerState(false);
  const navigateToAiAnalysisPage = () => {
    const activityId = activity.id.objectId;
    const activityTypeLogicRole = getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE)
      ?.valueLogicRole;

    let activityType = '';
    if (activityTypeLogicRole === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING) {
      activityType = 'meeting';
    } else if (activityTypeLogicRole === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL) {
      activityType = 'call';
    }

    const url = `/app/ai-analysis/${activityType}/${activityId}`;
    window.open(baseUrls[process.env.NODE_ENV] + url, '_blank');
  };

  const copilotAnalysis = getTextFromLogicRole(
    activity,
    ACTIVITY_FIELDS_LOGIC_ROLE.COPILOT_ANALYSIS,
  );
  const canShowAnalysis = copilotAnalysis && aiAnalysisEnabled && isTranscriptActivity; //TODO: temporary until aiAnalysis is made

  if (!isCopilotEnabled) return null;

  return (
    <motion.div
      initial={{ height: '42px', overflow: 'hidden' }}
      animate={{
        height: expandSummary ? 'auto' : '42px',
      }}
      transition={{ duration: 0.3 }}
      className={styles.iaCallout}
    >
      <div className={styles.suggestedEntitiesHeader}>
        <div className={styles.suggestedEntitiesHeaderContainer}>
          <Text
            color="purple"
            size="m"
            weight="heavy"
            className={styles.suggestedEntitiesHeaderTitle}
          >
            <Icon name="stars" color="lightPurple" size={16} />
            {t('activityTimelineItem.item.copilotCallout.aiAnalysis')}
          </Text>
        </div>
        <div className={styles.suggestedEntitiesHeaderContainer}>
          {['GENERATING', 'NOT_GENERATED'].includes(activityInsights?.status) && (
            <Label uppercase={false} color="verySoftPurple" textColor="lightPurple">
              <div className={styles.copilotInsightsStatusLabel}>
                {activityInsights?.status === 'NOT_GENERATED' && (
                  <Icon name="insights" color="lightPurple" size={16} />
                )}
                {t(`ai.playground.activityTab.insightsStatus.${activityInsights?.status}`)}
              </div>
            </Label>
          )}
          <IconButton
            name={expandSummary ? 'minus' : 'chevronDown'}
            color="lightPurple"
            onClick={toggleExpandSummary}
          />
        </div>
      </div>
      {activityInsights?.status === 'NOT_GENERATED' && (
        <div className={styles.notGeneratedContainer}>
          <Text size="s">{t('ai.playground.activityTab.aiAnalysisCandidate')}</Text>
          <Button
            size="small"
            iconLeft="insights"
            color="softPurple"
            uppercase={false}
            onClick={generateAnalysis}
          >
            {t('activityTimelineItem.item.transcript.generate')}
          </Button>
        </div>
      )}
      {activityInsights?.status === 'GENERATING' && (
        <div className={styles.generatingContainer}>
          <Icon color="softPurple" name="insights" size={32} className={styles.insightsIcon} />
          <Text size="s">{t('ai.playground.activityTab.insightsGenerating')}</Text>
        </div>
      )}
      {activityInsights?.status === 'GENERATED' && (
        <>
          <motion.div
            initial={{ height: '3em', overflow: 'hidden' }} // Approximately 2 lines
            animate={{
              height: showAllSummary ? 'auto' : '3em',
              display: showAllSummary ? 'flex' : '-webkit-box',
            }}
            transition={{ duration: 0.3 }}
            className={styles.summaryContainer}
          >
            {activityInsights?.summary}
          </motion.div>
          {(activityInsights?.summary?.length || 0) > 95 && ( // 95 is approximately 2 rows of characters
            <Button
              className={styles.showMoreButton}
              size="small"
              color="softPurple"
              variant="clear"
              uppercase={false}
              onClick={toggleShowAllSummary}
            >
              {t(
                `activityTimelineItem.item.copilotInsights.${
                  showAllSummary ? 'showLess' : 'showMore'
                }`,
              )}
            </Button>
          )}
          <div className={styles.buttons}>
            <ShowInsightsButton
              onClick={() => {
                if (activityInsights.status === 'GENERATED') {
                  setOverlay('analysis');
                }
                mixpanel.track(MIXPANEL_EVENTS.CLICK_ON_OPEN_INSIGHTS);
              }}
              generating={!activityInsights}
              error={false}
              isBubble={isBubble}
            />
            {isTranscriptActivity && (
              <>
                <div className={styles.verticalDivider} />
                <ShowTranscriptButton activity={activity} isBubble={isBubble} />
              </>
            )}
            {canShowAnalysis && (
              <>
                <div className={styles.verticalDivider} />

                <Tooltip position="top" title={t('common.openInNewTab')}>
                  <IconButton
                    name="externalLink"
                    color="lightPurple"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigateToAiAnalysisPage();
                    }}
                  />
                </Tooltip>
              </>
            )}
          </div>
        </>
      )}
    </motion.div>
  );
};
