// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".addAliasModal-module__text___paZZd {\n  margin-top: 8px;\n  margin-bottom: 16px;\n}\n\n.addAliasModal-module__modalTitle___OFCRZ {\n  display: flex;\n  align-items: center;\n}\n\n.addAliasModal-module__modalTitle___OFCRZ > * {\n  margin: 0 8px;\n}\n\n.addAliasModal-module__link___EIj2C {\n  color: var(--bloobirds);\n  text-decoration: none;\n}\n\n.addAliasModal-module__link___EIj2C:hover {\n  text-decoration: underline;\n}\n\n.addAliasModal-module__footerRight___y9UTE {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 16px\n}\n\n.addAliasModal-module__modalContent___vJb5L {\n  padding: 16px !important;\n}\n\n.addAliasModal-module__modalContent___vJb5L > div {\n  gap: 16px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/addAliasModal/addAliasModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB;AACF;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".text {\n  margin-top: 8px;\n  margin-bottom: 16px;\n}\n\n.modalTitle {\n  display: flex;\n  align-items: center;\n}\n\n.modalTitle > * {\n  margin: 0 8px;\n}\n\n.link {\n  color: var(--bloobirds);\n  text-decoration: none;\n}\n\n.link:hover {\n  text-decoration: underline;\n}\n\n.footerRight {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 16px\n}\n\n.modalContent {\n  padding: 16px !important;\n}\n\n.modalContent > div {\n  gap: 16px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "addAliasModal-module__text___paZZd",
	"modalTitle": "addAliasModal-module__modalTitle___OFCRZ",
	"link": "addAliasModal-module__link___EIj2C",
	"footerRight": "addAliasModal-module__footerRight___y9UTE",
	"modalContent": "addAliasModal-module__modalContent___vJb5L"
};
export default ___CSS_LOADER_EXPORT___;
