export enum STEPS {
  INACTIVE_HANDLING = 'INACTIVE_HANDLING',
  INITIAL = 'INITIAL',
  MEETING_RESULT = 'MEETING_RESULT',
  NOTES = 'NOTES',
  CALL_RESULTS_OPP = 'CALL_RESULTS_OPP',
  CALL_RESULTS = 'CALL_RESULTS',
  CHANGE_STATUS = 'CHANGE_STATUS',
  CONVERT_OBJECT = 'CONVERT_OBJECT',
  NOTES_AND_QQ = 'NOTES_AND_QQ',
  SALES_CHANGE_STATUS = 'SALES_CHANGE_STATUS',
  SCHEDULE_NEXT_STEPS = 'SCHEDULE_NEXT_STEPS',
  OPPORTUNITY_CONTROL = 'OPPORTUNITY_CONTROL',
  CUSTOM_OBJECT = 'CUSTOM_OBJECT',
  ONLY_QQS = 'ONLY_QQS',
  CHANGE_STATUS_SALESFORCE = 'CHANGE_STATUS_SALESFORCE',
  OPPORTUNITY_CONTROL_OTO = 'OPPORTUNITY_CONTROL_OTO',
  BOBJECT_FORM = 'BOBJECT_FORM',
  ORIGINAL_CHANGE_STATUS_COMPONENT = 'ORIGINAL_CHANGE_STATUS_COMPONENT',
  CADENCE_CONTROL = 'CADENCE_CONTROL',
  CUSTOM_ACTIONS_SALESFORCE = 'CUSTOM_ACTIONS_SALESFORCE',
  CRM_UPDATES = 'CRM_UPDATES',
  ADD_LEAD_TO_ACTIVITY = 'ADD_LEAD_TO_ACTIVITY',
  STATUS_NOTE_ACTIONS = 'STATUS_NOTE_ACTIONS',
  TASK_MANAGEMENT = 'TASK_MANAGEMENT',
}

export enum EVENTS {
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS',
  SKIP = 'SKIP',
  FINISH = 'FINISH',
  DETAIL = 'DETAIL',
}

export type StepsKey = keyof typeof STEPS;
