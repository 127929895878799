// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".resetButton-module___reset_button_container___nofIC {\n  margin-right: 12px;\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectTable/actionsPanel/resetButton/resetButton.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf","sourcesContent":["._reset_button_container {\n  margin-right: 12px;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_reset_button_container": "resetButton-module___reset_button_container___nofIC"
};
export default ___CSS_LOADER_EXPORT___;
