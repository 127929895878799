import React, { createContext, useContext, ReactNode } from 'react';

import { PhoneNumber } from '../constants/phoneNumbers.constants';
import { usePhoneNumberForm } from '../hooks/usePhoneNumberForm';

type PhoneNumberFormContextType = ReturnType<typeof usePhoneNumberForm>;

const PhoneNumberFormContext = createContext<PhoneNumberFormContextType | undefined>(undefined);

export const PhoneNumberFormProvider = ({
  children,
  phone,
}: {
  children: ReactNode;
  phone: PhoneNumber;
}) => {
  const phoneNumberForm = usePhoneNumberForm(phone);
  return (
    <PhoneNumberFormContext.Provider value={phoneNumberForm}>
      {children}
    </PhoneNumberFormContext.Provider>
  );
};

export const usePhoneNumberFormContext = () => {
  const context = useContext(PhoneNumberFormContext);
  if (!context) {
    throw new Error('usePhoneNumberFormContext must be used within a PhoneNumberFormProvider');
  }
  return context;
};
