export enum LocalStorageKeys {
  TaskTypeSubQuery = 'taskTypeSubQuery',
  IsMenuOpen = 'isMenuOpen',
  LastVisitedBobjects = 'lastVisitedBobjects',
  LastColors = 'lastColors',
}

export enum SessionStorageKeys {
  LastPhoneUsed = 'lastPhoneUsed',
  ActiveTab = 'activeTab',
  ActiveSubTab = 'activeSubTab',
  OpenStartTasksNavigation = 'openStartTasksNavigation',
  IndexSelectedTask = 'indexSelectedTask',
  IsSettings = 'isSettings',
  NoteInfo = 'noteInfo',
  TaskCompleted = 'taskCompleted',
  BarStatus = 'barStatus',
  CurrentTab = 'currentTab',
}
