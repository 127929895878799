import { useTranslation } from 'react-i18next';

import { useBaseSetEmailVariablesValues, useMinimizableModals } from '@bloobirds-it/hooks';
import {
  BobjectTypes,
  EditableTemplateType,
  MessagingTemplate,
  templateTypes,
} from '@bloobirds-it/types';
import { EMAIL_MODE } from '@bloobirds-it/utils';
import mixpanel from 'mixpanel-browser';

import { usePlaybookFeed } from '../components/playbookFeed/usePlaybookFeed';

export const useMessagingContentActions = (messagingTemplates?: MessagingTemplate[]) => {
  const {
    selectedTab: tabSelected,
    templateFunctions,
    onCardClicked,
    activeBobject,
    segmentationValues,
    visibilityFilters,
    searchValue,
    company,
    leads,
  } = usePlaybookFeed();
  const setEmailVariablesValues = useBaseSetEmailVariablesValues();
  const { openMinimizableModal } = useMinimizableModals();
  const { t } = useTranslation();

  const areThereSegmentationFiltersApplied =
    segmentationValues && Object.keys(segmentationValues).length !== 0;
  const areThereVisibilityFiltersApplied =
    visibilityFilters && Object.values(visibilityFilters).some(v => !!v);

  const noResults =
    messagingTemplates?.length === 0 &&
    (searchValue?.length > 0 ||
      !!areThereSegmentationFiltersApplied ||
      !!areThereVisibilityFiltersApplied);

  const noTemplates =
    messagingTemplates?.length === 0 &&
    !areThereSegmentationFiltersApplied &&
    !areThereVisibilityFiltersApplied &&
    (!searchValue || searchValue?.length === 0);

  const handleEmailModal = template => {
    const activeBobjectType = activeBobject?.id?.typeName;
    setEmailVariablesValues({
      company: company?.rawBobject,
      lead:
        activeBobjectType === BobjectTypes.Lead
          ? activeBobject?.rawBobject
          : leads && leads[0]?.rawBobject,
      opportunity: undefined,
    });
    openMinimizableModal({
      type: 'email',
      title: t('smartEmailModal.newEmail'),
      data: {
        template: {
          content: template?.content,
          subject: template?.subject || '',
          id: template?.id,
          format: template?.format,
          mediaFiles: template?.mediaFiles,
          name: template?.name || t('playbook.untitledTemplate'),
        },
        mode: EMAIL_MODE.SEND,
        isBlankEmail: false,
        company: activeBobjectType === BobjectTypes.Company ? activeBobject : company,
        leads,
        lead: activeBobjectType === BobjectTypes.Lead ? activeBobject : leads?.[0],
        pageBobjectType: activeBobject.id.typeName,
      },
      onSave: () => {
        window.dispatchEvent(
          new CustomEvent('ACTIVE_BOBJECT_UPDATED', {
            detail: { type: BobjectTypes.Activity },
          }),
        );
      },
    });
  };

  function handleOnClick(template) {
    onCardClicked(template, tabSelected, handleEmailModal);
    mixpanel.track(`CLICK_ON_${templateTypes[tabSelected]}_CARD_FROM_PLAYBOOK_OTO`);
  }

  const handleAddTemplateClick = () => {
    templateFunctions?.editTemplate({
      type: templateTypes[tabSelected],
      edit: true,
    } as EditableTemplateType);
    mixpanel.track(`CLICK_ON_${templateTypes[tabSelected]}_ADD_TEMPLATE_FROM_PLAYBOOK_OTO`);
  };

  return {
    areThereSegmentationFiltersApplied,
    areThereVisibilityFiltersApplied,
    noTemplates,
    noResults,
    handleAddTemplateClick,
    handleOnClick,
    handleEmailModal,
  };
};
