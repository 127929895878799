// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".targetMarketsButton-module___target_market_message___Eup66 {\n  display: flex;\n  background: var(--verySoftPurple);\n  mix-blend-mode: normal;\n  border-radius: 4px;\n  padding: 8px 26px;\n  align-items: center;\n  margin-left: 8px;\n}\n\n.targetMarketsButton-module___target_market_message___Eup66:hover {\n  cursor: pointer;\n}\n\n.targetMarketsButton-module___target_market_message___Eup66 > span {\n  margin-left: 3px;\n}\n\n.targetMarketsButton-module___target_market_message_small___zqba3 {\n  padding: 4px 11px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/targetMarketsButton/targetMarketsButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iCAAiC;EACjC,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["._target_market_message {\n  display: flex;\n  background: var(--verySoftPurple);\n  mix-blend-mode: normal;\n  border-radius: 4px;\n  padding: 8px 26px;\n  align-items: center;\n  margin-left: 8px;\n}\n\n._target_market_message:hover {\n  cursor: pointer;\n}\n\n._target_market_message > span {\n  margin-left: 3px;\n}\n\n._target_market_message_small {\n  padding: 4px 11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_target_market_message": "targetMarketsButton-module___target_market_message___Eup66",
	"_target_market_message_small": "targetMarketsButton-module___target_market_message_small___zqba3"
};
export default ___CSS_LOADER_EXPORT___;
