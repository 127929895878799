// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".messagingTemplateActions-module___container___93ah\\+ {\n  display: flex;\n  align-items: center;\n  flex-grow: 1;\n}\n\n.messagingTemplateActions-module___rightActions__container___isDQ8 {\n  display: flex;\n  align-items: center;\n}\n\n.messagingTemplateActions-module___searchBar__container___WhfYM {\n  max-width: 190px;\n  margin-right: 12px;\n  margin-bottom: 3px;\n}\n\n.messagingTemplateActions-module___switchAction__container___9TuLR {\n  display: flex;\n  align-items: center;\n  flex-grow: 1;\n  gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/messagingSectionLayout/messagingTemplateActions/messagingTemplateActions.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,QAAQ;AACV","sourcesContent":["._container {\n  display: flex;\n  align-items: center;\n  flex-grow: 1;\n}\n\n._rightActions__container {\n  display: flex;\n  align-items: center;\n}\n\n._searchBar__container {\n  max-width: 190px;\n  margin-right: 12px;\n  margin-bottom: 3px;\n}\n\n._switchAction__container {\n  display: flex;\n  align-items: center;\n  flex-grow: 1;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "messagingTemplateActions-module___container___93ah+",
	"_rightActions__container": "messagingTemplateActions-module___rightActions__container___isDQ8",
	"_searchBar__container": "messagingTemplateActions-module___searchBar__container___WhfYM",
	"_switchAction__container": "messagingTemplateActions-module___switchAction__container___9TuLR"
};
export default ___CSS_LOADER_EXPORT___;
