// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Section-module__root___bIU2O {\n  margin: 25px 0;\n}\n\n.Section-module__row___tMyQs {\n  display: flex;\n  margin: 0 -10px;\n  justify-content: center;\n}\n\n@media (max-width: 1279px) {\n  .Section-module__row___tMyQs {\n    flex-direction: column;\n    margin: 10px 0;\n  }\n  .Section-module__root___bIU2O {\n    margin-left: 40px;\n  }\n}\n\n.Section-module__rowItem___0qfFJ {\n  flex: 1;\n  margin: 0 10px;\n  min-width: calc(50% - 20px);\n}\n\n@media (max-width: 1279px) {\n  .Section-module__rowItem___0qfFJ {\n    margin: 10px 0;\n  }\n}\n\n.Section-module__title___WV5o5 {\n  display: flex;\n}\n\n.Section-module__tag___E6gJQ {\n  margin-left: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/dashboardPages/v1/Section/Section.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE;IACE,sBAAsB;IACtB,cAAc;EAChB;EACA;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE,OAAO;EACP,cAAc;EACd,2BAA2B;AAC7B;;AAEA;EACE;IACE,cAAc;EAChB;AACF;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".root {\n  margin: 25px 0;\n}\n\n.row {\n  display: flex;\n  margin: 0 -10px;\n  justify-content: center;\n}\n\n@media (max-width: 1279px) {\n  .row {\n    flex-direction: column;\n    margin: 10px 0;\n  }\n  .root {\n    margin-left: 40px;\n  }\n}\n\n.rowItem {\n  flex: 1;\n  margin: 0 10px;\n  min-width: calc(50% - 20px);\n}\n\n@media (max-width: 1279px) {\n  .rowItem {\n    margin: 10px 0;\n  }\n}\n\n.title {\n  display: flex;\n}\n\n.tag {\n  margin-left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Section-module__root___bIU2O",
	"row": "Section-module__row___tMyQs",
	"rowItem": "Section-module__rowItem___0qfFJ",
	"title": "Section-module__title___WV5o5",
	"tag": "Section-module__tag___E6gJQ"
};
export default ___CSS_LOADER_EXPORT___;
