import { LEAD_STATUS_LOGIC_ROLE } from '@bloobirds-it/types';

import { STEPS } from './changeStatus.machine';

export const COMPANY_STATUSES_WITH_MESSAGE = [
  STEPS.MEETING,
  STEPS.ACCOUNT,
  STEPS.DISCARDED,
  STEPS.NURTURING,
];

export const AVAILABLE_LEAD_STATUSES = Object.freeze({
  LEAD__STATUS__ON_PROSPECTION: 'LEAD__STATUS__ON_PROSPECTION',
  LEAD__STATUS__CONTACTED: 'LEAD__STATUS__CONTACTED',
  LEAD__STATUS__ENGAGED: 'LEAD__STATUS__ENGAGED',
  LEAD__STATUS__MEETING: 'LEAD__STATUS__MEETING',
  LEAD__STATUS__CONTACT: 'LEAD__STATUS__CONTACT',
  LEAD__STATUS__NURTURING: 'LEAD__STATUS__NURTURING',
  LEAD__STATUS__DISCARDED: 'LEAD__STATUS__DISCARDED',
});

export const AVAILABLE_COMPANY_STATUSES = Object.freeze({
  COMPANY__STATUS__ON_PROSPECTION: 'COMPANY__STATUS__ON_PROSPECTION',
  COMPANY__STATUS__CONTACTED: 'COMPANY__STATUS__CONTACTED',
  COMPANY__STATUS__ENGAGED: 'COMPANY__STATUS__ENGADED',
  COMPANY__STATUS__MEETING: 'COMPANY__STATUS__MEETING',
  COMPANY__STATUS__ACCOUNT: 'COMPANY__STATUS__ACCOUNT',
  COMPANY__STATUS__NURTURING: 'COMPANY__STATUS__NURTURING',
  COMPANY__STATUS__DISCARDED: 'COMPANY__STATUS__DISCARDED',
});

export const tooltipKeys = [
  'LEAD__STATUS__CONTACTED',
  'LEAD__STATUS__ENGAGED',
  'LEAD__STATUS__MEETING',
  'LEAD__STATUS__NURTURING',
  'LEAD__STATUS__DISCARDED',
  'COMPANY__STATUS__CONTACTED',
  'COMPANY__STATUS__ENGAGED',
  'COMPANY__STATUS__MEETING',
  'COMPANY__STATUS__NURTURING',
  'COMPANY__STATUS__DISCARDED',
];
