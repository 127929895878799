// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".importButton-module___container___hei5w {\n  margin-left: 8px;\n}\n\n.importButton-module___optionsWrapper___dnmkd {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  background: var(--white);\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);\n  border-radius: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectTable/rightPanel/importButton/importButton.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,MAAM;EACN,SAAS;EACT,wBAAwB;EACxB,0CAA0C;EAC1C,kBAAkB;AACpB","sourcesContent":["._container {\n  margin-left: 8px;\n}\n\n._optionsWrapper {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  background: var(--white);\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "importButton-module___container___hei5w",
	"_optionsWrapper": "importButton-module___optionsWrapper___dnmkd"
};
export default ___CSS_LOADER_EXPORT___;
