// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".activityEmailActions-module___edit_container___ydPI1 {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  background-color: var(--white);\n  text-align: right;\n  width: 100%;\n  align-items: self-start;\n}\n\n.activityEmailActions-module___reply__container___-Noxv {\n  margin-left: 12px;\n  height: 24px;\n}\n\n.activityEmailActions-module___reply__container___-Noxv > button {\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/activitySection/activityItem/activityEmailActions/activityEmailActions.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,8BAA8B;EAC9B,iBAAiB;EACjB,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["._edit_container {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  background-color: var(--white);\n  text-align: right;\n  width: 100%;\n  align-items: self-start;\n}\n\n._reply__container {\n  margin-left: 12px;\n  height: 24px;\n}\n\n._reply__container > button {\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_edit_container": "activityEmailActions-module___edit_container___ydPI1",
	"_reply__container": "activityEmailActions-module___reply__container___-Noxv"
};
export default ___CSS_LOADER_EXPORT___;
