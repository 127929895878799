import { ComponentType } from 'react';

import { ExtensionBobject } from '@bloobirds-it/types';

export const enum ContactViewActionTypes {
  REASSIGN_COMPANY = 'reassignCompany',
  ASSIGN_COMPANY = 'assignCompany',
  REMOVE_COMPANY = 'removeCompany',
}

export interface ActionComponentProps {
  activeBobject: ExtensionBobject;
  closeAction: () => void;
  refreshActiveBobject: () => void;
}

export type ActionComponents = Record<ContactViewActionTypes, ComponentType<ActionComponentProps>>;
