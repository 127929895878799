// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card-module___container___KJaBd {\n  flex-basis: 100%;\n}\n\n.card-module___container___KJaBd > *:first-child {\n  margin-bottom: 8px;\n}\n\n.card-module___body___z51FJ {\n  background-color: var(--white);\n  border: 1px solid #dedede;\n  box-sizing: border-box;\n  box-shadow: 0 2px 4px rgba(92, 92, 255, 0.0837296);\n  border-radius: 4px;\n  padding: 24px 24px 28px;\n  margin-bottom: 16px;\n}\n\n.card-module___body_link___bCZ5a {\n  display: inline-flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 4px;\n  text-decoration: none;\n}\n\n.card-module___body_link___bCZ5a:hover {\n  text-decoration: underline;\n  text-decoration-color: var(--bloobirds);\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/duplicateValidationModal/card/card.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,sBAAsB;EACtB,kDAAkD;EAClD,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,2BAA2B;EAC3B,QAAQ;EACR,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;EAC1B,uCAAuC;AACzC","sourcesContent":["._container {\n  flex-basis: 100%;\n}\n\n._container > *:first-child {\n  margin-bottom: 8px;\n}\n\n._body {\n  background-color: var(--white);\n  border: 1px solid #dedede;\n  box-sizing: border-box;\n  box-shadow: 0 2px 4px rgba(92, 92, 255, 0.0837296);\n  border-radius: 4px;\n  padding: 24px 24px 28px;\n  margin-bottom: 16px;\n}\n\n._body_link {\n  display: inline-flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 4px;\n  text-decoration: none;\n}\n\n._body_link:hover {\n  text-decoration: underline;\n  text-decoration-color: var(--bloobirds);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "card-module___container___KJaBd",
	"_body": "card-module___body___z51FJ",
	"_body_link": "card-module___body_link___bCZ5a"
};
export default ___CSS_LOADER_EXPORT___;
