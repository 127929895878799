// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fieldsTooltips-module___choose_bobject_wrapper___sJIBA {\n    z-index: 1;\n    position: relative;\n    left: -10rem;\n    top: 1rem;\n}\n\n.fieldsTooltips-module___create_field_wrapper___JnUM3 {\n    z-index: 1;\n    position: relative;\n    right: -20rem;\n    top: 2rem;\n}\n\n.fieldsTooltips-module___preview_wrapper___qNvsk {\n    z-index: 1;\n    position: absolute;\n    left: 29.5rem;\n    top: 6.2rem;\n}\n\n.fieldsTooltips-module___image___SjMDO {\n    margin-top: 20px;\n}\n\n.fieldsTooltips-module___primary_button___EA5RU {\n    border-color: var(--white);\n    margin-left: 215px;\n    margin-top: -12px;\n    margin-bottom: -6px;\n}", "",{"version":3,"sources":["webpack://./src/js/components/discoveryTooltips/fieldsTourTooltips/fieldsTooltips.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;IAClB,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,aAAa;IACb,SAAS;AACb;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":["._choose_bobject_wrapper {\n    z-index: 1;\n    position: relative;\n    left: -10rem;\n    top: 1rem;\n}\n\n._create_field_wrapper {\n    z-index: 1;\n    position: relative;\n    right: -20rem;\n    top: 2rem;\n}\n\n._preview_wrapper {\n    z-index: 1;\n    position: absolute;\n    left: 29.5rem;\n    top: 6.2rem;\n}\n\n._image {\n    margin-top: 20px;\n}\n\n._primary_button {\n    border-color: var(--white);\n    margin-left: 215px;\n    margin-top: -12px;\n    margin-bottom: -6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_choose_bobject_wrapper": "fieldsTooltips-module___choose_bobject_wrapper___sJIBA",
	"_create_field_wrapper": "fieldsTooltips-module___create_field_wrapper___JnUM3",
	"_preview_wrapper": "fieldsTooltips-module___preview_wrapper___qNvsk",
	"_image": "fieldsTooltips-module___image___SjMDO",
	"_primary_button": "fieldsTooltips-module___primary_button___EA5RU"
};
export default ___CSS_LOADER_EXPORT___;
