import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Icon, Text } from '@bloobirds-it/flamingo-ui';

import styles from './taskFeedErrorPage.module.css';

export const TaskFeedErrorPage = ({ onClick }: { onClick: () => void }) => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'taskFeedErrorPage' });

  return (
    <div className={styles.wrapper}>
      <Icon name="taskAction" size={36} color="softPeanut" />
      <Text size="l" color="peanut" align="center">
        {t('title')}
      </Text>
      <Text size="m" color="softPeanut" align="center">
        {t('subtitle')}
      </Text>
      <Button onClick={onClick} variant="secondary" size="small">
        {t('reloadButton')}
      </Button>
      <Text size="s" color="softPeanut" align="center">
        <Trans
          i18nKey="taskFeedErrorPage.linkText"
          components={[
            <a
              key="0"
              href={
                i18n.language === 'en'
                  ? 'https://support.bloobirds.com/hc/es-us/requests/new'
                  : 'https://support.bloobirds.com/hc/es-es/requests/new'
              }
              target="_blank"
              rel="noreferrer"
            />,
          ]}
        />
      </Text>
    </div>
  );
};
