// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rescheduleTaskModal-modules___date__wrapper___qB5yB {\n  display: flex;\n  justify-content: center;\n  margin-top: 30px;\n  margin-bottom: 50px;\n}\n\n.rescheduleTaskModal-modules__modal___AMlGn {\n  width: 300px !important;\n  background-color: var(--white);\n}\n\n.rescheduleTaskModal-modules__header___hlnlo {\n  padding: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.rescheduleTaskModal-modules__content___Qfe2A {\n  padding: 16px 24px;\n  padding-top: 0;\n}\n\n.rescheduleTaskModal-modules__customButton___2YB3a {\n  margin-top: 8px;\n  justify-content: center;\n}\n\n.rescheduleTaskModal-modules__shortcuts___z8THA {\n  display: grid;\n  grid-auto-flow: row;\n  row-gap: 4px;\n  margin-bottom: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/rescheduleTaskModal/rescheduleTaskModal.modules.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":["._date__wrapper {\n  display: flex;\n  justify-content: center;\n  margin-top: 30px;\n  margin-bottom: 50px;\n}\n\n.modal {\n  width: 300px !important;\n  background-color: var(--white);\n}\n\n.header {\n  padding: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.content {\n  padding: 16px 24px;\n  padding-top: 0;\n}\n\n.customButton {\n  margin-top: 8px;\n  justify-content: center;\n}\n\n.shortcuts {\n  display: grid;\n  grid-auto-flow: row;\n  row-gap: 4px;\n  margin-bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_date__wrapper": "rescheduleTaskModal-modules___date__wrapper___qB5yB",
	"modal": "rescheduleTaskModal-modules__modal___AMlGn",
	"header": "rescheduleTaskModal-modules__header___hlnlo",
	"content": "rescheduleTaskModal-modules__content___Qfe2A",
	"customButton": "rescheduleTaskModal-modules__customButton___2YB3a",
	"shortcuts": "rescheduleTaskModal-modules__shortcuts___z8THA"
};
export default ___CSS_LOADER_EXPORT___;
