// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".apiKeyPage-module__noResultsContents___QxSyx {\n  box-sizing: border-box;\n  padding: 150px 227px 150px 227px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: white;\n}\n.apiKeyPage-module__noResultsImg___chLAN {\n  margin-bottom: 20px;\n}\n\n.apiKeyPage-module__nameInput___s9vkP {\n  margin-top: 8px;\n  margin-bottom: 24px;\n  width: 100%;\n}\n\n.apiKeyPage-module__keysContainer___dW2LW {\n  margin-bottom: 24px;\n}\n\n.apiKeyPage-module__skeletonContainer___eYJId {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.apiKeyPage-module__skeletonContainer___eYJId > * {\n  margin-bottom: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/apiKeyPage/pages/apiKeyPage.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,gCAAgC;EAChC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".noResultsContents {\n  box-sizing: border-box;\n  padding: 150px 227px 150px 227px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: white;\n}\n.noResultsImg {\n  margin-bottom: 20px;\n}\n\n.nameInput {\n  margin-top: 8px;\n  margin-bottom: 24px;\n  width: 100%;\n}\n\n.keysContainer {\n  margin-bottom: 24px;\n}\n\n.skeletonContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.skeletonContainer > * {\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noResultsContents": "apiKeyPage-module__noResultsContents___QxSyx",
	"noResultsImg": "apiKeyPage-module__noResultsImg___chLAN",
	"nameInput": "apiKeyPage-module__nameInput___s9vkP",
	"keysContainer": "apiKeyPage-module__keysContainer___dW2LW",
	"skeletonContainer": "apiKeyPage-module__skeletonContainer___eYJId"
};
export default ___CSS_LOADER_EXPORT___;
