import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TableContainer,
  TableHeader,
  TableHeaderLeft,
  Button,
  TableHeaderRight,
  Table,
  TableHead,
  TableCell,
  TableBody,
  Text,
  Icon,
} from '@bloobirds-it/flamingo-ui';

import { AddUserModal } from '../components/modals/addUserModal';
import { AssignedUsersRows } from '../components/rows/assignedUsersRows';
import { UserInterface } from '../constants/phoneNumbers.constants';
import { usePhoneNumberFormContext } from '../context/phoneNumberFormProvider';
import styles from '../styles/phoneNumberPage.module.css';

export const TeamsAndUsers = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { phoneNumber } = usePhoneNumberFormContext();

  return (
    <div className={styles.generalSettingsContainer} style={{ marginTop: '8px' }}>
      <AddUserModal open={open} setOpen={setOpen} />
      <div className={styles.modifiedOverflow}>
        <TableContainer>
          <TableHeader>
            <TableHeaderLeft>
              <Text className={styles.titleGeneral} size="l" color="peanut" weight="medium">
                <Icon name="persons" size={24} color="bloobirds" />
                {t('accountSettings.phoneNumbers.phoneSettings.tabs.teamsandusers.subtitle')}
              </Text>
            </TableHeaderLeft>
            <TableHeaderRight>
              <Button onClick={() => setOpen(true)} variant="secondary">
                {t('accountSettings.phoneNumbers.phoneSettings.tabs.teamsandusers.addButton')}
              </Button>
            </TableHeaderRight>
          </TableHeader>
          <Table>
            <TableHead>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableHead>
            <TableBody>
              {phoneNumber.users.map((user: UserInterface) => (
                <AssignedUsersRows user={user} key={user.id} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
