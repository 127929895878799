import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useToasts } from '@bloobirds-it/flamingo-ui';
import {
  BobjectId,
  BobjectTypes,
  ACTIVITY_FIELDS_LOGIC_ROLE,
  RelatedObject,
  BobjectType,
  TASK_FIELDS_LOGIC_ROLE,
} from '@bloobirds-it/types';
import { api, getObjectIdFromId } from '@bloobirds-it/utils';
import useSWR from 'swr';

interface AssignmentFunctions {
  addRelatedToBobject: (relatedIds: string | string[], callback?: () => void) => void;
  activityAllRelated:
    | Array<{
        bobjectType: BobjectType;
        order: number;
        relatedObjectType: string;
        relatedSobjects: RelatedObject[];
      }>
    | undefined;
  isLoadingAllRelations: boolean;
  activityActiveRelated: Record<string, RelatedObject[]> | undefined;
  isLoading: boolean;
  mutate: (selectedValue: string | string[]) => void;
}

const fetchActivityActiveRelated = async (activityRelated: string[]) => {
  const res = await api.post('/utils/service/salesforce/activity/related', {
    activityRelated,
  });
  return res.data;
};

const fetchBobjectAllRelated = ([, bobjectId, referenceBobjectType]: string[]) =>
  api
    .get(
      `/utils/service/salesforce/${bobjectId
        ?.split('/')?.[1]
        ?.toLowerCase()}/related/${getObjectIdFromId(
        bobjectId,
      )}?bobjectType=${referenceBobjectType}`,
    )
    .then(res => res.data);

export const useRelatedAssignment = ({
  bobjectId,
  activityRelated,
  referenceBobjectType,
}: {
  bobjectId:
    | BobjectId<BobjectTypes.Task>['value']
    | BobjectId<BobjectTypes.Activity>['value']
    | Array<BobjectId<BobjectTypes.Activity>['value']>;
  activityRelated?: string | string[];
  referenceBobjectType: BobjectTypes;
}): AssignmentFunctions => {
  const { createToast } = useToasts();
  const { t } = useTranslation('translation', {
    keyPrefix: 'relatedObjects.assignRelatedDropdown.toasts',
  });

  const isBulk = typeof bobjectId !== 'string';
  const isTask = !isBulk && bobjectId?.includes(BobjectTypes.Task);
  const [activityActiveRelated, setActivityActiveRelated] = useState<
    Record<string, RelatedObject[]> | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { data: activityAllRelated, isLoading: isLoadingAllRelations } = useSWR(
    bobjectId &&
      referenceBobjectType && [
        `/utils/service/salesforce/activity/related/all`,
        isBulk ? bobjectId[0] : bobjectId,
        referenceBobjectType,
      ],
    fetchBobjectAllRelated,
  );

  const addRelatedToBobject = (relatedIds: string, callback?: () => void) => {
    if (isBulk) {
      const bobjectUrl = bobjectId?.[0].split('/')[0] + '/' + bobjectId?.[0].split('/')[1];
      const data = bobjectId?.reduce((acc, id) => {
        const objectId = getObjectIdFromId(id);
        acc[objectId] = {
          [isTask
            ? TASK_FIELDS_LOGIC_ROLE.RELATED_OBJECT_ID
            : ACTIVITY_FIELDS_LOGIC_ROLE.RELATED_OBJECT_ID]: relatedIds,
        };
        return acc;
      }, {});

      api.patch(`/bobjects/${bobjectUrl}/bulk`, data).then(callback);
    } else {
      const activityData = {
        [isTask
          ? TASK_FIELDS_LOGIC_ROLE.RELATED_OBJECT_ID
          : ACTIVITY_FIELDS_LOGIC_ROLE.RELATED_OBJECT_ID]: relatedIds,
      };

      api
        .patch(`/bobjects/${bobjectId}/raw`, activityData)
        .then(() => {
          createToast({ type: 'success', message: t('success') });
        })
        .then(callback);
    }
  };

  const loadActivityActiveRelated = async (selectValue?: string | string[]) => {
    const relatedToUse = selectValue ?? activityRelated;
    if (relatedToUse) {
      setIsLoading(true);

      const data = await fetchActivityActiveRelated(
        typeof relatedToUse === 'string' ? [relatedToUse] : relatedToUse,
      );
      setActivityActiveRelated(data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadActivityActiveRelated?.();
  }, []);

  return {
    addRelatedToBobject,
    activityAllRelated,
    isLoadingAllRelations,
    activityActiveRelated,
    isLoading,
    mutate: loadActivityActiveRelated,
  };
};
