import React from 'react';

import { useAccountId, usePhoneNumbersEnabled } from '@bloobirds-it/hooks';

import AccountSettingsLayout from '../../../layouts/accountSettingsLayout';
import AccountSettingsTab from '../../../layouts/accountSettingsLayout/accountSettingsTab';
import { AccountSettingsTabContent } from '../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import SessionManagerFactory from '../../../misc/session';
import NoPermissionsPage from '../../noPermissionsPage';
import { PhoneFilters } from './components/headers/phoneFilters';
import { PhoneNumbersHeader } from './components/headers/phoneNumbersHeader';
import { PhoneNumbersTableContent } from './components/table/phoneNumbersTableContent';
import { RequestedPhonesFormProvider } from './context/requestedPhonesFormProvider';
import { usePaginatedPhoneEntity } from './hooks/usePaginatedPhoneEntity';
import { PhoneSettings } from './pages/phoneSettings';
import { PurchaseNewPhone } from './pages/purchaseNewPhone';

export const PhoneNumbersPage = () => {
  const roleManager = SessionManagerFactory().getRoleManager();
  const {
    entities,
    isLoading,
    showPhoneSettings,
    showPurchasePhoneNumber,
  } = usePaginatedPhoneEntity();
  const totalElements = entities.length;
  const accountId = useAccountId();

  const isPhoneNumberEnabled = usePhoneNumbersEnabled(accountId);

  const isAccountAdmin = roleManager.isAccountAdmin();

  if (!isAccountAdmin || !isPhoneNumberEnabled) return <NoPermissionsPage />;

  if (showPurchasePhoneNumber) {
    return (
      <RequestedPhonesFormProvider>
        <PurchaseNewPhone />
      </RequestedPhonesFormProvider>
    );
  }

  return (
    <AccountSettingsLayout title="" subtitle="">
      <AccountSettingsTab>
        {showPhoneSettings.show ? (
          <PhoneSettings phone={showPhoneSettings.phone} />
        ) : (
          <>
            <PhoneNumbersHeader />
            <AccountSettingsTabContent>
              <PhoneFilters />
              <PhoneNumbersTableContent
                entities={entities}
                totalElements={totalElements}
                isLoading={isLoading}
              />
            </AccountSettingsTabContent>
          </>
        )}
      </AccountSettingsTab>
    </AccountSettingsLayout>
  );
};
