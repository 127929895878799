// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../assets/login-aside-708x1024.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aside-module___container___ei-or {\n  height: 100%;\n  background-size: 100% 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.aside-module___description_container___HJZbk {\n  height: 100%;\n  max-height: 238px;\n  width: 445px;\n}\n\n.aside-module___description_container___HJZbk > span > svg > path {\n  fill: var(--bloobirds);\n}\n\n.aside-module___text_wrapper___y1pN4 {\n  margin: 24px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/loginPage/aside/aside.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yDAAoE;AACtE;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["._container {\n  height: 100%;\n  background-size: 100% 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-image: url('../../../../assets/login-aside-708x1024.png');\n}\n\n._description_container {\n  height: 100%;\n  max-height: 238px;\n  width: 445px;\n}\n\n._description_container > span > svg > path {\n  fill: var(--bloobirds);\n}\n\n._text_wrapper {\n  margin: 24px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "aside-module___container___ei-or",
	"_description_container": "aside-module___description_container___HJZbk",
	"_text_wrapper": "aside-module___text_wrapper___y1pN4"
};
export default ___CSS_LOADER_EXPORT___;
