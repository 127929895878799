import { useController } from 'react-hook-form';

import { FormFieldProps } from '../baseInput/baseInput';
import styles from './textAreaField.module.css';

interface TextAreaFieldProps extends Omit<FormFieldProps, 'type'> {
  rows: number;
  width: string;
  className?: string;
  placeholder?: string;
  maxLength?: number;
  maxLengthMessage?: string;
}

export const TextAreaField = ({
  control,
  required,
  name,
  id,
  size = 'medium',

  rows,
  className,
  width,
  requiredMessage,
  formKey,
  maxLength,
  maxLengthMessage,
  placeholder,
}: TextAreaFieldProps) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name: formKey ?? `fields.${id}`,
    rules: {
      required: {
        value: required,
        message: requiredMessage,
      },
      maxLength: maxLength ? { value: maxLength, message: maxLengthMessage } : undefined,
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={styles.textareaWrapper}>
      <textarea
        id={id}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        className={`${styles.textarea} ${className || ''} ${error ? styles.error : ''}`}
        style={{ width }}
        placeholder={placeholder || (size === 'small' ? undefined : name)}
        maxLength={maxLength}
        rows={rows}
      />
      {error && <p className={styles.errorMessage}>{error.message}</p>}
      {maxLength && (
        <p className={styles.maxLengthIndicator}>{`${value?.length || 0}/${maxLength}`}</p>
      )}
    </div>
  );
};
