import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  Bobject,
  BobjectTypes,
  ExtensionCompany,
  TDateISO,
  TDateISODate,
  UserResponse,
} from '@bloobirds-it/types';
import {
  calculateCollisions,
  createParticipantsFromBloobirdsActivity,
  getDuration,
  getUserTimeZone,
  getValueFromLogicRole,
} from '@bloobirds-it/utils';
import spacetime from 'spacetime';

import {
  CalendarsWithColors,
  EventsPerDay,
  EventsType,
  ICalendarEvent,
  Invitee,
} from '../types/calendar';

export function parseEvents(
  events: any,
  type: EventsType,
  users: UserResponse,
  selectedTimezone: string,
  calendarsWithColors: CalendarsWithColors[],
  bannedEvent: string,
) {
  if (!events) {
    return {};
  }
  if (!type) {
    return {};
  }
  const eventPerDay = events?.reduce((perDay: EventsPerDay, event: any) => {
    if (event?.when?.startTime && event?.status !== 'cancelled' && event?.id !== bannedEvent) {
      const startSpaceTimeDate = spacetime(event?.when?.startTime);
      const date = startSpaceTimeDate
        .goto(selectedTimezone || getUserTimeZone())
        .format('iso-short');
      const endSpaceTimeDate = spacetime(event?.when?.endTime);
      const endDate = endSpaceTimeDate
        .goto(selectedTimezone || getUserTimeZone())
        .format('iso-short');

      const colorEvent = calendarsWithColors?.find(c => c?.calendarId === event.calendarId);
      if (date === endDate) {
        perDay[date] = [
          ...(perDay[date] || []),
          {
            duration: getDuration(event.when?.startTime, event.when?.endTime),
            id: event.id,
            title: event.title,
            startTime: event.when?.startTime,
            endTime: event.when?.endTime,
            startTimeTimestamp: startSpaceTimeDate.epoch,
            endTimeTimestamp: endSpaceTimeDate.epoch,
            participants: event.participants,
            collisions: 0,
            collisionNumber: 0,
            day: spacetime(event.when?.startTime)
              .startOf('day')
              .format('iso-short') as TDateISODate,
            type: 'nylas',
            calendarId: event.calendarId,
            backgroundColor: colorEvent?.color,
            barColor: colorEvent?.barColor,
            owner: event.owner,
          },
        ];
      }
    } else if (getValueFromLogicRole(event, ACTIVITY_FIELDS_LOGIC_ROLE.TIME)) {
      const spacetimeStartDatetime = spacetime(
        getValueFromLogicRole(event, ACTIVITY_FIELDS_LOGIC_ROLE.TIME),
      );
      const startDatetime = spacetimeStartDatetime?.goto(selectedTimezone || getUserTimeZone());
      const duration =
        parseInt(getValueFromLogicRole(event, ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_DURATION), 10) ||
        60;
      const endDatetime = spacetimeStartDatetime
        ?.goto(selectedTimezone || getUserTimeZone())
        .add(duration, 'minute');
      const date = spacetimeStartDatetime.format('iso-short') as TDateISODate;
      perDay[date] = [
        ...(perDay[date] || []),
        {
          duration,
          id: event?.id?.value,
          title: getValueFromLogicRole(event, ACTIVITY_FIELDS_LOGIC_ROLE.TITLE),
          startTime: startDatetime.format('iso-utc') as TDateISO,
          endTime: endDatetime.format('iso-utc') as TDateISO,
          startTimeTimestamp: startDatetime.epoch,
          endTimeTimestamp: endDatetime.epoch,
          participants: createParticipantsFromBloobirdsActivity(event, users) as Invitee[],
          collisions: 0,
          collisionNumber: 0,
          day: spacetime(startDatetime).startOf('day').format('iso-short') as TDateISODate,
          type: 'bloobirds',
          calendarId: 'bloobirds-event',
        },
      ];
    }
    return perDay;
  }, {});

  // Calculate and set the collisions, and the collision number. Two events are considered a collision if their start time is between the start time and the end time of the other.
  Object.keys(eventPerDay).map(date => {
    const events = eventPerDay[date];
    const sortedEvents = events?.sort(
      (a: ICalendarEvent, b: ICalendarEvent) => b.duration - a.duration,
    );
    return calculateCollisions(sortedEvents);
  });
  return eventPerDay;
}

/**
 * Takes an object and stringifies the arrays exclusively in the first level
 *
 * currently used in meetingModal
 * @param obj
 */
export function stringifyArraysInObjects(obj: Record<string, any>) {
  const transformedObj = {};
  if (!transformedObj) {
    return null;
  }
  if (Object.keys(obj)?.length === 0) {
    return {};
  }
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      transformedObj[key] = JSON.stringify(obj[key]);
    } else {
      transformedObj[key] = obj[key];
    }
  }
  return transformedObj;
}

/**
 * evaluates if the passed company is a classic bobject or an extension one
 * currently used in meetingModal
 * @param company --
 */
export const getEmailFromCompany = (company: Bobject<BobjectTypes.Company> | ExtensionCompany) => {
  if (!company) return null;
  if ('fields' in company) {
    const companyEmails =
      company.fields?.filter(field => field.value && field.type === 'EMAIL') ?? [];

    return companyEmails.length > 0 ? companyEmails[0] : undefined;
  } else {
    return null;
  }
};
