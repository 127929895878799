import React, { useState } from 'react';

import { useToasts } from '@bloobirds-it/flamingo-ui';
import { useAccountId, useActiveAccountSettings } from '@bloobirds-it/hooks';

import SessionManagerFactory from '../../../../misc/session';
import { linearIssueHandler } from '../utils/phoneHelper';
import { usePaginatedPhoneEntity } from './usePaginatedPhoneEntity';

export const useRequestPhoneForm = () => {
  const [phoneNumber, setPhoneNumber] = useState({
    id: '',
    phonesRequested: '',
    country: '',
    prefix: [],
    typeOfNumber: '',
    additionalInformation: '',
    capabilities: [],
  });
  const accountName = SessionManagerFactory().getAccount().name;
  const [phoneNumberList, setPhoneNumberList] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const { createToast } = useToasts();
  const { setShowPurchasePhoneNumber } = usePaginatedPhoneEntity();
  const handleChangePhone = (key, value) => {
    setPhoneNumber(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const checkIfEveryFieldIsFilled = () => {
    const {
      phonesRequested,
      country,
      prefix,
      typeOfNumber,
      additionalInformation,
      capabilities,
    } = phoneNumber;
    if (prefix?.length === 0 || capabilities?.length === 0) {
      return false;
    }
    if (phonesRequested && country && prefix && typeOfNumber && additionalInformation) {
      return true;
    }
    return false;
  };

  const createLinearIssue = async () => {
    try {
      await linearIssueHandler(phoneNumberList, accountName)();
      setShowPurchasePhoneNumber(false);
      setPhoneNumberList([]);
      createToast({ message: 'Phone number request submitted', type: 'success' });
    } catch (error) {
      createToast({ message: 'Error creating linear issue', type: 'error' });
      console.error('Error creating linear issue:', error);
    }
  };

  const addNewPhoneNumber = () => {
    if (!checkIfEveryFieldIsFilled()) {
      createToast({ message: 'Please fill all the fields', type: 'error' });
      return;
    }
    setPhoneNumberList(prevState => [
      ...prevState,
      {
        ...phoneNumber,
        id: Math.random().toString(36),
      },
    ]);
    setPhoneNumber({
      id: '',
      phonesRequested: '',
      country: '',
      prefix: [],
      typeOfNumber: '',
      additionalInformation: '',
      capabilities: [],
    });
    createToast({ message: 'New phone number added', type: 'success' });
  };

  const removePhoneNumber = phoneId => {
    setPhoneNumberList(prevState => prevState.filter(phone => phone.id !== phoneId));
    createToast({ message: 'Phone number removed', type: 'success' });
  };

  const handleStepChange = step => {
    setCurrentStep(step);
  };

  return {
    phoneNumber,
    handleChangePhone,
    phoneNumberList,
    addNewPhoneNumber,
    removePhoneNumber,
    currentStep,
    handleStepChange,
    createLinearIssue,
  };
};
