import React, { useEffect } from 'react';

import { RescheduleModal } from '@bloobirds-it/bobjects';
import { CadenceControlModal, RescheduleCadence, StopCadenceModal } from '@bloobirds-it/cadence';
import { useMinimizableModals } from '@bloobirds-it/hooks';
import {
  Bobject,
  BobjectTypes,
  MIXPANEL_EVENTS,
  TASK_FIELDS_LOGIC_ROLE,
} from '@bloobirds-it/types';
import { getValueFromLogicRole } from '@bloobirds-it/utils';
import mixpanel from 'mixpanel-browser';

import { useTaskManagementContext } from '../hooks/useTaskManagement';
import { TaskManagementModals } from '../types/taskManagement.types';

export const TasksModalsController = () => {
  const {
    bobject: referenceBobject,
    modalContext,
    handleCloseModal,
    handleSaveModal,
    handleSaveCadence,
    currentTasksProps,
  } = useTaskManagementContext();
  const { modal, task } = modalContext;
  const { hasCadenceSteps } = currentTasksProps;
  const { openMinimizableModal } = useMinimizableModals();

  useEffect(() => {
    if (modal === TaskManagementModals.NextStep && referenceBobject) {
      openMinimizableModal({
        type: 'taskStatic',
        data: {
          [referenceBobject.id.typeName.toLowerCase()]: referenceBobject,
        },
        onClose: handleCloseModal,
        onSave: () => {
          mixpanel.track(MIXPANEL_EVENTS.TASK_MANAGEMENT_STEP_CREATE_TASK);
          handleSaveModal();
        },
      });
    }
  }, [modal]);

  switch (modal) {
    case TaskManagementModals.NextStep:
      // Since we've already called openMinimizableModal in useEffect, return null here
      return null;
    case TaskManagementModals.RescheduleCadence:
      return (
        <RescheduleCadence
          bobject={referenceBobject as Bobject<BobjectTypes.Task>}
          onClose={handleCloseModal}
          onSave={() => {
            handleSaveModal(3000);
            mixpanel.track(MIXPANEL_EVENTS.TASK_MANAGEMENT_STEP_RESCHEDULE_CADENCE);
          }}
        />
      );
    case TaskManagementModals.StartCadence:
      return (
        <CadenceControlModal
          bobject={referenceBobject}
          setIsOpen={(open: boolean) => {
            if (!open) handleCloseModal(true);
          }}
          initialStep={{ step: 'CONFIGURE_CADENCE', hadStartedCadence: false }}
          callbackOnSave={() => {
            handleSaveCadence(hasCadenceSteps ? 12000 : 8000);
            mixpanel.track(MIXPANEL_EVENTS.TASK_MANAGEMENT_STEP_START_CADENCE);
          }}
        />
      );
    case TaskManagementModals.StopCadence:
      return (
        <StopCadenceModal
          bobject={referenceBobject}
          handleClose={handleCloseModal}
          handleSave={() => {
            handleSaveModal();
            mixpanel.track(MIXPANEL_EVENTS.TASK_MANAGEMENT_STEP_STOP_CADENCE);
          }}
          size="small"
        />
      );
    case TaskManagementModals.RescheduleTask:
      return (
        <RescheduleModal
          bobject={task as Bobject<BobjectTypes.Task>}
          onSave={() => {
            handleSaveModal();
            mixpanel.track(MIXPANEL_EVENTS.TASK_MANAGEMENT_STEP_RESCHEDULE_TASK);
          }}
          onClose={handleCloseModal}
        />
      );
    default:
      return <></>;
  }
};
