export enum NotificationsCategory {
  UPDATES = 'UPDATES',
  INBOUND = 'INBOUND',
  EMAIL_TRACKING = 'EMAIL_TRACKING',
  CALLS = 'CALLS',
  REMINDERS = 'REMINDERS',
  INSIGHTS = 'INSIGHTS',
}

export enum NotificationsTypes {
  PAGE_VIEW = 'PAGE_VIEW',
  NEW_EMAIL = 'NEW_EMAIL',
  NEW_LINKEDIN = 'NEW_LINKEDIN',
  NEW_INBOUND = 'NEW_INBOUND',
  NEW_INBOUND_LEAD = 'NEW_INBOUND_LEAD',
  MISSED_CALL_LEAD = 'MISSED_CALL_LEAD',
  MISSED_CALL_UNKNOWN = 'MISSED_CALL_UNKNOWN',
  REPORT_CALL = 'REPORT_CALL',
  EMAIL_CLICKED = 'EMAIL_CLICKED',
  EMAIL_OPENED = 'EMAIL_OPENED',
  INFORMATION = 'INFORMATION',
  ADVISE = 'ADVISE',
  IMPORT_FAILED = 'IMPORT_FAILED',
  IMPORT_COMPLETED = 'IMPORT_COMPLETED',
  IMPORT_COMPLETED_WITH_WARNINGS = 'IMPORT_COMPLETED_WITH_WARNINGS',
  ACCOUNT_STOPPED = 'ACCOUNT_STOPPED',
  MEETING_DONE = 'MEETING_DONE',
  MEETING_ACCEPTED = 'MEETING_ACCEPTED',
  MEETING_DELETED = 'MEETING_DELETED',
  MEETING_CANCELLED = 'MEETING_CANCELLED',
  MEETING_RESCHEDULED = 'MEETING_RESCHEDULED',
  CADENCE_ENDED = 'CADENCE_ENDED',
  COMPANY_ASSIGNED = 'COMPANY_ASSIGNED',
  LEAD_ASSIGNED = 'LEAD_ASSIGNED',
  WORKFLOWS = 'WORKFLOWS',
  RELATED_COMPANY_MEETING = 'RELATED_COMPANY_MEETING',
  RELATED_COMPANY_STATUS_ACCOUNT = 'RELATED_COMPANY_STATUS_ACCOUNT',
  RELATED_COMPANY_STATUS_CLIENT = 'RELATED_COMPANY_STATUS_CLIENT',
  RELATED_COMPANIES_OPPORTUNITY = 'RELATED_COMPANIES_OPPORTUNITY',
  RELATED_COMPANY_ACTIVITY_INBOUND = 'RELATED_COMPANY_ACTIVITY_INBOUND',
  RELATED_COMPANY_LEAD_INBOUND = 'RELATED_COMPANY_LEAD_INBOUND',
  ACTIVITY_MENTION = 'ACTIVITY_MENTION',
}

export interface Notification {
  id: string;
  title: string;
  subtitle: string;
  category: NotificationsCategory;
  type: NotificationsTypes;
  objectId: string;
  url: string;
  priority: string;
  userId: string;
  accountId: string;
  timestamp: string;
  read: boolean;
}
