// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inboxTooltips-module___sidebar_wrapper___xmQFH {\n    z-index: 1;\n    position: absolute;\n    top: 130px;\n    left: 95%;\n}\n\n.inboxTooltips-module___filters_wrapper___\\+2gTi {\n    z-index: 1;\n    position: relative;\n    left: 2%;\n}\n\n.inboxTooltips-module___bulk_wrapper___c6My3 {\n    z-index: 1;\n    position: relative;\n    left: 65%;\n    top: 23px;\n}\n\n.inboxTooltips-module___card_wrapper___xSj-D {\n    z-index: 1;\n    position: absolute;\n    left: 50%;\n    top: 265px;\n}\n\n.inboxTooltips-module___no_cards_wrapper___ZcMvL {\n    z-index: 1;\n    position: relative;\n    left: 50%;\n    top: 20px;\n    width: 24px;\n}\n\n.inboxTooltips-module___image___tmEuU {\n    margin-bottom: -42px;\n}\n\n.inboxTooltips-module___primary_button___4CHJU {\n    border-color: var(--white);\n}", "",{"version":3,"sources":["webpack://./src/js/components/discoveryTooltips/inboxTourTooltips/inboxTooltips.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;IAClB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,QAAQ;AACZ;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,SAAS;IACT,SAAS;AACb;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,WAAW;AACf;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["._sidebar_wrapper {\n    z-index: 1;\n    position: absolute;\n    top: 130px;\n    left: 95%;\n}\n\n._filters_wrapper {\n    z-index: 1;\n    position: relative;\n    left: 2%;\n}\n\n._bulk_wrapper {\n    z-index: 1;\n    position: relative;\n    left: 65%;\n    top: 23px;\n}\n\n._card_wrapper {\n    z-index: 1;\n    position: absolute;\n    left: 50%;\n    top: 265px;\n}\n\n._no_cards_wrapper {\n    z-index: 1;\n    position: relative;\n    left: 50%;\n    top: 20px;\n    width: 24px;\n}\n\n._image {\n    margin-bottom: -42px;\n}\n\n._primary_button {\n    border-color: var(--white);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_sidebar_wrapper": "inboxTooltips-module___sidebar_wrapper___xmQFH",
	"_filters_wrapper": "inboxTooltips-module___filters_wrapper___+2gTi",
	"_bulk_wrapper": "inboxTooltips-module___bulk_wrapper___c6My3",
	"_card_wrapper": "inboxTooltips-module___card_wrapper___xSj-D",
	"_no_cards_wrapper": "inboxTooltips-module___no_cards_wrapper___ZcMvL",
	"_image": "inboxTooltips-module___image___tmEuU",
	"_primary_button": "inboxTooltips-module___primary_button___4CHJU"
};
export default ___CSS_LOADER_EXPORT___;
