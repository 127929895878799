import { IconType } from '@bloobirds-it/flamingo-ui';

export type PlaygroundTab = 'insights' | 'prompts' | 'lab';
interface Tab {
  id: PlaygroundTab;
  label: string;
  icon: IconType;
}

export const PLAYGROUND_TABS = {
  INSIGHTS: 'insights' as PlaygroundTab,
  LAB: 'lab' as PlaygroundTab,
  PROMPTS: 'prompts' as PlaygroundTab,
};

export const TABS: Array<Tab> = [
  { id: PLAYGROUND_TABS.INSIGHTS, label: 'Insights', icon: 'insights' },
  { id: PLAYGROUND_TABS.LAB, label: 'Lab', icon: 'test' },
];

export type State = 'All' | 'CREATED' | 'ACTIVE' | 'DRAFT';
export type InsightType = 'All' | 'DECISION' | 'GENERATION';

export const States = {
  ALL: 'ALL' as State,
  CREATED: 'CREATED' as State,
  ACTIVE: 'ACTIVE' as State,
  DRAFT: 'DRAFT' as State,
};

export const QuickFilters = [
  { id: States.ALL, name: 'All' },
  { id: States.ACTIVE, name: 'Active' },
  { id: States.CREATED, name: 'Created' },
  { id: States.DRAFT, name: 'Draft' },
];

export const InsightTypes = {
  ALL: 'ALL' as InsightType,
  DECISION: 'DECISION' as InsightType,
  GENERATION: 'GENERATION' as InsightType,
};

export const InsightTypesArray = [InsightTypes.DECISION, InsightTypes.GENERATION];

export interface CopilotInsightSettings {
  insightsCreated: number;
  insightsActive: number;
  maxInsightsCreated: number;
  maxInsightsActive: number;
}
