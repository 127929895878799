// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".createInboundMappings-module___container___8q9gN {\n  box-sizing: border-box;\n  padding: 0 32px 34px 32px;\n}\n.createInboundMappings-module___container___8q9gN > p:first-child {\n  margin-bottom: 16px;\n}\n\n.createInboundMappings-module___content___8j8Ty {\n  box-sizing: border-box;\n  border: 1px solid #e6e6e6;\n  border-radius: 4px;\n  display: flex;\n  padding: 25px;\n  justify-content: space-between;\n  align-items: center;\n}\n.createInboundMappings-module___textArea___I-740 {\n  display: flex;\n}\n.createInboundMappings-module___textArea___I-740 > svg {\n  margin: 0 8px;\n}\n.createInboundMappings-module___direction___DHL7c {\n  display: flex;\n}\n.createInboundMappings-module___direction___DHL7c > svg {\n  margin: 2px 8px 0 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/integrationLayout/syncSettingsTab/outbound/syncAccountSettings/createInboundMappings/createInboundMappings.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,yBAAyB;AAC3B;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,qBAAqB;AACvB","sourcesContent":["._container {\n  box-sizing: border-box;\n  padding: 0 32px 34px 32px;\n}\n._container > p:first-child {\n  margin-bottom: 16px;\n}\n\n._content {\n  box-sizing: border-box;\n  border: 1px solid #e6e6e6;\n  border-radius: 4px;\n  display: flex;\n  padding: 25px;\n  justify-content: space-between;\n  align-items: center;\n}\n._textArea {\n  display: flex;\n}\n._textArea > svg {\n  margin: 0 8px;\n}\n._direction {\n  display: flex;\n}\n._direction > svg {\n  margin: 2px 8px 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "createInboundMappings-module___container___8q9gN",
	"_content": "createInboundMappings-module___content___8j8Ty",
	"_textArea": "createInboundMappings-module___textArea___I-740",
	"_direction": "createInboundMappings-module___direction___DHL7c"
};
export default ___CSS_LOADER_EXPORT___;
