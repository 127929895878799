import { atom, useRecoilState } from 'recoil';

const openSendToMetaModalAtom = atom({
  key: 'openSendToMetaModalAtom',
  default: false,
});

export const useSendToMetaModalVisibility = () => {
  const [isOpen, setIsOpen] = useRecoilState(openSendToMetaModalAtom);

  return {
    isOpen,
    setIsOpen,
    openSendToMetaModal: () => setIsOpen(true),
    closeSendToMetaModal: () => setIsOpen(false),
  };
};
