import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Text, useToasts } from '@bloobirds-it/flamingo-ui';
import { UserSettings, PermissionType } from '@bloobirds-it/types';

import { useUserSettingsReload } from '../../../components/userPermissions/hooks';
import { ServiceApi } from '../../../misc/api/service';
import styles from './whatsappSettings.module.css';

const WhatsappSettings = ({ userSettings }: { userSettings: UserSettings }) => {
  const { autoSyncWhatsappPermission, id } = userSettings;
  const [enableAutoSync, setEnableAutoSync] = useState(
    autoSyncWhatsappPermission !== PermissionType.DISABLED,
  );
  const userSettingsReload = useUserSettingsReload();
  const { createToast } = useToasts();
  const { t } = useTranslation('translation', { keyPrefix: 'userSettingsWebapp.whatsappSettings' });

  const onChange = () => {
    setEnableAutoSync(!enableAutoSync);

    ServiceApi.request({
      url: `/service/users/${id}`,
      method: 'PATCH',
      body: {
        autoSyncWhatsappPermission: !enableAutoSync
          ? PermissionType.ENABLED
          : PermissionType.DISABLED,
      },
    })
      .then(() => {
        createToast({ type: 'success', message: t('toasts.success') });
        userSettingsReload();
      })
      .catch(() => {
        createToast({
          type: 'error',
          message: t('toasts.error'),
        });
      });
  };

  return (
    <div className={styles.box}>
      <div className={styles.container}>
        <Text size="m" color="softPeanut" htmlTag="span" weight="bold">
          {t('title')}
        </Text>
        <Text size="s" color="peanut" htmlTag="span">
          {t('subtitle')}
        </Text>
        <Checkbox
          onClick={onChange}
          checked={enableAutoSync}
          size="medium"
          disabled={autoSyncWhatsappPermission === PermissionType.FORCED}
          expand
        >
          {t('checkbox')}
        </Checkbox>
        {autoSyncWhatsappPermission === PermissionType.FORCED && (
          <Text size="s" color="peanut" htmlTag="span">
            {t('disableText')}
          </Text>
        )}
      </div>
    </div>
  );
};

export default WhatsappSettings;
