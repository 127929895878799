import { ColorType } from '@bloobirds-it/flamingo-ui';
import { TDateISO, TDateISODate, Bobject } from '@bloobirds-it/types';

export enum SearchType {
  leads,
  coworkers,
}

export interface Coworker {
  type: string;
  email: string;
  name: string;
  id: string;
}

export type EventsType = 'nylas' | 'bloobirds' | 'placeholder' | 'dragging' | 'pendingConfirmation';

export interface Invitee {
  email?: string;
  name?: string | null;
  type?: 'Lead' | 'AE' | 'Organizer' | 'Company' | 'User';
  comment?: string;
  status?: 'yes' | 'no' | 'noreply';
  leadId?: string;
}

export type ICalendarEvent = {
  duration: number;
  collisions: number;
  startTime: TDateISO;
  endTime: TDateISO;
  startTimeTimestamp: number;
  endTimeTimestamp: number;
  id: string;
  title: string;
  collisionNumber: number;
  day: TDateISODate;
  type: EventsType;
  participants: Invitee[];
  calendarId: string;
  backgroundColor?: ColorType;
  barColor?: ColorType;
  owner?: string;
};

export type EventsPerDay = {
  [key: string]: ICalendarEvent[];
};

export interface CalendarsWithColors {
  calendarId?: string;
  color?: ColorType;
  barColor?: ColorType;
}

export interface CalendarData {
  id: string;
  name: string;
  description?: any;
  location?: any;
  timezone: string;
  readOnly: boolean;
  primary: boolean;
  accountId: string;
  jobStatusId?: any;
}

export enum RemindeBeforeType {
  minutes = 'minutes',
  hours = 'hours',
  days = 'days',
}

export interface ReminderBefore {
  type: RemindeBeforeType;
  value: number;
}

export interface MinimizableBobject {
  name: string;
  stage: string;
  url: string;
  data: Bobject;
}

export interface FormDataInterface {
  company: Bobject | MinimizableBobject;
  lead: Bobject | MinimizableBobject;
  opportunity?: Bobject | MinimizableBobject;

  [key: string]: any;
}
