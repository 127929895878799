import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  CircularBadge,
  Icon,
  SearchInput,
  Skeleton,
  Text,
  Tooltip,
  useHover,
} from '@bloobirds-it/flamingo-ui';
import {
  useUserTeams,
  useTogglerState,
  TeamSortValues,
  usePreventWindowUnload,
} from '@bloobirds-it/hooks';
import { TeamMember, TeamModalModes, TeamType, UserTeamRole } from '@bloobirds-it/types';
import { AnimatePresence, motion, usePresence } from 'framer-motion';

import { useMediaQuery } from '../../../../../hooks';
import useDebounce from '../../../../../hooks/useDebounce';
import {
  AccountSettingsTab,
  AccountSettingsTabContent,
  AccountSettingsTabHeader,
  AccountSettingsTabHeaderLeft,
  AccountSettingsTabHeaderRight,
  AccountSettingsTabTitle,
} from '../../../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import { useTeamManagementModalVisibility } from './components/teamManagementModal/hooks/useTeamManagementModalVisibility';
import { TeamManagementModal } from './components/teamManagementModal/teamManagementModal';
import { TeamsFilters } from './components/teamsFilters/teamsFilters';
import styles from './teamsPage.module.css';

export const TeamsPage = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.salesTeam.teamUsersPage',
  });
  const [onlyMine, toggleOnlyMine] = useTogglerState();
  const [orderBy, setOrderBy] = useState<TeamSortValues>(TeamSortValues.FromAZ);

  const { teams, isLoadingTeams, mutate } = useUserTeams(onlyMine, orderBy);
  const {
    viewManagementModal,
    closeModal,
    openEditTeamModal,
    openCreateTeamModal,
  } = useTeamManagementModalVisibility();
  const { isSmallDesktop } = useMediaQuery();
  const [searchValue, setSearchValue] = useState<string>();
  const debounceSearchValue = useDebounce(searchValue, 200);

  const filteredTeams = debounceSearchValue
    ? teams?.filter((team: TeamType) =>
        team.name.toLowerCase().includes(debounceSearchValue?.toLowerCase()),
      )
    : teams;

  usePreventWindowUnload(viewManagementModal.mode !== TeamModalModes.Idle);

  return (
    <>
      <AccountSettingsTab>
        <AccountSettingsTabHeader>
          <AccountSettingsTabHeaderLeft>
            <AccountSettingsTabTitle icon="people">{t('title')}</AccountSettingsTabTitle>
            <Text size="s" color="softPeanut">
              {t('subtitle')}
            </Text>
          </AccountSettingsTabHeaderLeft>
          <AccountSettingsTabHeaderRight>
            <SearchInput
              width="200px"
              color="softBloobirds"
              placeholder={t('search')}
              value={searchValue}
              onChange={value => setSearchValue(value)}
            />
            <Button color="softBloobirds" iconLeft="plus" onClick={openCreateTeamModal}>
              {!isSmallDesktop && t('newTeamButton')}
            </Button>
          </AccountSettingsTabHeaderRight>
        </AccountSettingsTabHeader>
        <AccountSettingsTabContent>
          <TeamsFilters
            onlyMineState={[onlyMine, toggleOnlyMine]}
            orderByState={[orderBy, setOrderBy]}
          />
          <div className={styles.teamCardWrapper}>
            <div className={styles.teamCardContainer}>
              {isLoadingTeams ? (
                Array.from({ length: 5 }).map((_, index) => (
                  <Skeleton key={'skeleton' + index} height="138px" width="48%" variant="rect" />
                ))
              ) : (
                <AnimatePresence>
                  {filteredTeams?.map(team => (
                    <TeamCard
                      key={team.id}
                      team={team}
                      toggleModal={() => openEditTeamModal(team)}
                    />
                  ))}
                  <CreateTeams />
                </AnimatePresence>
              )}
            </div>
          </div>
        </AccountSettingsTabContent>
      </AccountSettingsTab>
      {viewManagementModal.mode !== TeamModalModes.Idle && (
        <TeamManagementModal
          team={viewManagementModal}
          toggleVisibility={closeModal}
          onSave={() => mutate()}
        />
      )}
    </>
  );
};

const CreateTeams = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.salesTeam.teamUsersPage.createTeams',
  });
  const { openCreateTeamModal } = useTeamManagementModalVisibility();

  return (
    <div className={styles.createTeamsContainer}>
      <Text size="l" color="softPeanut">
        {t('createNewTeams')}
      </Text>
      <Button
        uppercase={false}
        variant="secondary"
        color="softBloobirds"
        size="small"
        iconLeft="plus"
        onClick={openCreateTeamModal}
      >
        {t('newTeam')}
      </Button>
    </div>
  );
};

const TeamCard = ({ team, toggleModal }: { team: TeamType; toggleModal: () => void }) => {
  const [isPresent] = usePresence();
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.salesTeam.teamUsersPage.teamCard',
  });

  const animations = {
    style: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 32px',
      border: '1px solid var(--verySoftPeanut)',
      borderRadius: '8px',
      gap: '16px',
      boxShadow: '1px 1px 2px 0px var(--softPeanut})',
      width: 'calc(50% - 84px)',
    },
    initial: { scale: 0.9, opacity: 0.85 },
    animate: { scale: 1, opacity: 1 },
    exit: { opacity: 0 },
    transition: { type: 'linear' },
  };

  const managers = team?.teamUsers?.filter(
    (user: TeamMember) => user?.userRole === UserTeamRole.Manager,
  );
  const members = team?.teamUsers?.filter(
    (user: TeamMember) => user?.userRole === UserTeamRole.User,
  );

  return (
    isPresent && (
      // @ts-ignore
      <motion.div {...animations}>
        <header className={styles.teamCardHeaderWrapper}>
          <div className={styles.teamCardHeaderContainer}>
            <Icon name={team.icon} size={24} color="bloobirds" />
            <Text size="l">{team.name}</Text>
          </div>
          <div className={styles.manageButton} onClick={toggleModal}>
            <Icon name="edit" size={16} />
            <Text size="s" color="bloobirds">
              {t('manage')}
            </Text>
          </div>
        </header>
        <div className={styles.teamCardBody}>
          <CompanySection title={t('manager', { count: managers?.length ?? 0 })}>
            <UsersList users={managers ?? []} role={UserTeamRole.Manager} />
          </CompanySection>
          <CompanySection title={t('teamMembers', { count: members?.length ?? 0 })}>
            <UsersList users={members ?? []} role={UserTeamRole.User} />
          </CompanySection>
        </div>
      </motion.div>
    )
  );
};

const CompanySection = ({ title, children }: { title: string; children: any }) => {
  return (
    <div className={styles.companySection}>
      <Text size="s" color="softPeanut">
        {title}
      </Text>
      {children}
    </div>
  );
};

const UsersList = ({ users, role }: { users: TeamMember[]; role: UserTeamRole }) => {
  const [sectionWidth, setSectionWidth] = useState(0);
  const uniqueUser = users.length === 1;
  const displayableEntities = useMemo(() => Math.floor(sectionWidth / 30), [sectionWidth]);
  const [shownUsers, hiddenUsers] =
    users.length > displayableEntities
      ? [users.slice(0, displayableEntities), users.slice(displayableEntities)]
      : [users, undefined];
  const hiddenUsersNames = hiddenUsers && hiddenUsers.map(user => user.userName).join(', ');
  const [ref, isHovering] = useHover();
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.salesTeam.teamUsersPage.teamCard',
  });

  useEffect(() => {
    setSectionWidth(document.getElementById('circles-container')?.clientWidth);
  }, [document.getElementById('circles-container')?.clientWidth]);

  return (
    <div className={styles.container} id="circles-container" ref={ref}>
      {users?.length === 0 && (
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          {role === UserTeamRole.Manager && <Icon name="alertTriangle" color="softTomato" />}
          <Text size="s" color={role === UserTeamRole.User ? 'softPeanut' : 'softTomato'}>
            {role === UserTeamRole.User ? t('noUsers') : t('noManager')}
          </Text>
        </div>
      )}
      {shownUsers.map(user => (
        <UserDisplay key={user?.userId} user={user} isOnlyMember={uniqueUser} />
      ))}
      {hiddenUsers ? (
        hiddenUsers.length <= 1 ? (
          <UserDisplay user={hiddenUsers[0]} isOnlyMember={false} />
        ) : (
          <Tooltip title={hiddenUsersNames} position="top">
            <CircularBadge size="m" color="softBloobirds">
              {`+${users.length - displayableEntities}`}
            </CircularBadge>
          </Tooltip>
        )
      ) : null}
      {isHovering && !uniqueUser && users?.length > 0 && (
        <div className={styles.dropdownWrapper}>
          {users.map(user => (
            <UserCard key={user?.userId} user={user} />
          ))}
        </div>
      )}
    </div>
  );
};

const UserCard = ({ user }: { user: TeamMember }) => {
  return (
    <div className={styles.dropdownUserCard}>
      <CircularBadge
        size="m"
        backgroundColor={user.userColor}
        color="white"
        className={styles.circularBadgeContainer}
      >
        {/* @ts-ignore */}
        {user?.userShortName ?? <Icon name="person" size={16} color="white" />}
      </CircularBadge>
      <section>
        <Text size="s">{user.userName}</Text>
        <Text size="xs" color="softPeanut">
          {user.userEmail}
        </Text>
      </section>
    </div>
  );
};

const UserDisplay = ({ user, isOnlyMember }: { user: TeamMember; isOnlyMember: boolean }) => {
  return (
    <div className={styles.userDisplayContainer}>
      <CircularBadge size={isOnlyMember ? 'l' : 'm'} backgroundColor={user.userColor} color="white">
        {/* @ts-ignore */}
        {user?.userShortName ?? <Icon name="person" size={isOnlyMember ? 24 : 16} color="white" />}
      </CircularBadge>
      {isOnlyMember && (
        <div className={styles.userDisplayContent}>
          <Text size="m">{user.userName}</Text>
          <Text color="softPeanut" size="xs">
            {user.userEmail}
          </Text>
        </div>
      )}
    </div>
  );
};
