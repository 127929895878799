// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".typeList-module___container___HqMfy {\n  padding: 29px 40px;\n}\n\n.typeList-module___cancel_button___sNVBl {\n  margin-top: 30px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectTable/createListModal/typeList/typeList.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["._container {\n  padding: 29px 40px;\n}\n\n._cancel_button {\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "typeList-module___container___HqMfy",
	"_cancel_button": "typeList-module___cancel_button___sNVBl"
};
export default ___CSS_LOADER_EXPORT___;
