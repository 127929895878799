import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@bloobirds-it/flamingo-ui';

import styles from '../../../styles/phoneNumberPage.module.css';

export const NoPhonesRequested = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.noPhonesRequested}>
      <Text align="center" size="s" weight="medium" color="softPeanut">
        {t('accountSettings.phoneNumbers.purchaseNewPhone.orderReview.noPhonesRequested')}
      </Text>
    </div>
  );
};
