// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".createListModal-module___container___tNee7 {\n  width: 504px;\n  background: var(--white);\n  box-shadow: 0 2px 6px rgba(70, 79, 87, 0.403136);\n  border-radius: 4px;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  position: absolute;\n}\n\n.createListModal-module___container___tNee7:focus {\n  outline: none;\n}\n\n.createListModal-module___header___Yazl0 {\n  height: 88px;\n  background: var(--veryLightBloobirds);\n  border-radius: 4px 4px 0 0;\n  display: flex;\n  align-items: center;\n}\n\n.createListModal-module___title___ntifj {\n  margin-left: 40px;\n  align-items: center;\n  color: var(--peanut);\n  flex: 7 1;\n}\n\n.createListModal-module___close_button___lSFxg {\n  flex: 1 1;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectTable/createListModal/createListModal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,wBAAwB;EACxB,gDAAgD;EAChD,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,qCAAqC;EACrC,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,oBAAoB;EACpB,SAAS;AACX;;AAEA;EACE,SAAS;AACX","sourcesContent":["._container {\n  width: 504px;\n  background: var(--white);\n  box-shadow: 0 2px 6px rgba(70, 79, 87, 0.403136);\n  border-radius: 4px;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  position: absolute;\n}\n\n._container:focus {\n  outline: none;\n}\n\n._header {\n  height: 88px;\n  background: var(--veryLightBloobirds);\n  border-radius: 4px 4px 0 0;\n  display: flex;\n  align-items: center;\n}\n\n._title {\n  margin-left: 40px;\n  align-items: center;\n  color: var(--peanut);\n  flex: 7 1;\n}\n\n._close_button {\n  flex: 1 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "createListModal-module___container___tNee7",
	"_header": "createListModal-module___header___Yazl0",
	"_title": "createListModal-module___title___ntifj",
	"_close_button": "createListModal-module___close_button___lSFxg"
};
export default ___CSS_LOADER_EXPORT___;
