import { IconType } from '@bloobirds-it/flamingo-ui';
import { TeamModalModes, TeamModalProps, TeamType } from '@bloobirds-it/types';
import { atom, useRecoilState } from 'recoil';

function parseTeam(team: TeamType): TeamModalProps {
  return {
    ...team,
    mode: TeamModalModes.Edition,
  };
}

const teamManagementModalVisibilityAtom = atom({
  key: 'teamManagementModalVisibilityAtom',
  default: {
    name: '',
    icon: 'bloobirds' as IconType,
    teamUsers: [],
    mode: TeamModalModes.Idle,
    timezone: undefined,
    id: '',
  },
});

export const useTeamManagementModalVisibility = () => {
  const modalDefaultValues = (teamModalModes: TeamModalModes) =>
    ({
      name: '',
      icon: 'bloobirds' as IconType,
      teamUsers: [],
      mode: teamModalModes,
      timezone: undefined,
      id: '',
    } as TeamModalProps);

  const [viewManagementModal, setManagementModal] = useRecoilState(
    teamManagementModalVisibilityAtom,
  );

  function closeModal() {
    setManagementModal(modalDefaultValues(TeamModalModes.Idle));
  }

  function openCreateTeamModal() {
    setManagementModal(modalDefaultValues(TeamModalModes.Create));
  }

  function openEditTeamModal(team: TeamType) {
    setManagementModal(parseTeam(team));
  }

  return {
    viewManagementModal,
    openCreateTeamModal,
    openEditTeamModal,
    closeModal,
  };
};
