// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".passwordChangeForm-module__content___7vELz {\n  width: 400px;\n  margin: 40px auto 0;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.passwordChangeForm-module__passwordInputs___UO34R {\n  margin-top: 16px;\n}\n\n.passwordChangeForm-module__passwordInputs___UO34R > *:first-child {\n  margin-bottom: 16px;\n}\n\n.passwordChangeForm-module__saveButton___1ARPx {\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/userSettingsPages/passwordSettings/passwordChangeForm/passwordChangeForm.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".content {\n  width: 400px;\n  margin: 40px auto 0;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.passwordInputs {\n  margin-top: 16px;\n}\n\n.passwordInputs > *:first-child {\n  margin-bottom: 16px;\n}\n\n.saveButton {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "passwordChangeForm-module__content___7vELz",
	"passwordInputs": "passwordChangeForm-module__passwordInputs___UO34R",
	"saveButton": "passwordChangeForm-module__saveButton___1ARPx"
};
export default ___CSS_LOADER_EXPORT___;
