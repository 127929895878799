// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".autoCompleteSearchLeads-module___item_wrapper___lYAOm {\n  width: 520px;\n  max-height: 200px;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.autoCompleteSearchLeads-module___relative___ix-Ov {\n  width: 100%;\n  position: relative;\n}\n\n.autoCompleteSearchLeads-module___lead__info___DzkX0 {\n  margin-left: 8px;\n}\n\n.autoCompleteSearchLeads-module___lead__company___FX6T1 {\n  display: flex;\n  align-items: center;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.autoCompleteSearchLeads-module___lead__company___FX6T1 > * {\n  margin-right: 4px;\n}\n\n.autoCompleteSearchLeads-module___company__icon___iByMd {\n  flex-shrink: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/autoCompleteSearchLeads/autoCompleteSearchLeads.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["._item_wrapper {\n  width: 520px;\n  max-height: 200px;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n._relative {\n  width: 100%;\n  position: relative;\n}\n\n._lead__info {\n  margin-left: 8px;\n}\n\n._lead__company {\n  display: flex;\n  align-items: center;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n._lead__company > * {\n  margin-right: 4px;\n}\n\n._company__icon {\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_item_wrapper": "autoCompleteSearchLeads-module___item_wrapper___lYAOm",
	"_relative": "autoCompleteSearchLeads-module___relative___ix-Ov",
	"_lead__info": "autoCompleteSearchLeads-module___lead__info___DzkX0",
	"_lead__company": "autoCompleteSearchLeads-module___lead__company___FX6T1",
	"_company__icon": "autoCompleteSearchLeads-module___company__icon___iByMd"
};
export default ___CSS_LOADER_EXPORT___;
