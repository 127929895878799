import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IconButton, Item, Select, Text } from '@bloobirds-it/flamingo-ui';
import { useUserSearch } from '@bloobirds-it/hooks';
import { TeamMember, UserTeamRole } from '@bloobirds-it/types';

import styles from '../teamManagement.module.css';

export const UserCreationRow = ({
  rowId,
  newTeamUsersValue,
  newTeamUsersOnChange,
  remove,
}: {
  rowId: string;
  newTeamUsersValue: Record<string, { userRole: string; userId: string }>;
  newTeamUsersOnChange: (value: any) => void;
  remove: () => void;
}) => {
  const { users } = useUserSearch();
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.salesTeam.teamUsersPage.teamManagementModal.userCreationRow',
  });
  const { getValues } = useFormContext();
  const { teamUsers } = getValues() || {};

  const hasUserSelected = useMemo(() => !!newTeamUsersValue?.[rowId]?.userId, [newTeamUsersValue]);
  function handleReset() {
    if (hasUserSelected) {
      newTeamUsersOnChange({ ...newTeamUsersValue, [rowId]: {} });
    } else {
      const cleanedObject = newTeamUsersValue;
      delete cleanedObject[rowId];
      newTeamUsersOnChange(cleanedObject);
      remove?.();
    }
  }
  function changeRowValue(newValue: Record<string, string>) {
    newTeamUsersOnChange({
      ...newTeamUsersValue,
      [rowId]: { ...newTeamUsersValue?.[rowId], ...newValue },
    });
  }

  const filteredUsers = users?.filter(
    u =>
      !Object.values(newTeamUsersValue)?.find(selectedUser => selectedUser?.userId === u?.id) &&
      !teamUsers?.find((su: TeamMember) => su?.userId === u?.id),
  );

  return (
    <div className={styles.createMemberInfo} style={{ position: 'relative' }}>
      <Select
        value={newTeamUsersValue?.[rowId]?.userId || ''}
        onChange={internalValue => {
          changeRowValue({ userId: internalValue });
        }}
        placeholder={hasUserSelected ? undefined : t('searchUsers')}
        autocomplete
        width="100%"
        renderDisplayValue={value => users?.find(u => u?.id === value)?.name}
      >
        {filteredUsers?.map(user => (
          <Item key={user?.id} label={user?.name + '-' + user?.email} value={user?.id}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Text size="s" weight="bold">
                {user.name}
              </Text>
              <Text size="s" color="softPeanut">
                {user.email}
              </Text>
            </div>
          </Item>
        ))}
      </Select>
      <div className={styles.userSelect}>
        <Select
          placeholder={newTeamUsersValue?.[rowId]?.userRole ? undefined : t('setRole')}
          width="164px"
          value={newTeamUsersValue?.[rowId]?.userRole || ''}
          onChange={internalValue => changeRowValue({ userRole: internalValue })}
        >
          <Item value={UserTeamRole.Manager}>{t('manager')}</Item>
          <Item value={UserTeamRole.User}>{t('user')}</Item>
        </Select>
        <IconButton
          name={hasUserSelected ? 'redoReload' : 'trashFull'}
          size={18}
          color="softPeanut"
          onClick={handleReset}
        />
      </div>
    </div>
  );
};
