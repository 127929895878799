import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  Button,
  Spinner,
  Text,
} from '@bloobirds-it/flamingo-ui';

import styles from '../playgroundPages.module.css';

export const ConfirmationDeleteModal = ({
  onConfirm,
  onClose,
  title,
  description,
  question,
  subtitle,
  loading,
}: {
  onConfirm: () => void;
  onClose: () => void;
  title: string;
  description: string;
  question: string;
  subtitle?: string;
  loading?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Modal width={600} open onClose={onClose}>
      <ModalHeader variant="primary" size="small" color="lightestPurple">
        <ModalTitle size="small" icon="trashFull">
          {t(title)}
        </ModalTitle>
        <ModalCloseIcon color="black" size="small" onClick={onClose} />
      </ModalHeader>
      <ModalContent className={styles.delete_test_set_modalContent}>
        {subtitle && (
          <Text size="m" align="center">
            <Trans t={t} i18nKey={subtitle} />
          </Text>
        )}
        <Text size="m" align="center">
          {t(description)}
        </Text>
        <Text size="m" align="center">
          <b>{t(question)}</b>
        </Text>
      </ModalContent>
      <ModalFooter>
        <Button variant="tertiary" onClick={onClose} color="softPurple">
          {t('common.back')}
        </Button>
        <Button variant="primary" color="tomato" onClick={onConfirm} disabled={loading}>
          {loading ? <Spinner name="loadingCircle" size={10} color="tomato" /> : t('common.delete')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
