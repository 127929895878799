import React from 'react';

import { Text } from '@bloobirds-it/flamingo-ui';
import { GeneralPrompt } from '@bloobirds-it/types';
import clsx from 'clsx';

import styles from '../../playgroundPages.module.css';
import { IGeneralPromptsColumn } from './generalPromptsColumn';

interface IGeneralPropsColumnCardProps extends IGeneralPromptsColumn {
  prompt: GeneralPrompt;
}

export const GeneralPromptsColumnCard = ({
  prompt,
  selectedPrompt,
  setSelectedPrompt,
}: IGeneralPropsColumnCardProps) => {
  return (
    <div
      className={clsx(styles.generalPromptCard, {
        [styles.generalPromptCardActive]: prompt.id === selectedPrompt?.id,
      })}
      onClick={() => setSelectedPrompt(prompt)}
    >
      <Text color="peanut" size="s" weight="bold" className={styles.generalPromptCardTitle}>
        {prompt.promptType.replace(/_/g, ' ').toLocaleLowerCase()}
      </Text>
    </div>
  );
};
