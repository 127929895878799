// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".playbookSegmentation-module___container___SGjCF {\n  padding: 48px 40px;\n}\n\n.playbookSegmentation-module___container___SGjCF > *:first-child {\n  margin-bottom: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/playbookPages/playbookSegmentationPage/playbookSegmentation.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["._container {\n  padding: 48px 40px;\n}\n\n._container > *:first-child {\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "playbookSegmentation-module___container___SGjCF"
};
export default ___CSS_LOADER_EXPORT___;
