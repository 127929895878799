// If it ends or starts with low bar it means it has a dynamic prop in it
export const MIXPANEL_EVENTS = Object.freeze({
  // Global Search bar
  GLOBAL_SEARCH_BAR_SEARCHED: 'GLOBAL_SEARCH_BAR_SEARCHED',
  GLOBAL_SEARCH_BAR_CHANGED_TYPE_FILTER: 'GLOBAL_SEARCH_BAR_CHANGED_TYPE_FILTER',
  GLOBAL_SEARCH_BAR_RESULT_CLICKED: 'GLOBAL_SEARCH_BAR_RESULT_CLICKED',
  GLOBAL_SEARCH_BAR_QUICK_ACTION_CLICKED: 'GLOBAL_SEARCH_BAR_QUICK_ACTION_CLICKED',
  // Start task in prospect subhome
  TASK_STARTED_IN_: 'TASK_STARTED_IN_',
  // Inbox call section
  ASSIGN_LEAD_PHONE_MODAL: 'ASSIGN_LEAD_PHONE_MODAL',
  ASSIGN_LEAD_PHONE_MODAL_LEAD_CREATED: 'ASSIGN_LEAD_PHONE_MODAL_LEAD_CREATED',
  ASSIGN_LEAD_PHONE_MODAL_LEAD_ASSIGNED: 'ASSIGN_LEAD_PHONE_MODAL_LEAD_ASSIGNED',
  CALL_MARKED_ALL_AS_READ_FROM_INBOX: 'CALL_MARKED_ALL_AS_READ_FROM_INBOX',
  CALL_REPORTED_RESULT_FROM_INBOX: 'CALL_REPORTED_RESULT_FROM_INBOX',
  // Inbox email section
  EMAIL_MARKED_AS_READ_FROM_INBOX: 'EMAIL_MARKED_AS_READ_FROM_INBOX',
  EMAIL_REPORTED_RESULT_FROM_INBOX: 'EMAIL_REPORTED_RESULT_FROM_INBOX',
  EMAIL_REPLIED_FROM_INBOX: 'EMAIL_REPLIED_FROM_INBOX',
  EMAIL_SENT_ANOTHER_FROM_INBOX: 'EMAIL_SENT_ANOTHER_FROM_INBOX',
  EMAIL_MARKED_ALL_AS_READ_FROM_INBOX: 'EMAIL_MARKED_ALL_AS_READ_FROM_INBOX',
  // Outbox - Automated section
  OUTBOX_SEND_NOW_ACTION_CLICKED_ON_AUTOMATED_TAB:
    'OUTBOX_SEND_NOW_ACTION_CLICKED_ON_AUTOMATED_TAB',
  OUTBOX_RETRY_ACTION_CLICKED_ON_AUTOMATED_TAB: 'OUTBOX_RETRY_ACTION_CLICKED_ON_AUTOMATED_TAB',
  OUTBOX_STOP_CADENCE_ACTION_CLICKED_ON_AUTOMATED_TAB:
    'OUTBOX_STOP_CADENCE_ACTION_CLICKED_ON_AUTOMATED_TAB',
  OUTBOX_CANCEL_EMAIL_ACTION_CLICKED_ON_AUTOMATED_TAB:
    'OUTBOX_CANCEL_EMAIL_ACTION_CLICKED_ON_AUTOMATED_TAB',
  OUTBOX_RESCHEDULED_ACTION_CLICKED_ON_AUTOMATED_TAB:
    'OUTBOX_RESCHEDULED_ACTION_CLICKED_ON_AUTOMATED_TAB',
  // Bulk
  OUTBOX_STOP_CADENCE_BULK_ACTION_CLICKED_ON_AUTOMATED_TAB:
    'OUTBOX_STOP_CADENCE_BULK_ACTION_CLICKED_ON_AUTOMATED_TAB',
  OUTBOX_RESCHEDULE_BULK_ACTION_CLICKED_ON_AUTOMATED_TAB:
    'OUTBOX_RESCHEDULE_BULK_ACTION_CLICKED_ON_AUTOMATED_TAB',
  OUTBOX_SEND_NOW_BULK_ACTION_CLICKED_ON_AUTOMATED_TAB:
    'OUTBOX_SEND_NOW_BULK_ACTION_CLICKED_ON_AUTOMATED_TAB',
  OUTBOX_CANCEL_EMAIL_BULK_ACTION_CLICKED_ON_AUTOMATED_TAB:
    'OUTBOX_CANCEL_EMAIL_BULK_ACTION_CLICKED_ON_AUTOMATED_TAB',
  // Outbox - Schedule section
  OUTBOX_SEND_NOW_ACTION_CLICKED_ON_SCHEDULED_TAB:
    'OUTBOX_SEND_NOW_ACTION_CLICKED_ON_SCHEDULED_TAB',
  OUTBOX_RETRY_ACTION_CLICKED_ON_SCHEDULED_TAB: 'OUTBOX_RETRY_ACTION_CLICKED_ON_SCHEDULED_TAB',
  // Stats prospect subhome
  STATS_TIME_WINDOW_CHANGED_COMPANY_DELIVERED_TAB:
    'STATS_TIME_WINDOW_CHANGED_COMPANY_DELIVERED_TAB',
  STATS_TIME_WINDOW_CHANGED_LEAD_DELIVERED_TAB: 'STATS_TIME_WINDOW_CHANGED_LEAD_DELIVERED_TAB',
  STATS_TIME_WINDOW_CHANGED_ON_CADENCE_TAB: 'STATS_TIME_WINDOW_CHANGED_ON_CADENCE_TAB',
  STATS_TIME_WINDOW_CHANGED_SCHEDULED_TAB: 'STATS_TIME_WINDOW_CHANGED_SCHEDULED_TAB',
  STATS_TIME_WINDOW_CHANGED_MEETING_TAB: 'STATS_TIME_WINDOW_CHANGED_MEETING_TAB',
  // Stats sales subhome
  STATS_TIME_WINDOW_CHANGED_ALL_OPPORTUNITIES_TAB:
    'STATS_TIME_WINDOW_CHANGED_ALL_OPPORTUNITIES_TAB',
  // Filters in prospect subhome
  _QUICK_FILTER_ADDED_IN_: '_QUICK_FILTER_ADDED_IN_',
  FILTERS_CHANGED_IN_: 'FILTERS_CHANGED_IN_',
  // Delivered tab quick actions
  ADD_LEAD_ACTION_CLICKED_ON_DELIVERED_TAB: 'ADD_LEAD_ACTION_CLICKED_ON_DELIVERED_TAB',
  QUICK_START_ACTION_CLICKED_ON_DELIVERED_TAB: 'QUICK_START_ACTION_CLICKED_ON_DELIVERED_TAB',
  QUICK_START_LEAD_ACTION_CLICKED_ON_DELIVERED_TAB:
    'QUICK_START_LEAD_ACTION_CLICKED_ON_DELIVERED_TAB',
  COMPANY_SET_CADENCE_ACTION_CLICKED_ON_DELIVERED_TAB:
    'COMPANY_SET_CADENCE_ACTION_CLICKED_ON_DELIVERED_TAB',
  LEAD_SET_CADENCE_ACTION_CLICKED_ON_DELIVERED_TAB:
    'LEAD_SET_CADENCE_ACTION_CLICKED_ON_DELIVERED_TAB',
  LEAD_SET_CADENCE_BULK_ACTION_CLICKED_ON_DELIVERED_TAB:
    'LEAD_SET_CADENCE_BULK_ACTION_CLICKED_ON_DELIVERED_TAB',
  REASSIGN_ACTION_CLICKED_ON_DELIVERED_TAB: 'REASSIGN_ACTION_CLICKED_ON_DELIVERED_TAB',
  CHANGE_STATUS_ACTION_CLICKED_ON_DELIVERED_TAB: 'CHANGE_STATUS_ACTION_CLICKED_ON_DELIVERED_TAB',
  QUICK_START_BULK_ACTION_CLICKED_ON_DELIVERED_TAB:
    'QUICK_START_BULK_ACTION_CLICKED_ON_DELIVERED_TAB',
  QUICK_START_LEAD_BULK_ACTION_CLICKED_ON_DELIVERED_TAB:
    'QUICK_START_LEAD_BULK_ACTION_CLICKED_ON_DELIVERED_TAB',
  COMPANY_SET_CADENCE_BULK_ACTION_CLICKED_ON_DELIVERED_TAB:
    'COMPANY_SET_CADENCE_BULK_ACTION_CLICKED_ON_DELIVERED_TAB',
  REASSIGN_BULK_ACTION_CLICKED_ON_DELIVERED_TAB: 'REASSIGN_BULK_ACTION_CLICKED_ON_DELIVERED_TAB',
  CHANGE_STATUS_BULK__ACTION_CHANGE_STATUS_ON_MY_OPPS_TAB:
    'CHANGE_STATUS_BULK__ACTION_CHANGE_STATUS_ON_MY_OPPS_TAB',
  CHANGE_STATUS_BULK__ACTION_CLICKED_ON_MY_COMPANIES_TAB:
    'CHANGE_STATUS_BULK__ACTION_CLICKED_ON_MY_COMPANIES_TAB',
  CHANGE_STATUS_BULK__ACTION_CLICKED_ON_DELIVERED_TAB:
    'CHANGE_STATUS_BULK__ACTION_CLICKED_ON_DELIVERED_TAB',
  DISCARD_LEAD_ACTION_CLICKED_ON_DELIVERED_TAB: 'DISCARD_LEAD_ACTION_CLICKED_ON_DELIVERED_TAB',
  DISCARD_LEAD_BULK_ACTION_CLICKED_ON_DELIVERED_TAB:
    'DISCARD_LEAD_BULK_ACTION_CLICKED_ON_DELIVERED_TAB',
  ASSIGN_COMPANY_ACTION_CLICKED_ON_DELIVERED_TAB: 'ASSIGN_COMPANY_ACTION_CLICKED_ON_DELIVERED_TAB',
  ASSIGN_COMPANY_BULK_ACTION_CLICKED_ON_DELIVERED_TAB: 'ASSIGN_COMPANY_CLICKED_ON_DELIVERED_TAB',
  REASSIGN_LEAD_ACTION_CLICKED_ON_DELIVERED_TAB: 'REASSIGN_LEAD_ACTION_CLICKED_ON_DELIVERED_TAB',
  CHANGE_LEAD_STATUS_ACTION_CLICKED_ON_DELIVERED_TAB:
    'CHANGE_LEAD_STATUS_ACTION_CLICKED_ON_DELIVERED_TAB',
  // On cadence tab quick actions
  MARK_AS_DONE_ACTION_CLICKED_ON_CADENCE_TAB: 'MARK_AS_DONE_ACTION_CLICKED_ON_CADENCE_TAB',
  MARK_AS_DONE_BULK_ACTION_CLICKED_ON_CADENCE_TAB:
    'MARK_AS_DONE_BULK_ACTION_CLICKED_ON_CADENCE_TAB',
  STOP_CADENCE_BULK_ACTION_CLICKED_ON_CADENCE_TAB:
    'STOP_CADENCE_BULK_ACTION_CLICKED_ON_CADENCE_TAB',
  SET_CADENCE_BULK_ACTION_CLICKED_ON_CADENCE_TAB: 'SET_CADENCE_BULK_ACTION_CLICKED_ON_CADENCE_TAB',
  RESCHEDULE_CADENCE_BULK_ACTION_CLICKED_ON_CADENCE_TAB:
    'RESCHEDULE_CADENCE_BULK_ACTION_CLICKED_ON_CADENCE_TAB',
  // Sales tab > Nurturing
  MARK_AS_DONE_ACTION_CLICKED_ON_NURTURING_PROSPECTING_TAB:
    'MARK_AS_DONE_ACTION_CLICKED_ON_NURTURING_PROSPECTING_TAB',
  MARK_AS_DONE_BULK_ACTION_CLICKED_ON_NURTURING_PROSPECTING_TAB:
    'MARK_AS_DONE_BULK_ACTION_CLICKED_ON_NURTURING_PROSPECTING_TAB',
  SET_CADENCE_ACTION_CLICKED_ON_NURTURING_PROSPECTING_TAB:
    'SET_CADENCE_ACTION_CLICKED_ON_NURTURING_PROSPECTING_TAB',
  SET_CADENCE_BULK_ACTION_CLICKED_ON_NURTURING_PROSPECTING_TAB:
    'SET_CADENCE_BULK_ACTION_CLICKED_ON_NURTURING_PROSPECTING_TAB',
  STOP_CADENCE_ACTION_CLICKED_ON_NURTURING_PROSPECTING_TAB:
    'STOP_CADENCE_ACTION_CLICKED_ON_NURTURING_PROSPECTING_TAB',
  STOP_CADENCE_BULK_ACTION_CLICKED_ON_NURTURING_PROSPECTING_TAB:
    'STOP_CADENCE_BULK_ACTION_CLICKED_ON_NURTURING_PROSPECTING_TAB',
  RESHEDULE_ACTION_CLICKED_ON_NURTURING_PROSPECTING_TAB:
    'RESHEDULE_ACTION_CLICKED_ON_NURTURING_PROSPECTING_TAB',
  RESHEDULE_BULK_ACTION_CLICKED_ON_NURTURING_PROSPECTING_TAB:
    'RESHEDULE_BULK_ACTION_CLICKED_ON_NURTURING_PROSPECTING_TAB',
  // Scheduled tab quick actions
  MARK_AS_DONE_ACTION_CLICKED_ON_SCHEDULED_TAB: 'MARK_AS_DONE_ACTION_CLICKED_ON_SCHEDULED_TAB',
  RESCHEDULE_ACTION_CLICKED_ON_SCHEDULED_TAB: 'RESCHEDULE_ACTION_CLICKED_ON_SCHEDULED_TAB',
  MARK_AS_DONE_BULK_ACTION_CLICKED_ON_SCHEDULED_TAB:
    'MARK_AS_DONE_BULK_ACTION_CLICKED_ON_SCHEDULED_TAB',
  RESCHEDULE_BULK_ACTION_CLICKED_ON_SCHEDULED_TAB:
    'RESCHEDULE_BULK_ACTION_CLICKED_ON_SCHEDULED_TAB',
  MARK_AS_DONE_BULK_ACTION_CONFIRMED_ON_: 'MARK_AS_DONE_BULK_ACTION_CONFIRMED_ON_',
  RESCHEDULE_ACTION_CONFIRMED_ON_SCHEDULE_TAB: 'RESCHEDULE_ACTION_CONFIRMED_ON_SCHEDULE_TAB',
  RESCHEDULE_BULK_ACTION_CONFIRMED_ON_SCHEDULED_TAB:
    'RESCHEDULE_BULK_ACTION_CONFIRMED_ON_SCHEDULED_TAB',
  // Meeting tab quick actions
  MARK_AS_DONE_ACTION_CLICKED_ON_MEETING_TAB: 'MARK_AS_DONE_ACTION_CLICKED_ON_MEETING_TAB',
  MARK_AS_DONE_BULK_ACTION_CLICKED_ON_MEETING_TAB:
    'MARK_AS_DONE_BULK_ACTION_CLICKED_ON_MEETING_TAB',
  SET_CADENCE_ACTION_CLICKED_ON_MEETING_TAB: 'SET_CADENCE_ACTION_CLICKED_ON_MEETING_TAB',
  SET_CADENCE_BULK_ACTION_CLICKED_ON_MEETING_TAB: 'SET_CADENCE_BULK_ACTION_CLICKED_ON_MEETING_TAB',
  // Inactive tab quick actions (Prospecting)
  ADD_LEAD_ACTION_CLICKED_ON_INACTIVE_PROSPECTING_TAB:
    'ADD_LEAD_ACTION_CLICKED_ON_INACTIVE_PROSPECTING_TAB',
  SET_CADENCE_ACTION_CLICKED_ON_INACTIVE_PROSPECTING_TAB:
    'SET_CADENCE_ACTION_CLICKED_ON_INACTIVE_PROSPECTING_TAB',
  ADD_TASK_ACTION_CLICKED_ON_INACTIVE_PROSPECTING_TAB:
    'ADD_TASK_ACTION_CLICKED_ON_INACTIVE_PROSPECTING_TAB',
  NEXT_STEP_ACTION_CLICKED_ON_INACTIVE_PROSPECTING_COMPANY_TAB:
    'NEXT_STEP_ACTION_CLICKED_ON_INACTIVE_PROSPECTING_COMPANY_TAB',
  NEXT_STEP_ACTION_CLICKED_ON_INACTIVE_PROSPECTING_LEAD_TAB:
    'NEXT_STEP_ACTION_CLICKED_ON_INACTIVE_PROSPECTING_LEAD_TAB',
  NEXT_STEP_ACTION_CLICKED_ON_INACTIVE_SALES_COMPANY_TAB:
    'NEXT_STEP_ACTION_CLICKED_ON_INACTIVE_SALES_COMPANY_TAB',
  NEXT_STEP_ACTION_CLICKED_ON_INACTIVE_SALES_LEAD_TAB:
    'NEXT_STEP_ACTION_CLICKED_ON_INACTIVE_SALES_LEAD_TAB',
  REASSIGN_ACTION_CLICKED_ON_INACTIVE_PROSPECTING_TAB: 'REASSIGN_ACTION_CLICKED_ON_INACTIVE_TAB',
  CHANGE_STATUS_ACTION_CLICKED_ON_INACTIVE_PROSPECTING_TAB:
    'CHANGE_STATUS_ACTION_CLICKED_ON_INACTIVE_PROSPECTING_TAB',
  SET_CADENCE_BULK_ACTION_CLICKED_ON_INACTIVE_PROSPECTING_TAB:
    'SET_CADENCE_BULK_ACTION_CLICKED_ON_INACTIVE_PROSPECTING_TAB',
  REASSIGN_BULK_ACTION_CLICKED_ON_INACTIVE_PROSPECTING_TAB:
    'REASSIGN_BULK_ACTION_CLICKED_ON_INACTIVE_PROSPECTING_TAB',
  CHANGE_STATUS_BULK__ACTION_CLICKED_ON_INACTIVE_PROSPECTING_TAB:
    'CHANGE_STATUS_BULK__ACTION_CLICKED_ON_INACTIVE_PROSPECTING_TAB',
  // Inactive tab quick actions (Sales > Inactive > Companies )
  ADD_LEAD_ACTION_CLICKED_ON_INACTIVE_SALES_TAB: 'ADD_LEAD_ACTION_CLICKED_ON_INACTIVE_SALES_TAB',
  SET_CADENCE_ACTION_CLICKED_ON_INACTIVE_SALES_TAB:
    'SET_CADENCE_ACTION_CLICKED_ON_INACTIVE_SALES_TAB',
  ADD_TASK_ACTION_CLICKED_ON_INACTIVE_SALES_TAB: 'ADD_TASK_ACTION_CLICKED_ON_INACTIVE_SALES_TAB',
  REASSIGN_ACTION_CLICKED_ON_INACTIVE_SALES_TAB: 'REASSIGN_ACTION_CLICKED_ON_INACTIVE_SALES_TAB',
  NEXT_STEP_ACTION_CLICKED_ON_INACTIVE_COMPANY_SALES_TAB:
    'NEXT_STEP_ACTION_CLICKED_ON_INACTIVE_COMPANY_SALES_TAB',
  CHANGE_STATUS_ACTION_CLICKED_ON_INACTIVE_SALES_TAB:
    'CHANGE_STATUS_ACTION_CLICKED_ON_INACTIVE_SALES_TAB',
  SET_CADENCE_BULK_ACTION_CLICKED_ON_INACTIVE_SALES_TAB:
    'SET_CADENCE_BULK_ACTION_CLICKED_ON_INACTIVE_SALES_TAB',
  REASSIGN_BULK_ACTION_CLICKED_ON_INACTIVE_SALES_TAB:
    'REASSIGN_BULK_ACTION_CLICKED_ON_INACTIVE_SALES_TAB',
  CHANGE_STATUS_BULK__ACTION_CLICKED_ON_INACTIVE_SALES_TAB:
    'CHANGE_STATUS_BULK__ACTION_CLICKED_ON_INACTIVE_SALES_TAB',
  // All my companies quick actions
  ASSIGN_COMPANY_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_TAB:
    'ASSIGN_COMPANY_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_TAB',
  ADD_LEAD_ACTION_CLICKED_ON_MY_COMPANIES_TAB: 'ADD_LEAD_ACTION_CLICKED_ON_MY_COMPANIES_TAB',
  ADD_TASK_ACTION_CLICKED_ON_MY_COMPANIES_TAB: 'ADD_TASK_ACTION_CLICKED_ON_MY_COMPANIES_TAB',
  ADD_TASK_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_TAB:
    'ADD_TASK_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_TAB',
  QUICK_START_ACTION_CLICKED_ON_MY_COMPANIES_TAB: 'QUICK_START_ACTION_CLICKED_ON_MY_COMPANIES_TAB',
  SET_CADENCE_ACTION_CLICKED_ON_MY_COMPANIES_TAB: 'SET_CADENCE_ACTION_CLICKED_ON_MY_COMPANIES_TAB',
  SET_CADENCE_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_TAB:
    'SET_CADENCE_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_TAB',
  REASSIGN_ACTION_CLICKED_ON_MY_COMPANIES_TAB: 'REASSIGN_ACTION_CLICKED_ON_MY_COMPANIES_TAB',
  REASSIGN_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_TAB:
    'REASSIGN_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_TAB',
  CHANGE_STATUS_ACTION_CLICKED_ON_MY_COMPANIES_TAB:
    'CHANGE_STATUS_ACTION_CLICKED_ON_MY_COMPANIES_TAB',
  CHANGE_STATUS_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_TAB:
    'CHANGE_STATUS_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_TAB',
  QUICK_START_BULK_ACTION_CLICKED_ON_MY_COMPANIES_TAB:
    'QUICK_START_BULK_ACTION_CLICKED_ON_MY_COMPANIES_TAB',
  SET_CADENCE_BULK_ACTION_CLICKED_ON_MY_COMPANIES_TAB:
    'SET_CADENCE_BULK_ACTION_CLICKED_ON_MY_COMPANIES_TAB',
  STOP_CADENCE_BULK_ACTION_CLICKED_ON_MY_COMPANIES_TAB:
    'STOP_CADENCE_BULK_ACTION_CLICKED_ON_MY_COMPANIES_TAB',
  REASSIGN_BULK_ACTION_CLICKED_ON_MY_COMPANIES_TAB:
    'REASSIGN_BULK_ACTION_CLICKED_ON_MY_COMPANIES_TAB',
  // Change status modal
  CHANGED_STATUS_ON_: 'CHANGED_STATUS_ON_',
  CHANGED_STATUS_ON_COMPANY_VIEW: 'CHANGED_STATUS_ON_COMPANY_VIEW',
  CHANGED_STATUS_ON_LEAD_VIEW: 'CHANGED_STATUS_ON_LEAD_VIEW',
  CHANGED_STATUS_ON_OPPORTUNITY_VIEW: 'CHANGED_STATUS_ON_OPPORTUNITY_VIEW',
  BULK_CHANGED_STATUS_ON_OPPORTUNITY_VIEW: 'BULK_CHANGED_STATUS_ON_OPPORTUNITY_VIEW',
  // Company card status pill
  CHANGE_COMPANY_STATUS_MODAL_OPENED: 'CHANGE_COMPANY_STATUS_MODAL_OPENED',
  CHANGE_LEAD_STATUS_MODAL_OPENED: 'CHANGE_LEAD_STATUS_MODAL_OPENED',
  CHANGE_OPP_STATUS_MODAL_OPENED: 'CHANGE_OPP_STATUS_MODAL_OPENED',
  CADENCE_CREATED: 'CADENCE_CREATED',
  CADENCE_UPDATED: 'CADENCE_UPDATED',
  STEP_ADDED: 'STEP_ADDED',
  STEP_UPDATED: 'STEP_UPDATED',
  STEP_CLONED: 'STEP_CLONED',
  CADENCE_CLONED: 'CADENCE_CLONED',
  // Contact view:
  AIRCALL_DIALER_OPENED: 'AIRCALL_DIALER_OPENED',
  // Company view:
  RESCHEDULED_CADENCE_FOR_: 'RESCHEDULED_CADENCE_FOR_',
  // HOME:
  // Left content
  HOME_MARK_AS_DONE_ACTION_CLICKED_ON_SINGLE_CARD:
    'HOME_MARK_AS_DONE_ACTION_CLICKED_ON_SINGLE_CARD',
  HOME_TASKS_SELECTOR_CHANGED: 'HOME_TASKS_SELECTOR_CHANGED',
  HOME_TASKS_FILTERS_CHANGED: 'HOME_TASKS_FILTERS_CHANGED',
  HOME_STARTED_TASKS: 'HOME_STARTED_TASKS',
  // Center content
  HOME_CHANGED_TIMEFRAME_BLOCKS: 'HOME_CHANGED_TIMEFRAME_BLOCKS',
  HOME_REORDERED_BLOCKS: 'HOME_REORDERED_BLOCKS',
  HOME_CHANGED_FILTERS_BLOCKS: 'HOME_CHANGED_FILTERS_BLOCKS',
  // Right Content
  HOME_CHANGED_NOTIFICATION_FILTERS: 'HOME_CHANGED_NOTIFICATION_FILTERS',
  HOME_MARK_ALL_AS_READ: 'HOME_MARK_ALL_AS_READ',
  HOME_LOADED_MORE_NOTIFICATIONS: 'HOME_LOADED_MORE_NOTIFICATIONS',
  // Bobject details
  BOBJECT_DETAILS_OPENED_FOR_: 'BOBJECT_DETAILS_OPENED_FOR_',
  // Task feed
  RETRY_ACTION_CLICKED_ON_TASK_FEED: 'RETRY_ACTION_CLICKED_ON_TASK_FEED',
  SEND_NOW_ACTION_CLICKED_ON_TASK_FEED: 'SEND_NOW_ACTION_CLICKED_ON_TASK_FEED',
  RESCHEDULED_ACTION_CLICKED_ON_TASK_FEED: 'RESCHEDULED_ACTION_CLICKED_ON_TASK_FEED',
  RESCHEDULE_CADENCE_ACTION_CLICKED_ON_TASK_FEED: 'RESCHEDULE_CADENCE_ACTION_CLICKED_ON_TASK_FEED',
  CANCEL_EMAIL_ACTION_CLICKED_ON_TASK_FEED: 'CANCEL_EMAIL_ACTION_CLICKED_ON_TASK_FEED',
  // Workflows
  CONFIGURE_NEW_WORKFLOW: 'CONFIGURE_NEW_WORKFLOW',
  WORKFLOW_CREATED: 'WORKFLOW_CREATED',
  WORKFLOW_UPDATE: 'WORKFLOW_UPDATE',
  WORKFLOW_CLICKED_ON_LOGS_PAGE: 'WORKFLOW_CLICKED_ON_LOGS_PAGE',
  WORKFLOW_CLICKED_ON_SETTINGS_PAGE: 'WORKFLOW_CLICKED_ON_SETTINGS_PAGE',
  WORKFLOW_ENABLED: 'WORKFLOW_ENABLED',
  WORKFLOW_DISABLED: 'WORKFLOW_DISABLED',
  WORKFLOW_CLONED: 'WORKFLOW_CLONED',
  // Parent companies
  PARENT_COMPANY_ADDED: 'PARENT_COMPANY_ADDED',
  CHILD_COMPANIES_ADDED: 'CHILD_COMPANIES_ADDED',
  // new Sales tab
  COMPANY_SET_CADENCE_ACTION_CLICKED_ON_COMPANIES_AND_LEADS_TAB:
    'COMPANY_SET_CADENCE_ACTION_CLICKED_ON_COMPANIES_AND_LEADS_TAB',
  RESCHEDULE_TASK_FROM_CARD: 'RESCHEDULE_TASK_FROM_CARD',
  SKIP_TASK_FROM_CARD: 'SKIP_TASK_FROM_CARD',
  MARK_AS_IMPORTANT_TASK_FROM_CARD: 'MARK_AS_IMPORTANT_TASK_FROM_CARD',
  UNMARK_AS_IMPORTANT_TASK_FROM_CARD: 'UNMARK_AS_IMPORTANT_TASK_FROM_CARD',
  RESCHEDULE_TASK_FROM_CADENCE: 'RESCHEDULE_TASK_FROM_CADENCE',
  // All My Opps > Kanban view
  ADD_TASK_ACTION_CLICKED_ON_ALL_MY_OPPS_KANBAN_TAB:
    'ADD_TASK_ACTION_CLICKED_ON_ALL_MY_OPPS_KANBAN_TAB',
  REASSIGN_ACTION_CLICKED_ON_ALL_MY_OPPS_KANBAN_TAB:
    'REASSIGN_ACTION_CLICKED_ON_ALL_MY_OPPS_KANBAN_TAB',
  CHANGE_STAGE_ACTION_CLICKED_ON_ALL_MY_OPPS_KANBAN_TAB:
    'CHANGE_STAGE_ACTION_CLICKED_ON_ALL_MY_OPPS_KANBAN_TAB',
  SET_CADENCE_ACTION_CLICKED_ON_ALL_MY_OPPS_KANBAN_TAB:
    'SET_CADENCE_ACTION_CLICKED_ON_ALL_MY_OPPS_KANBAN_TAB',
  // All My Opps
  ADD_LEAD_ACTION_CLICKED_ON_INACTIVE_ALL_MY_OPPS_TAB:
    'ADD_LEAD_ACTION_CLICKED_ON_INACTIVE_ALL_MY_OPPS_TAB',
  SET_CADENCE_ACTION_CLICKED_ON_INACTIVE_ALL_MY_OPPS_TAB:
    'SET_CADENCE_ACTION_CLICKED_ON_INACTIVE_ALL_MY_OPPS_TAB',
  ADD_TASK_ACTION_CLICKED_ON_INACTIVE_ALL_MY_OPPS_TAB: 'ADD_TASK_ACTION_CLICKED_ON_ALL_MY_OPPS_TAB',
  REASSIGN_ACTION_CLICKED_ON_INACTIVE_ALL_MY_OPPS_TAB:
    'REASSIGN_ACTION_CLICKED_ON_INACTIVE_ALL_MY_OPPS_TAB',
  CHANGE_STATUS_ACTION_CLICKED_ON_INACTIVE_ALL_MY_OPPS_TAB:
    'CHANGE_STATUS_ACTION_CLICKED_ON_INACTIVE_ALL_MY_OPPS_TAB',
  SET_CADENCE_BULK_ACTION_CLICKED_ON_INACTIVE_ALL_MY_OPPS_TAB:
    'SET_CADENCE_BULK_ACTION_CLICKED_ON_INACTIVE_ALL_MY_OPPS_TAB',
  REASSIGN_BULK_ACTION_CLICKED_ON_INACTIVE_ALL_MY_OPPS_TAB:
    'REASSIGN_BULK_ACTION_CLICKED_ON_INACTIVE_ALL_MY_OPPS_TAB',
  CHANGE_STATUS_BULK__ACTION_CLICKED_ON_INACTIVE_ALL_MY_OPPS_TAB:
    'CHANGE_STATUS_BULK__ACTION_CLICKED_ON_INACTIVE_ALL_MY_OPPS_TAB',
  // Sales tab > Companies & Sales
  ADD_TASK_ACTION_CLICKED_ON_MY_COMPANIES_SALES_TAB:
    'ADD_TASK_ACTION_CLICKED_ON_MY_COMPANIES_SALES_TAB',
  ADD_TASK_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_SALES_TAB:
    'ADD_TASK_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_SALES_TAB',
  SET_CADENCE_ACTION_CLICKED_ON_MY_COMPANIES_SALES_TAB:
    'SET_CADENCE_ACTION_CLICKED_ON_MY_COMPANIES_SALES_TAB',
  SET_CADENCE_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_SALES_TAB:
    'SET_CADENCE_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_SALES_TAB',
  REASSIGN_ACTION_CLICKED_ON_MY_COMPANIES_SALES_TAB:
    'REASSIGN_ACTION_CLICKED_ON_MY_COMPANIES_SALES_TAB',
  REASSIGN_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_SALES_TAB:
    'REASSIGN_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_SALES_TAB',
  CHANGE_STATUS_ACTION_CLICKED_ON_MY_COMPANIES_SALES_TAB:
    'CHANGE_STATUS_ACTION_CLICKED_ON_MY_COMPANIES_SALES_TAB',
  CHANGE_STATUS_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_SALES_TAB:
    'CHANGE_STATUS_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_SALES_TAB',
  QUICK_START_BULK_ACTION_CLICKED_ON_MY_COMPANIES_SALES_TAB:
    'QUICK_START_BULK_ACTION_CLICKED_ON_MY_COMPANIES_SALES_TAB',
  SET_CADENCE_BULK_ACTION_CLICKED_ON_MY_COMPANIES_SALES_TAB:
    'SET_CADENCE_BULK_ACTION_CLICKED_ON_MY_COMPANIES_SALES_TAB',
  REASSIGN_BULK_ACTION_CLICKED_ON_MY_COMPANIES_SALES_TAB:
    'REASSIGN_BULK_ACTION_CLICKED_ON_MY_COMPANIES_SALES_TAB',
  CHANGE_STATUS_BULK_ACTION_CLICKED_ON_MY_COMPANIES_SALES_TAB:
    'CHANGE_STATUS_BULK_ACTION_CLICKED_ON_MY_COMPANIES_SALES_TAB',
  CHANGE_STATUS_BULK_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_SALES_TAB:
    'CHANGE_STATUS_BULK_ACTION_CLICKED_ON_MY_COMPANIES_LEAD_SALES_TAB',
  // Sales tab > Follow up
  MARK_AS_DONE_ACTION_CLICKED_ON_FOLLOW_UP_TAB: 'MARK_AS_DONE_ACTION_CLICKED_ON_FOLLOW_UP_TAB',
  MARK_AS_DONE_BULK_ACTION_CLICKED_ON_FOLLOW_UP_TAB:
    'MARK_AS_DONE_BULK_ACTION_CLICKED_ON_FOLLOW_UP_TAB',
  SET_CADENCE_ACTION_CLICKED_ON_FOLLOW_UP_TAB: 'SET_CADENCE_ACTION_CLICKED_ON_FOLLOW_UP_TAB',
  SET_CADENCE_BULK_ACTION_CLICKED_ON_FOLLOW_UP_TAB:
    'SET_CADENCE_BULK_ACTION_CLICKED_ON_FOLLOW_UP_TAB',
  STOP_CADENCE_ACTION_CLICKED_ON_FOLLOW_UP_TAB: 'STOP_CADENCE_ACTION_CLICKED_ON_FOLLOW_UP_TAB',
  STOP_CADENCE_BULK_ACTION_CLICKED_ON_FOLLOW_UP_TAB:
    'STOP_CADENCE_BULK_ACTION_CLICKED_ON_FOLLOW_UP_TAB',
  RESHEDULE_ACTION_CLICKED_ON_FOLLOW_UP_TAB: 'RESHEDULE_ACTION_CLICKED_ON_FOLLOW_UP_TAB',
  RESHEDULE_BULK_ACTION_CLICKED_ON_FOLLOW_UP_TAB: 'RESHEDULE_BULK_ACTION_CLICKED_ON_FOLLOW_UP_TAB',
  // Sales tab > Nurturing
  MARK_AS_DONE_ACTION_CLICKED_ON_NURTURING_SALES_TAB:
    'MARK_AS_DONE_ACTION_CLICKED_ON_NURTURING_SALES_TAB',
  MARK_AS_DONE_BULK_ACTION_CLICKED_ON_NURTURING_SALES_TAB:
    'MARK_AS_DONE_BULK_ACTION_CLICKED_ON_NURTURING_SALES_TAB',
  SET_CADENCE_ACTION_CLICKED_ON_NURTURING_SALES_TAB:
    'SET_CADENCE_ACTION_CLICKED_ON_NURTURING_SALES_TAB',
  SET_CADENCE_BULK_ACTION_CLICKED_ON_NURTURING_SALES_TAB:
    'SET_CADENCE_BULK_ACTION_CLICKED_ON_NURTURING_SALES_TAB',
  STOP_CADENCE_ACTION_CLICKED_ON_NURTURING_SALES_TAB:
    'STOP_CADENCE_ACTION_CLICKED_ON_NURTURING_SALES_TAB',
  STOP_CADENCE_BULK_ACTION_CLICKED_ON_NURTURING_SALES_TAB:
    'STOP_CADENCE_BULK_ACTION_CLICKED_ON_NURTURING_SALES_TAB',
  RESHEDULE_ACTION_CLICKED_ON_NURTURING_SALES_TAB:
    'RESHEDULE_ACTION_CLICKED_ON_NURTURING_SALES_TAB',
  RESHEDULE_BULK_ACTION_CLICKED_ON_NURTURING_SALES_TAB:
    'RESHEDULE_BULK_ACTION_CLICKED_ON_NURTURING_SALES_TAB',
  // Inactive tab quick actions (Sales > Inactive > Leads )
  ADD_LEAD_ACTION_CLICKED_ON_INACTIVE_LEADS_SALES_TAB:
    'ADD_LEAD_ACTION_CLICKED_ON_INACTIVE_LEADS_SALES_TAB',
  NEXT_STEP_ACTION_CLICKED_ON_INACTIVE_LEAD_SALES_TAB:
    'NEXT_STEP_ACTION_CLICKED_ON_INACTIVE_LEAD_SALES_TAB',
  SET_CADENCE_ACTION_CLICKED_ON_INACTIVE_LEADS_SALES_TAB:
    'SET_CADENCE_ACTION_CLICKED_ON_INACTIVE_LEADS_SALES_TAB',
  ADD_TASK_ACTION_CLICKED_ON_INACTIVE_LEADS_SALES_TAB:
    'ADD_TASK_ACTION_CLICKED_ON_INACTIVE_LEADS_SALES_TAB',
  REASSIGN_ACTION_CLICKED_ON_INACTIVE_LEADS_SALES_TAB:
    'REASSIGN_ACTION_CLICKED_ON_INACTIVE_LEADS_SALES_TAB',
  CHANGE_STATUS_ACTION_CLICKED_ON_INACTIVE_LEADS_SALES_TAB:
    'CHANGE_STATUS_ACTION_CLICKED_ON_INACTIVE_LEADS_SALES_TAB',
  SET_CADENCE_BULK_ACTION_CLICKED_ON_INACTIVE_LEADS_SALES_TAB:
    'SET_CADENCE_BULK_ACTION_CLICKED_ON_INACTIVE_SALES_TAB',
  REASSIGN_BULK_ACTION_CLICKED_ON_INACTIVE_LEADS_SALES_TAB:
    'REASSIGN_BULK_ACTION_CLICKED_ON_INACTIVE_LEADS_SALES_TAB',
  CHANGE_STATUS_BULK__ACTION_CLICKED_ON_INACTIVE_LEADS_SALES_TAB:
    'CHANGE_STATUS_BULK__ACTION_CLICKED_ON_INACTIVE_LEADS_SALES_TAB',
  // Inactive tab quick actions (Sales > Inactive > Opportunities )
  ADD_LEAD_ACTION_CLICKED_ON_INACTIVE_OPPORTUNITIES_SALES_TAB:
    'ADD_LEAD_ACTION_CLICKED_ON_INACTIVE_OPPORTUNITIES_SALES_TAB',
  SET_CADENCE_ACTION_CLICKED_ON_INACTIVE_OPPORTUNITIES_SALES_TAB:
    'SET_CADENCE_ACTION_CLICKED_ON_INACTIVE_OPPORTUNITIES_SALES_TAB',
  ADD_TASK_ACTION_CLICKED_ON_INACTIVE_OPPORTUNITIES_SALES_TAB:
    'ADD_TASK_ACTION_CLICKED_ON_INACTIVE_OPPORTUNITIES_SALES_TAB',
  REASSIGN_ACTION_CLICKED_ON_INACTIVE_OPPORTUNITIES_SALES_TAB:
    'REASSIGN_ACTION_CLICKED_ON_INACTIVE_OPPORTUNITIES_SALES_TAB',
  CHANGE_STATUS_ACTION_CLICKED_ON_INACTIVE_OPPORTUNITIES_SALES_TAB:
    'CHANGE_STATUS_ACTION_CLICKED_ON_INACTIVE_OPPORTUNITIES_SALES_TAB',
  NEXT_STEP_ACTION_CLICKED_ON_INACTIVE_OPPORTUNITY_SALES_TAB:
    'NEXT_STEP_ACTION_CLICKED_ON_INACTIVE_OPPORTUNITY_SALES_TAB',
  SET_CADENCE_BULK_ACTION_CLICKED_ON_INACTIVE_OPPORTUNITIES_SALES_TAB:
    'SET_CADENCE_BULK_ACTION_CLICKED_ON_INACTIVE_OPPORTUNITIES_SALES_TAB',
  REASSIGN_BULK_ACTION_CLICKED_ON_INACTIVE_OPPORTUNITIES_SALES_TAB:
    'REASSIGN_BULK_ACTION_CLICKED_ON_INACTIVE_OPPORTUNITIES_SALES_TAB',
  CHANGE_STATUS_BULK__ACTION_CLICKED_ON_INACTIVE_OPPORTUNITIES_SALES_TAB:
    'CHANGE_STATUS_BULK__ACTION_CLICKED_ON_INACTIVE_OPPORTUNITIES_SALES_TAB',
  // All Opps tab quick actions (Sales > AllOpps  )
  SET_CADENCE_BULK_ACTION_CLICKED_ON_ALL_OPPS_SALES_TAB:
    'SET_CADENCE_BULK_ACTION_CLICKED_ON_ALL_OPPS_SALES_TAB',
  REASSIGN_BULK_ACTION_CLICKED_ON_ALL_OPPS_SALES_TAB:
    'REASSIGN_BULK_ACTION_CLICKED_ON_ALL_OPPS_SALES_TAB',
  CHANGE_STATUS_BULK_ACTION_CLICKED_ON_ALL_OPPS_SALES_TAB:
    'CHANGE_STATUS_BULK_ACTION_CLICKED_ON_ALL_OPPS_SALES_TAB',
  //Meeting tab
  MEETING_MARKED_ALL_AS_READ_FROM_MEETINGS_TAB: 'MEETING_MARKED_ALL_AS_READ_FROM_MEETINGS_TAB',
});
