import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Callout, Text } from '@bloobirds-it/flamingo-ui';
import { usePausePeriods, usePausePeriodsModal } from '@bloobirds-it/hooks';

import { useActiveUser } from '../../../hooks';
import styles from './cadenceSettings.module.css';
import PauseCadenceCard from './pauseCadenceCard/pauseCadenceCard';
import PauseCadenceModal from './pauseCadenceModal/pauseCadenceModal';

const CadenceSettings = () => {
  const [showPast, setShowPast] = useState(false);
  const { activeUser } = useActiveUser();
  const { periods } = usePausePeriods({ userId: activeUser.id });
  const { openCreatePauseModal } = usePausePeriodsModal();
  // @ts-ignore will be fixed once 'hooks' export typing
  const hasFinishedPeriods = periods?.list?.filter(period => period.finished).length > 0;
  // @ts-ignore will be fixed once 'hooks' export typing
  const hasActivePeriods = periods.list.some(period => !period.finished);
  const hasPeriods = periods?.list?.length > 0;

  const { t } = useTranslation('translation', { keyPrefix: 'userSettingsWebapp.cadenceSettings' });

  return (
    <div className={styles._container} data-intercom="user-settings-page-cadence">
      <PauseCadenceModal />
      <div className={styles._content__box}>
        <div className={styles._section__box}>
          <div className={styles._title__container}>
            <div className={styles._title__content}>
              <Text size="m" color="softPeanut" htmlTag="span" weight="bold">
                {t('title')}
              </Text>
            </div>
            <div className={styles._add_pause__container}>
              <Button
                dataTest={'pauseCadence'}
                iconLeft="plus"
                size="small"
                onClick={openCreatePauseModal}
              >
                {t('pauseCadence')}
              </Button>
            </div>
          </div>
          <div className={styles._subSection__container}>
            {periods && hasPeriods && hasActivePeriods ? (
              <>
                <div className={styles._callout__container}>
                  <Callout icon="clock" variant="alert">
                    {t('calloutTitle')}
                    <Text weight="bold" size="m">
                      {t('calloutSubtitle')}
                    </Text>
                  </Callout>
                </div>
                {periods.list.map(
                  // @ts-ignore will be fixed once 'hooks' export typing
                  period => !period.finished && <PauseCadenceCard key={period.id} {...period} />,
                )}
              </>
            ) : (
              <>
                <Text color="softPeanut" size="m">
                  {t('noCadenceTitle')}
                </Text>
                <div className={styles._description__text}>
                  <Text align="center" size="m">
                    {t('noCadenceSubtitle')}
                  </Text>
                </div>
              </>
            )}
          </div>
          <div
            className={styles._past__periods__button__container}
            onClick={() => setShowPast(!showPast)}
          >
            {hasFinishedPeriods && (
              <Text size="s" weight="bold" color="bloobirds">
                {showPast ? t('hidePast') : t('showPast')}
              </Text>
            )}
          </div>
          <div className={styles._subSection__container}>
            {periods &&
              showPast &&
              periods.list.map(
                // @ts-ignore will be fixed once 'hooks' export typing
                period =>
                  period.finished && <PauseCadenceCard completed key={period.id} {...period} />,
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CadenceSettings;
