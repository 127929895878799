import {
  COMPANY_FIELDS_LOGIC_ROLE,
  LEAD_FIELDS_LOGIC_ROLE,
  OPPORTUNITY_FIELDS_LOGIC_ROLE,
  TASK_ACTION,
  TASK_FIELDS_LOGIC_ROLE,
} from '@bloobirds-it/types';

export const COMPANY_COLUMNS = [
  COMPANY_FIELDS_LOGIC_ROLE.HIGH_PRIORITY,
  COMPANY_FIELDS_LOGIC_ROLE.NAME,
  COMPANY_FIELDS_LOGIC_ROLE.NUMBER_OF_LEADS,
  COMPANY_FIELDS_LOGIC_ROLE.TARGET_MARKET,
  COMPANY_FIELDS_LOGIC_ROLE.STATUS,
  COMPANY_FIELDS_LOGIC_ROLE.SOURCE,
  COMPANY_FIELDS_LOGIC_ROLE.ASSIGNED_TO,
  COMPANY_FIELDS_LOGIC_ROLE.MR_RATING,
  COMPANY_FIELDS_LOGIC_ROLE.ASSIGNED_DATE,
  COMPANY_FIELDS_LOGIC_ROLE.UPDATE_DATETIME,
];

export const LEAD_COLUMNS = [
  LEAD_FIELDS_LOGIC_ROLE.HIGH_PRIORITY,
  LEAD_FIELDS_LOGIC_ROLE.FULL_NAME,
  LEAD_FIELDS_LOGIC_ROLE.NAME,
  LEAD_FIELDS_LOGIC_ROLE.STATUS,
  LEAD_FIELDS_LOGIC_ROLE.SOURCE,
  LEAD_FIELDS_LOGIC_ROLE.ASSIGNED_TO,
  LEAD_FIELDS_LOGIC_ROLE.MR_RATING,
  LEAD_FIELDS_LOGIC_ROLE.ASSIGNED_DATE,
  LEAD_FIELDS_LOGIC_ROLE.ICP,
  LEAD_FIELDS_LOGIC_ROLE.COMPANY,
  LEAD_FIELDS_LOGIC_ROLE.UPDATE_DATETIME,
];
export const LEAD_REFERENCED_COLUMNS = [
  COMPANY_FIELDS_LOGIC_ROLE.NAME,
  COMPANY_FIELDS_LOGIC_ROLE.STATUS,
  COMPANY_FIELDS_LOGIC_ROLE.COMPANY_PARENT,
];

export const TASK_COLUMNS = [
  TASK_FIELDS_LOGIC_ROLE.TITLE,
  TASK_FIELDS_LOGIC_ROLE.STATUS,
  TASK_FIELDS_LOGIC_ROLE.COMPANY,
  TASK_FIELDS_LOGIC_ROLE.LEAD,
  TASK_FIELDS_LOGIC_ROLE.OPPORTUNITY,
  TASK_FIELDS_LOGIC_ROLE.ASSIGNED_TO,
  TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATE,
  TASK_FIELDS_LOGIC_ROLE.SCHEDULED_DATETIME,
  TASK_FIELDS_LOGIC_ROLE.TASK_TYPE,
  TASK_FIELDS_LOGIC_ROLE.IS_AUTOMATED,
  TASK_FIELDS_LOGIC_ROLE.AUTOMATION_ERROR,
  TASK_FIELDS_LOGIC_ROLE.DESCRIPTION,
  TASK_FIELDS_LOGIC_ROLE.AUTOMATED_STATUS,
  TASK_FIELDS_LOGIC_ROLE.CADENCE,
  TASK_FIELDS_LOGIC_ROLE.EMAIL_METADATA,
  TASK_FIELDS_LOGIC_ROLE.AUTOMATION_PAUSE_REASON,
  TASK_ACTION.CALL,
  TASK_ACTION.EMAIL,
  TASK_ACTION.LINKEDIN_MESSAGE,
  TASK_FIELDS_LOGIC_ROLE.TEMPLATE,
  TASK_FIELDS_LOGIC_ROLE.PRIORITY,
];
export const TASK_REFERENCED_COLUMNS = [
  COMPANY_FIELDS_LOGIC_ROLE.NAME,
  COMPANY_FIELDS_LOGIC_ROLE.STATUS,
  COMPANY_FIELDS_LOGIC_ROLE.SOURCE,
  COMPANY_FIELDS_LOGIC_ROLE.TIME_ZONE,
  COMPANY_FIELDS_LOGIC_ROLE.COUNTRY,
  COMPANY_FIELDS_LOGIC_ROLE.CADENCE,
  COMPANY_FIELDS_LOGIC_ROLE.ATTEMPTS_LAST_DAY,
  COMPANY_FIELDS_LOGIC_ROLE.HIGH_PRIORITY,
  LEAD_FIELDS_LOGIC_ROLE.FULL_NAME,
  LEAD_FIELDS_LOGIC_ROLE.STATUS,
  LEAD_FIELDS_LOGIC_ROLE.SOURCE,
  LEAD_FIELDS_LOGIC_ROLE.CADENCE,
  LEAD_FIELDS_LOGIC_ROLE.COMPANY,
  LEAD_FIELDS_LOGIC_ROLE.ATTEMPTS_LAST_DAY,
  LEAD_FIELDS_LOGIC_ROLE.HIGH_PRIORITY,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.NAME,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.STATUS,
  OPPORTUNITY_FIELDS_LOGIC_ROLE.CADENCE,
];

export const EMAILING_TYPE = Object.freeze({
  AUTOMATED: 'automatedEmails',
  SCHEDULE: 'scheduledEmails',
});

export const DATE_TEXT = {
  today: 'today',
  next_7_days: 'next 7 days',
  next_30_days: 'next 30 days',
  since_today: 'since_today',
  all_time: 'all time',
};
