import React from 'react';

import { Button, Tooltip } from '@bloobirds-it/flamingo-ui';
import { Bobject } from '@bloobirds-it/types';

import useSendToPlaygroundModal from '../../../../hooks/useSendToPlaygroundModal';

export const SendToPlayground = ({ bobjects }: { bobjects?: Bobject[] }) => {
  const { openSendToPlaygroundModal } = useSendToPlaygroundModal();
  return (
    <Button
      variant="clear"
      iconLeft="playground"
      uppercase={false}
      onClick={() => openSendToPlaygroundModal(bobjects)}
    >
      Send to Playground
    </Button>
  );
};
