// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".actionsRow-module__container___jYSce {\n  display: flex;\n  align-items: center;\n  width: 50%;\n  visibility: hidden;\n  gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectTable/actionsRow/actionsRow.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,UAAU;EACV,kBAAkB;EAClB,QAAQ;AACV","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  width: 50%;\n  visibility: hidden;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "actionsRow-module__container___jYSce"
};
export default ___CSS_LOADER_EXPORT___;
