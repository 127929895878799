import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useActivityFieldsInfo, InsightFormModal } from '@bloobirds-it/copilot';
import {
  Button,
  Dropdown,
  Icon,
  Label,
  Text,
  useToasts,
  useVisible,
  IconButton,
  Tooltip,
} from '@bloobirds-it/flamingo-ui';
import {
  CopilotInsight,
  InsightType,
  INSIGHT_STATE_OPTIONS,
  INSIGHT_TYPE_OPTIONS,
  ACTIVITY_TYPE_OPTIONS,
  InsightState,
  CopilotInsightVersion,
} from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import { mutate } from 'swr';

import styles from '../../playgroundPages.module.css';
import { NewVersionModal } from './newVersionModal';

const InsightStateDropdown = ({
  id,
  selectedInsight,
}: {
  id: string;
  selectedInsight: CopilotInsight;
}) => {
  const { ref, visible, setVisible } = useVisible(false);
  const [insightState, setInsightState] = useState(selectedInsight?.state);
  const { t: tBase } = useTranslation();
  const { t } = useTranslation('translation', { keyPrefix: 'ai.insightCard' });
  const { createToast } = useToasts();

  useEffect(() => {
    if (selectedInsight) {
      setInsightState(selectedInsight?.state);
    }
  }, [selectedInsight]);

  const onSelectStateChange = (value: InsightState) => {
    api
      .patch('/utils/service/copilot-insights/changeState/' + id, {
        newState: value,
      })
      .then(() => {
        setInsightState(value);
        createToast({
          message: t('changeStateSuccess'),
          type: 'success',
        });
        mutate('api/insights');
        mutate('api/insights/settings');
      })
      .catch(() => {
        createToast({
          message: t('changeStateError'),
          type: 'error',
        });
      })
      .finally(() => {
        setVisible(false);
      });
  };

  const stateActive = INSIGHT_STATE_OPTIONS(tBase).find(option => option.id === insightState);

  return (
    <Dropdown
      ref={ref}
      visible={visible}
      position="bottom"
      onClose={() => setVisible(false)}
      arrow={false}
      anchor={
        stateActive && (
          <Label
            size="small"
            color={stateActive?.color}
            textColor={stateActive?.textColor}
            icon={stateActive?.icon}
            iconSize={14}
            onClick={() => setVisible(!visible)}
            uppercase={false}
          >
            {stateActive?.name}
          </Label>
        )
      }
    >
      <div className={styles.insight_dropdown}>
        {INSIGHT_STATE_OPTIONS(tBase).map(state => (
          <Label
            key={state.id}
            size="small"
            color={state.color}
            textColor={state.textColor}
            icon={state.icon}
            iconSize={14}
            onClick={() => onSelectStateChange(state.id)}
            uppercase={false}
          >
            {state.name}
          </Label>
        ))}
      </div>
    </Dropdown>
  );
};

export const InsightHeader = ({
  selectedInsight,
  history = [],
  setSelectedInsight,
}: {
  selectedInsight: CopilotInsight;
  history: CopilotInsightVersion[];
  setSelectedInsight: (insight: CopilotInsight) => void;
}) => {
  const [openNewVersionModal, setOpenNewVersionModal] = useState(false);
  const { t: tBase } = useTranslation();
  const { t } = useTranslation('translation', { keyPrefix: 'ai.insightCard' });
  const { t: baseT } = useTranslation();
  const { meetingTypes } = useActivityFieldsInfo();
  const [openEditInsight, setOpenEditInsight] = useState(false);

  const onOpenNewVersion = () => {
    setOpenNewVersionModal(true);
  };

  const onEdit = () => {
    setOpenEditInsight(true);
  };

  const { title, description, insightType, activityType, activitySubtype } = selectedInsight || {};
  const currentActivityType = ACTIVITY_TYPE_OPTIONS(tBase).find(
    option => option.id === activityType,
  );
  const currentType = INSIGHT_TYPE_OPTIONS(tBase).find(option => option.id === insightType);
  const activeVersion: CopilotInsightVersion | undefined = history.find(
    version => version.isActiveVersion,
  );
  const subtype = meetingTypes.get(activitySubtype);

  return (
    <>
      <div className={styles.insight_header}>
        <div className={styles.insight_title}>
          <Text size="xl" color="peanut" weight="bold">
            {title}
          </Text>
          <div>
            <InsightStateDropdown id={selectedInsight.id} selectedInsight={selectedInsight} />
            <Button
              uppercase={false}
              size="small"
              color="purple"
              variant="tertiary"
              iconLeft="fork"
              onClick={onOpenNewVersion}
            >
              {t('newVersion')}
            </Button>
            <Tooltip title={t('editInsight')} position="top">
              <IconButton name="edit" color="purple" size={18} onClick={onEdit} />
            </Tooltip>
          </div>
        </div>
        <Text size="xs" color="peanut">
          {description}
        </Text>
        <div className={styles.insight_info}>
          <div>
            <Icon name="gridSquares" size={14} color="softPeanut" />
            <Text size="s" weight="bold">
              {t('type')}:
            </Text>
            <Label
              size="small"
              color="lightPeanut"
              textColor="peanut"
              icon={insightType === InsightType.GENERATION ? 'suggestions' : 'book'}
              iconColor="peanut"
              iconSize={14}
              overrideStyle={{ display: 'flex' }}
              uppercase={false}
            >
              {currentType?.name}
            </Label>
          </div>
          <div>
            <Icon name="taskAction" size={14} color="softPeanut" />
            <Text size="s" weight="bold">
              {t('activityType')}:
            </Text>
            <Text size="s">{currentActivityType?.name}</Text>
          </div>
        </div>
        <div className={styles.insight_info}>
          <div>
            <Icon name="number" size={14} color="softPeanut" />
            <Text size="s" weight="bold">
              {t('activeVersion')}:
            </Text>
            <Text size="s">Version {activeVersion?.version ?? '0'}</Text>
          </div>
          <div>
            <Icon name="child" size={14} color="softPeanut" />
            <Text size="s" weight="bold">
              {t('activitySubtype')}:
            </Text>
            <Text size="s">{subtype?.name || 'All'}</Text>
          </div>
        </div>
        <div className={styles.insight_info}>
          <Icon name="label" size={14} color="softPeanut" />
          <Text size="s" weight="bold">
            {t('category')}:
          </Text>
          <Text size="s">
            {selectedInsight?.category
              ? baseT(`ai.insightCategory.${selectedInsight.category}`)
              : 'N/A'}
          </Text>
        </div>
      </div>
      {openEditInsight && (
        <InsightFormModal
          currentInsight={selectedInsight}
          mode="edit"
          onClose={() => setOpenEditInsight(false)}
          onSave={setSelectedInsight}
        />
      )}
      {openNewVersionModal && (
        <NewVersionModal
          selectedVersion={activeVersion}
          history={history}
          compareVersions={false}
          onClose={() => setOpenNewVersionModal(false)}
        />
      )}
    </>
  );
};
