import { MessagingTemplate, MetaValidationStatus, TEMPLATE_TYPES } from '@bloobirds-it/types';

export const checkIfMetaApproved = (template: MessagingTemplate) => {
  return (
    template?.type === TEMPLATE_TYPES.WHATSAPP &&
    template?.twilioSid &&
    template?.metaValidationStatus === MetaValidationStatus.APPROVED
  );
};

export const checkIfMetaSent = (template: MessagingTemplate) => {
  return template?.type === TEMPLATE_TYPES.WHATSAPP && !!template?.metaValidationStatus;
};
