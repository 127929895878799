import { useEffect, useRef, useState } from 'react';

import { useEventSubscription } from '@bloobirds-it/plover';
import { atom, useRecoilState } from 'recoil';
import useSWR from 'swr';

import { api } from '../utils/api';

const activeIntegrationAtom = atom({
  key: 'activeIntegration',
  default: {
    clientId: '',
    username: '',
    resource: '',
    token: '',
    hasError: false,
    isLoaded: false,
  },
});

export const useGenericIntegration = driver => {
  const [activeIntegration, setActiveIntegration] = useRecoilState(activeIntegrationAtom);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const authRef = useRef(null);

  const { data: integration } = useSWR(
    `/integrations/manager/drivers/${driver}`,
    async () => await api.get(`/integrations/manager/drivers/${driver}`).then(res => res.data),
  );

  useEffect(() => {
    if (integration) {
      setActiveIntegration({
        ...activeIntegration,
        username: integration?.username,
        clientId: integration?.clientId,
        resoucre: integration?.resoucre,
        token: integration?.token,
        isLoaded: true,
        hasError: false,
      });
    }
  }, [integration]);

  const closeLoginPopup = () => {
    // Clean up event listeners and reload app with loaded dynamics
    authRef?.current?.close();
    authRef.current = null;
    window.location.reload();
  };

  useEventSubscription('genericIntegration', success => {
    if (success) {
      closeLoginPopup();
    }
  });

  const createIntegration = async (bodyRequest, handleError) => {
    setIsSubmitting(true);
    const baseUri =
      process.env.NODE_ENV === 'development'
        ? 'https://gateway.dev-bloobirds.com/integrations'
        : 'https://gateway.bloobirds.com/integrations';
    if (driver === 'msndynamics') {
      authRef.current = window.open(
        `${baseUri}/manager/drivers/msndynamics/authenticate/${
          bodyRequest.accountId
        }?resourceUrl=${encodeURIComponent(bodyRequest.resource)}`,
        'MsgWindow',
        'width=620,height=420',
      );

      let errorCounterInInterval = 0;
      let timerInSeconds = 0; // to give user 2 minutes at most to login or force close

      // this setInterval is in any situation the WS is blocked by cors or something
      const popupClosedChecker = setInterval(() => {
        try {
          if (authRef?.current?.closed || timerInSeconds >= 120) {
            closeLoginPopup();
            clearInterval(popupClosedChecker);
          }

          timerInSeconds++;
        } catch (e) {
          // Handle errors, likely due to cross-origin policies
          console.error('Error accessing the content of the new window:', e);
          errorCounterInInterval++;
          // more than 5 attempts will stop this interval and close the tab
          if (errorCounterInInterval > 5) {
            clearInterval(popupClosedChecker);
            authRef?.current?.close();
          }
        }
      }, 1000);

      return;
    }

    const response = await api.post(`/integrations/manager/drivers/${driver}`, bodyRequest, {
      validateStatus: () => true,
    });
    if (response.status === 200) {
      setActiveIntegration({
        ...activeIntegration,
        username: response?.username,
        clientId: response?.clientId,
        resoucre: response?.resoucre,
        token: response?.token,
        isLoaded: true,
        hasError: false,
      });
      setIsConnected(true);
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
      setActiveIntegration({
        ...activeIntegration,
        isLoaded: false,
        hasError: true,
      });
      if (response.status === 400) {
        handleError({
          hasError: true,
          message: ' CRM user profile is not System Administrator',
        });
      } else {
        handleError({
          hasError: true,
          message:
            ' Make sure that all fields are filled properly and if errors persist contact support.',
        });
      }
    }
    return response;
  };

  const disconnectIntegration = async () => {
    await api
      .delete(`/integrations/manager/drivers/${driver}`, {
        validateStatus: () => true,
      })
      .then(() => {
        setActiveIntegration({});
        setIsConnected(false);
      });
  };

  return {
    activeIntegration,
    integration,
    isSubmitting,
    isConnected,
    createIntegration,
    disconnectIntegration,
  };
};
