// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".generalSettingsTooltips-module___discovery_wrapper___jEwtg {\n    z-index: 1;\n    position: relative;\n    margin-left: 12px;\n}\n\n.generalSettingsTooltips-module___image___jDVe\\+ {\n    margin-bottom: -42px;\n}\n\n.generalSettingsTooltips-module___image_collage___\\+YDBp {\n    width: 250px;\n}\n\n.generalSettingsTooltips-module___image_collage___\\+YDBp > img:first-child {\n    position: absolute;\n    right: 20px;\n    top: 40px;\n    z-index:1 ;\n}\n\n.generalSettingsTooltips-module___image_collage___\\+YDBp > img:last-child {\n    position: absolute;\n    left: 20px;\n    bottom: 150px;\n    z-index: 1;\n}\n\n.generalSettingsTooltips-module___primary_button___hi0h- {\n    border-color: var(--white);\n}", "",{"version":3,"sources":["webpack://./src/js/components/discoveryTooltips/generalSettingsTourTooltips/generalSettingsTooltips.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,UAAU;AACd;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["._discovery_wrapper {\n    z-index: 1;\n    position: relative;\n    margin-left: 12px;\n}\n\n._image {\n    margin-bottom: -42px;\n}\n\n._image_collage {\n    width: 250px;\n}\n\n._image_collage > img:first-child {\n    position: absolute;\n    right: 20px;\n    top: 40px;\n    z-index:1 ;\n}\n\n._image_collage > img:last-child {\n    position: absolute;\n    left: 20px;\n    bottom: 150px;\n    z-index: 1;\n}\n\n._primary_button {\n    border-color: var(--white);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_discovery_wrapper": "generalSettingsTooltips-module___discovery_wrapper___jEwtg",
	"_image": "generalSettingsTooltips-module___image___jDVe+",
	"_image_collage": "generalSettingsTooltips-module___image_collage___+YDBp",
	"_primary_button": "generalSettingsTooltips-module___primary_button___hi0h-"
};
export default ___CSS_LOADER_EXPORT___;
