// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qualifyingQuestion-module__header___qsQm\\+ {\n  display: flex;\n  margin-bottom: 10px;\n  margin-top: 30px;\n}\n\n.qualifyingQuestion-module__headerIcon___vPFnt {\n  margin-right: 10px;\n  flex-shrink: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/qualifyingQuestions/qualifyingQuestion/qualifyingQuestion.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".header {\n  display: flex;\n  margin-bottom: 10px;\n  margin-top: 30px;\n}\n\n.headerIcon {\n  margin-right: 10px;\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "qualifyingQuestion-module__header___qsQm+",
	"headerIcon": "qualifyingQuestion-module__headerIcon___vPFnt"
};
export default ___CSS_LOADER_EXPORT___;
