// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dependenciesPage-module___action__text___YUdG7 {\n  cursor: pointer;\n  margin-right: 4px;\n}\n\n.dependenciesPage-module___action__container___4GovG {\n  margin-left: 12px;\n  display: flex;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/dependenciesPage/dependenciesPage.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":["._action__text {\n  cursor: pointer;\n  margin-right: 4px;\n}\n\n._action__container {\n  margin-left: 12px;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_action__text": "dependenciesPage-module___action__text___YUdG7",
	"_action__container": "dependenciesPage-module___action__container___4GovG"
};
export default ___CSS_LOADER_EXPORT___;
