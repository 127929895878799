import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@bloobirds-it/flamingo-ui';
import { useGetI18nSpacetime } from '@bloobirds-it/internationalization';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  Bobject,
  COMPANY_FIELDS_LOGIC_ROLE,
  LEAD_FIELDS_LOGIC_ROLE,
} from '@bloobirds-it/types';
import { getRelatedBobject, getTextFromLogicRole } from '@bloobirds-it/utils';

import styles from '../infoAiAnalysis.module.css';
import { IconLabelAnalysis, InfoSectionAnalysis } from '../infoAiAnalysis.utils';

export const LinkedinAiAnalysis = ({ activity }: { activity: Bobject }) => {
  const { t, i18n } = useTranslation();

  const creationDateTime = getTextFromLogicRole(
    activity,
    ACTIVITY_FIELDS_LOGIC_ROLE.CREATION_DATETIME,
  );

  const format =
    i18n?.language === 'en'
      ? '{month-short} {date-pad} {year}, {time-24}'
      : '{date-pad} {month-short} {year}, {time-24}';

  const conversationDate = useGetI18nSpacetime(creationDateTime).format(format);

  const assignedTo = getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_ASSIGNED_TO);
  const company = getRelatedBobject(activity, 'Company');
  const companyName = getTextFromLogicRole(company, COMPANY_FIELDS_LOGIC_ROLE.NAME);
  const lead = getRelatedBobject(activity, 'Lead');
  const leadName = getTextFromLogicRole(lead, LEAD_FIELDS_LOGIC_ROLE.FULL_NAME);

  return (
    <div className={styles.container}>
      <Text size="xl" weight="heavy">
        {t('activityTimelineItem.item.linkedinConversation')}
      </Text>
      {(companyName || leadName) && (
        <div className={styles.infoSubtitles}>
          {companyName && (
            <IconLabelAnalysis
              id="User name"
              iconProps={{ name: 'company', color: 'verySoftBloobirds', size: 20 }}
              labelProps={{
                weight: 'heavy',
                color: 'darkGray',
                size: 'm',
              }}
              text={companyName}
            />
          )}
          {leadName && (
            <IconLabelAnalysis
              id="User name"
              iconProps={{ name: 'person', color: 'verySoftBloobirds', size: 20 }}
              labelProps={{
                weight: 'heavy',
                color: 'darkGray',
                size: 'm',
              }}
              text={leadName}
            />
          )}
        </div>
      )}
      <div className={styles.infoSection}>
        <InfoSectionAnalysis
          icon="event"
          title={t('ai.aiAnalysis.conversationDate')}
          info={conversationDate || 'N/A'}
        />

        <InfoSectionAnalysis
          icon="person"
          title={t('ai.aiAnalysis.assignedTo')}
          info={assignedTo}
        />
      </div>
    </div>
  );
};
