// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".activitiesPlaceholder___card___hDOtC {\n  border: solid 1px var(--veryLightBloobirds);\n  background-color: var(--white);\n  margin-bottom: 40px;\n  box-sizing: border-box;\n  border-radius: 4px;\n  max-width: 1280px;\n  padding: 32px;\n}\n\n.activitiesPlaceholder___card_header___sJGT- {\n  display: flex;\n  gap: 16px;\n  margin-bottom: 32px;\n}\n\n.activitiesPlaceholder___card_header___sJGT- > * {\n  flex-shrink: 0;\n}\n\n.activitiesPlaceholder___card_header___sJGT- > *:last-child {\n  margin-left: auto;\n}\n\n.activitiesPlaceholder___card_header_text___M3lU0 {\n  width: 80%;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.activitiesPlaceholder___list___2yvhY {\n  margin-top: 32px;\n}\n\n.activitiesPlaceholder___date_title___XeKos {\n  margin-bottom: 8px;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/activitySection/activitiesPlaceholder/activitiesPlaceholder.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,8BAA8B;EAC9B,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb","sourcesContent":["._card {\n  border: solid 1px var(--veryLightBloobirds);\n  background-color: var(--white);\n  margin-bottom: 40px;\n  box-sizing: border-box;\n  border-radius: 4px;\n  max-width: 1280px;\n  padding: 32px;\n}\n\n._card_header {\n  display: flex;\n  gap: 16px;\n  margin-bottom: 32px;\n}\n\n._card_header > * {\n  flex-shrink: 0;\n}\n\n._card_header > *:last-child {\n  margin-left: auto;\n}\n\n._card_header_text {\n  width: 80%;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n._list {\n  margin-top: 32px;\n}\n\n._date_title {\n  margin-bottom: 8px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_card": "activitiesPlaceholder___card___hDOtC",
	"_card_header": "activitiesPlaceholder___card_header___sJGT-",
	"_card_header_text": "activitiesPlaceholder___card_header_text___M3lU0",
	"_list": "activitiesPlaceholder___list___2yvhY",
	"_date_title": "activitiesPlaceholder___date_title___XeKos"
};
export default ___CSS_LOADER_EXPORT___;
