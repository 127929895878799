import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useVisible, Icon, Dropdown, IconType, Text } from '@bloobirds-it/flamingo-ui';
import { ExtensionBobject } from '@bloobirds-it/types';

import { ACTION_COMPONENTS } from './components';
import styles from './contactViewActions.module.css';
import { useFilteredActions } from './hooks/useFilterActionsByPermit';
import { ContactViewActionTypes } from './types';

interface IContactViewActionsProps {
  actions: ContactViewActionTypes[];
  activeBobject: ExtensionBobject;
  refreshActiveBobject: () => void;
}

const actionIcons: Record<ContactViewActionTypes, IconType> = {
  reassignCompany: 'deliver',
  assignCompany: 'deliver',
  removeCompany: 'cancel',
};

export const ContactViewActions = ({
  actions,
  activeBobject,
  refreshActiveBobject,
}: IContactViewActionsProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'sidePeek.contactViewActions' });

  const { visible, setVisible, ref } = useVisible();
  const [selectedAction, setSelectedAction] = useState<ContactViewActionTypes | null>(null);
  const filteredActions = useFilteredActions(actions, activeBobject);

  const handleActionClick = (action: ContactViewActionTypes) => {
    setSelectedAction(action);
  };

  const SelectedComponent = selectedAction ? ACTION_COMPONENTS[selectedAction] : null;

  // If we dont set the reference conditionally, any interaction with any component rendered outside the dropdown its "unclickable"
  // like the confirmation modals
  const outsideClickReference = !selectedAction ? ref : undefined;

  return (
    <Dropdown
      width="100%"
      visible={visible}
      ref={outsideClickReference}
      zIndex={102}
      arrow={false}
      onClose={() => setSelectedAction(null)}
      anchor={
        //Fixed height for horizontal alignment with the labels
        <div
          onClick={() => setVisible(true)}
          style={{ height: '27px', display: 'flex', alignItems: 'center' }}
        >
          <Icon name="moreOpenholesVertical" size={20} />
        </div>
      }
      style={{ minWidth: 0, minHeight: 0 }}
    >
      {selectedAction ? (
        <SelectedComponent
          refreshActiveBobject={refreshActiveBobject}
          activeBobject={activeBobject}
          closeAction={() => {
            setSelectedAction(null);
            setVisible(false);
          }}
        />
      ) : (
        filteredActions.map(action => (
          <div className={styles.actions} key={action} onClick={() => handleActionClick(action)}>
            <Icon size={16} color="bloobirds" name={actionIcons[action]} />
            <Text size="s">{t(action)}</Text>
          </div>
        ))
      )}
    </Dropdown>
  );
};
