// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notificationTab-module___show_more___4NsTV {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  margin-top: 7px;\n}\n\n.notificationTab-module___loading_container___wKc3s,\n.notificationTab-module___empty_container___Pw\\+Ux {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  margin-top: 96px;\n}\n\n.notificationTab-module___loading_container___wKc3s {\n  margin-top: 128px;\n}\n\n.notificationTab-module___empty_container___Pw\\+Ux > *:first-child {\n  margin-bottom: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/header/headerActions/notificationBell/notificationTab/notificationTab.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,eAAe;AACjB;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["._show_more {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  margin-top: 7px;\n}\n\n._loading_container,\n._empty_container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  margin-top: 96px;\n}\n\n._loading_container {\n  margin-top: 128px;\n}\n\n._empty_container > *:first-child {\n  margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_show_more": "notificationTab-module___show_more___4NsTV",
	"_loading_container": "notificationTab-module___loading_container___wKc3s",
	"_empty_container": "notificationTab-module___empty_container___Pw+Ux"
};
export default ___CSS_LOADER_EXPORT___;
