export const fetchLanguages = async () => {
  const result = await fetch(
    `https://delivery.localazy.com/_a7714004326121177803f2a33b98/_e0.json`,
  );

  const data = await result.json();
  const languages: string[] = [];

  for (const key in Object.values(data)) {
    const item = Object.values(data)[key];
    const locales = item['locales'];

    locales.forEach(locale => {
      let langCode = locale.language;
      if (locale.region) {
        langCode = langCode + '-' + locale.region;
      }

      if (!languages.includes(langCode)) {
        languages.push(langCode);
      }
    });
  }

  return languages;
};
