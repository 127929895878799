// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".disconnectIntegration-module___check_box___1f3q- {\n  box-sizing: border-box;\n  padding: 20px 0 40px 0;\n}\n.disconnectIntegration-module___call_out___ttNqa {\n  margin-bottom: 40px;\n}\n.disconnectIntegration-module___call_out___ttNqa > div {\n  width: 100%;\n  margin-bottom: 12px;\n}\n.disconnectIntegration-module___call_out___ttNqa > p {\n  display: flex;\n}\n.disconnectIntegration-module___call_out_text___2MzhE {\n  font-weight: bold;\n  display: inline;\n  color: var(--peanut);\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/integrationLayout/syncSettingsTab/outbound/disconnectIntegrationModal/disconnectIntegration.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,sBAAsB;AACxB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,aAAa;AACf;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,oBAAoB;AACtB","sourcesContent":["._check_box {\n  box-sizing: border-box;\n  padding: 20px 0 40px 0;\n}\n._call_out {\n  margin-bottom: 40px;\n}\n._call_out > div {\n  width: 100%;\n  margin-bottom: 12px;\n}\n._call_out > p {\n  display: flex;\n}\n._call_out_text {\n  font-weight: bold;\n  display: inline;\n  color: var(--peanut);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_check_box": "disconnectIntegration-module___check_box___1f3q-",
	"_call_out": "disconnectIntegration-module___call_out___ttNqa",
	"_call_out_text": "disconnectIntegration-module___call_out_text___2MzhE"
};
export default ___CSS_LOADER_EXPORT___;
