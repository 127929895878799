import React from 'react';

import { Item, Select, Text, Tooltip } from '@bloobirds-it/flamingo-ui';

import useAutomationEmailTemplates from '../../../../../../hooks/useAutomationEmailTemplates';
import styles from './messagingTemplatesBar.module.css';

interface MessagingTemplatesSelectProps {
  value?: string;
  onChange: (id: string) => void;
}

function MessagingTemplatesBar({ value, onChange }: MessagingTemplatesSelectProps) {
  const { messagingTemplates } = useAutomationEmailTemplates();

  return (
    <div className={styles.container}>
      <div className={styles.select}>
        <div className={styles.label}>
          <Text size="xs" color="softPeanut">
            Template:
          </Text>
        </div>
        <Select
          autocomplete
          borderless={false}
          size="small"
          width="256px"
          placeholder="Email Template"
          value={value}
          onChange={onChange}
        >
          {messagingTemplates.map((messagingTemplate: any) => {
            return messagingTemplate.name?.length >= 40 ? (
              <Item
                key={messagingTemplate.id}
                label={messagingTemplate.name}
                value={messagingTemplate.id}
                className={styles['select-item']}
              >
                <Tooltip
                  className={styles['select-item']}
                  position="top"
                  title={messagingTemplate.name}
                  delay={500}
                >
                  {messagingTemplate.name}
                </Tooltip>
              </Item>
            ) : (
              <Item
                key={messagingTemplate.id}
                label={messagingTemplate.name}
                value={messagingTemplate.id}
                className={styles['select-item']}
              >
                {messagingTemplate.name}
              </Item>
            );
          })}
        </Select>
      </div>
    </div>
  );
}

export default MessagingTemplatesBar;
