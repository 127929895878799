// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cadencesLayout-module___container___xdtcH {\n  padding: 40px 120px;\n  width: 100%;\n  height: calc(100vh - 8rem);\n  overflow-y: auto;\n  overflow-x: hidden;\n  background: var(--lighterGray);\n  display: flex;\n  flex-direction: column;\n}\n\n.cadencesLayout-module___container_small_desktop___OViX5 {\n  padding: 40px 40px;\n}\n\n.cadencesLayout-module___header___2NrB- {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 16px;\n}\n\n.cadencesLayout-module___container___xdtcH div[class^='Tab-module_tab'] {\n  width: 100px;\n  justify-content: center;\n}\n\n.cadencesLayout-module___container___xdtcH div[class^='Tab-module_bottomLine'] {\n  height: 2px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/cadencesLayout/cadencesLayout.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;EACX,0BAA0B;EAC1B,gBAAgB;EAChB,kBAAkB;EAClB,8BAA8B;EAC9B,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb","sourcesContent":["._container {\n  padding: 40px 120px;\n  width: 100%;\n  height: calc(100vh - 8rem);\n  overflow-y: auto;\n  overflow-x: hidden;\n  background: var(--lighterGray);\n  display: flex;\n  flex-direction: column;\n}\n\n._container_small_desktop {\n  padding: 40px 40px;\n}\n\n._header {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 16px;\n}\n\n._container div[class^='Tab-module_tab'] {\n  width: 100px;\n  justify-content: center;\n}\n\n._container div[class^='Tab-module_bottomLine'] {\n  height: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "cadencesLayout-module___container___xdtcH",
	"_container_small_desktop": "cadencesLayout-module___container_small_desktop___OViX5",
	"_header": "cadencesLayout-module___header___2NrB-"
};
export default ___CSS_LOADER_EXPORT___;
