import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Text, Button, Spinner } from '@bloobirds-it/flamingo-ui';
import { useTogglerState } from '@bloobirds-it/hooks';
import { ActivityInsights } from '@bloobirds-it/types';
import { motion } from 'framer-motion';

import styles from './copilotSummary.module.css';

interface ICopilotSummaryProps {
  onAddToNote: () => void;
  activityInsights: ActivityInsights;
}

export const CopilotSummary = ({ onAddToNote, activityInsights }: ICopilotSummaryProps) => {
  const { t } = useTranslation();
  const [isExpanded, toggle] = useTogglerState(false);

  const hasSummary = !!activityInsights?.summary;
  const isGenerating = activityInsights?.status === 'GENERATING';

  if (!isGenerating && !hasSummary) return null;

  return (
    <motion.div
      className={styles.container}
      initial={{ height: 0, overflow: 'hidden' }}
      animate={{ height: 'auto', overflow: 'unset' }}
      transition={{ duration: 0.5 }}
    >
      <div className={styles.header}>
        <div className={styles.title}>
          <Icon name="stars" color="purple" size={16} />
          <Text size="xs" weight="heavy">
            {t('ai.playground.activityTab.summary')}
          </Text>
        </div>
        {hasSummary ? (
          <Button
            className={styles.addToNoteButton}
            size="small"
            uppercase={false}
            variant={'IAGradient'}
            onClick={onAddToNote}
          >
            <Icon color="purple" name="noteAction" size={14} />
            {t('activityTimelineItem.item.copilotInsights.addToNote')}
          </Button>
        ) : (
          <div className={styles.title}>
            <Spinner name="loadingCircle" color="softPurple" size={14} />
            <Text size="xs" color="softPurple">
              {t('ai.aiInsights.generating')}
            </Text>
          </div>
        )}
      </div>
      {hasSummary && (
        <>
          <motion.div
            initial={{ height: '4.5em' }} // Approximately 3 lines
            animate={{
              height: isExpanded ? '400px' : '4.5em',
              overflow: isExpanded ? 'scroll' : 'hidden',
            }}
            transition={{ duration: 0.3 }}
            className={styles.summaryContainer}
          >
            {activityInsights?.summary}
          </motion.div>

          {(activityInsights?.summary?.length || 0) > 130 && ( // 130 is approximately 3 rows of characters
            <Button
              className={styles.showMoreButton}
              size="small"
              color="softPurple"
              variant="clear"
              onClick={toggle}
            >
              {t('generalSearchBar.showMore')}
              <Icon name={isExpanded ? 'chevronUp' : 'chevronDown'} size={12} color="softPurple" />
            </Button>
          )}
        </>
      )}
    </motion.div>
  );
};
