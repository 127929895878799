// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".emptyFieldsList-module___container___PCl1n {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  margin-top: 34px;\n  gap: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/salesforceObjects/relatedObjects/emptyFieldsList/emptyFieldsList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;EAChB,SAAS;AACX","sourcesContent":["._container {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  margin-top: 34px;\n  gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "emptyFieldsList-module___container___PCl1n"
};
export default ___CSS_LOADER_EXPORT___;
