// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".addCategoryModal-module__deleteInfo___zeKgm {\n  margin-top: 16px;\n  margin-bottom: 16px;\n}\n\n.addCategoryModal-module__deleteButtons___OI8JV > button:not(:last-child) {\n  margin-right: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/playbookPages/productPage/addCategoryModal/addCategoryModal.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".deleteInfo {\n  margin-top: 16px;\n  margin-bottom: 16px;\n}\n\n.deleteButtons > button:not(:last-child) {\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteInfo": "addCategoryModal-module__deleteInfo___zeKgm",
	"deleteButtons": "addCategoryModal-module__deleteButtons___OI8JV"
};
export default ___CSS_LOADER_EXPORT___;
