// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".orderingArrows-module___fields_list_order_buttons___uGc30 {\n  width: 16px;\n  min-height: 37px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/orderingArrows/orderingArrows.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":["._fields_list_order_buttons {\n  width: 16px;\n  min-height: 37px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_fields_list_order_buttons": "orderingArrows-module___fields_list_order_buttons___uGc30"
};
export default ___CSS_LOADER_EXPORT___;
