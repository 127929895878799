export const LEAD_STATUS = Object.freeze({
  MEETING: 'MEETING',
  CONTACT: 'CONTACT',
  DISCARDED: 'DISCARDED',
  NURTURING: 'NURTURING',
  ON_HOLD: 'ON_HOLD',
});

export const LEAD_STATUS_LOGIC_ROLE = Object.freeze({
  CONTACT: 'LEAD__STATUS__CONTACT',
  CONTACTED: 'LEAD__STATUS__CONTACTED',
  DELIVERED: 'LEAD__STATUS__DELIVERED',
  DISCARDED: 'LEAD__STATUS__DISCARDED',
  ENGAGED: 'LEAD__STATUS__ENGAGED',
  MEETING: 'LEAD__STATUS__MEETING',
  NEW: 'LEAD__STATUS__NEW',
  NURTURING: 'LEAD__STATUS__NURTURING',
  ON_HOLD: 'LEAD__STATUS__ON_HOLD',
  ON_PROSPECTION: 'LEAD__STATUS__ON_PROSPECTION',
  BACKLOG: 'LEAD__STATUS__BACKLOG',
});

export const LEAD_SALES_STATUS_VALUES_LOGIC_ROLE = Object.freeze({
  ACTIVE: 'LEAD__SALES_STATUS__ACTIVE',
  ON_HOLD: 'LEAD__SALES_STATUS__ON_HOLD',
  NURTURING: 'LEAD__SALES_STATUS__NURTURING',
  DISCARDED: 'LEAD__SALES_STATUS__DISCARDED',
  CLIENT: 'LEAD__SALES_STATUS__CLIENT',
});

export const LEAD_STAGE_LOGIC_ROLE = Object.freeze({
  PROSPECT: 'LEAD__STAGE__PROSPECTING',
  SALES: 'LEAD__STAGE__SALES',
});

export const LEAD_FIELDS_LOGIC_ROLE = Object.freeze({
  ASSIGNED_DATE: 'LEAD__LAST_ASSIGNED_DATE',
  ASSIGNED_TO: 'LEAD__ASSIGNED_TO',
  ATTEMPTS_COUNT: 'LEAD__ATTEMPTS_COUNT',
  ATTEMPTS_LAST_DAY: 'LEAD__ATTEMPTS_LAST_DAY',
  AUTHOR: 'LEAD__AUTHOR',
  BUYING_ROLE: 'LEAD__BUYING_ROLE',
  CADENCE_DATA: 'LEAD__CADENCE_DATA',
  CADENCE_STOPPED: 'LEAD__CADENCE_STOPPED',
  CADENCE: 'LEAD__CADENCE',
  COMPANY: 'LEAD__COMPANY',
  CREATION_DATETIME: 'LEAD__CREATION_DATETIME',
  DISCARDED_REASONS: 'LEAD__DISCARDED_REASONS',
  EMAIL: 'LEAD__EMAIL',
  FULL_NAME: 'LEAD__FULL_NAME',
  FUNCTION: '',
  HIGH_PRIORITY: 'LEAD__HIGH_PRIORITY',
  ICP: 'LEAD__ICP',
  IS_MQL_OR_SAL_REQUIRED: 'LEAD__IS_MQL_OR_SAL_REQUIRED',
  JOB_TITLE: 'LEAD__LINKEDIN_JOB_TITLE',
  LINKEDIN_JOB_TITLE: 'LEAD__LINKEDIN_JOB_TITLE',
  LINKEDIN_URL: 'LEAD__LINKEDIN_URL',
  SALES_NAV_URL: 'LEAD__SALES_NAVIGATOR_URL',
  MR_RATING: 'LEAD__MR_RATING',
  NAME: 'LEAD__NAME',
  NURTURING_REASONS: 'LEAD__NURTURING_REASONS',
  OPPORTUNITY: 'LEAD__OPPORTUNITY',
  OPT_OUT: 'LEAD__OPT_OUT',
  ON_HOLD_REASONS: 'LEAD__ON_HOLD_REASONS',
  PHONE: 'LEAD__PHONE',
  POSITION_ROLE: 'LEAD__MANAGEMENT_LEVEL',
  SALES_DISCARDED_REASONS: 'LEAD__SALES_DISCARDED_REASONS',
  SALES_NURTURING_REASONS: 'LEAD__SALES_NURTURING_REASONS',
  SALES_ON_HOLD_REASONS: 'LEAD__SALES_ON_HOLD_REASONS',
  SALES_STATUS: 'LEAD__SALES_STATUS',
  SOURCE: 'LEAD__SOURCE',
  STAGE: 'LEAD__STAGE',
  START_CADENCE: 'LEAD__START_CADENCE',
  STATUS: 'LEAD__STATUS',
  TIME_ZONE: 'LEAD__TIME_ZONE',
  TOUCHES_COUNT: 'LEAD__TOUCHES_COUNT',
  TOUCHES_LAST_DAY: 'LEAD__TOUCHES_LAST_DAY',
  UPDATE_DATETIME: 'LEAD__UPDATE_DATETIME',
  MQL: 'LEAD__MQL',
  SAL: 'LEAD__SAL',
  IS_INACTIVE: 'LEAD__IS_INACTIVE',
});

export const LEAD_IS_MQL_OR_SAL_LOGIC_ROLE = Object.freeze({
  YES: 'LEAD__IS_MQL_OR_SAL_REQUIRED__YES',
  NO: 'LEAD__IS_MQL_OR_SAL_REQUIRED__NO',
});

export const LEAD_HIGH_PRIORITY_LOGIC_ROLE = Object.freeze({
  YES: 'LEAD__HIGH_PRIORITY_YES',
  NO: 'LEAD__HIGH_PRIORITY_NO',
});

export const LEAD_OPT_OUT_LOGIC_ROLE = Object.freeze({
  YES: 'LEAD__OPT_OUT__YES',
  NO: 'LEAD__OPT_OUT__NO',
});

export const LEAD_CADENCE_STOPPED_LOGIC_ROLE = Object.freeze({
  YES: 'LEAD__CADENCE_STOPPED_YES',
  NO: 'LEAD__CADENCE_STOPPED_NO',
});

export const LEAD_SOURCE_LOGIC_ROLES = Object.freeze({
  INBOUND: 'LEAD__SOURCE__INBOUND',
  OUTBOUND: 'LEAD__SOURCE__OUTBOUND',
});
