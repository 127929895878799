import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  EntityList,
  EntityListHeader,
  EntityHeaderItem,
} from '../../../../../components/entityList/entityList';
import {
  AccountSettingsTabEmptyContent,
  AccountSettingsTableContainer,
} from '../../../../../layouts/accountSettingsLayout/accountSettingsTab/accountSettingsTab';
import {
  PHONE_COLUMNS,
  PhoneNumbersTableContentProps,
} from '../../constants/phoneNumbers.constants';
import styles from '../../styles/phoneNumberPage.module.css';
import { PhoneRow } from './phoneRow';

export const PhoneNumbersTableContent = ({
  entities,
  isLoading,
  totalElements,
}: PhoneNumbersTableContentProps) => {
  const { t } = useTranslation();
  const lastTwoRowIndexes = [totalElements - 3, totalElements - 2, totalElements - 1];
  if (isLoading) {
    return <AccountSettingsTabEmptyContent>Loading...</AccountSettingsTabEmptyContent>;
  }
  if (entities?.length === 0) {
    return (
      <AccountSettingsTabEmptyContent>
        No phone numbers for the following search
      </AccountSettingsTabEmptyContent>
    );
  }
  return (
    <>
      <AccountSettingsTableContainer>
        <EntityList>
          <EntityListHeader>
            {PHONE_COLUMNS.map(column => (
              <EntityHeaderItem
                key={column?.id}
                label={t(`accountSettings.phoneNumbers.filters.${column?.name}`)}
              />
            ))}
          </EntityListHeader>
          <>
            {entities.map((phone, index) => {
              const isLastTwoRows = lastTwoRowIndexes.includes(index);
              return <PhoneRow key={phone.id} phone={phone} isLastTwoRows={isLastTwoRows} />;
            })}
          </>
        </EntityList>
      </AccountSettingsTableContainer>
    </>
  );
};
