// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".prospectingTooltips-module___companies_leads_tooltip___qUkOw {\n  margin-left: -8px;\n}\n\n.prospectingTooltips-module___start_to_finsih_tooltip___iAq7b {\n  top: 47px;\n  right: 13px;\n  position: relative;\n}\n\n.prospectingTooltips-module___on_cadence_tooltip___vv3FQ {\n  position: absolute;\n  top: 35px;\n}\n\n.prospectingTooltips-module___start_finish_image___xpn8H {\n  margin: -62px 0 -80px;\n}\n\n.prospectingTooltips-module___filters_tooltip___18A8d {\n  align-self: baseline;\n}\n\n.prospectingTooltips-module___on_cadence_filters_image___e8BRn {\n  margin-top: 16px;\n}\n\n.prospectingTooltips-module___on_cadence_start_tasks_image___fnm02 {\n  margin-bottom: -73px;\n}", "",{"version":3,"sources":["webpack://./src/js/components/discoveryTooltips/prospectingTourTooltips/prospectingTooltips.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":["._companies_leads_tooltip {\n  margin-left: -8px;\n}\n\n._start_to_finsih_tooltip {\n  top: 47px;\n  right: 13px;\n  position: relative;\n}\n\n._on_cadence_tooltip {\n  position: absolute;\n  top: 35px;\n}\n\n._start_finish_image {\n  margin: -62px 0 -80px;\n}\n\n._filters_tooltip {\n  align-self: baseline;\n}\n\n._on_cadence_filters_image {\n  margin-top: 16px;\n}\n\n._on_cadence_start_tasks_image {\n  margin-bottom: -73px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_companies_leads_tooltip": "prospectingTooltips-module___companies_leads_tooltip___qUkOw",
	"_start_to_finsih_tooltip": "prospectingTooltips-module___start_to_finsih_tooltip___iAq7b",
	"_on_cadence_tooltip": "prospectingTooltips-module___on_cadence_tooltip___vv3FQ",
	"_start_finish_image": "prospectingTooltips-module___start_finish_image___xpn8H",
	"_filters_tooltip": "prospectingTooltips-module___filters_tooltip___18A8d",
	"_on_cadence_filters_image": "prospectingTooltips-module___on_cadence_filters_image___e8BRn",
	"_on_cadence_start_tasks_image": "prospectingTooltips-module___on_cadence_start_tasks_image___fnm02"
};
export default ___CSS_LOADER_EXPORT___;
