/* jscpd:ignore-start */
import React from 'react';

import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import styles from './privacyPolicy.module.css';

export const PrivacyPolicy = () => {
  useDocumentTitle('Privacy Policy');
  return (
    <div className={styles.container}>
      <div className={styles.root}>
        <h1>Privacy policy</h1>
        <p className="p1">
          <span className="s1">
            <b>PRIVACY POLICY</b>
          </span>
        </p>
        <table className="t1" cellSpacing="0" cellPadding="0">
          <tbody>
          <tr>
            <td className="td1" colSpan="2" valign="top">
              <p className="p3">
                  <span className="s1">
                    <b>Basic Data Protection Information</b>
                  </span>
              </p>
            </td>
          </tr>
          <tr>
            <td className="td2" valign="top">
              <p className="p4">
                  <span className="s1">
                    <b>Controller</b>
                  </span>
              </p>
            </td>
            <td className="td3" valign="top">
              <p className="p4">BLOOBIRDS, S.L.</p>
            </td>
          </tr>
          <tr>
            <td className="td4" valign="top">
              <p className="p4">
                  <span className="s1">
                    <b>Purpose</b>
                  </span>
              </p>
            </td>
            <td className="td5" valign="top">
              <p className="p4">
                <span className="s1">Online services</span>
              </p>
              <p className="p4">
                <span className="s1">User experience</span>
              </p>
              <p className="p4">
                <span className="s1">Customer acquisition </span>
              </p>
            </td>
          </tr>
          <tr>
            <td className="td6" valign="top">
              <p className="p4">
                  <span className="s1">
                    <b>Legal bases</b>
                  </span>
              </p>
            </td>
            <td className="td7" valign="top">
              <p className="p5">
                <span className="s1">Express consent and legitimate interest</span>
              </p>
            </td>
          </tr>
          <tr>
            <td className="td8" valign="top">
              <p className="p4">
                  <span className="s1">
                    <b>Recipients</b>
                  </span>
              </p>
            </td>
            <td className="td9" valign="top">
              <p className="p5">
                  <span className="s1">
                    No data is transferred to third parties, except legal obligation
                  </span>
              </p>
            </td>
          </tr>
          <tr>
            <td className="td10" valign="top">
              <p className="p4">
                  <span className="s1">
                    <b>Rights</b>
                  </span>
              </p>
            </td>
            <td className="td11" valign="top">
              <p className="p4">
                  <span className="s1">
                    You have the right to request from us access to and rectification or erasure of
                    your personal data, the right to restrict processing, object to the processing
                    as well as in certain circumstances the right to data portability.
                  </span>
              </p>
            </td>
          </tr>
          <tr>
            <td className="td12" valign="top">
              <p className="p4">
                  <span className="s1">
                    <b>Additional information</b>
                  </span>
              </p>
            </td>
            <td className="td13" valign="top">
              <p className="p7">
                  <span className="s1">
                    Additional and detailed information on Data Protection can be found in the
                    attached clauses in https://bloobirds.wpengine.com/legal-advise/
                  </span>
              </p>
            </td>
          </tr>
          </tbody>
        </table>
        <p className="p4">
          <span className="s1">
            At<span className="Apple-converted-space"> BLOOBIRDS, S.L.</span> we work to offer you
            the best possible experience through our products and services. In some cases, it is
            necessary to collect information in order to achieve this. We care about your privacy
            and believe we should be transparent about it.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            For this reason, and for the purposes of the provisions of REGULATION (EU) 2016/679 OF
            THE EUROPEAN PARLIAMENT AND OF THE COUNCIL of 27 April 2016 (hereinafter
            &#8220;RGPD&#8221;) on the protection of individuals with regard to the processing of
            personal data and on the free movement of such data, and Law 34/2002, of 11 July, on
            Information Society Services and Electronic Commerce (hereinafter, &#8220;LSSI&#8221;),
            <span className="Apple-converted-space">BLOOBIRDS, S.L.</span> informs the user that, as
            the party responsible for processing, it will incorporate the personal data provided by
            users in an automated file.
          </span>
        </p>
        <p className="p4">
          <span className="s1">Our commitment begins by explaining the following:</span>
        </p>
        <p className="p4">
          <span className="s1">
            Your data is collected so that the user&#8217;s experience improves, attending to your
            interests and needs.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            We are transparent in relation to the data we obtain about you and the reason why we do
            so.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            Our intention is to offer you the best possible experience. Therefore, when we are going
            to use your personal information, we will always do so in compliance with the
            regulations, and when necessary, we will ask for your consent.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            We understand that your details belong to you. Therefore, if you decide not to allow us
            to process them, you can ask us to stop processing them.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            Our priority is to guarantee your security and to treat your data in accordance with
            European regulations.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            If you would like more information about how your data will be treated, please refer to
            the different sections of the privacy policy below:
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            <b>Who is the controller your personal data?</b>
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            Identity:<span className="Apple-converted-space"> BLOOBIRDS, S.L.</span>
          </span>
        </p>
        <p className="p4">
          <span className="s1">Registered office: </span>
          <span className="s2">Carrer de Lluçà 28, 2, 08028</span>
        </p>
        <p className="p4">
          <span className="s1">
            N.I.F. nº.:<span className="Apple-converted-space"> </span>
          </span>
          <span className="s2">B67294611</span>
        </p>
        <p className="p4">
          <span className="s1">
            Email:<span className="Apple-converted-space">privacy@bloobirds.com</span>
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            <b>What personal data do we collect? </b>
          </span>
        </p>
        <p className="p4">
          <span className="s1">The personal data that the user may provide:</span>
        </p>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">Name, address, and date of birth.</span>
          </li>
        </ul>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">Telephone number and email address.</span>
          </li>
        </ul>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">Location.</span>
          </li>
        </ul>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">Information regarding payments and returns.</span>
          </li>
        </ul>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">
              IP address, date and time you accessed our services, the Internet browser you use and
              data about the operating system of the device.
            </span>
          </li>
        </ul>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">Any other information or data you choose to share with us.</span>
          </li>
        </ul>
        <p className="p4">
          <span className="s1">
            In some cases, it is mandatory to fill out the registration form to access and enjoy
            certain services offered on the web, likewise, not providing the personal data requested
            or not accepting this data protection policy means the inability to subscribe, register
            or participate in any of the promotions in which personal data are requested.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            <b>Why do we treat your data?</b>
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            At BLOOBIRDS, S.L. we treat the information provided by interested people with the
            following purposes:
          </span>
        </p>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">
              Manage orders or hire any of our services, either online or in physical stores.
            </span>
          </li>
        </ul>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">Manage the sending of the information requested.</span>
          </li>
        </ul>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">Customer Acquisition </span>
          </li>
        </ul>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">
              In some cases, it will be necessary to provide information to the Authorities or third
              companies for auditing purposes, as well as handle personal data from invoices,
              contracts, and documents to respond to complaints from customers or Public
              Administrations.
            </span>
          </li>
        </ul>
        <p className="p4">
          <span className="s1">
            We inform you that the personal data obtained as a result of your registration as a user
            will form part of the Register of Activities and Treatment Operations (RAT), which will
            be updated periodically in accordance with the provisions of the RGPD.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            <b>What is the legitimacy for the treatment of your data?</b>
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            The processing of your data may be based on the following legal bases:
          </span>
        </p>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">
              Consent of the interested party for the contracting of services and products, for
              contact forms, requests for information or registration in e-newsletters.
            </span>
          </li>
        </ul>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">
              Fulfillment of legal obligations for fraud prevention, communication with public
              authorities and claims of third parties.
            </span>
          </li>
        </ul>
        <p className="p4">
          <span className="s1">
            <b>How long do we keep your data?</b>
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            The processing of data for the purposes described will be maintained for as long as
            necessary to meet the purpose of collection (for example, for the duration of the
            business relationship), as well as for compliance with legal obligations arising from
            the processing of data.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            <b>To which recipients will your data be communicated?</b>
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            In some cases, only when necessary, BLOOBIRDS, S.L. will provide user data to third
            parties. However, the data will never be sold to third parties. External service
            providers (e.g. payment providers or delivery companies) with whom BLOOBIRDS, S.L. works
            may use the data to provide the corresponding services, however, they will not use this
            information for their own purposes or for transfer to third parties.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            BLOOBIRDS, S.L. seeks to ensure the security of personal data when it is sent outside
            the company and ensures that third-party service providers respect confidentiality and
            have adequate measures in place to protect personal data. These third parties have an
            obligation to ensure that the information is treated in accordance with data privacy
            regulations.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            In some cases, the law may require that personal data be disclosed to public bodies or
            other parties, only what is strictly necessary for the fulfillment of such legal
            obligations will be disclosed.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            Personal data obtained may also be shared with other companies in the group.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            <b>Where is your data stored?</b>
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            BLOOBIRDS, S.L. strictly stores all data within the European Union to ensure the highest
            level of data protection. While we may process data outside the EU in alignment with our
            international operations and under the acceptance of our privacy policies, we guarantee
            that data processing by non-EU third parties adheres to equivalent standards of data
            protection. This processing activity is strictly limited to operational necessity and
            under no circumstances involves data storage outside the EU. Appropriate safeguards,
            such as Standard Contractual Clauses (SCCs), are implemented to ensure that these third
            parties maintain an adequate level of data protection in accordance with GDPR
            requirements. This practice is distinct and operates in conjunction with our policy on
            International Data Transfers to third countries, ensuring a consistent approach to data
            protection and privacy.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            <b>International Data Transfers to Third Countries</b>
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            BLOOBIRDS, S.L. engages with global services to enhance our offerings and operations.
            This includes engaging service providers located outside the European Economic Area
            (EEA), such as OpenAI, based in the United States, for purposes including but not
            limited to artificial intelligence and machine learning technologies to improve our
            services and customer experience.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            In compliance with the General Data Protection Regulation (GDPR) Article 46, BLOOBIRDS,
            S.L. has implemented appropriate safeguards to ensure that your personal data remains
            protected according to European data protection standards when transferred outside the
            EEA. Specifically, regarding our engagement with OpenAI:
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            1. <b>Legal Basis for Transfer:</b> The transfer of personal data to OpenAI is based on
            Standard Contractual Clauses (SCCs) approved by the European Commission, which
            contractually obligate OpenAI to ensure an adequate level of data protection and
            security comparable to that provided within the EEA.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            2. <b>Data Protection Measures:</b> OpenAI commits to maintaining robust technical and
            organizational measures to protect your personal data against unauthorized or unlawful
            processing and against accidental loss, destruction, or damage.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            3. <b>Rights of Data Subjects:</b> You retain all your rights under the GDPR, including
            the right to access, correct, delete, or transfer your data. BLOOBIRDS, S.L., in
            collaboration with OpenAI, will facilitate the exercise of these rights in respect to
            data processed by OpenAI.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            4. <b>Regulatory Oversight and Compliance:</b> Our relationship with OpenAI is
            structured to comply with GDPR requirements for international data transfers, including
            adherence to the guidelines set by the European Data Protection Board (EDPB) regarding
            codes of conduct and certification mechanisms as tools for safe data transfers.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            BLOOBIRDS, S.L. remains committed to upholding the highest standards of data privacy and
            security, continuously reviewing and updating our data protection measures to align with
            regulatory requirements and best practices.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            <b>What rights do you have and how can you exercise them?</b>
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            You can address your communications and exercise your rights by petition at the
            following email address{' '}
            <a href="mailto:privacy@bloobirds.com" target="_blank" rel="noopener noreferrer">
              privacy@bloobirds.com
            </a>
          </span>
        </p>
        <p className="p4">
          <span className="s1">Under the RGPD you can apply:</span>
        </p>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">
              Right of information: you can request information about the personal data that we have
              about you.
            </span>
          </li>
        </ul>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">
              Right of rectification: you can communicate any change in your personal data.
            </span>
          </li>
        </ul>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">
              Right to erasure: you can request the prior deletion blocking of personal data.
            </span>
          </li>
        </ul>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">
              Right to restriction of processing: this means restricting the processing of personal
              data.
            </span>
          </li>
        </ul>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">
              Right to data portability: in some cases, you can ask for a copy of the personal data
              in a structured format, commonly used and mechanically read for transmission to
              another data controller.
            </span>
          </li>
        </ul>
        <ul className="ul1">
          <li className="li4">
            <span className="s1">
              Right to object and automated individual decision-making: you can request that
              decisions not be taken which are based solely on automated processing, including
              profiling, which produces legal effects or significantly affects the data subject.
            </span>
          </li>
        </ul>
        <p className="p4">
          <span className="s1">
            In some cases, the request may be refused if you request that the data necessary for the
            fulfillment of legal obligations be deleted.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            Also, if you have a complaint about the processing of data you can make a complaint to
            the data protection authority.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            <b>Who is the controller for the accuracy and veracity of the data provided?</b>
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            The user is solely responsible for the accuracy and correctness of the data included,
            exonerating BLOOBIRDS, S.L. of any responsibility in this regard. Users guarantee and
            respond, in any case, the accuracy, validity, and authenticity of the personal data
            provided, and undertake to keep them properly updated. The user agrees to provide the
            complete and correct information in the registration or subscription form BLOOBIRDS,
            S.L. reserves the right to terminate the services contracted with users, in the event
            that the data provided are false, incomplete, inaccurate or are not updated.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            BLOOBIRDS, S.L. is not responsible for the veracity of the information that is not of
            its own elaboration and for which another source is indicated, therefore it does not
            assume any responsibility as far as hypothetical damages that could originate from the
            use of this information are concerned.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            BLOOBIRDS, S.L. reserves the right to update, modify or eliminate the information
            contained in its web pages and may even limit or deny access to said information
            BLOOBIRDS, S.L. of respondents are exonerated.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            The user also certifies that he or she is over 14 years of age and that he or she has
            the necessary legal capacity to give consent for the processing of his or her personal
            data.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            <b>How do we treat the personal data of minors?</b>
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            In principle, our services are not specifically aimed at minors. However, in the event
            that any of them is addressed to minors under fourteen years, in accordance with Article
            8 of the RGPD and Article 7 of LO3/2018 of 5 December (LOPDGDD), BLOOBIRDS, S.L. will
            require the valid, free, unequivocal, specific and informed consent of their legal
            guardians to process the personal data of minors. In this case, the identity card or
            other form of identification of the person giving consent will be required.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            In the case of persons over fourteen years of age, the data may be processed with the
            consent of the user, with the exception of those cases in which the Law requires the
            assistance of the holders of parental authority or guardianship.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            <b>Social Networks Policy </b>
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            BLOOBIRDS, S.L. has the following profiles in the main social networks of the Internet
            (Twitter, Instagram, Facebook, Youtube, Linkedin,).
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            It is recognized as responsible for the processing of data of its users, followers, or
            people who make comments through them. Also in accordance with the Law of information
            society services and electronic commerce
            <span className="Apple-converted-space"> BLOOBIRDS, S.L.</span>
            <span className="Apple-converted-space"> </span>is exonerated from any liability arising
            from comments from users and followers on their social networks.{' '}
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            BLOOBIRDS, S.L. may use the profiles described above to inform its users of issues it
            considers at an informative level, any events, articles that are published in the Blog
            or in any social network, as well as publish offers or provide personalized services for
            the customer.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            <span className="Apple-converted-space">BLOOBIRDS, S.L. </span>will not extract
            information from social networks, except when strictly necessary to warn the affected
            user.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            <b>What security measures do we apply to protect your personal data?</b>
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            BLOOBIRDS, S.L. has adopted the legally required levels of personal data protection
            security and tries to install those other means and additional technical measures within
            its reach to prevent the loss, misuse, alteration, unauthorized access and theft of
            personal data provided to<span className="Apple-converted-space"> BLOOBIRDS, S.L.</span>
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            <span className="Apple-converted-space">BLOOBIRDS, S.L. </span>is not responsible for
            any hypothetical damages that may arise from interferences, omissions, interruptions,
            computer viruses, telephone breakdowns or disconnections in the operation of this
            electronic system, caused by causes beyond the control of
            <span className="Apple-converted-space"> BLOOBIRDS, S.L.</span> of delays or blockages
            in the use of the present electronic system caused by deficiencies or overloads of
            telephone lines or overloads in the Data Processing Centre, in the Internet system or in
            other electronic systems, as well as of damages that may be caused by third parties
            through illegitimate intrusions beyond the control of BLOOBIRDS, S.L. However, the user
            must be aware that Internet security measures are not impregnable.
          </span>
        </p>
        <p>
          <strong>
            How do we process your personal data obtained through Gmail or other Google tools?
          </strong>
        </p>
        <p>
          Bloobirds will only use access to read, write, modify or control Google message bodies
          (including attachments), metadata, headers, and settings to provide a web email client
          that allows users to compose, send, read and process emails and will not transfer this
          Gmail data to others unless doing so is necessary to provide and improve these features,
          comply with applicable law, or as part of a merger, acquisition, or sale of assets.
        </p>
        <p>Bloobirds will not use this Google data for serving advertisements.</p>
        <p>
          Bloobirds will not allow humans to read this data unless we have your affirmative
          agreement for specific messages, doing so is necessary for security purposes such as
          investigating abuse, to comply with applicable law, or for Bloobirds&#8217; internal
          operations and even then only when the data have been aggregated and anonymized.
        </p>
        <p>
          Bloobirds&#8217; use of information received, and Bloobirds&#8217; transfer of information
          to any other app or platform, from Google APIs will adhere to Google’s Limited Use
          Requirements.
        </p>
        <p className="p4">
          <span className="s1">
            <b>Links to other websites</b>
          </span>
        </p>
        <p className="p4">
          <span className="s1">The website </span>
          <span className="s3">
            {' '}
            <a href="https://bloobirds..com/">
              <span className="s4">www.bloobirds.com</span>
            </a>
          </span>
          <span className="s1">
            {' '}
            may contain links to other websites. By clicking on one of these links and accessing an
            external website, the visit will be subject to the privacy policy of that website, being
            BLOOBIRDS, S.L. disassociated from any responsibility about its privacy policy.
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            <b>Can the privacy policy be modified?</b>
          </span>
        </p>
        <p className="p4">
          <span className="s1">
            This privacy policy may be modified. We recommend that you review the privacy policy
            from time to time.
          </span>
        </p>
      </div>
    </div>
  );
};
/* jscpd:ignore-end */
