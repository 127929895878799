import { useEffect, useRef } from 'react';



import { useUserSettings } from '@bloobirds-it/hooks';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  Bobject,
  SALESFORCE_LOGIC_ROLES,
} from '@bloobirds-it/types';
import {
  api,
  getRelatedBobject,
  getTextFromLogicRole,
  getValueFromLogicRole,
  getFieldByLogicRole,
  injectReferencesGetProcess,
  isUrl,
} from '@bloobirds-it/utils';
import useSWR, { useSWRConfig } from 'swr';


export interface SfdcRecord {
  id: string;
  type: string;
  activityId?: string;
}

export interface ActivityInfo {
  activity: Bobject;
  botId: string;
  recordCall: string;
  sfdcRecord: SfdcRecord;
  activityType: ACTIVITY_TYPES_VALUES_LOGIC_ROLE;
}

export interface Source {
  src: string;
  type: string;
}

export interface ActivityData {
  source: Source;
  loading: boolean;
}

export const getSignedCallRecordingUrl = async (recordCall: string) => {
  const oldRecordingRegex = /^(https:\/\/record-calls.bloobirds.com\/)(.{34})/g;
  let callSid = recordCall;
  const itsADeprecatedRecordingLink = recordCall.match(oldRecordingRegex);
  if (!itsADeprecatedRecordingLink && isUrl(recordCall)) {
    return recordCall;
  }
  if (recordCall && itsADeprecatedRecordingLink) {
    callSid = recordCall.split('/').at(-1);
  } else {
    callSid = recordCall.split('/')[1];
  }
  const signedUrl = await api.get(`/calls/whiteLabel/calls/${callSid}/recording`);
  if (signedUrl.status === 200) {
    return signedUrl.data.url;
  } else {
    throw new Error('Failed to get signed url');
  }
};

export const parseActivityForAnalysis = (bobject: Bobject): ActivityInfo => {
  const botId = getTextFromLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.BOT_ID);
  const recordCall = getValueFromLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.CALL_RECORD_URL);
  const activityType = getFieldByLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE)
    ?.valueLogicRole;
  const company = getRelatedBobject(bobject, 'Company');
  const lead = getRelatedBobject(bobject, 'Lead');

  const contactIdField = getValueFromLogicRole(lead, SALESFORCE_LOGIC_ROLES.CONTACT_ID_FIELD);
  const leadIdField = getValueFromLogicRole(lead, SALESFORCE_LOGIC_ROLES.LEAD_ID_FIELD);
  const accountIdField = getValueFromLogicRole(company, SALESFORCE_LOGIC_ROLES.ACCOUNT_ID_FIELD);

  let sfdcRecord: SfdcRecord | undefined;
  if (contactIdField) {
    sfdcRecord = { id: contactIdField, type: 'Contact', activityId: bobject.id.value };
  } else if (leadIdField) {
    sfdcRecord = { id: leadIdField, type: 'Lead', activityId: bobject.id.value };
  } else if (accountIdField) {
    sfdcRecord = { id: accountIdField, type: 'Account', activityId: bobject.id.value };
  }

  return {
    activity: bobject,
    botId,
    recordCall,
    sfdcRecord,
    //@ts-ignore
    activityType,
  };
};

export const useActivityAnalysis = (activityObjectIds: string[], currentIndex: number) => {
  const currentId = activityObjectIds[currentIndex];
  const mediaControllersRef = useRef<{ [key: string]: AbortController }>({});
  const activitiesControllersRef = useRef<{ [key: string]: AbortController }>({});
  const settings = useUserSettings();
  const swrConfig = useSWRConfig();
  const accountId = settings?.account?.id;
  const fetchActivity = async (id: string) => {
    const cachedActivity = swrConfig.cache.get(`activity/${currentId}`)?.data;
    if (cachedActivity) return injectReferencesGetProcess(cachedActivity);
    const controller = new AbortController();
    activitiesControllersRef.current[id] = controller;

    try {
      const response = await api.get(
        `/bobjects/${accountId}/Activity/${id}/form?injectReferences=true`,
        {
          signal: controller.signal,
        },
      );
      return injectReferencesGetProcess(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      delete activitiesControllersRef.current[id];
    }
  };

  const fetchMediaSource = async (activity: Bobject) => {
    const parsedActivity = parseActivityForAnalysis(activity);
    const controller = new AbortController();
    mediaControllersRef.current[activity.id.value] = controller;

    try {
      if (
        ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING === parsedActivity?.activityType &&
        parsedActivity.botId
      ) {
        const recordingRes = await api.get(`/messaging/recall/recording/${parsedActivity.botId}`, {
          signal: controller.signal,
        });
        return {
          src: `${recordingRes.data.url}#t=0.5`,
          type: 'video/mp4',
        };
      }

      if (
        ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL === parsedActivity?.activityType &&
        parsedActivity.recordCall
      ) {
        const signedUrl = await getSignedCallRecordingUrl(parsedActivity.recordCall);
        return { src: signedUrl, type: 'audio/mpeg' };
      }

      return null;
    } finally {
      delete mediaControllersRef.current[activity.id.value];
    }
  };
  // Fetch current activity
  const { data: currentActivity, error: currentError, isLoading } = useSWR(
    currentId && `activity/${currentId}`,
    () => fetchActivity(currentId),
    { revalidateOnFocus: false },
  );

  // Fetch media for current activity
  const { data: currentMedia } = useSWR(currentActivity ? `media/${currentId}` : null, () =>
    fetchMediaSource(currentActivity),
  );

  useEffect(() => {
    Object.entries({ ...mediaControllersRef.current, ...activitiesControllersRef.current }).forEach(
      ([id, controller]) => {
        if (
          ![
            currentId,
            activityObjectIds[currentIndex + 1],
            activityObjectIds[currentIndex - 1],
          ].includes(id)
        ) {
          console.log('Cancelled unnecesary request');
          controller.abort();
        }
      },
    );
    mediaControllersRef.current = {};
    activitiesControllersRef.current = {};
  }, [currentIndex]);

  return {
    activity: currentActivity,
    source: currentMedia,
    sfdcRecord: currentActivity && parseActivityForAnalysis(currentActivity).sfdcRecord,
    isLoading,
    error: currentError,
  };
};

export default useActivityAnalysis;