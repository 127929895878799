import { useMemo } from 'react';

import { UserPermission } from '@bloobirds-it/types';

/**
 * Decoupled / context free permissions hook
 * @param userPermissions Can recieve string array or undefined without crashing in the implemented comonent
 * when getting possible undefined like `user?.permissions`
 */
export const usePermissions = (
  userPermissions: string[] | undefined,
): Map<UserPermission, boolean> => {
  return useMemo(() => {
    const map = new Map<UserPermission, boolean>();

    if (userPermissions) {
      userPermissions.forEach(permission => {
        map.set(permission as UserPermission, true);
      });
    }

    return map;
  }, [userPermissions, Boolean(userPermissions)]);
};
