// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".simpleSelect-module__displayValue___TagPd {\n  line-height: 16px;\n  cursor: pointer;\n}\n\n.simpleSelect-module__dropdown___Ga8Wf {\n  max-height: 256px;\n  overflow-y: scroll;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/simpleSelect/simpleSelect.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".displayValue {\n  line-height: 16px;\n  cursor: pointer;\n}\n\n.dropdown {\n  max-height: 256px;\n  overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"displayValue": "simpleSelect-module__displayValue___TagPd",
	"dropdown": "simpleSelect-module__dropdown___Ga8Wf"
};
export default ___CSS_LOADER_EXPORT___;
