import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Item, Select, Text, Tooltip } from '@bloobirds-it/flamingo-ui';
import { TeamSortValues } from '@bloobirds-it/hooks';

import styles from '../../../../../fieldsPage/styles/fieldsPage.module.css';

export const TeamsFilters = ({
  onlyMineState,
  orderByState,
}: {
  onlyMineState: [boolean, () => void];
  orderByState: [string, (value: TeamSortValues) => void];
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.salesTeam.teamUsersPage.filters',
  });
  const [onlyMine, toggleOnlyMine] = onlyMineState;
  const [orderBy, setOrderBy] = orderByState;
  const [filtersTouched, setFiltersTouched] = useState(false);

  const resetFilters = () => {
    setOrderBy(undefined);
    if (onlyMine) toggleOnlyMine();
  };

  useEffect(() => {
    setFiltersTouched(!!onlyMine || !!orderBy);
  }, [onlyMine, orderBy]);

  return (
    <div className={styles.filters_container}>
      <Text htmlTag="span" size="s" color="softPeanut" className={styles.filters_title__container}>
        {t('sort')}:{' '}
      </Text>
      <div className={styles._filter__input}>
        <Select
          width="150px"
          size="small"
          placeholder={t('sort')}
          value={orderBy || null}
          borderless={false}
          onChange={v => setOrderBy(v)}
        >
          {Object.values(TeamSortValues).map(type => (
            <Item key={type} value={type}>
              {t('sortLabels.' + type)}
            </Item>
          ))}
        </Select>
      </div>
      <div className={styles.filters_separator} />
      <div className={styles._filter__input}>
        <Tooltip title={t('onlyAssociatedCheckbox')} position="top">
          <Checkbox
            checked={onlyMine}
            backgroundColor="verySoftBloobirds"
            size="small"
            onClick={toggleOnlyMine}
          >
            <Text color="peanut" size="s">
              {t('myTeamsCheckbox')}
            </Text>
          </Checkbox>
        </Tooltip>
      </div>
      {filtersTouched && (
        <Button variant="clear" iconLeft="cross" onClick={resetFilters} uppercase={false}>
          {t('clear')}
        </Button>
      )}
    </div>
  );
};
