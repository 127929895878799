// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chipField-module___container___x-yZn {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectForm/section/field/chipField/chipField.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV","sourcesContent":["._container {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "chipField-module___container___x-yZn"
};
export default ___CSS_LOADER_EXPORT___;
