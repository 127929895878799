// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".updateCampaignMemberStatusModal-module__content___jvBAh {\n  margin-bottom: 56px;\n}\n.updateCampaignMemberStatusModal-module__content___jvBAh p {\n  margin-bottom: 12px;\n}\n\n.updateCampaignMemberStatusModal-module__logo___LVP6h {\n  display: flex;\n  justify-content: center;\n}\n\n.updateCampaignMemberStatusModal-module__dropdown___\\+IXNW {\n  display: flex;\n  align-items: center;\n}\n\n.updateCampaignMemberStatusModal-module__dropdown___\\+IXNW > p {\n  margin-right: 8px;\n  margin-bottom: 0;\n}\n\n.updateCampaignMemberStatusModal-module__text___LnsNc {\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/updateCampaignMemberStatusModal/updateCampaignMemberStatusModal.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".content {\n  margin-bottom: 56px;\n}\n.content p {\n  margin-bottom: 12px;\n}\n\n.logo {\n  display: flex;\n  justify-content: center;\n}\n\n.dropdown {\n  display: flex;\n  align-items: center;\n}\n\n.dropdown > p {\n  margin-right: 8px;\n  margin-bottom: 0;\n}\n\n.text {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "updateCampaignMemberStatusModal-module__content___jvBAh",
	"logo": "updateCampaignMemberStatusModal-module__logo___LVP6h",
	"dropdown": "updateCampaignMemberStatusModal-module__dropdown___+IXNW",
	"text": "updateCampaignMemberStatusModal-module__text___LnsNc"
};
export default ___CSS_LOADER_EXPORT___;
