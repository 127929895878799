import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Text,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import { api } from '@bloobirds-it/utils';
import { mutate } from 'swr';

import styles from './mainObjects.module.css';

export const MainObjectConfirmDeleteModal = ({
  id,
  setOpen,
}: {
  id: string;
  setOpen: (open: boolean) => void;
}) => {
  const { createToast } = useToasts();
  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.relatedObjects.confirmDeleteModal',
  });

  const closeDeleteModal = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/utils/service/salesforce/relatedMainObjects/${id}`);
      closeDeleteModal();
      mutate('/utils/service/salesforce/relatedMainObjects');
      createToast({
        type: 'success',
        message: t('successMessage'),
      });
    } catch (error) {
      setOpen(false);
      createToast({
        type: 'error',
        message: t('errorMessage'),
      });
    }
  };

  return (
    <Modal width={600} open onClose={handleDelete}>
      <ModalHeader>
        <ModalTitle>{t('title')}</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <div className={styles.confirmCloseModalContent}>
          <Text size="m" align="center">
            {t('subtitle1')}
          </Text>
          <Text size="m" align="center" weight="bold">
            {t('subtitle2')}
          </Text>
          <Text size="m" align="center">
            {t('subtitle3')}
          </Text>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button variant="tertiary" onClick={closeDeleteModal}>
          {t('cancel')}
        </Button>
        <Button variant="primary" dataTest="deleteModalDeleteButton" onClick={handleDelete}>
          {t('confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
