// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subhomeContent-module___container___8H2fl {\n  background-color: var(--lightestGray);\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n  overflow-y: scroll;\n  justify-content: center;\n  padding-right: 0;\n}\n\n.subhomeContent-module___content_wrapper___Hbzbi {\n  padding: 36px 32px;\n  height: 100%;\n  width: 100%;\n  flex-grow: 1;\n  box-sizing: border-box;\n  display: flex;\n  justify-content: center;\n}\n\n.subhomeContent-module___content___qYIrW {\n  max-width: 1280px;\n  width: 100%;\n}\n\n.subhomeContent-module___startTasks__container___BV7Xj {\n  margin-left: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/subhomeLayout/subhomeContent/subhomeContent.module.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["._container {\n  background-color: var(--lightestGray);\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n  overflow-y: scroll;\n  justify-content: center;\n  padding-right: 0;\n}\n\n._content_wrapper {\n  padding: 36px 32px;\n  height: 100%;\n  width: 100%;\n  flex-grow: 1;\n  box-sizing: border-box;\n  display: flex;\n  justify-content: center;\n}\n\n._content {\n  max-width: 1280px;\n  width: 100%;\n}\n\n._startTasks__container {\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "subhomeContent-module___container___8H2fl",
	"_content_wrapper": "subhomeContent-module___content_wrapper___Hbzbi",
	"_content": "subhomeContent-module___content___qYIrW",
	"_startTasks__container": "subhomeContent-module___startTasks__container___BV7Xj"
};
export default ___CSS_LOADER_EXPORT___;
