// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".htmlMessage-module___email_wrapper___sUQx9 {\n  display: flex;\n  flex-direction: column;\n  font-size: 14px;\n  line-height: 24px;\n  overflow: hidden;\n}\n\n.htmlMessage-module___email_wrapper_trimmed___06MLn {\n  position: relative;\n  max-height: 156px;\n  overflow: hidden;\n}\n\n.htmlMessage-module___email_wrapper_trimmed___06MLn::after {\n  content: '';\n  top: 0;\n  bottom: 0;\n  height: 100%;\n  width: 100%;\n  pointer-events: none;\n  position: absolute;\n  background: linear-gradient(rgba(0, 0, 0, 0) 80%, white);\n}\n\n.htmlMessage-module__body___\\+dy\\+\\+ {\n  white-space: initial;\n}\n\n.htmlMessage-module__body___\\+dy\\+\\+ div[dir='ltr'] + blockquote {\n  opacity: 0.75;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/activitySection/activityItem/activityBody/htmlMessage/htmlMessage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,MAAM;EACN,SAAS;EACT,YAAY;EACZ,WAAW;EACX,oBAAoB;EACpB,kBAAkB;EAClB,wDAAwD;AAC1D;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;AACf","sourcesContent":["._email_wrapper {\n  display: flex;\n  flex-direction: column;\n  font-size: 14px;\n  line-height: 24px;\n  overflow: hidden;\n}\n\n._email_wrapper_trimmed {\n  position: relative;\n  max-height: 156px;\n  overflow: hidden;\n}\n\n._email_wrapper_trimmed::after {\n  content: '';\n  top: 0;\n  bottom: 0;\n  height: 100%;\n  width: 100%;\n  pointer-events: none;\n  position: absolute;\n  background: linear-gradient(rgba(0, 0, 0, 0) 80%, white);\n}\n\n.body {\n  white-space: initial;\n}\n\n.body div[dir='ltr'] + blockquote {\n  opacity: 0.75;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_email_wrapper": "htmlMessage-module___email_wrapper___sUQx9",
	"_email_wrapper_trimmed": "htmlMessage-module___email_wrapper_trimmed___06MLn",
	"body": "htmlMessage-module__body___+dy++"
};
export default ___CSS_LOADER_EXPORT___;
