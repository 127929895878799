// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".actionsModal-module___steps__section___ccTEF {\n  margin-top: 8px;\n  display: flex;\n  flex-direction: column;\n}\n.actionsModal-module___step___uWM8V {\n  padding-right: 104px;\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 32px;\n}\n.actionsModal-module___step__text___Z160\\+ {\n  align-self: center;\n  margin-right: 24px;\n  margin-left: 104px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/genericIntegrationLayout/actions/actionsModal/actionsModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,oBAAoB;EACpB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":["._steps__section {\n  margin-top: 8px;\n  display: flex;\n  flex-direction: column;\n}\n._step {\n  padding-right: 104px;\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 32px;\n}\n._step__text {\n  align-self: center;\n  margin-right: 24px;\n  margin-left: 104px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_steps__section": "actionsModal-module___steps__section___ccTEF",
	"_step": "actionsModal-module___step___uWM8V",
	"_step__text": "actionsModal-module___step__text___Z160+"
};
export default ___CSS_LOADER_EXPORT___;
