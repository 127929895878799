//test run modal
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  Callout,
  Icon,
  Item,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Select,
  Skeleton,
  Spinner,
  Text,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import {
  CopilotInsight,
  CopilotInsightVersion,
  TestRun,
  TestRunStatus,
  TestSet,
} from '@bloobirds-it/types';

import { useTestRuns } from '../hooks/useTestRuns';
import styles from './testRunModal.module.css';

interface ITestRunModalProps {
  onClose: () => void;
  insights: CopilotInsight[];
  selectedTestSet: TestSet;
  testRuns: TestRun[];
  mutateTestRuns: () => void;
}

const calloutColor: Record<TestRunStatus, 'negative' | 'alert'> = {
  COMPLETED: 'negative',
  RUNNING: 'negative',
  NOT_STARTED: 'alert',
};
const calloutMessage: Record<TestRunStatus, string> = {
  COMPLETED: 'completed',
  RUNNING: 'completed',
  NOT_STARTED: 'notStarted',
};

type VersionSelectValues = {
  withRun: CopilotInsightVersion[];
  withoutRun: CopilotInsightVersion[];
};
const EMPTY_VERSIONS: VersionSelectValues = { withRun: [], withoutRun: [] };
/**
 * IMPORTANT: This modal is open by default to guarantee proper reset when rerendering, the
 * open status should be managed outside the modal in the implementation
 * example:
 * {openTestRunModal && <TestRunModal onClose={onClose} />}
 * @param onClose callback that will set the conditional boolean to false
 */
export const TestRunModal = ({
  onClose,
  insights = [],
  selectedTestSet,
  testRuns = [],
  mutateTestRuns,
}: ITestRunModalProps) => {
  const { t: baseT } = useTranslation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'ai.testRunModal',
  });
  const [selectedInsight, setSelectedInsight] = useState<CopilotInsight>(null);

  const { createTestRun, getInsightVersions } = useTestRuns();
  const [versions, setVersions] = useState<VersionSelectValues>(EMPTY_VERSIONS);
  const [selectedVersion, setSelectedVersion] = useState<CopilotInsightVersion | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [loadingVersions, setLoadingVersions] = useState(false);
  const { createToast } = useToasts();

  const handleInsightChange = (insight: CopilotInsight) => {
    setSelectedVersion(null);
    setSelectedInsight(insight);
  };
  const handleVersionChange = (value: any) => {
    setSelectedVersion(value);
  };

  const uniqueInsights = new Map<string, CopilotInsight>([]);

  insights?.forEach(ins => {
    const discriminators = ['All', ins.activityType];
    if (discriminators.includes(selectedTestSet.activity_type) || ins.activityType === 'All') {
      uniqueInsights.set(ins.id, ins);
    }
  });

  useEffect(() => {
    if (selectedInsight?.id) {
      setLoadingVersions(true);
      getInsightVersions(selectedInsight?.id)
        .then(payload => {
          const newVersions: VersionSelectValues = {
            withRun: [],
            withoutRun: [],
          };
          const testrunMap = new Map(testRuns.map(run => [run.insight_version_id, true]));

          (payload.data || []).forEach(insightVersion => {
            if (testrunMap.has(insightVersion.id)) {
              newVersions.withRun.push(insightVersion);
            } else {
              newVersions.withoutRun.push(insightVersion);
            }
          });
          setVersions(newVersions);
          setLoadingVersions(false);
        })
        .catch(error => {
          console.error(error);
          setLoadingVersions(false);
        });
    }
  }, [selectedInsight?.id]);
  const onSave = async () => {
    try {
      setSubmitting(true);
      await createTestRun({
        testSetId: selectedTestSet?.pk,
        insightId: selectedVersion.id,
      });
      setSubmitting(false);
      mutateTestRuns();
      onClose();
    } catch (error) {
      setSubmitting(false);
      createToast({ type: 'error', message: error?.response?.data?.detail ?? t('createError') });
    }
  };

  const foundTestRun = selectedVersion
    ? testRuns.find(testRun => testRun.insight_version_id === selectedVersion.id)
    : null;

  return (
    <Modal open onClose={!submitting ? onClose : null} width={900}>
      <ModalHeader color="verySoftPurple" size="small">
        <ModalTitle color="peanut" icon="test" size="small">
          {t('title')}
        </ModalTitle>
        <ModalCloseIcon color="purple" size="small" onClick={onClose} />
      </ModalHeader>

      <ModalContent className={styles.contentContainer}>
        <Text size="m">
          <Trans
            i18nKey="ai.testRunModal.description"
            values={{ testSetName: selectedTestSet?.name }}
          />
        </Text>
        <div className={styles.insightAndPreviewContainer}>
          <div className={styles.insightContainer}>
            <Text size="s" uppercase color="softPeanut">
              {t('insightAndVersion.title')}
            </Text>
            <Select
              value={selectedInsight}
              placeholder={t('insight')}
              onChange={handleInsightChange}
              width="100%"
            >
              {[...uniqueInsights.values()].map(ins => (
                <Item key={ins.title} value={ins} label={ins.title} className={styles.selectOption}>
                  {ins.title}
                </Item>
              ))}
            </Select>
            {selectedInsight ? (
              <VersionSelect
                loadingVersions={loadingVersions}
                selectedVersion={selectedVersion}
                handleVersionChange={handleVersionChange}
                versions={versions}
              />
            ) : (
              <div className={styles.selectInsightPlaceholder}>
                <Icon name="search" color="softPeanut" size={30} />
                <Text size="s" color="softPeanut" align="center">
                  {t('insightAndVersion.placeholder')}
                </Text>
              </div>
            )}
            {selectedVersion && foundTestRun && (
              <Callout
                width="100%"
                icon="alertTriangle"
                variant={calloutColor[foundTestRun.status]}
              >
                <Text size="s">
                  <Trans
                    i18nKey={`ai.testRunModal.foundVersionCallout.${
                      calloutMessage[foundTestRun.status]
                    }`}
                  />
                </Text>
              </Callout>
            )}
          </div>
          <div className={styles.previewContainer}>
            <Text size="s" uppercase color="softPeanut">
              {t('insightPromptPreview.title')}
            </Text>
            {selectedVersion ? (
              <div className={styles.prompt}>{selectedVersion.prompt}</div>
            ) : (
              <div className={styles.promptPlaceholder}>
                <Icon name="alignLeft" color="softPeanut" size={30} />
                <Text size="s" color="softPeanut" align="center">
                  {t('insightPromptPreview.placeholder')}
                </Text>
              </div>
            )}
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          variant="tertiary"
          uppercase={false}
          onClick={onClose}
          disabled={submitting}
          color="softPurple"
        >
          {baseT('common.cancel')}
        </Button>
        <Button
          uppercase={false}
          className={styles.create_test_run_button}
          disabled={!!foundTestRun || foundTestRun === null || submitting}
          onClick={onSave}
          color={!!foundTestRun || foundTestRun === null ? 'lightestGray' : 'purple'}
        >
          {submitting ? <Spinner color="white" size={18} name="dots" /> : t('createTestRun')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

interface IVersionSelectProps {
  loadingVersions: boolean;
  selectedVersion: CopilotInsightVersion | null;
  handleVersionChange: (value: any) => void;
  versions: VersionSelectValues;
}
const VersionSelect = ({
  loadingVersions,
  selectedVersion,
  handleVersionChange,
  versions,
}: IVersionSelectProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'ai.testRunModal',
  });

  return !loadingVersions ? (
    <Select
      value={selectedVersion}
      placeholder={t('version')}
      onChange={handleVersionChange}
      width="100%"
    >
      {versions.withoutRun.length > 0 && (
        <Text className={styles.versionSelectSection} size="s" color="softPeanut">
          {t('withoutTestRun')}
        </Text>
      )}
      {versions.withoutRun.map(insightWV => (
        <Item key={insightWV.id} value={insightWV} label={`${t('version')} ${insightWV.version}`}>
          {`${t('version')} ${insightWV.version}`}
        </Item>
      ))}
      {versions.withRun.length > 0 && (
        <Text className={styles.versionSelectSection} size="s" color="softPeanut">
          {t('withTestRun')}
        </Text>
      )}
      {versions.withRun.map(insightWV => (
        <Item key={insightWV.id} value={insightWV} label={`${t('version')} ${insightWV.version}`}>
          {`${t('version')} ${insightWV.version}`}
        </Item>
      ))}
    </Select>
  ) : (
    <Skeleton height={60} width="100%" variant="text" />
  );
};
