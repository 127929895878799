// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".metric-module___statistics_item___SW5zK {\n  display: flex;\n}\n\n.metric-module___statistics_item___SW5zK > p {\n  cursor: default;\n  margin-left: 4px;\n  margin-right: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/messagingSectionLayout/messagingTemplateCard/metric/metric.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["._statistics_item {\n  display: flex;\n}\n\n._statistics_item > p {\n  cursor: default;\n  margin-left: 4px;\n  margin-right: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_statistics_item": "metric-module___statistics_item___SW5zK"
};
export default ___CSS_LOADER_EXPORT___;
