import { useController } from 'react-hook-form';

import { DateTimePicker } from '@bloobirds-it/flamingo-ui';

import { FormGroup, FormLabel } from '../../formGroup/formGroup';
import { FormFieldProps } from '../baseInput/baseInput';
import styles from './dateField.module.css';

export const DateField = ({
  control,
  required,
  name,
  id,
  size = 'small',
  requiredMessage,
  hideLabel,
  formKey,
  withFormGroup = true,
}: FormFieldProps) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name: formKey ?? `fields.${id}`,
    rules: {
      required: {
        value: required,
        message: requiredMessage,
      },
    },
  });

  const simpleDateField = (
    <>
      {size === 'small' && !hideLabel && <FormLabel required={required}>{name}</FormLabel>}
      <div className={styles.datepicker}>
        <DateTimePicker
          value={value}
          placeholder={size === 'small' ? undefined : `${name}${required ? ' *' : ''}`}
          onChange={onChange}
          error={error?.message}
          width="100%"
          size={size}
        />
      </div>
    </>
  );

  return withFormGroup ? (
    <FormGroup size={hideLabel ? undefined : size}>{simpleDateField}</FormGroup>
  ) : (
    simpleDateField
  );
};
