// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DataScope-module__content___j6wOl {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n}\n\n.DataScope-module__content___j6wOl > *:first-child {\n  align-self: center;\n}\n\n.DataScope-module__content___j6wOl > *:last-child {\n  align-self: stretch;\n}\n\n.DataScope-module__footer___ink4g {\n  margin-left: 20px;\n}\n\n.DataScope-module__footer___ink4g > * {\n  margin-right: 4px;\n}\n\n.DataScope-module__button___TGIUs {\n  cursor: pointer;\n  width: 24px;\n  height: 24px;\n  border: 1px solid var(--veryLightBloobirds);\n  background: white;\n  overflow: hidden;\n  border-radius: 4px;\n  padding: 0;\n}\n\n.DataScope-module__button___TGIUs:disabled {\n  cursor: default;\n  background: var(--lighterGray);\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/dashboardPages/v1/panels/shared/DataScope/DataScope.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,2CAA2C;EAC3C,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,8BAA8B;AAChC","sourcesContent":[".content {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n}\n\n.content > *:first-child {\n  align-self: center;\n}\n\n.content > *:last-child {\n  align-self: stretch;\n}\n\n.footer {\n  margin-left: 20px;\n}\n\n.footer > * {\n  margin-right: 4px;\n}\n\n.button {\n  cursor: pointer;\n  width: 24px;\n  height: 24px;\n  border: 1px solid var(--veryLightBloobirds);\n  background: white;\n  overflow: hidden;\n  border-radius: 4px;\n  padding: 0;\n}\n\n.button:disabled {\n  cursor: default;\n  background: var(--lighterGray);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "DataScope-module__content___j6wOl",
	"footer": "DataScope-module__footer___ink4g",
	"button": "DataScope-module__button___TGIUs"
};
export default ___CSS_LOADER_EXPORT___;
