// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".passwordResetForm-module__container___VQs8m {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n}\n\n.passwordResetForm-module__banner___OAzFV {\n  background: white;\n  box-shadow: 0 2px 8px rgba(197, 209, 221, 0.3);\n  padding: 30px 0;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n}\n\n.passwordResetForm-module__content___pFUxw {\n  width: 400px;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.passwordResetForm-module__passwordInputs___RSrHZ {\n  margin-top: 16px;\n}\n\n.passwordResetForm-module__passwordInputs___RSrHZ > *:first-child {\n  margin-bottom: 16px;\n}\n\n.passwordResetForm-module__saveButton___ZKGbE {\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/externalActionsPages/resetPassword/passwordResetForm/passwordResetForm.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,8CAA8C;EAC9C,eAAe;EACf,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n}\n\n.banner {\n  background: white;\n  box-shadow: 0 2px 8px rgba(197, 209, 221, 0.3);\n  padding: 30px 0;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n}\n\n.content {\n  width: 400px;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.passwordInputs {\n  margin-top: 16px;\n}\n\n.passwordInputs > *:first-child {\n  margin-bottom: 16px;\n}\n\n.saveButton {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "passwordResetForm-module__container___VQs8m",
	"banner": "passwordResetForm-module__banner___OAzFV",
	"content": "passwordResetForm-module__content___pFUxw",
	"passwordInputs": "passwordResetForm-module__passwordInputs___RSrHZ",
	"saveButton": "passwordResetForm-module__saveButton___ZKGbE"
};
export default ___CSS_LOADER_EXPORT___;
