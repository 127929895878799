import React, { useEffect, useMemo, useState } from 'react';

import { Icon, IconButton, IconType, Text } from '@bloobirds-it/flamingo-ui';
import {
  useMinimizableModals,
} from '@bloobirds-it/hooks';
import { NoteEditor } from '@bloobirds-it/notes';
import {
  Bobject,
  ACTIVITY_FIELDS_LOGIC_ROLE,
  COMPANY_FIELDS_LOGIC_ROLE,
  LEAD_FIELDS_LOGIC_ROLE,
} from '@bloobirds-it/types';
import {
  getReferencedBobjectFromLogicRole,
  getTextFromLogicRole,
  isHtml,
  linkify,
} from '@bloobirds-it/utils';

import { CopyButton } from './copyButton';
import styles from './noteBox.module.css';

interface NoteBoxProps {
  activity: Bobject;
  isSmallBlock?: boolean;
  icon: IconType;
  title: string;
}

export const NoteBox = ({ activity, title, icon }: NoteBoxProps) => {
  const [openFloatingNote, setOpenFloatingNote] = useState(false);
  const {
    openMinimizableModal,
    minimizableModals,
    openMinimizableModalById,
    closeMinimizableModalById,
  } = useMinimizableModals();

  const note = useMemo(
    () => linkify(getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.NOTE)),
    [activity],
  );
  const [data, setData] = useState(note);

  useEffect(() => {
    setData(note);
  }, [note]);

  const openNoteModal = () => {
    setOpenFloatingNote(true);

    const find = minimizableModals?.find(
      modal => modal?.type === 'internalNote' && modal?.data?.bobjectId === activity?.id.value,
    );
    if (find) {
      openMinimizableModalById(find.id);
    } else {
      const activityType = getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE);
      const activityTitle = getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.TITLE);
      const activityDirection = getTextFromLogicRole(
        activity,
        ACTIVITY_FIELDS_LOGIC_ROLE.DIRECTION,
      );
      const activityLead = getReferencedBobjectFromLogicRole(
        activity,
        ACTIVITY_FIELDS_LOGIC_ROLE.LEAD,
      );
      const activityLeadName = getTextFromLogicRole(activityLead, LEAD_FIELDS_LOGIC_ROLE.FULL_NAME);
      const activityCompany = getReferencedBobjectFromLogicRole(
        activity,
        ACTIVITY_FIELDS_LOGIC_ROLE.COMPANY,
      );
      const activityCompanyName = getTextFromLogicRole(
        activityCompany,
        COMPANY_FIELDS_LOGIC_ROLE.NAME,
      );

      openMinimizableModal({
        type: 'internalNote',
        data: {
          bobjectId: activity?.id.value,
          activityInfo: {
            activityType,
            activityLeadName,
            activityCompanyName,
            activityDirection,
            activityTitle,
          },
          [ACTIVITY_FIELDS_LOGIC_ROLE.NOTE]: data,
          cb: () => {
            closeNoteModal();
          },
        },
      });
    }
  };

  const closeNoteModal = () => {
    setOpenFloatingNote(false);
    const find = minimizableModals?.find(
      modal => modal?.type === 'internalNote' && modal?.data?.bobjectId === activity?.id.value,
    );
    if (find) {
      closeMinimizableModalById(find.id);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles.text}>
          <Icon name={icon} color="bloobirds" />
          <Text size="xs" weight="heavy">
            {title}
          </Text>
        </div>
        <div className={styles.buttons}>
          <IconButton
            name={openFloatingNote ? 'sidepeek' : 'floatingpeek'}
            color="bloobirds"
            onClick={() => (openFloatingNote ? closeNoteModal() : openNoteModal())}
            size={16}
          />
          {!openFloatingNote && (
            <CopyButton alwaysDisplay textToCopy={data} htmlFormat={isHtml(data)} />
          )}
        </div>
      </div>
      <NoteEditor
        id={activity?.id.value}
        data={data}
        setData={setData}
        openFloatingNote={openFloatingNote}
      />
    </div>
  );
};
