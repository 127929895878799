import {
  COMPANY_SALES_STATUS_VALUES_LOGIC_ROLE,
  LEAD_SALES_STATUS_VALUES_LOGIC_ROLE,
} from '@bloobirds-it/types';

export const AVAILABLE_COMPANY_STATUSES = [
  COMPANY_SALES_STATUS_VALUES_LOGIC_ROLE.ACTIVE,
  COMPANY_SALES_STATUS_VALUES_LOGIC_ROLE.ON_HOLD,
  COMPANY_SALES_STATUS_VALUES_LOGIC_ROLE.NURTURING,
  COMPANY_SALES_STATUS_VALUES_LOGIC_ROLE.DISCARDED,
  COMPANY_SALES_STATUS_VALUES_LOGIC_ROLE.CLIENT,
];

export const AVAILABLE_LEAD_STATUSES = [
  LEAD_SALES_STATUS_VALUES_LOGIC_ROLE.ACTIVE,
  LEAD_SALES_STATUS_VALUES_LOGIC_ROLE.ON_HOLD,
  LEAD_SALES_STATUS_VALUES_LOGIC_ROLE.NURTURING,
  LEAD_SALES_STATUS_VALUES_LOGIC_ROLE.DISCARDED,
  LEAD_SALES_STATUS_VALUES_LOGIC_ROLE.CLIENT,
];
