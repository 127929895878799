// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subhomeSkeleton-module___list___zjdQ1 {\n  margin-top: 32px;\n}\n\n.subhomeSkeleton-module___date_title___YUNfn {\n  margin-bottom: 8px;\n  width: 100%;\n}\n\n.subhomeSkeleton-module___card___43Z9b {\n  margin-bottom: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/subhomeLayout/subhomeContent/subhomeContentSkeleton/subhomeSkeleton.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["._list {\n  margin-top: 32px;\n}\n\n._date_title {\n  margin-bottom: 8px;\n  width: 100%;\n}\n\n._card {\n  margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_list": "subhomeSkeleton-module___list___zjdQ1",
	"_date_title": "subhomeSkeleton-module___date_title___YUNfn",
	"_card": "subhomeSkeleton-module___card___43Z9b"
};
export default ___CSS_LOADER_EXPORT___;
