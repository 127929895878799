import React from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsible, Text } from '@bloobirds-it/flamingo-ui';

import { RequestedPhone } from '../../../constants/phoneNumbers.constants';
import { useRequestNumberFormContext } from '../../../context/requestedPhonesFormProvider';
import styles from '../../../styles/phoneNumberPage.module.css';
import { NavigationButtons } from '../navigationButtons';
import { PhoneCard } from '../orderReview/phoneCard';
import { NoPhonesRequested } from '../shared/noPhonesRequested';
import { AdditionalInformation } from './additionalInformation';
import { CapabilitiesCheckbox } from './capabilitiesCheckbox';
import { FormPurchasePhone } from './formPurchasePhone';

export const PurchasePhoneNumberGeneralInfo = () => {
  const { t } = useTranslation();
  const { phoneNumberList } = useRequestNumberFormContext();

  return (
    <div className={styles.phoneNumberInformationContainer}>
      <Text size="m" weight="heavy">
        {t('accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.phoneNumberInformation')}
      </Text>
      <Text size="xs" color="softPeanut" weight="regular">
        {t(
          'accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.phoneNumberInformationSubtext',
        )}
      </Text>
      <FormPurchasePhone />
      <Text size="m" weight="heavy">
        {t(
          'accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.phoneNumberCapabilities.title',
        )}
      </Text>
      <Text size="xs" color="softPeanut" weight="regular">
        {t(
          'accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.phoneNumberCapabilities.subtext',
        )}
      </Text>
      <CapabilitiesCheckbox />

      <Text size="m" weight="heavy">
        {t('accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.additionalInformation.title')}
      </Text>
      <Text size="xs" color="softPeanut" weight="regular">
        {t(
          'accountSettings.phoneNumbers.purchaseNewPhone.generalInfo.additionalInformation.subtext',
        )}
      </Text>
      <AdditionalInformation />
      <div className={styles.dividerOrderReview}></div>
      <Collapsible
        color="softPeanut"
        arrowPosition="right"
        className={styles.collapsableGeneralInfo}
        title={t('accountSettings.phoneNumbers.purchaseNewPhone.orderReview.requestedPhones')}
      >
        {phoneNumberList.length === 0 ? (
          <NoPhonesRequested />
        ) : (
          phoneNumberList.map((phoneNumber, index) => (
            <PhoneCard key={index} phone={phoneNumber as RequestedPhone} />
          ))
        )}
      </Collapsible>

      <NavigationButtons />
    </div>
  );
};
