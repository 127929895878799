import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown, IconButton, Tooltip } from '@bloobirds-it/flamingo-ui';
import { ACTIVITY_DIRECTION } from '@bloobirds-it/types';

import { ConfirmDeleteMessage } from '../confirmDeleteMessage/ConfirmDeleteMessage';
import styles from './deleteMessage.module.css';

export const DeleteMessage = ({
  onClick,
  isDisabled,
  messageDirection,
}: {
  onClick: () => void;
  isDisabled: boolean;
  messageDirection: ACTIVITY_DIRECTION;
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Dropdown
        position={messageDirection === ACTIVITY_DIRECTION.INCOMING ? 'right' : 'left'}
        visible={visible}
        anchor={
          <Tooltip
            className={styles.deleteButton}
            title={t('activityTimelineItem.item.deleteMessage')}
            position="top"
          >
            <IconButton name="trashFull" onClick={() => setVisible(true)} disabled={isDisabled} />
          </Tooltip>
        }
      >
        <ConfirmDeleteMessage
          onClick={() => {
            onClick();
            setVisible(false);
          }}
          onCancel={() => setVisible(false)}
          message={t('activityTimelineItem.item.deleteMessageInfo')}
        />
      </Dropdown>
    </>
  );
};
