import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CopilotActivityContextProvider, isTranscriptActivity } from '@bloobirds-it/copilot';
import { TabGroup } from '@bloobirds-it/flamingo-ui';
import {
  CopilotActivityType,
  ACTIVITY_FIELDS_LOGIC_ROLE,
  AIResults,
  TestSet,
  TestRun,
  CopilotInsight,
} from '@bloobirds-it/types';
import { getFieldByLogicRole } from '@bloobirds-it/utils';

import LeftSideAiAnalysis from '../../../aiAnalysisPages/leftSideAiAnalysis';
import { DetailsAiAnalysis } from '../../../aiAnalysisPages/leftSideAiAnalysis/detailsAiAnalysis/detailsAiAnalysis';
import InfoAiAnalysis from '../../../aiAnalysisPages/leftSideAiAnalysis/infoAiAnalysis';
import RightSideAiAnalysis from '../../../aiAnalysisPages/rightSideAiAnalysis';
import { SummaryUI } from '../../../aiAnalysisPages/rightSideAiAnalysis/summary/summary';
import Tab from '../../../aiAnalysisPages/rightSideAiAnalysis/tabAiAnalysis';
import Transcript from '../../../aiAnalysisPages/rightSideAiAnalysis/transcript/transcript';
import useActivityAnalysis from '../../../aiAnalysisPages/useActivityAnalysis';
import styles from './activityCarouselTestSetReview.module.css';
import { Result } from './result';

export interface IActivityCarouselTestSetReviewProps {
  activityType: CopilotActivityType | 'Call' | 'Meeting' | 'LinkedIn Message' | 'All';
  activityResults: AIResults;
  selectedActivityIndex: number;
  selectedTestSet: TestSet;
  selectedTestRun: TestRun;
  selectedInsight: CopilotInsight;
}
export const ActivityCarouselTestSetReview = ({
  activityType,
  activityResults = {},
  selectedActivityIndex,
  selectedTestSet,
  selectedTestRun,
  selectedInsight,
}: IActivityCarouselTestSetReviewProps) => {
  const [player, setPlayer] = useState<Player | null>(null);
  const { t } = useTranslation();
  const { activity, isLoading, source, sfdcRecord } = useActivityAnalysis(
    Object.keys(activityResults || {}),
    selectedActivityIndex,
  );
  const testRunActivitiesType = getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE)
    ?.valueLogicRole;
  const isTranscriptAct = isTranscriptActivity(
    activityType !== 'All' ? activityType : testRunActivitiesType,
  );

  const currentActivityId = activity?.id?.objectId;

  const currentActivitySummary = selectedTestSet?.activities?.[currentActivityId]?.summary;
  const currentActivityTestRunResults = selectedTestRun?.results?.[currentActivityId];

  return (
    <CopilotActivityContextProvider activity={activity}>
      <div className={styles.container}>
        <LeftSideAiAnalysis>
          <InfoAiAnalysis activity={activity} isLoading={false} activityType={activityType} />
          <DetailsAiAnalysis
            activity={activity}
            isLoading={isLoading}
            source={source}
            sfdcRecord={sfdcRecord}
            player={player}
            setPlayer={setPlayer}
            activityType={activityType !== 'All' ? activityType : testRunActivitiesType}
          />
        </LeftSideAiAnalysis>
        <RightSideAiAnalysis>
          <TabGroup>
            <Tab active name={t('ai.playground.activityTab.result')} icon="magic">
              <Result
                activityResult={currentActivityTestRunResults}
                selectedInsight={selectedInsight}
                selectedRun={selectedTestRun}
              />
            </Tab>
            <Tab name={t('ai.aiAnalysis.summary')} icon="snippet">
              <SummaryUI summary={currentActivitySummary} />
            </Tab>
            {isTranscriptAct && (
              <Tab name={t('ai.aiAnalysis.transcript')} icon="alignCenter">
                <Transcript
                  onClick={time => player?.currentTime(time)}
                  activityType={testRunActivitiesType}
                />
              </Tab>
            )}
          </TabGroup>
        </RightSideAiAnalysis>
      </div>
    </CopilotActivityContextProvider>
  );
};
