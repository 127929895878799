// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".syncSettingsTab-module___container___jvxpa {\n  margin-top: 28px;\n}\n.syncSettingsTab-module___container___jvxpa > div > div > div > p {\n  color: var(--peanut);\n}\n.syncSettingsTab-module___tabTitle___QhBR6 {\n  display: flex;\n}\n.syncSettingsTab-module___radio_group_container___thhTL {\n  margin-top: 24px;\n  width: 617px;\n}\n.syncSettingsTab-module__loader___LVlNo {\n  margin-top: 35vh;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n}\n\n.syncSettingsTab-module__spinner___fQ6QS {\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/layouts/iAppIntegrationLayout/syncSettingsTab/syncSettingsTab.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["._container {\n  margin-top: 28px;\n}\n._container > div > div > div > p {\n  color: var(--peanut);\n}\n._tabTitle {\n  display: flex;\n}\n._radio_group_container {\n  margin-top: 24px;\n  width: 617px;\n}\n.loader {\n  margin-top: 35vh;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n}\n\n.spinner {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "syncSettingsTab-module___container___jvxpa",
	"_tabTitle": "syncSettingsTab-module___tabTitle___QhBR6",
	"_radio_group_container": "syncSettingsTab-module___radio_group_container___thhTL",
	"loader": "syncSettingsTab-module__loader___LVlNo",
	"spinner": "syncSettingsTab-module__spinner___fQ6QS"
};
export default ___CSS_LOADER_EXPORT___;
