import { LocalStorageKeys } from '@bloobirds-it/types';

import { api, appHostnames } from './api';
import { MessagesEvents } from './types/events';

export async function isLoggedIn() {
  return new Promise(resolve => {
    chrome?.storage?.sync?.get({ token: '' }, items => {
      resolve(!!items.token);
    });
  });
}

export function getAuthUrl(): string {
  const host = window.location.hostname;
  switch (host) {
    case 'localhost':
      return 'http://localhost:5173';
    case 'app.dev-bloobirds.com':
      return 'https://auth.dev-bloobirds.com';
    case 'app.bloobirds.com':
      return 'https://auth.bloobirds.com';
    default: {
      const env = process.env.ENV;
      switch (env) {
        case 'development':
          return 'https://auth.dev-bloobirds.com';
        case 'production':
          return 'https://auth.bloobirds.com';
        default:
          return `http://localhost:5173`;
      }
    }
  }
}

export function logoutExtension() {
  return new Promise(resolve => {
    if (chrome && chrome.storage && chrome.storage.sync) {
      chrome?.storage?.sync?.set({ token: '', refreshToken: '', hashedFingerprint: '' }, () => {
        window.dispatchEvent(new CustomEvent(MessagesEvents.UserLoggedOut));
        localStorage.removeItem(LocalStorageKeys.LastVisitedBobjects);
        chrome?.runtime?.sendMessage({
          action: 'logoutAllTabs',
        });
        resolve('');
      });
    } else {
      resolve(null);
    }
  });
}

export function getAuthTokens(encoded) {
  return new Promise(resolve => {
    if (chrome && chrome.storage && chrome.storage.sync) {
      chrome?.storage?.sync?.get(
        { token: '', refreshToken: '', hashedFingerprint: '' },
        ({ token, refreshToken, hashedFingerprint }) => {
          if (token) {
            if (!encoded) {
              const contents = JSON.parse(atob(token.split('.')[1]));
              resolve(contents);
            } else {
              resolve({ token, refreshToken, hashedFingerprint });
            }
          } else {
            resolve(null);
          }
        },
      );
    } else {
      resolve(null);
    }
  });
}

export async function getTokenExt() {
  return await getAuthTokens(false);
}

export async function getTokenExtEncoded() {
  return await getAuthTokens(true);
}

export async function getAccountId() {
  if (
    appHostnames.includes(window.location.hostname) ||
    window.location.hostname.includes('bloobirds-platform-frontend.pages.dev')
  ) {
    const session = localStorage.getItem('bb-app-session');
    if (session) {
      return JSON.parse(session)?.context?.account?.id || null;
    }
    return null;
  }
  const token = await getTokenExt();
  // @ts-ignore
  return token?.account;
}

export async function getUserId() {
  if (
    appHostnames.includes(window.location.hostname) ||
    window.location.hostname.includes('bloobirds-platform-frontend.pages.dev')
  ) {
    const session = localStorage.getItem('bb-app-session');
    if (session) {
      return JSON.parse(session)?.context?.user?.id || null;
    }
    return null;
  } else {
    const token = await getTokenExt();
    // @ts-ignore
    return token?.sub;
  }
}

export async function getUserName() {
  const token = await getTokenExt();
  // @ts-ignore
  return token.userName;
}

export const cleanLocalStorageCache = () => {
  Object.keys(window.localStorage).forEach(key => {
    if (key.startsWith('bb-')) {
      localStorage.removeItem(key);
    }
    if (key === 'recoil-persist') {
      localStorage.removeItem(key);
    }
  });
};

const removeRegexKeys = regex => {
  for (const key in sessionStorage) {
    if (regex.test(key)) {
      sessionStorage.removeItem(key);
    }
  }
};

const logoutRedirect = (ref?: string) => {
  const authUrl = getAuthUrl();
  if (ref) {
    window.location.href = `${authUrl}?logout=true&afterLogout=${ref}`;
  } else {
    window.location.href = `${authUrl}?logout=true`;
  }
};

export const logout = async isWeb => {
  if (isWeb) {
    const token = localStorage.getItem('bb-token') || localStorage.getItem('bb-token-webapp');
    api.post('/auth/service/externalAction/logout', { token }).then(() => {
      cleanLocalStorageCache();
      removeRegexKeys(/subhomeFilters-[a-zA-Z]*/);

      logoutRedirect();
    });
  } else {
    const tokens = await getTokenExtEncoded();
    // @ts-ignore
    const token = tokens?.token;
    api.post('/auth/service/externalAction/logout', { token }).then(async () => {
      await logoutExtension();
      logoutRedirect(window.location.href);
    });
  }
};

const updateSession = (token: string) => {
  const session = localStorage.getItem('bb-app-session');
  if (session) {
    const newSession = JSON.parse(session);
    newSession.token = token;
    newSession.context.token = token;
    localStorage.setItem('bb-app-session', JSON.stringify(newSession));
  }
};

const updateCredentials = (isWeb, token, refreshToken, fingerprint) => {
  if (isWeb) {
    updateSession(token);
    localStorage.setItem('bb-token', token);
    localStorage.setItem('bb-refresh-token', refreshToken);
    localStorage.setItem('bb-hashed-fingerprint', fingerprint);
  } else {
    chrome?.storage?.sync?.set({ token, refreshToken, hashedFingerprint: fingerprint });
  }
};

export const manageRefreshToken = async (error: any, isWeb: boolean, cb: () => Promise<any>) => {
  const response = error?.response;
  if (response) {
    if (response.status === 401 && response.data?.code === 'JWT_EXPIRED') {
      const refreshResponse = await api.get('/auth/service/jwt/refresh');
      const { status, data } = refreshResponse;
      if (status === 200) {
        updateCredentials(isWeb, data.token, data.refreshToken, data.hashedFingerprint);
        return await cb?.();
      }
      return refreshResponse;
    }
    if (response.status === 401 && response.data?.error === 'INVALID_REFRESH_TOKEN') {
      await logout(isWeb);
      return Promise.reject('Failed to fetch: jwt invalid refresh token');
    }
    /* TODO: Uncomment this when the backend is ready https://bloobirds.sentry.io/issues/6114229836/events/?cursor=0%3A150%3A0&project=4504802112634880&referrer=issue-stream&statsPeriod=7d&stream_index=0&utc=true 
    if (response.status === 403) {
      if (!window.location.href.includes('auth/login')) {
        await logout(isWeb);
      }
      return Promise.reject('Failed to fetch: jwt token is forbidden');
    } */
    return Promise.reject(error);
  }
  return Promise.reject(error);
};
