import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@bloobirds-it/flamingo-ui';

import styles from '../../playgroundPages.module.css';

export const NoMainSelection = ({ title, subtitle }: { title: string; subtitle?: string }) => {
  return (
    <div className={styles.test_empty}>
      <span style={{ fontSize: 24 }}>👈</span>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16, padding: '0 8px' }}>
        <Text size="s" color="peanut" weight="heavy" align="center">
          {title}
        </Text>
        {subtitle && (
          <Text size="xs" color="peanut" align="center">
            {subtitle}
          </Text>
        )}
      </div>
    </div>
  );
};

export const TestRunsNoSetSelect = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground.runs' });

  return <NoMainSelection title={t('noSetSelected')} subtitle={t('noSetSelectedDescription')} />;
};
