import { BobjectId, BobjectTypes, RelatedObject } from './bobjects';

export interface BasePropModals {
  companyId?: BobjectId<BobjectTypes.Company>['value'];
  leadId?: BobjectId<BobjectTypes.Lead>['value'];
  opportunityId?: BobjectId<BobjectTypes.Opportunity>['value'];
  activeRelatedId?: RelatedObject['compositeRelatedId'];
  onSave?: () => void;
  onClose?: () => void;
}

export enum ModalType {
  PREVIEW_EMAIL = 'previewEmail',
  RETRY_EMAIL = 'retryEmail',
  SEND_NOW_EMAIL = 'sendNowEmail',
  RESCHEDULE_EMAIL = 'rescheduleEmail',
  CANCEL_EMAIL = 'cancelEmail',
}
