import { useEffect } from 'react';

import { useUserSettings } from '@bloobirds-it/hooks';
import { api, keepPreviousResponse } from '@bloobirds-it/utils';
import hash from 'object-hash';
import { atom, useRecoilState } from 'recoil';
import useSWR from 'swr';

import { sortEntities } from '../utils/phoneHelper';

const phoneNumberFiltersAtom = atom({
  key: 'phoneNumberFiltersAtom',
  default: {
    location: null,
    tags: [],
    prefixes: [],
    capabilities: [],
    search: '',
    status: null,
    typeOfNumber: '',
    page: 1,
    pageSize: 5,
    sort: { creationDate: 'ASC' },
  },
});

const showPhoneSettingsAtom = atom({
  key: 'showPhoneSettingsAtom',
  default: {
    phone: {
      id: '',
      flag: null,
      phoneNumber: '',
      users: [],
      usersRoundRobin: [],
      location: '',
      status: null,
      callDistributionType: '',
      typeOfNumber: '',
      type: null,
      name: '',
      callRecordingEnabled: null,
      activeConfiguration: [],
      capabilities: [],
      prefix: '',
      phonesRequested: 0,
      tags: [],
      sid: '',
      countryCode: '',
    },
    show: false,
  },
});

const showPurchasePhoneNumberAtom = atom({
  key: 'showPurchasePhoneNumberAtom',
  default: false,
});

const showCalloutsAtom = atom({
  key: 'showCalloutsAtom',
  default: {
    InfoCallout: true,
    ErrorCallout: true,
    InfoCalloutPurchasePhone: true,
  },
});

export const usePhoneNumberFilters = () => {
  const [filters, setFilters] = useRecoilState(phoneNumberFiltersAtom);

  const updatePhoneNumberFilters = (filter, value) => {
    setFilters(prev => ({
      ...prev,
      [filter]: value,
      page: 1,
    }));
  };

  const updateSorting = column => {
    setFilters(prev => ({
      ...prev,
      sort: {
        [column]: prev.sort[column] === 'ASC' ? 'DESC' : 'ASC',
      },
    }));
  };

  return {
    filters,
    updatePhoneNumberFilters,
    updateSorting,
    setPage: page => setFilters(prev => ({ ...prev, page })),
    setPageSize: pageSize => setFilters(prev => ({ ...prev, pageSize })),
  };
};

export const usePaginatedPhoneEntity = () => {
  const { filters } = usePhoneNumberFilters();
  const [showPhoneSettings, setShowPhoneSettings] = useRecoilState(showPhoneSettingsAtom);
  const [showPurchasePhoneNumber, setShowPurchasePhoneNumber] = useRecoilState(
    showPurchasePhoneNumberAtom,
  );
  const [showCallouts, setShowCallouts] = useRecoilState(showCalloutsAtom);

  const settings = useUserSettings();

  const params = {
    ...filters,
    sort:
      Object.keys(filters.sort).length > 0
        ? `${Object.keys(filters.sort)[0]},${Object.values(filters.sort)[0]}`
        : undefined,
  };

  const { data, mutate, isLoading } = useSWR(
    () => `/calls/settings/phoneNumbers/${hash(params)}`,
    () => api.get(`/calls/settings/phoneNumbers/${settings.account.id}`, { params }),
    {
      use: [keepPreviousResponse],
    },
  );

  let filteredEntities = data?.data || [];

  const allTags = Array.isArray(data?.data)
    ? data.data.reduce((acc, item) => {
        const tags = item.tags.map(tag => ({
          id: Math.random().toString(36).substring(2, 15),
          name: tag,
        }));
        return [...acc, ...tags];
      }, [])
    : [];

  if (filters.search) {
    filteredEntities =
      data?.data.filter(item => {
        const searchQuery = filters.search?.toLowerCase();

        const phoneNumber = item.phoneNumber ? item.phoneNumber.toLowerCase() : '';
        const name = item.name ? item.name.toLowerCase() : '';

        return phoneNumber.includes(searchQuery) || name.includes(searchQuery);
      }) || [];
  }

  if (filters.sort && Object.keys(filters.sort).length > 0) {
    const [sortBy, sortOrder] = Object.entries(filters.sort)[0];
    filteredEntities = sortEntities(filteredEntities, sortBy, sortOrder);
  }

  const handleRefresh = () => mutate();

  return {
    entities: filteredEntities,
    handleRefresh,
    isLoading,
    showPhoneSettings,
    setShowPhoneSettings,
    showPurchasePhoneNumber,
    setShowPurchasePhoneNumber,
    showCallouts,
    setShowCallouts,
    allTags,
    totalElements: filteredEntities.length,
  };
};
