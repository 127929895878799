import useSWR from "swr";
import { api } from "@bloobirds-it/utils";
import { MessagingTemplate } from "@bloobirds-it/types";

const searchMessagingTemplates = ([url, ...filters]: [url: string, ...filters: string[]]) => {
  return api
    .post(`${url}?sort=updateDatetime%2Cdesc&&page=0&&size=${filters[5]}`, {
      criteria: {
        type: filters[0],
        stage: filters[1],
        visibility: filters[2],
        name: filters[3] || '',
        segmentationValues: JSON.parse(filters[4]),
        usedInCadences: filters[6],
        onlyMine: filters[7],
        onlyOfficials: filters[8],
        onlyBattlecards: filters[9],
        teams: filters[10] ? filters[10] : null,
      },
      myTemplatesPage: 0,
      myTemplatesPageSize: 100,
      teamTemplatesPage: 0,
      teamTemplatesPageSize: 100
    })
    .then(res => {
      const templates = [
        ...res.data.myTemplates.messagingTemplates,
        ...res.data.teamTemplates.messagingTemplates,
      ];
      const uniqueTemplates = [
        ...new Map(
          templates
            .filter(template => template !== null) 
            .map(template => [template.id, template]) 
        ).values() 
      ];
      return uniqueTemplates;
    });
};

interface UseMessagingTemplatesOptions {
  segmentationValues: any;
  stage?: string;
  type: string;
  name: string;
  size: number;
  page: number;
  visibility: string;
  showCadencesTemplates?: boolean;
  onlyMine: boolean;
  onlyOfficials?: boolean;
  onlyBattlecards?: boolean;
  teams?: string[];
}

export const useAllMessagingTemplates = (filters: UseMessagingTemplatesOptions) => {
  const {
    segmentationValues,
    stage,
    type,
    size,
    name,
    onlyMine,
    onlyOfficials,
    onlyBattlecards,
    visibility,
    showCadencesTemplates,
    teams,
  } = filters;

  const { data, error, isValidating } = useSWR<Array<MessagingTemplate>>(
    [
      '/messaging/messagingTemplates/search/v2',
      type,
      stage,
      visibility,
      name,
      JSON.stringify(segmentationValues),
      size,
      showCadencesTemplates,
      onlyMine,
      onlyOfficials,
      onlyBattlecards,
      teams,
    ],
    searchMessagingTemplates,
  );

  return {
    messagingTemplates: data || [],
    isLoading: isValidating,
    isError: error,
  };
};
