import { createComboboxPlugin, createElementAs, createMentionPlugin } from '@udecode/plate';

import { ELEMENT_USER_MENTION } from './defaults';

export const createUserMentionsPlugins = () => {
  return [
    createComboboxPlugin(),
    createMentionPlugin({
      key: ELEMENT_USER_MENTION,
      options: {
        createMentionNode: (mention: any) => ({ value: mention.data }),
        trigger: '@',
        insertSpaceAfterMention: true,
      },
      serializeHtml: props => {
        const { element } = props;
        if (!element || element.type !== 'user-mention') return null;
        const name = (element?.name as string)?.split('@')?.[1];

        return createElementAs('span', {
          ...element,
          style: {
            color: '#2e67bd',
            backgroundColor: '#f0f0f0',
            borderRadius: '4px',
            padding: '2px 4px',
            whiteSpace: 'normal',
          },
          children: name,
        });
      },
      deserializeHtml: {
        rules: [{ validNodeName: ['SPAN'] }, { validAttribute: { type: ELEMENT_USER_MENTION } }],
        getNode: (el: any) => {
          return {
            type: ELEMENT_USER_MENTION,
            name: el.getAttribute('name'),
            id: el.getAttribute('id'),
            color: '#2e67bd',
            children: el,
          };
        },
      },
    }),
  ];
};
