import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dropdown,
  Callout,
  Text,
  Button,
  Spinner,
  useVisible,
  ColorType,
  IconType,
} from '@bloobirds-it/flamingo-ui';
import { GeneralPrompt } from '@bloobirds-it/types';

import styles from '../../playgroundPages.module.css';

interface IAnchorButtonProps {
  color: ColorType;
  variant: any;
  disabled: boolean;
  iconLeft: IconType;
  text: string;
}

interface ISaveEditGeneralPromptDropdownProps {
  onSave: (setVisible: (val: boolean) => void) => void;
  loading: boolean;
  anchorButtonProps: IAnchorButtonProps;
}
export const SaveEditGeneralPromptDropdown = ({
  onSave,
  loading,
  anchorButtonProps = {} as IAnchorButtonProps,
}: ISaveEditGeneralPromptDropdownProps) => {
  const { t } = useTranslation();
  const { visible, setVisible, ref } = useVisible();

  return (
    <Dropdown
      width="100%"
      visible={visible}
      ref={ref}
      zIndex={102}
      arrow={false}
      anchor={
        <Button
          onClick={() => setVisible(true)}
          size="small"
          {...anchorButtonProps}
          uppercase={false}
        >
          {anchorButtonProps?.text}
        </Button>
      }
      style={{ minWidth: 0, minHeight: 0 }}
    >
      <div className={styles.saveDropdownContainer}>
        <Callout variant="neutral" width="100%" icon="infoFilled">
          <Text size="s">{t('ai.playground.generalPromptsTab.saveDropdown.callout')}</Text>
        </Callout>
        <Text size="s" weight="bold">
          {t('ai.playground.generalPromptsTab.saveDropdown.confirmation')}
        </Text>
        <Text size="s" color="softPeanut">
          {t('ai.playground.generalPromptsTab.saveDropdown.info')}
        </Text>

        <div className={styles.saveGeneralPromptButtonContainer}>
          <Button
            size="small"
            color="bloobirds"
            variant="primary"
            disabled={loading}
            uppercase={false}
            onClick={() => onSave(setVisible)}
            className={styles.saveButtons}
          >
            {loading ? (
              <Spinner name="loadingCircle" size={12} color="white" />
            ) : (
              t('ai.playground.generalPromptsTab.saveDropdown.saveChanges')
            )}
          </Button>
        </div>
      </div>
    </Dropdown>
  );
};
