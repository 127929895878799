import React from 'react';
import { Trans } from 'react-i18next';

export const AutomatedTaskText = (
  <>
    <b>Automated tasks</b> will tell you <b>who to contact</b>
    <br /> and <b>what to tell</b> them every day
  </>
);

export const WhatWhenHowText = (
  <>
    Know exactly <b>what</b> to say,
    <br />
    <b>when</b> to say it, and <b>how.</b>
  </>
);

export const CorrectContactFlowText = (
  <>
    Our <b>correct contact flow</b> ® makes reporting
    <br />
    easy by turning it into an intuitive conversation
  </>
);

export const SalesTeamText = (
  <>
    Bloobirds assists your <b>sales team</b> in getting more
    <br />
    qualified <b>meetings</b> & closing more <b>deals</b>
  </>
);

export const GuidesSaleText = (
  <>
    Bloobirds <b>guides Sales Reps</b> from one task to the next,
    <br />
    while effortlessly recording all <b>the data you need</b>
  </>
);

export const StrategicMetricsProText = (
  <>
    Become a <b>Strategic Metrics Pro</b> and drive
    <br />a smooth sales process from start to finish
  </>
);

export const ContactabilityToolsText = (
  <>
    All the <b>contactability tools</b> you need in one place,
    <br />
    and all activities synchronised.
  </>
);

export const LetTheSystemRemindYouText = (
  <Trans i18nKey="welcomeScreens.screenTexts.letSystemRemindYou" components={[<br key="0" />]} />
);
