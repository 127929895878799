// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notFound-module__root___71Nhc {\n  height: 100vh;\n  width: 100vw;\n  position: absolute;\n  display: flex;\n  background-color: rgb(197 209 221 / 60%);\n  align-items: center;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/js/app/main/notFound/notFound.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,wCAAwC;EACxC,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".root {\n  height: 100vh;\n  width: 100vw;\n  position: absolute;\n  display: flex;\n  background-color: rgb(197 209 221 / 60%);\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "notFound-module__root___71Nhc"
};
export default ___CSS_LOADER_EXPORT___;
