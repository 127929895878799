import React from 'react';

import { Icon } from '@bloobirds-it/flamingo-ui';
import { ACTIVITY_TYPES_VALUES_LOGIC_ROLE, CopilotActivityType } from '@bloobirds-it/types';

interface ITestCardIconProps {
  activityType: CopilotActivityType | 'All';
}
export const ActivityTypeIcon = ({ activityType }: ITestCardIconProps) => {
  switch (activityType) {
    case 'Call':
    case ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL:
      return <Icon name="phone" size={16} color="melon" />;

    case 'Meeting':
    case ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING:
      return <Icon name="calendar" size={16} color="tomato" />;

    case 'Email':
    case ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL:
      return <Icon name="mail" size={16} color="tangerine" />;

    case ACTIVITY_TYPES_VALUES_LOGIC_ROLE.LINKEDIN:
      return <Icon name="linkedin" size={16} color="bloobirds" />;

    default:
      return null;
  }
};
