import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralPromptFormModal, useActivityFieldsInfo } from '@bloobirds-it/copilot';
import { Icon, Text, Button, IconButton, Select, Item } from '@bloobirds-it/flamingo-ui';
import { useTogglerState } from '@bloobirds-it/hooks';
import {
  GeneralPrompt,
  ACTIVITY_TYPE_OPTIONS,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
} from '@bloobirds-it/types';
import clsx from 'clsx';
import { motion } from 'framer-motion';

import { useGeneralPrompts } from '../../hooks/useGeneralPrompts';
import styles from '../../playgroundPages.module.css';
import { NoMainSelection } from '../runs/testRunsNoSetSelect';
import {
  DefaultPromptCard,
  GeneralPromptsSubTypeColumnCard,
} from './generalPromptsSubtypesColumnCard';

const variants = {
  open: {
    height: '60px',
    transitionEnd: { overflow: 'visible' }, // Set overflow after the height animation ends
  },
  closed: {
    height: 0,
    overflow: 'hidden', // Set overflow before the height animation starts
  },
};

type SubTypesFilterState = { activityType: string; activitySubType: string };

const WITHOUT_FILTERS_STATE: SubTypesFilterState = {
  activityType: '',
  activitySubType: '',
};
export interface IGeneralPromptsSubTypesColumn {
  selectedPrompt: GeneralPrompt | null;
  selectedSubType: GeneralPrompt;
  setSelectedSubType: React.Dispatch<React.SetStateAction<GeneralPrompt>>;
}
export const GeneralPromptsSubTypesColumn = (props: IGeneralPromptsSubTypesColumn) => {
  const { t } = useTranslation();
  const [isOpen, toggle] = useTogglerState();
  const [openFilters, toggleFilters] = useTogglerState();
  const { accountPrompts, reloadAccountPrompts } = useGeneralPrompts();
  const [filter, setFilter] = useState<SubTypesFilterState>(WITHOUT_FILTERS_STATE);
  const { meetingTypes } = useActivityFieldsInfo();

  useEffect(() => {
    //When closing the filters, remove them too (same behavior as testruns)
    if (!isOpen) {
      setFilter(WITHOUT_FILTERS_STATE);
    }
  }, [isOpen]);
  useEffect(() => {
    //When changing the prompt, delete filters
    if (props?.selectedPrompt) {
      setFilter(WITHOUT_FILTERS_STATE);
    }
  }, [props?.selectedPrompt]);

  const generalPromptSubTypes = props.selectedPrompt
    ? [...accountPrompts.values()].filter(
        prompt =>
          prompt?.promptType === props.selectedPrompt?.promptType ||
          prompt?.promptType.startsWith(`${props.selectedPrompt?.promptType}:`),
      )
    : [];
  // TODO: FE-806 Remove this retrocompatibility in new beta
  const activitySubTypes = ['Meeting', ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING].includes(
    filter.activityType,
  )
    ? [...meetingTypes.values()]
    : [];

  const filteredSubTypes = generalPromptSubTypes.filter(prompt => {
    const [, activityType, activitySubType] = prompt.promptType.split(':');
    if (filter.activitySubType) {
      return activitySubType === filter.activitySubType;
    }
    if (filter.activityType) {
      return activityType === filter.activityType;
    }
    return true;
  });
  const sortedArray = filteredSubTypes.sort((a, b) => {
    return new Date(b.creationDatetime) - new Date(a.creationDatetime);
  });

  const defaultPrompt = generalPromptSubTypes.find(prompt => !prompt?.promptType.includes(':'));

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '250px' }}>
      <div className={styles.generalPromptColumnTitle}>
        <Text color="softPeanut" size="s" weight="bold" className={styles.textWithIcon}>
          <Icon name="copy" color="softPeanut" size={20} />
          {t('ai.playground.generalPromptsTab.subTypeColumnTitle')}
        </Text>
        <IconButton
          disabled={!props.selectedPrompt}
          name="sliders"
          color={openFilters ? 'white' : 'bloobirds'}
          size={18}
          className={clsx(styles.testRuns_filter_button, {
            [styles.testRuns_filter_button_selected]: openFilters,
          })}
          onClick={toggleFilters}
        />
      </div>
      {!props.selectedPrompt ? (
        <NoMainSelection title={t('ai.playground.generalPromptsTab.subTypeColumnNoSelection')} />
      ) : (
        <div className={styles.generalPromptSubTypeColumnContainer}>
          <motion.div
            initial="closed"
            animate={openFilters ? 'open' : 'closed'}
            variants={variants}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            className={styles.select}
          >
            <Select
              size="small"
              value={filter?.activityType || ''}
              onChange={value =>
                setFilter(() => ({
                  activitySubType: '',
                  activityType: value,
                }))
              }
              placeholder={t('ai.insightCard.activityType')}
            >
              <Item value="" key="all">
                {t('leftBar.filters.all')}
              </Item>
              {ACTIVITY_TYPE_OPTIONS(t)
                ?.slice(1)
                ?.filter(v => v?.enabled)
                .map(activityType => (
                  <Item value={activityType.id} key={activityType.id}>
                    {activityType?.name}
                  </Item>
                ))}
            </Select>
            <Select
              size="small"
              value={filter?.activitySubType || ''}
              onChange={value =>
                setFilter(prevFilter => ({ ...prevFilter, activitySubType: value }))
              }
              placeholder={t('ai.insightCard.activitySubtype')}
              disabled={filter?.insight}
            >
              <Item value="" key="all">
                {t('leftBar.filters.all')}
              </Item>
              {activitySubTypes.map(subType => (
                <Item value={subType.id} key={subType.id}>
                  {subType.name}
                </Item>
              ))}
            </Select>
          </motion.div>
          <Button
            className={styles.testRuns_createRun}
            uppercase={false}
            iconLeft="add"
            variant="primary"
            disabled={!props.selectedPrompt}
            color="lightPurple"
            size="small"
            onClick={toggle}
          >
            {t('ai.playground.generalPromptsTab.createCustomPrompt')}
          </Button>
          <div className={styles.generalPromptSubTypeColumnCardContainer}>
            {defaultPrompt && <DefaultPromptCard prompt={defaultPrompt} {...props} />}
            {sortedArray.map(
              prompt =>
                prompt?.promptType.includes(':') && (
                  <GeneralPromptsSubTypeColumnCard key={prompt.id} prompt={prompt} {...props} />
                ),
            )}
          </div>
        </div>
      )}
      {isOpen && (
        <GeneralPromptFormModal
          accountPrompts={accountPrompts}
          currentPrompt={props.selectedPrompt}
          onClose={toggle}
          onSave={() => reloadAccountPrompts()}
        />
      )}
    </div>
  );
};
