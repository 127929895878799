// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".triggers-module___action_page__container___TMzY\\+ {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-bottom: 20px;\n}\n\n.triggers-module___workflows_actions_icon___3DEwc {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 1px;\n}\n\n.triggers-module___workflows_actions_text___pmt33 {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/playbookPages/automationsPages/workflowEditionPage/actionsTabFolder/triggers/triggers.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;AACpB","sourcesContent":["._action_page__container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-bottom: 20px;\n}\n\n._workflows_actions_icon {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 1px;\n}\n\n._workflows_actions_text {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_action_page__container": "triggers-module___action_page__container___TMzY+",
	"_workflows_actions_icon": "triggers-module___workflows_actions_icon___3DEwc",
	"_workflows_actions_text": "triggers-module___workflows_actions_text___pmt33"
};
export default ___CSS_LOADER_EXPORT___;
