// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".companyCard-module___lead_dropdown_element___a3o2U {\n  margin-left: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/contactPages/companyPage/companyCard/companyCard.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB","sourcesContent":["._lead_dropdown_element {\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_lead_dropdown_element": "companyCard-module___lead_dropdown_element___a3o2U"
};
export default ___CSS_LOADER_EXPORT___;
