// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bobjectFormSkeleton-module___section___rYOSl {\n  margin-bottom: 64px;\n}\n\n.bobjectFormSkeleton-module___header___j8kqS {\n  display: flex;\n  gap: 16px;\n  margin-bottom: 16px;\n}\n\n.bobjectFormSkeleton-module___divider___7clBE {\n  margin-bottom: 20px;\n}\n\n.bobjectFormSkeleton-module___content___5mgBY {\n  padding: 0 16px;\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  column-gap: 36px;\n  row-gap: 28px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectForm/bobjectFormSkeleton/bobjectFormSkeleton.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,qCAAqC;EACrC,gBAAgB;EAChB,aAAa;AACf","sourcesContent":["._section {\n  margin-bottom: 64px;\n}\n\n._header {\n  display: flex;\n  gap: 16px;\n  margin-bottom: 16px;\n}\n\n._divider {\n  margin-bottom: 20px;\n}\n\n._content {\n  padding: 0 16px;\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  column-gap: 36px;\n  row-gap: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_section": "bobjectFormSkeleton-module___section___rYOSl",
	"_header": "bobjectFormSkeleton-module___header___j8kqS",
	"_divider": "bobjectFormSkeleton-module___divider___7clBE",
	"_content": "bobjectFormSkeleton-module___content___5mgBY"
};
export default ___CSS_LOADER_EXPORT___;
