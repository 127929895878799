import { useState, useCallback, useEffect, useMemo } from 'react';

import { TranscriptFragment } from '@bloobirds-it/types';
import debounce from 'lodash/debounce';

const normalizeText = (text: string): string => {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

const escapeRegExp = (string: string): string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const useSearchInTranscript = (transcript: TranscriptFragment[]) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredTranscript, setFilteredTranscript] = useState(transcript);

  const filterTranscript = useCallback(
    (searchTerm: string) => {
      if (!searchTerm) {
        return transcript;
      } else {
        const normalizedSearchTerm = normalizeText(searchTerm);
        return transcript.filter(item => normalizeText(item.text).includes(normalizedSearchTerm));
      }
    },
    [transcript],
  );

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string) => {
        const result = filterTranscript(value);
        setFilteredTranscript(result);
      }, 300),
    [filterTranscript],
  );

  useEffect(() => {
    debouncedSearch(inputValue);
    return () => {
      debouncedSearch.cancel();
    };
  }, [inputValue, debouncedSearch]);

  const handleSearchChange = useCallback((value: string) => {
    setInputValue(value);
  }, []);

  const highlightText = useCallback(
    (text: string) => {
      if (!inputValue.trim() || !text) return text;

      const normalizedSearchValue = normalizeText(inputValue);
      const regex = new RegExp(`(${escapeRegExp(normalizedSearchValue)})`, 'gi');

      return text.split(regex).map((fragment, index) =>
        fragment.match(regex) ? (
          <span style={{ backgroundColor: '#ffe8b2' }} key={index}>
            {fragment}
          </span>
        ) : (
          fragment
        ),
      );
    },
    [inputValue],
  );

  return {
    searchTerm: inputValue,
    filteredTranscript,
    handleSearchChange,
    highlightText,
  };
};
