import React, { useRef, useState } from 'react';

import { Icon, Text } from '@bloobirds-it/flamingo-ui';
import { TeamType } from '@bloobirds-it/types';
import { motion, AnimatePresence } from 'framer-motion';

import styles from '../../styles/phoneNumberPage.module.css';

export const UserTeamsRow = ({ teams }: { teams: TeamType[] }) => {
  const [isHovered, setIsHovered] = useState(false);
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    hoverTimeout.current = setTimeout(() => {
      setIsHovered(false);
    }, 100);
  };
  if (teams.length === 1) {
    return (
      <div className={styles.itemInformation}>
        <Icon name={teams[0].icon} size={24} color="bloobirds" />
        <Text size="s" color="peanut" weight="regular">
          {teams[0].name}
        </Text>
      </div>
    );
  } else {
    return (
      <div
        className={styles.usersGroupCard}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Icon name="gridSquares" size={24} color="bloobirds" />
        <Text size="s" color="peanut" weight="regular">
          {teams.length} teams
        </Text>
        <AnimatePresence>
          {isHovered && (
            <motion.div
              className={styles.usersDropdown}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{
                duration: 0.3,
                ease: 'easeInOut',
              }}
              style={{ bottom: 'auto', top: '100%' }}
            >
              {teams?.map(team => (
                <div key={team.id} className={styles.userDetail}>
                  <Icon name={team.icon} size={24} color="bloobirds" />
                  <div className={styles.userInfo}>
                    <Text size="s" color="peanut">
                      {team.name}
                    </Text>
                  </div>
                </div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
};
