import React from 'react';

import { RequestedPhones } from '../components/purchaseNumber/orderReview/requestedPhones';
import { InfoComponent } from '../components/purchaseNumber/shared/infoComponent';
import styles from '../styles/phoneNumberPage.module.css';

export const OrderReview = () => {
  return (
    <div className={styles.purchaseGeneralContainer}>
      <InfoComponent type="orderReview" />
      <RequestedPhones />
    </div>
  );
};
