import {
  ComboboxOnSelectItem,
  NoData,
  PlatePluginKey,
  comboboxSelectors,
  MentionPlugin,
  getPlugin,
  getBlockAbove,
  isEndPoint,
  Data,
  withoutNormalizing,
  select,
  removeNodes,
  insertNodes,
  insertText,
  TMentionElement,
  withoutMergingHistory,
  ELEMENT_MENTION_INPUT,
  moveSelection,
  comboboxActions,
} from '@udecode/plate';

import { ELEMENT_USER_MENTION } from './defaults';

export const getMentionOnSelection = <TData extends Data = NoData>({
  key = ELEMENT_USER_MENTION,
}: PlatePluginKey = {}): ComboboxOnSelectItem<TData> => (editor, item) => {
  const targetRange = comboboxSelectors.targetRange();
  if (!targetRange) return;

  const {
    options: { insertSpaceAfterMention, createMentionNode },
  } = getPlugin<MentionPlugin>(editor as any, key);

  const pathAbove = getBlockAbove(editor)?.[1];
  const isBlockEnd = () =>
    editor.selection && pathAbove && isEndPoint(editor, editor.selection.anchor, pathAbove);

  withoutNormalizing(editor, () => {
    const { value } = createMentionNode!(item, {
      search: comboboxSelectors.text() ?? '',
    });

    select(editor, targetRange);

    withoutMergingHistory(editor, () =>
      removeNodes(editor, {
        match: node => node.type === ELEMENT_MENTION_INPUT,
      }),
    );

    insertNodes<TMentionElement>(editor, {
      type: ELEMENT_USER_MENTION,
      children: value as any,
      id: value[0].id,
      name: value[0].text,
      value: value[0].id,
    });

    // move the selection after the element
    moveSelection(editor, { unit: 'offset' });

    if (isBlockEnd() && insertSpaceAfterMention) {
      insertText(editor, ' ');
    }
  });

  return comboboxActions.reset();
};
