// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".addCrmStatusFieldModal-module___modal_content___2qYq2 {\n  padding: 10px 20px 10px 16px !important;\n}\n.addCrmStatusFieldModal-module___search_content___B9q8A {\n  margin-top: 15px;\n}\n\n.addCrmStatusFieldModal-module___modal_footer___616zY {\n  padding: 10px 20px 20px 16px !important;\n}\n\n.addCrmStatusFieldModal-module___footer_buttons___jrn8S {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.addCrmStatusFieldModal-module___save_button___vbwcP {\n  margin-left: auto;\n}\n\n.addCrmStatusFieldModal-module___remove_button___zv5WC {\n  padding-left: 0px;\n}\n\n.addCrmStatusFieldModal-module___title__container___nBMvp {\n  display: flex;\n  align-items: center;\n}\n\n.addCrmStatusFieldModal-module___icon___gj8UP {\n  margin-right: 4px;\n}\n\n.addCrmStatusFieldModal-module__content___POOC\\+ {\n  margin: 8px 0 16px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/crmStatus/addCrmStatusFieldModal/addCrmStatusFieldModal.module.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;AACzC;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":["._modal_content {\n  padding: 10px 20px 10px 16px !important;\n}\n._search_content {\n  margin-top: 15px;\n}\n\n._modal_footer {\n  padding: 10px 20px 20px 16px !important;\n}\n\n._footer_buttons {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n}\n\n._save_button {\n  margin-left: auto;\n}\n\n._remove_button {\n  padding-left: 0px;\n}\n\n._title__container {\n  display: flex;\n  align-items: center;\n}\n\n._icon {\n  margin-right: 4px;\n}\n\n.content {\n  margin: 8px 0 16px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_modal_content": "addCrmStatusFieldModal-module___modal_content___2qYq2",
	"_search_content": "addCrmStatusFieldModal-module___search_content___B9q8A",
	"_modal_footer": "addCrmStatusFieldModal-module___modal_footer___616zY",
	"_footer_buttons": "addCrmStatusFieldModal-module___footer_buttons___jrn8S",
	"_save_button": "addCrmStatusFieldModal-module___save_button___vbwcP",
	"_remove_button": "addCrmStatusFieldModal-module___remove_button___zv5WC",
	"_title__container": "addCrmStatusFieldModal-module___title__container___nBMvp",
	"_icon": "addCrmStatusFieldModal-module___icon___gj8UP",
	"content": "addCrmStatusFieldModal-module__content___POOC+"
};
export default ___CSS_LOADER_EXPORT___;
