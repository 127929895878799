// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".messagingTabs-module___container___U8xwf {\n  background-color: var(--lighterGray);\n  padding: 27px 26px 40px;\n  min-height: 1000px;\n}\n\n.messagingTabs-module___wrapper___jCAwl {\n  max-width: 1280px;\n  width: 100%;\n  margin: 0 auto;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/contactPages/contactTabs/messagingTabs/messagingTabs.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,cAAc;AAChB","sourcesContent":["._container {\n  background-color: var(--lighterGray);\n  padding: 27px 26px 40px;\n  min-height: 1000px;\n}\n\n._wrapper {\n  max-width: 1280px;\n  width: 100%;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "messagingTabs-module___container___U8xwf",
	"_wrapper": "messagingTabs-module___wrapper___jCAwl"
};
export default ___CSS_LOADER_EXPORT___;
