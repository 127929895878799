import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Text, Select, Item, IconButton } from '@bloobirds-it/flamingo-ui';
import clsx from 'clsx';

import styles from './basicPagination.module.css';

export interface PaginationProps {
  children?: any;
  page: number;
  rowsPerPage: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  hasNextPage: boolean;
  rowsPerPageOptions?: number[];
  title?: string;
  stylesCustom?: {
    container?: string;
    text?: string;
  };
}

export const BasicPagination = ({
  page,
  hasNextPage,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPageOptions = [5, 10, 15],
  title,
  stylesCustom,
}: PaginationProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pagination' });

  const prevPageDisabled = page === 0;
  const nextPageDisabled = !hasNextPage;
  const from = page * rowsPerPage + 1;
  const to = (page + 1) * rowsPerPage;

  const containerStyle = clsx(styles.container, stylesCustom?.container);
  const divStyle = clsx(styles.text, stylesCustom?.text);

  return (
    <div className={containerStyle}>
      <Text weight="medium" size="xs" color="softPeanut">
        {title ?? t('title')}:
      </Text>
      <div className={styles.select}>
        <Select
          size="small"
          value={rowsPerPage}
          width="100%"
          variant="filters"
          onChange={rowNumber => {
            onChangePage(Math.floor((page * rowsPerPage) / rowNumber));
            onChangeRowsPerPage(rowNumber);
          }}
        >
          {rowsPerPageOptions.map((rowNumber, idx) => (
            <Item key={idx} value={rowNumber}>
              {rowNumber.toString()}
            </Item>
          ))}
        </Select>
      </div>
      <div className={divStyle}>
        <Text weight="medium" size="xs" color="softPeanut">
          {`${from} - ${to}`}
        </Text>
      </div>
      <div className={styles.buttonContainer}>
        <IconButton
          name="chevronLeft"
          size={14}
          color="peanut"
          onClick={() => {
            onChangePage(page > 0 ? page - 1 : page);
          }}
          className={clsx({ [styles.buttonHover]: !prevPageDisabled })}
          disabled={prevPageDisabled}
        />

        <IconButton
          name="chevronRight"
          size={14}
          color="peanut"
          onClick={() => onChangePage(page + 1)}
          className={clsx({ [styles.buttonHover]: !nextPageDisabled })}
          disabled={nextPageDisabled}
        />
      </div>
    </div>
  );
};
