// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".importForm-module___root___vs-IO {\n  height: calc(100vh - 48px);\n  width: 100vw;\n  position: absolute;\n  top: 0;\n  z-index: 0;\n  background-color: var(--white);\n  margin-top: 48px;\n  overflow-y: scroll;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/importForm/importForm.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,UAAU;EACV,8BAA8B;EAC9B,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["._root {\n  height: calc(100vh - 48px);\n  width: 100vw;\n  position: absolute;\n  top: 0;\n  z-index: 0;\n  background-color: var(--white);\n  margin-top: 48px;\n  overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_root": "importForm-module___root___vs-IO"
};
export default ___CSS_LOADER_EXPORT___;
