// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".quickFilter-module___quick_filter_wrapper___VU1-u {\n  display: flex;\n  align-content: space-between;\n}\n\n.quickFilter-module__dropdown_menu___TKM5H {\n  width: 23px;\n  height: 23px;\n  border-radius: 16px;\n  margin-left: -23px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.quickFilter-module__icon_wrapper___SaeY8 {\n  margin-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/subhomePages/prospectingPage/quickFilter/quickFilter.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,4BAA4B;AAC9B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["._quick_filter_wrapper {\n  display: flex;\n  align-content: space-between;\n}\n\n.dropdown_menu {\n  width: 23px;\n  height: 23px;\n  border-radius: 16px;\n  margin-left: -23px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.icon_wrapper {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_quick_filter_wrapper": "quickFilter-module___quick_filter_wrapper___VU1-u",
	"dropdown_menu": "quickFilter-module__dropdown_menu___TKM5H",
	"icon_wrapper": "quickFilter-module__icon_wrapper___SaeY8"
};
export default ___CSS_LOADER_EXPORT___;
