// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".leadsOverview-module__LeadOverview_root___RqsNt {\n  grid-column: 1/4;\n  grid-row: 4;\n  min-height: 30px;\n  margin: 0 10px 6px 10px;\n  justify-content: center;\n  flex-wrap: wrap;\n  display: flex;\n  flex-direction: row;\n}\n\n.leadsOverview-module__LeadOverview_root___RqsNt > * {\n  margin: 9px 13px 0 13px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/bobjectDetailsModal/header/leadsOverview/leadsOverview.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,uBAAuB;EACvB,uBAAuB;EACvB,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".LeadOverview_root {\n  grid-column: 1/4;\n  grid-row: 4;\n  min-height: 30px;\n  margin: 0 10px 6px 10px;\n  justify-content: center;\n  flex-wrap: wrap;\n  display: flex;\n  flex-direction: row;\n}\n\n.LeadOverview_root > * {\n  margin: 9px 13px 0 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LeadOverview_root": "leadsOverview-module__LeadOverview_root___RqsNt"
};
export default ___CSS_LOADER_EXPORT___;
