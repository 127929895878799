// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iconPicker-module__iconPicker___ZH-9Q {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  grid-template-rows: repeat(4, 1fr);\n  grid-column-gap: 16px;\n  grid-row-gap: 8px;\n  padding: 15px;\n}\n\n.iconPicker-module__iconPickerButton___ba2-2 {\n  border-radius: 4px;\n  border: 1px solid var(--bloobirds);\n}\n\n.iconPicker-module__iconPickerButtonHover___6Mnkp:hover {\n  border-radius: 4px;\n  border: 1px solid var(--bloobirds);\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/iconPicker/iconPicker.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,kCAAkC;EAClC,qBAAqB;EACrB,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;AACpC","sourcesContent":[".iconPicker {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  grid-template-rows: repeat(4, 1fr);\n  grid-column-gap: 16px;\n  grid-row-gap: 8px;\n  padding: 15px;\n}\n\n.iconPickerButton {\n  border-radius: 4px;\n  border: 1px solid var(--bloobirds);\n}\n\n.iconPickerButtonHover:hover {\n  border-radius: 4px;\n  border: 1px solid var(--bloobirds);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconPicker": "iconPicker-module__iconPicker___ZH-9Q",
	"iconPickerButton": "iconPicker-module__iconPickerButton___ba2-2",
	"iconPickerButtonHover": "iconPicker-module__iconPickerButtonHover___6Mnkp"
};
export default ___CSS_LOADER_EXPORT___;
