// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".callErrorModal-module___container___aP4Te {\n  padding: 48px 60px;\n  background-color: var(--white);\n}\n\n.callErrorModal-module___container___aP4Te > p {\n  margin-right: 12px;\n}\n\n.callErrorModal-module___container___aP4Te > div {\n  margin-top: 32px;\n}\n\n.callErrorModal-module___container___aP4Te > div > span > p {\n  display: inline;\n}\n\n.callErrorModal-module___icon___BMjBo {\n  display: inline-block;\n  margin-right: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/dialer/callErrorModal/callErrorModal.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;AACnB","sourcesContent":["._container {\n  padding: 48px 60px;\n  background-color: var(--white);\n}\n\n._container > p {\n  margin-right: 12px;\n}\n\n._container > div {\n  margin-top: 32px;\n}\n\n._container > div > span > p {\n  display: inline;\n}\n\n._icon {\n  display: inline-block;\n  margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "callErrorModal-module___container___aP4Te",
	"_icon": "callErrorModal-module___icon___BMjBo"
};
export default ___CSS_LOADER_EXPORT___;
