// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".quickStartModal-module___message_wrapper___T\\+Yvv {\n  margin-bottom: 45px;\n}\n\n.quickStartModal-module___button_wrapper___lL9fs {\n  display: inline-flex;\n}\n\n.quickStartModal-module___button_wrapper___lL9fs > button:nth-child(1) {\n  margin-right: 16px;\n}\n\n.quickStartModal-module___text_wrapper___duS6i {\n  margin-top: 30px;\n}\n\n.quickStartModal-module___text_wrapper__small___\\+GieN {\n  margin-bottom: 5px;\n  margin-left: 12px;\n}\n\n.quickStartModal-module___section___q8DVh {\n  margin-top: 30px;\n  display: list-item;\n  margin-left: 30px;\n  list-style-type: disc;\n}\n\n.quickStartModal-module___section___q8DVh > span {\n  display: initial;\n}\n\n.quickStartModal-module___message___WtjFv {\n  margin-top: 30px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/subhomePages/prospectingPage/quickStartModal/quickStartModal.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["._message_wrapper {\n  margin-bottom: 45px;\n}\n\n._button_wrapper {\n  display: inline-flex;\n}\n\n._button_wrapper > button:nth-child(1) {\n  margin-right: 16px;\n}\n\n._text_wrapper {\n  margin-top: 30px;\n}\n\n._text_wrapper__small {\n  margin-bottom: 5px;\n  margin-left: 12px;\n}\n\n._section {\n  margin-top: 30px;\n  display: list-item;\n  margin-left: 30px;\n  list-style-type: disc;\n}\n\n._section > span {\n  display: initial;\n}\n\n._message {\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_message_wrapper": "quickStartModal-module___message_wrapper___T+Yvv",
	"_button_wrapper": "quickStartModal-module___button_wrapper___lL9fs",
	"_text_wrapper": "quickStartModal-module___text_wrapper___duS6i",
	"_text_wrapper__small": "quickStartModal-module___text_wrapper__small___+GieN",
	"_section": "quickStartModal-module___section___q8DVh",
	"_message": "quickStartModal-module___message___WtjFv"
};
export default ___CSS_LOADER_EXPORT___;
