import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  GenerationInsight,
  DecisionInsight,
  useGetCoreInsights,
  useCopilotActivity,
} from '@bloobirds-it/copilot';
import { Icon, Text } from '@bloobirds-it/flamingo-ui';
import {
  Insight,
  InsightType,
  ACTIVITY_FIELDS_LOGIC_ROLE,
  Bobject,
  CopilotInsight,
} from '@bloobirds-it/types';
import { getFieldByLogicRole } from '@bloobirds-it/utils';

import styles from './insights.module.css';
import NoResultsFound from './noResultsFound';

interface Props {
  activity: Bobject;
}

interface InsightProps {
  activity: Bobject;
  insight: Insight;
  insightDefinition: CopilotInsight;
}

const insightComponents: Record<InsightType, (props: InsightProps) => JSX.Element> = {
  DECISION: DecisionInsight,
  GENERATION: GenerationInsight,
};

const Insights = ({ activity }: Props) => {
  const { t } = useTranslation();
  const { activityInsights: data } = useCopilotActivity();
  const type = getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE);
  const mainTypeField = getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_MAIN_TYPE);
  const coreInsights = useGetCoreInsights(type, mainTypeField);

  return (
    <div className={styles.insights}>
      <div className={styles.insightsTitle}>
        <div className={styles.titleSection}>
          <Icon name="snippet" color="purple" />
          <Text size="s" weight="heavy">
            {t('ai.aiAnalysis.insightsFor')}
          </Text>
        </div>
      </div>

      {coreInsights && data?.insights?.length > 0 ? (
        data?.insights?.map(i => {
          const Insight = insightComponents[i.insight_type];
          const coreInsight = coreInsights.find(ci => ci.id === i.insightId);

          if (!coreInsight || !Insight) {
            return null;
          }

          return (
            <Insight key={i.pk} activity={activity} insight={i} insightDefinition={coreInsight} />
          );
        })
      ) : (
        <NoResultsFound />
      )}
    </div>
  );
};

export default Insights;
