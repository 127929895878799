import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Skeleton, Chip, Icon, CheckItem, MultiSelect, Text } from '@bloobirds-it/flamingo-ui';
import { useUserTeams } from '@bloobirds-it/hooks';
import { TeamType } from '@bloobirds-it/types';
import clsx from 'clsx';

import { usePhoneNumberFormContext } from '../../context/phoneNumberFormProvider';
import styles from '../../styles/phoneNumberPage.module.css';
import { handleValueChange } from '../../utils/phoneHelper';

enum IdFilterTypes {
  TEAM = 'TEAM',
  USER = 'USER',
}

export const SelectUsersTeams = ({
  userTeams,
  setUserTeams,
}: {
  userTeams: any;
  setUserTeams: (team: any) => void;
}) => {
  const { teams, isLoadingTeams, isAdminOrManager, teamlessUsers } = useUserTeams();

  const { phoneNumber } = usePhoneNumberFormContext();
  const [selectedTeamsState, setSelectedTeamsState] = useState([]);

  const filteredTeamlessUsers = teamlessUsers
    .flat()
    .filter(user => !phoneNumber.users.some((phoneUser: any) => phoneUser.id === user.id));

  const teamIds = teams?.map(t => t?.id);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedGroup, setSelectedGroup] = useState<IdFilterTypes>(IdFilterTypes.TEAM);

  const { t } = useTranslation();

  const UserCard = ({ user, isTeamUser }: { user: any; isTeamUser: boolean }) => {
    return (
      <div className={styles.itemInformation}>
        <div className={styles.avatarIcon}>
          <Icon name="person" color="bloobirds" size={20} />
        </div>
        <div>
          <Text color="bloobirds" size="s">
            {!isTeamUser ? user.name : user.userName}
          </Text>
          <Text color="softPeanut" size="s">
            {!isTeamUser ? user.email : user.userEmail}
          </Text>
        </div>
      </div>
    );
  };

  const displayedUserIds = new Set<string>();

  if (!isAdminOrManager) {
    return null;
  }

  return isLoadingTeams ? (
    <Skeleton variant="rect" height={24} width={'100%'} />
  ) : (
    // @ts-ignore
    <MultiSelect
      size="medium"
      variant="form"
      width="640px"
      placeholder={t(
        'accountSettings.phoneNumbers.phoneSettings.tabs.teamsandusers.placeholderSelect',
      )}
      value={userTeams.map((v: any) => v.id)}
      onChange={value =>
        handleValueChange(
          value,
          setUserTeams,
          teams,
          teamlessUsers,
          setSelectedTeamsState,
          selectedTeamsState,
        )
      }
      //onSearch={value => setSearchValue(value)}
      onClose={searchValue?.length > 0 ? () => setSearchValue('') : undefined}
      onBlur={searchValue?.length > 0 ? () => setSearchValue('') : undefined}
      onFocus={searchValue?.length > 0 ? () => setSearchValue('') : undefined}
      //autocomplete
      sortByChecked={false}
      renderDisplayValue={value => {
        const selectedUsers = value?.filter(v => !teamIds?.includes(v))?.length;
        if (selectedUsers > 0)
          return t('leftBar.userTeamsFilter.teamsSelected_other', { count: selectedUsers });
        return '';
      }}
    >
      {[
        <>
          <div className={styles['tab-buttons']} key="tab-buttons">
            <Chip
              size="small"
              selected={selectedGroup === IdFilterTypes.TEAM}
              variant="secondary"
              onClick={() => setSelectedGroup(IdFilterTypes.TEAM)}
            >
              {t('common.teams')}
            </Chip>
            <Chip
              size="small"
              variant="secondary"
              selected={selectedGroup === IdFilterTypes.USER}
              onClick={() => setSelectedGroup(IdFilterTypes.USER)}
            >
              {t('common.users')}
            </Chip>
          </div>
        </>,
        teams.length === 0 && selectedGroup !== IdFilterTypes.USER ? (
          <div className={styles.noTeamsToDisplay}>
            <Text color="peanut" size="s">
              {t('accountSettings.phoneNumbers.phoneSettings.tabs.teamsandusers.noTeams')}
            </Text>
          </div>
        ) : (
          teams.map((team: TeamType) => {
            const teamChecked = selectedTeamsState?.includes(team.id);
            const everyUserIsInPhoneNumber = team?.teamUsers?.every((u: any) =>
              phoneNumber.users.some((pu: any) => pu.id === u.userId),
            );
            if (selectedGroup === IdFilterTypes.TEAM) {
              return (
                // @ts-ignore
                <CheckItem
                  key={team?.id}
                  value={team?.id}
                  label={team?.name}
                  icon={team.icon}
                  disabled={everyUserIsInPhoneNumber}
                  iconColor={everyUserIsInPhoneNumber ? 'lightPeanut' : 'bloobirds'}
                  className={teamChecked ? styles.checkedUser : ''}
                >
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    {team?.name}
                    {everyUserIsInPhoneNumber && (
                      <Text className={styles.displayInfo} color="lightPeanut" size="xs">
                        {t(
                          'accountSettings.phoneNumbers.phoneSettings.tabs.teamsandusers.allSelected',
                        )}
                      </Text>
                    )}
                  </div>
                </CheckItem>
              );
            } else {
              return (team?.teamUsers || []).map(user => {
                const userChecked = userTeams?.map((u: any) => u.id).includes(user.userId);
                // Prevent duplicates between team members
                if (displayedUserIds.has(user.userId)) {
                  return null;
                }
                displayedUserIds.add(user.userId);
                // Remove repeated users or users that are already in the phone number
                if (
                  filteredTeamlessUsers.some((u: any) => u.id === user.userId) ||
                  phoneNumber.users.some((u: any) => u.id === user.userId)
                ) {
                  return null;
                }

                return (
                  //@ts-ignore
                  <CheckItem
                    key={user?.userId}
                    value={user?.userId}
                    label={user?.userName}
                    className={clsx(styles['multiselect-items'], {
                      [styles.checkedUser]: userChecked,
                    })}
                  >
                    <UserCard user={user} isTeamUser={true} />
                  </CheckItem>
                );
              });
            }
          })
        ),
        ...(selectedGroup === IdFilterTypes.USER ? filteredTeamlessUsers : []).map(user => {
          const userChecked = userTeams?.map((u: any) => u.id).includes(user.id);
          return (
            //@ts-ignore
            <CheckItem
              key={user.id}
              value={user.id}
              label={user.name}
              className={clsx(styles['multiselect-items'], { [styles.checkedUser]: userChecked })}
            >
              <UserCard user={user} isTeamUser={false} />
            </CheckItem>
          );
        }),
      ]}
    </MultiSelect>
  );
};
