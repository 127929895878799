// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tagsFilter-module___container___cz1UC {\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 18px;\n  margin-bottom: 12px;\n}\n\n.tagsFilter-module___tag__container___VX49g {\n  margin-right: 12px;\n  cursor: pointer;\n  white-space: nowrap;\n  overflow: visible;\n  text-overflow: ellipsis;\n}\n\n.tagsFilter-module___tag__container___VX49g > * {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  max-width: 200px;\n  margin-bottom: 12px;\n}\n\n.tagsFilter-module___more_options__container___vZ1cW {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/viewTable/tagsFilter/tagsFilter.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["._container {\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 18px;\n  margin-bottom: 12px;\n}\n\n._tag__container {\n  margin-right: 12px;\n  cursor: pointer;\n  white-space: nowrap;\n  overflow: visible;\n  text-overflow: ellipsis;\n}\n\n._tag__container > * {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  max-width: 200px;\n  margin-bottom: 12px;\n}\n\n._more_options__container {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_container": "tagsFilter-module___container___cz1UC",
	"_tag__container": "tagsFilter-module___tag__container___VX49g",
	"_more_options__container": "tagsFilter-module___more_options__container___vZ1cW"
};
export default ___CSS_LOADER_EXPORT___;
