import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createToast } from '@bloobirds-it/flamingo-ui';
import { api } from '@bloobirds-it/utils';
import useSWR from 'swr';

import { useActiveAccountId } from './useActiveAccount';
import { useActiveUserId } from './useActiveUser';
import { useEmailConnections } from './useEmailConnections';

export const useExtractSignatures = () => {
  const userId = useActiveUserId();
  const accountId = useActiveAccountId();
  const isLoggedIn = !!accountId;
  const { connections } = useEmailConnections();

  const { t } = useTranslation();
  const { mutate } = useSWR(
    userId && isLoggedIn ? `/utils/helpers/${userId}` : null,
    () => api.get('/utils/users/helpers').then(res => res.data),
    {
      revalidateOnFocus: false,
    },
  );

  const [loading, setLoading] = useState(false);

  const extractSignatures = async () => {
    if (accountId) {
      setLoading(true);
      try {
        connections.list.forEach(async connection => {
          await api.post(
            `/messaging/signatures/nylasUserAccount/${connection.id}/extractSignature`,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          );
        });
        createToast({
          message: t('quickStartGuide.oto.blocks.signatures.toast.success'),
          type: 'success',
        });
        mutate();
      } catch (error) {
        createToast({
          message: t('quickStartGuide.oto.blocks.signatures.toast.error'),
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return {
    extractSignatures,
    loading,
  };
};
