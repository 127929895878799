// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".salesforceNoIntegrationPage-module___input_group___iN0tq > div {\n  margin-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/accountSettingsPages/salesforceIntegrationPage/noIntegration/salesforceNoIntegrationPage.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB","sourcesContent":["._input_group > div {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_input_group": "salesforceNoIntegrationPage-module___input_group___iN0tq"
};
export default ___CSS_LOADER_EXPORT___;
