// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stagesList-module___tbody___ZL6vL {\n  display: table-row-group;\n  vertical-align: middle;\n  border: 1px solid black;\n}\n\n.stagesList-module___no_results__contents___uWLn9 {\n  box-sizing: border-box;\n  padding: 150px 227px 150px 227px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: white;\n}\n\n.stagesList-module___no_results__img___M-FeR {\n  margin-bottom: 20px;\n}\n\n.stagesList-module___card__dragging___FPOVr {\n  box-shadow: 0 4px 6px rgba(92, 92, 255, 0.3), 0 -4px 6px rgba(92, 92, 255, 0.15) !important;\n}\n\n.stagesList-module___card__dragging___FPOVr > *:nth-of-type(1n + 3) {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://./src/js/pages/playbookPages/salesPipelinePage/stagesList/stagesList.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,gCAAgC;EAChC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,2FAA2F;AAC7F;;AAEA;EACE,aAAa;AACf","sourcesContent":["._tbody {\n  display: table-row-group;\n  vertical-align: middle;\n  border: 1px solid black;\n}\n\n._no_results__contents {\n  box-sizing: border-box;\n  padding: 150px 227px 150px 227px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: white;\n}\n\n._no_results__img {\n  margin-bottom: 20px;\n}\n\n._card__dragging {\n  box-shadow: 0 4px 6px rgba(92, 92, 255, 0.3), 0 -4px 6px rgba(92, 92, 255, 0.15) !important;\n}\n\n._card__dragging > *:nth-of-type(1n + 3) {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_tbody": "stagesList-module___tbody___ZL6vL",
	"_no_results__contents": "stagesList-module___no_results__contents___uWLn9",
	"_no_results__img": "stagesList-module___no_results__img___M-FeR",
	"_card__dragging": "stagesList-module___card__dragging___FPOVr"
};
export default ___CSS_LOADER_EXPORT___;
