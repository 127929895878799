import React, { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Text } from '@bloobirds-it/flamingo-ui';

import { InputFeedbackItem } from '../../../../components/inputFeedback/inputFeedbackItem';
import { InputFeedbackList } from '../../../../components/inputFeedback/inputFeedbackList';
import PasswordInput from '../../../../components/passwordInput/passwordInput';
import styles from './passwordChangeForm.module.css';

const PasswordChangeForm = ({ onSave }) => {
  const { control, handleSubmit, formState, errors, trigger, reset } = useForm({
    criteriaMode: 'all',
    mode: 'onChange',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
    },
  });
  const { t } = useTranslation('translation', { keyPrefix: 'userSettingsWebapp.passwordSettings' });

  const {
    field: { ref: oldPasswordRef, ...oldPasswordField },
  } = useController({
    name: 'oldPassword',
    control,
    rules: { required: true },
    defaultValue: '',
  });

  const {
    field: { ref: newPasswordRef, ...newPasswordField },
  } = useController({
    name: 'newPassword',
    control,
    rules: {
      validate: {
        minLength: data => data.length > 7,
        uppercaseCharacter: data => !!data.match(/[A-Z]/),
        lowercaseCharacter: data => !!data.match(/[a-z]/),
        specialSymbol: data => !!data.match(/[^A-z]/),
      },
    },
    defaultValue: '',
  });

  // Invalidate errors initially
  useEffect(() => {
    trigger();
  }, []);

  async function onSubmit({ oldPassword, newPassword }) {
    await onSave({ oldPassword, newPassword });
    reset();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-intercom="user-settings-page-security">
      <div className={styles.content}>
        <Text color="softPeanut" weight="medium" size="m" htmlTag="span">
          {t('title')}
        </Text>
        <div className={styles.passwordInputs}>
          <PasswordInput width="100%" placeholder={t('pwdPlaceholder')} {...oldPasswordField} />
          <PasswordInput width="100%" placeholder={t('newPwdPlaceholder')} {...newPasswordField} />
        </div>
        <InputFeedbackList>
          <InputFeedbackItem valid={!errors.newPassword?.types.minLength}>
            {t('rule1')}{' '}
          </InputFeedbackItem>
          <InputFeedbackItem valid={!errors.newPassword?.types.uppercaseCharacter}>
            {t('rule2')}
          </InputFeedbackItem>
          <InputFeedbackItem valid={!errors.newPassword?.types.lowercaseCharacter}>
            {t('rule3')}
          </InputFeedbackItem>
          <InputFeedbackItem valid={!errors.newPassword?.types.specialSymbol}>
            {t('rule4')}
          </InputFeedbackItem>
        </InputFeedbackList>
        <Button
          type="submit"
          className={styles.saveButton}
          disabled={formState.isSubmitting || !formState.isValid}
        >
          {t('reset')}
        </Button>
      </div>
    </form>
  );
};

export default PasswordChangeForm;
