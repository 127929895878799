import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Action,
  Button,
  ColorType,
  Icon,
  IconType,
  Label,
  Text,
  Tooltip,
} from '@bloobirds-it/flamingo-ui';
import { RelatedObject } from '@bloobirds-it/types';
import { relatedPickableIcons, toTitleCase, getSalesforceObjectIcon } from '@bloobirds-it/utils';
import clsx from 'clsx';
import spacetime from 'spacetime';

import styles from './relatedObjectCard.module.css';

export const RelatedObjectCard = ({
  data,
  onClick,
}: {
  data: RelatedObject;
  onClick: () => void;
}) => {
  const { icon, title, fields, salesforceUrl, lastModifiedDate, relatedObjectType } = data;
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    window.open(salesforceUrl, '_blank');
  };

  const handleClickOnReferenceField = urlReferenceField => {
    window.open(urlReferenceField, '_blank');
  };

  const fieldsToShow = fields?.slice(0, 10);

  const getValue = field => {
    const value = field?.value;
    if (!value || value === 'null') {
      return '-';
    }

    const type = field?.fieldType;
    if (type === 'datetime') {
      try {
        return spacetime(value).format(t('dates.monthShortWithTime'));
      } catch (e) {
        return value;
      }
    }
    return value;
  };

  const smallCard = ref.current?.clientWidth < 300;

  return (
    <div
      key={title}
      className={clsx(styles.relationCard, { [styles.clickableCard]: onClick })}
      onClick={onClick}
      ref={ref}
    >
      <div className={styles.relationTitle}>
        <Action
          icon={icon ?? ('salesforce' as IconType)}
          color={
            relatedPickableIcons.find(p => p.name === (icon ?? 'salesforce'))?.color as ColorType
          }
          size="xs"
        />
        <Text size="s" color="peanut" weight="bold">
          {title}
        </Text>
        <div className={clsx(styles.rightSide, { [styles.rightSideSmall]: smallCard })}>
          <Tooltip
            title={smallCard && toTitleCase(relatedObjectType?.toLowerCase())}
            position="top"
          >
            <Label
              color="lightPeanut"
              size="small"
              textColor="peanut"
              iconColor="peanut"
              icon={getSalesforceObjectIcon(relatedObjectType)}
              uppercase={false}
            >
              {!smallCard && toTitleCase(relatedObjectType?.toLowerCase())}
            </Label>
          </Tooltip>
          <Button variant="secondary" size="small" onClick={handleClick}>
            <Icon name="salesforce" size={16} color="bloobirds" />
          </Button>
          <Text size="xs" color="peanut">
            {spacetime(lastModifiedDate).format('{date-pad} {month-short}')}
          </Text>
        </div>
      </div>
      {fieldsToShow?.length > 0 && (
        <div className={styles.relationBody}>
          {fieldsToShow.map(field => (
            <div key={field.apiName} className={styles.relationField}>
              <Text size="xs" color="softPeanut">
                {field?.label}
              </Text>
              {field?.fieldType === 'reference' && field?.urlReferencedObject != null ? (
                <span
                  className={styles.referenceField}
                  onClick={() => handleClickOnReferenceField(field?.urlReferencedObject)}
                >
                  <Text size="xs" color="bloobirds" ellipsis={40}>
                    {getValue(field)}
                  </Text>
                </span>
              ) : (
                <Text size="xs">{getValue(field)}</Text>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
