import { createApi } from '@bloobirds-it/plover';
import { AxiosInstance } from 'axios';

import { manageRefreshToken } from './auth';

export const appHostnames = ['app.dev-bloobirds.com', 'app.bloobirds.com', 'localhost'];

export function getTokenWebapp() {
  const newToken = localStorage.getItem('bb-token-webapp');
  const oldSession = localStorage.getItem('bb-app-session');
  const newSecuredToken = localStorage.getItem('bb-token');
  if (newSecuredToken) {
    return newSecuredToken;
  }
  if (newToken) {
    return newToken;
  }
  if (oldSession) {
    return JSON.parse(oldSession)?.token || null;
  }
  return null;
}

export const isWeb = () =>
  typeof window !== 'undefined' &&
  (appHostnames.includes(window.location.hostname) ||
    window.location.hostname.includes('bloobirds-platform-frontend.pages.dev'));

const isExtension = () => chrome?.storage?.sync;

export const getToken = (): Promise<string | null> => {
  if (isWeb()) {
    return Promise.resolve(getTokenWebapp());
  } else {
    if (isExtension()) {
      return new Promise(resolve => {
        chrome.storage?.sync?.get({ token: '' }, ({ token }) => {
          resolve(token);
        });
      });
    } else {
      return Promise.resolve(null);
    }
  }
};

export const setToken = (token: string): Promise<void> => {
  if (token) {
    if (isWeb()) {
      localStorage.setItem('bb-token', token);
      return Promise.resolve();
    } else {
      if (isExtension()) {
        return new Promise<void>(resolve => {
          chrome.storage?.sync?.set({ token }, () => {
            resolve();
          });
        });
      }
    }
  }
};

export const getFingerprint = (): Promise<string | null> => {
  if (isWeb()) {
    return Promise.resolve(localStorage.getItem('bb-hashed-fingerprint'));
  } else {
    if (isExtension()) {
      return new Promise(resolve => {
        chrome.storage?.sync?.get({ hashedFingerprint: '' }, ({ hashedFingerprint }) => {
          resolve(hashedFingerprint);
        });
      });
    } else {
      return Promise.resolve(null);
    }
  }
};

export const getRefreshToken = (): Promise<string | null> => {
  if (isWeb()) {
    return Promise.resolve(localStorage.getItem('bb-refresh-token'));
  } else {
    if (isExtension()) {
      return new Promise<string>(resolve => {
        chrome.storage?.sync?.get({ refreshToken: '' }, ({ refreshToken }) => {
          resolve(refreshToken);
        });
      });
    } else {
      return Promise.resolve(null);
    }
  }
};

export const refreshOrRemoveTokens = async (api, error) => {
  const originalRequest = error?.response?.config;
  return await manageRefreshToken(error, isWeb(), async () => await api(originalRequest));
};

export const api: AxiosInstance = createApi({
  getToken,
  setToken,
  getFingerprint,
  getRefreshToken,
  getEnvironment: () => process.env.ENV || 'local',
  refreshOrRemoveTokens,
});
