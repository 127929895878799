import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Tag, Tooltip, Text, ColorType, useHover } from '@bloobirds-it/flamingo-ui';
import {
  useActiveUserSettings,
  useMainRelatedUsageEnabled,
  useObjectCreationSettings,
} from '@bloobirds-it/hooks';
import { BobjectId, SmartEmailTab } from '@bloobirds-it/types';
import { isEmail } from '@bloobirds-it/utils';

import { useSmartEmailModal } from '../../../../modals/smartEmailModal/smartEmailModal';
import { Contact } from '../../recipientSearchInput';
import styles from '../../recipientSearchInput.module.css';
import { RelatedObjectHandler } from './components/relatedObjectHandler';

function getTooltipText(email, isOutsider, isValidEmail, showAddToDB, t) {
  if (showAddToDB) return t('notRegisteredTooltip');
  if (isOutsider && isValidEmail) return t('outsiderTooltip', { email });
}
export const EmailBadge = ({
  contact,
  unselectEmail,
  isOutsider = false,
  activeRelated,
  onAddRelated,
}: {
  contact: Contact;
  unselectEmail: () => void;
  isOutsider: boolean;
  activeRelated?: string[];
  onAddRelated?: (value: { [leadId: BobjectId['value']]: string[] }) => void;
}) => {
  const { settings } = useActiveUserSettings();
  const mainRelatedUsageEnabled = useMainRelatedUsageEnabled(settings?.account?.id);
  const { enabledObjectCreation } = useObjectCreationSettings();
  const { t } = useTranslation('translation', {
    keyPrefix: 'smartEmailModal.components.recipientSearchInput',
  });
  const { newLeadInfo, setNewLeadInfo, setSelectedTab, company } = useSmartEmailModal() || {
    setNewLeadInfo: { email: undefined, company: undefined },
    setSelectedTab: () => null,
  };
  const isSmartEmailEditor = typeof setNewLeadInfo === 'function';
  const isContactOnDatabase = contact.isInDB;
  const isValidEmail = isEmail(contact.email);
  const showAddToDB =
    !contact.isCompanyMember && !isContactOnDatabase && isValidEmail && isSmartEmailEditor;
  const [ref, isHoveringChild] = useHover();
  const tooltipText =
    !isHoveringChild && getTooltipText(contact.email, isOutsider, isValidEmail, showAddToDB, t);
  const color = ((): ColorType => {
    if (!isValidEmail) return 'verySoftTomato';
    else if (contact.isCompanyMember) return 'lightBloobirds';
    else if (showAddToDB) {
      return 'verySoftTangerine';
    } else if (isContactOnDatabase && !isOutsider) {
      return 'lightestBloobirds';
    } else {
      return 'verySoftBanana';
    }
  })();

  return (
    <div
      style={{
        display: 'flex',
        gap: '4px',
        borderRadius: '17px',
      }}
    >
      <Tag
        uppercase={false}
        color={color || 'lightestBloobirds'}
        iconLeft={showAddToDB && enabledObjectCreation ? 'plus' : undefined}
        onClickLeft={() => {
          if (showAddToDB && enabledObjectCreation) {
            setNewLeadInfo({ ...newLeadInfo, email: contact.email, company });
            setSelectedTab(SmartEmailTab.CREATE_LEAD);
          }
        }}
        iconRight="cross"
        onClickRight={e => {
          e.stopPropagation();
          unselectEmail();
        }}
      >
        <Tooltip title={tooltipText} position="top">
          <div style={{ display: 'flex', gap: '4px' }}>
            {showAddToDB ? (
              <Icon
                name="alertTriangle"
                color="tangerine"
                size={16}
                className={styles.warningIcon}
              />
            ) : (
              isOutsider &&
              isValidEmail && (
                <Icon
                  name="alertTriangle"
                  color="peanut"
                  size={16}
                  className={styles.warningIcon}
                />
              )
            )}
            <Text size="s" inline>
              {contact.email}
            </Text>
            {mainRelatedUsageEnabled && contact.isInDB && contact?.bobject && (
              <RelatedObjectHandler
                ref={ref}
                isOutsider={isOutsider}
                {...contact}
                activeRelated={activeRelated}
                onAddRelated={(value: string[]) =>
                  onAddRelated({ [contact?.bobject?.id?.value]: value })
                }
              />
            )}
          </div>
        </Tooltip>
      </Tag>
    </div>
  );
};
