import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Modal,
  ModalContent,
  ModalFooter,
  Button,
  ModalFooterButtons,
  IconButton,
  Text,
  Icon,
} from '@bloobirds-it/flamingo-ui';

import { usePhoneNumberFormContext } from '../../context/phoneNumberFormProvider';
import styles from '../../styles/phoneNumberPage.module.css';
import { InfoCalloutModal } from '../advancedSettings/customCallouts';
import { SelectUsersTeams } from '../teamsandusers/selectUsersTeams';

export const AddUserModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const { handleChange, phoneNumber, handleSaveChanges } = usePhoneNumberFormContext();
  const [userTeams, setUserTeams] = useState([]);

  const handleSaveUsers = () => {
    const updatedPhoneNumber = {
      ...phoneNumber,
      users: [
        ...phoneNumber.users,
        ...userTeams
          .map((user: any) => ({
            id: user.id,
            name: user.name,
            color: user.color,
            email: user.email,
          }))
          .filter(
            (user: any) => !phoneNumber.users.some((phoneUser: any) => phoneUser.id === user.id),
          ),
      ],
    };
    handleChange('users', updatedPhoneNumber.users);
    handleSaveChanges(updatedPhoneNumber);
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} onOverlayClick={() => setOpen(false)}>
      <ModalContent dataTest="modal-example">
        <div className={styles.modalHeader}>
          <div className={styles.itemInformation}>
            <Icon name="phone" size={24} color="bloobirds" />
            <Text size="l" color="peanut" weight="bold">
              {t('accountSettings.phoneNumbers.phoneSettings.tabs.teamsandusers.modalTitle')}
            </Text>
          </div>
          <IconButton name="cross" onClick={() => setOpen(false)} />
        </div>
        <div style={{ marginTop: '46px' }}>
          <Text size="l" color="peanut" weight="regular">
            {t('accountSettings.phoneNumbers.phoneSettings.tabs.teamsandusers.chooseTeam')}
          </Text>
        </div>
        <InfoCalloutModal />
        <div className={styles.teamFiltersContainer}>
          <SelectUsersTeams userTeams={userTeams} setUserTeams={setUserTeams} />
        </div>
      </ModalContent>
      <ModalFooter>
        <Button color="bloobirds" onClick={() => setOpen(false)} variant="clear">
          {t('common.goBack')}
        </Button>
        <ModalFooterButtons>
          <Button onClick={() => handleSaveUsers()}>
            {t('accountSettings.phoneNumbers.phoneSettings.tabs.teamsandusers.setNumber')}
          </Button>
        </ModalFooterButtons>
      </ModalFooter>
    </Modal>
  );
};
